<template>
  <div>
    <LoadingSpinner />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, onBeforeMount } from "vue";
import { logEvent, logException } from "@/common/logger";
import { useRoute, useRouter } from "vue-router";
import { usePayPalStore } from "@/stores/payPal";
import { useBuyFlowStore } from "@/stores/buyFlow";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { pageStates, paymentTypes } from "@/components/Buy/BuyEnums";
import { IUserAddress, ICountry, IAutoRenewRequest, IAutoRenewResponse } from "@/common/api/unifiedPortal/interfaces";
import { shippingStatesAndProvinces } from "@/common/ShippingStatesAndProvinces";
import { unifiedApi } from "@/common";
import { payPalCheckOutPage } from "@/globalEnums";
import { useAutoRenewalStore } from "@/stores/autoRenewal";
//import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import {
  changePageState,
  getLicenseKeyFromCartItems,
  handlePurchaseError,
  loadPaymentMethods,
} from "@/components/Buy/BuyHelper";
import { useUserStore } from "@/stores/user";
import { reloadSubscriberInfoLite } from "@/common/reloadSubscriberInfo";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { AxiosError } from "axios";
import { IPurchaseError } from "../Buy/Interfaces";
import { handleApiError } from "@/common/handleApiError";
import { SubscriptionInformationRouteName } from "@/define";

const componentName = "ReturnComponent";

logEvent("created", componentName);
const route = useRoute();
const router = useRouter();
const payPalStore = usePayPalStore();
const buyFlowStore = useBuyFlowStore();
const userStore = useUserStore();
const autoRenewalStore = useAutoRenewalStore();
const loadUserInfoFromCart = ref(false);
const subscriptionsStore = useSubscriptionsStore();

const countriesList = ref<ICountry[] | null>();

const token = route.query.token?.toString() || "";
const ba_token = route.query.ba_token?.toString() || "";
const isEditPayment = sessionStorage.getItem("isEditPayment") === "true";

onBeforeMount(async () => {
  const res = (await unifiedApi.getCountries()).data;
  countriesList.value = res?.countries;
});

onMounted(async () => {
  try {
    logEvent("mounted", componentName);
    const planetOpenTextDiscountApplied = sessionStorage.getItem("planetOpenTextDiscountApplied");
    if (planetOpenTextDiscountApplied) {
      buyFlowStore.planetOpenTextDiscountApplied = planetOpenTextDiscountApplied === "true" ? true : false;
    }
    sessionStorage.removeItem("planetOpenTextDiscountApplied");
    //check Billing Agreement token exist
    if (token != "" && ba_token != "") {
      if (sessionStorage.getItem("IsGuestFlowForPayPal") !== "true") {
        //Reload userStore since this data get lost due to redirected to paypal portal
        await reloadSubscriberInfoLite();
      } else {
        loadUserInfoFromCart.value = true;
        sessionStorage.removeItem("IsGuestFlowForPayPal");
      }
      //check and set if cart has AllState with Non US Paypal Shipping Address
      //payPalStore.setPayPalShippingAddressIsNonUS();
      //Reload the PayPal_CheckOutPage from session //This is done since redirect to paypal doesn't persist data in pinia store
      var payPalCheckOutFrom = sessionStorage["PayPal_CheckOutPage"] ?? payPalCheckOutPage.BuyFlowPayment;

      //set the reloaded PayPal_CheckOutPage to store
      payPalStore.SetPayPalCheckoutFrom(payPalCheckOutFrom);

      //set the Billing Agreement Response in store
      await payPalStore.setPayPalBillingAgreementResponse(ba_token);

      //check if paypal checkout is done from buyflow page
      if (payPalCheckOutFrom == payPalCheckOutPage.BuyFlowPayment) {
        await payPalReturnSetupBuyFlow();
        //Set the buy flow page state here since to fix ZUOR-48888 does not call change page state in all situations anymore
        //This was to fix an issue with the back button
        changePageState(pageStates.reviewOrder);
        //Redirect to buy Flow Page to set the cart and the redirect to reviewOrderPage
        router.push(payPalStore.getPayPalSuccessRoute);
      } else if (payPalCheckOutFrom == payPalCheckOutPage.SubscriptionInformation) {
        //Reload the auto Renew request object from session //This is done since redirect to paypal doesn't persist data in pinia store
        var autoRenewRequest = JSON.parse(sessionStorage["subscription_autoRenewRequest"]);
        //set the reloaded subscription_autoRenewRequest to store
        autoRenewalStore.setAutoRenewRequestParameters(autoRenewRequest);
        //autorenew from subscription page
        if (!payPalStore.payPalShippingAddressIsNonUS) {
          const updatedAutoRenewRequest: IAutoRenewRequest = {
            ...autoRenewalStore.getAutoRenewRequestParameters,
            paymentMethodId: payPalStore.payPalPaymentMethodId,
          };
          const response = (await unifiedApi.setAutoRenew(updatedAutoRenewRequest)).data;
          if (isEditPayment) {
            autoRenewalStore.isEditPayment = isEditPayment;
          } else {
            autoRenewalStore.setAutoRenewResponse({ status: response });
          }
        } else {
          autoRenewalStore.setAutoRenewResponse({
            status: false,
            message: "AllStateNonUs",
          });
        }
        //Next page should reload the subs
        subscriptionsStore.forceRefresh();
        //clear session
        clearSessionItems();
        //set paypal checkout from page to default buyflow payment page
        payPalStore.SetPayPalCheckoutFrom(payPalCheckOutPage.BuyFlowPayment);
        //clear store values for the
        autoRenewalStore.setAutoRenewRequestParameters({});
        //Redirect to subscription information page after the auto renew is set.
        router.push(autoRenewalStore.getAutoRenewSuccessRoute);
      } else if (payPalCheckOutFrom == payPalCheckOutPage.OutstandingInvoice) {
        //clear session
        clearSessionItems();
        //set paypal checkout from page to default buyflow payment page
        payPalStore.SetPayPalCheckoutFrom(payPalCheckOutPage.BuyFlowPayment);
        router.push({ name: "subscription_information", query: { action: "outstandinginvoice" } });
      }
    }
  } catch (error) {
    const err = error as AxiosError;
    const statusCode = err?.response?.status;
    const response = err?.response?.data as IPurchaseError;
    //Clear session so we don;t get stuck in a weird state
    clearSessionItems();

    if (payPalStore.payPalCheckoutFrom === payPalCheckOutPage.BuyFlowPayment) {
      payPalReturnSetupBuyFlow(true);
      handlePurchaseError(response.message, statusCode, true);
      return;
    } else if (
      payPalCheckOutFrom === payPalCheckOutPage.SubscriptionInformation ||
      payPalCheckOutFrom === payPalCheckOutPage.OutstandingInvoice
    ) {
      //Clear everything that could set off successful messages since we failed somewhere
      autoRenewalStore.setAutoRenewResponse({} as IAutoRenewResponse);
      autoRenewalStore.isEditPayment = false;
      handlePurchaseError(response.message, statusCode, true, SubscriptionInformationRouteName);
      return;
    }

    logException(error as Error, componentName);
    handleApiError(err, false);
  }
});

async function payPalReturnSetupBuyFlow(skipDueToError = false) {
  //Reload BuyFlow store from session
  buyFlowStore.cart = JSON.parse(sessionStorage["BuyFlow_Paypal_cart"]);
  if (loadUserInfoFromCart.value) {
    //If the refresh subscriber call failed then the user must be on a unauthenticated flow
    buyFlowStore.userGuid = buyFlowStore.cart?.userGuid ?? "";
    buyFlowStore.currentEmail = buyFlowStore.cart?.userEmail ?? "";
    userStore.email = buyFlowStore.cart?.userEmail ?? "";
    buyFlowStore.licenseKey = getLicenseKeyFromCartItems(buyFlowStore.cart?.items || []);
  }
  buyFlowStore.paymentType = paymentTypes.PayPal;
  //Set paypal shipping address to buyflow shipping and billing address
  if (payPalStore.payPalShippingAddress) {
    const shippingAddress = createShippingAddressFromPaypalResponse();
    buyFlowStore.shippingAddress = Object.assign({}, shippingAddress);
    if (buyFlowStore.cart) {
      buyFlowStore.cart.shippingAddress = buyFlowStore.shippingAddress;
    }
    logEvent("buyFlowStore-shippingAddress", componentName, buyFlowStore.shippingAddress);
  }

  //Skip over getting/setting the payment if there was an error on the billing agreement
  if (!skipDueToError) {
    //This needs to happen before we try to load the payment methods
    //because of the case where this is a preliminary user the call to get the guest payment method is failing
    //since the payment method id has not be set to the cart.
    if (payPalStore.payPalPaymentMethodId) {
      buyFlowStore.updateCreditCard(payPalStore.payPalPaymentMethodId);
    }
  }
  //reload the credit cards list.This needs to be loaded even if error happens either in paypal or credit card
  //so that user can select the existing card details instead
  buyFlowStore.creditCards = (await loadPaymentMethods()) || [];
}

// Check if user is authenticated and reload user store if empty
// This will prevent a authenticated user get on a unauthenticated state if page refreshed
// async function getCurrentUser() {
//   const userStore = useUserStore();
//   try {
//     const currentUser = (await unifiedApi.getUser(true))?.data;
//     if (currentUser && !userStore.currentUser) {
//       await reloadSubscriberInfo();
//     }
//   } catch {
//     logEvent("User not logged in", "buyHelper");
//   }
// }

const createShippingAddressFromPaypalResponse = (): IUserAddress => {
  //TODO
  //PayPal response must contain country Name & state.One done Remove below code for getting the country Name & state
  let countryName = "United States";
  let stateName = "";
  if (payPalStore.payPalShippingAddress?.country == null) {
    if (payPalStore.payPalShippingAddress?.countryCode && payPalStore.payPalShippingAddress?.countryCode != "") {
      countryName =
        countriesList?.value?.find(x => x.countryCode === payPalStore.payPalShippingAddress?.countryCode)
          ?.countryName ?? "United States";
    }
  }
  if (payPalStore.payPalShippingAddress?.stateOrProvince && payPalStore.payPalShippingAddress?.stateOrProvince != "") {
    const values = shippingStatesAndProvinces[countryName];
    if (values && values.length > 0) {
      stateName = values.find(n => n.value == payPalStore.payPalShippingAddress?.stateOrProvince).text ?? "";
    }
  }

  const shippingAddress: IUserAddress = {
    firstName: payPalStore.payPalShippingAddress?.contactFirstName || "",
    lastName: payPalStore.payPalShippingAddress?.contactLastName || "",
    address1: payPalStore.payPalShippingAddress?.streetAddress1 || "",
    address2: payPalStore.payPalShippingAddress?.streetAddress2 || "",
    city: payPalStore.payPalShippingAddress?.cityName || "",
    state: stateName || "",
    zipCode: payPalStore.payPalShippingAddress?.postalCode || "",
    country: countryName || "",
  };
  return shippingAddress;
};

function clearSessionItems() {
  //clear session
  sessionStorage.removeItem("isEditPayment");
  sessionStorage.removeItem("PayPal_CheckOutPage");
}

onUnmounted(() => {
  logEvent("unmounted", componentName);
});
</script>
