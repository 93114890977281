<template>
  <ul class="plansList">
    <li v-for="(subscription, index) in safeSubscriptions" :id="`sub${index}`" :key="`sub${index}`">
      <span v-if="isOnlyKeycode(subscription)">
        <strong>{{ subscription.productDisplayName }}</strong>
        ({{ t("keycode") }}:
        <strong>{{ formatKeycode(subscription?.keyCode ?? "") }})</strong>
      </span>
      <span v-else>
        <div v-if="computerName(subscription)">
          <strong>{{ subscription.productDisplayName }}</strong>
          {{ t("SubscriptionInformationComponent.on") }}
          <span class="computer-name">
            <strong>{{ computerName(subscription) }}</strong>
          </span>
        </div>
        <div v-else>
          <strong>{{ subscription.productDisplayName }}</strong>
          ({{ t("SubscriptionInformationComponent.unassigned") }})
        </div>
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { KeyCodeSeller } from "@/globalEnums";
import { formatKeycode } from "@/common/formatKeycode";
import { carboniteProducts } from "@/common/productsBySku";
import { FLAGS } from "@/define";

const componentName = "SafeProductsInfoComponent";
const props = defineProps({
  subscriptions: {
    type: Array as PropType<ISubscription[]>,
    required: true,
  },
});

logEvent("created", componentName);

const safeSubscriptions = computed(() => {
  //If webroot buyflow is on only skus in the carbonite products should be listed. Legacy Webroot keycodes should be treated as normal Webroot keycodes
  if (FLAGS.ENABLE_BUYFLOW_WEBROOT) {
    return props.subscriptions.filter(sub => carboniteProducts.find(product => product.sku === sub.sku));
  }
  return props.subscriptions.filter(sub => sub.keyCodeSellerType === KeyCodeSeller.Safe && sub.isAutoRenewEnabled);
});

function computerName(subscription: ISubscription) {
  return subscription.computer?.description ?? subscription.computer?.name;
}

function isOnlyKeycode(subscription: ISubscription) {
  return (
    subscription.keyCode &&
    subscription.sku &&
    carboniteProducts.filter(product => product.sku === subscription.sku).length === 0
  );
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.plansList {
  list-style-type: disc;
  padding-left: var(--space);
}

.computer-name {
  max-width: 200px;
  display: flex;
}

.computer-name strong {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
