<template src="./SvgIcon.html"></template>

<script setup lang="ts">
//Disable eslint error since the SvgIcon.html is a generated file that uses the props
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
});
</script>
