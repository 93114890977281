<template>
  <ModalWrapperComponent
    dialog-name="VerifyPrimaryEmailAddress"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul>
        <li>
          <i18n-t keypath="VerifyPrimaryEmailAddressComponent.line1" tag="p" scope="global">
            <template #email>
              <strong v-if="personalInfoStore.pendingVerificationEmail">
                {{ personalInfoStore.pendingVerificationEmail }}
              </strong>
              <strong v-else>{{ eMail }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="VerifyPrimaryEmailAddressComponent.line2" tag="p">
            <template #carbonitePlusWebroot>
              <strong>{{ tn("carbonitePlusWebroot") }}</strong>
            </template>
            <template #alert>
              <strong>{{ tn("alert") }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="VerifyPrimaryEmailAddressComponent.line3" tag="p">
            <template #time>
              <strong class="danger-text">{{ tn("time") }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <button
            id="btnSendMail"
            type="button"
            class="btn-outline-primary btn-action"
            :disabled="resendMailSent || isSaving"
            @click="sendAnotherEmail()"
          >
            <spinner :is-spinning="isSaving" />
            <span v-if="!resendMailSent">{{ tn("sendAnotherEmail") }}</span>
            <span v-else>{{ tn("emailSent") }}</span>
          </button>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnClose" type="button" class="btn-primary" @click="close()">{{ tn("close") }}</button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { unifiedApi } from "@/common";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { usePersonalInfoStore } from "@/stores/personalInfo";

const componentName = "VerifyPrimaryEmailAddressComponent";

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal"]);

logEvent("created", componentName, props.email);
const eMail = ref<string>(props.email);
const resendMailSent = ref(false);
const isSaving = ref(false);
const personalInfoStore = usePersonalInfoStore();

function beforeOpen() {
  eMail.value = props.email;
  resendMailSent.value = false;
  isSaving.value = false;
}

onMounted(async () => {
  if (props.showDialog) sendEmail(false);
  await personalInfoStore.checkPendingEmailVerification();
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function sendEmail(isResend: boolean) {
  try {
    const emailUpdateResponse = (await unifiedApi.resendVerificationEmail()).data;
    if (emailUpdateResponse !== "") {
      logEvent(isResend ? "resend another email failed : " : "send email failed : ", emailUpdateResponse);
    }
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${componentName}${isResend ? "/sendAnotherEmail" : "/sendEmail"}`);
    handleApiError(error as AxiosError);
  }
}

async function sendAnotherEmail() {
  isSaving.value = true;
  sendEmail(true);
  resendMailSent.value = true;
  isSaving.value = false;
}

function close() {
  emits("close-modal");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
