import { useUserStore } from "@/stores/user";
import { useConfigStore } from "@/stores/config";
import { logEvent } from "./logger";
import { PENDO_LISTENER_INTERVAL_IN_MS, PENDO_LISTENER_TIMEOUT_IN_MS } from "@/define";

const pendo = "Pendo";

export async function initializePendo() {
  logEvent("Initializing...", pendo);
  const command = `
    if (pendo) {
      pendo.initialize({
          visitor: {
              id: ''
          },
      
          account: {
              id: '0'
          }
      });
    }  
    `;
  await run(command);
}

export async function identifyPendo(userId: number) {
  logEvent("Identifying...", pendo);
  const userStore = useUserStore();
  if (userStore.isPendoIdentified) {
    return;
  }

  const command = `
    if (pendo) {
      pendo.clearSession();
      pendo.identify({
          visitor: {
              id: "${userId}"
          },
          account: {
              id: "0",
          }
      });
    }
    `;
  await run(command);
  userStore.isPendoIdentified = true;
}

export async function clearSession() {
  logEvent("Clearing Session...", pendo);
  const command = `
    if (pendo) {
      pendo.clearSession();
    }`;
  await run(command);
  const userStore = useUserStore();
  userStore.isPendoIdentified = false;
}

export async function validateInstallPendo() {
  logEvent("Validating Install...", pendo);
  const command = `if (pendo) { pendo.validateInstall(); }`;
  await run(command);
}

export async function installPendo() {
  logEvent("Installing...", pendo);
  const configStore = useConfigStore();
  const apiKey = configStore.config?.pendo?.apiKey;

  // Pendo snippet
  const command = `
    (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('${apiKey}');`;
  await run(command);

  await initializePendo();

  // If the cookie is still valid, initialize
  const userStore = useUserStore();
  const currentUser = userStore.currentUser;
  if (currentUser && currentUser.personId > 0) {
    await identifyPendo(currentUser.personId);
  }
}

// Access the script on Pendo's CDN
export async function run(command: string) {
  const configStore = useConfigStore();

  if (!configStore.config?.pendo?.enabled) {
    return;
  }

  const pendoRunner = "pendoRunner";
  const script: HTMLScriptElement = document.createElement("script");
  script.id = pendoRunner;
  script.innerHTML = command;
  document.body.appendChild(script);
  document.body.removeChild(script); // Remove child so we don't expose Pendo's commands on Inspect.
}

export async function removePendoToolTip() {
  // Listen if Pendo tooltip will be rendered
  const interval = setInterval(() => {
    const element = document.getElementById("pendo-base");
    element?.remove();
  }, PENDO_LISTENER_INTERVAL_IN_MS);
  // Stops listening after 20 seconds
  setTimeout(() => {
    clearInterval(interval);
  }, PENDO_LISTENER_TIMEOUT_IN_MS);
}
