<template>
  <ModalWrapperComponent
    dialog-name="AddEncryptionKeyComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul class="form">
        <li>
          <p>{{ tn("p1") }}</p>
          <div class="label-wrapper">
            <label for="enter-keycode">{{ tn("selectEncryptionFile") }}</label>
          </div>
          <div class="input-btn-set">
            <div class="input-wrapper">
              <input v-model="fileName" type="text" disabled="true" />
            </div>
            <div>
              <button class="btn-primary" @click="($refs.fileSelector as HTMLElement).click()">
                {{ tn("browse") }}
              </button>
              <input
                id="fileSelector"
                ref="fileSelector"
                type="file"
                style="display: none"
                accept="*.pem"
                @change="handleFileSelected"
              />
            </div>
          </div>
          <p class="text-sm">
            {{ t("AddEncryptionKeyComponent.selectEncryptionFileHint", { browse: tn("browse") }) }}
          </p>
          <div class="label-wrapper">
            <label for="create-password">{{ tn("encryptionPassword") }}</label>
            <button
              v-if="togglePasswordHidden"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="togglePasswordHidden = !togglePasswordHidden"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="togglePasswordHidden = !togglePasswordHidden"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <div class="input-btn-set">
            <input v-model="password" :type="togglePasswordHidden ? 'password' : 'text'" />
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="t('AddEncryptionKeyComponent.passwordHint', { hint: encryptionKeyPasswordHint })"></p>
          <p>{{ tn("note") }}</p>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button
        id="btnAddChanges"
        type="submit"
        class="btn-primary"
        :disabled="!fileName || isAdding"
        @click="submitFile()"
      >
        <spinner :is-spinning="isAdding" />
        {{ tn("footerButtonSave") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common/index";
import { AxiosError } from "axios";
import { useNotificationsStore } from "@/stores/notifications";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { IErrorMessage } from "@/common/api/interfaces";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { IPasswordHintResponse } from "@/common/api/unifiedPortal/interfaces";

const componentName = "AddEncryptionKeyComponent";

const props = defineProps({
  computerId: {
    type: Number,
    required: true,
  },
  showDialog: {
    type: Boolean,
  },
});

const emits = defineEmits(["close-modal", "save-modal"]);

logEvent("created", componentName);

const notificationsStore = useNotificationsStore();
const isAdding = ref<boolean>(false);
const fileSelector = ref<FileList | null>(null);
const selectedFile = ref<File | null>(null);
const fileName = ref<string>("");
const fileString = ref<string>("");
const password = ref<string>("");
const togglePasswordHidden = ref<boolean>(true);
const encryptionKeyPasswordHint = ref<string>("");
let hintNotSet = tn("HintNotSet");
const passwordHint = ref<IPasswordHintResponse>();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function refreshHintNotSetString() {
  hintNotSet = tn("HintNotSet");
}

registerRefreshFunction(refreshHintNotSetString);

function beforeOpen() {
  notificationsStore.clearNotifications();
}

function setPasswordHintText() {
  if (passwordHint.value) {
    encryptionKeyPasswordHint.value =
      passwordHint.value && passwordHint.value.passwordHint.length === 0 ? hintNotSet : passwordHint.value.passwordHint;
  }
}

registerRefreshFunction(setPasswordHintText);

onBeforeMount(async () => {
  logEvent("Before Mount", componentName);
  fileSelector.value = null;
  selectedFile.value = null;
  fileName.value = "";
  fileString.value = "";
  try {
    passwordHint.value = (await unifiedApi.getEncryptionKeyPasswordHint(props.computerId)).data;
    logEvent(`Password Hint: ${passwordHint.value?.passwordHint}`, componentName);
    if (passwordHint.value) {
      setPasswordHintText();
    }
  } catch (err) {
    logException(err as Error);
  }
});

function handleFileSelected() {
  if (fileSelector.value) {
    const fileList = fileSelector?.value?.["files"] as FileList;
    selectedFile.value = fileList[0];
    fileName.value = selectedFile.value.name;
    readKey();
  }
}

function readKey(): boolean {
  const extension = fileName.value.substring(fileName.value.length - 3);
  if (extension === "pem") {
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile.value as Blob);
    fileReader.addEventListener("load", () => {
      if (typeof fileReader.result === "string") {
        fileString.value = fileReader.result;
      }
    });
    return true;
  }
  notificationsStore.addNotification({ type: "EncryptionKeyInvalidFile" });
  return false;
}

async function submitFile() {
  notificationsStore.clearNotifications();
  isAdding.value = true;
  if (fileString.value.length > 0) {
    try {
      logEvent(`EncryptionKey ${fileString.value.substring(0, 10)}`, componentName);

      const nullablePassword = password.value.trim() !== "" ? password.value : null;
      await unifiedApi.addEncryptionKey(props.computerId, fileString.value, nullablePassword);

      notificationsStore.addNotification({ type: "EncryptionKeySuccess" });
      emits("save-modal");
      close();
    } catch (err) {
      const response = err as AxiosError;
      const errorMessage = (response?.response?.data as IErrorMessage)?.message;

      //log the event first, so it gets transmitted along with the exception report
      logEvent(`Error: ${errorMessage}`, componentName);
      notificationsStore.addNotification({
        type: errorMessage === "EncryptionKeyInvalidPassword" ? "EncryptionKeyWrongPassword" : "EncryptionKeyFailure",
      });

      //report to Stackify
      logException(err as Error);
    }
  }
  isAdding.value = false;
}

function close() {
  emits("close-modal");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
