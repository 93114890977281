<template>
  <div>
    <p>
      <i18n-t keypath="WebrootARTurnOnComponent.WRAutoRenewOnLine1" tag="span" scope="global">
        <template #link>
          <button id="knowledgeBase" type="button" class="inline-btn-link" @click="goToSupport()">
            {{ t("WebrootARTurnOnComponent.supportLink") }}
          </button>
        </template>
      </i18n-t>
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useUserStore } from "@/stores/user";
import { getLanguageUrlParm } from "@/common/helper";

const componentName = "WebrootARTurnOnComponent";

logEvent("created", componentName);
const userStore = useUserStore();
const languageUrlParm = computed(() => getLanguageUrlParm(userStore.selectedLanguage || "en"));

function goToSupport() {
  window.open(`https://www.webroot.com/${languageUrlParm.value}/support/support-home`, "_blank");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
