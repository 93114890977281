<template>
  <div v-if="showSpinner" class="credit-cards">
    <LoadingSpinner />
  </div>
  <div v-else>
    <div class="content">
      <section>
        <header>
          <h1>{{ tn("header") }}</h1>
        </header>
        <p>{{ tn("bodyP1") }}</p>
        <p>{{ tn("bodyP2") }}</p>
        <div>
          <button
            v-if="buttonState === sendEmailButtonState.normal"
            type="button"
            class="btn-primary"
            @click="resendEmail()"
          >
            {{ tn("btnState1") }}
          </button>
          <button v-else-if="buttonState === sendEmailButtonState.spinning" type="button" class="btn-primary" disabled>
            <spinner :is-spinning="true" />
          </button>
          <button v-else-if="buttonState === sendEmailButtonState.sent" type="button" class="btn-primary" disabled>
            {{ tn("btnState2") }}
          </button>
        </div>

        <i18n-t keypath="EmailVerificationNotComplete.bodyP3" tag="p" class="mt mb" scope="global">
          <template #contactSupport>
            <button class="inline-btn-link" @click="goToSupportPage()">{{ t("Common.contactSupport") }}</button>
          </template>
        </i18n-t>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import Spinner from "@/components/shared/Spinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { getUrl } from "@/common/getUrl";

enum sendEmailButtonState {
  normal,
  spinning,
  sent,
}

const componentName = "EmailVerificationNotComplete";

logEvent("created", componentName);
const showSpinner = ref(false);
const buttonState = ref<sendEmailButtonState>(sendEmailButtonState.normal);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function resendEmail() {
  try {
    buttonState.value = sendEmailButtonState.spinning;
    await unifiedApi.resendVerificationEmail();
    buttonState.value = sendEmailButtonState.sent;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function goToSupportPage() {
  window.open(getUrl("VITE_APP_CARBONITE_SUPPORT_SITE"), "_blank");
}
</script>
