import { ref } from "vue";
import { IRfaSessionResponse } from "@/common/api/unifiedPortal/interfaces";
import { logEvent, logException } from "@/common/logger";
import { MSEC_IN_MINUTE } from "@/define";
import { componentName, propData } from "./commonFn";
import { requestSessionKey } from "./serverComm";

export const TOKEN_REFRESH_INTERVAL = 10 * MSEC_IN_MINUTE; //10 minutes
export const TOKEN_REFRESH_RETRY_INTERVAL = 1 * MSEC_IN_MINUTE; //1 minute

export const session = ref<IRfaSessionResponse>();

let updateIntervalId;

//start session update
export function startSessionUpdate(interval) {
  updateIntervalId = window.setInterval(async () => {
    logEvent("refreshing rfa session", componentName, session.value);
    try {
      if (propData.value.computerId) {
        await requestSessionKey();
      }
      //if we've been running at the retry interval, slow it back down
      if (interval == TOKEN_REFRESH_RETRY_INTERVAL) {
        logEvent("rfa refresh rate decrease", componentName, session.value);
        clearInterval(updateIntervalId);
        startSessionUpdate(TOKEN_REFRESH_INTERVAL);
      }
    } catch (err) {
      logException(err as Error);
      //try more often on failure
      if (interval == TOKEN_REFRESH_INTERVAL) {
        logEvent("rfa refresh rate increase", componentName, session.value);
        clearInterval(updateIntervalId);
        startSessionUpdate(TOKEN_REFRESH_RETRY_INTERVAL);
      }
    }
  }, interval);
}

export function endSessionUpdate() {
  clearInterval(updateIntervalId);
}
