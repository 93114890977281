<template>
  <div
    v-if="
      creditEarned &&
      (buyFlowStore.pageState === pageStates.reviewOrder || buyFlowStore.pageState === pageStates.orderConfirmation)
    "
    id="accountCreditEarned"
    class="mb"
  >
    {{ t("AccountCreditEarned.text", { amount: formatMoney(creditEarned.amount || 0) }) }}
  </div>
</template>

<script setup lang="ts">
import { IAdjustment } from "@/common/api/unifiedPortal/interfaces";
import { t } from "@/i18n";
import { computed, PropType } from "vue";
import { adjustments as adjustmentsEnum, pageStates } from "@/components/Buy/BuyEnums";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { formatMoney } from "@/components/Buy/commonFn";
import { logEvent } from "@/common/logger";

const componentName = "AccountCreditEarnedComponent";

logEvent("created", componentName);

const buyFlowStore = useBuyFlowStore();
const props = defineProps({
  adjustments: {
    type: Object as PropType<IAdjustment[]>,
    required: true,
  },
});

const creditEarned = computed(() => {
  return props.adjustments.find(l => l.type === adjustmentsEnum.RemainingCreditBalance);
});
</script>
