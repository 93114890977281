//Guidelines for ENUMS

//All 'enums' that are exported must be declared in a separate enums file.
//If they are used in multiple folders, they must be declared in globalEnums.ts
//If they are used in multiple files from the same folder, they must be declared in an enums file for the folder.

//ex: SubscriptionsEnums.ts contains enums used in the Subscription folder only.
//ex: storesEnums.ts contains enums used in the stores folder only.
//Enums that are not exported do not need to follow this convention.

//previously in enums.ts
export enum pageState {
  offerTrial = "offerTrial",
  download = "download",
  uninstalledSub = "uninstalledSub",
  default = "default",
  upgradeAdd = "upgradeAdd",
  invoiceDetails = "invoiceDetails",
  addPaymentMethod = "addPaymentMethod",
  editPaymentMethod = "editPaymentMethod",
  movePaymentMethodMRToAR = "movePaymentMethodMRToAR",
  movePaymentMethodMRToARaddCard = "movePaymentMethodMRToARaddCard",
  bestBuyOnly = "bestBuyOnly",
  paymentMethodDetails = "paymentMethodDetails",
  cancelSubscriptionSuccess = "cancelSubscriptionSuccess",
  cancelSubscriptionFailure = "cancelSubscriptionFailure",
}

//previously in productList.ts
export enum productType {
  Desktop,
  Browser,
  Mobile,
  Undefined,
}

export enum upgradeGroup {
  ConsumerCarbonite,
  ConsumerAntivirus,
  ConsumerIdProtection,
}

export enum productSubGroups {
  GetStarted,
  SetUpKeyFeatures,
  MobileApps,
}

//previously in securityDevices.ts
export enum ProtectionStatus {
  protected = "protected",
  protectionDisabled = "protectionDisabled",
  shieldsDisabled = "shieldsDisabled",
  expired = "expired",
}

//previously in strongPassword.ts
export enum RuleNames {
  characters = "characters",
  uppercase = "uppercase",
  lowercase = "lowercase",
  number = "number",
  invalidCharacterBrace = "invalid_character_brace",
  invalidCharacters = "invalid_characters",
}

//previously in interfaces.ts
export enum ExpirationState {
  Normal = "Normal", //Has More than 90 days
  Upcoming = "Upcoming", //Has between 1 and 90 days
  Expired = "Expired", //Expired less than 31 days
  FinalWarning = "FinalWarning", // expired less than 61 days, but more than 31
  Purged = "Purged", //Expired more than 60 days
}

//previously in interfaces.ts
export enum KeyCodeSeller {
  Safe = "Safe",
  Webroot = "Webroot",
  BestBuy = "BestBuy",
  Amazon = "Amazon",
}

//previously in interfaces.ts
export enum allstateSubscriptionTypes {
  BasicSingle = "BasicSingle",
  PremiumSingle = "PremiumSingle",
}

//previously in index.ts
export enum UnitType {
  none = "none",
  bytes = "bytes",
  count = "count",
  computers = "computers",
  shields = "shields",
  subscriptionState = "subscriptionState",
}

//previously in index.ts
export enum TileTitle {
  backupOverview = "backupOverview",
  backupZeroState = "backupZeroState",
  securityOverview = "securityOverview",
  securityZeroState = "securityZeroState",
  subscriptionOverview = "subscriptions",
  allStateOverview = "allStateOverview",
}

//previously in index.ts
export enum TileType {
  list = "list",
  tile = "tile",
}

//previously in index.ts
export enum IconType {
  data = "backup-size",
  files = "files",
  devices = "devices",
  threat = "threat-protection",
}

//previously in index.ts
export enum StatusType {
  normal = "normal",
  warning = "warning",
  danger = "danger",
}

//previously in index.ts
export enum SubscriptionState {
  expired = 0,
  expiring = 1,
  activateNow = 2,
  inactive = 3,
  active = 4,
  cancelled = 5,
}

//previously in index.ts
export enum StateType {
  notPurchased = "notPurchased",
  requiresInstall = "requiresInstall",
  requiresInstallAndActivation = "requiresInstallAndActivation",
  installed = "installed",
  serverError = "serverError",
  securityBlocked = "securityBlocked",
}

//previously in MaskedInput.vue
export enum MaskTypes {
  phone = "phone",
  date = "date",
  zip = "zip",
  nonEmpty = "nonEmpty",
}

//previously in notificationInterfaces.ts
export enum NotificationIcon {
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
  INFO = "info",
  INFO_BG = "info-bg",
}

//previously in userInterfaces.ts
export enum UserType {
  smb = "smb",
  consumer = "consumer",
}

// ProductCatalog Type enums
export enum ProductCatalogType {
  Consumer = "Consumer",
  SmallBusiness = "SmallBusiness",
  Webroot = "Webroot",
}

// ProductCatalog Brand enums
export enum ProductCatalogBrand {
  Carbonite = "Carbonite",
  Webroot = "Webroot",
}

//Paypal redirect to states
export enum PayPalRedirectState {
  Return = "return",
  Notify = "notify",
  Cancel = "cancel",
}

// portalDestination part of Ecom redirect parameter
// Format "[portalDestination]-[portalStep]"
// where [portalDestination] is "carb" or "webroot"
export enum portalDestination {
  Carbonite = "carb",
  Webroot = "webroot",
}

//To identify from which page papal checkout button is clicked
//paypal checkout currently called from buyflow page & subscription set auto renew page
export enum payPalCheckOutPage {
  BuyFlowPayment = "BuyFlowPayment",
  SubscriptionInformation = "SubscriptionInformation",
  OutstandingInvoice = "OutstandingInvoice",
}

export enum ZuoraSubscriptionStatus {
  Cancelled = "Cancelled",
  Active = "Active",
}

export enum KeycodeStatus {
  Active = "active",
  Inactive = "inactive",
}
