<template>
  <!-- email and email validation sections -->
  <!-- Primary email is not verified and email change request is present-->
  <section v-if="!personalInfoStore.pendingVerificationEmail && !hasValidatedEmail" class="mb-double">
    <h1>{{ tn("emailAddress") }}</h1>
    <dl>
      <dt>{{ tn("email") }}</dt>
      <dd :class="{ notSetUp: !userEmail }">
        <span id="userEmail" class="mr">{{ userEmail ? userEmail : tn("noEntry") }}</span>
        <span class="danger-text">{{ tn("notVerified") }}</span>
      </dd>
      <dd>
        <button id="validateExistingEmail" class="inline-btn-link" type="button" @click="toggleExistingEmailAddress()">
          {{ tn("verifyEmailAddress") }}
        </button>
      </dd>
      <dd class="italic-text">*{{ tn("verifyEmailAddressMessage") }}</dd>
    </dl>
    <button id="btnHeaderEdit" class="btn-outline-primary" type="button" @click="toggleEmailAddressModal('click')">
      {{ tn("update") }}
    </button>
  </section>

  <!-- email change request is present -->
  <section v-if="personalInfoStore.pendingVerificationEmail" class="mb-double">
    <h1>{{ tn("emailAddress") }}</h1>
    <dl>
      <dt>{{ tn("email") }}</dt>
      <dd :class="{ notSetUp: !userEmail }">
        <span id="userEmail" class="mr">{{ userEmail ? userEmail : tn("noEntry") }}</span>
        <span>{{ tn("Current") }}</span>
      </dd>
      <dd>
        <span id="pendingVerificationEmail" class="pending-email">
          {{ personalInfoStore.pendingVerificationEmail ? personalInfoStore.pendingVerificationEmail : tn("noEntry") }}
        </span>
        <span class="pending-email danger-text">{{ tn("notVerified") }}</span>
      </dd>
      <dd>
        <button
          v-if="userEmail != personalInfoStore.pendingVerificationEmail"
          id="cancelEmailChangeRequest"
          class="inline-btn-link"
          type="button"
          @click="cancelPendingEmailValidation()"
        >
          {{ tn("CancelEmailChangeRequest") }}
        </button>
      </dd>
      <dd>
        <button
          id="finishEmailVerification"
          class="inline-btn-link"
          type="button"
          @click="toggleVerifyEmailAddressModal()"
        >
          {{ tn("FinishEmailVerification") }}
        </button>
      </dd>
      <dd>
        {{ tn("Disclaimer") }}
      </dd>
    </dl>
    <button id="btnHeaderEdit" class="btn-outline-primary" type="button" @click="toggleEmailAddressModal('click')">
      {{ tn("update") }}
    </button>
  </section>

  <!-- Email is verified no pending email change request -->
  <section v-if="!personalInfoStore.pendingVerificationEmail && hasValidatedEmail" class="mb-double">
    <h1>{{ tn("emailAddress") }}</h1>
    <dl>
      <dt>{{ tn("email") }}</dt>
      <dd>
        <span id="userEmail" class="mr">{{ userEmail ? userEmail : tn("noEntry") }}</span>
        <span class="success-text">{{ tn("verified") }}</span>
      </dd>
    </dl>
    <button id="btnHeaderEdit" class="btn-outline-primary" type="button" @click="toggleEmailAddressModal('click')">
      {{ tn("update") }}
    </button>
  </section>

  <UpdateEmailAddress
    :show-dialog="showEmailAddressDialog"
    :email="userEmail ?? ''"
    @close-modal="toggleEmailAddressModal"
    @save-modal="setNewEmailAddress"
  />
  <VerifyPendingEmailAddress
    :show-dialog="showVerifyEmailAddressDialog"
    :email="verifyData.email"
    @close-modal="toggleVerifyEmailAddressModal"
  />
  <VerifyExistingEmailAddress :show-dialog="showExistingEmailAddressModal" @close-modal="toggleExistingEmailAddress" />
</template>

<script setup lang="ts">
import UpdateEmailAddress from "@/components/PersonalInformation/UpdateEmailAddress.vue";
import VerifyPendingEmailAddress from "@/components/PersonalInformation/VerifyPendingEmailAddress.vue";
import VerifyExistingEmailAddress from "@/components/PersonalInformation/VerifyExistingEmailAddress.vue";
import { usePersonalInfoStore } from "@/stores/personalInfo";
import { useNotificationsStore } from "@/stores/notifications";
import { unifiedApi } from "@/common/index";
import { useUserStore } from "@/stores/user";
import { logEvent } from "@/common/logger";
import { computed, onBeforeMount, ref, watch } from "vue";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { ILoggedInUser, IVerifyEmail } from "@/common/api/unifiedPortal/interfaces";
import { t } from "@/i18n";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";

const name = "EmailSectionComponent";
const baseComponent = "PersonalInformationComponent";
const emits = defineEmits(["show-spinner"]);

const userStore = useUserStore();
const personalInfoStore = usePersonalInfoStore();
const notificationsStore = useNotificationsStore();

const verifyData = ref<IVerifyEmail>({ email: "", response: "" });
const showVerifyEmailAddressDialog = ref<boolean>(false);
const showEmailAddressDialog = ref<boolean>(false);
const showExistingEmailAddressModal = ref<boolean>(false);
const apiUser = ref<ILoggedInUser>();

const userEmail = computed(() => {
  return user.value?.email;
});

// computed properties based on the current user
const user = computed(() => {
  return userStore.currentUser;
});

watch(
  () => personalInfoStore.$state.pendingVerificationEmail,
  (newValue, oldValue) => {
    if (newValue === oldValue || !newValue) {
      return;
    }

    notificationsStore.addNotification({ type: "PendingEmailVerification" });
  }
);

watch(
  () => personalInfoStore.$state.showVerifyEmailAddressModal,
  (newValue, oldValue) => {
    if (newValue === oldValue || !newValue) {
      return;
    }

    toggleVerifyEmailAddressModal(newValue);
    //Clear the string
    personalInfoStore.showVerifyEmailAddressModal = "";
  }
);

const hasValidatedEmail = computed(() => {
  return user.value?.subscriberInfo?.hasValidatedEmail;
});

watch(hasValidatedEmail, () => {
  if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  } else {
    notificationsStore.deleteNotificationByType("PrimaryEmailVerificationWebroot");
  }
});

onBeforeMount(() => {
  verifyData.value.email = personalInfoStore.pendingVerificationEmail;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponent}.${v}`, params);
}

async function cancelPendingEmailValidation() {
  try {
    await unifiedApi.cancelPendingEmailValidation();
    await personalInfoStore.checkPendingEmailVerification();
    verifyData.value.email = personalInfoStore.pendingVerificationEmail;
    notificationsStore.clearNotifications();
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/cancelEmail`);
    handleApiError(error as AxiosError);
  }
}

async function toggleExistingEmailAddress() {
  showExistingEmailAddressModal.value = !showExistingEmailAddressModal.value;

  if (!showExistingEmailAddressModal.value) {
    await getApiUser();
    if (hasValidatedEmail.value) {
      notificationsStore.clearNotifications();
    }
  }
}

async function getApiUser() {
  try {
    apiUser.value = (await unifiedApi.getUser()).data;
    if (apiUser.value) {
      userStore.setLoggedInUser(apiUser.value);
    }
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getQuestions`);
    handleApiError(error as AxiosError);
  }
}

function setNewEmailAddress(res) {
  toggleEmailAddressModal("setNewEmailAddress");
  if (res.response === "ok") {
    const verifyEmail = res as IVerifyEmail;
    verifyData.value.email = verifyEmail.email;
    toggleVerifyEmailAddressModal("setNewEmailAddress");
  }
}

async function toggleEmailAddressModal(type = "modal") {
  if (user.value) {
    logEvent("toggleEmailAddressModal", name, `${showEmailAddressDialog.value ? "Hiding" : "Showing"} via ${type}`);
    //Check for pending email when closing the modal since the user could have finished the setup while the modal is open
    if (showEmailAddressDialog.value) {
      await personalInfoStore.checkPendingEmailVerification();
    }
    showEmailAddressDialog.value = !showEmailAddressDialog.value;
  } else {
    logEvent("toggleEmailAddressModal", name, `No action due to no user via ${type}`);
  }
}

async function toggleVerifyEmailAddressModal(type = "modal") {
  if (user.value) {
    logEvent(
      "toggleVerifyEmailAddressModal",
      name,
      `${showVerifyEmailAddressDialog.value ? "Hiding" : "Showing"} via ${type}`
    );
    showVerifyEmailAddressDialog.value = !showVerifyEmailAddressDialog.value;
    if (type === "checkEmailVerificationIsPending") {
      if (personalInfoStore.pendingVerificationEmail) {
        await personalInfoStore.checkPendingEmailVerification();

        if (!personalInfoStore.pendingVerificationEmail) {
          emits("show-spinner", true);
          notificationsStore.clearNotifications();
          notificationsStore.addNotification({ type: "EmailVerificationComplete" });
          await reloadSubscriberInfo();
          emits("show-spinner", false);
        }
      }
    }
  } else {
    logEvent("toggleVerifyEmailAddressModal", name, `No action due to no user via ${type}`);
  }
}
</script>
