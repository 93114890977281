import { MAX_SECURITY_QUESTION_LENGTH, MIN_SECURITY_QUESTION_LENGTH } from "@/define";
import { nameAndSecurityAnswerRegex } from "@/globalRegex";
import { t } from "@/i18n";
import { reportStringErrors } from "./validator";

export function securityAnswerValidator(answer, selectedQuestion, previouslySavedQuestion = "") {
  //selectedQuestion and previouslySavedQuestion help identify if the user selected a new question from the dropdown.
  //If they have, we display an error.
  if (answer.value === "" && previouslySavedQuestion != "" && selectedQuestion.value !== previouslySavedQuestion) {
    return t("SecurityAnswerValidation.AnswerDefaultError");
  }
  //below may not ever trigger since leading spaces are deleted in InputErrorComponent textbox
  if (answer.value.trim().length === 0 && answer.value.length >= MIN_SECURITY_QUESTION_LENGTH) {
    return t("SecurityAnswerValidation.AnswerEmptyStringError");
  }
  return reportStringErrors(
    answer.value,
    t("SecurityAnswerValidation.SecurityAnswer"),
    MIN_SECURITY_QUESTION_LENGTH,
    MAX_SECURITY_QUESTION_LENGTH,
    nameAndSecurityAnswerRegex
  );
}
