<template>
  <label for="language-drop-down">{{ t(`${componentName}.label`) }}</label>
  <select id="language-drop-down" v-model="selectedLanguage" class="select-css auto">
    <option v-for="(language, i) in languages" :key="i" :value="language">{{ languageDisplayNames[language] }}</option>
  </select>
</template>

<script setup lang="ts">
import { languages, t } from "@/i18n";
import { languageDisplayNames } from "@/common/Language";
import { ref, watch } from "vue";

const componentName = "languageDropDownComponent";

const props = defineProps({
  selectedLanguage: {
    type: String,
    required: false,
    default: "en",
  },
});

const emits = defineEmits(["set-selected-language"]);

const selectedLanguage = ref<string>(props.selectedLanguage ?? languages[0]);

watch(selectedLanguage, () => {
  emits("set-selected-language", selectedLanguage.value);
});
</script>
