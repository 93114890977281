import { BYTE_SCALE, COUNT_SCALE, NOMINAL_BYTE_SCALE } from "@/define";
import { UnitType, SubscriptionState } from "@/globalEnums";
import i18n from "@/i18n";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";

export const i18nRoot = "HomeComponent.";
export const MAX_UNIT_NAMES = 4;
let divideBy = i18n.global.t(`${"Common.of"}`);
let byteUnitNames = [
  "",
  i18n.global.t(`${i18nRoot}kilobytes`),
  i18n.global.t(`${i18nRoot}megabytes`),
  i18n.global.t(`${i18nRoot}gigabytes`),
  i18n.global.t(`${i18nRoot}terabytes`),
];

let countUnitNames = [
  "",
  i18n.global.t(`${i18nRoot}thousand`),
  i18n.global.t(`${i18nRoot}million`),
  i18n.global.t(`${i18nRoot}billion`),
  i18n.global.t(`${i18nRoot}trillion`),
];

let subscriptionStateNames = [
  i18n.global.t(`${i18nRoot}expired`),
  i18n.global.t(`${i18nRoot}expiring`),
  i18n.global.t(`${i18nRoot}activateNow`),
  i18n.global.t(`${i18nRoot}inactive`),
  i18n.global.t(`${i18nRoot}active`),
  i18n.global.t(`${i18nRoot}cancelled`),
];

function refreshCachedStrings() {
  divideBy = i18n.global.t(`${"Common.of"}`);
  byteUnitNames = [
    "",
    i18n.global.t(`${i18nRoot}kilobytes`),
    i18n.global.t(`${i18nRoot}megabytes`),
    i18n.global.t(`${i18nRoot}gigabytes`),
    i18n.global.t(`${i18nRoot}terabytes`),
  ];

  countUnitNames = [
    "",
    i18n.global.t(`${i18nRoot}thousand`),
    i18n.global.t(`${i18nRoot}million`),
    i18n.global.t(`${i18nRoot}billion`),
    i18n.global.t(`${i18nRoot}trillion`),
  ];

  subscriptionStateNames = [
    i18n.global.t(`${i18nRoot}expired`),
    i18n.global.t(`${i18nRoot}expiring`),
    i18n.global.t(`${i18nRoot}activateNow`),
    i18n.global.t(`${i18nRoot}inactive`),
    i18n.global.t(`${i18nRoot}active`),
    i18n.global.t(`${i18nRoot}cancelled`),
  ];
}

registerRefreshFunction(refreshCachedStrings, true);

export interface ITileData {
  title: string;
  type: string;
  status: string;
  state: string;
  installData?: ITileInstall;
  items?: ITileItemData[];
  detailsLink?: string;
  devices?: ISkyDevice[];
  keycode?: string;
  identifier?: string; //Used to append to HTML ids in the tileWrapper
}

export interface ISkyDevice {
  name: string;
  keycode: string;
  instanceId: string;
  isVisible: boolean;
  shields: IShield[];
  id: string;
  operatingSystem: string;
  type: string;
  osBuild: string;
  osPlatform: number | null;
  nickname: string;
  version: string;
  isActive: boolean | null;
}

export interface IShield {
  name: string;
  value: boolean;
}

export interface ITileInstall {
  logo: string;
  mainText: string;
  auxText?: string;
  protectText?: string;
  installButtonText: string;
  installText: string;
}

export interface ITileItemData {
  caption: string;
  value: number;
  maxValue?: number;
  unitType?: string;
  iconType?: string;
}

export function convertAmount(item: ITileItemData): string {
  //if the value is something custom, just return that
  let result = item.value?.toString() ?? "";
  const intlNum = Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 });
  refreshCachedStrings();

  //however, if we have a unit type, then convert amount according to the type rules
  if (item.unitType) {
    switch (item.unitType) {
      case UnitType.bytes:
      case UnitType.count:
        const scale = item.unitType == UnitType.bytes ? BYTE_SCALE : COUNT_SCALE;
        let amount = item.value ?? 0;
        while (amount >= scale) {
          amount /= scale;
        }

        // If amount is between 1000 and 1024, round up for the next unitType
        if (amount >= NOMINAL_BYTE_SCALE) {
          amount /= scale;
        }

        result = intlNum.format(amount);
        break;
      case UnitType.subscriptionState:
        result = subscriptionStateNames[item.value];
        break;
      case UnitType.shields:
        if (item.maxValue) result = `${item.value} ${divideBy} ${item.maxValue}`;
        break;
    }
  }

  return result;
}

export function convertUnitType(item: ITileItemData): string {
  let unitText = "";
  refreshCachedStrings();

  if (item.unitType) {
    switch (item.unitType) {
      case UnitType.bytes:
      case UnitType.count:
        //if it is a number that must be scaled, then look up the appropriate unit
        const scale = item.unitType == UnitType.bytes ? BYTE_SCALE : COUNT_SCALE;
        let amount = item.value ?? 0;
        let unitIndex = 0;
        while (amount >= scale && unitIndex < MAX_UNIT_NAMES) {
          amount /= scale;
          unitIndex++;
        }

        // If amount is between 1000 and 1024, display the next unitType
        if (amount >= NOMINAL_BYTE_SCALE) {
          unitIndex++;
        }
        unitText = item.unitType == UnitType.bytes ? byteUnitNames[unitIndex] : countUnitNames[unitIndex];
        break;
      case UnitType.none:
        break;
      default:
        //if the unit is something arbitrary, then look up the unit in text
        unitText = i18n.global.t(`${i18nRoot}${item.unitType}`);
        break;
    }
  }

  return unitText;
}

export function calcItemClass(item: ITileItemData): Record<string, string> {
  const ic = {};

  if (item.iconType) ic[item.iconType] = true;

  if (item.unitType == UnitType.subscriptionState) {
    switch (item.value) {
      case SubscriptionState.expired:
        ic["danger"] = true;
        break;
      case SubscriptionState.expiring:
        ic["warning"] = true;
        break;
    }
  }

  return ic;
}
