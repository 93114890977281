<template>
  <div v-if="displayCardSelector" class="mb-quarter">
    <span v-if="props.isAutoRenew">{{ tn("savedPaymentMethods") }}</span>
    <span v-else>{{ tn("payWith") }}</span>
  </div>
  <select
    v-if="displayCardSelector"
    id="select-credit-card"
    v-model="selectedCreditCard"
    class="select-css mb"
    @change="onCardSelectionChange($event)"
  >
    <option v-for="c in creditCards" :key="c.id" :value="c">
      {{
        c.cardType.toLowerCase() === "paypal"
          ? `${c.cardType} - ${c.email}`
          : `${c.cardType} ${tn("endingIn")} ${removeStars(c.lastDigits)},
        ${tn("exp")} ${formatCCDateString(c.expirationDate)}`
      }}
    </option>
    <option value="+">
      {{ tn("addPaymentMethod") }}
    </option>
  </select>
  <div v-if="props.showDetails">
    <ul
      v-if="typeof selectedCreditCard !== 'string' && selectedCreditCard && userStore.currentUser"
      id="current-credit-card-info"
      class="mb-double"
    >
      <li v-if="selectedPaymentIsPayPal" class="mb-half">
        <svg-icon-component icon="paypal" class="paypal-svg-90" />
      </li>
      <li v-if="selectedPaymentIsPayPal" class="flex-start mb">
        <div class="pr">{{ selectedCreditCard.email }}</div>
        <button
          v-if="!props.isAutoRenew && !props.isEditPaymentMethod"
          id="btnEditPayPalEmail"
          type="button"
          class="text-sm btn-link no-print"
          @click="editPayPalEmail()"
        >
          {{ t("Common.change") }}
        </button>
      </li>
      <SelectPaymentAddress v-if="showAddress" :payment-method="selectedCreditCard" />
    </ul>
    <!--Unauthenticated buyflow Payment Information-->
    <dl
      v-if="typeof selectedCreditCard !== 'string' && selectedCreditCard && !userStore.currentUser"
      id="current-credit-card-info"
      class="emphasize-dd"
    >
      <dt>{{ tn("cardNumber") }}</dt>
      <dd id="currentCardLastDigits">**** **** **** {{ selectedCreditCard.lastDigits }}</dd>
      <dt>{{ tn("cardExpiration") }}</dt>
      <dd id="currentExpirationDate">{{ selectedCreditCard.expirationDate }}</dd>
      <dt>{{ tn("cardBillingAddress") }}</dt>
      <dd>
        <ul>
          <li id="currentCityStatePostalCode">
            {{
              `${selectedCreditCard.address.cityName}, ${selectedCreditCard.address.stateOrProvince} ${selectedCreditCard.address.postalCode}`
            }}
          </li>
          <li id="currentCountry">{{ selectedCreditCard.address.country }}</li>
        </ul>
      </dd>
    </dl>
    <ShippingForm v-if="FLAGS.ENABLE_BUYFLOW_WEBROOT && props.showShippingForm && buyFlowStore.hasShippableItem" />
    <section v-if="showButtonSection()" class="constrain-65 mt">
      <div class="mb-double">
        <button
          v-if="props.isAutoRenew || props.showCancelButton"
          id="btnClose"
          class="btn-outline-primary mr-half"
          @click="goToSubscriptions()"
        >
          {{ t("Common.cancel") }}
        </button>
        <button
          v-if="props.showCheckOutButton"
          id="btnCheckOut"
          class="btn-primary"
          :disabled="typeof selectedCreditCard === 'string' || !selectedCreditCard"
          @click="checkOut()"
        >
          {{ props.btnText ? props.btnText : t("Common.reviewOrder") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { ref, onBeforeMount, PropType, computed, watch } from "vue";
import { logEvent, logException } from "@/common/logger";
import { IPaymentMethod, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { formatCCDateString } from "@/common/dateFormat";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { checkShippingFormForErrors, removeStars } from "@/components/Buy/BuyHelper";
import { useUserStore } from "@/stores/user";
import { usePaymentPageStore } from "@/stores/paymentPage";
import { FLAGS } from "@/define";
import { openTextBrands } from "@/components/Buy/BuyEnums";
import { pageState as subscriptionPageState } from "@/globalEnums";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { createPayPalAgreementToken } from "@/components/Buy/Payment/Payment";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import SelectPaymentAddress from "@/components/Buy/Payment/SelectPaymentAddress.vue";
import ShippingForm from "@/components/Buy/Payment/ShippingForm.vue";

const componentName = "BuySelectPaymentComponent";

const props = defineProps({
  paymentMethods: {
    type: Object as PropType<IPaymentMethod[]>,
    default: null,
    required: false,
  },
  renewDate: {
    type: String,
    default: "",
    required: false,
  },
  showDetails: {
    type: Boolean,
    default: false,
    required: false,
  },
  btnText: {
    type: String,
    default: "",
    required: false,
  },
  isAutoRenew: {
    type: Boolean,
    default: false,
    required: false,
  },
  showCancelButton: {
    type: Boolean,
    default: false,
    required: false,
  },
  showCheckOutButton: {
    type: Boolean,
    default: true,
  },
  subscription: {
    type: Object as PropType<ISubscription>,
    default: null,
    required: false,
  },
  brand: {
    type: Object as PropType<openTextBrands>,
    default: openTextBrands.Carbonite,
  },
  isEditPaymentMethod: {
    type: Boolean,
    default: false,
    required: false,
  },
  isOutstandingInvoice: {
    type: Boolean,
    default: false,
  },
  selectedPaymentMethodId: {
    type: String,
    default: "",
  },
  showPayPalAddress: {
    type: Boolean,
    default: true,
  },
  showShippingForm: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  "add-card",
  "selected-credit-card",
  "edit-payment-method",
  "set-auto-renew",
  "set-display-card-details-state",
  "reset-subscription-state",
  "show-cancel-button",
  "toggle-reset-flag",
  "newly-selected-payment",
]);

logEvent("created", componentName);

const buyFlowStore = useBuyFlowStore();
const userStore = useUserStore();
const paymentPageStore = usePaymentPageStore();
const selectedCreditCard = ref<IPaymentMethod | string>();
const showPayPalDetails = ref<boolean>(false);
const showButtons = computed(() => {
  return props.showCancelButton || props.showCheckOutButton;
});

const showAddress = computed(() => {
  if (selectedPaymentIsPayPal.value) {
    return props.showPayPalAddress;
  }

  return true;
});

const selectedPaymentIsPayPal = computed(() => {
  return (
    showPayPalDetails.value ||
    (typeof selectedCreditCard.value === "object" && selectedCreditCard.value?.cardType?.toLowerCase() === "paypal")
  );
});

const creditCards = computed(() => {
  const ccs: IPaymentMethod[] = [];
  if (props.paymentMethods !== null && props.paymentMethods.length > 0) {
    let paymentMethods = props.paymentMethods;
    if (
      !props.isOutstandingInvoice &&
      (FLAGS.ENABLE_BUYFLOW_PAYPAL === "false" || props?.brand === openTextBrands.Carbonite) //added same check for auto renewal scenario
    ) {
      paymentMethods = props.paymentMethods.filter(n => n.cardType.toLowerCase() !== "paypal");
    }
    const defaultCC = paymentMethods.find(card => card.defaultPaymentMethod) ?? null;
    const otherCCs = paymentMethods.filter(card => !card.defaultPaymentMethod) ?? null;
    if (defaultCC) ccs.push(defaultCC);
    for (const cc of otherCCs) {
      ccs.push(cc);
    }
  }
  return ccs;
});

const defaultOrFirstCreditCard = computed(() => {
  const defaultCC = creditCards.value.find(l => l.defaultPaymentMethod);
  if (defaultCC) {
    return defaultCC;
  }

  if (creditCards.value.length > 0) {
    return creditCards.value[0];
  }

  return undefined;
});

const displayCardSelector = computed(() => userStore.currentUser && creditCards.value.length > 0);

onBeforeMount(async () => {
  try {
    logEvent("mounting", componentName);
    if (props.isEditPaymentMethod) {
      selectedCreditCard.value = props.paymentMethods?.find(l => l.id === props.subscription.zuoraPaymentMethodId);
    }
    //Auto renew logic
    else if (props.isAutoRenew) {
      if (creditCards.value.length > 0) {
        if (props.brand === openTextBrands.Carbonite) {
          selectedCreditCard.value = defaultOrFirstCreditCard.value;
        } else {
          const cc = props.paymentMethods.find(l => l.id === props.subscription.zuoraPaymentMethodId);
          selectedCreditCard.value = cc;
        }
      } else {
        //For carbonite products paypal should not be displayed
        if (props?.brand === openTextBrands.Carbonite) {
          selectedCreditCard.value = "+";
        }
      }
    } else if (props.isOutstandingInvoice) {
      if (props.brand === openTextBrands.Webroot && props.selectedPaymentMethodId) {
        const cc = creditCards.value.find(l => l.id === props.selectedPaymentMethodId);
        if (cc) {
          selectedCreditCard.value = cc;
          return;
        }
      }
      selectedCreditCard.value = defaultOrFirstCreditCard.value;
    } else {
      //Buy Flow logic
      if (paymentPageStore.displayPaymentMethods) {
        selectedCreditCard.value = "+";
      } else {
        if (props.selectedPaymentMethodId) {
          const paymentMethod = creditCards.value.find(l => l.id === props.selectedPaymentMethodId);
          //If we find the payment method. Set it in the store and exit
          if (paymentMethod) {
            selectedCreditCard.value = paymentMethod;
            buyFlowStore.updateCreditCard(paymentMethod.id);
            return;
          }
        }
        selectedCreditCard.value = creditCards.value[0];
        buyFlowStore.updateCreditCard(selectedCreditCard.value.id);
      }
    }
  } catch (ex) {
    logException(ex as Error);
  }
});

const selectedPaymentMethodIdProp = computed(() => {
  return props.selectedPaymentMethodId;
});

watch(selectedPaymentMethodIdProp, (newId, oldId) => {
  if (newId === oldId) {
    return;
  }

  selectedCreditCard.value = creditCards.value.find(l => l.id === newId);
});

watch(selectedCreditCard, async () => {
  if (selectedCreditCard.value === "+") {
    emits("add-card", props.isEditPaymentMethod);
  } else if (typeof selectedCreditCard.value !== "string" && selectedCreditCard.value) {
    buyFlowStore.updateCreditCard(selectedCreditCard.value.id);
    emits("set-display-card-details-state");
    emits("newly-selected-payment", selectedCreditCard.value.id);
  }
});

function goToSubscriptions() {
  sessionStorage.removeItem("product_name_for_auto_renew");
  emits("reset-subscription-state", subscriptionPageState.default);
}

function onCardSelectionChange(e) {
  showPayPalDetails.value = false;
  if (e.target.value == "+") {
    paymentPageStore.setDisplayPaymentMethods(true);
  } else {
    if (
      typeof selectedCreditCard.value !== "string" &&
      selectedCreditCard?.value?.cardType.toLowerCase() === "paypal"
    ) {
      showPayPalDetails.value = true;
    }
    paymentPageStore.setDisplayPaymentMethods(false);
  }
}

async function editPayPalEmail() {
  buyFlowStore.showProcessingOrderMessage = true;
  logEvent("Initiating paypal transaction", componentName);
  try {
    const paypalAgreementTokenResponse = await createPayPalAgreementToken();
    if (paypalAgreementTokenResponse) {
      logEvent("Got the paypal agreement token, redirecting to paypal approval url", componentName);
      sessionStorage.setItem("PayPal_token_id", paypalAgreementTokenResponse.tokenId);
      sessionStorage.setItem("BuyFlow_Paypal_cart", JSON.stringify(buyFlowStore.cart));
      window.location.href = paypalAgreementTokenResponse.approvalUrl;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  } finally {
    buyFlowStore.showProcessingOrderMessage = false;
  }
}

function checkOut() {
  if (FLAGS.ENABLE_BUYFLOW_WEBROOT && !buyFlowStore.shippingMatchesBilling && buyFlowStore.hasShippableItem) {
    //If there are shipping form errors stop
    if (checkShippingFormForErrors()) {
      return;
    }
  }

  if (typeof selectedCreditCard.value !== "string" && selectedCreditCard.value) {
    if (props.isAutoRenew) {
      emits("set-auto-renew", selectedCreditCard.value?.id);
      return;
    }
    buyFlowStore.showProcessingOrderMessage = true;
    buyFlowStore.updateCreditCard(selectedCreditCard.value.id);
    logEvent(`Credit Card Id selected: ${selectedCreditCard.value.id}`, componentName);
    if (props.isEditPaymentMethod) {
      emits("edit-payment-method", props.subscription, selectedCreditCard.value.id);
    } else {
      emits("selected-credit-card", selectedCreditCard.value.id, false);
    }
  }
}
function showButtonSection() {
  return showButtons.value && (!paymentPageStore.displayPaymentMethods || props.isAutoRenew);
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
