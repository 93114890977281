<template>
  <div class="mb-double">
    <ul class="flex-start-md">
      <li class="mr-md m-auto-mobil mb-double-mobile constrain-25">
        <div class="text-center">
          <img src="@/assets/img/nextsteps-mac-1.png" alt="Screenshot of first step in downloading" />
          <h4>{{ tn("downloadInstructionsMac1") }}</h4>
        </div>
        <div v-if="showDetailsMac" id="Details1">
          <p>{{ tn("nextStepsMac1Line1") }}</p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="tn('nextStepsMac1Line2')"></p>
        </div>
      </li>
      <li class="mr-md m-auto-mobil mb-double-mobile constrain-25">
        <div class="text-center">
          <img src="@/assets/img/nextsteps-mac-2.png" alt="Screenshot of third step in downloading" />
          <h4>{{ tn("downloadInstructionsWindows2") }}</h4>
        </div>
        <div v-if="showDetailsMac" id="Details2">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="tn('nextStepsMac2Line1')"></p>
          <p>{{ tn("nextStepsMac2Line2") }}</p>
        </div>
      </li>
      <li class="m-auto-mobilconstrain-25">
        <div class="text-center">
          <img src="@/assets/img/nextsteps-mac-3.png" alt="Screenshot of first step in downloading" />
          <h4>{{ tn("downloadInstructionsWindows3") }}</h4>
        </div>
        <div v-if="showDetailsMac" id="Details2" class="mb-double-mobile">
          <p>{{ tn("nextStepsMac3Line1") }}</p>
          <p>{{ tn("nextStepsMac3Line2") }}</p>
          <p>
            <button type="button" class="btn-link" @click="goToMacKbArticle()">
              {{ tn("nextStepsMac3Line3") }}
            </button>
          </p>
        </div>
      </li>
    </ul>
    <div>
      <button id="btnDetails" type="button" class="btn-link" @click="toggleShowDetailsMac()">
        <span v-if="showDetailsMac">
          {{ tn("hideDetails") }}
          <svg-icon-component icon="chevron-up" class="icon-sm pl-quarter" />
        </span>
        <span v-else>
          {{ tn("showDetails") }}
          <svg-icon-component icon="chevron-down" class="icon-sm pl-quarter" />
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { ref } from "vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { logEvent } from "@/common/logger";

const componentName = "CarboniteNextStepsMac";
const baseComponentName = "NextSteps";
const showDetailsMac = ref<boolean>(false);
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function toggleShowDetailsMac() {
  return (showDetailsMac.value = !showDetailsMac.value);
}

function goToMacKbArticle() {
  window.open(
    "https://support.carbonite.com/articles/Personal-Mac-Installing-Carbonite-and-Initial-Backup-Selections",
    "_blank"
  );
}
</script>
