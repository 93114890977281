<template>
  <LoadingSpinner />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { logEvent, logException } from "@/common/logger";
import { useRouter } from "vue-router";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { paymentTypes, pageStates } from "@/components/Buy/BuyEnums";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { payPalCheckOutPage } from "@/globalEnums";
import { SubscriptionInformationRouteName, buyFlowRouteName } from "@/define";
import { usePaymentPageStore } from "@/stores/paymentPage";
import { pageStates as paymentPageStates } from "@/components/Buy/Payment/PaymentConfigs";
const componentName = "CancelComponent";

logEvent("created", componentName);

const router = useRouter();
const buyflowStore = useBuyFlowStore();
const paymentPageStore = usePaymentPageStore();
const siteSettingsStore = useSiteSettingsStore();

onMounted(async () => {
  try {
    logEvent("mounted", componentName);
    //Reload BuyFlow store from session
    siteSettingsStore.setSkipAccountSetup(true);
    var payPalCheckOutFrom = sessionStorage["PayPal_CheckOutPage"] ?? payPalCheckOutPage.BuyFlowPayment;

    if (payPalCheckOutFrom == payPalCheckOutPage.BuyFlowPayment) {
      buyflowStore.cart = JSON.parse(sessionStorage["BuyFlow_Paypal_cart"]);
      buyflowStore.paymentType = paymentTypes.CreditCard;
      buyflowStore.pageState = pageStates.payment;
      paymentPageStore.pageState = paymentPageStates.addCard;
      buyflowStore.paymentType = paymentTypes.PayPal;
      paymentPageStore.setDisplayPaymentMethods(true);
      // Redirect to buy Flow Page to set the cart and the redirect to Payment Page
      router.push({ name: buyFlowRouteName, query: { step: pageStates.payment, from: "Paypal" } });
    } else if (
      payPalCheckOutFrom == payPalCheckOutPage.SubscriptionInformation ||
      payPalCheckOutFrom == payPalCheckOutPage.OutstandingInvoice
    ) {
      router.push({ name: SubscriptionInformationRouteName, query: { from: "Paypal" } });
    }
  } catch (err) {
    logException(err as Error, componentName);
  }
});
onUnmounted(() => {
  logEvent("unmounted", componentName);
});
</script>
