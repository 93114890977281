let refreshFunctions: unknown[] = [];
const refreshFunctionsGlobal: unknown[] = [];

export function refreshCachedStrings() {
  refreshFunctions.forEach(item => {
    if (item instanceof Function) {
      item();
    }
  });

  refreshFunctionsGlobal.forEach(item => {
    if (item instanceof Function) {
      item();
    }
  });
}

// refreshFunction is a function which are registered and called later when localization strings refresh is needed.
// isGlobal flag determines whether refreshFunction which are registered through ts files which are done only once when the app loads/when ts files loads.
// these refreshFunctionsGlobal are not cleared until full reloads of files happen.
// Functions loaded when component(vue files) loads can be cleared out because there will be registered again when component is mounted again.
export function registerRefreshFunction(refreshFunction: unknown, isGlobal = false) {
  if (refreshFunction instanceof Function) {
    if (!isGlobal) {
      refreshFunctions.push(refreshFunction);
    } else {
      refreshFunctionsGlobal.push(refreshFunction);
    }
  }
}

export function clearRefreshFunctions() {
  refreshFunctions = [];
}
