// *************
// pagination functions
// *************

import { computed, ref } from "vue";
import { logEvent } from "@/common/logger";
import { componentName, allItems, currentCSSBGetComputerContentsRequest, addLocalValues } from "./commonFn";
import { getCurrentItemImagesAndAlerts } from "./imagePreviewFn";
import { clearSelection } from "./selectionFn";
import { getComputerContents } from "./serverComm";
import { IComputerContents } from "./interfaces";

const FIRST_PAGE = 1;
const ELLIPSIS_PAGE = -1;
const MAX_LEADING_PAGES = 3;
const MAX_TRAILING_PAGES = 3;
const MIDDLE_PAGE_START = -2;
const MIDDLE_PAGE_END = 1;

export const pageSizes = ref<number[]>([10, 25, 50, 100]);
export const currentPage = ref<number>(1);
export const currentPageSize = ref<number>(10);
export const currentPageStart = ref<number>(0);

//this is ONLY for entering/exiting cross-server backup run searches
export const storedPageSize = ref<number>(10);

//figure out how many pages
export const pages = computed(() => {
  if (allItems.value.length > 0 && allItems.value[0].TotalItems) {
    return Math.ceil(allItems.value[0].TotalItems / currentPageSize.value);
  }
  return Math.ceil(allItems.value.length / currentPageSize.value);
});

export const displayPages = computed(() => {
  const pgs: number[] = [];
  const numPages = pages.value;
  //if less than 6 pages, show all pages
  if (numPages < MAX_LEADING_PAGES + MAX_TRAILING_PAGES) {
    for (let i = 0; i < numPages; i++) pgs.push(i + 1);
  } else {
    if (currentPage.value <= MAX_LEADING_PAGES) {
      //if we're on one of the first three pages
      //show the first three (or four if page three is current)
      for (let i = 0; i <= Math.max(currentPage.value, MAX_LEADING_PAGES); i++) pgs.push(i + 1);

      pgs.push(ELLIPSIS_PAGE);
      pgs.push(numPages);
    } else if (currentPage.value > numPages - MAX_TRAILING_PAGES) {
      //if we're on one of the last three pages
      pgs.push(FIRST_PAGE);
      pgs.push(ELLIPSIS_PAGE);

      //show the last three (or four if third last page is current)
      for (
        let i = Math.min(currentPage.value - MAX_TRAILING_PAGES + 1, numPages - MAX_TRAILING_PAGES);
        i < numPages;
        i++
      )
        pgs.push(i + 1);
    } else {
      //if we're in the middle somewhere
      pgs.push(FIRST_PAGE);
      pgs.push(ELLIPSIS_PAGE);

      //the current page plus one page on either side
      for (let i = currentPage.value + MIDDLE_PAGE_START; i < currentPage.value + MIDDLE_PAGE_END; i++) pgs.push(i + 1);

      pgs.push(ELLIPSIS_PAGE);
      pgs.push(numPages);
    }
  }
  return pgs;
});

export async function pageLoad(fromPaging = false) {
  const min = (currentPage.value - 1) * currentPageSize.value;
  const max = min + currentPageSize.value;

  //If this is an SMB we need to pull the new items from the server
  if (
    fromPaging &&
    allItems.value.length > 0 &&
    allItems.value[0].IsSMB &&
    currentCSSBGetComputerContentsRequest.value
  ) {
    allItems.value = await getComputerContents(currentCSSBGetComputerContentsRequest.value as IComputerContents);
  }

  addLocalValues();
  getCurrentItemImagesAndAlerts(min, max);
  clearSelection();
  window.scrollTo({ top: 0, behavior: "auto" });
}

export function pageBack() {
  if (currentPage.value > 1) currentPage.value--;
  logEvent(`page back to ${currentPage.value}`, componentName);
  pageLoad(true);
}

export function pageForward() {
  if (currentPage.value < Math.ceil(allItems.value.length / currentPageSize.value)) currentPage.value++;
  logEvent(`page forward to ${currentPage.value}`, componentName);
  pageLoad(true);
}

export function pageSelect(page: number) {
  if (page <= 0) return;
  logEvent(`page ${page} selected`, componentName);
  currentPage.value = page;
  if (allItems.value[0].IsSMB) {
    currentPageStart.value = (page - 1) * currentPageSize.value;
  }
  pageLoad(true);
}
