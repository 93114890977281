<template>
  <div>
    <p>
      <strong>{{ tn("autoRenewOffSurveyH2") }}</strong>
    </p>
    <p>{{ tn("reasonTurnOff") }}</p>
    <ul class="tight">
      <li v-for="questionCode in surveyQuestionCodes" :key="questionCode.id" class="radio">
        <input
          :id="`radioQC${questionCode.id}`"
          v-model="selectedDisableReason"
          name="disableReason"
          type="radio"
          :value="questionCode.id"
        />
        <label :for="`radioQC${questionCode.id}`">{{ tn(questionCode.displayName) }}</label>
      </li>
      <li>
        <input
          id="otherReason"
          v-model="otherReason"
          class="constrain-65 indent"
          type="text"
          :disabled="selectedDisableReason !== 306"
          maxlength="80"
        />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "WebrootAutoRenewOffSurveyComponent";

const selectedDisableReason = ref<number>(0);

const surveyQuestionCodes = [
  { id: 301, displayName: "r301" },
  { id: 302, displayName: "r302" },
  { id: 304, displayName: "r304" },
  { id: 312, displayName: "r312" },
  { id: 313, displayName: "r313" },
  { id: 306, displayName: "r306" },
];

logEvent("created", componentName);
const otherReason = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

watch(selectedDisableReason, () => {
  emitValues();
});

watch(otherReason, () => {
  emitValues();
});

const emits = defineEmits(["values"]);

function emitValues() {
  emits("values", selectedDisableReason.value, otherReason.value);
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
