export enum cpuArchitecture {
  windows64 = "x86_64",
  window32 = "x86",
  arm64 = "ARM64",
  arm = "ARM32",
  unknown = "unknown",
}

export async function cpuDetect() {
  const data = await navigator?.userAgentData?.getHighEntropyValues(["architecture", "bitness"]);
  let platform = cpuArchitecture.unknown;
  if (data) {
    if (navigator?.userAgentData?.platform === "Windows") {
      if (data.architecture === "x86") {
        if (data.bitness === "64") {
          platform = cpuArchitecture.windows64;
        } else if (data.bitness === "32") {
          platform = cpuArchitecture.window32;
        }
      } else if (data.architecture === "arm") {
        if (data.bitness === "64") {
          platform = cpuArchitecture.arm64;
        } else if (data.bitness === "32") {
          platform = cpuArchitecture.arm;
        }
      }
    }
  }

  return platform;
}
