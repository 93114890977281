// Good ActivationCodes
// CX-01001-26790-5253, CX-01001-26800-9651
import { IValidationResult, IValidationRule, validate } from "./validator";
import i18n from "../i18n";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { alphaNumericWithHyphenRegex } from "@/globalRegex";

let activationCodeOnlyLetterAndNumbers = i18n.global.t(`CommonErrors.ActivationCodeInvalid`);

function refreshActivationCodeOnlyLetterAndNumbers() {
  activationCodeOnlyLetterAndNumbers = i18n.global.t(`CommonErrors.ActivationCodeInvalid`);
}

let rules: IValidationRule<string>[] = [];
refreshRules();

function refreshRules() {
  rules = [
    {
      name: "CommonErrors.ActivationCodeInvalid",
      test: k => alphaNumericWithHyphenRegex.test(k),
      description: activationCodeOnlyLetterAndNumbers.toString(),
    },
  ];
}

function refreshCachedString() {
  refreshActivationCodeOnlyLetterAndNumbers();
  refreshRules();
}

registerRefreshFunction(refreshCachedString, true);

export function validateActivationCode(activationCode: string): IValidationResult[] {
  return validate<string>(activationCode, rules);
}
