<template>
  <i18n-t keypath="NotificationsComponent.ValidationError_AMZSLicense" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <button class="inline-btn-link" type="button" @click="goToUrl()">
        {{ t("NotificationsComponent.ValidationError_AMZSLicense_link") }}
      </button>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";

function goToUrl() {
  window.open(getUrl("AMAZON_MEMBERSHIP_URL"), "_blank");
}
</script>
