// common object used to perform validations based on a set of rules, and then get back the keys/errors

import { t } from "@/i18n";

export interface IValidationRule<T> {
  /* functional name of the rule */
  name: string;
  /* test to perform for validation */
  test: (field: T) => boolean;
  /* description of the rule / fallback message */
  description?: string;
  /* order to display validations on screen */
  displayOrder?: number;
  // parameters for the message
  param?: Record<string, unknown>;
}

export interface IValidationResult {
  name: string;
  message?: string;
  passed?: boolean;
  displayOrder?: number;
}

//use this function if you have complex validation rules
//that cannot be expressed with reportStringErrors()
export function validate<T>(p: T, rules: IValidationRule<T>[]): IValidationResult[] {
  const retVal: IValidationResult[] = [];

  for (const r of rules) {
    const pass = r.test(p);
    let text: undefined | string = undefined;
    if (r.param) {
      text = t(r.name, r.param);
    }
    retVal.push({
      name: r.name,
      passed: pass,
      message: text || r.description || "",
      displayOrder: r.displayOrder === undefined ? 0 : r.displayOrder,
    });
  }

  return retVal;
}

//use this function if you have simple validation rules
//that is, min and max length and some single regex to use to extract invalid characters
export function reportStringErrors(str: string, fieldName: string, min: number, max: number, regex: RegExp): string {
  if ((!str && min > 0) || str.length < min || str.length >= max) {
    return t("CommonErrors.lengthOutOfRange", { fieldName: fieldName, min: min, max: max });
  }

  // Computer Names should not have the characters other than allowed in the regex
  const unMatched = str.match(regex);
  const distinctUnMatched = [...new Set(unMatched)];
  if (distinctUnMatched?.length == 0) {
    return "";
  }
  return t("CommonErrors.invalidCharacters", { invalid: distinctUnMatched.join(" ") });
}
