<template>
  <div v-if="!props.hideFooter" class="footer">
    <div class="opposite-ends-desktop mb">
      <div>
        <small @click="handleCarboniteLabelClick">{{ t("Brand.carbonite") }}:</small>
        <ul class="pipe">
          <li>
            <a id="LinkFooterPrivacyPolicy" :href="privacyUrl" target="_blank" :title="tn('privacyPolicy')">
              {{ tn("privacyPolicy") }}
            </a>
          </li>
          <li>
            <a id="LinkFooterTOS" :href="termsUrl" target="_blank" :title="tn('termsOfService')">
              {{ tn("termsOfService") }}
            </a>
          </li>
          <li>
            <a id="LinkFooterContactUs" :href="getUrl('VITE_APP_CONTACT_US')" target="_blank" :title="tn('contactUs')">
              {{ tn("contactUs") }}
            </a>
          </li>
          <li>
            <a id="LinkFooterAgent" href="https://help.carbonite.com/" target="_blank" :title="tn('agent')">
              {{ tn("agent") }}
            </a>
          </li>
          <li>
            <a href="#" class="ot-sdk-show-settings">
              {{ tn("cookieSettings") }}
            </a>
          </li>
        </ul>
      </div>
      <div>
        <!-- intentionally throw an unhandled exception outside a Vue component -->
        <small
          onclick="setTimeout(() => { throw new Error('Non-Vue Easter Egg') }, 100)"
          @click="handleWebrootLabelClick"
        >
          {{ t("Brand.webroot") }}:
        </small>
        <ul class="pipe">
          <li>
            <a
              id="LinkFooterWebrootPrivacy"
              :href="`https://www.webroot.com/${languageUrlParm}/legal/privacy`"
              target="_blank"
              :title="tn('privacyPolicy')"
            >
              {{ tn("privacyPolicy") }}
            </a>
          </li>
          <li>
            <a
              id="LinkFooterWebrootTerms"
              :href="`https://www.webroot.com/${languageUrlParm}/legal/service-terms-and-conditions`"
              target="_blank"
              :title="tn('termsOfService')"
            >
              {{ tn("termsOfService") }}
            </a>
          </li>
          <li>
            <a
              id="LinkFooterWebrootSupport"
              :href="`https://www.webroot.com/${languageUrlParm}/support/support-home`"
              target="_blank"
              :title="tn('support')"
            >
              {{ tn("support") }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div id="copyright">
      <i18n-t keypath="FooterComponent.copyright" tag="p" scope="global">
        <template #currentYear>&copy;{{ buildDate }}</template>
        <template #copyrightBrand>{{ t("Brand.opentext") }}. {{ tn("allRightsReserved") }}.</template>
      </i18n-t>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { BUILD_DATE } from "@/define";
import { useConfigStore } from "@/stores/config";
import { useUserStore } from "@/stores/user";
import { getLanguageUrlParm } from "@/common/helper";
import { getUrl } from "@/common/getUrl";

const name = "FooterComponent";

const props = defineProps({
  hideFooter: {
    type: Boolean,
  },
});

logEvent("created", name);
const userStore = useUserStore();
const termsUrl = ref("");
const privacyUrl = ref("");
const buildDate = computed(() => new Date(BUILD_DATE).getFullYear());
const languageUrlParm = computed(() => getLanguageUrlParm(userStore.selectedLanguage || "en"));

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

onMounted(async () => {
  logEvent("mounted", name);
  const configStore = useConfigStore();
  const links = configStore.config?.footerLinks;
  termsUrl.value = links?.terms ?? "";
  privacyUrl.value = links?.privacy ?? "";
});

function handleCarboniteLabelClick() {
  logEvent("Carbonite label click", "FooterComponent");
  //intentionally throw an unhandled exception inside a Vue component
  throw new Error("Vue Easter Egg");
}

function handleWebrootLabelClick() {
  logEvent("Webroot label click", "FooterComponent");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.footer {
  background-color: transparent;
  border-color: transparent;
}
small {
  display: block;
  padding-right: var(--space-quarter);
}
small,
p {
  color: var(--grey);
}
@media (min-width: 600px) {
  span,
  a,
  p {
    font-size: var(--small-text);
  }
}
@media (min-width: 960px) {
  span {
    display: block;
  }
  small {
    display: inline-block;
  }
}
@media (min-width: 1280px) {
  span {
    display: inline-block;
  }
}
</style>
