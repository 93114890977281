<template>
  <i18n-t :keypath="'NotificationsComponent.' + notification.type" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <a id="linkToSupport" :href="getUrl('VITE_APP_WEBROOT_INSTALL_SUPPORT')" target="_blank">
        {{ t(notificationMessage) }}
      </a>
    </template>
    <template #keycode>
      <span>
        <strong>
          {{ t(keycode) }}
        </strong>
      </span>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";
import { formatKeycode } from "@/common/formatKeycode";
import { getUrl } from "@/common/getUrl";

const componentName = "AddFraudKeyNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

logEvent("created", componentName);

const notificationMessage = ref<string>(props.notification?.params?.text ?? "");
const keycode = ref<string>(formatKeycode(props.notification?.params?.keycode ?? ""));
</script>
