<template>
  <svg-icon-component v-if="props.isSpinning" icon="spinner" class="spinning-icon" />
</template>

<script setup lang="ts">
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

/* used inline, like in a button */
/* we have 3 spinners: shared/Spinner.vue, shared/LoadingSpinner.vue, and shared/LoadingBox.vue */

const props = defineProps({
  isSpinning: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

svg {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
