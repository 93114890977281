<template>
  <section>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <div v-if="FLAGS.ENABLE_CDLP === 'true'">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('nextStepsWebrootAllStepsMyAccount')"></p>
      <p>{{ tn("nextStepsWebrootEstablishedP2") }}</p>
      <div class="mb">
        <button id="btn2GoToDownloadsFromFullAccount" type="button" class="btn-primary" @click="goToDownloads()">
          {{ t("Common.goToDownloads") }}
        </button>
      </div>
    </div>
    <div v-else>
      <p>
        <button id="btn1GoToDownloadsFromFullAccount" type="button" class="inline-btn-link" @click="goToDownloads()">
          {{ t("Common.visitMyAccount") }}
        </button>
        {{ tn("nextStepsWebrootEstablishedP1") }}
      </p>
      <div class="mb">
        <button id="btn2GoToDownloadsFromFullAccount" type="button" class="btn-primary" @click="goToDownloads()">
          {{ t("Common.visitMyAccount") }}
        </button>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { continueToMyAccount } from "../BuyHelper";
import { FLAGS } from "@/define";

const componentName = "WebrootNextStepsEstablished";
const baseComponentName = "NextSteps";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToDownloads() {
  continueToMyAccount();
}
</script>
