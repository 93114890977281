<template>
  <div>
    <div class="flex-start constrain-65">
      <div class="mr-half">
        <svg-icon-component icon="circle-phone" class="icon" />
      </div>
      <div id="haveQuestions">
        {{ tn("haveQuestions") }}
      </div>
    </div>
    <p class="constrain-65">
      {{ tn("description1") }}
      <strong>
        <a id="linkSalesTeamPhone" href="tel:+17208423701">720-842-3701</a>
      </strong>
      {{ tn("description2") }}
      <strong>
        <a id="linkSalesTeamTollFreePhone" href="tel:+18663506089">1-866-350-6089</a>
      </strong>
      .
    </p>
    <p v-if="buyFlowStore.cart?.orderCode && buyFlowStore.pageState !== buyFlowPageStates.email" class="mb-double">
      {{ tn("yourOrderCode") }}
      <strong>{{ buyFlowStore.cart?.orderCode }}</strong>
    </p>
  </div>
</template>
<script setup lang="ts">
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { pageStates as buyFlowPageStates } from "@/components/Buy/BuyEnums";

const componentName = "PhoneAssistance";
const buyFlowStore = useBuyFlowStore();
function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
