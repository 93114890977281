<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <span class="pl-quarter" v-html="t(`NotificationsComponent.${notification.type}`, { text: keycode })"></span>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import { INotification } from "@/stores/notifications";
import { formatKeycode } from "@/common/formatKeycode";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "AddKeycodeNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

logEvent("created", componentName);

const keycode = ref<string>(formatKeycode(props.notification?.params?.text ?? ""));
</script>
