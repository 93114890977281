<template>
  <td>
    <ul :id="props.product.id">
      <li>
        <h3 :id="`${props.product.sku}MonthlyPrice`">{{ formatMoney(monthlyPrice) }}{{ t("SelectPlan.perMonth") }}</h3>
      </li>
      <li>
        <span
          v-if="ratePlan && ratePlan.catalogPrice > ratePlan.newSubscriptionPrice"
          :id="`${props.product.sku}StrikeThroughPrice`"
          class="strike text-grey border-bottom-tight"
        >
          {{ formatMoney(ratePlan?.catalogPrice / 12 || 0) }}{{ t("SelectPlan.perMonth") }}
        </span>
      </li>
      <li>
        <small>{{ t("SelectPlan.billedAnnually") }}</small>
      </li>
    </ul>
  </td>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { computed, PropType } from "vue";
import { ISelectPlanProductDetails } from "@/components/Buy/SelectPlan/interfaces";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { formatMoney } from "@/components/Buy/commonFn";
import { logEvent } from "@/common/logger";

const componentName = "Pricing";
logEvent(componentName);
const buyFlowStore = useBuyFlowStore();

const props = defineProps({
  product: {
    type: Object as PropType<ISelectPlanProductDetails>,
    required: true,
  },
  isNewPurchase: {
    type: Boolean,
    default: true,
  },
});

//Get the 1 year ratePlan for the given product
const ratePlan = computed(() => {
  return buyFlowStore.getRatePlanInfo(buyFlowStore.getRatePlanIdBySku(props.product.sku, 12));
});

const monthlyPrice = computed(() => {
  if (ratePlan.value) {
    return props.isNewPurchase ? ratePlan.value.newSubscriptionPrice / 12 : ratePlan.value.catalogPrice / 12;
  }

  return 0;
});
</script>
