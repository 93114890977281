<template>
  <section v-if="isLoading">
    <LoadingSpinner />
  </section>
  <section v-else>
    <AddPaymentMethodComponent
      :show-submit-btn="props.showSubmitBtn"
      :form-submit-button-text="t('Common.reviewOrder')"
      :enable-captcha="false"
      :user-guid="buyFlowStore.userGuid || buyFlowStore.cart?.userGuid"
      :email="email"
      :computer-id="computerId"
      :license-key="licenseKey"
      :pre-populate-form="paymentPageStore.creditAddress"
      @submit="submit"
      @payment-method-added="zuoraCreditCardFormSuccessHandler"
      @payment-method-error="zuoraCreditCardFormFailureHandler"
    />
  </section>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { ref, onMounted, onUnmounted, computed } from "vue";
import { logEvent, logException } from "@/common/logger";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import AddPaymentMethodComponent from "@/components/shared/AddPaymentMethodComponent.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useUserStore } from "@/stores/user";
import { pageStates as paymentState } from "./PaymentConfigs";
import { usePaymentPageStore } from "@/stores/paymentPage";
import { getLicenseKeyFromCartItems } from "@/components/Buy/BuyHelper";

const componentName = "BuyAddCardComponent";
const emits = defineEmits(["payment-method-added", "payment-method-error"]);
const paymentPageStore = usePaymentPageStore();

logEvent("created", componentName);
const isLoading = ref(true);
const buyFlowStore = useBuyFlowStore();
const userStore = useUserStore();
const props = defineProps({
  showSubmitBtn: {
    type: Boolean,
    default: true,
  },
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  try {
    logEvent("mounting", componentName);
    isLoading.value = false;
  } catch (err) {
    //Need to change to logException because this catch was overwriting the one from creditCardFunctions.getCreditCardFormParams
    //where we are putting up the notification instead of redirecting
    logException(err as Error, componentName);
  }
});

onUnmounted(() => {
  logEvent("unmounted", componentName);
});

const computerId = computed(() => buyFlowStore.cart?.items[0]?.computerId ?? 0);
const email = computed(() => userStore.currentUser?.email ?? userStore.email);
const licenseKey = computed(() => {
  return getLicenseKeyFromCartItems(buyFlowStore.cart?.items || []);
});

async function zuoraCreditCardFormSuccessHandler(event, hasAddedCreditCard: boolean) {
  try {
    paymentPageStore.creditAddress = {
      creditCardAddress1: event.detail.creditCardAddress1?.replaceAll("+", " ") || "",
      creditCardAddress2: event.detail.creditCardAddress2?.replaceAll("+", " ") || "",
      creditCardPostalCode: event.detail.creditCardPostalCode?.replaceAll("+", " ") || "",
      creditCardCity: event.detail.creditCardCity?.replaceAll("+", " ") || "",
      creditCardCountry: event.detail.creditCardCountry || "",
      creditCardState: event.detail.creditCardState?.replaceAll("+", " ") || "",
      creditCardHolderName: event.detail.creditCardHolderName?.replaceAll("+", " ") || "",
    };

    buyFlowStore.updateCreditCard(event.detail.creditCardId);
    logEvent("zuoraCreditCardFormSuccessHandler", componentName, event.detail);
    emits("payment-method-added", event.detail.creditCardId, hasAddedCreditCard);

    paymentPageStore.pageState = paymentState.selectedCard;
    // TODO: update buyflow store for the next step here
  } catch (err) {
    logException(err as Error, componentName);
  }
}

async function zuoraCreditCardFormFailureHandler(error) {
  logEvent("zuoraCreditCardFormFailureHandler", componentName, error);
  emits("payment-method-error", error);
}

function submit() {
  buyFlowStore.processingOrderMessage = tn("processingOrderMessage");
  logEvent("submit", componentName, "Submit Zuora Credit Card Form");
  /* eslint-disable */
      //@ts-ignore
      Z.submit();
      /* eslint-enable */
}
</script>
