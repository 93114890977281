<template>
  <div class="flex-start-md">
    <p>
      <span>
        <svg-icon-component icon="circle-exclamation" class="icon-sm danger" />
      </span>
      <i18n-t
        keypath="NotificationsComponent.VPNBuyflowLimitSingleItemDeadEnd"
        tag="span"
        class="mr-half"
        scope="global"
      >
        <template #webrootDotCom>
          <button class="inline-btn-link" @click="openWebrootURL()">
            {{ t("Common.webrootDotCom") }}
          </button>
        </template>
      </i18n-t>
    </p>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";

function openWebrootURL() {
  window.open(getUrl("WEBROOT_HOME"), "_self");
}
</script>
