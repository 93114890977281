<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
    <li>
      <div>{{ t(`${parentComponentName}.${productCode}UnsupportedReference`) }}</div>
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepOne`) }}</p>
      <SelectorComponent :product-code="productCode" />
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";

const productCode = "WIFI";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

logEvent("created", componentName, props);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
