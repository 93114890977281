// *************
// sort functions
// *************

import { ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IRfaItem } from "./interfaces";
import { allItems, componentName, currentItems } from "./commonFn";
import { SortOrder } from "./FileAccessEnums";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { hierarchyLevel } from "./navigationFn";

export interface ISortOption {
  field: string;
  direction: SortOrder;
  label: string;
}
export const sortField = ref<string>("Name");
export const sortDirection = ref<SortOrder>(SortOrder.ascend);
export const showSortOptions = ref<boolean>(false);
export const sortOptions = ref<ISortOption[]>();

//index mapping: 0 -> backup sets, 1 -> backup runs, 2 -> directories
export const savedSortField = ref(["Name", "Timestamp", "Name"]);
export const savedSortDirection = ref([SortOrder.ascend, SortOrder.descend, SortOrder.ascend]);

export function initSortOptions(isSmb, isBackupRunsPage = false) {
  if (isSmb) {
    if (isBackupRunsPage) {
      sortOptions.value = [
        { field: "Size", direction: SortOrder.ascend, label: t(`${componentName}.sortSizeSmallFirst`) },
        { field: "Size", direction: SortOrder.descend, label: t(`${componentName}.sortSizeLargeFirst`) },
        { field: "Timestamp", direction: SortOrder.descend, label: t(`${componentName}.sortDateNewFirst`) },
        { field: "Timestamp", direction: SortOrder.ascend, label: t(`${componentName}.sortDateOldFirst`) },
      ];
    } else {
      sortOptions.value = [
        { field: "Name", direction: SortOrder.ascend, label: t(`${componentName}.sortNameAZ`) },
        { field: "Name", direction: SortOrder.descend, label: t(`${componentName}.sortNameZA`) },
        { field: "Size", direction: SortOrder.ascend, label: t(`${componentName}.sortSizeSmallFirst`) },
        { field: "Size", direction: SortOrder.descend, label: t(`${componentName}.sortSizeLargeFirst`) },
        { field: "Timestamp", direction: SortOrder.descend, label: t(`${componentName}.sortDateNewFirst`) },
        { field: "Timestamp", direction: SortOrder.ascend, label: t(`${componentName}.sortDateOldFirst`) },
      ];
    }
  } else {
    sortOptions.value = [
      { field: "Name", direction: SortOrder.ascend, label: t(`${componentName}.sortNameAZ`) },
      { field: "Name", direction: SortOrder.descend, label: t(`${componentName}.sortNameZA`) },
      { field: "Extension", direction: SortOrder.ascend, label: t(`${componentName}.sortExtensionAZ`) },
      { field: "Extension", direction: SortOrder.descend, label: t(`${componentName}.sortExtensionZA`) },
      { field: "Size", direction: SortOrder.ascend, label: t(`${componentName}.sortSizeSmallFirst`) },
      { field: "Size", direction: SortOrder.descend, label: t(`${componentName}.sortSizeLargeFirst`) },
      { field: "Modified", direction: SortOrder.descend, label: t(`${componentName}.sortDateNewFirst`) },
      { field: "Modified", direction: SortOrder.ascend, label: t(`${componentName}.sortDateOldFirst`) },
    ];
  }
}

registerRefreshFunction(initSortOptions, true);

let userRequestsShowSortOptions = false;

export function setUserRequestsShowSortOptions(show) {
  userRequestsShowSortOptions = show;
}
export function getUserRequestsShowSortOptions(): boolean {
  return userRequestsShowSortOptions;
}

// sort the currently visible items by the field indicated
// this is called each time the user switches directories but when they do that
// we don't want them to also change direction of the sort, so pass false for changeDirection in that case
export function sortItems(field: string, direction: SortOrder, force?: boolean) {
  //if the user requests the same sort again, do nothing unless we are forced to sort
  if (!force && sortField.value === field && direction === sortDirection.value) return;

  logEvent("sortItems", componentName, { field, direction });

  if (allItems.value)
    allItems.value = allItems.value.sort((a: IRfaItem, b: IRfaItem) => {
      let af = a[field];
      let bf = b[field];
      if (typeof af == "string") af = (af as string).toLocaleLowerCase();
      if (typeof bf == "string") bf = (bf as string).toLocaleLowerCase();
      if (af > bf) return direction;
      if (af < bf) return -direction;
      return 0;
    });

  //remember the column being sorted
  sortField.value = field;
  sortDirection.value = direction;
  showSortOptions.value = false;

  //update the display with the new sort order
  currentItems.value = allItems.value.slice(0, currentItems.value.length);
}

export async function sortItemsSmb(field: string, direction: SortOrder, force?: boolean) {
  //if the user requests the same sort again, do nothing unless we are forced to sort
  if (!force && sortField.value === field && direction === sortDirection.value) return;

  logEvent("sortItems", componentName, { field, direction });

  //remember values for each level (backup run, backup set, and directory)
  savedSortField.value[hierarchyLevel.value] = field;
  savedSortDirection.value[hierarchyLevel.value] = direction;
  //actual values
  sortField.value = field;
  sortDirection.value = direction;
  showSortOptions.value = false;
}

export function toggleShowSortOptions() {
  showSortOptions.value = !showSortOptions.value;
  setUserRequestsShowSortOptions(showSortOptions.value);
}
