<template>
  <article class="mb-double">
    <svg-icon-component class="mb" icon="carb-logo" />
    <ul class="disc mb">
      <li>{{ tn("carboniteSafeOs") }}</li>
      <li>{{ tn("carboniteMobile") }}</li>
    </ul>
    <div class="support-wrapper">
      <div class="search-container mb">
        <p id="pCarboniteDescription">{{ tn("findHelpCarbText") }}</p>
        <form @submit.prevent>
          <div class="input-btn-set-md width-half-lg">
            <div class="input-wrapper">
              <input
                id="searchCarbonite"
                v-model="carbSearchText"
                type="text"
                :placeholder="tn('searchKnowledge')"
                @keyup.enter="searchCarbonite"
              />
            </div>
            <div>
              <button id="btnSearchCarbonite" type="button" class="btn-primary" @click="searchCarbonite">
                {{ tn("search") }}
              </button>
            </div>
          </div>
        </form>
        <div class="popular-searches mt-quarter">
          {{ tn("popularSearches") }}:
          <span v-for="(item, index) in carbSearchesKeys" :key="item">
            <button
              :id="`btnCarbonitePopularSearch${index}`"
              class="inline-btn-link"
              type="button"
              @click="goLink('carb', index)"
            >
              {{ tn(item) }}
            </button>
            <em v-if="index != carbSearches.length - 1" class="only-show-desktop-inline">,&nbsp;</em>
          </span>
        </div>
      </div>
      <div>
        <h2 id="pCarboniteCustomerSupport" class="mb-half">{{ tn("carboniteCustomerSupport") }}</h2>
        <div>
          <button id="goCarbonite" class="inline-btn-link" @click="goCarbonite">
            {{ tn("carboniteSupportText") }}
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "CarboniteSupportSectionComponent";
const carbSupportSite = "https://support.carbonite.com";

logEvent("created", componentName);
const carbSearchText = ref<string>("");
const carbSearches = ["Reinstall Carbonite", "Restore files to new computer", "Renew subscription"];
const carbSearchesKeys = ["reinstallCarbonite", "restoreFiles", "renewSubscription"];

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function searchCarbonite() {
  logEvent("Search Carbonite", componentName, { searchText: carbSearchText.value });
  window.open(
    `${carbSupportSite}/search/${carbSearchText.value}?&utm_campaign=portalSearch&utm_term=${carbSearchText.value}`
  );
  return false;
}

function goLink(linkType: string, linkIndex: number) {
  logEvent("Click static help link", componentName, { linkType, linkIndex });
  if (linkType === "carb") {
    carbSearchText.value = carbSearches[linkIndex];
    searchCarbonite();
    carbSearchText.value = "";
  }
}

function goCarbonite() {
  logEvent("Go to Carb Knowledge base", componentName);
  window.open(`${carbSupportSite}`);
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.popular-searches span {
  display: block;
  margin: var(--space) 0 var(--space) var(--space);
}

@media (min-width: 600px) {
  .popular-searches {
    font-size: var(--small-text);
  }
  .popular-searches span {
    display: inline-block;
    margin: 0;
  }
}
</style>
