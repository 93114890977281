import { IValidationRule, validate } from "@/common/validator";
import i18n, { t } from "@/i18n";

// Regex
const namesRegex = "^[a-zA-Z\\s\\-\\'\\.]+$"; // Used by FirstName, LastName
const cityRegex = "^[a-zA-Z\\s\\-\\.]+$"; // Used by City
const addressRegex = "^[0-9A-Za-z\\s\\-\\'\\.\\#\\/\\,\\(\\)]+$"; // Used by Address 1 and Address 2
const zipRegex = "^[0-9]+$";

// Lengths
const MIN_NAME_LENGTH = 1;
const MAX_NAME_LENGTH = 50;
const PHONE_LENGTH = 10;
const MIN_ADDRESS_LENGTH = 3;
const MAX_ADDRESS_LENGTH = 60;
const MIN_CITY_LENGTH = 2;
const MAX_CITY_LENGTH = 50;
const STATE_LENGTH = 2;
const ZIP_LENGTH = 5;

// Rules
const firstNameRules: IValidationRule<string>[] = [
  {
    name: "AllstateFormErrors.firstNameLengthMin",
    test: e => e.trim()?.length >= MIN_NAME_LENGTH,
  },
  {
    name: "AllstateFormErrors.firstNameLengthMax",
    test: e => e.trim()?.length <= MAX_NAME_LENGTH,
  },
  { name: "AllstateFormErrors.firstNameInvalidCharacter", test: e => new RegExp(namesRegex).test(e) },
];

const lastNameRules: IValidationRule<string>[] = [
  {
    name: "AllstateFormErrors.lastNameLengthMin",
    test: e => e.trim()?.length >= MIN_NAME_LENGTH,
  },
  {
    name: "AllstateFormErrors.lastNameLengthMax",
    test: e => e.trim()?.length <= MAX_NAME_LENGTH,
  },
  { name: "AllstateFormErrors.lastNameInvalidCharacter", test: e => new RegExp(namesRegex).test(e) },
];

const phoneRules: IValidationRule<string>[] = [
  {
    name: "AllstateFormErrors.phoneLength",
    test: e => e?.length == PHONE_LENGTH,
  },
];

const addressRules: IValidationRule<string>[] = [
  {
    name: "AllstateFormErrors.addressLength",
    test: e => e.trim()?.length >= MIN_ADDRESS_LENGTH && e?.length <= MAX_ADDRESS_LENGTH,
  },
  { name: "AllstateFormErrors.addressInvalidCharacter", test: e => new RegExp(addressRegex).test(e) },
];

const cityRules: IValidationRule<string>[] = [
  {
    name: "AllstateFormErrors.cityLength",
    test: e => e.trim()?.length >= MIN_CITY_LENGTH && e?.length <= MAX_CITY_LENGTH,
  },
  { name: "AllstateFormErrors.cityInvalidCharacter", test: e => new RegExp(cityRegex).test(e) },
];

const stateRules: IValidationRule<string>[] = [
  {
    name: "AllstateFormErrors.stateLength",
    test: e => e?.length == STATE_LENGTH,
  },
];

const zipRules: IValidationRule<string>[] = [
  { name: "AllstateFormErrors.zipNumbersOnly", test: e => new RegExp(zipRegex).test(e) },
  {
    name: "AllstateFormErrors.zipLength",
    test: e => e?.length == ZIP_LENGTH,
  },
];

export function isFirstNameValid(firstName: string): string[] {
  // Required - Only letters - 1 to 50 characters
  return validateAndDisplay<string>(firstName, firstNameRules);
}

export function isLastNameValid(lastName: string): string[] {
  // Required - Only letters - 1 to 50 characters
  return validateAndDisplay<string>(lastName, lastNameRules);
}

export function isPhoneValid(phone: string): string[] {
  // Required - should be on MM/DD/YYYY format - 10 digits;
  return validateAndDisplay<string>(phone, phoneRules);
}

export function isAddress1Valid(address1: string): string[] {
  // Required - Letters, numbers, spaces and some symbols - 3 to 60 characters
  return validateAndDisplay<string>(address1, addressRules);
}

export function isAddress2Valid(address2: string): string[] {
  // Required - Letters, numbers, spaces and some symbols - 3 to 60 characters
  return validateAndDisplay<string>(address2, addressRules);
}

export function isCityValid(city: string): string[] {
  // Required - Letters only - 2 to 50 characters
  return validateAndDisplay<string>(city, cityRules);
}

export function isStateValid(state: string): string[] {
  // Required - Letters only - 2 characters
  return validateAndDisplay<string>(state, stateRules);
}

export function isZipValid(zip: string): string[] {
  // Required - Numbers only - 5 characters
  return validateAndDisplay<string>(zip, zipRules);
}

function validateAndDisplay<T>(property: T, rules: IValidationRule<T>[]): string[] {
  const results = validate<T>(property, rules);
  const messages: string[] = [];
  for (const result of results.filter(r => !r.passed)) {
    let message = t(`${result.name}`);
    if (result.name.includes("InvalidCharacter")) {
      const validCharactersRegex = new RegExp(getRegex(result.name).replace("^", "").replace("+$", ""), "gi");
      const value = typeof property === "string" ? property.toString() : "";
      const invalidCharactersArray = [...value.replace(validCharactersRegex, "")];
      const invalidCharacters = invalidCharactersArray.filter(onlyUnique).join(", ");
      message = i18n.global.t(result.name, [invalidCharacters]);
    }
    messages.push(message);
  }
  return messages;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function getRegex(name: string) {
  if (name.includes("Name")) return namesRegex;
  else if (name.includes("address")) return addressRegex;
  else if (name.includes("city")) return cityRegex;
  return "";
}
