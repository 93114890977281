<template>
  <h1>{{ tn("shipping") }}</h1>
  <div v-if="props.showCheckBox" class="checkbox mb-double constrain-65">
    <input id="checkboxUseBillingAddress" v-model="buyFlowStore.shippingMatchesBilling" type="checkbox" />
    <label for="checkboxUseBillingAddress">{{ tn("sameAddressLabel") }}</label>
  </div>
  <ul v-if="!buyFlowStore.shippingMatchesBilling" class="form tight constrain-65">
    <li>
      <label for="shippingFirstName">{{ tn("firstName") }}</label>
      <InputErrorComponent :show="showErrors.firstName && showErrors.firstName.length > 0">
        <input
          id="shippingFirstName"
          v-model="buyFlowStore.shippingAddress.firstName"
          type="text"
          @input="validateShippingFormFields('firstName')"
          @change="validateShippingFormFields('firstName')"
        />
        <template #error>
          <div>
            <span id="showFirstNameError" :key="showErrors.firstName[0]">{{ showErrors.firstName[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li>
      <label for="shippingLastName">{{ tn("lastName") }}</label>
      <InputErrorComponent :show="showErrors.lastName && showErrors.lastName.length > 0">
        <input
          id="shippingLastName"
          v-model="buyFlowStore.shippingAddress.lastName"
          type="text"
          @input="validateShippingFormFields('lastName')"
          @change="validateShippingFormFields('lastName')"
        />
        <template #error>
          <div>
            <span id="showLastNameError" :key="showErrors.lastName[0]">{{ showErrors.lastName[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li>
      <label for="shippingCountry">{{ tn("country") }}</label>
      <InputErrorComponent :show="showErrors.country && showErrors.country.length > 0">
        <select
          id="shippingCountry"
          v-model="buyFlowStore.shippingAddress.country"
          class="select-css mr-half mb-half-mobile"
        >
          <option v-for="country in countriesList" :key="country.countryName" :value="country.countryName">
            {{ country.countryName }}
          </option>
        </select>
        <template #error>
          <div>
            <span id="showCountryError">{{ t("CommonErrors.requiredFieldError") }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li v-if="statesAndProvinces.length > 0">
      <label for="shippingState">{{ tn("state") }}</label>
      <InputErrorComponent :show="showErrors.state && showErrors.state.length > 0">
        <select
          id="shippingState"
          v-model="buyFlowStore.shippingAddress.state"
          class="select-css mr-half mb-half-mobile"
          @change="validateShippingFormFields('state')"
        >
          <option key="selectOne" value="">{{ tn("selectOne") }}</option>
          <option v-for="item in statesAndProvinces" :key="item.value" :value="item.text">{{ item.text }}</option>
        </select>
        <template #error>
          <div>
            <span id="showStateError" :key="showErrors.state[0]">{{ showErrors.state[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li v-else>
      <label for="shippingState">{{ tn("state") }}</label>
      <InputErrorComponent :show="showErrors.state && showErrors.state.length > 0">
        <input
          id="shippingState"
          v-model="buyFlowStore.shippingAddress.state"
          type="text"
          @input="validateShippingFormFields('state')"
          @change="validateShippingFormFields('state')"
        />
        <template #error>
          <div>
            <span id="showStateError" :key="showErrors.state[0]">{{ showErrors.state[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li>
      <label for="shippingAddress">{{ tn("address") }}</label>
      <InputErrorComponent :show="showErrors.address1 && showErrors.address1.length > 0">
        <input
          id="shippingAddress"
          v-model="buyFlowStore.shippingAddress.address1"
          type="text"
          @input="validateShippingFormFields('address1')"
          @change="validateShippingFormFields('address1')"
        />
        <template #error>
          <div>
            <span id="showAddress1Error" :key="showErrors.address1[0]">{{ showErrors.address1[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li>
      <label for="shippingAddress2">{{ tn("address2") }}</label>
      <input id="shippingAddress2" v-model="buyFlowStore.shippingAddress.address2" type="text" />
    </li>

    <li>
      <label for="shippingCity">{{ tn("city") }}</label>
      <InputErrorComponent :show="showErrors.city && showErrors.city.length > 0">
        <input
          id="shippingCity"
          v-model="buyFlowStore.shippingAddress.city"
          type="text"
          @input="validateShippingFormFields('city')"
          @change="validateShippingFormFields('city')"
        />
        <template #error>
          <div>
            <span id="showCityError" :key="showErrors.city[0]">{{ showErrors.city[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>

    <li class="pb">
      <label for="shippingZip">{{ tn("zip") }}</label>
      <InputErrorComponent :show="showErrors.zipCode && showErrors.zipCode.length > 0">
        <input
          id="shippingZip"
          v-model="buyFlowStore.shippingAddress.zipCode"
          type="text"
          @input="validateShippingFormFields('zipCode')"
          @change="validateShippingFormFields('zipCode')"
        />
        <template #error>
          <div>
            <span id="showZipError" :key="showErrors.zipCode[0]">{{ showErrors.zipCode[0] }}</span>
          </div>
        </template>
      </InputErrorComponent>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { unifiedApi } from "@/common";
import { ICountry } from "@/common/api/unifiedPortal/interfaces";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { computed, onBeforeMount, ref, watch } from "vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import { shippingStatesAndProvinces, IStateAndProvinceItem } from "@/common/ShippingStatesAndProvinces";
import { validateShippingFormFields } from "@/components/Buy/BuyHelper";
import { UNITED_STATES_COUNTRY_CODE } from "@/define";

const componentName = "ShippingForm";
const buyFlowStore = useBuyFlowStore();
const countriesList = ref<ICountry[] | null>();
const showErrors = computed(() => {
  return buyFlowStore.showShippingFormErrors;
});

const statesAndProvinces = computed<IStateAndProvinceItem[]>(() => {
  const values = shippingStatesAndProvinces[buyFlowStore.shippingAddress.country];

  if (values && values.length > 0) {
    return values;
  }
  return [];
});

const props = defineProps({
  showCheckBox: {
    type: Boolean,
    default: true,
  },
});

logEvent("created", componentName);

onBeforeMount(async () => {
  const res = (await unifiedApi.getCountries()).data;
  countriesList.value = res?.countries;
  if (!buyFlowStore.shippingAddress.country) {
    buyFlowStore.shippingAddress.country =
      countriesList?.value?.find(x => x.countryCode === UNITED_STATES_COUNTRY_CODE)?.countryName ?? "";
  }
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

watch(
  () => buyFlowStore.$state.shippingAddress.country,
  () => {
    //clear the state when the country changes
    buyFlowStore.shippingAddress.state = "";
  }
);
</script>
