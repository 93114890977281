export const windows = "Windows";
export const windowsDisplayName = "Windows (x64/x86)";
export const windowsArm = "Arm";
export const windowsArmDisplayName = "Windows (ARM)";
export const mac = "Mac";
export const chromeOS = "ChromeOS";
export const linux = "Linux";
export const android = "Android";
export const iOS = "iOS";
export const chrome = "Chrome";
export const edge = "Edge";
export const firefox = "Firefox";
export const googleChrome = "Google Chrome";
export const microsoftEdge = "Microsoft Edge";
export const mozillaFirefox = "Mozilla Firefox";
export const png = "png";
export const svg = "svg";
const CARB = "CARB";
const SAFE = "SAFE";
const downloadsComponent = "DownloadProductComponent";

import { allstateLCNs, webrootLicenseCategories } from "./webrootProductName";
import { productSubGroups, productType } from "@/globalEnums";
import { t } from "@/i18n";
import { getUrl } from "./getUrl";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { globalFeatures } from "@/common/featureDisplayAccess";

export enum downloadListComponents {
  LastPass = "LastPass",
  AllState = "AllState",
}

export interface IProduct {
  code: string;
  name: string;
  localizedName: string;
  image: string;
  imageType: string;
  productType: productType;
  buttonCaption: string;
  targetSystem: ITargetSystem[];
  licenseCategories: string[];
  supportedOS: string[];
  supportURL: string;
  supportURLText: string;
  featureName: globalFeatures;
  sortOrder: number;
  subGroup: productSubGroups;
  componentName?: downloadListComponents;
}

export interface ITargetSystem {
  familyName: string;
  fullName: string;
  link: string;
  icon: string;
  bbyLink: string;
  retailLink: string;
  versionKey?: string;
}

let safeRestoreSupportUrl = getUrl("VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT");
let webrootInstallSupportUrl = getUrl("VITE_APP_WEBROOT_INSTALL_SUPPORT");
let wpcoInstallSupportUrl = getUrl("VITE_APP_WPCO_INSTALL_SUPPORT");

function refreshSupportUrls() {
  safeRestoreSupportUrl = getUrl("VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT");
  webrootInstallSupportUrl = getUrl("VITE_APP_WEBROOT_INSTALL_SUPPORT");
  wpcoInstallSupportUrl = getUrl("VITE_APP_WPCO_INSTALL_SUPPORT");
}

registerRefreshFunction(refreshSupportUrls, true);

export function productList(): IProduct[] {
  return [
    {
      code: "WTP",
      name: "Webroot Total Protection",
      localizedName: "Brand.WebrootTotalProtection",
      image: "webroot-total-protection",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 1,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "mac_link",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [webrootLicenseCategories.WTP, webrootLicenseCategories.WTPF],
      supportedOS: [windows, mac],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WebrootBackupAndRestore",
      name: "Webroot Backup + Restore",
      localizedName: "Brand.WebrootBackupAndRestore",
      image: "webroot-backup-and-restore",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGoToBackup",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 2,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "mac_link",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [webrootLicenseCategories.WTP, webrootLicenseCategories.WTPF],
      supportedOS: [windows, mac],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WWTS",
      name: "Webroot Web Threat Shield Browser Extension",
      localizedName: "Brand.WebrootWTSBE",
      image: "webroot-threat-shield",
      imageType: svg,
      productType: productType.Browser,
      buttonCaption: "btnGetTheExtension",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 3,
      targetSystem: [
        {
          familyName: chrome,
          fullName: googleChrome,
          link: "https://chrome.google.com/webstore/detail/web-threat-shield/kjeghcllfecehndceplomkocgfbklffd?utm_source=chrome-ntp-icon",
          icon: "chrome",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: edge,
          fullName: microsoftEdge,
          link: "https://microsoftedge.microsoft.com/addons/detail/web-threat-shield/fmkaflbamgddpjacdmjlkhbnpnlemaea",
          icon: "edge",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: firefox,
          fullName: mozillaFirefox,
          link: "https://addons.mozilla.org/en-US/firefox/addon/web-threat-shield/",
          icon: "firefox",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [
        CARB,
        webrootLicenseCategories.WSAV,
        webrootLicenseCategories.WSAI,
        webrootLicenseCategories.WSAC,
        webrootLicenseCategories.WSAFMPK,
        webrootLicenseCategories.WSAG,
        webrootLicenseCategories.WSCB,
        webrootLicenseCategories.WSAP,
        webrootLicenseCategories.WSAPF,
        webrootLicenseCategories.WSAIA,
        webrootLicenseCategories.WTP,
        webrootLicenseCategories.WTPF,
        webrootLicenseCategories.WE,
      ],
      supportedOS: [windows, mac, chromeOS],
      supportURL: webrootInstallSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "SAFE",
      name: "Carbonite Safe Cloud Backup",
      localizedName: "Brand.carboniteSafeCloudBackup",
      image: "carbonite-safe-cloud-backup",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGoToBackup",
      featureName: globalFeatures.downloadCarbonite,
      sortOrder: 4,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "mac_link",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [SAFE],
      supportedOS: [windows, mac],
      supportURL: safeRestoreSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WSAP",
      name: "Webroot AntiVirus",
      localizedName: "Brand.WebrootAntiVirus",
      image: "webroot-internet-security",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "downloadTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 5,
      targetSystem: [
        {
          familyName: windowsArm,
          fullName: windowsArmDisplayName,
          link: "https://anywhere.webrootcloudav.com/zerol/wsaarm.exe",
          icon: "windows",
          bbyLink: "https://anywhere.webrootcloudav.com/zerol/wsaarmbbs2.exe",
          retailLink: "https://anywhere.webrootcloudav.com/zerol/wsaretail.exe", //This is not the correct URL
        },
        {
          familyName: windows,
          fullName: windowsDisplayName,
          link: "https://anywhere.webrootcloudav.com/zerol/wsainstall.exe",
          icon: "windows",
          bbyLink: "https://anywhere.webrootcloudav.com/zerol/wsabbs2.exe",
          retailLink: "https://anywhere.webrootcloudav.com/zerol/wsaretail.exe",
        },
        {
          familyName: mac,
          fullName: "macOS",
          link: "https://mac.webrootmultiplatform.com/production/wsa-mac/versions/latest/WSAMAC.pkg",
          icon: "apple",
          bbyLink: "https://anywhere.webrootcloudav.com/zerol/wsamacbbs2.dmg",
          retailLink: "https://anywhere.webrootcloudav.com/zerol/wsamacretail.dmg",
          versionKey: "MacWSAPVersion",
        },
      ],
      licenseCategories: [
        CARB,
        webrootLicenseCategories.WSAV,
        webrootLicenseCategories.WSAI,
        webrootLicenseCategories.WSAC,
        webrootLicenseCategories.WSAFMPK,
        webrootLicenseCategories.WSAG,
        webrootLicenseCategories.WSAP,
        webrootLicenseCategories.WSAPF,
        webrootLicenseCategories.WSAIA,
        webrootLicenseCategories.WE,
      ],
      supportedOS: [windowsArm, windows, mac],
      supportURL: webrootInstallSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WSVPN",
      name: "Webroot Secure VPN",
      localizedName: "Brand.WebrootSecureVPNP",
      image: "webroot-secure-vpn",
      imageType: svg,
      productType: productType.Undefined,
      buttonCaption: "downloadTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 6,
      targetSystem: [
        {
          familyName: windows,
          fullName: windowsDisplayName,
          link: "/Webroot_Secure_VPN_Setup.exe",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "/SecureVPN.pkg",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [webrootLicenseCategories.WSVPN, webrootLicenseCategories.WTP, webrootLicenseCategories.WTPF],
      supportedOS: [windows, mac, windowsArm],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WSCB",
      name: "Webroot Security for Chromebook",
      localizedName: "Brand.WSCB",
      image: "webroot-for-chromebook",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 7,
      targetSystem: [
        {
          familyName: chromeOS,
          fullName: chromeOS,
          link: "https://play.google.com/store/apps/details?id=com.webroot.chromebook",
          icon: "chrome",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [
        webrootLicenseCategories.WSAI,
        webrootLicenseCategories.WSAC,
        webrootLicenseCategories.WSAFMPK,
        webrootLicenseCategories.WSCB,
        webrootLicenseCategories.WSAP,
        webrootLicenseCategories.WSAPF,
        webrootLicenseCategories.WSAIA,
      ],
      supportedOS: [windows, mac, android, iOS, chromeOS, linux],
      supportURL: "",
      supportURLText: t(`${downloadsComponent}.AboutSecurityMobileLogin`),
      subGroup: productSubGroups.GetStarted,
    },

    {
      code: "WSASB",
      name: "Webroot Small Business Antivirus",
      localizedName: "Brand.WebrootSmallBusinessAntiVirus",
      image: "webroot-small-business-antivirus",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 8,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "mac_link",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [],
      supportedOS: [windows, mac],
      supportURL: webrootInstallSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WW",
      name: "Window Washer",
      localizedName: "Brand.WindowWasher",
      image: "window-washer",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 9,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "mac_link",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [],
      supportedOS: [windows, mac],
      supportURL: webrootInstallSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WPCO",
      name: "Webroot PC Optimizer",
      localizedName: "Brand.WebrootPcOptimizer",
      image: "webroot-pc-optimizer",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "downloadTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 10,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [webrootLicenseCategories.WPCO],
      supportedOS: [windows],
      supportURL: wpcoInstallSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "WIFI",
      name: "Webroot WiFi Security VPN",
      localizedName: "Brand.WIFI",
      image: "webroot-wifi-security",
      imageType: svg,
      productType: productType.Browser,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 11,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "https://anywhere.webrootcloudav.com/zerol/webrootwifisecurity.exe",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: "macOS",
          link: "https://anywhere.webrootcloudav.com/zerol/webrootwifisecurity.dmg",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
          versionKey: "MacWSAPVersion",
        },
        {
          familyName: android,
          fullName: android,
          link: "https://play.google.com/store/apps/details?id=com.webroot.mobile.wifisecurity",
          icon: "android",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: iOS,
          fullName: iOS,
          link: "https://apps.apple.com/us/app/webroot-wifi-security-vpn/id1431626168",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [webrootLicenseCategories.WIFI],
      supportedOS: [windows, mac, android, iOS, chromeOS, linux],
      supportURL: "",
      supportURLText: t(`${downloadsComponent}.AboutSecurityMobileLogin`),
      subGroup: productSubGroups.GetStarted,
    },
    {
      code: "LPPM",
      name: "LastPass® Password Manager",
      localizedName: "Brand.lastPassFullName",
      image: "lastPass-icon",
      imageType: svg,
      productType: productType.Undefined,
      buttonCaption: "btnActivate",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 12,
      targetSystem: [
        {
          familyName: android,
          fullName: android,
          link: "https://play.google.com/store/apps/details?id=com.lastpass.lpandroid",
          icon: "android",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: iOS,
          fullName: iOS,
          link: "https://apps.apple.com/us/app/lastpass-password-manager/id324613447",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: chrome,
          fullName: googleChrome,
          link: "https://chrome.google.com/webstore/detail/lastpass-free-password-ma/hdokiejnpimakedhajhdlcegeplioahd",
          icon: "chrome",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: edge,
          fullName: microsoftEdge,
          link: "https://microsoftedge.microsoft.com/addons/detail/lastpass-free-password-m/bbcinlkgjjkejfdpemiealijmmooekmp",
          icon: "edge",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: firefox,
          fullName: mozillaFirefox,
          link: "https://addons.mozilla.org/en-US/firefox/addon/lastpass-password-manager/",
          icon: "firefox",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [
        webrootLicenseCategories.WSAC,
        webrootLicenseCategories.WSAFMPK,
        webrootLicenseCategories.WSAI,
        webrootLicenseCategories.WSAP,
        webrootLicenseCategories.WSAPF,
        webrootLicenseCategories.WSAIA,
        webrootLicenseCategories.WTP,
        webrootLicenseCategories.WTPF,
        webrootLicenseCategories.WE,
      ],
      supportedOS: [windows, mac, android, iOS, chromeOS, linux],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.SetUpKeyFeatures,
      componentName: downloadListComponents.LastPass,
    },
    {
      code: "ALLSTATE",
      name: "Allstate® Identity Protection",
      localizedName: "Brand.allStateFullName",
      image: "",
      imageType: svg,
      productType: productType.Undefined,
      buttonCaption: "",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 13,
      targetSystem: [],
      licenseCategories: [...allstateLCNs],
      supportedOS: [],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.SetUpKeyFeatures,
      componentName: downloadListComponents.AllState,
    },
    {
      code: "ADP",
      name: "Webroot Security & Antivirus Premier",
      localizedName: "Brand.AndroidMobile",
      image: "webroot-security-antivirus-premier",
      imageType: svg,
      productType: productType.Desktop,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 14,
      targetSystem: [
        {
          familyName: windows,
          fullName: windows,
          link: "windows_link",
          icon: "windows",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: mac,
          fullName: mac,
          link: "mac_link",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [],
      supportedOS: [windows, mac],
      supportURL: webrootInstallSupportUrl,
      supportURLText: t(`${downloadsComponent}.linkFooterNeedHelp`),
      subGroup: productSubGroups.MobileApps,
    },
    {
      code: "WRMS",
      name: "Webroot Mobile Security",
      localizedName: "Brand.WebrootMS",
      image: "webroot-mobile-security",
      imageType: svg,
      productType: productType.Mobile,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 15,
      targetSystem: [
        {
          familyName: android,
          fullName: android,
          link: "https://play.google.com/store/apps/details?id=com.webroot.wms",
          icon: "android",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: iOS,
          fullName: iOS,
          link: "https://apps.apple.com/us/app/webroot-secureweb-browser/id460986853",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [
        webrootLicenseCategories.WSAI,
        webrootLicenseCategories.WSAC,
        webrootLicenseCategories.WSAFMPK,
        webrootLicenseCategories.WSAP,
        webrootLicenseCategories.WSAPF,
        webrootLicenseCategories.WSAIA,
        webrootLicenseCategories.WTP,
        webrootLicenseCategories.WTPF,
        webrootLicenseCategories.WE,
      ],
      supportedOS: [windows, mac, android, iOS, chromeOS, linux],
      supportURL: "",
      supportURLText: t(`${downloadsComponent}.AboutSecurityMobileLogin`),
      subGroup: productSubGroups.MobileApps,
    },
    {
      code: "WSVPNM",
      name: "Webroot Secure VPN Mobile",
      localizedName: "Brand.WebrootSecureVPNP",
      image: "webroot-secure-vpn",
      imageType: svg,
      productType: productType.Undefined,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadWebrootApps,
      sortOrder: 16,
      targetSystem: [
        {
          familyName: android,
          fullName: android,
          link: "https://www.webroot.com/android-vpn",
          icon: "android",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: iOS,
          fullName: iOS,
          link: "https://www.webroot.com/iOS-vpn",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [webrootLicenseCategories.WSVPN, webrootLicenseCategories.WTP, webrootLicenseCategories.WTPF],
      supportedOS: [windows, mac, android, iOS, chromeOS, linux],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.MobileApps,
    },
    {
      code: "SAFEMOBILE",
      name: "Carbonite Mobile",
      localizedName: "Brand.carboniteMobile",
      image: "carbonite-mobile",
      imageType: svg,
      productType: productType.Mobile,
      buttonCaption: "btnGetTheApp",
      featureName: globalFeatures.downloadCarbonite,
      sortOrder: 17,
      targetSystem: [
        {
          familyName: android,
          fullName: android,
          link: "https://play.google.com/store/apps/details?id=com.carbonite.android",
          icon: "android",
          bbyLink: "",
          retailLink: "",
        },
        {
          familyName: iOS,
          fullName: iOS,
          link: "https://apps.apple.com/us/app/carbonite-mobile/id347953228",
          icon: "apple",
          bbyLink: "",
          retailLink: "",
        },
      ],
      licenseCategories: [SAFE],
      supportedOS: [android, iOS],
      supportURL: "",
      supportURLText: "",
      subGroup: productSubGroups.MobileApps,
    },
  ];
}
