import {
  ISkyWebrootDevice,
  ISubscriberDownloadsInfo,
  IWebrootDeviceResult,
} from "@/common/api/unifiedPortal/interfaces";
import { StatusType } from "@/globalEnums";

export const criticalShields = ["realtimeShield"];

export function getAllWebrootDevices(subscriberInfo: ISubscriberDownloadsInfo) {
  let allDevices: ISkyWebrootDevice[] = [];

  subscriberInfo.webrootSubscriptions.forEach(sub => {
    if (sub.devices && sub.devices.length > 0) {
      allDevices = [...allDevices, ...sub.devices];
    }
  });

  return allDevices;
}

export function calculateStatus(results: IWebrootDeviceResult[]) {
  const devices: ISkyWebrootDevice[] = [];
  for (const result of results) {
    if (result.devices) {
      for (const device of result.devices) {
        devices.push(device);
      }
    }
  }

  const state = devices.some(d => !d.shields.realtimeShield)
    ? StatusType.danger
    : devices.some(
          d =>
            d.shields.phishingShield === false || // forcing false here, since the shields can be null
            d.shields.rootkitShield === false ||
            d.shields.webShield === false ||
            d.shields.usbShield === false ||
            d.shields.offlineShield === false ||
            d.shields.identityShield === false
        )
      ? StatusType.warning
      : StatusType.normal;
  return state;
}

export function hasOnlyUninstalledDevices(results: IWebrootDeviceResult[]) {
  for (const result of results) {
    if (result.devices && result.devices.some(d => !d.uninstalled)) {
      return false;
    }
  }
  return true;
}

export function getInstalledDevices(results: IWebrootDeviceResult[]): ISkyWebrootDevice[] {
  const devices: ISkyWebrootDevice[] = [];
  results.forEach(result => {
    if (result.devices) {
      result.devices?.forEach(d => {
        if (d.uninstalled !== true) {
          devices.push(d);
        }
      });
    }
  });
  return devices;
}
