import { defineStore } from "pinia";
import { ref } from "vue";
import { pageStates } from "@/components/Buy/Payment/PaymentConfigs";
import { ICreditCardAddress } from "@/components/Buy/Interfaces";

export const usePaymentPageStore = defineStore("paymentPage", () => {
  const pageState = ref<pageStates>(pageStates.displayCards);
  const displayPaymentMethods = ref<boolean>(false);
  const creditAddress = ref<ICreditCardAddress>();
  const setDisplayPaymentMethods = (val: boolean) => (displayPaymentMethods.value = val);
  return {
    pageState,
    creditAddress,
    setDisplayPaymentMethods,
    displayPaymentMethods,
  };
});
