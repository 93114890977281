import { defineStore } from "pinia";
import { unifiedApi } from "@/common";
import { ref } from "vue";
import { handleApiError } from "@/common/handleApiError";
import { logEvent } from "@/common/logger";
import { AxiosError } from "axios";
import { IPendingVerificationEmailResponse } from "@/common/api/unifiedPortal/interfaces";

export const usePersonalInfoStore = defineStore("personalInfo", () => {
  const pendingVerificationEmail = ref<string>("");
  const showVerifyEmailAddressModal = ref("");

  /** Retrieves any pending email verifications so we can get the current email user is using */
  async function checkPendingEmailVerification() {
    try {
      const getPendingVerificationResponse: IPendingVerificationEmailResponse = (
        await unifiedApi.getPendingVerificationEmail()
      ).data;

      pendingVerificationEmail.value = getPendingVerificationResponse.pendingEmail;
    } catch (error) {
      logEvent(`Resulted in not OK ${error}`, `${name}/getPendingVerification`);
      handleApiError(error as AxiosError);
    }
  }

  return {
    pendingVerificationEmail,
    showVerifyEmailAddressModal,
    checkPendingEmailVerification,
  };
});
