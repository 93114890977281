<template>
  <div v-if="creditCard">
    <ul v-if="!props.onlyShowAddress && !isPayPalPayment" id="credit-card-info" class="mb">
      <li>
        {{ `${creditCard.cardType} ${tn("endingIn")} ${creditCard.lastDigits}` }}
      </li>
      <li>
        {{ tn("exp") }}
        {{ expirationDate() }}
      </li>
    </ul>
    <ul v-if="!isPayPalPayment" id="credit-card-address" class="mb-double">
      <li id="currentCityStatePostalCode">
        {{ `${creditCard.address.cityName}, ${creditCard.address.stateOrProvince} ${creditCard.address.postalCode}` }}
      </li>
      <li id="currentCountry">{{ creditCard.address.country }}</li>
    </ul>
    <ul v-if="buyFlowStore.hasAllStateNonUSPaypalShippingAddress && props.showAllStateNonUSPaypalShippingAddress">
      <li>
        <div class="warning-block constrain-90">
          {{ tn("allStateAvailabilityMessageLine1") }} {{ tn("allStateAvailabilityMessageLine2") }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { onBeforeMount, computed } from "vue";
import { logEvent } from "@/common/logger";
import { formatCCDateString } from "@/common/dateFormat";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { FLAGS } from "@/define";

const componentName = "BuySelectedCreditCardComponent";

const buyFlowStore = useBuyFlowStore();
logEvent("created", componentName);

const props = defineProps({
  selectedPaymentMethodId: {
    type: String,
    required: true,
  },
  onlyShowAddress: {
    type: Boolean,
    default: false,
  },
  showAllStateNonUSPaypalShippingAddress: {
    type: Boolean,
    default: false,
  },
});

const isPayPalPayment = computed(() => {
  return creditCard.value?.cardType.toLowerCase() === "paypal";
});

const creditCard = computed(() => {
  return buyFlowStore.creditCards.find(l => l.id === props.selectedPaymentMethodId) ?? null;
});

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  if (
    creditCard.value &&
    FLAGS.ENABLE_BUYFLOW_WEBROOT === "true" &&
    FLAGS.ENABLE_BUYFLOW_PAYPAL === "true" &&
    creditCard.value?.cardType.toLowerCase() === "paypal" &&
    buyFlowStore.hasAllStateInCart
  ) {
    if (creditCard.value?.address.country?.toLowerCase() != "us") {
      buyFlowStore.setHasAllStateNonUSPaypalShippingAddress(true);
    }
  }
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function expirationDate() {
  return formatCCDateString(creditCard.value?.expirationDate || "");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
