<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else>
    <ul class="mt">
      <li class="mb"><svg-icon-component icon="paypal" class="paypal-svg-90" /></li>
      <li class="flex-start">
        <div class="pr">{{ paypalEmail }}</div>
        <button id="btnEditPayPalEmail" type="button" class="text-sm btn-link no-print" @click="editPayPalEmail()">
          {{ t("Common.change") }}
        </button>
      </li>
      <li>
        <div v-if="buyFlowStore.hasAllStateNonUSPaypalShippingAddress" class="warning-block constrain-90">
          {{ tn("allStateAvailabilityMessageLine1") }} {{ tn("allStateAvailabilityMessageLine2") }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import { usePayPalStore } from "@/stores/payPal";
import { useBuyFlowStore } from "@/stores/buyFlow";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { handleApiError } from "@/common/handleApiError";
import { createPayPalAgreementToken } from "./Payment";
import { AxiosError } from "axios";

const componentName = "PayPalPaymentComponent";
const paypalStore = usePayPalStore();
const buyFlowStore = useBuyFlowStore();
const isLoading = ref(true);

const paypalEmail = computed(() => paypalStore.payPalEmail);

onBeforeMount(() => {
  logEvent("Created", componentName);
  isLoading.value = false;
  if (buyFlowStore.isPayPalPayment && paypalStore.payPalShippingAddressIsNonUS && buyFlowStore.hasAllStateInCart) {
    buyFlowStore.setHasAllStateNonUSPaypalShippingAddress(true);
  }
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function editPayPalEmail() {
  buyFlowStore.showProcessingOrderMessage = true;
  logEvent("Initiating paypal transaction", componentName);
  try {
    const paypalAgreementTokenResponse = await createPayPalAgreementToken();
    if (paypalAgreementTokenResponse) {
      logEvent("Got the paypal agreement token, redirecting to paypal approval url", componentName);
      sessionStorage.setItem("PayPal_token_id", paypalAgreementTokenResponse.tokenId);
      sessionStorage.setItem("BuyFlow_Paypal_cart", JSON.stringify(buyFlowStore.cart));
      window.location.href = paypalAgreementTokenResponse.approvalUrl;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  } finally {
    buyFlowStore.showProcessingOrderMessage = false;
  }
}
</script>
