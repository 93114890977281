<template>
  <section class="card mb-double">
    <header>
      <h3>
        <svg-icon-component icon="webroot-icon" class="icon-md icon-relative-move" />
        {{ t(getProductDisplayName(filteredItems?.[0].sku || "Webroot")) }}
      </h3>
    </header>
    <article>
      <p>
        <strong>{{ tn("toInstall") }}</strong>
      </p>
      <ol class="form tight">
        <i18n-t keypath="WebrootDownloadInstructions.allOthersOl1" tag="li" scope="global">
          <template #myAccount>
            <button id="btnGoToDownloadsAllOthersCard" type="button" class="inline-btn-link" @click="goToDownloads()">
              {{ t("Brand.myAccount") }}
            </button>
          </template>
        </i18n-t>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="tn('allOthersOl2')"></li>
        <li>{{ tn("allOthersOl3") }}</li>
      </ol>
      <ul class="mb">
        <li v-if="fetchKeyCodeState === showKeycodeState.loading">
          <span id="spinnerSpan" class="accent-box-info">
            <spinner :is-spinning="true" />
            {{ tn("keycodeRetrieving") }}
          </span>
        </li>
        <li v-if="fetchKeyCodeState === showKeycodeState.success">
          {{ tn("keycodeSuccess") }}
          <span id="keycodeDisplay" class="accent-box-info">
            <strong>{{ formatWebrootKeycode(purchaseKeyCode) }}</strong>
          </span>
          <!-- commenting this out till keycode work is ready -->
          <button
            id="btnCopyKeycodeAllOthersCard"
            type="button"
            class="btn-link"
            @click="copy(formatWebrootKeycode(purchaseKeyCode))"
          >
            {{ t("Common.copy") }}
          </button>
        </li>
        <li v-if="fetchKeyCodeState === showKeycodeState.failed">
          <span id="keycodeFailed" class="accent-box-info">
            {{ tn("keycodeFailed") }}
          </span>
        </li>
      </ul>
      <p>
        <strong>{{ tn("forRenewals") }}</strong>
        {{ t("NextSteps.nextStepsRenewUpgradeP1") }}
      </p>
      <p>
        {{ tn("allOthersP1a") }}
        <button
          id="btnDetailedKeycodeInstructions"
          type="button"
          class="inline-btn-link"
          @click="openKeycodeInstructions()"
        >
          {{ tn("allOthersP1b") }}
          .
        </button>
      </p>
    </article>
  </section>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";
import { getProductDisplayNameBySku } from "@/common/productsBySku";
import { useBuyFlowStore } from "@/stores/buyFlow";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { formatKeycode as formatWebrootKeycode } from "@/common/formatKeycode";
import { showKeycodeState } from "@/components/Buy/BuyEnums";
import { WEBROOT_PC_OPTIMIZER } from "@/define";
import { ICartItem } from "@/common/api/unifiedPortal/interfaces";

const filteredItems = ref<ICartItem[]>();
const componentName = "WebrootAllOthers";
const baseComponentName = "WebrootDownloadInstructions";
const buyFlowStore = useBuyFlowStore();
logEvent("created", componentName);

const fetchKeyCodeState = ref<showKeycodeState>(showKeycodeState.loading);
const purchaseKeyCode = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

onBeforeMount(() => {
  filteredItems.value = buyFlowStore?.cart?.items.filter(l => l.sku !== WEBROOT_PC_OPTIMIZER);
  var keycode = buyFlowStore.PurchaseKeycodes.find(n => n.sku == filteredItems?.value?.[0].sku)?.keycode;
  if (keycode) {
    purchaseKeyCode.value = keycode;
    fetchKeyCodeState.value = showKeycodeState.success;
  } else {
    fetchKeyCodeState.value = showKeycodeState.failed;
  }
});

function getProductDisplayName(sku: string) {
  return getProductDisplayNameBySku({ sku: sku, isTrial: false });
}

function goToDownloads() {
  router.push("/downloads");
}

function openKeycodeInstructions() {
  window.open(
    "https://answers.webroot.com/Webroot/ukp.aspx?pid=12&app=vw&vw=1&login=1&json=1&solutionid=1564",
    "_blank"
  );
}

function copy(purchaseKeyCode: string) {
  logEvent("copy keycode", componentName, purchaseKeyCode);
  navigator.clipboard.writeText(purchaseKeyCode);
}
</script>
