<template>
  <ModalWrapperComponent
    :dialog-name="componentName"
    :show-dialog="props.showDialog"
    @close-modal="close()"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("header") }}
    </template>

    <template #content>
      <p>{{ tn("p1", { firstName: firstName, lastName: lastName, email: email }) }}</p>
      <p>{{ tn("p2") }}</p>
      <ul class="disc">
        <li>{{ tn("li1", { firstName: firstName, lastName: lastName }) }}</li>
        <li>{{ tn("li2", { firstName: firstName, lastName: lastName }) }}</li>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="tn('li3', { firstName: firstName, lastName: lastName })"></li>
      </ul>
      <p>
        <strong>{{ tn("question", { firstName: firstName, lastName: lastName }) }}</strong>
      </p>
    </template>
    <template #footer>
      <button id="btnYes" type="submit" class="btn-primary mr" :disabled="isSending" @click="manageControl(true)">
        <spinner :is-spinning="isSending && command === true" />
        {{ tn("btnYes") }}
      </button>
      <button id="btnNo" type="submit" class="btn-primary" :disabled="isSending" @click="manageControl(false)">
        <spinner :is-spinning="isSending && command === false" />
        {{ tn("btnNo") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import {
  IGetPendingRequestToManageResponse,
  IManageCustomerByResellerRequest,
} from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";

const componentName = "ManageResellerControlRequestComponent";
const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
  requestData: {
    type: Object as PropType<IGetPendingRequestToManageResponse>,
    default: null,
    required: false,
  },
});
const emits = defineEmits(["close-modal", "save-modal"]);
const firstName = ref<string>("");
const lastName = ref<string>("");
const email = ref<string>("");
const isSending = ref<boolean>(false);
const command = ref<boolean>(true);

logEvent("created", componentName, props);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function beforeOpen() {
  logEvent("beforeOpen", componentName, props);
  firstName.value = props.requestData.firstName;
  lastName.value = props.requestData.lastName;
  email.value = props.requestData.email;
}

// true accepts, false declines
async function manageControl(action: boolean) {
  try {
    isSending.value = true;
    command.value = action;
    const request: IManageCustomerByResellerRequest = {
      resellerId: props.requestData.resellerId,
      userAnswer: action,
    };
    const response = (await unifiedApi.manageCustomerByResellerAsync(request)).data;
    isSending.value = false;
    if (response === "manage_request_accepted" || response === "manage_request_canceled") {
      emits("save-modal");
    } else {
      logEvent(response, componentName);
      close();
    }
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function close() {
  if (!isSending.value) {
    emits("close-modal");
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
