<template>
  <article class="constrain-90 m-auto mt-double mb-200">
    <h1 class="mb">{{ tn("header") }}</h1>
    <i18n-t keypath="FlatRateRemainingDaysInvalid.p1" tag="p" scope="global" class="mb mobile-text">
      <template #currentSku>
        {{ t(`Brand.${currentSku}`) }}
      </template>
    </i18n-t>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="mb mobile-text" v-html="t('FlatRateCommon.upgradeOffersParagraph')"></p>
    <p class="mb">
      <button type="button" class="btn-primary mobile-text" @click="renewNow()">
        {{ t("FlatRateCommon.btnUpgrade") }}
      </button>
    </p>

    <i18n-t keypath="FlatRateCommon.contactSupportParagraph" tag="p" scope="global" class="mb mobile-text">
      <template #contactSupport>
        <button type="button" class="inline-btn-link mobile-text" @click="contactSupport()">
          {{ t("Common.contactSupport") }}
        </button>
      </template>
    </i18n-t>
  </article>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { getWebrootInterstitialLinkUnauthenticated } from "@/components/Buy/BuyHelper";

const componentName = "FlatRateRemainingDaysInvalid";
const buyFlowStore = useBuyFlowStore();
const currentSku = ref("");

onBeforeMount(() => {
  currentSku.value = buyFlowStore.licenseKeyInfo?.sku || "";
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function contactSupport() {
  window.open(getUrl("WEBROOT_SUPPORT_HOME"), "_blank");
}

function renewNow() {
  window.open(getWebrootInterstitialLinkUnauthenticated(), "_self");
}
</script>
