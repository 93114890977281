<template>
  <div class="flex-start-md">
    <p>
      <span>
        <svg-icon-component icon="circle-exclamation" class="icon-sm danger" />
      </span>
      <span class="mr-half">
        {{ t("NotificationsComponent.allStateNonAvailabilityMessageLine1") }}
      </span>
      <span class="mr-half">
        {{ t("NotificationsComponent.allStateNonAvailabilityMessageLine2") }}
      </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
</script>
