<template>
  <home-component v-if="showHomePage" />
  <div v-else></div>
</template>

<script setup lang="ts">
import { onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import HomeComponent from "@/components/Home/Home.vue";
import { getLandingPage } from "@/common/landingPage";
import { FLAGS } from "@/define";
import { refreshSubscriber } from "@/common/refreshSubscriber";
import { useSiteSettingsStore } from "@/stores/siteSettings";

const router = useRouter();

const showHomePage = computed(() => {
  return FLAGS.SHOW_HOME_COMPONENT === "true";
});

onBeforeMount(async () => {
  //Subscriber Refresh if Webroot API was down when email was validated
  //Not using await since the code should not be blocked
  if (!useSiteSettingsStore().getSubscriberRefreshedAfterLogin()) {
    refreshSubscriber();
  }

  if (showHomePage.value) {
    return;
  }
  const nextUrl = await getLandingPage();
  router.push(nextUrl);
});
</script>
