<template>
  <div>
    <div class="content">
      <NotificationsComponent context="CourierRecoveryComponent" />
      <section v-if="pickComputer">
        <header>
          <h1>{{ tn("headerH1") }}</h1>
        </header>
        <p>{{ tn("description") }}</p>
        <p>{{ tn("descriptionNote") }}</p>
        <article class="step1">
          <header>
            <h1>{{ tn("step1H1") }}</h1>
          </header>
          <p>{{ tn("step1Description") }}</p>
          <div>
            <button class="btn-link left-link" @click="goToUrl(getUrl('MAC_UNINSTALL_URL'))">
              {{ tn("btnStep1UninstallMac") }}
            </button>
          </div>
          <div class="mb-half">
            <button class="btn-link left-link" @click="goToUrl(getUrl('WINDOWS_UNINSTALL_URL'))">
              {{ tn("btnStep1UninstallWindows") }}
            </button>
          </div>
          <p>{{ tn("step1Note") }}</p>
          <div class="mb-half">
            <button class="btn-link left-link" @click="goToUrl(getUrl('RANSOMEWARE_INFECTION_URL'))">
              {{ tn("btnStep1Recovering") }}
            </button>
          </div>
        </article>
        <article class="step2">
          <header>
            <h1>{{ tn("step2H1") }}</h1>
          </header>
          <div v-if="hiddenDevices.length" class="warning-block constrain-90">
            <p>{{ tn("step2Note") }}</p>
            <button class="btn-link left-link" @click="goToUrl(getUrl('ENCRYPTION_KEY_URL'))">
              {{ tn("btnStep2Note") }}
            </button>
          </div>
          <br />
          <div class="courier-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th class="col-radio">&nbsp;</th>
                  <th>{{ tn("colComputer") }}</th>
                  <th>{{ tn("colUsage") }}</th>
                  <th>{{ tn("colPlan") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="showSpinner">
                  <td colspan="4" class="text-center">
                    <div><img src="/spinner.gif" /></div>
                  </td>
                </tr>
                <tr v-for="device in visibleDevices" :key="device.deviceId">
                  <td class="col-radio">
                    <input :id="`computer${device.deviceId}`" v-model="selectedComputer" type="radio" :value="device" />
                  </td>
                  <td class="col-computer">
                    <label :for="`computer${device.deviceId}`">{{ device.name }}</label>
                    <small class="block text-grey">{{ device.description }}</small>
                  </td>
                  <td class="col-usage">
                    {{ convertMBtoGB(device.fileSizeMB) }}
                  </td>
                  <td class="col-plan">
                    {{ device.productName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="isMac" class="warning-block constrain-90">
            <p>{{ tn("macNote") }}</p>
            <input id="intelOption" v-model="chipSet" type="radio" value="" />
            <label for="intelOption">{{ tn("intelProcessor") }}</label>
            <br />
            <input id="macOption" v-model="chipSet" type="radio" value="M1" />
            <label for="macOption">{{ tn("macProcessor") }}</label>
            <br />
            <p>
              <button class="btn-link left-link" @click="goToUrl(getUrl('MAC_PROCESSOR_URL'))">
                {{ tn("macProcessorUrlText") }}
              </button>
            </p>
          </div>
          <div class="mb-half">
            <br />
            <button class="btn-primary" :disabled="selectedComputer === null" @click="computerSelectionUpdate()">
              {{ tn("next") }}
            </button>
          </div>
        </article>
      </section>
      <section v-if="paymentInfo">
        <header v-if="currentStep !== courierSteps.CONFIRMATION">
          <h1>{{ tn("headerH1Continued") }}</h1>
        </header>
        <div v-if="currentStep !== courierSteps.CONFIRMATION" class="mb-half">
          <button class="inline-btn-link left-link" @click="previousStep()">
            <svg-icon-component icon="chevron-left" class="icon-xs" />
            {{ tn("btnPreviousStep") }}
          </button>
        </div>
        <article v-if="currentStep === courierSteps.SHIPPING_INFORMATION" class="step3">
          <p>{{ tn("step3Description") }}</p>
          <header>
            <h1>{{ tn("step3H1") }}</h1>
          </header>
          <ul class="form constrain-65">
            <li>
              <label for="contactFname">{{ tn("step3FName") }}</label>
              <InputErrorComponent :show="contactFirstNameErrors && contactFirstNameErrors.length > 0">
                <input
                  id="contact-first-name"
                  v-model="contactFirstName"
                  type="text"
                  @keyup="dirtyFlagContactFirstName = true"
                />
                <template #error>
                  <div>
                    <span :key="contactFirstNameErrors[0]">
                      {{ contactFirstNameErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="contactLname">{{ tn("step3LName") }}</label>
              <InputErrorComponent :show="contactLastNameErrors && contactLastNameErrors.length > 0">
                <input
                  id="contact-last-name"
                  v-model="contactLastName"
                  type="text"
                  @keyup="dirtyFlagContactLastName = true"
                />
                <template #error>
                  <div>
                    <span :key="contactLastNameErrors[0]">
                      {{ contactLastNameErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="contactEmail">{{ tn("step3Email") }}</label>
              <InputErrorComponent :show="emailErrors && emailErrors.length > 0">
                <input id="contact-email" v-model="email" type="text" @keyup="dirtyFlagEmail = true" />
                <template #error>
                  <div>
                    <span :key="emailErrors[0]">
                      {{ emailErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="contactPhone">{{ tn("step3Phone") }}</label>
              <InputErrorComponent id="contact-phone" :show="contactPhoneErrors && contactPhoneErrors.length > 0">
                <MaskedInputComponent
                  :mask-type="MaskTypes.phone"
                  :validate-length="false"
                  :initial-value="contactPhone"
                  @update="value => (contactPhone = value.rawValue)"
                  @keyup="dirtyFlagContactPhone = true"
                />
                <template #error>
                  <div>
                    <span :key="contactPhoneErrors[0]">
                      {{ contactPhoneErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
          </ul>
        </article>
        <article v-if="currentStep === courierSteps.SHIPPING_INFORMATION" class="step4">
          <header>
            <h1>{{ tn("step4H1") }}</h1>
          </header>
          <p class="warning-block constrain-90">{{ tn("step4Note") }}</p>
          <ul class="form constrain-65">
            <li>
              <label for="recipientFname">{{ tn("step4FName") }}</label>
              <InputErrorComponent :show="firstNameErrors && firstNameErrors.length > 0">
                <input id="first-name" v-model="firstName" type="text" @keyup="dirtyFlagFirstName = true" />
                <template #error>
                  <div>
                    <span :key="firstNameErrors[0]">
                      {{ firstNameErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="recipientLname">{{ tn("step4LName") }}</label>
              <InputErrorComponent :show="lastNameErrors && lastNameErrors.length > 0">
                <input id="last-name" v-model="lastName" type="text" @keyup="dirtyFlagLastName = true" />
                <template #error>
                  <div>
                    <span :key="lastNameErrors[0]">
                      {{ lastNameErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="recipientPhone">{{ tn("step4Phone") }}</label>
              <InputErrorComponent :show="phoneErrors && phoneErrors.length > 0">
                <MaskedInputComponent
                  :mask-type="MaskTypes.phone"
                  :validate-length="false"
                  :initial-value="phone"
                  @update="value => (phone = value.rawValue)"
                  @keyup="dirtyFlagPhone = true"
                />
                <template #error>
                  <div>
                    <span :key="phoneErrors[0]">
                      {{ phoneErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="recipientAddress">{{ tn("step4Address") }}</label>
              <InputErrorComponent :show="address1Errors && address1Errors.length > 0">
                <input id="address-1" v-model="address1" type="text" @keyup="dirtyFlagAddress1 = true" />
                <template #error>
                  <div>
                    <span :key="address1Errors[0]">
                      {{ address1Errors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="recipientAddress2">{{ tn("step4Address2") }}</label>
              <InputErrorComponent :show="address2Errors && address2Errors.length > 0">
                <input id="address-2" v-model="address2" type="text" />
                <template #error>
                  <div>
                    <span :key="address2Errors[0]">
                      {{ address2Errors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="recipientCity">{{ tn("step4City") }}</label>
              <InputErrorComponent :show="cityErrors && cityErrors.length > 0">
                <input id="city" v-model="city" type="text" @keyup="dirtyFlagCity = true" />
                <template #error>
                  <div>
                    <span :key="cityErrors[0]">
                      {{ cityErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li>
              <label for="recipientState">{{ tn("step4State") }}</label>
              <InputErrorComponent :show="stateErrors && stateErrors.length > 0">
                <select id="state" v-model="state" class="select-css">
                  <option selected value="" disabled>{{ t("Common.selectOne") }}</option>
                  <option v-for="usState in usStates" :key="usState.code" :value="usState.code">
                    {{ usState.name }}
                  </option>
                </select>
                <template #error>
                  <div>
                    <span :key="stateErrors[0]">
                      {{ stateErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
            <li class="constrain-20">
              <label for="recipientZip">{{ tn("step4Zip") }}</label>
              <InputErrorComponent :show="zipErrors && zipErrors.length > 0">
                <input id="zip" v-model="zip" type="text" maxlength="9" @keyup="dirtyFlagZip = true" />
                <template #error>
                  <div>
                    <span :key="zipErrors[0]">
                      {{ zipErrors[0] }}
                    </span>
                  </div>
                </template>
              </InputErrorComponent>
            </li>
          </ul>
          <div class="mb-half">
            <button class="btn-primary" :disabled="!isNextButtonEnabled" @click="nextStep()">{{ tn("next") }}</button>
          </div>
        </article>
        <article v-if="currentStep === courierSteps.BILLING_INFORMATION" class="step5">
          <header>
            <h1>{{ tn("step5H1") }}</h1>
          </header>
          <p>{{ tn("summaryHeader") }}</p>
          <dl class="bold-dt">
            <dt>{{ tn("step5ComputerBackup") }}</dt>
            <dd>
              <div>{{ selectedComputer?.name }}</div>
              <div>{{ selectedComputer?.description }}</div>
              <div>{{ convertMBtoGB(selectedComputer?.fileSizeMB) }}</div>
              <div>{{ selectedComputer?.productName }}</div>
            </dd>
            <dt>{{ tn("step5Contact") }}</dt>
            <dd>
              <div>{{ contactFirstName }} {{ contactLastName }}</div>
              <div>{{ email }}</div>
              <div>{{ formatPhoneNumber(contactPhone) }}</div>
            </dd>
            <dt>{{ tn("step5ShippingInformation") }}</dt>
            <dd>
              <div>{{ firstName }} {{ lastName }}</div>
              <div>{{ formatPhoneNumber(phone) }}</div>
              <div>{{ address1 }}</div>
              <div>{{ address2 }}</div>
              <div>{{ city }} {{ state }} {{ zip }}</div>
            </dd>
          </dl>
        </article>
        <article v-if="currentStep === courierSteps.BILLING_INFORMATION" class="step6">
          <header>
            <h1>{{ tn("step6H1") }}</h1>
          </header>
          <ul class="courier-summary constrain-65">
            <li class="opposite-ends courier-product">
              <span>{{ tn("courierRecoveryFor") }} {{ selectedComputer?.name }}</span>
              <span v-if="selectedComputer?.sku !== 'PersonalPrime'">{{ tn("courierRecoveryPrice") }}</span>
              <span v-if="selectedComputer?.sku === 'PersonalPrime'">{{ tn("courierRecoveryPriceIncluded") }}</span>
            </li>
            <li v-if="selectedComputer?.sku !== 'PersonalPrime'" class="opposite-ends courier-shipping">
              <span>{{ tn("step5ShippingExpeditedA") }} {{ tn("step5ShippingExpeditedB") }}</span>
              <span>{{ tn("priceZero") }}</span>
            </li>
            <li v-if="selectedComputer?.sku === 'PersonalPrime'" class="opposite-ends courier-shipping-middle">
              <span>
                <input v-model="shippingType" type="radio" value="Standard" />
                {{ tn("step5ShippingStandardA") }} {{ tn("step5ShippingStandardB") }}
              </span>
              <span>{{ tn("StandardShippingPrice") }}</span>
            </li>
            <li v-if="selectedComputer?.sku === 'PersonalPrime'" class="opposite-ends courier-shipping">
              <span>
                <input v-model="shippingType" type="radio" value="Expedited" />
                {{ tn("step5ShippingExpeditedA") }} {{ tn("step5ShippingExpeditedB") }}
              </span>
              <span>{{ tn("ExpeditedShippingPrice") }}</span>
            </li>
            <li class="opposite-ends courier-total">
              <span>{{ tn("total") }}</span>
              <span>{{ totalPrice }}</span>
            </li>
          </ul>
          <p class="text-grey mb-double">{{ tn("Step5Note") }}</p>
          <div class="mb">
            <ul class="form constrain-65">
              <li>
                <label for="recipientZip">{{ tn("payWith") }}</label>
                <select v-model="selectedCard" class="select-css" @change="onPaymentMethodChange($event)">
                  <option>{{ t("Common.selectOne") }}</option>
                  <option value="addCard">{{ tn("CourierAddCard") }}</option>
                  <option v-for="card in creditCards" :key="card.id" :value="card">
                    {{ card.cardType }} {{ tn("cardEndingWith")
                    }}{{ card.lastDigits.substr(card.lastDigits.length - 4) }}
                    <span v-if="card.defaultPaymentMethod">{{ tn("defaultCard") }}</span>
                  </option>
                </select>
              </li>
            </ul>
            <dl v-if="selectedCard !== null && !showNewCard" class="bold-dt">
              <dd>
                <div>{{ selectedCard.accountHolder }}</div>
                <div>{{ selectedCard.address.streetAddress1 }}</div>
                <div v-if="selectedCard.address.streetAddress2">{{ selectedCard.address.streetAddress2 }}</div>
                <div>
                  {{ selectedCard.address.cityName }} {{ selectedCard.address.stateOrProvince }}
                  {{ selectedCard.address.postalCode }}
                </div>
                <div>{{ selectedCard.address.country }}</div>
              </dd>
            </dl>
          </div>
          <div v-if="showNewCard" class="add-credit-card">
            <AddPaymentMethodComponent
              :form-submit-button-text="tn('checkoutButton')"
              :checkout-disable="submitOrderEnabled"
              @submit="CompleteOrder()"
              @payment-method-added="zuoraCreditCardFormSuccessHandler"
              @payment-method-error="zuoraCreditCardFormFailureHandler"
            >
              <template #afterIframe>
                <h3 class="mb">{{ tn("certifications") }}</h3>
                <p class="mb">{{ tn("certificationsDescription") }}</p>
                <p class="mb">
                  <button class="inline-btn-link" @click="goToUrl(getUrl('TERMS_URL'))">{{ tn("tos") }}</button>
                </p>
                <ul class="form">
                  <li class="checkbox">
                    <input id="acceptTos" v-model="permissionChecked" type="checkbox" />
                    <label for="acceptTos">{{ tn("permissionLabel") }}</label>
                  </li>
                </ul>
              </template>
            </AddPaymentMethodComponent>
          </div>
          <div v-if="!showNewCard" class="mb-double">
            <h3 class="mb">{{ tn("certifications") }}</h3>
            <p class="mb">{{ tn("certificationsDescription") }}</p>
            <p class="mb">
              <button class="inline-btn-link" @click="goToUrl(getUrl('TERMS_URL'))">{{ tn("tos") }}</button>
            </p>
            <ul class="form">
              <li class="checkbox">
                <input id="acceptTos" v-model="permissionChecked" type="checkbox" />
                <label for="acceptTos">{{ tn("permissionLabel") }}</label>
              </li>
            </ul>
            <button
              id="secureCheckout"
              class="btn-primary"
              :disabled="submitOrderEnabled || isSubmitting"
              @click="CompleteOrder()"
            >
              <spinner :is-spinning="isSubmitting" />
              {{ tn("btnSecureCheckout") }}
            </button>
          </div>
        </article>
        <article v-if="currentStep === courierSteps.CONFIRMATION" class="step7">
          <header>
            <div v-if="success">
              <h1>{{ tn("step7H1") }}</h1>
              <p class="mb">{{ tn("caseNumberIs") }} {{ caseNumber }}</p>
              <p class="mb">{{ tn("step7P1") }}</p>
              <p class="mb">{{ tn("step7P2") }}</p>
              <p class="mb">
                {{ tn("step7P3a") }}
                <button class="inline-btn-link" @click="goToUrl(getUrl('SUPPORT_URL'))">{{ tn("step7P3b") }}</button>
                {{ tn("step7P3c") }}
              </p>
            </div>
            <div v-if="!success">
              <h1>{{ tn("errorHeader") }}</h1>
              <p class="mb">{{ tn("error1Line") }}</p>
              <p class="mb">
                {{ tn("error2Line") }}
                <button class="inline-btn-link" @click="goToUrl(getUrl('CONTACT_SUPPORT_URL'))">
                  {{ tn("errorSupportLink") }}
                </button>
              </p>
            </div>
          </header>
        </article>
      </section>
    </div>
  </div>
  <LoadingBoxComponent
    :show-dialog="buyFlowStore.showProcessingOrderMessage"
    :message="t('BuyAddCardComponent.processingOrderMessage')"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { logEvent, logException } from "@/common/logger";
import { IZuoraCreditCardFormRequest } from "@/common/api/unifiedPortal/interfaces";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import LoadingBoxComponent from "@/components/shared/LoadingBox.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import MaskedInputComponent from "@/components/shared/MaskedInput.vue";
import AddPaymentMethodComponent from "@/components/shared/AddPaymentMethodComponent.vue";
import { t } from "@/i18n";
import { getUserDetailsFromStore } from "@/common/userDetails";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { RateLimitCreditCardError } from "@/define";
import * as creditCardFunctions from "../CreditCards/CreditCards";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { IUserDetails, IDevice, IPaymentMethod, ICourierRecoveryRequest } from "@/common/api/unifiedPortal/interfaces";
import { MaskTypes } from "@/globalEnums";
import { COURIER_PRICES } from "@/define";
import { usStates } from "@/common/usStates";
import { unifiedApi } from "@/common";
import { useUserStore } from "@/stores/user";
import Spinner from "@/components/shared/Spinner.vue";
import {
  isFirstNameValid,
  isLastNameValid,
  isAddress1Valid,
  isAddress2Valid,
  isPhoneValid,
  isZipValid,
  isStateValid,
  isCityValid,
  isEmailValid,
} from "./CourierFormValidations";
import { getUrl } from "@/common/getUrl";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { paymentTypes } from "@/components/Buy/BuyEnums";
import { CASTLE_PK, CASTLE_API } from "@/define";
import * as Castle from "@castleio/castle-js";

const componentName = "CourierRecoveryComponent";

logEvent("created", componentName);
const pickComputer = ref<boolean>(true);
const paymentInfo = ref<boolean>(false);
const currentStep = ref<number>(0);
const showSpinner = ref<boolean>(true);
const isSubmitting = ref<boolean>(false);
const zuoraIFrameRequest = ref<IZuoraCreditCardFormRequest>();
const disableBtn = ref<boolean>(false);
const showNewCard = ref<boolean>(false);
const paymentMethodSelected = ref<boolean>(false);
const userDetails = ref<IUserDetails | null>(null);
const creditCards = ref<IPaymentMethod[] | null>(null);
const selectedComputer = ref<IDevice | null>(null);
const selectedCard = ref<IPaymentMethod | null>(null);

const courierSteps = {
  SELECT_COMPUTER: 0,
  SHIPPING_INFORMATION: 1,
  BILLING_INFORMATION: 2,
  CONFIRMATION: 3,
};

const contactFirstName = ref<string>("");
const contactLastName = ref<string>("");
const email = ref<string>("");
const contactPhone = ref<string>("");
const firstName = ref<string>("");
const lastName = ref<string>("");
const phone = ref<string>("");
const address1 = ref<string>("");
const address2 = ref<string>("");
const city = ref<string>("");
const state = ref<string>("");
const zip = ref<string>("");
const dirtyFlagContactFirstName = ref<boolean>(false);
const dirtyFlagContactLastName = ref<boolean>(false);
const dirtyFlagContactPhone = ref<boolean>(false);
const dirtyFlagEmail = ref<boolean>(false);
const dirtyFlagFirstName = ref<boolean>(false);
const dirtyFlagLastName = ref<boolean>(false);
const dirtyFlagPhone = ref<boolean>(false);
const dirtyFlagAddress1 = ref<boolean>(false);
const dirtyFlagCity = ref<boolean>(false);
const dirtyFlagZip = ref<boolean>(false);
const userStore = useUserStore();
const buyFlowStore = useBuyFlowStore();
const notificationsStore = useNotificationsStore();
const shippingType = ref<string>("");
const chipSet = ref<string>("");
const caseNumber = ref<string>("");
const success = ref<boolean>(false);
const permissionChecked = ref<boolean>(false);
const castleRequestToken = ref<string>("");
const castleOptions = {
  pk: CASTLE_PK,
  apiUrl: CASTLE_API,
} as Castle.ConfigureOptions;

Castle.configure(castleOptions);

// Computed validations
const contactFirstNameErrors = computed(() =>
  dirtyFlagContactFirstName.value === false ? [] : isFirstNameValid(contactFirstName.value)
);

const contactLastNameErrors = computed(() =>
  dirtyFlagContactLastName.value === false ? [] : isLastNameValid(contactLastName.value)
);

const contactPhoneErrors = computed(() =>
  dirtyFlagContactPhone.value === false ? [] : isPhoneValid(contactPhone.value)
);

const emailErrors = computed(() => (dirtyFlagEmail.value === false ? [] : isEmailValid(email.value)));

const firstNameErrors = computed(() => (dirtyFlagFirstName.value === false ? [] : isFirstNameValid(firstName.value)));

const lastNameErrors = computed(() => (dirtyFlagLastName.value === false ? [] : isLastNameValid(lastName.value)));

const phoneErrors = computed(() => (dirtyFlagPhone.value === false ? [] : isPhoneValid(phone.value)));

const cityErrors = computed(() => (dirtyFlagCity.value === false ? [] : isCityValid(city.value)));

const stateErrors = computed(() => (state.value !== null && state.value === "" ? [] : isStateValid(state.value)));

const zipErrors = computed(() => (dirtyFlagZip.value === false ? [] : isZipValid(zip.value)));

const address1Errors = computed(() => (dirtyFlagAddress1.value === false ? [] : isAddress1Valid(address1.value)));

const address2Errors = computed(() => (address2.value === "" ? [] : isAddress2Valid(address2.value)));

const isNextButtonEnabled = computed(() => !hasValidationErrors() && areRequiredFieldsFilled());

const showShippingOptions = computed(
  () => selectedComputer.value != null && selectedComputer.value?.sku === "PersonalPrime"
);

const isMac = computed(
  () => selectedComputer.value != null && selectedComputer.value?.platform.toLowerCase().includes("mac")
);

const totalPrice = computed(() => {
  if (shippingType.value === "Standard") {
    return COURIER_PRICES.CourierStandardShipping;
  }
  if (shippingType.value === "Expedited") {
    return COURIER_PRICES.CourierExpeditedShipping;
  }
  if (showShippingOptions.value) {
    return COURIER_PRICES.CourierRecoveryIncluded;
  }
  return COURIER_PRICES.CourierPrice;
});

const submitOrderEnabled = computed(() => {
  return !(permissionChecked.value && paymentMethodSelected.value);
});

function computerSelectionUpdate() {
  if (showShippingOptions.value) {
    shippingType.value = "Standard";
  } else {
    shippingType.value = "None";
  }
  nextStep();
}

// Functions
function hasValidationErrors() {
  return (
    contactFirstNameErrors.value.length > 0 ||
    contactLastNameErrors.value.length > 0 ||
    contactPhoneErrors.value.length > 0 ||
    emailErrors.value.length > 0 ||
    firstNameErrors.value.length > 0 ||
    lastNameErrors.value.length > 0 ||
    phoneErrors.value.length > 0 ||
    cityErrors.value.length > 0 ||
    stateErrors.value.length > 0 ||
    zipErrors.value.length > 0 ||
    address1Errors.value.length > 0 ||
    address2Errors.value.length > 0
  );
}

function areRequiredFieldsFilled() {
  return (
    contactFirstName.value.length > 0 &&
    contactLastName.value.length > 0 &&
    contactPhone.value.length > 0 &&
    email.value.length > 0 &&
    firstName.value.length > 0 &&
    lastName.value.length > 0 &&
    phone.value.length > 0 &&
    city.value.length > 0 &&
    state.value.length > 0 &&
    zip.value.length > 0 &&
    address1.value.length > 0
  );
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  try {
    logEvent("mounted", componentName);
    isSubmitting.value = false;
    disableBtn.value = false;

    pickComputer.value = true;
    paymentInfo.value = false;

    castleRequestToken.value = await Castle.createRequestToken();

    await Promise.all([getUserDetailsFromStore(false), getCreditCards()])
      .then(([userDetailsInfo, creditCardList]) => {
        if (creditCardList) {
          creditCards.value = creditCardList;
        }
        if (userDetailsInfo) {
          userDetails.value = userDetailsInfo;
          logEvent("UserDetails", componentName, userDetails.value);
        }
        showSpinner.value = false;
      })
      .catch(error => {
        logException(error as Error, "Failed to retrieve UserDetails in Courier Recovery onMounted");
      });
  } catch (err) {
    if ((err as string) === RateLimitCreditCardError) {
      disableBtn.value = true;
    } else {
      //Need to change to logException because this catch was overwriting the one from creditCardFunctions.getCreditCardFormParams
      //where we are putting up the notification instead of redirecting
      logException(err as Error, componentName);
    }
  }
});
onUnmounted(() => {
  logEvent("unmounted", componentName);
});

async function setupNewPaymentMethod() {
  zuoraIFrameRequest.value = (await creditCardFunctions.getCreditCardFormParams(
    "",
    "",
    castleRequestToken.value
  )) as IZuoraCreditCardFormRequest;
  zuoraIFrameRequest.value.locale = "en";
  if (userStore.selectedLanguage !== "en") {
    zuoraIFrameRequest.value.locale = "ja";
  }
  /* eslint-disable */
          //@ts-ignore
          Z.renderWithErrorHandler(zuoraIFrameRequest.value, {}, null, errorHandler);
        /* eslint-enable */
}

async function zuoraCreditCardFormSuccessHandler(event) {
  try {
    await unifiedApi.setDefaultCreditCard(event.detail.creditCardId);
    creditCards.value = await getCreditCards();
    const courierRequest = fillCourierRequest();
    var response = (await unifiedApi.submitCourierRecovery(courierRequest)).data;
    if (response && response.caseNumber) {
      caseNumber.value = response.caseNumber;
      success.value = true;
    } else {
      success.value = false;
    }
    buyFlowStore.showProcessingOrderMessage = false;
    pickComputer.value = false;
    paymentInfo.value = true;
    currentStep.value++;
    window.scrollTo(0, 0);
  } catch (err) {
    buyFlowStore.showProcessingOrderMessage = false;
    handleApiError(err as AxiosError);
  }
}

async function getCreditCards() {
  var creditCardList = (await unifiedApi.getUserPaymentMethods()).data.paymentMethods;
  creditCardList.forEach(card => {
    if (card.defaultPaymentMethod) {
      selectedCard.value = card;
      selectPaymentMethod();
    }
  });
  return creditCardList.filter(l => l.cardType !== paymentTypes.PayPal);
}

async function zuoraCreditCardFormFailureHandler(error: string) {
  if (error === RateLimitCreditCardError) {
    disableBtn.value = true;
  }
  isSubmitting.value = false;
  zuoraIFrameRequest.value = (await creditCardFunctions.getCreditCardFormParams(
    "",
    "",
    castleRequestToken.value
  )) as IZuoraCreditCardFormRequest;
  zuoraIFrameRequest.value.locale = "en";
  if (userStore.selectedLanguage !== "en") {
    zuoraIFrameRequest.value.locale = "ja";
  }
  //Redisplay form
  /* eslint-disable */
      //@ts-ignore
      Z.renderWithErrorHandler(zuoraIFrameRequest.value, {}, null, errorHandler);
      /* eslint-enable */
  //Don't log failures here since it is all Zuora side!
  if (error === "AddCreditCardFailed") {
    notificationsStore.clearNotifications();
    notificationsStore.addNotification({ type: error as NotificationType });
  }
}

function errorHandler(key, code, message) {
  /* eslint-disable */
      //@ts-ignore
      Z.sendErrorMessageToHpm(key, message);
      /* eslint-enable */
  isSubmitting.value = false;
}

async function CompleteOrder() {
  isSubmitting.value = true;
  buyFlowStore.showProcessingOrderMessage = true;

  const courierRequest = fillCourierRequest();

  if (showNewCard.value) {
    /* eslint-disable */
        //@ts-ignore
        Z.submit();
        /* eslint-enable */
    return;
  } else {
    var response = (await unifiedApi.submitCourierRecovery(courierRequest)).data;
    if (response && response.caseNumber) {
      caseNumber.value = response.caseNumber;
      success.value = true;
    } else {
      success.value = false;
    }
  }
  buyFlowStore.showProcessingOrderMessage = false;
  pickComputer.value = false;
  paymentInfo.value = true;
  currentStep.value++;
  window.scrollTo(0, 0);
}

function fillCourierRequest() {
  return {
    contactFirstName: contactFirstName.value,
    contactLastName: contactLastName.value,
    contactEmail: email.value,
    contactPhone: contactPhone.value,
    recipientFirstName: firstName.value,
    recipientLastName: lastName.value,
    recipientPhone: phone.value,
    address1: address1.value,
    address2: address2.value,
    city: city.value,
    state: state.value,
    zipCode: zip.value,

    orgId: 0,
    username: userStore.currentUser?.email,

    computerName: selectedComputer.value?.name,
    computerNickname: selectedComputer.value?.description,
    computerId: selectedComputer.value?.deviceId,
    clientBackupFileCount: selectedComputer.value?.fileCount,
    clientBackupFileMb: selectedComputer.value?.fileSizeMB,
    shippingType: showShippingOptions.value ? shippingType.value : "None",
    chipSet: isMac.value ? chipSet.value : "",

    cardLastDigits:
      selectedCard.value?.lastDigits != null && selectedCard.value?.lastDigits.length > 4
        ? selectedCard.value?.lastDigits.substr(selectedCard.value?.lastDigits.length - 4)
        : "",
  } as ICourierRecoveryRequest;
}
function nextStep() {
  pickComputer.value = false;
  paymentInfo.value = true;
  currentStep.value++;
  window.scrollTo(0, 0);
}

function previousStep() {
  currentStep.value--;
  if (currentStep.value === courierSteps.SELECT_COMPUTER) {
    pickComputer.value = true;
    paymentInfo.value = false;
  } else {
    pickComputer.value = false;
    paymentInfo.value = true;
  }
}

function onPaymentMethodChange(e) {
  if (e.target.value === "addCard") {
    setupNewPaymentMethod();
    showNewCard.value = true;
  } else {
    showNewCard.value = false;
  }
  selectPaymentMethod();
}

function selectPaymentMethod() {
  paymentMethodSelected.value = true;
}

function convertMBtoGB(numberValue) {
  return (numberValue / 1024).toFixed(2) + " GB";
}

function goToUrl(url) {
  window.open(url, "_blank");
}

const visibleDevices = computed(() => {
  const devices = userDetails.value?.devices ?? [];
  return devices.filter(d => d.hasSSLKey && d.deviceId > 0 && !d.isTrial);
});

const hiddenDevices = computed(() => {
  const devices = userDetails.value?.devices ?? [];
  return devices.filter(d => !d.hasSSLKey && d.deviceId > 0 && !d.isTrial);
});

function formatPhoneNumber(numberToFormat): string {
  return (
    "(" + numberToFormat.substring(0, 3) + ") " + numberToFormat.substring(3, 6) + "-" + numberToFormat.substring(6)
  );
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.courier {
  overflow: hidden;
}
.courier iframe {
  height: 5050px;
  position: relative;
  top: -60px;
}
.zuora-hosted-page {
  position: relative;
  overflow: hidden;
  padding-bottom: 500px;
}
.add-credit-card {
  display: flex;
  flex-direction: column;
  padding: var(--space) 0;
}
.col-radio {
  width: var(--space-double);
}
@media (min-width: 600px) {
  .courier iframe {
    height: 4350px;
  }
}
@media (min-width: 960px) {
  .courier iframe {
    height: 3750px;
  }
}
@media (min-width: 992px) {
  .courier iframe {
    height: 3750px;
    top: -60px;
  }
}
@media (min-width: 1060px) {
  .courier iframe {
    height: 2400px;
  }
}
@media (min-width: 1254px) {
  .courier iframe {
    top: -160px;
  }
}
</style>
