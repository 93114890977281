import { unifiedApi } from "@/common";
import { IAllstateState } from "@/common/api/unifiedPortal/interfaces";
import { LAST_API_FETCH_INTERVAL } from "@/define";
import { logEvent } from "@/common/logger";
import { defineStore } from "pinia";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";

export const useAllstateSubscriptionsStore = defineStore("allstateSubscriptions", {
  state: (): IAllstateState => ({
    allstateSubscriptions: [],
    lastRequestTime: 0,
    isRunning: false,
  }),
  actions: {
    async getAllstateSubscriptions() {
      try {
        // Waits for the current call to finish before trying and get aborted (in case a simultaneous call)
        while (this.$state.isRunning) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }

        const currentTime = new Date().getTime();
        if (currentTime - this.lastRequestTime >= LAST_API_FETCH_INTERVAL) {
          this.lastRequestTime = new Date().getTime();
          this.isRunning = true;
          this.allstateSubscriptions = (await unifiedApi.getAllstateSubscriptions()).data;
          this.isRunning = false;
          logEvent("allstateSubscriptions state updated", "subscriptions store", this.$state);
          return this.$state;
        }
        return this.$state;
      } catch (err) {
        //redirect to error page
        handleApiError(err as AxiosError, false);
        // return existing state to satisfy places it is called even though it will be redirected
        return this.$state;
      }
    },
    //If the user has updated their subscriptions off site we want to refresh the store next time they come to the page
    forceRefresh() {
      this.lastRequestTime = new Date().getTime() - LAST_API_FETCH_INTERVAL;
      logEvent("Force AllstateSubscriptions store refresh");
    },
    async forceRefreshAndGetAllstateSubscriptions() {
      this.forceRefresh();
      return await this.getAllstateSubscriptions();
    },
  },
});
