<template>
  <div v-if="!props.hideTitlebar" class="main-header">
    <div class="outer">
      <!-- TODO: use prop to trigger logout? -->
      <!-- TODO: span for name needs styling, can barely see it -->
      <div class="logo-wrapper cursorPointer">
        <svg-icon-component icon="logo" @click="goToHome()" />
      </div>
      <div class="version-wrapper">
        <span class="carboniteOTVersion">
          <span class="product mr-quarter">{{ tn("myAccount") }}</span>
          <span class="version">{{ carboniteVersion }}</span>
        </span>
      </div>
      <div class="desktop">
        <div v-if="user" class="log-out">
          <span v-if="userFullName.length < 24" id="headerUser" class="user-email">{{ userFullName }}</span>
          <span v-else id="headerUserTruncated" :title="userFullName" class="user-email">
            {{ userFullName.substring(0, 24) + ".." }}
          </span>
          <button v-if="!hideLogOutLink" id="headerLogout" type="button" class="btn-link log-in" @click="logout()">
            {{ tn("logOut") }}
          </button>
        </div>
        <div v-else id="linkHeaderLogin">
          <button v-if="!hideLogInLink" id="headerLogin" class="btn-link log-in" @click="logIn()">
            {{ t("Common.logIn") }}
          </button>
        </div>
        <LanguageSelectorComponent v-if="showLanguagePicker" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { useUserStore } from "@/stores/user";
import { FLAGS } from "@/define";
import { logout as commonLogout } from "@/common/logout";
import {
  getCustomerPortalRedirectURL,
  getRedirectToLoginURLWithEmail,
  getRedirectToLoginURL,
} from "@/common/loginRedirect";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import LanguageSelectorComponent from "@/components/shared/LanguageSelector/LanguageSelector.vue";
import { getUrl } from "@/common/getUrl";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { pageStates as buyFlowPageStates } from "../Buy/BuyEnums";
import { continueToMyAccount } from "@/components/Buy/BuyHelper";

const componentName = "HeaderComponent";

const props = defineProps({
  hideTitlebar: {
    type: Boolean,
  },
});

logEvent("created", componentName);

const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const carboniteVersion = ref<string>("");
const buyFlowStore = useBuyFlowStore();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  try {
    carboniteVersion.value = (await unifiedApi.getCarboniteVersionNumber()).data;
  } catch (error) {
    logException(error as Error);
    const date = new Date();
    const quarter = date.getMonth() <= 2 ? 1 : date.getMonth() <= 5 ? 2 : date.getMonth() <= 8 ? 3 : 4;
    carboniteVersion.value = "CE " + (date.getFullYear() % 100) + "." + quarter;
  }
});

const user = computed(() => {
  return userStore.currentUser;
});

const showLanguagePicker = computed(() => {
  return FLAGS.SHOW_LANGUAGE_PICKER === "true";
});

const userFullName = computed(() => {
  return (
    (user.value?.firstName || user.value?.lastName
      ? `${user.value?.firstName ? user.value?.firstName : ""} ${user.value?.lastName ? user.value?.lastName : ""}`
      : user.value?.email) ?? ""
  );
});

const hideLogInLink = computed(() => {
  return !userStore.isEstablishedAccount && route.name === "buy" && route.query.step === buyFlowPageStates.reviewOrder;
});

const hideLogOutLink = computed(() => {
  return route.name === "setNewPassword" || buyFlowStore.isNewUserBuyFlow;
});

async function logIn() {
  if (route.name === "buy" || route.name === "selectPlan") {
    window.location.href = userStore.email
      ? getRedirectToLoginURLWithEmail(buyFlowStore.prepareReturnUrl(route.name), userStore.email)
      : getRedirectToLoginURL(buyFlowStore.prepareReturnUrl(route.name));
  } else if (route.name === "orderConfirmation") {
    await continueToMyAccount();
  } else {
    window.location.href = getCustomerPortalRedirectURL(route.name?.toString());
  }
}

function goToHome() {
  if (user.value) {
    router.push("/");
  } else {
    window.location.href =
      route.path.toString().toLowerCase() === "/createaccount"
        ? getUrl("WEBROOT_COM_SITE")
        : getUrl("CARBONITE_COM_SITE");
  }
}

async function logout() {
  await commonLogout();
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.main-header {
  padding: var(--space-quarter) 0;
  background-color: var(--darkest-grey);
  font-size: var(--mobile-text);
}
.outer {
  display: flex;
  justify-content: space-between;
}
.logo-wrapper {
  color: white;
  fill: white;
  margin: var(--space-third) auto 0;
  padding: 0 var(--space-quarter);
}
.cursorPointer {
  cursor: pointer;
}
.carboniteOTVersion {
  padding-left: var(--space-quarter);
}
.logo {
  display: block;
  fill: white;
  margin-bottom: var(--space-quarter);
  height: 33px;
  max-width: 100%;
}
.desktop {
  display: none;
}
.version-wrapper {
  display: none;
}
.log-out {
  display: flex;
  align-items: center;
}
.btn-link {
  color: white;
  min-width: auto;
}
.btn-link:hover {
  text-decoration: none;
}

@media (min-width: 600px) {
  .logo-wrapper {
    margin: var(--space-third) var(--space-half) 0;
    padding: 0;
  }
  .desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-header .notification-icon {
    display: inline-block;
    height: var(--icon);
    width: var(--icon);
  }
  .main-header button svg {
    top: 4px;
  }
  .log-in {
    text-transform: uppercase;
    padding: var(--space-third);
    margin-right: var(--space-half);
  }
  .user-email {
    display: none;
    color: white;
    padding: var(--space-quarter) var(--space-half);
    vertical-align: top;
  }
}

@media (min-width: 960px) {
  .version-wrapper {
    display: block;
    color: white;
    margin-right: auto;
  }
  .carboniteOTVersion {
    border-left: 2px solid white;
    padding-left: var(--space-half);
  }
  .product,
  .version {
    font-size: var(--mobile-text);
  }
  .user-email {
    display: inline-block;
  }
}
</style>
