<template>
  <error-component />
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import ErrorComponent from "@/components/error/Error.vue";

const componentName = "ErrorRoute";

logEvent("Created", componentName);
</script>
