<template>
  <ModalWrapperComponent
    :dialog-name="componentName"
    :show-dialog="props.showDialog"
    :closable="false"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <strong id="title">{{ tn("header") }}</strong>
    </template>

    <template #content>
      <i18n-t id="testLine1" keypath="CheckHasRecentPurchase.p1" tag="p" class="mt" scope="global">
        <template #computerName>
          <strong>{{ props.computerName }}</strong>
        </template>
      </i18n-t>

      <i18n-t id="testLine2" keypath="CheckHasRecentPurchase.p2" tag="p" class="mt" scope="global">
        <template #subscriptionsLinkText>
          <button type="button" class="inline-btn-link" @click="goToSubscriptions()">
            {{ tn("subscriptionsLinkText") }}
          </button>
        </template>
      </i18n-t>

      <i18n-t id="testLine3" keypath="CheckHasRecentPurchase.p3" tag="p" class="mt" scope="global">
        <template #termLength>
          {{ props.termLength }}
        </template>
        <template #computerName>
          <strong>{{ props.computerName }}</strong>
        </template>
      </i18n-t>

      <p id="testLine4" class="bold-text">{{ tn("p4") }}</p>
    </template>
    <template #footer>
      <div>
        <button id="btnStopPurchase" type="button" class="btn-outline-primary mr" @click="takeAction(false)">
          {{ tn("noBtn") }}
        </button>
        <button id="btnContinuePurchase" type="submit" class="btn-primary" @click="takeAction(true)">
          {{ tn("yesBtn") }}
        </button>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { useRouter } from "vue-router";

const componentName = "CheckHasRecentPurchase";
const props = defineProps({
  showDialog: {
    type: Boolean,
    required: false,
  },
  computerName: {
    type: String,
    required: true,
  },
  termLength: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["close-modal"]);
const router = useRouter();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function beforeOpen() {
  logEvent("created", componentName);
}

function close() {
  emits("close-modal", false);
}

function goToSubscriptions() {
  const routeData = router.resolve({ name: "subscription_information" });
  window.open(routeData.href, "_blank");
}

function takeAction(action: boolean) {
  emits("close-modal", action);
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
