<template>
  <li
    v-if="displayShowHide && !displayNotifications"
    class="notification showHide"
    :class="type"
    @click="toggleNotification"
  >
    <p>
      <svg-icon-component v-if="type === notificationIconType.SUCCESS" icon="circle-check" class="icon-sm success" />
      <svg-icon-component v-if="type === notificationIconType.WARNING" icon="circle-triangle" class="icon-sm warning" />
      <svg-icon-component
        v-if="type === notificationIconType.DANGER"
        icon="circle-exclamation"
        class="icon-sm danger"
      />
      <svg-icon-component v-if="type === notificationIconType.INFO" icon="circle-information" class="icon-sm info" />
      <svg-icon-component v-if="type === notificationIconType.INFO_BG" icon="circle-information" class="icon-sm info" />
      <span>
        {{ t("NotificationsComponent.showAlerts", { qty: props.notifications.length }) }}
      </span>
      <svg-icon-component icon="chevron-down" class="icon-xs chevron" />
    </p>
  </li>
  <li
    v-if="displayShowHide && displayNotifications"
    class="notification showHide"
    :class="type"
    @click="toggleNotification"
  >
    <p>
      <svg-icon-component v-if="type === notificationIconType.SUCCESS" icon="circle-check" class="icon-sm success" />
      <svg-icon-component v-if="type === notificationIconType.WARNING" icon="circle-triangle" class="icon-sm warning" />
      <svg-icon-component
        v-if="type === notificationIconType.DANGER"
        icon="circle-exclamation"
        class="icon-sm danger"
      />
      <svg-icon-component v-if="type === notificationIconType.INFO" icon="circle-information" class="icon-sm info" />
      <span>{{ t("NotificationsComponent.hideAlerts") }}</span>
      <svg-icon-component icon="chevron-up" class="icon-xs chevron" />
    </p>
  </li>
  <li v-for="n in props.notifications" v-show="displayNotifications" :key="n.id" class="notification" :class="type">
    <p v-if="!n.ignoreBaseLayout">
      <svg-icon-component icon="circle-check" class="icon-sm success" />
      <svg-icon-component icon="circle-triangle" class="icon-sm warning" />
      <svg-icon-component icon="circle-exclamation" class="icon-sm danger" />
      <svg-icon-component icon="circle-information" class="icon-sm info" />
      <component
        :is="n.component"
        v-if="n.component"
        :notification="n"
        @custom-notification-event="handleCustomEventNotifications"
      />
      <span v-else @click="handleDeleteNotification(n)">
        {{ t("NotificationsComponent." + n.type) }}
      </span>
    </p>
    <div v-else>
      <component
        :is="n.component"
        v-if="n.component"
        :notification="n"
        @custom-notification-event="handleCustomEventNotifications"
      />
    </div>
  </li>
</template>

<script setup lang="ts">
import { computed, ref, PropType } from "vue";
import { t } from "@/i18n";
import { INotification, useNotificationsStore } from "@/stores/notifications";
import { NotificationIcon } from "@/globalEnums";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const props = defineProps({
  notifications: {
    type: Array as PropType<INotification[]>,
    required: true,
  },
});

const emits = defineEmits(["custom-notification-event"]);

const nStore = useNotificationsStore();
const displayNotifications = ref<boolean>(true);
const displayShowHide = ref<boolean>(false);
const type = ref<NotificationIcon | null>(props.notifications[0].icon || null);
displayShowHide.value = props.notifications.length > 1;

const notificationIconType = computed(() => {
  return NotificationIcon;
});

function handleCustomEventNotifications(notification: INotification, eventType: string) {
  emits("custom-notification-event", notification, eventType);
}

function handleDeleteNotification(notification: INotification) {
  if (notification.dismissible) {
    nStore.deleteNotification(notification.id);
  }
}

function toggleNotification() {
  displayNotifications.value = !displayNotifications.value;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.showHide {
  padding-top: var(--space-quarter);
  padding-bottom: var(--space-quarter);
  cursor: pointer;
  border-top-left-radius: var(--space-half);
  border-top-right-radius: var(--space-half);
}

.chevron {
  fill: var(--link-color);
  display: inline;
  margin-left: var(--space-quarter);
}
</style>
