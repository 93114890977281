import { unifiedApi } from "@/common";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { LAST_API_FETCH_INTERVAL } from "@/define";
import { logEvent } from "@/common/logger";
import { defineStore } from "pinia";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { ref } from "vue";
import { paymentTypes } from "@/components/Buy/BuyEnums";
export const useSubscriptionsStore = defineStore("subscriptions", () => {
  const subscriptions = ref<ISubscription[]>([]);
  const lastRequestTime = ref(0);
  const isRunning = ref(false);

  //This replaces get subscriptions going forward.
  //Separating the call to get the data and the return of the data is easier to unit test
  async function populateSubscriptions() {
    try {
      // Waits for the current call to finish before trying and get aborted (in case a simultaneous call)
      while (isRunning.value) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const currentTime = new Date().getTime();
      if (currentTime - lastRequestTime.value >= LAST_API_FETCH_INTERVAL) {
        lastRequestTime.value = new Date().getTime();
        isRunning.value = true;

        subscriptions.value = (await unifiedApi.getLatestSubscriptions()).data ?? [];
        const userPaymentMethods = (await unifiedApi.getUserPaymentMethods()).data;
        subscriptions.value.forEach(sub => {
          const paymentMethod = userPaymentMethods.paymentMethods.find(x => x.id == sub.zuoraPaymentMethodId);
          if (paymentMethod?.cardType !== paymentTypes.PayPal) {
            sub.paymentInfo = {
              cardLastFourDigits: paymentMethod?.lastDigits ?? "",
              cardType: paymentMethod?.cardType ?? "",
              paymentType: paymentMethod?.paymentMethodType ?? "",
            };
          } else {
            sub.paymentInfo = {
              payPalEmail: paymentMethod.email,
              cardType: paymentTypes.PayPal,
            };
          }
        });

        isRunning.value = false;
        logEvent("subscriptions state updated", "subscriptions store", subscriptions.value);
      }
    } catch (err) {
      //redirect to error page
      handleApiError(err as AxiosError, false);
    }
  }

  //If the user has updated their subscriptions off site we want to refresh the store next time they come to the page
  function forceRefresh() {
    lastRequestTime.value = new Date().getTime() - LAST_API_FETCH_INTERVAL;
    logEvent("Force Subscriptions store refresh");
  }

  async function forceRefreshAndPopulateSubscriptions() {
    forceRefresh();
    await populateSubscriptions();
  }

  return {
    subscriptions,
    lastRequestTime,
    isRunning,
    forceRefresh,
    forceRefreshAndPopulateSubscriptions,
    populateSubscriptions,
  };
});
