// common object used to perform google page view events
import { useUserStore } from "@/stores/user";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import {
  ICartItem,
  IOutstandingInvoice,
  IShoppingCart,
  IUnauthenticatedSubscription,
  ISubscription,
} from "@/common/api/unifiedPortal/interfaces";
import { t } from "@/i18n";
import { adjustments, pageStates } from "@/components/Buy/BuyEnums";
import { useUnauthenticatedBuyFlowStore } from "@/stores/unauthorizedBuyflow";
import { unifiedApi } from ".";
import { AxiosError } from "axios";
import { handleApiError } from "./handleApiError";

export enum googleTagCartEvents {
  purchase = "purchase",
  add = "add",
  remove = "remove",
  change = "changePlan",
}

enum salesTypes {
  new = "new",
  trial = "trial",
  renew = "renew",
  upgrade = "upgrade",
  downgrade = "downgrade",
  unknown = "unknown",
}

enum userTypes {
  unknown = "unknown",
  login = "login",
  new = "new",
  guid = "guid",
  email = "email",
  device = "device",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsWindow = window as any;
let lastEvent: pageStates | googleTagCartEvents | undefined;

export function googleTagInitialize(key: string) {
  (function () {
    //add or create a dataLayer object on the window and store the start time
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
    //find the existing scripts and add a new one to the list
    //this will cause the google tag manager script to load with our userId
    const firstScript = document.getElementsByTagName("script")[0];
    const gtmScript = document.createElement("script") as HTMLScriptElement;
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${key}`;
    if (firstScript.parentNode != null) firstScript.parentNode.insertBefore(gtmScript, firstScript);
  })();
}

export function pageView(page_name: string, page_type: string) {
  let personId = 0;
  const userStore = useUserStore();

  if (userStore.currentUser) {
    personId = userStore.currentUser.personId;
  }

  analyticsWindow.dataLayer = analyticsWindow.dataLayer || [];
  analyticsWindow.dataLayer.push({
    account_id: "",
    user_id: personId,
    page_name: page_name, //can use the page title for this
    page_type: page_type, //thinking this should differentiate between plan selection, user form, payment form. Might need some more thinking on this one so ok to hold off for a future release
    catID: "",
  });
}

export function outstandingInvoicePayEvent(invoice: IOutstandingInvoice) {
  analyticsWindow.dataLayer = analyticsWindow.dataLayer || [];
  analyticsWindow.dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: invoice.googleTag.actionField,
        products: invoice.googleTag.products,
      },
    },
    event: "eec.purchase",
  });
}

export async function cartUpdate(cart: IShoppingCart | undefined, type: pageStates | googleTagCartEvents | undefined) {
  const cartData = await buildCartData(cart);
  if (cartData.length > 0) {
    //rather than just indicating that the user has selected a plan
    //let's figure out if it is an upgrade/downgrade and report eec.changePlan
    //or if it is a new purchase and report eec.add
    //if this is a renew, then continue on reporting selectPlan page

    if (type == googleTagCartEvents.add) {
      //the second field in dimension19 contains the sales type
      //see getSalesType() below for how this is calculated
      const salesType = cartData[0].dimension19.split("+")[1];
      switch (salesType) {
        case salesTypes.new:
        case salesTypes.trial:
          type = googleTagCartEvents.add;
          break;
        case salesTypes.upgrade:
        case salesTypes.downgrade:
          type = googleTagCartEvents.change;
          break;
      }
    } else if (type == pageStates.email) {
      //if we don't know who this is and they're buying something new
      //it must be from .com, so they'll be unknown until they enter an email address
      if (cartData[0].dimension19 == "unknown+new") {
        type = googleTagCartEvents.add;
      }
    }

    //there are a couple of circumstances (ZUOR-44467 ZUOR-44469) where the code will updated the shopping cart twice
    //with no actual changes by the user. This shouldn't happen and the actual fix is to add logic to ensure that two
    //calls are not made since this will keep the buy flow moving quickly. However, this was discovered late and so
    //this temporary fix implemented instead.

    //if we find more than one of these in a row, then block all but the first one
    //don't block all because multiple changePlan in a row are valid, for example
    if (type == pageStates.reviewOrder || type == pageStates.payment) {
      if (type == lastEvent) {
        //as long as no other type of message is being sent, block additional review order messages
        return;
      } else {
        //allow the first one to pass through, but remember that it happened
        lastEvent = type;
      }
    } else {
      //some other message means we're no longer looking for a second review order message
      lastEvent = undefined;
    }

    const msgData: IGoogleTagMessage = { ecommerce: {}, event: "" };
    switch (type) {
      case pageStates.email:
      case pageStates.payment:
      case pageStates.reviewOrder:
      case pageStates.orderConfirmation:
      case pageStates.createAccount:
        msgData.ecommerce.checkout = {
          actionField: {
            step: type,
            option: "",
          },
          products: cartData,
        };
        msgData.event = "eec.checkout_step";
        break;
      case googleTagCartEvents.add:
        msgData.ecommerce.add = {
          actionField: {
            list: [window.location.pathname],
          },
          products: cartData,
        };
        msgData.event = "eec.add";
        break;
      case googleTagCartEvents.change:
        msgData.ecommerce.remove = {
          actionField: {
            list: [window.location.pathname],
          },
          products: cartData,
        };
        msgData.event = "eec.changePlan";
        break;
      case googleTagCartEvents.remove:
        msgData.ecommerce.remove = {
          actionField: {
            list: [window.location.pathname],
          },
          products: cartData,
        };
        msgData.event = "eec.remove";
        break;
      case googleTagCartEvents.purchase:
        const tax = cart?.adjustments.find(a => a.type == adjustments.Tax);
        //even if there is more than one invoice, just return the first
        let invoiceNumber = "";
        if (cart?.invoiceNumbers && cart.invoiceNumbers[0]) {
          invoiceNumber = cart.invoiceNumbers[0];
        }
        let salesType = salesTypes.unknown.toString();
        if (cart?.items && cart.items[0]) {
          salesType = await getSalesType(cart.items[0]);
        }
        msgData.ecommerce.purchase = {
          actionField: {
            id: invoiceNumber,
            affiliation: "Direct",
            coupon: cart?.promotionCode ?? "",
            revenue: cart?.total ?? 0,
            shipping: "Consumer",
            tax: tax?.amount ?? 0,
            dimension19: getUserType() + "+" + salesType,
          },
          products: cartData,
        };
        msgData.event = "eec.purchase";
        break;
      default:
        break;
    }

    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push(msgData);
  }
}

interface IGoogleTagMessage {
  ecommerce: IGoogleTagBody;
  event: string;
}

interface IGoogleTagBody {
  checkout?: IGoogleTagCheckout;
  add?: IGoogleTagAddRemove;
  remove?: IGoogleTagAddRemove;
  purchase?: IGoogleTagPurchase;
}

interface IGoogleTagCheckout {
  actionField: {
    step: string;
    option: string;
  };
  products: IGoogleTagProduct[];
}

interface IGoogleTagAddRemove {
  actionField: {
    list: string[];
  };
  products: IGoogleTagProduct[];
}

interface IGoogleTagPurchase {
  actionField: {
    id: string;
    affiliation: string;
    revenue: number;
    tax: number;
    shipping: string;
    coupon: string;
    dimension19: string;
  };
  products: IGoogleTagProduct[];
}

interface IGoogleTagProduct {
  name: string;
  id: string;
  price: number;
  brand: string;
  category: string;
  quantity: number;
  coupon: string;
  product_code: string;
  dimension15: string;
  dimension16: string;
  dimension17: string;
  dimension18: string;
  dimension19: string;
  dimension20: string;
}

async function buildCartData(cart: IShoppingCart | undefined): Promise<IGoogleTagProduct[]> {
  const products: IGoogleTagProduct[] = [];
  const userType = getUserType();

  if (cart) {
    for (const item of cart.items) {
      products.push(await populateGtmProduct(cart.promotionCode ?? "", item, userType));
      if (item.bundledItems) {
        for (const bundledItem of item.bundledItems) {
          products.push(await populateGtmProduct(cart.promotionCode ?? "", bundledItem, userType));
        }
      }
    }
  }
  return products;
}

async function populateGtmProduct(promoCode: string, item: ICartItem, userType: string): Promise<IGoogleTagProduct> {
  const buyFlowStore = useBuyFlowStore();

  const salesType = await getSalesType(item);
  const product = buyFlowStore?.plans.find(l => l.sku === item.sku);
  const ratePlan = product?.ratePlans.find(l => l.ratePlanId == item.ratePlanId);
  return {
    name: t(`Brand.${item.sku}`),
    id: item.sku,
    price: ratePlan?.catalogPrice ?? 0,
    brand: product?.brand ?? "",
    category: userType == userTypes.unknown ? "New" : "Existing",
    quantity: item.quantity,
    coupon: promoCode,
    product_code: product?.productLevel ?? "",
    dimension15: product?.candyRackSettings?.bundleWithCart ? "Entitlement" : "Subscription",
    dimension16: ratePlan?.months?.toString() ?? "0",
    dimension17: promoCode,
    dimension18: "Consumer",
    dimension19: userType + "+" + salesType,
    dimension20: "Direct",
  };
}

function getUserType(): string {
  const userStore = useUserStore();
  const buyFlowStore = useBuyFlowStore();

  //assume the user is unknown
  let userType = userTypes.unknown;
  if (userStore.currentUser) {
    if (userStore.isEstablishedAccount) {
      //an existing user that's logged in
      //if they chose to log in even after they arrived by guid or device, we'll still report them as a logged in user
      userType = userTypes.login;
    } else {
      //an new user for whom we've created a provisional account
      userType = userTypes.new;
    }
  } else if (buyFlowStore.userGuid) {
    //if we remember a guid from the parameters, then that's how they identified
    userType = userTypes.guid;
  } else if (buyFlowStore.currentEmail) {
    //if we have an email, but they're not logged in then this is an existing user choosing not to log in
    userType = userTypes.email;
  } else if (buyFlowStore.computerName) {
    //if we have a computer name but they didn't identify in any other way, then they identified by deviceId
    userType = userTypes.device;
  }
  return userType;
}

async function getSalesType(cartItem: ICartItem): Promise<string> {
  const userStore = useUserStore();
  const buyFlowStore = useBuyFlowStore();
  const subscriptionStore = useSubscriptionsStore();
  const unauthenticatedBuyflowStore = useUnauthenticatedBuyFlowStore();

  //assume this is a new subscription
  let salesType = salesTypes.new;
  let sub: ISubscription | IUnauthenticatedSubscription | undefined;

  //if we're logged in and this is likely not a new sub
  if ((cartItem.subscriptionNumbers || cartItem.computerId) && userStore.currentUser) {
    //ensure the subscriptions for this user are available
    if (!subscriptionStore.subscriptions || !subscriptionStore.subscriptions.length) {
      subscriptionStore.populateSubscriptions();
    }
  }

  //if we have subscription numbers
  if (cartItem.subscriptionNumbers) {
    //only check the first number because the only way there can be more is if the user just purchased
    //quantity > 1 subscriptions, otherwise, there will only be one listed
    sub = subscriptionStore.subscriptions.find(s => s.zuoraSubscriptionNumber == cartItem.subscriptionNumbers[0]);
  }

  //if we didn't find anything but have a computerId
  if (!sub && cartItem.computerId) {
    //see if we can find it that way
    sub = subscriptionStore.subscriptions.find(s => s.computer?.id == cartItem.computerId);
    if (!sub && !userStore.currentUser && cartItem.computerId > 0) {
      //still didn't find anything, try the unauthenticated sub approach if we're not logged in
      unauthenticatedBuyflowStore.populateSubscription(cartItem.computerId);
      sub = unauthenticatedBuyflowStore.subscription;
    }
  }

  let isRetail = false;

  //might this be a webroot purchase with a licenseKey?
  if (!sub && cartItem.licenseKey) {
    //see if we have a subscription
    sub = subscriptionStore.subscriptions.find(s => s.licenseKey == cartItem.licenseKey);
    if (!sub) {
      //if not, then see if we can get info about it directly
      if (!buyFlowStore.licenseKeyInfo || cartItem.licenseKey != buyFlowStore.licenseKey) {
        try {
          buyFlowStore.licenseKey = cartItem.licenseKey;
          buyFlowStore.setLicenseKeyInfo(
            (await unifiedApi.getLicenseKeyInfo(buyFlowStore.licenseKey, cartItem.sku || "")).data
          );
        } catch (error) {
          handleApiError(error as AxiosError);
        }
      }

      //create a pretend UnauthenticatedSubscriptionObject
      if (buyFlowStore.licenseKeyInfo) {
        sub = {
          sku: buyFlowStore.licenseKeyInfo.sku ?? "",
          computerId: 0,
          carbLicenseDistributionMethodCode: "",
          isTrial: buyFlowStore.licenseKeyInfo.isTrial ?? false,
          hasOutstandingInvoices: buyFlowStore.licenseKeyInfo.hasOutstandingInvoice ?? false,
        };

        //also note if this is retail license
        isRetail = !buyFlowStore.licenseKeyInfo.hasAccount;
      }
    }
  }

  //if we found a match
  if (sub) {
    if (sub.isTrial) {
      //if it says it is a trial, then this must be a trial conversion
      salesType = salesTypes.trial;
    } else if (sub.sku == cartItem.sku) {
      //if the sku has not changed, then this is a renewal
      salesType = salesTypes.renew;
    } else {
      //find the upgrade order of the two skus
      const oldOrder = buyFlowStore.plans.find(p => p.sku == sub?.sku)?.upgradeOrder ?? 0;
      const newOrder = buyFlowStore.plans.find(p => p.sku == cartItem.sku)?.upgradeOrder ?? 0;
      if (oldOrder > newOrder) {
        //if old is later in the upgrade order, then this is a downgrade
        salesType = salesTypes.downgrade;
      } else {
        //otherwise, it is an upgrade
        salesType = salesTypes.upgrade;
      }
    }
  }

  let returnValue = salesType.toString();
  if (isRetail) {
    returnValue += "+retail";
  }

  return returnValue;
}
