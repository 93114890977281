<template>
  <ul v-if="notifications.length > 0" id="ulNotifications" class="notifications-wrapper">
    <NotificationContentComponent
      v-if="dangerNotifications.length > 0"
      :notifications="dangerNotifications"
      @custom-notification-event="handleCustomNotificationContent"
    />
    <NotificationContentComponent
      v-if="warningNotifications.length > 0"
      :notifications="warningNotifications"
      @custom-notification-event="handleCustomNotificationContent"
    />
    <NotificationContentComponent
      v-if="successNotifications.length > 0"
      :notifications="successNotifications"
      @custom-notification-event="handleCustomNotificationContent"
    />
    <NotificationContentComponent
      v-if="infoNotifications.length > 0"
      :notifications="infoNotifications"
      @custom-notification-event="handleCustomNotificationContent"
    />
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  INotification,
  notificationPriority,
  NOTIFICATION_SEQUENCE_LAST,
  useNotificationsStore,
} from "@/stores/notifications";
import { NotificationIcon } from "@/globalEnums";
import NotificationContentComponent from "../notifications/NotificationContent.vue";

const props = defineProps({
  context: {
    type: String,
    required: true,
  },
});

const emits = defineEmits([
  "notification-open-pending-email-verification",
  "notification-open-primary-email-verification",
]);

const nStore = useNotificationsStore();

const notifications = computed(() => {
  return orderNotifications(removeDuplicates(nStore.getNotifications(props.context)));
});

const dangerNotifications = computed(() => {
  return notifications.value.filter(c => c.icon === NotificationIcon.DANGER);
});

const warningNotifications = computed(() => {
  return notifications.value.filter(c => c.icon === NotificationIcon.WARNING);
});

const successNotifications = computed(() => {
  return notifications.value.filter(c => c.icon === NotificationIcon.SUCCESS);
});

const infoNotifications = computed(() => {
  return notifications.value.filter(c => c.icon === NotificationIcon.INFO || c.icon === NotificationIcon.INFO_BG);
});

function orderNotifications(notifications: INotification[]) {
  const notificationOrder = notificationPriority.find(n => n.context === props.context)?.notificationsInOrder;
  if (notificationOrder) {
    for (const notification of notifications) {
      const index = notificationOrder.indexOf(notification.type.toString());
      notification.order = index < 0 ? NOTIFICATION_SEQUENCE_LAST : index;
    }
    notifications.sort((a, b) => a.order - b.order);
    // remove dupes
  }
  return notifications;
}

function removeDuplicates(notifications: INotification[]) {
  const newNotifications: INotification[] = [];
  for (const n of notifications) {
    if (
      !newNotifications.find(
        c => c.type === n.type && c.icon == n.icon && c.params === n.params && c.contexts === n.contexts
      )
    ) {
      newNotifications.push(n);
    }
  }
  return newNotifications;
}

function handleCustomNotificationContent(notification: INotification, eventType: string) {
  if (eventType === "openPendingEmailVerification") {
    emits("notification-open-pending-email-verification", notification);
  } else if (eventType === "openPrimaryEmailVerification") {
    emits("notification-open-primary-email-verification", notification);
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
