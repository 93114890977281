<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepOne`) }}</p>
      <ul>
        <li>
          <button class="inline-btn-link" @click="goTo(iOS)">
            <svg-icon-component
              :icon="`logo-app-store-${logoLanguage}`"
              style="width: 135px; height: 40px; fill: black"
            />
          </button>
        </li>
        <li>
          <button class="inline-btn-link" @click="goTo(android)">
            <svg-icon-component
              :icon="`logo-google-play-store-${logoLanguage}`"
              style="width: 135px; height: 40px; fill: black"
            />
          </button>
        </li>
      </ul>
    </li>
    <li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="t(`${parentComponentName}.${productCode}StepTwo`)"></p>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { computed } from "vue";
import { useUserStore } from "@/stores/user";
import { productList, iOS, android } from "@/common/productList";

const productCode = "WSVPNM";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";
const userStore = useUserStore();

const props = defineProps({
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

const logoLanguage = computed(() => (userStore.selectedLanguage === "ja" ? "jp" : "en"));

logEvent("created", componentName, props);

function goTo(system: string) {
  const product = productList().find(p => p.code === productCode);
  const url = product?.targetSystem?.find(t => t.fullName === system)?.link;
  window.open(url, "_blank");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
