// *************
// download functions
// *************

import { ref } from "vue";
import { logEvent } from "@/common/logger";
import { ICssbDownloadUrlRequest, IRfaItem } from "./interfaces";
import { breadcrumbItems, selectedDisk } from "./diskFn";
import { downloadFile, getCollection, downloadFileCssb } from "./serverComm";
import { clearSelection, selectedItems, selectionInfo } from "./selectionFn";
import { componentName, isSmb, propData } from "./commonFn";
import { unifiedApi } from "@/common";

export const isDownloading = ref<boolean>(false);
export const showEncryptedError = ref(false);
export const downloadWindow = ref<Window | null>();
export const cssbDownloadError = ref<string>("");

//wrap the downloadFile call so we turn the spinner on and off for an individual item
export async function downloadItem(item: IRfaItem, passphase = "", deviceTrustToken = "") {
  item.IsDownloading = true;
  const collection = getCollection({ items: [item] });
  if (isSmb.value) {
    const response = await downloadFileCssb({ path: item.Path, name: item.Name, passPhrase: passphase, item: item });
    //Don't open the popup if there are errors
    if (!cssbDownloadError.value) {
      //Open Popup download window if there isn't one already
      openDownloadWindow(response?.requestId ?? "", propData.value.computerName, item.Name);
    } else {
      //If user hit Max Active Downloads and tries to download, check and update state of existing downloads.
      checkAndUpdateAPIDownloadStates();
    }
  } else {
    await downloadFile({ path: item.DownloadUrl, name: item.Name }, collection, deviceTrustToken);
  }

  item.IsDownloading = false;
}

//user clicked the download selection button
export async function handleDownload(passphase = "", deviceTrustToken = "") {
  if (isSmb.value && selectedItems.value.length > 0) {
    isDownloading.value = true;
    const response = await downloadFileCssb({
      multipleItems: selectedItems.value,
      passPhrase: passphase,
      path: "",
      name: selectedDisk.value?.Name ?? "",
    });
    isDownloading.value = false;
    //Don't open the popup if there are errors
    if (!cssbDownloadError.value) {
      //Open Popup download window if there isn't one already
      openDownloadWindow(response?.requestId ?? "", propData.value.computerName, selectedDisk.value?.Name ?? "");
    } else {
      //If user hit Max Active Downloads and tries to download, check and update state of existing downloads.
      checkAndUpdateAPIDownloadStates();
    }
  } else if (selectionInfo.value?.NumFiles) {
    const collection = getCollection({ items: selectedItems.value });
    //only zip if multiple files or one or more directories were selected
    if (selectedItems.value.length == 1 && !selectedItems.value[0].IsDirectory) {
      isDownloading.value = true;
      await downloadFile(
        { path: selectedItems.value[0].DownloadUrl, name: selectedItems.value[0].Name },
        collection,
        deviceTrustToken
      );
      isDownloading.value = false;
      clearSelection();
    } else {
      let name = "download.zip";
      //get the name of the parent, if there is one
      if (breadcrumbItems.value.length > 0)
        name = `${breadcrumbItems.value[breadcrumbItems.value.length - 1].Name}.zip`;
      //but if only one item is selected and it is a directory, then change the name to that
      if (selectedItems.value.length == 1 && selectedItems.value[0].IsDirectory)
        name = `${selectedItems.value[0].Name}.zip`;

      logEvent("downloading selected", componentName, name);

      //remember the selection
      const computerId = propData.value.computerId;
      const numFiles = selectionInfo.value.NumFiles;
      const downloadUrl = `C${computerId}-Z/Archive.zip`;
      //remove the selection (clears UI)
      clearSelection();

      //wrap the call with spinner on and off
      isDownloading.value = true;
      await downloadFile({ path: downloadUrl, name: name, count: numFiles }, collection, deviceTrustToken);
      isDownloading.value = false;
    }
  }
}

function openDownloadWindow(requestId: string, computerName: string, backupSetName: string) {
  if (!downloadWindow.value || downloadWindow.value?.closed) {
    downloadWindow.value = window.open(
      `${window.location.origin}/processDownloads?requestId=${requestId}&computerName=${computerName}&backupSetName=${backupSetName}`,
      "Prepare Downloads",
      "width=400,height=360,top=200,left=900,toolbar=no,location=no,status=no,resizable=no,scrollbars=no,menubar=no"
    );
  }

  //The popup can't close itself so we need to have it set a local storage item to and poll that to check if we need to close the window
  setInterval(() => {
    const closeWindow =
      localStorage.getItem("closeDownloadPopup") && localStorage.getItem("closeDownloadPopup") === "true";

    if (closeWindow) {
      downloadWindow.value?.close();
      localStorage.removeItem("closeDownloadPopup");
    }
  }, 1000);
}

//Check if there are downloads queued API side and trigger an update to their state
async function checkAndUpdateAPIDownloadStates() {
  if (cssbDownloadError.value == "Request_Max_Limit" && (!downloadWindow.value || downloadWindow.value?.closed)) {
    const downloads = await unifiedApi.getInProgressCssbDownloadRequests();
    await Promise.all(
      downloads.data.map(async download => {
        await unifiedApi.getCssbDownloadUrl({
          requestId: download.downloadRequestId,
          computerName: download.computerName,
          backupSetName: download.backupSetName,
        } as ICssbDownloadUrlRequest);
      })
    );
  }
}

export function getDownloadErrorMessage(error: string, isPopUp: boolean) {
  let errorMessage = "";
  switch (error) {
    case "Invalid_Passphrase":
      errorMessage = "passPhraseError";
      break;
    case "Too_Many_Files":
      errorMessage = "tooManyFiles";
      break;
    case "Restore_Too_Big":
      errorMessage = "restoreTooBig";
      break;
    case "Not_Found":
      errorMessage = "notFound";
      break;
    case "Request_Max_Limit":
      errorMessage = "requestMaxLimit";
      break;
    case "Empty_Folder":
      errorMessage = "emptyFolder";
      break;
    default:
      if (isPopUp) {
        errorMessage = "downloadError";
      } else {
        errorMessage = "genericDownloadError";
      }
  }

  return `FileAccessComponent.${errorMessage}`;
}
