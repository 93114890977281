<template>
  <section class="card mb-double">
    <header>
      <h3>
        <svg-icon-component icon="webroot-secure-vpn" class="icon-native-fill icon-relative-move" />
        {{ t("Brand.WebrootSecureVPNP") }}
      </h3>
    </header>
    <article>
      <p>
        <strong>{{ tn("toInstall") }}</strong>
      </p>
      <ol class="form tight">
        <i18n-t keypath="WebrootDownloadInstructions.allOthersOl1" tag="li" scope="global">
          <template #myAccount>
            <button id="btnGoToDownloadsAllOthersCard" type="button" class="inline-btn-link" @click="goToDownloads()">
              {{ t("Brand.myAccount") }}
            </button>
          </template>
        </i18n-t>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="tn('allOthersOl2')"></li>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="tn('pvpnl3')"></li>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="tn('pvpnl4')"></li>
      </ol>
      <p>
        <strong>{{ tn("forRenewals") }}</strong>
        {{ t("NextSteps.nextStepsRenewUpgradeP1") }}
      </p>
    </article>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "WebrootSecureVPNP";
const baseComponentName = "WebrootDownloadInstructions";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToDownloads() {
  router.push("/downloads");
}
</script>
