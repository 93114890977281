<template>
  <ul class="flex-start-md choose-cc-or-pp mt">
    <li class="mr">
      <label class="flex-start pa-half" :class="currentPaymentType === paymentTypes.CreditCard ? 'selected' : ''">
        <input
          id="payment-type-cc"
          v-model="currentPaymentType"
          type="radio"
          name="paymentType"
          value="CreditCard"
          class="mr-quarter"
          @click="setPaymentType(paymentTypes.CreditCard)"
        />
        <svg-icon-component icon="credit-card" class="mr-quarter" />
        {{ t("SubscriptionInformationComponent.paymentTypeCC") }}
      </label>
    </li>
    <li id="liRadioPaypal" class="mr">
      <label class="flex-start pa-half" :class="currentPaymentType === paymentTypes.PayPal ? 'selected' : ''">
        <input
          id="payment-type-pp"
          v-model="currentPaymentType"
          type="radio"
          name="paymentType"
          value="PayPal"
          class="mr-quarter"
          @click="setPaymentType(paymentTypes.PayPal)"
        />
        <svg-icon-component icon="paypal-icon" class="mr-quarter" />
        {{ t("SubscriptionInformationComponent.paymentTypePP") }}
      </label>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { paymentTypes } from "@/components/Buy/BuyEnums";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const currentPaymentType = ref<paymentTypes>(paymentTypes.CreditCard);
const emits = defineEmits(["changePaymentType"]);
function setPaymentType(paymentType: paymentTypes) {
  emits("changePaymentType", paymentType);
}
</script>
