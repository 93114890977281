<template>
  <ModalWrapperComponent
    dialog-name="AddKeycodeComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul v-if="!isAllstateKeycodeError" class="form">
        <li>
          <div class="label-wrapper">
            <label for="enter-keycode">{{ tn("keycodeLabel") }}</label>
          </div>
          <InputErrorComponent :show="hasKeycodeErrors && hasKeycodeErrors.length > 0">
            <input
              id="enter-keycode"
              v-model="keycode"
              type="text"
              autocomplete="off"
              @keyup="dirtyFlagKeycode = true"
            />
            <template #error>
              <div>
                <span v-for="e in hasKeycodeErrors" :key="e.name">{{ e.message }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
      </ul>
      <div v-if="!isAllstateKeycodeError">
        <p>{{ tn("lineItem1") }}</p>
        <p>
          <strong>{{ tn("lineItem2Heading") }}</strong>
          {{ tn("lineItem2") }}
        </p>
        <p>
          <strong>{{ tn("lineItem3Heading") }}</strong>
          {{ tn("lineItem3") }}
        </p>
      </div>
      <div v-else>
        <p>
          <strong>{{ tn("nextSteps") }}</strong>
        </p>
        <ol>
          <i18n-t keypath="AddKeycodeComponent.stepOne" tag="li" scope="global">
            <template #createNewAccount>
              <strong>{{ tn("createNewAccount") }}</strong>
            </template>
          </i18n-t>
          <li>{{ tn("stepTwo") }}</li>
        </ol>
      </div>
    </template>
    <template #footer>
      <div class="opposite-ends">
        <div id="linksModalFooter">
          <a id="linkNeedHelp" :href="getUrl('VITE_APP_WEBROOT_INSTALL_SUPPORT')" target="_blank">
            {{ tn("linkFooterNeedHelp") }}
            <svg-icon-component icon="external-link" class="pl-quarter" />
          </a>
        </div>
        <div id="buttonsModalFooter">
          <button id="btnCancelChanges" type="button" class="btn-link mr" :disabled="isAdding" @click="close(null)">
            {{ tn("footerButtonCancel") }}
          </button>
          <button
            v-if="!isAllstateKeycodeError"
            id="btnAddChanges"
            type="submit"
            class="btn-primary"
            :disabled="addButtonDisabled || isAdding"
            @click="add()"
          >
            <spinner :is-spinning="isAdding" />
            {{ tn("footerButtonAdd") }}
          </button>
          <button
            v-else
            id="btnLogout"
            type="button"
            class="btn-primary"
            :disabled="isAdding"
            @click="logoutToCreateAccount()"
          >
            <spinner :is-spinning="isAdding" />
            {{ tn("footerButtonLogout") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { AxiosError } from "axios";
import { unifiedApi } from "@/common/index";
import { IAddKeycodeResponse, ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import { validateKeycode } from "@/common/webrootKeycodes";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { NotificationType } from "@/stores/notifications";
import InputErrorComponent from "@/components/shared/InputError.vue";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { IErrorMessage } from "@/common/api/interfaces";
import { logoutURL, clearSession } from "@/common/logout";
import { getUrl } from "@/common/getUrl";

const componentName = "AddKeycodeComponent";

const props = defineProps({
  keycodesInfo: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.keycodesInfo);

const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const isAdding = ref<boolean>(false);
const keycode = ref<string>("");
const userKeycodesInfo = ref<ISubscriberDownloadsInfo | null>(props.keycodesInfo);
const isAllstateKeycodeError = ref<boolean>(false);
const dirtyFlagKeycode = ref<boolean>(false);

const hasKeycodeErrors = computed(() => {
  return dirtyFlagKeycode.value === false ? [] : validateKeycode(keycode.value).filter(e => !e.passed);
});

const addButtonDisabled = computed(() => {
  return hasKeycodeErrors.value.length > 0 || keycode.value === "";
});

const user = computed(() => {
  return userStore.currentUser;
});

function checkIfKeycodeAlreadyExists(keycode) {
  if (userKeycodesInfo.value !== null) {
    return userKeycodesInfo.value?.webrootSubscriptions.filter(e => e.keycode === keycode).length > 0;
  }
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function add() {
  notificationsStore.clearNotifications();
  const formattedKeycode = keycode.value.replace(/-/g, "")?.replace(/ /g, "");

  try {
    isAdding.value = true;

    if (!addButtonDisabled.value && user.value) {
      if (checkIfKeycodeAlreadyExists(formattedKeycode)) {
        processAlert("KeycodeAlreadyExists", formattedKeycode);
        isAdding.value = false;
        return;
      }

      const isAdded: IAddKeycodeResponse = (await unifiedApi.addKeycode(formattedKeycode)).data;
      if (isAdded) {
        if (!isAdded.success) {
          const message = isAdded.messageId as NotificationType;
          processAlert(message, formattedKeycode);
          isAdding.value = false;
          return;
        }

        const response: IAddKeycodeResponse = {
          messageId: isAdded.messageId,
          expirationDate: isAdded.expirationDate,
          success: isAdded.success,
          keycode: formattedKeycode,
        };

        if (new Date(isAdded.expirationDate) < new Date() && response.messageId !== "Success_InactiveKeycode") {
          response.messageId = "KeycodeExpired";
        }

        save(response);
      } else {
        logEvent(`AddKeyCode api response null`, "addKeycodeComponent");
      }
    }
  } catch (err) {
    const e = err as AxiosError;
    const message: NotificationType = (e.response?.data as IErrorMessage)?.message as NotificationType;
    processAlert(message, formattedKeycode);
    logEvent(`AddKeyCode failed ${err}`, componentName, message);
  }
  isAdding.value = false;
}

function processAlert(alertType: NotificationType, formattedKeycode: string) {
  let notificationMessage: NotificationType = "ServerErrors";
  switch (alertType) {
    case "ValidationError_FraudKeycode":
      notificationMessage = "ValidationError_FraudKeycode";
      notificationsStore.addNotification({
        type: notificationMessage,
        params: { url: "support", text: "NotificationsComponent.support", keycode: formattedKeycode },
      });
      break;
    case "ValidationError_LicenseStatusIneligible":
      notificationMessage = "ValidationError_LicenseStatusIneligible";
      notificationsStore.addNotification({
        type: notificationMessage,
        params: { url: "support", text: "NotificationsComponent.support", keycode: formattedKeycode },
      });
      break;
    case "ValidationError_KeycodeEmailMismatch":
      notificationMessage = "ValidationError_KeycodeEmailMismatch";
      notificationsStore.addNotification({ type: notificationMessage, params: { text: formattedKeycode } });
      break;
    case "ValidationError_KeycodeIneligible":
      notificationMessage = "ValidationError_KeycodeIneligible";
      notificationsStore.addNotification({ type: notificationMessage, params: { text: formattedKeycode } });
      break;
    case "ValidationError_InvalidKeycode":
      notificationMessage = "ValidationError_InvalidKeycode";
      notificationsStore.addNotification({ type: notificationMessage, params: { text: formattedKeycode } });
      break;
    case "KeycodeAlreadyExists":
      const responseKeycode: IAddKeycodeResponse = {
        messageId: "KeycodeAlreadyExists",
        keycode: formattedKeycode,
        success: false,
        expirationDate: "",
      };
      close(responseKeycode);
      break;
    case "ValidationError_DuplicateCustomerInEcommerce":
      notificationsStore.addNotification({ type: "ValidationError_DuplicateCustomerInEcommerce" });
      break;
    case "ValidationError_AMZSLicense":
      const responseAMZS: IAddKeycodeResponse = {
        messageId: "ValidationError_AMZSLicense",
        keycode: formattedKeycode,
        success: false,
        expirationDate: "",
      };
      close(responseAMZS);
      break;
    case "unable_to_use_AllstateKeycode":
      notificationsStore.addNotification({ type: "unable_to_use_AllstateKeycode", dismissible: false });
      isAllstateKeycodeError.value = true;
      break;
    default:
      notificationsStore.addNotification({ type: notificationMessage });
  }
}

function resetFlags() {
  dirtyFlagKeycode.value = false;
}

function save(response: IAddKeycodeResponse) {
  resetFlags();
  emits("save-modal", response);
}

function close(response: IAddKeycodeResponse | null) {
  resetFlags();
  emits("close-modal", response);
  isAllstateKeycodeError.value = false;
}

function beforeOpen() {
  notificationsStore.clearNotifications();
  userKeycodesInfo.value = props.keycodesInfo;
  keycode.value = "";
}

function logoutToCreateAccount() {
  isAdding.value = true;
  clearSession();
  const logoutWindow = window.open(logoutURL, "logoutWindow");
  // Wait for 10 seconds to redirect to createAccount page
  setTimeout(function () {
    if (logoutWindow) logoutWindow.close();
    window.location.href = `${window.location.origin}/createAccount`;
  }, 1500);
  isAdding.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
