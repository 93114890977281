<template>
  <tr>
    <th v-if="feature.icon">
      <div class="flex-start-md align-items-center">
        <svg-icon :icon="feature.icon" class="no-shrink mr-quarter" />
        {{ t(`${labelPrefix}.${props.feature.name}`) }}
      </div>
    </th>

    <th v-else>
      {{ t(`${labelPrefix}.${props.feature.name}`) }}
    </th>
    <td v-for="(hasFeature, index) in props.details" :key="index">
      <svg-icon v-if="hasFeature" icon="circle-check" class="icon-sm success" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { computed, PropType } from "vue";
import { ISelectPlanFeature } from "@/components/Buy/SelectPlan/interfaces";
import SvgIcon from "@/components/shared/SvgIcon/SvgIcon.vue";

const props = defineProps({
  feature: {
    type: Object as PropType<ISelectPlanFeature>,
    required: true,
  },
  details: {
    type: Object as PropType<boolean[]>,
    required: true,
  },
});

const labelPrefix = computed(() => {
  return props.feature.labelPrefix || "SelectPlan";
});
</script>
