<template>
  <ModalWrapperComponent
    dialog-name="VerifyPendingEmailAddress"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul>
        <li>
          <i18n-t keypath="VerifyPendingEmailAddressComponent.line1" tag="p" scope="global">
            <template #email>
              <strong>{{ eMail }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="VerifyPendingEmailAddressComponent.line2" tag="p">
            <template #carbonitePlusWebroot>
              <strong>{{ tn("carbonitePlusWebroot") }}</strong>
            </template>
            <template #alert>
              <strong>{{ tn("alert") }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="VerifyPendingEmailAddressComponent.line3" tag="p">
            <template #time>
              <strong class="danger-text">{{ tn("time") }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <button
            id="btnSendMail"
            type="button"
            class="btn-outline-primary btn-action"
            :disabled="resendMailSent || isSaving"
            @click="sendAnotherEmail()"
          >
            <spinner :is-spinning="isSaving" />
            <span v-if="!resendMailSent">{{ tn("sendAnotherEmail") }}</span>
            <span v-else>{{ tn("emailSent") }}</span>
          </button>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnClose" type="button" class="btn-primary" @click="close()">{{ tn("close") }}</button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { unifiedApi } from "@/common";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { useNotificationsStore } from "@/stores/notifications";
import Spinner from "@/components/shared/Spinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";

const componentName = "VerifyPendingEmailAddressComponent";

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal"]);

logEvent("created", componentName, props.email);
const notificationsStore = useNotificationsStore();
const eMail = ref<string>(props.email);
const resendMailSent = ref(false);
const isSaving = ref(false);

function beforeOpen() {
  notificationsStore.clearNotifications();
  eMail.value = props.email;
  resendMailSent.value = false;
  isSaving.value = false;
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function sendAnotherEmail() {
  isSaving.value = true;
  notificationsStore.clearNotifications();
  try {
    const emailUpdateResponse = (await unifiedApi.resendVerificationEmail()).data;
    if (emailUpdateResponse !== "") {
      logEvent("resend another email failed : ", emailUpdateResponse);
    }
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${componentName}/sendAnotherEmail`);
    handleApiError(error as AxiosError);
  }
  resendMailSent.value = true;
  isSaving.value = false;
}

function close() {
  emits("close-modal", "checkEmailVerificationIsPending");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
