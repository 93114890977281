import { recaptchaSiteKey } from "@/define";
import { load, ReCaptchaInstance } from "recaptcha-v3";

export async function getReCaptcha(lang = "en") {
  const recaptchaInstance = await load(recaptchaSiteKey, {
    autoHideBadge: true,
    renderParameters: { hl: lang, render: "explicit" },
  });

  //Badge is hidden by default because we don't want it sticking around on every page
  recaptchaInstance.showBadge();
  return recaptchaInstance;
}

export async function executeReCaptcha(recaptchaInstance: ReCaptchaInstance) {
  const token = await recaptchaInstance.execute("login");
  return token;
}

//Get the SRC from the ReCaptcha's Iframe and reload it with a new language
//This will work without having to reload the page
export function updateReCaptchaLanguage(lang = "en") {
  const iframeGoogleCaptcha = document.getElementsByClassName("grecaptcha-badge")[0].getElementsByTagName("iframe")[0];
  const currentLang = iframeGoogleCaptcha?.src?.match(/hl=(.*?)&/)?.pop();

  if (currentLang !== lang) {
    iframeGoogleCaptcha.setAttribute(
      "src",
      iframeGoogleCaptcha?.getAttribute("src")?.replace(/hl=(.*?)&/, "hl=" + lang + "&") || ""
    );
  }
}
