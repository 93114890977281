<template>
  <DownloadsComponent :error-message="props.errorMessage" :keycode="props.keycode" />
</template>

<script setup lang="ts">
import DownloadsComponent from "@/components/Downloads/Downloads.vue";

const props = defineProps({
  errorMessage: {
    type: String,
    required: false,
    default: null,
  },
  keycode: {
    type: String,
    required: false,
    default: null,
  },
});
</script>
