import { useRouter } from "vue-router";
import { unifiedApi } from ".";
import { AxiosError } from "axios";
import { handleApiError } from "./handleApiError";
import { useUserStore } from "@/stores/user";
import { UserType } from "@/globalEnums";
import { logEvent } from "./logger";
import { HTTP_STATUS_UNAUTHORIZED, HTTP_STATUS_FORBIDDEN } from "@/define";
import { useSecurityStore } from "@/stores/security";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";
import { getUserDetailsFromStore } from "@/common/userDetails";

export async function reloadSubscriberInfo() {
  const userStore = useUserStore();
  try {
    const subscriberLoginInfo = (await unifiedApi.getSubscriberInfo())?.data;
    if (subscriberLoginInfo) {
      const user = {
        userGuid: subscriberLoginInfo.userGuid,
        personId: subscriberLoginInfo.personId,
        firstName: subscriberLoginInfo.firstName,
        lastName: subscriberLoginInfo.lastName,
        email: subscriberLoginInfo.email,
        alternateEmail: subscriberLoginInfo.alternateEmail,
        subscriberInfo: subscriberLoginInfo,
      };
      userStore.setUser(user);
      userStore.userType = subscriberLoginInfo.accountUID ? UserType.smb : UserType.consumer;

      // Don't run below for SMB
      if (userStore.userType === UserType.consumer) {
        // user details is time consuming call starting it before-hand reduces load time.
        getUserDetailsFromStore(false);
        // This populate all we use for rendering in parallel to improve login speed
        // this is done in the side bar because it's the first to be rendered when user is logged in
        await Promise.all([
          useSubscriptionsStore().populateSubscriptions(),
          useSecurityStore().getSecurity(),
          useAllstateSubscriptionsStore().getAllstateSubscriptions(),
        ]);
      }
    } else {
      logEvent("reload subscriber got invalid data", "reloadSubscriberInfo");
      useRouter().push("/error");
    }
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === HTTP_STATUS_UNAUTHORIZED || error.response?.status === HTTP_STATUS_FORBIDDEN) {
      //Get out and let the Axios Interceptors redirect to the log in page
      return;
    }
    handleApiError(error, false);
  }
}

//This will only reload the user info and not subscriptions.
//This will be used in the Return.vue as the pages afterwards will load the sub info
export async function reloadSubscriberInfoLite() {
  const userStore = useUserStore();
  try {
    const subscriberLoginInfo = (await unifiedApi.getSubscriberInfo())?.data;
    if (subscriberLoginInfo) {
      const user = {
        userGuid: subscriberLoginInfo.userGuid,
        personId: subscriberLoginInfo.personId,
        firstName: subscriberLoginInfo.firstName,
        lastName: subscriberLoginInfo.lastName,
        email: subscriberLoginInfo.email,
        alternateEmail: subscriberLoginInfo.alternateEmail,
        subscriberInfo: subscriberLoginInfo,
      };
      userStore.setUser(user);
      userStore.userType = subscriberLoginInfo.accountUID ? UserType.smb : UserType.consumer;
      if (userStore.userType === UserType.consumer) {
        // user details is time consuming call starting it before-hand reduces load time.
        getUserDetailsFromStore(false);
      }
    }
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === HTTP_STATUS_UNAUTHORIZED || error.response?.status === HTTP_STATUS_FORBIDDEN) {
      //Get out and let the Axios Interceptors redirect to the log in page
      return;
    }
    handleApiError(error, false);
  }
}
