import { IValidationResult, IValidationRule, validate } from "./validator";
import i18n from "../i18n";
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from "@/define";
import { RuleNames } from "@/globalEnums";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";

let cannotUseBraces = i18n.global.t("StrongPasswordMessages.cannotUseBraces");

function refreshCannotUseBracesString() {
  cannotUseBraces = i18n.global.t("StrongPasswordMessages.cannotUseBraces");
}

let useUpperCase = i18n.global.t("StrongPasswordMessages.useUpperCase");

function refreshUseUpperCaseString() {
  useUpperCase = i18n.global.t("StrongPasswordMessages.useUpperCase");
}

let useLowerCase = i18n.global.t("StrongPasswordMessages.useLowerCase");

function refreshUseLowerCaseString() {
  useLowerCase = i18n.global.t("StrongPasswordMessages.useLowerCase");
}

let oneNumber = i18n.global.t("StrongPasswordMessages.oneNumber");

function refreshOneNumberString() {
  oneNumber = i18n.global.t("StrongPasswordMessages.oneNumber");
}

let characters = i18n.global.t("StrongPasswordMessages.characters", {
  min: MIN_PASSWORD_LENGTH,
  max: MAX_PASSWORD_LENGTH,
});

function refreshCharactersString() {
  characters = i18n.global.t("StrongPasswordMessages.characters", {
    min: MIN_PASSWORD_LENGTH,
    max: MAX_PASSWORD_LENGTH,
  });
}

const re = new RegExp(`^.{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}$`);

let rules: IValidationRule<string>[] = [];
refreshRules();

function refreshRules() {
  rules = [
    {
      name: RuleNames.characters,
      description: characters,
      test: password => (password ? re.test(password) : false),
      displayOrder: 3,
    },
    {
      name: RuleNames.uppercase,
      description: useUpperCase,
      test: password => (password ? /[A-Z]/.test(password) : false),
      displayOrder: 0,
    },
    {
      name: RuleNames.lowercase,
      description: useLowerCase,
      test: password => (password ? /[a-z]/.test(password) : false),
      displayOrder: 1,
    },
    {
      name: RuleNames.number,
      description: oneNumber,
      test: password => (password ? /[\d]/.test(password) : false),
      displayOrder: 2,
    },
    {
      name: RuleNames.invalidCharacterBrace,
      description: cannotUseBraces,
      test: password => (password ? password.indexOf("<") === -1 && password.indexOf(">") === -1 : false),
      displayOrder: 4,
    },
  ];
}

//List of functions to refresh when localization changes. Doing in bulk because we don't want the rules to refresh before the
//other strings are updated
function refreshCachedString() {
  refreshCannotUseBracesString();
  refreshUseUpperCaseString();
  refreshOneNumberString();
  refreshUseLowerCaseString();
  refreshCharactersString();
  refreshRules();
}

registerRefreshFunction(refreshCachedString, true);

export function validatePassword(password: string): IValidationResult[] {
  return validate(password, rules);
}
