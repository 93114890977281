<template>
  <div>
    <div v-if="loading" class="backup-page">
      <LoadingSpinner />
    </div>
    <div v-else class="backup-page content">
      <PrimaryEmailVerificationWrapperComponent notification-context="BackupComponent" :email="email" />
      <div v-if="backupPageStore.pageState === backupPageState.upgradeAdd">
        <UpgradeSubscriptionComponent
          :devices="carboniteDevicesNotCdlp"
          :user-id="user?.personId ?? 0"
          :subscriptions="subscriptions"
          @action="closeUpgradeAddSubscription"
          @show-invoice-details="showOutstandingInvoiceDetails"
          @install-ac="showDownload"
        />
      </div>
      <div v-else-if="backupPageStore.pageState === backupPageState.invoiceDetails">
        <InvoiceDetailsComponent
          :payment-methods="paymentMethods"
          :subscriptions="subscriptions"
          :origin-from-backup-tab="true"
          @invoice-details-canceled="returnPageToPreviousState"
          @success="handleSuccessInvoicePaid"
          @updated-payment-methods="updatePaymentMethods"
        />
      </div>
      <div
        v-else-if="
          backupPageStore.pageState === backupPageState.addPaymentMethod ||
          backupPageStore.pageState === backupPageState.movePaymentMethodMRToAR ||
          backupPageStore.pageState === backupPageState.movePaymentMethodMRToARaddCard
        "
      >
        <h1>
          <button
            id="btnAddPaymentMethod"
            class="inline-btn-link"
            type="button"
            @click="backupPageStore.pageState = backupPageState.default"
          >
            <svg-icon-component icon="chevron-left" class="icon" />
          </button>
          <strong v-if="backupPageStore.pageState === backupPageState.movePaymentMethodMRToAR">
            {{ t("SubscriptionInformationComponent.changePaymentMethod") }}
          </strong>
          <strong v-else>{{ t("SubscriptionInformationComponent.addPaymentMethod") }}</strong>
        </h1>
        <div class="mb">
          <i18n-t keypath="SubscriptionInformationComponent.addPaymentHeader" tag="p" class="mt" scope="global">
            <template #all>
              <strong>{{ t("SubscriptionInformationComponent.all") }}</strong>
            </template>
          </i18n-t>
          <SafeProductsInfoComponent :subscriptions="subscriptions" />
        </div>
        <div v-if="backupPageStore.pageState === backupPageState.movePaymentMethodMRToAR">
          <SelectPayment
            :payment-methods="paymentMethods"
            :subscription="currentSubscription"
            :show-details="true"
            :btn-text="t('Common.save')"
            :is-auto-renew="shouldAutoRenew()"
            :show-cancel-button="true"
            :brand="autoRenewalStore.selectedBrandForAutoRenewal"
            @add-card="addCard"
            @set-auto-renew="enableAutoRenew"
            @reset-subscription-state="backupPageStore.pageState = backupPageState.default"
          />
        </div>
        <div v-else>
          <AddCreditCardComponent
            :sub="currentSubscription"
            :is-auto-renew="backupPageStore.pageState === backupPageState.movePaymentMethodMRToARaddCard"
            :credit-card-previous-state="backupPageState.default"
            @subscription-page-state-change="backupPageStore.pageState = backupPageState.default"
            @payment-method-added="addPaymentMethodHandler"
            @auto-renew-payment-method-added="showAddCardAutoRenewSuccess"
          />
        </div>
      </div>
      <div v-else>
        <header>
          <h1 v-if="backupPageStore.pageState !== backupPageState.download">{{ tn("backupH1") }}</h1>
          <h1 v-else>{{ tn("backupH1") }}</h1>
        </header>
        <!-- cdlp section -->
        <article v-if="FLAGS.ENABLE_CDLP === 'true' && visibleDeviceShowCdlp" class="tile-wrapper no-grid mb-double">
          <WebrootTotalProtectionSection
            :device="visibleDeviceShowCdlp"
            :subs="subscriptions"
            :computer-id-to-reinstall="computerIdToReinstall"
            @set-installer-type="showDownload"
            @show-invoice-details="showOutstandingInvoiceDetails"
            @change-payment-method="changePaymentMethod"
            @auto-renew-updated="showAutoRenewSuccess"
            @move-payment-method-mr-to-ar="handleMoveFromMRtoAR"
            @update-device-keycode="handleUpdateKeycode"
          />
        </article>
        <!--Best Buy-->
        <div v-if="backupPageStore.pageState === backupPageState.bestBuyOnly" class="best-buy">
          <p class="mt-2 mb-2">{{ tn("bestBuyOnly") }}</p>
        </div>
        <!-- Carbonite section -->
        <article v-if="showSafeSection" class="tile-wrapper no-grid mb-double">
          <section class="card">
            <header :class="{ visibleArticle: show }" @click="toggleCarbTile()">
              <h3>{{ t("Brand.carboniteSafe") }}</h3>
              <div>
                <SvgIconComponent v-if="showCarbTile" icon="collapse-minus-sign" class="icon-sm" />
                <SvgIconComponent v-else icon="expand-plus" class="icon-sm" />
              </div>
            </header>
            <article v-if="showCarbTile" class="visibleArticle">
              <div v-if="backupPageStore.pageState === backupPageState.default">
                <KbSearchComponent />
              </div>
              <div
                v-else-if="
                  backupPageStore.pageState === backupPageState.download ||
                  backupPageStore.pageState === backupPageState.uninstalledSub
                "
              >
                <article class="ma-half">
                  <p v-if="backupPageStore.pageState === backupPageState.uninstalledSub">
                    {{ tn("noneComputer") }}
                  </p>
                  <p v-if="backupPageStore.pageState === backupPageState.download">
                    <span>{{ tn("readyToInstall") }}</span>
                  </p>
                  <p>
                    {{ tn("getStarted") }}
                  </p>
                  <ol class="mb">
                    <li>
                      <i18n-t keypath="BackupComponent.stepOne" tag="div" scope="global">
                        <template #computer>
                          <strong>{{ tn("computerBackup") }}</strong>
                        </template>
                      </i18n-t>
                    </li>
                    <li>
                      <i18n-t keypath="BackupComponent.stepTwo" tag="div" scope="global">
                        <template #button>
                          <span v-if="backupPageStore.pageState === backupPageState.download">
                            <strong>{{ tn("downloadNow") }}</strong>
                          </span>
                          <span v-if="backupPageStore.pageState === backupPageState.uninstalledSub">
                            <strong>{{ tn("installNow") }}</strong>
                          </span>
                        </template>
                      </i18n-t>
                    </li>
                    <li>
                      <div>{{ tn("stepThree") }}</div>
                    </li>
                  </ol>
                  <div v-if="isMobileClient" id="mobileWarning" class="danger-text italic-text mb">
                    {{ tn("noteOnlyAvailableFor") }}
                  </div>
                  <div v-if="backupPageStore.pageState === backupPageState.uninstalledSub">
                    <ul>
                      <li v-for="d in visibleDevicesNoDeviceId" :key="d.activationCode">
                        <CardUninstalledSubComponent :device="d" @ac-selected="showDownload" />
                      </li>
                      <li v-for="s in wsacSafeLiteToProvision" :key="s.keyCode">
                        <CardWsacSafeLiteComponent :subscription="s" @ac-selected="showDownload" />
                      </li>
                    </ul>
                  </div>
                  <div v-if="backupPageStore.pageState === backupPageState.download" class="mb">
                    <DownloadSafeComponent :device="selectedDevice" :install-type="installType" />
                  </div>
                  <footer class="text-center opposite-ends-desktop mb">
                    <div id="linksModalFooter">
                      <button
                        v-if="backupPageStore.pageState === backupPageState.download"
                        id="linkNeedHelp"
                        class="inline-btn-link"
                        @click="goToInstallKnowledgeBaseLink()"
                      >
                        {{ tn("linkFooterNeedHelp") }}
                        <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
                      </button>
                      <a
                        v-else
                        id="linkNeedHelp"
                        href="https://support.carbonite.com/articles/Personal-Windows-Getting-Started-with-Carbonite-Personal"
                        target="_blank"
                      >
                        {{ tn("linkFooterNeedHelp") }}
                        <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
                      </a>
                    </div>
                  </footer>
                </article>
              </div>
              <ul v-if="backupPageStore.pageState === backupPageState.offerTrial && user?.personId !== undefined">
                <li>
                  <BuyOrTryCarboniteComponent :person-id="user.personId" @show-start-trial="showDownload" />
                </li>
              </ul>
              <ul v-if="backupPageStore.pageState === backupPageState.default" class="mb">
                <li v-for="d in carboniteDevicesNotCdlp" :key="d.activationCode" class="mb">
                  <CardComputerComponent
                    v-if="showCardComputer(d)"
                    :device="d"
                    :is-cdlp="false"
                    :subscription="getComputerSubscriptions(d)"
                    :user-id="user?.personId"
                    :reseller-email="userDetails?.resellerEmail"
                    :display-forced-reinstall-modal="d.deviceId === computerIdToReinstall"
                    @set-installer-type="showDownload"
                    @show-invoice-details="showOutstandingInvoiceDetails"
                    @change-payment-method="changePaymentMethod"
                    @auto-renew-updated="showAutoRenewSuccess"
                    @move-payment-method-mr-to-ar="handleMoveFromMRtoAR"
                    @update-device-keycode="handleUpdateKeycode"
                  />
                </li>
              </ul>
              <div v-if="displayAdditionalBackupSection" class="mt">
                <h3 class="text-grey border-bottom pb-quarter mb-quarter">{{ tn("BackupAdditional") }}</h3>
                <article class="ma-half">
                  <p>
                    {{ tn("youHaveUninstalledSubs") }}
                  </p>
                  <p>
                    {{ tn("getStarted") }}
                  </p>
                  <ol class="mb">
                    <li>
                      <i18n-t keypath="BackupComponent.stepOne" tag="div" scope="global">
                        <template #computer>
                          <strong>{{ tn("computerBackup") }}</strong>
                        </template>
                      </i18n-t>
                    </li>
                    <li>
                      <i18n-t keypath="BackupComponent.stepTwo" tag="div" scope="global">
                        <template #button>
                          <strong>{{ tn("installNow") }}</strong>
                        </template>
                      </i18n-t>
                    </li>
                    <li>
                      <div>{{ tn("stepThree") }}</div>
                    </li>
                  </ol>
                  <p id="linksModalFooter">
                    <a
                      id="linkNeedHelp"
                      href="https://support.carbonite.com/articles/Personal-Windows-Getting-Started-with-Carbonite-Personal"
                      target="_blank"
                    >
                      {{ tn("linkFooterNeedHelp") }}
                      <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
                    </a>
                  </p>
                  <p v-if="isMobileClient" id="mobileWarning" class="unsupported">
                    {{ tn("noteOnlyAvailableFor") }}
                  </p>
                  <ul>
                    <li v-for="d in visibleDevicesNoDeviceId" :key="d.activationCode">
                      <CardUninstalledSubComponent :device="d" @ac-selected="showDownload" />
                    </li>
                    <li v-for="s in wsacSafeLiteToProvision" :key="s.keyCode">
                      <CardWsacSafeLiteComponent :subscription="s" @ac-selected="showDownload" />
                    </li>
                  </ul>
                </article>
              </div>
              <div
                v-if="
                  canDisplayGlobalFeature(globalFeatures.addOrUpgrade) &&
                  (backupPageStore.pageState === backupPageState.default ||
                    backupPageStore.pageState === backupPageState.uninstalledSub) &&
                  !hasResellerSubNonTrial
                "
              >
                <h3 class="text-grey border-bottom pb-quarter mb-quarter">{{ tn("upgradeOrExtend") }}</h3>
                <article class="ma-half opposite-ends">
                  <ul class="disc">
                    <li>
                      {{ tn("upgradeIntro") }}
                    </li>
                    <li>
                      {{ tn("upgradeIntro2") }}
                    </li>
                    <li>
                      {{ tn("upgradeIntro3") }}
                    </li>
                  </ul>
                  <div>
                    <button id="upgradeAddBackup" class="btn-link" @click="upgradeAddSubscription">
                      {{ tn("upgradeAdd") }}
                    </button>
                  </div>
                </article>
              </div>
              <div
                v-if="
                  canDisplayGlobalFeature(globalFeatures.startATrial) &&
                  (backupPageStore.pageState === backupPageState.default ||
                    backupPageStore.pageState === backupPageState.uninstalledSub)
                "
              >
                <article class="ma-half">
                  <div>
                    <button id="startATrial" class="btn-link" @click="showDownload(undefined, 'Install')">
                      {{ tn("startATrial") }}
                    </button>
                  </div>
                </article>
              </div>
            </article>
          </section>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";

import {
  IDevice,
  IPaymentMethod,
  IUserDetails,
  ISubscription,
  IAutoRenewUpdateRequest,
  IDeviceKeycodeUpdateRequest,
} from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { useUserStore } from "@/stores/user";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { useNotificationsStore } from "@/stores/notifications";
import { isMac, isMobile } from "@/common/browserDetect";
import { isPurgedComputer } from "@/components/Backup/purgedComputer";
import * as subHelper from "@/common/subscriptionHelper";
import { getUserDetailsFromStore } from "@/common/userDetails";
import { pageState as subscriptionPageState, pageState as backupPageState } from "@/globalEnums";
import { displayPasswordResetSuccess } from "@/common/passwordReset";
import KbSearchComponent from "@/components/shared/KbSearch.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import WebrootTotalProtectionSection from "./WebrootTotalProtectionSection.vue";
import UpgradeSubscriptionComponent from "./UpgradeSubscription.vue";
import CardComputerComponent from "./CardComputer/CardComputer.vue";
import CardUninstalledSubComponent from "./CardUninstalledSub.vue";
import CardWsacSafeLiteComponent from "./CardWsacSafeLite.vue";
import DownloadSafeComponent from "./DownloadSafe.vue";
import BuyOrTryCarboniteComponent from "./BuyOrTryCarbonite.vue";
import InvoiceDetailsComponent from "@/components/Subscriptions/InvoiceDetails.vue";
import SafeProductsInfoComponent from "@/components/Subscriptions/SafeProductsInfo.vue";
import AddCreditCardComponent from "@/components/CreditCards/AddCreditCard.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { FLAGS, INVOICE_PAID_REFRESH_DELAY, SUBSCRIPTION_MIN_GRACE_PERIOD_DAY } from "@/define";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";
import { useBackupPageStore } from "@/stores/backupPage";
import SelectPayment from "@/components/Buy/Payment/SelectPayment.vue";
import { useAutoRenewalStore } from "@/stores/autoRenewal";
import { webrootCDLPcodes } from "@/common/webrootProductName";
import { carboniteSkus } from "@/common/productsBySku";

const name = "BackupComponent";

logEvent("created", name);

const route = useRoute();
const userStore = useUserStore();
const email = ref<string>("");
const buyFlowStore = useBuyFlowStore();
const notificationsStore = useNotificationsStore();
const userDetails = ref<IUserDetails | null>(null);
const loading = ref<boolean>(true);
const previousPageState = ref<backupPageState | null>(null);
const selectedDevice = ref<IDevice>();
const installType = ref<string>("");
const paymentMethods = ref<IPaymentMethod[]>([]);
const subscriptions = ref<ISubscription[]>([]);
const currentSubscription = ref<ISubscription>();
const computerIdToReinstall = ref<number>(-1);
const isMobileClient = ref<boolean>(false);
const siteSettingsStore = useSiteSettingsStore();
const backupPageStore = useBackupPageStore();
const autoRenewalStore = useAutoRenewalStore();
const showCarbTile = ref<boolean>(true);
const show = ref<boolean>(true);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

const appliedAC = computed(() => siteSettingsStore.activationCodeToInstall);

const isLoggedIn = computed(() => {
  return !!user.value;
});

const user = computed(() => {
  return userStore.currentUser;
});

const isResellerManaged = computed(() => {
  return visibleDevices.value.some(d => d.isResellerManaged);
});

const carboniteDevicesNotCdlp = computed(() => {
  return visibleDevices.value.filter(
    d => d.zuoraSubscriptionNumber !== visibleDeviceShowCdlp.value?.zuoraSubscriptionNumber
  );
});

const visibleDevices = computed(() => {
  const devices = userDetails.value?.devices ?? [];
  devices.sort((a, b) => a.daysRemaining - b.daysRemaining);
  return devices;
});

const visibleDevicesNoDeviceId = computed(() => {
  return carboniteDevicesNotCdlp.value.filter(d => d.deviceId === 0);
});

const wsacSafeLiteToProvision = computed(() => {
  return subscriptions.value.filter(s => s.packageName === "Safe Lite");
});

const visibleDeviceShowCdlp = computed(() => {
  return visibleDevices.value.find(d =>
    subscriptions.value.find(
      e =>
        e.zuoraSubscriptionNumber === d.zuoraSubscriptionNumber &&
        webrootCDLPcodes.some(f => f === e.licenseCategoryName) &&
        e.remainingDays > SUBSCRIPTION_MIN_GRACE_PERIOD_DAY
    )
  );
});

const carboniteAndUnexpiredCDLPDevices = computed(() => {
  return subscriptions.value.filter(
    e =>
      (webrootCDLPcodes.some(f => f === e.licenseCategoryName) &&
        e.remainingDays > SUBSCRIPTION_MIN_GRACE_PERIOD_DAY) ||
      carboniteSkus.some(c => c === e.sku)
  );
});

const showSafeSection = computed(() => {
  if (backupPageStore.pageState !== backupPageState.bestBuyOnly) {
    //If the user owns CDLP only show Safe section if they own a safe sub
    if (visibleDeviceShowCdlp.value) {
      return carboniteDevicesNotCdlp.value.length > 0;
    }
    return true;
  }
  return false;
});

const hasResellerSubNonTrial = computed((): boolean => {
  return visibleDevices.value.some(d => d.isResellerManaged && !d.isTrial && !d.isPartnerReferral);
});

const displayAdditionalBackupSection = computed((): boolean => {
  return !!(
    backupPageStore.pageState === backupPageState.default &&
    (visibleDevices.value.find(d => d.deviceId === 0) || wsacSafeLiteToProvision.value.length > 0)
  );
});

onBeforeMount(async () => {
  backupPageStore.pageState = backupPageState.default;
  await backupPageStore.populateCreditCards();
});

onMounted(async () => {
  try {
    notificationsStore.clearNotifications();
    displayPasswordResetSuccess();
    email.value = user.value?.email ?? "";

    if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
      notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
    }
    await loadPage(false);

    if (siteSettingsStore.moveMySubscriptionSuccess) {
      //if there is some success message to be shown, especially after page reload, show the message
      notificationsStore.addNotification({ type: "MoveMySubscriptionSuccess" });
      siteSettingsStore.moveMySubscriptionSuccess = false;
    }

    if (userStore.twoFactorAuthAddedFromFirstLogin) {
      notificationsStore.addNotification({ type: "SetTwoStepVerificationSuccess" });
      userStore.twoFactorAuthAddedFromFirstLogin = false;
    }

    if (userStore.currentUser?.subscriberInfo?.isUnderMaintenance) {
      notificationsStore.addNotification({ type: "UnderMaintenance" });
    }

    isMobileClient.value = isMobile();
    manageTrialUrl();
    logEvent("onMounted", name, wsacSafeLiteToProvision.value);
  } catch (err) {
    logException(err as Error);
  }
});

async function loadPage(force: boolean) {
  logEvent("loadPage", name, { force });

  if (isLoggedIn.value && user) {
    loading.value = true;
    // this notification is removed for WTP/WTPF because Webroot products allow indefinite renewal, unlike Safe
    notificationsStore.deleteNotificationByType("SubscriptionsExpired");
    await Promise.all([getUserDetailsFromStore(force), getSubscriptions()])
      .then(([userDetailsInfo]) => {
        if (userDetailsInfo) {
          userDetails.value = userDetailsInfo;
          logEvent("UserDetails", name, userDetails.value);
        }
      })
      .catch(error => {
        logException(error as Error, "Failed to retrieve UserDetails");
      });

    if (userDetails.value?.devices.some(d => d.hasFailedWebrootProvisioning)) {
      notificationsStore.addNotification({ type: "PartialRetrieveKeycodesFailed" });
    }
    prepareInvoiceDetailsData();
    if (paymentMethods.value.length <= 0) {
      loadPaymentMethods();
    }
    manageReinstallUrl();
    loading.value = false;
    await calculateInitialPageState();
  }
}

async function calculateInitialPageState() {
  let pageStateCurrent: backupPageState = backupPageState.default;
  if (userDetails.value) {
    const subscriberInfo = user.value?.subscriberInfo;
    //An Account with an BBY S2 keycode and no Safe subscriptions should not show the start a trial text
    //even if the Account also has a retail keycode
    if (subscriberInfo?.hasBestBuyS2 && !subscriberInfo.hasSafeSubscriptions) {
      backupPageStore.pageState = backupPageState.bestBuyOnly;
      logEvent("calculateInitialPageState - bestBuyS2 + Ecomm, No Safe", name, {
        pageState: backupPageStore.pageState,
      });
      return;
    }

    // filter purged computers and do not display it.
    const devices = userDetails.value?.devices.filter(
      d =>
        !isPurgedComputer(
          d.daysRemaining,
          d.isTrial,
          isResellerManaged.value === true,
          d.dataHasBeenPurged,
          d.isEvergreenMonthly
        )
    );

    logEvent("calculateInitialPageState", name, { devices, userDetails: userDetails.value });

    // offerTrial
    if (
      !visibleDevices.value ||
      visibleDevices.value?.length === 0 ||
      carboniteAndUnexpiredCDLPDevices.value?.length === 0
    ) {
      pageStateCurrent = backupPageState.offerTrial;
      logEvent("calculateInitialPageState - trial", name, { pageStateCurrent });
    }

    // uninstalledSub
    if (visibleDevices.value?.length !== 0 && !visibleDevices.value?.find(c => c.deviceId > 0)) {
      pageStateCurrent = backupPageState.uninstalledSub;
      logEvent("calculateInitialPageState - find", name, {
        uninstalled: !devices?.find(c => c.deviceId > 0),
        pageStateCurrent,
      });
    }

    // WSAC Safe Lite pending provision
    if ((!visibleDevices.value || visibleDevices.value?.length === 0) && wsacSafeLiteToProvision.value.length > 0) {
      pageStateCurrent = backupPageState.uninstalledSub;
      logEvent("calculateInitialPageState - pending", name, { pageStateCurrent });
    }
  }

  //If AC has been applied to a new sub, open download screen
  if (siteSettingsStore.activationCodeAppliedSuccess || siteSettingsStore.installDeviceFromSubscriptionPage) {
    const device = userDetails.value?.devices.find(device => device.activationCode === appliedAC.value);
    selectedDevice.value = device || undefined;
    pageStateCurrent = backupPageState.download;
    notificationsStore.clearNotifications();
    const logEventMessage = siteSettingsStore.activationCodeAppliedSuccess ? "applied" : "fromSubs";
    logEvent(`calculateInitialPageState - ${logEventMessage}`, name, { pageStateCurrent });
    if (siteSettingsStore.activationCodeAppliedSuccess) {
      notificationsStore.addNotification({ type: "ActivationCodeAppliedSuccess" });
      siteSettingsStore.activationCodeAppliedSuccess = false;
    } else {
      siteSettingsStore.installDeviceFromSubscriptionPage = false;
    }
    siteSettingsStore.activationCodeToInstall = "";
  }
  backupPageStore.pageState = pageStateCurrent;
  logEvent("calculateInitialPageState - nonBestBuy", name, { pageState: backupPageStore.pageState, pageStateCurrent });
}

function toggleCarbTile() {
  showCarbTile.value = !showCarbTile.value;
  show.value = showCarbTile.value;
}

function addCard() {
  backupPageStore.pageState = backupPageState.movePaymentMethodMRToARaddCard;
}

function shouldAutoRenew() {
  return !(currentSubscription.value?.isAutoRenewEnabled ?? false);
}

function upgradeAddSubscription() {
  notificationsStore.clearNotifications();
  backupPageStore.pageState = backupPageState.upgradeAdd;
  logEvent("upgradeAddSubscription", name, { pageState: backupPageStore.pageState });
}

async function closeUpgradeAddSubscription(action: string, success: boolean) {
  logEvent("closeUpgradeAddSubscription", name, { action, success });
  backupPageStore.pageState = backupPageState.default;
  notificationsStore.clearNotifications();
  if (action === "acApplied") {
    await loadPage(true);
    if (success) {
      notificationsStore.clearNotifications();
      notificationsStore.addNotification({ type: "ActivationCodeAppliedSuccess" });
    } else {
      notificationsStore.clearNotifications();
      notificationsStore.addNotification({ type: "ActivationCodeAppliedFailed" });
    }
  }
}

async function enableAutoRenew(paymentMethodId: string) {
  try {
    if (currentSubscription.value !== undefined) {
      const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
        allowAutoRenew: true,
        zuoraSubscriptionNumber: currentSubscription.value.zuoraSubscriptionNumber,
        surveyChoice: "",
        surveyChoiceCode: null,
        paymentMethodId: paymentMethodId,
      };
      const updated = (await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest)).data;
      if (updated) {
        notificationsStore.clearNotifications();
        notificationsStore.addNotification({
          type: "MovePaymentMethodMRToAR",
          params: { plan: currentSubscription.value.productDisplayName },
        });
        await showAutoRenewSuccess();
      }
    }
  } catch (error) {
    handleApiError(error as AxiosError);
  } finally {
    backupPageStore.pageState = subscriptionPageState.default;
  }
}

function showDownload(device: IDevice | undefined, downloadInstallType: string) {
  selectedDevice.value = device;
  installType.value = downloadInstallType;
  notificationsStore.clearNotifications();
  backupPageStore.pageState = backupPageState.download;
  logEvent("showDownload", name, {
    pageState: backupPageStore.pageState,
    deviceId: device?.deviceId,
    downloadInstallType,
  });
}

function showCardComputer(device: IDevice) {
  //purged computer card should not be visible in the backup screen
  return (
    device.deviceId > 0 &&
    !isPurgedComputer(
      device.daysRemaining,
      device.isTrial,
      isResellerManaged.value === true,
      device.dataHasBeenPurged,
      device.isEvergreenMonthly
    )
  );
}

async function prepareInvoiceDetailsData() {
  //if any of the device or webroot has as outstanding invoice
  const hasAnyOutstandingInvoice = visibleDevices.value.find(x => x.hasOutstandingInvoice);
  if (hasAnyOutstandingInvoice) {
    loading.value = true;
    //load payment methods details
    await loadPaymentMethods();
    loading.value = false;
  }
}

async function showOutstandingInvoiceDetails() {
  notificationsStore.clearNotifications();
  previousPageState.value = backupPageStore.pageState;
  backupPageStore.pageState = backupPageState.invoiceDetails;
  logEvent("showOutstandingInvoiceDetails", name, { pageState: backupPageStore.pageState });
}

async function returnPageToPreviousState() {
  notificationsStore.clearNotifications();
  backupPageStore.pageState = previousPageState.value ?? backupPageState.default;
  if (backupPageStore.pageState === backupPageState.default) {
    await loadPage(true);
  }
}

async function getSubscriptions() {
  try {
    const subs = await subHelper.getSubscriptions();
    if (subs) {
      subscriptions.value = subs;
      logEvent("Subscriptions: ", name, subscriptions.value);
    }
  } catch (error) {
    handleApiError(error as AxiosError, false);
  }
}

async function loadPaymentMethods() {
  try {
    paymentMethods.value = (await unifiedApi.getUserPaymentMethods()).data.paymentMethods;
    logEvent("setPaymentMethods", name, paymentMethods.value);
  } catch (error) {
    handleApiError(error as AxiosError);
  }
}

async function handleSuccessInvoicePaid() {
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: "RenewSuccessMessage" });
  //for all correct data, need to reload the page after successfully paid invoice, reloading the page a delay to give the user a chance to see the notification
  setTimeout(() => {
    window.location.reload();
  }, INVOICE_PAID_REFRESH_DELAY);
}

function changePaymentMethod() {
  notificationsStore.clearNotifications();
  backupPageStore.pageState = backupPageState.addPaymentMethod;
  logEvent("changePaymentMethod", name, { pageState: backupPageStore.pageState });
}

async function addPaymentMethodHandler(sub: ISubscription, paymentMethodId: string) {
  notificationsStore.clearNotifications();
  if (backupPageStore.pageState === backupPageState.movePaymentMethodMRToARaddCard) {
    try {
      const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
        allowAutoRenew: !sub.isAutoRenewEnabled,
        surveyChoice: "",
        zuoraSubscriptionNumber: sub.zuoraSubscriptionNumber,
        surveyChoiceCode: null,
        paymentMethodId: paymentMethodId,
      };

      await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest);

      notificationsStore.clearNotifications();
      logEvent("addPaymentMethodHandler", name, "updated");
      notificationsStore.addNotification({
        type: "MovePaymentMethodMRToAR",
        params: { plan: sub.productDisplayName },
      });
      loadPage(true);
    } catch (error) {
      notificationsStore.addNotification({ type: "AutoRenewTurnOnOrOffFailure" });
      handleApiError(error as AxiosError, true, "AutoRenewTurnOnOrOffFailure");
    }
  } else {
    notificationsStore.addNotification({ type: "AddCreditCardSuccess" });
  }
  buyFlowStore.showProcessingOrderMessage = false;
  //get latest subscriptions after adding new payment method
  getSubscriptions();
  backupPageStore.pageState = backupPageState.default;
  logEvent("addPaymentMethodHandler", name, { pageState: backupPageStore.pageState });
}

async function showAutoRenewSuccess() {
  backupPageStore.pageState = backupPageState.default;
  logEvent("showAutoRenewSuccess", name, { pageState: backupPageStore.pageState });
  loadPage(true);
}

async function showAddCardAutoRenewSuccess(sub: ISubscription) {
  backupPageStore.pageState = backupPageState.default;
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({
    type: "MovePaymentMethodMRToAR",
    params: { plan: sub.productDisplayName },
  });
  logEvent("showAddCardAutoRenewSuccess", name, { pageState: backupPageStore.pageState });
  loadPage(true);
}

async function updatePaymentMethods(creditCardsInfo: IPaymentMethod[]) {
  paymentMethods.value = creditCardsInfo;
  logEvent("updatePaymentMethods", name, paymentMethods.value);
}

function handleMoveFromMRtoAR(sub: ISubscription) {
  notificationsStore.clearNotifications();
  backupPageStore.pageState = backupPageState.movePaymentMethodMRToAR;
  logEvent("handleMoveFromMRtoAR", name, { pageState: backupPageStore.pageState });
  currentSubscription.value = sub;
}

function getComputerSubscriptions(device: IDevice): ISubscription | undefined {
  return subscriptions.value.find(s => s.zuoraSubscriptionNumber === device.zuoraSubscriptionNumber);
}

function manageReinstallUrl() {
  //if url contains Backup/computerId=$computerId$&action=reinstall show Download model and reinstall installer for the matching computerId
  if (route.query.computerId) {
    const computerId: number = parseInt(route.query.computerId?.toString());
    if (computerId > 0 && route.query.action?.toString().toLowerCase() === "reinstall") {
      computerIdToReinstall.value = computerId;
    }
  }
}

function manageTrialUrl() {
  //if url contains Backup/action=trial show Download model and download trial version
  if (route.query.action?.toString().toLowerCase() === "trial") {
    showDownload(undefined, "");
  }
}

function handleUpdateKeycode(request: IDeviceKeycodeUpdateRequest) {
  logEvent("handleUpdateKeycode", name, request);
  const deviceToUpdate = visibleDevices.value.find(l => l.deviceId === request.deviceId);

  if (deviceToUpdate) {
    deviceToUpdate.webrootKeycode = request.keycode;
  }
}

function goToInstallKnowledgeBaseLink() {
  logEvent("goToInstallKnowledgeBaseLink", name);
  let link =
    "https://support.carbonite.com/articles/Personal-Windows-Installing-Carbonite-and-Initial-Backup-Selections";

  if (isMac()) {
    link = "https://support.carbonite.com/articles/Personal-Mac-Installing-Carbonite-and-Initial-Backup-Selections";
  }

  window.open(link, "_blank");
}
</script>
