<template>
  <ModalWrapperComponent dialog-name="VerifyExistingEmailAddress" :show-dialog="props.showDialog" @close-modal="close">
    <template #header>
      <h1>{{ tn("Heading") }}</h1>
    </template>
    <template #content>
      <p class="mb">{{ tn("VerifyEmail") }}</p>
      <p class="mb">
        {{ tn("ClickWord") }}
        <strong>{{ tn("ButtonLabel") }}</strong>
        {{ tn("AfterButton") }}
        <strong>{{ userEmail }}</strong>
        {{ tn("From") }}
        <strong>{{ tn("CarbWebroot") }}.</strong>
      </p>
      <p class="mb">
        {{ tn("YouHave") }}
        <strong class="danger-text">{{ tn("TimeFrame") }}</strong>
        {{ tn("AfterTimeFrame") }}
      </p>

      <button
        id="btnVerificationEmail"
        type="button"
        class="btn-outline-primary"
        :disabled="isSaving || hasPendingEmailVerification"
        @click="sendVerificationEmail()"
      >
        <span v-if="!isSaving && !hasPendingEmailVerification">{{ tn("ButtonLabel") }}</span>
        <spinner :is-spinning="isSaving" />
        <span v-if="!isSaving && hasPendingEmailVerification">{{ tn("EmailSent") }}</span>
      </button>
    </template>
    <template #footer>
      <button id="btnClose" type="button" class="btn-outline-primary" @click="close()">{{ tn("close") }}</button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { unifiedApi } from "@/common";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { useNotificationsStore } from "@/stores/notifications";
import Spinner from "@/components/shared/Spinner.vue";
import { useUserStore } from "@/stores/user";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";

const componentName = "VerifyExistingEmailAddressComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal", "save-modal"]);

const userStore = useUserStore();
const userEmail = computed(() => {
  return userStore.currentUser?.email;
});
logEvent("Created", componentName);
const notificationsStore = useNotificationsStore();
const isSaving = ref<boolean>(false);
const hasPendingEmailVerification = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function sendVerificationEmail() {
  isSaving.value = true;
  notificationsStore.clearNotifications();
  try {
    await unifiedApi.resendVerificationEmail();
    hasPendingEmailVerification.value = true;
    notificationsStore.addNotification({ type: "PendingEmailVerification" });
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, componentName, "sendVerificationEmail failed");
    handleApiError(error as AxiosError);
    notificationsStore.clearNotifications();
    hasPendingEmailVerification.value = false;
  }
  isSaving.value = false;
}

function close() {
  emits("close-modal");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
