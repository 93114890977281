<template>
  <div>This is the Paypal Notify Component</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { logEvent, logException } from "@/common/logger";
const componentName = "NotifyComponent";

logEvent("created", componentName);

onMounted(async () => {
  try {
    logEvent("mounted", componentName);
  } catch (err) {
    logException(err as Error, componentName);
  }
});
onUnmounted(() => {
  logEvent("unmounted", componentName);
});
</script>
