import { MAX_EMAIL_LENGTH, MIN_EMAIL_LENGTH } from "@/define";
import { IValidationResult, IValidationRule, validate } from "./validator";
import { isEmailWithTLDRegex } from "@/globalRegex";

const rules: IValidationRule<string>[] = [
  { name: "CommonErrors.emailMoreThanOneAt", test: e => (e?.match(/@/g) || []).length < 2 },
  { name: "CommonErrors.emailFollowTLDPattern", test: e => isEmailWithTLDRegex.test(e) },
  {
    name: "CommonErrors.emailLengthGreaterThanMin",
    param: { minLength: MIN_EMAIL_LENGTH },
    test: e => e?.length >= MIN_EMAIL_LENGTH,
  },
  {
    name: "CommonErrors.emailLengthLessThanMax",
    param: { maxLength: MAX_EMAIL_LENGTH },
    test: e => e?.length < MAX_EMAIL_LENGTH,
  },
  //{ name: "CommonErrors.invalidCharacters", test: e => emailCharactersRegex.test(e) },
];

export function isValidEmail(email: string): IValidationResult[] {
  // Email address can only contain one @ symbol.
  // Email must follow user@domain.com (ie, have a top level domain)
  // Email address needs to be at least min characters long.
  // Email needs to be less than max characters
  return validate<string>(email, rules);
}
