<template>
  <ModalWrapperComponent dialog-name="MoveMySubscriptionComponent" :show-dialog="showDialog" @close-modal="closeModal">
    <template #header>
      <span v-if="isCdlp">{{ tn("headerCdlpH1") }}</span>
      <span v-else>{{ tn("headerH1") }}</span>
    </template>
    <template #content>
      <p>
        {{ tn("moveNicknameLabel") }}
        <strong>{{ nickname }}</strong>
      </p>
      <p v-if="isCdlp">{{ tn("moveNicknameCdlpInstruction") }}</p>
      <p v-else>{{ tn("moveNicknameInstruction") }}</p>
      <h3 class="mb">{{ tn("moveNicknameOptions") }}</h3>
      <ul class="form">
        <li class="radio mb-0">
          <input id="newbackup" type="radio" name="method" value="newbackup" @click="setInstallType('Transfer')" />

          <i18n-t
            v-if="isCdlp"
            keypath="WindowsMoveSubscriptionComponent.moveAndBackupCdlp"
            tag="label"
            for="newbackup"
            scope="global"
          >
            <template #backup>
              <strong>{{ tn("startNewBackup") }}</strong>
            </template>
          </i18n-t>
          <i18n-t
            v-else
            keypath="WindowsMoveSubscriptionComponent.moveAndBackup"
            tag="label"
            for="newbackup"
            scope="global"
          >
            <template #backup>
              <strong>{{ tn("startNewBackup") }}</strong>
            </template>
          </i18n-t>
        </li>
        <li>
          <p class="text-grey text-sm indent">{{ t("WindowsMoveSubscriptionComponent.moveAndNote") }}</p>
        </li>
        <li class="radio">
          <input id="recover" type="radio" name="method" value="recover" @click="setInstallType('Recover')" />
          <i18n-t
            v-if="isCdlp"
            keypath="WindowsMoveSubscriptionComponent.moveAndTransferCdlp"
            tag="label"
            for="recover"
            scope="global"
          >
            <template #transfer>
              <strong>{{ tn("transferFiles") }}</strong>
            </template>
          </i18n-t>
          <i18n-t
            v-else
            keypath="WindowsMoveSubscriptionComponent.moveAndTransfer"
            tag="label"
            for="recover"
            scope="global"
          >
            <template #transfer>
              <strong>{{ tn("transferFiles") }}</strong>
            </template>
          </i18n-t>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr-half" @click="closeModal()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button
        id="btnSaveChanges"
        type="button"
        :disabled="!isOptionSelected"
        class="btn-primary"
        @click="handleSaveModal()"
      >
        {{ tn("next") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
//cSpell:ignore newbackup

import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { useRouter } from "vue-router";

const componentName = "WindowsMoveSubscriptionComponent";
const router = useRouter();

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
  isCdlp: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save", "cancel"]);

logEvent("created", componentName, props.device);
const nickname = ref<string>(props.device.description);
const installType = ref<string>("");

const isOptionSelected = computed(() => {
  return installType.value;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function setInstallType(type: string) {
  installType.value = type;
}

function handleSaveModal() {
  if (props.isCdlp) {
    router.push(`/backup/download/${props.device?.zuoraSubscriptionNumber}/${installType.value}`);
  } else {
    emits("save", installType.value);
  }
}

function closeModal() {
  emits("cancel");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
.danger-text {
  margin-left: 27px;
}
</style>
