<template>
  <file-access-component v-if="showFileAccess" :computer-id="computerId" :path="path" />
  <div v-else></div>
</template>

<script setup lang="ts">
import { onBeforeMount, computed, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import FileAccessComponent from "@/components/FileAccess/FileAccess.vue";
import { FLAGS } from "@/define";
import { logEvent } from "@/common/logger";

const componentName = "FileAccessRoute";

const router = useRouter();
const q = router.currentRoute.value.query;
const computerId = ref<string>((q.computerUid || q.computeruid) as string);
const path = ref<string>(q.path as string);

logEvent("starting", componentName, q);

const showFileAccess = computed(() => {
  return FLAGS.SHOW_RFA_COMPONENT === "true";
});

onBeforeMount(() => {
  if (showFileAccess.value) {
    return;
  }
  router.replace("/backup");
});

//watch for changes in the route parameters and pass them on

watchEffect(() => {
  const q = router.currentRoute.value.query;
  computerId.value = (q.computeruid || q.computerUid) as string;
});

watchEffect(() => {
  path.value = router.currentRoute.value.query.path as string;
});
</script>
