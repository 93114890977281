<template>
  <div>
    <p>
      <strong>{{ tn("autoRenewForLine1") }}</strong>
    </p>
    <ul class="tight mt mb">
      <li>
        <em>{{ tn("subscription") }}</em>
        {{ subscriptionName }}
      </li>
      <li>
        <div v-if="isWebrootProduct(subscription)">
          <em>{{ t("Common.keycode") }}:</em>
          {{ keyCode }}
        </div>
        <div v-else>
          <em>{{ tn("computer") }}</em>
          {{ computerName }}
        </div>
      </li>
      <li>
        <em>{{ tn("willRenewOn") }}</em>
        {{ formatExpirationDate(expiresOn) }}
      </li>
    </ul>
    <p>{{ tn("paymentChoice") }}</p>
  </div>
</template>
<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { logEvent } from "@/common/logger";
import { formatDateString } from "@/common/dateFormat";
import { t } from "@/i18n";
import { isWebrootProduct } from "@/common/subscriptionHelper";

const componentName = "AutoRenewTurnOnComponent";

const props = defineProps({
  subscription: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

logEvent("created", componentName, props.subscription);

const subscriptionName = ref<string>(props.subscription?.productDisplayName ?? "");
const expiresOn = ref<string>(props.subscription?.expirationDate ?? "");
const creditCardType = ref<string>(props.subscription?.paymentInfo?.cardType ?? "");
const showPaymentMethod = ref<boolean>(false);

if (creditCardType.value) {
  showPaymentMethod.value = true;
}

const computerName = computed(() => {
  return props.subscription?.computer?.description ?? props.subscription?.computer?.name ?? "";
});

const keyCode = computed(() => {
  return props.subscription?.keyCode || "";
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function formatExpirationDate(expirationDate: string) {
  return formatDateString(expirationDate);
}
</script>
