<template>
  <div></div>
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

onBeforeMount(async () => {
  router.push("/home");
});
</script>
