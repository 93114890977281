<template>
  <TileWrapper
    v-if="!isLoading"
    :key="td.status"
    :is-loading="isLoading"
    :tile-data="td"
    notification-context="SecurityOverviewComponent"
  >
    <template #title>{{ t(`${textRoot}${td.title}`) }}</template>

    <template #content>
      <RequiresInstallOrPurchaseComponent
        v-if="showRequiresInstallOrPurchase"
        :tile-data="td"
        @button-action="installOrCompare()"
      />

      <TileDetails v-else :path="td.detailsLink" :title="td.title">
        <template #content>
          <ul>
            <li v-for="(item, i) in td.items" :key="i" :class="calcItemClass(item)">
              <span>{{ t(`${textRoot}${item.caption}`) }}</span>
              <b v-if="td.type === 'tile'">{{ convertAmount(item) }}</b>
              <em v-if="td.type === 'tile'">{{ convertUnitType(item) }}</em>
              <SvgIconComponent :icon="item.iconType || ''" />
            </li>
          </ul>
        </template>
      </TileDetails>
    </template>
  </TileWrapper>
</template>

<script setup lang="ts">
import { ref, PropType, computed, watch, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { useSecurityStore } from "@/stores/security";
import { IconType, TileType, TileTitle, UnitType, StatusType, StateType, ZuoraSubscriptionStatus } from "@/globalEnums";
import { calculateStatus, hasOnlyUninstalledDevices, getInstalledDevices } from "@/common/securityDevices";
import TileWrapper from "@/components/shared/TileWrapper.vue";
import TileDetails from "@/components/shared/TileDetails.vue";
import RequiresInstallOrPurchaseComponent from "@/components/Home/RequiresInstallOrPurchase.vue";
import { hasOnlyBestBuyS2 } from "@/common/bestBuy";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { webrootLCNs } from "@/common/webrootProductName";
import { getUrl } from "@/common/getUrl";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { ITileData, i18nRoot, calcItemClass, convertAmount, convertUnitType } from "@/components/Home";
import { EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY } from "@/define";

const name = "SecurityOverviewComponent";

const props = defineProps({
  subscriptionStatusType: {
    type: String as PropType<StatusType>,
    default: StatusType.normal,
  },
  needsToValidateEmail: {
    type: Boolean,
    required: false,
  },
});

const emits = defineEmits(["tile-ready", "tile-count", "show-verify-email-dialog"]);

logEvent("created", name);
const textRoot = ref<string>(i18nRoot);
const router = useRouter();
const securityStore = useSecurityStore();
const isLoading = ref(true);
const td = ref<ITileData>({
  title: "securityOverview",
  type: TileType.tile,
  status: StatusType.normal,
  state: StateType.installed,
});
const subscriptionStatusType = computed<StatusType>(() => props.subscriptionStatusType);
const isBestBuy = ref<boolean>(false);
const subscriptionsStore = useSubscriptionsStore();

onBeforeMount(async () => {
  try {
    emits("tile-count");
    isBestBuy.value = await hasOnlyBestBuyS2();
    await getSecuritySummary();
    isLoading.value = false;
    emits("tile-ready");
  } catch (ex) {
    logException(ex as Error);
  }
});

async function getSecuritySummary() {
  try {
    // get user's devices
    const response = await securityStore.getSecurity();
    // Regular
    if (response.totalDevices > 0) {
      if (hasOnlyUninstalledDevices(response.webrootDeviceResult ?? [])) {
        renderInstall();
        return;
      }

      td.value = {
        title: TileTitle.securityOverview,
        type: TileType.tile,
        status: calculateStatus(response.webrootDeviceResult ?? []),
        state: StateType.installed,
        items: [
          {
            caption: "protecting",
            value: getInstalledDevices(response.webrootDeviceResult ?? []).length, // using scale of 1000s
            unitType: UnitType.computers,
            iconType: IconType.devices,
          },
          {
            caption: "threatProtection",
            value: response.totalEnabledShields,
            maxValue: response.totalShields,
            unitType: UnitType.shields,
            iconType: IconType.threat,
          },
        ],
        detailsLink: "/security",
      };
    } else {
      await renderInstallOrCompare();
    }
  } catch (error) {
    const err = error as Error;
    if (err.message.includes("status code 404")) {
      await renderInstallOrCompare();
    } else {
      td.value = {
        title: TileTitle.securityOverview,
        type: TileType.tile,
        status: StatusType.warning,
        state: StateType.serverError,
      };
    }
  }
}

const showRequiresInstallOrPurchase = computed(() => {
  return (
    td.value.state === StateType.notPurchased ||
    td.value.state === StateType.requiresInstall ||
    td.value.state === StateType.securityBlocked
  );
});

function installOrCompare() {
  if (td.value.state === StateType.requiresInstall) {
    router.push("/downloads");
  } else if (td.value.state === StateType.securityBlocked) {
    emits("show-verify-email-dialog");
  } else {
    const url = isBestBuy.value ? getUrl("BESTBUY_URL") : getUrl("WEBROOT_LEARN_MORE");
    window.open(url);
  }
}

async function renderInstallOrCompare() {
  await subscriptionsStore.populateSubscriptions();
  const webrootSubscriptions = subscriptionsStore.subscriptions.filter(
    w =>
      (webrootLCNs.includes(w.licenseCategoryName ?? "") &&
        !EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY.includes(w.licenseCategoryName ?? "")) ||
      w.sku === "PersonalPlus" ||
      w.sku === "PersonalPrime"
  );

  const hasActiveWebroot = webrootSubscriptions.some(
    w =>
      w.remainingDays > 0 &&
      (w.webrootStatusDescription?.toLowerCase() === "active" ||
        w.sku === "PersonalPlus" ||
        w.sku === "PersonalPrime") &&
      w.zuoraSubscriptionStatus !== ZuoraSubscriptionStatus.Cancelled
  );

  const hasInactiveWebroot = webrootSubscriptions.every(w => w.webrootStatusDescription?.toLowerCase() === "inactive");
  if (hasActiveWebroot) {
    renderInstall();
  } else if (webrootSubscriptions.length > 0 && hasInactiveWebroot) {
    renderInstallAndActivate();
  } else if (props.needsToValidateEmail) {
    renderShowVerifyEmail();
  } else {
    renderCompare();
  }
}

function renderInstall() {
  // Install
  td.value = {
    title: TileTitle.securityOverview,
    type: TileType.tile,
    status: StatusType.normal,
    state: StateType.requiresInstall,
    installData: {
      logo: "webroot-logo",
      mainText: "securityZeroStateText",
      installButtonText: "install",
      installText: "installText",
    },
    detailsLink: "/downloads",
  };
}

function renderInstallAndActivate() {
  // Install
  td.value = {
    title: TileTitle.securityOverview,
    type: TileType.tile,
    status: StatusType.normal,
    state: StateType.requiresInstall,
    installData: {
      logo: "webroot-logo",
      mainText: "securityZeroStateText",
      installButtonText: "installAndActivate",
      installText: "installText",
    },
    detailsLink: "/downloads",
  };
}

function renderCompare() {
  td.value = {
    title: TileTitle.securityOverview,
    type: TileType.tile,
    status: StatusType.normal,
    state: StateType.notPurchased,
    installData: {
      logo: "webroot-logo",
      mainText: "securityNotPurchasedP1",
      auxText: "securityNotPurchasedP2",
      protectText: "protectYourSelfWithWebroot",
      installButtonText: isBestBuy.value ? "comparePlansBBY" : "comparePlans",
      installText: "",
    },
    detailsLink: "/security",
  };
}

function renderShowVerifyEmail() {
  td.value = {
    title: TileTitle.securityOverview,
    type: TileType.tile,
    status: StatusType.normal,
    state: StateType.securityBlocked,
    installData: {
      logo: "webroot-logo",
      mainText: "securityEmailNotValidated",
      installButtonText: "btnVerifyEmail",
      installText: "",
    },
  };
}

watch(subscriptionStatusType, () => {
  td.value.status = subscriptionStatusType.value;
});
</script>
