<template>
  <div>
    <svg-icon-component icon="circle-exclamation" class="icon-sm danger mr-half" />
  </div>
  <div class="truncate-flex-child-wrapper">
    <div>
      <i18n-t keypath="OutstandingInvoiceAlertComponent.safeLine1" tag="strong" scope="global">
        <template #outstandingDays>
          {{ daysToDeleteBackup(props.sub) }}
        </template>
      </i18n-t>
      <i18n-t keypath="OutstandingInvoiceAlertComponent.line2" tag="div" scope="global">
        <template #invoiceNumber>
          <strong>{{ props.sub?.outstandingInvoice?.invoiceNumber }}</strong>
        </template>
      </i18n-t>
    </div>
    <i18n-t keypath="OutstandingInvoiceAlertComponent.safeLine3" tag="div" scope="global" class="truncate-flex">
      <template #productName>
        {{ props.sub.productDisplayName }}
      </template>
      <template #computerName>
        <strong>{{ props.sub?.computer?.description }}</strong>
      </template>
    </i18n-t>
  </div>
  <div class="action">
    <button class="btn-outline-primary" @click="renewSubscription(props.sub)">{{ tn("btnRenewNow") }}</button>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { daysToDeleteBackup } from "@/common/subscriptionHelper";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const baseComponent = "OutstandingInvoiceAlertComponent";

const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

const emits = defineEmits(["renew-safe"]);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponent}.${v}`, params);
}

function renewSubscription(sub: ISubscription) {
  emits("renew-safe", sub);
}
</script>
