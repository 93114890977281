// *************
// image preview functions
// *************

import { ref } from "vue";
import { calcAlert } from "./viewFn";
import { IRfaItem } from "./interfaces";
import { isDownloading } from "./downloadFn";
import { allItems, currentItems } from "./commonFn";
import { downloadFile, downloadImage, updateItemWithThumbnail } from "./serverComm";

export const THUMBNAIL_SIZE = 124;
export const PREVIEW_SIZE = 648;
export const showPreview = ref<boolean>(false);
export const previewItem = ref<IRfaItem>();

//download the full image of the image being previewed
export async function downloadPreview() {
  if (previewItem.value) {
    isDownloading.value = true;
    await downloadFile(
      { path: previewItem.value.DownloadUrl, name: previewItem.value.Name },
      { fileIds: [previewItem.value.FileId.toString()] }
    );
    isDownloading.value = false;
  }
}

//get a larger thumbnail for the preview dialog
export async function setPreviewImage(item: IRfaItem) {
  //download a new thumbnail (648) and use that from now on
  //display the thumbnail in the preview dialog
  if (item.ImageSize == THUMBNAIL_SIZE) {
    if (item.IsImage) {
      const blob = await downloadImage({ path: item.ThumbnailUrl, size: PREVIEW_SIZE, fileId: item.FileId });
      updateItemWithThumbnail({ item: item, image: blob, size: PREVIEW_SIZE });
    }
  }
  if (!item.IsInvalidImage) {
    previewItem.value = item;
    showPreview.value = true;
  }
}

//move between preview images
//direction is either +1 or -1
export function navPreview(direction: number) {
  //figure out how many items are visible and which one we're showing
  const maxIndex = allItems.value.length;
  const curIndex = allItems.value.findIndex(i => i.FileId == previewItem.value?.FileId);

  //iterate through all items
  for (let i = 1; i < maxIndex; i++) {
    //index is the 'next' one (depending on direction)
    let index = curIndex + direction * i;
    //if index is out of bounds, wrap around
    if (index >= maxIndex || index < 0) index = curIndex + direction * i - direction * maxIndex;
    //if the item at this location is an image
    if (allItems.value[index].IsImage && !allItems.value[index].IsInvalidImage) {
      //then this is the new preview and exit
      setPreviewImage(allItems.value[index]);
      break;
    }
  }
}

export async function getCurrentItemImagesAndAlerts(min: number, max: number) {
  //BackupRuns and BackupSets need to be sliced.
  //Directories and files are return sliced from the API through query params.
  if (
    allItems.value.length > 0 &&
    allItems.value[0].IsSMB &&
    !allItems.value[0].IsBackupRun &&
    !allItems.value[0].IsBackupSet
  ) {
    currentItems.value = allItems.value;
  } else {
    currentItems.value = allItems.value.slice(min, max);
  }

  max = currentItems.value.length;
  //if any of the contents are images, then download a thumbnail in case the user switches to tile view
  const downloads: Promise<void>[] = [];
  for (let i = 0; i < max; i++) {
    const item = currentItems.value[i];
    if (item) {
      if (item.IsImage && !item.Base64Image) {
        downloads.push(getItemThumbnail(item));
      }
      //if there is some kind of alert message to display, add it to the item here
      calcAlert(item);
    }
  }
  await Promise.all(downloads);
}

export async function getItemThumbnail(item: IRfaItem) {
  const blob = await downloadImage({ path: item.ThumbnailUrl, size: PREVIEW_SIZE, fileId: item.FileId });
  updateItemWithThumbnail({ item: item, image: blob, size: THUMBNAIL_SIZE });
}
