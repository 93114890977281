<template>
  <div>
    <NotificationsComponent context="FinalizeAccount" />
    <div class="content">
      <h1>{{ tn("headerH1") }}</h1>
      <h3 v-show="userInfo?.userEmail" class="mb truncate">{{ tn("welcome", { email: userInfo?.userEmail }) }}</h3>
      <p v-show="!showLoginMessage && !passwordSaved" class="mb">{{ tn("pleaseEnter") }}</p>
      <p v-show="showLoginMessage" class="mb">
        {{ tn("needToLogin") }}
        <a :href="cpLogin">{{ tn("login") }}</a>
      </p>
      <p v-show="passwordSaved" class="mb">
        {{ tn("passwordAdded") }}
        <a :href="cpLogin">{{ tn("login") }}</a>
      </p>
      <ul v-show="!showLoginMessage && !passwordSaved" class="form">
        <li>
          <div class="label-wrapper">
            <label for="new-password">{{ tn("password") }}</label>
            <button
              v-if="hidePassword"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="hidePassword = !hidePassword"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="hidePassword = !hidePassword"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="passwordErrors && passwordErrors.length > 0">
            <input
              id="new-password"
              v-model.trim="newPassword"
              :type="hidePassword ? 'password' : 'text'"
              autocomplete="off"
              @keyup="dirtyFlagPassword = true"
            />
            <template #error>
              <div>
                <span v-for="e in passwordErrors" :key="e.name">{{ e.message }}</span>
              </div>
            </template>
          </InputErrorComponent>
          <p class="mt-quarter">{{ tn("passwordRequirements") }}</p>
          <PasswordRulesComponent :password="newPassword" :show-rules="false" />
        </li>
        <li>
          <div class="label-wrapper">
            <label for="confirm-new-password">{{ tn("confirmNewPassword") }}</label>
            <button
              v-if="hideConfirmPassword"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="hideConfirmPassword = !hideConfirmPassword"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="hideConfirmPassword = !hideConfirmPassword"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="showPasswordConfirmError">
            <input
              id="confirm-new-password"
              v-model.trim="newPasswordConfirm"
              :type="hideConfirmPassword ? 'password' : 'text'"
              autocomplete="off"
              @keyup="dirtyFlagConfirmPassword = true"
            />
            <template #error>
              <div>
                <span>{{ tn("passwordMatch") }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <div class="mb-double">
            <button id="btnFinish" class="btn-primary" :disabled="!isPasswordValid || isSaving" @click="save()">
              <spinner :is-spinning="isSaving" />
              {{ tn("finishSetup") }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { logEvent, logException } from "@/common/logger";
import { useRoute } from "vue-router";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { validatePassword } from "@/common/strongPassword";
import { passwordRegex } from "@/globalRegex";
import Spinner from "@/components/shared/Spinner.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import PasswordRulesComponent from "@/components/shared/PasswordRules.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { reportStringErrors } from "@/common/validator";
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, URLS } from "@/define";
import { INewAccountInfoResponse } from "@/common/api/unifiedPortal/interfaces";
import { useNotificationsStore } from "@/stores/notifications";
import NotificationsComponent from "@/components/shared/Notifications.vue";

const componentName = "FinalizeAccount";
logEvent(componentName);

const isSaving = ref<boolean>(false);
const passwordSaved = ref<boolean>(false);
const showLoginMessage = ref<boolean>(true);
const newPassword = ref<string>("");
const newPasswordConfirm = ref<string>("");
const dirtyFlagPassword = ref<boolean>(false);
const dirtyFlagConfirmPassword = ref<boolean>(false);
const hidePassword = ref(true);
const hideConfirmPassword = ref(true);
const route = useRoute();
const guid = ref("");
const userInfo = ref<INewAccountInfoResponse>();
const cpLogin = URLS.CP_LOGIN;
const notificationsStore = useNotificationsStore();

onBeforeMount(async () => {
  try {
    logEvent("mounted", componentName);

    if (route.query?.guid) {
      guid.value = route.query?.guid.toString();
      userInfo.value = await getUserInfo(guid.value);
      showLoginMessage.value = userInfo.value?.hasPassword ?? false;
    }
  } catch (err) {
    logException(err as Error, componentName);
  }
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function getUserInfo(guid: string) {
  return (await unifiedApi.getNewAccountStatus(guid)).data;
}

const isPasswordValid = computed(() => {
  return (
    newPassword.value &&
    newPasswordConfirm.value &&
    !showPasswordConfirmError.value &&
    passwordErrors.value.length === 0
  );
});

const showPasswordConfirmError = computed(() => {
  return !!newPassword.value && !!dirtyFlagConfirmPassword.value && newPassword.value !== newPasswordConfirm.value;
});

const passwordInvalidCharacterError = computed(() => {
  return reportStringErrors(
    newPassword.value,
    tn("newPassword"),
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH,
    passwordRegex
  );
});

const passwordErrors = computed(() => {
  const result = validatePassword(newPassword.value);

  //Piggybacking reportStringErrors on top of validatePassword to return
  //error message that displays the invalid characters used.
  result.push({
    name: "invalid_characters",
    message: passwordInvalidCharacterError.value,
    displayOrder: 5,
    passed: passwordInvalidCharacterError.value.length === 0,
  });

  const isNotValidPassword = result.some(e => !e.passed) && dirtyFlagPassword.value === true;
  return isNotValidPassword ? [result.filter(e => !e.passed)[0]] : [];
});

function resetFlags() {
  dirtyFlagPassword.value = false;
  dirtyFlagConfirmPassword.value = false;
}

async function save() {
  isSaving.value = true;
  try {
    if (isPasswordValid.value) {
      const response = (await unifiedApi.setNewAccountPassword(guid.value, newPassword.value)).data;
      if (!response || !response.success) {
        logEvent("SetPasswordFailure", `${componentName}`);

        notificationsStore.clearNotifications();
        notificationsStore.addNotification({ type: "NewAccountPasswordError" });
      } else {
        passwordSaved.value = true;
      }
    }
  } catch (error) {
    logEvent("NewAccountPasswordError", `${componentName}`);
    handleApiError(error as AxiosError, true, "NewAccountPasswordError");
  }
  resetFlags();
  isSaving.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
