import { logEvent, logException } from "@/common/logger";
import {
  showFullPageError,
  fullPageErrorType,
  propData,
  currentCSSBGetComputerContentsRequest,
  allItems,
} from "./commonFn";
import { FullPageErrorTypes } from "./FileAccessEnums";
import { unifiedApi } from "@/common";
import {
  ICollectionParams,
  IComputerContents,
  IFileDownload,
  IImageDownload,
  IItemCollection,
  IRfaComputer,
  IRfaItem,
  IRfaSelectionInfo,
  ISearchRequest,
  IThumbnailUpdate,
  ICssbRfaItems,
  ICssbDetailsRequest,
  ICssbDownloadInfoRequest,
  ICssbRfaBackupRestoreRequest,
  IFile,
  ICssbDownloadInfoResponse,
} from "./interfaces";

import { currentPage, currentPageSize, currentPageStart } from "./paginationFn";
import { computer, accountComputers, selectedDisk } from "./diskFn";
import { selectionInfo } from "./selectionFn";
import { cssbDownloadError, showEncryptedError } from "./downloadFn";
import { savedSortDirection, savedSortField, sortDirection, sortField, sortItemsSmb } from "./sortFn";
import { calledSearchText, isSearching } from "./searchFn";
import { AxiosError } from "axios";
import { hierarchyLevel } from "./navigationFn";

const componentName = "FileAccess.CssbServerComm";
const MAX_CSSB_ITEMS = 10000; //CSSB API throws errors if you try to get files past 10,000

export async function requestSessionKey(): Promise<void> {
  //no session key required for cssb. We're already communicating with api to get this far.
  logEvent("requesting session key", componentName);
  return;
}

export async function checkHealth(): Promise<boolean> {
  logEvent("checking rfa server health", componentName);
  //Keep the session alive every 15 minutes. If the user has large downloads processing the main window could auto log out before they are complete
  setInterval(() => {
    unifiedApi.getUser();
  }, 900000);
  return true;
}

export async function getComputer(): Promise<IRfaComputer | undefined> {
  cssbDownloadError.value = "";
  showEncryptedError.value = false;
  try {
    //Get all the server names for the Account
    const servers = await (await unifiedApi.getCssbServers()).data;
    accountComputers.value = [];

    servers.forEach(l => {
      accountComputers.value.push(l.computerName);
    });

    const rfaComputer: IRfaComputer = {
      ComputerName: propData.value.computerName,
      HardDisks: [],
      ComputerId: 0,
    };

    return rfaComputer;
  } catch {
    showFullPageError.value = true;
    fullPageErrorType.value = FullPageErrorTypes.noData;
    return undefined;
  }
}

export async function getComputerContents(cc: IComputerContents): Promise<IRfaItem[]> {
  cssbDownloadError.value = "";
  showEncryptedError.value = false;
  if (cc) {
    //setting and remembering sorting values
    sortItemsSmb(savedSortField.value[hierarchyLevel.value], savedSortDirection.value[hierarchyLevel.value], true);
    currentCSSBGetComputerContentsRequest.value = cc;

    const orderBy = sortDirection.value === 1 ? "asc" : "desc";
    const field = sortField.value.toLowerCase();

    try {
      if (cc.path === cc.computerName) {
        const backupSetData = await (await unifiedApi.getCssbBackupSets(cc.computerName, field, orderBy)).data;
        return mapCssbItemsToRfaItems(backupSetData.cssbRfaItems, backupSetData.totalItems);
      }

      if (cc.currentDisk && cc.currentDisk.IsBackupSet) {
        const backupRuns = await (
          await unifiedApi.getCssbBackupRuns(cc.computerName, cc.currentDisk.Name, field, orderBy)
        ).data;
        return mapCssbItemsToRfaItems(backupRuns.cssbRfaItems, backupRuns.totalItems);
      }

      if (cc.currentDisk && cc.currentDisk.IsBackupRun) {
        let path = "";
        if (isSearching.value) {
          path = cc.path === cc.currentDisk.Name ? (calledSearchText.value ?? "") : cc.path + calledSearchText.value;
        } else {
          path = cc.path === cc.currentDisk.Name ? "" : cc.path;
        }

        let pageStart = currentPage.value <= 1 ? 0 : currentPageStart.value;
        if (allItems.value.length > 0 && allItems.value[0].TotalItems > 0 && pageStart > allItems.value[0].TotalItems) {
          pageStart = allItems.value[0].TotalItems - currentPageSize.value;
        }
        if (pageStart < 0) pageStart = 0;

        const request = {
          computerName: cc.computerName,
          backupSetName: cc.currentDisk.Name,
          path: path, //If the path is the same as the backup run name set it to empty string
          timeStamp: cc.currentDisk.UnixTime,
          numberOfFilesPerPage: currentPageSize.value,
          pageStart: pageStart, //Page from CSSB start on 0
          isSearch: isSearching.value,
          sort: field,
          orderBy: orderBy,
        } as ICssbDetailsRequest;

        const backupRuns = await (await unifiedApi.getCssbBackupRunDetails(request)).data;
        showFullPageError.value = false;
        return mapCssbItemsToRfaItems(backupRuns.cssbRfaItems, backupRuns.totalItems);
      }
    } catch {
      showFullPageError.value = true;
      fullPageErrorType.value = FullPageErrorTypes.folderEmpty;
      return [];
    }
  }
  return [];
}

export async function getSearchResults(sr: ISearchRequest): Promise<IRfaItem[]> {
  cssbDownloadError.value = "";
  showEncryptedError.value = false;
  //setting and remembering sorting values
  sortItemsSmb(savedSortField.value[hierarchyLevel.value], savedSortDirection.value[hierarchyLevel.value], true);
  const orderBy = sortDirection.value === 1 ? "asc" : "desc";
  if (sr) {
    try {
      const request = {
        path: sr.path + sr.filter,
        pageStart: 0,
        numberOfFilesPerPage: sr.backupRunTime <= 0 ? 100 : currentPageSize.value,
        computerName: propData.value.computerName,
        backupSetName: sr.backupSetName,
        timeStamp: sr.backupRunTime,
        isSearch: true,
        sort: sortField.value,
        orderBy: orderBy,
      } as ICssbDetailsRequest;

      const response = await (await unifiedApi.getCssbBackupRunDetails(request)).data;
      calledSearchText.value = sr.filter;
      showFullPageError.value = false;

      //If there is no backup run time stamp on the request we are looking across backup runs and need to group the search results
      if (request.timeStamp <= 0) {
        return mapAndGroupSearchResults(response.cssbRfaItems, response.totalItems);
      } else {
        return mapCssbItemsToRfaItems(response.cssbRfaItems, response.totalItems);
      }
    } catch {
      //API returns 404 if there are no results
      return [];
    }
  }
  return [];
}

export async function getSelectionInfo(ic: IItemCollection): Promise<IRfaSelectionInfo | undefined> {
  if (ic) return selectionInfo.value;
  return undefined;
}

export async function downloadFile(fd: IFileDownload) {
  try {
    cssbDownloadError.value = "";
    showEncryptedError.value = false;
    let downloadRequest: ICssbDownloadInfoRequest;
    const computerName = propData.value.computerName;
    const backupSetName = selectedDisk.value?.Name ?? fd.name;
    if (fd.item && fd.item.IsBackupRun) {
      //Get the data for the backup run to the max number of files
      const request = {
        computerName: computerName,
        backupSetName: backupSetName,
        path: fd.item.Path,
        timeStamp: fd.item.UnixTime,
        pageStart: 0,
        numberOfFilesPerPage: MAX_CSSB_ITEMS,
      } as ICssbDetailsRequest;

      const backupRunData = await (await unifiedApi.getCssbBackupRunDetails(request)).data;
      downloadRequest = mapDownloadRequest(
        mapCssbItemsToRfaItems(backupRunData.cssbRfaItems, backupRunData.totalItems),
        fd.passPhrase
      );
    } else {
      let items: IRfaItem[] = [];
      //Single item download
      if (fd.item) {
        items.push(fd.item);
        //Multi item download
      } else if (fd.multipleItems && fd.multipleItems.length > 0) {
        items = fd.multipleItems;
      }

      downloadRequest = mapDownloadRequest(items, fd.passPhrase);
    }

    const response = await (await unifiedApi.addCssbDownloadInfo(downloadRequest)).data;
    if (response.error) {
      cssbDownloadError.value = response.error;
    } else if (response.requestId) {
      //If there are no Errors update
      const downloadRequests = localStorage.getItem("downloadRequests");
      //Create a string that will be parsed by the download watcher
      const newRequest = `{"requestId":"${response.requestId}","computerName":"${computerName}","backupSetName":"${backupSetName}","numberOfFiles":${response.numFiles},"totalSize":${response.totalSize}}`;
      //use local storage to pass the requests between the windows
      if (downloadRequests) {
        localStorage.setItem("downloadRequests", `${downloadRequests}|${newRequest}`);
      } else {
        localStorage.setItem("downloadRequests", newRequest);
      }
    } else {
      cssbDownloadError.value = "genericError";
    }

    return response;
  } catch (err) {
    const error = err as AxiosError;
    let errorMessage = "genericError";

    if (error && error.response) {
      const response = error?.response.data as ICssbDownloadInfoResponse;
      errorMessage = response.error;
    }

    cssbDownloadError.value = errorMessage;
    logException(err as Error);
  }

  return;
}

export async function downloadImage(img: IImageDownload): Promise<Blob | undefined> {
  if (img) return undefined;
  return undefined;
}

export function updateItemWithThumbnail(tu: IThumbnailUpdate) {
  if (tu) return;
  return;
}

export function getCollection(cp: ICollectionParams): IItemCollection {
  if (cp) return { collection: "" };
  return { collection: "" };
}

function mapCssbItemsToRfaItems(items: ICssbRfaItems[], totalItems: number) {
  const newItems: IRfaItem[] = [];

  items.forEach(item => {
    let fileId = item.fileId;
    if (fileId === 0) {
      //Generate a random number so the breadcrumbs will work
      fileId = Math.floor(Math.random() * 10000000000) + 1;
    }

    newItems.push({
      FileId: fileId,
      Name: item.name,
      IsDirectory: item.isDirectory,
      IsBackupRun: item.isBackupRun,
      IsBackupSet: item.isBackupSet,
      Path: item.path,
      ModifiedDate: item.modifiedDate,
      BucketName: item.bucketName,
      LastKey: item.lastKey,
      Size: item.size,
      IsDownloadable: true,
      IsDownloading: false,
      UnixTime: item.unixTime,
      IsSMB: true,
      ItemUniqueKey: item.itemUniqueKey,
      NumDataFile: item.numDataFile,
      TotalItems: totalItems > MAX_CSSB_ITEMS ? MAX_CSSB_ITEMS : totalItems,
      IsItemEncrypted: item.isItemEncrypted,
    } as IRfaItem);
  });

  return newItems;
}

function mapAndGroupSearchResults(items: ICssbRfaItems[], totalItems: number) {
  const mappedItems = mapCssbItemsToRfaItems(items, totalItems);
  const groupedItems: IRfaItem[] = [];

  mappedItems.forEach(item => {
    const groupAlreadyAdded = groupedItems.find(l => item.Name === l.Name && item.Path === l.Path);
    if (groupAlreadyAdded) {
      groupAlreadyAdded.GroupedSearchItems.push(item);
    } else {
      //If there are no already grouped items, this item becomes the parent and the first child. The parent will mainly me used for display
      //the child items will be the main details
      const parentItem = item;
      //Copy item so the child doesn't have the grouped items. This will fix issues with the log event()
      const child = Object.assign({}, item);
      parentItem.GroupedSearchItems = [child];
      groupedItems.push(parentItem);
    }
  });

  //slice off to 10
  const slicedGroupedItems = groupedItems.slice(0, 10);

  //Sort grouped items by date DESC
  slicedGroupedItems.forEach(item => {
    item.GroupedSearchItems.sort((a: IRfaItem, b: IRfaItem) => {
      const aConvertTime = new Date(a.ModifiedDate).getTime();
      const bConvertTime = new Date(b.ModifiedDate).getTime();
      return bConvertTime - aConvertTime;
    });
  });

  //Need to change the totalItems after grouping items
  const correctedTotalItems = slicedGroupedItems.length;
  slicedGroupedItems.forEach(item => {
    item.TotalItems = correctedTotalItems;
  });

  return slicedGroupedItems;
}

function mapDownloadRequest(items: IRfaItem[], passphase = "") {
  const request: ICssbDownloadInfoRequest = {
    backupSetName: selectedDisk.value?.Name ?? "",
    computerName: computer.value?.ComputerName ?? propData.value.computerName,
    cssbRfaBackupRestoreRequest: [],
  };

  //Get list of unique backup runs by time
  const uniques = [...new Set(items.map(item => item.UnixTime))];

  uniques.forEach(uniqueItem => {
    //Get all the items that match the time
    const groupedItemsByTime = items.filter(l => l.UnixTime === uniqueItem);
    const files: IFile[] = [];

    groupedItemsByTime.forEach(rfaItem => {
      files.push({
        name: rfaItem.Path,
        isFolder: rfaItem.IsDirectory,
        size: rfaItem.Size ?? 0,
      });
    });

    //Use the first item to set the generic values
    const firstItem = groupedItemsByTime[0];
    const cssbRfaBackupRestoreRequest: ICssbRfaBackupRestoreRequest = {
      files: files,
      bucket_name: firstItem.BucketName,
      last_key: firstItem.LastKey,
      num_data_file: firstItem.NumDataFile,
      encrypted: firstItem.IsItemEncrypted ? 1 : 0,
      passphase: firstItem.IsItemEncrypted ? passphase : "",
    };

    request.cssbRfaBackupRestoreRequest.push(cssbRfaBackupRestoreRequest);
  });

  return request;
}
