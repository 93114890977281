<template>
  <ModalWrapperComponent
    :dialog-name="componentName"
    :show-dialog="props.showDialog"
    :closable="false"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <strong id="title">{{ tn("header") }}</strong>
      <div class="close-modal">
        <button id="btnCloseModalX" type="button" class="btn-link icon-sm" @click="close()">
          <svg-icon-component icon="times" />
        </button>
      </div>
    </template>

    <template #content>
      <p>{{ tn("p1") }}</p>
      <p>{{ tn("p2") }}</p>
      <p>{{ tn("p3") }}</p>
    </template>

    <template #footer>
      <div class="opposite-ends-desktop">
        <div>
          <button id="btnNo" type="button" class="btn-link" @click="openKb()">
            <svg-icon-component icon="external-link" />
            {{ tn("btnActivateNewKeycode") }}
          </button>
        </div>
        <div>
          <button id="btnNo" type="button" class="btn-outline-primary mr" @click="close()">
            {{ tn("btnNo") }}
          </button>
          <button id="btnYes" type="submit" class="btn-primary" @click="continuePurchase()">
            {{ tn("btnYes") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "MonthlyToAnnualWarning";
const props = defineProps({
  showDialog: {
    type: Boolean,
    required: false,
  },
});

const emits = defineEmits(["close-modal", "continue-purchase"]);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function beforeOpen() {
  logEvent("created", componentName);
}

function close() {
  emits("close-modal", false);
}

function continuePurchase() {
  emits("continue-purchase");
}

function openKb() {
  window.open(
    "https://answers.webroot.com/Webroot/ukp.aspx?pid=17&app=vw&vw=1&solutionid=2490&t=Activate-a-New-Keycode",
    "_blank"
  );
}
</script>
