<template>
  <ul class="pw-requirements">
    <li v-for="(v, index) in validations" :key="v.name" class="icon-text-sidebyside truncate" :class="'li-' + index">
      <!-- add a second class 'success' to the span with a class of icon-sm as each password condition is met -->
      <svg-icon-component
        :id="`passwordRules${index}`"
        class="icon-sm"
        :class="v.passed ? 'requirement-success' : ''"
        icon="circle-check"
      />
      <span>{{ ruleText(v.name) }}</span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { validatePassword } from "@/common/strongPassword";
import { RuleNames } from "@/globalEnums";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "@/define";

const componentName = "PasswordRulesComponent";

const props = defineProps({
  password: {
    type: String,
    required: true,
  },
});

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function ruleText(ruleName: string): string {
  if (ruleName === RuleNames.characters) {
    return tn(ruleName, { min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH });
  }
  return tn(ruleName);
}

const validations = computed(() => {
  const result = validatePassword(props.password);
  result.sort((a, b) => {
    if (a.displayOrder !== undefined && b.displayOrder !== undefined) return a.displayOrder - b.displayOrder;
    else return 0;
  });
  return result.filter(r => r.name !== RuleNames.invalidCharacterBrace);
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.login-password-hint {
  margin-top: var(--space-quarter);
}
.login-password-hint span {
  text-transform: lowercase;
}
.login-password-hint span:after {
  content: ", ";
}
.login-password-hint span:last-child:after {
  content: none;
}
.pw-requirements {
  padding: 0;
}
.pw-requirements li {
  margin-bottom: 0;
}

.requirement-success {
  fill: var(--success);
}

@media (min-width: 600px) {
  .pw-requirements {
    display: grid;
    grid-template-areas: "a a";
    grid-gap: 10px;
    grid-auto-columns: 50%;
  }
}
</style>
