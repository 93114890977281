<template>
  <div class="flex-start">
    <div class="compound-svg">
      <h3 class="mb">{{ tn(`passwordManager`) }}</h3>
      <div v-if="displayAccountsUsed" class="mb">{{ tn(`accountsUsed`, { used: seatsUsed, total: totalSeats }) }}</div>
      <div>
        <button
          v-if="displayAdditionalAccounts"
          id="btnLastPassAdd"
          class="inline-btn-link mt"
          @click="openLastPassModal('Add')"
        >
          {{ tn(`accountSetup`) }}
        </button>
      </div>
      <div v-if="displayActivate">
        <p class="mb">{{ tn(`activateMessage`) }}</p>
        <button
          id="btnLastPassActivate"
          class="btn-outline-primary"
          :disabled="disableActivate"
          @click="openLastPassModal('Activate')"
        >
          {{ tn(`activate`) }}
        </button>
      </div>
      <div v-if="displayManageAccounts">
        <button id="btnLastPassManage" class="btn-primary" @click="manageAccounts()">
          {{ tn(`managePasswords`) }}
          <svg-icon-component icon="external-link" class="icon-xs" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, computed, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ILastPassInfo, IWebrootSubscriptions } from "@/common/api/unifiedPortal/interfaces";
import { getUrl } from "@/common/getUrl";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "LastPassSecurityTabComponent";

const props = defineProps({
  lastPassInfo: {
    type: Object as PropType<ILastPassInfo>,
    default: null,
  },
  webrootSubInfo: {
    type: Object as PropType<IWebrootSubscriptions | undefined>,
    required: true,
  },
});

const emits = defineEmits(["openLastPassModal"]);

logEvent("Created", componentName);
const displayActivate = ref<boolean>(false);
const displayAdditionalAccounts = ref<boolean>(false);
const displayManageAccounts = ref<boolean>(true);
const displayAccountsUsed = ref<boolean>(false);
const seatsUsed = ref<number>(0);
const totalSeats = ref<number>(0);

onBeforeMount(() => {
  render();
});

const disableActivate = computed(() => props.webrootSubInfo?.licenseStatusDescription === "Inactive");

function render() {
  const info = props.lastPassInfo;
  if (info) {
    // Get Pending activations
    seatsUsed.value = info.seatsUsed;
    totalSeats.value = info.seatsAvailable + info.seatsUsed;
    const hasPendingURL = info.pendingLastPassTokenUrl.length > 0;
    const hasCompletedAccounts = (info.seatsUsed > 0 && info.pendingLastPassTokenUrl.length < 1) || info.seatsUsed > 1;
    const hasSeatsAvailable = info.seatsAvailable > 0;

    logEvent("Load info", componentName, info);
    // display Activate button if no seat was used;
    displayActivate.value = seatsUsed.value == 0;
    // display Additional Accounts button if there is still seats available;
    displayAdditionalAccounts.value = !displayActivate.value && (hasSeatsAvailable || hasPendingURL);
    // display Manage Accounts button if there are
    displayManageAccounts.value = hasCompletedAccounts;
  } else if (props.webrootSubInfo) {
    totalSeats.value = props.webrootSubInfo.seats;
    seatsUsed.value = props.webrootSubInfo.seats;
  }
  displayAccountsUsed.value = !displayActivate.value && seatsUsed.value !== totalSeats.value;
}

function manageAccounts() {
  window.open(getUrl("LASTPASS_PORTAL_URL"));
}

// LastPass Modal
function openLastPassModal(operation: string) {
  emits("openLastPassModal", operation);
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
