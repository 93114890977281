<template>
  <!-- add either current or recommended as a class on the th -->
  <th :id="`planHeader${props.productName}`" :class="{ current: props.isCurrent, recommended: props.isRecommended }">
    <div>
      <span v-if="props.isCurrent" class="current-text">{{ t("SelectPlan.currentPlan") }}</span>
      <span v-if="props.isRecommended" class="recommended-text">{{ t("SelectPlan.bestValue") }}</span>
      <span v-if="props.isSelected" class="selected-text">{{ t("SelectPlan.selectedPlan") }}</span>
      <span v-if="!props.isCurrent && !props.isRecommended && !props.isSelected"></span>
    </div>
  </th>
</template>
<script setup lang="ts">
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";

const componentName = "recommendedPlan";
logEvent(componentName);

const props = defineProps({
  productName: {
    type: String,
    required: true,
  },
  isCurrent: {
    type: Boolean,
    default: false,
  },
  isRecommended: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
th {
  border: none;
  padding: 0 var(--space-half);
}
th span {
  border-bottom: 1px solid var(--medium-grey);
  display: block;
  min-height: 20px;
  font-size: var(--small-text);
  margin: 0 var(--space-half);
}
.current-text,
.recommended-text,
.selected-text {
  color: var(--carb-accent);
  border-color: var(--carb-accent);
}
.current-text,
.selected-text {
  /* color: var(--grey); change this color if UX decides to */
}
.current .recommended-text,
.recommended .current-text {
  display: none;
}
.current .current-text,
.recommended .recommended-text {
  display: block;
}
</style>
