<template>
  <div class="quantity-stepper">
    <button
      id="btnStepperDown"
      class="step-down"
      type="button"
      :disabled="numberOfItems === minNumberOfItems"
      @click="stepperDown()"
    >
      <svg-icon icon="stepper-minus" />
    </button>
    <div id="numberOfItems">{{ numberOfItems }}</div>
    <button
      id="btnStepperUp"
      class="step-up"
      type="button"
      :disabled="numberOfItems === maxNumberOfItems"
      @click="stepperUp()"
    >
      <svg-icon icon="stepper-plus" />
    </button>
  </div>
</template>
<script setup lang="ts">
import SvgIcon from "@/components/shared/SvgIcon/SvgIcon.vue";
import { ref } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { DEFAULT_QUANTITY } from "@/define";
const buyFlowStore = useBuyFlowStore();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const componentName = "QuantityStepper";

const emits = defineEmits(["increase", "decrease"]);
// eslint-disable-next-line vue/require-prop-types
const props = defineProps(["initialNumber", "sku"]);

const minNumberOfItems = DEFAULT_QUANTITY;
const numberOfItems = ref<number>(parseInt(props.initialNumber) ?? minNumberOfItems);
const maxNumberOfItems = ref<number>(buyFlowStore.getMaxQuantityBySku(props.sku));

function stepperUp() {
  numberOfItems.value++;

  if (numberOfItems.value > maxNumberOfItems.value) {
    numberOfItems.value = maxNumberOfItems.value;
  }
  emits("increase");
}

function stepperDown() {
  numberOfItems.value--;

  if (numberOfItems.value < minNumberOfItems) {
    numberOfItems.value = minNumberOfItems;
  }
  emits("decrease");
}
</script>
