<template>
  <ModalWrapperComponent
    dialog-name="SetSecurityQuestionsComponent"
    :show-dialog="showDialog"
    content-class="security-questions"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <dl>
        <!--Question 1-->
        <dt>
          <p>{{ t("PersonalInformationComponent.Question1Header") }}</p>
          <select id="setSecurityQuestion1" v-model="question1" class="select-css">
            <option value="" disabled>
              {{ tn("chooseOption") }}
            </option>
            <option v-for="option in questionsList.questionGroup1" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </dt>
        <!--Question 1 Answer-->
        <dd>
          <label v-if="question1" for="question1">{{ question1 }}</label>
          <label v-else for="answer">{{ t("SetSecurityQuestionsComponent.answer") }}</label>
          <InputErrorComponent :show="hasSecurityAnswer1Error">
            <input
              id="setSecurityAnswer1"
              v-model="answer1"
              type="text"
              autocomplete="off"
              :placeholder="maskedAnswer"
              @keyup="dirtyFlagAnswer1 = true"
            />
            <template #error>
              <span>{{ securityAnswer1ErrorText }}</span>
            </template>
          </InputErrorComponent>
        </dd>
        <!--Question 2-->
        <dt>
          <p>{{ t("PersonalInformationComponent.Question2Header") }}</p>
          <div>
            <select id="setSecurityQuestion2" v-model="question2" class="select-css">
              <option value="" disabled>
                {{ tn("chooseOption") }}
              </option>
              <option v-for="option in questionsList.questionGroup2" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
        </dt>
        <!--Question 2 Answer-->
        <dd>
          <label v-if="question2" for="question2">{{ question2 }}</label>
          <label v-else for="answer">{{ t("SetSecurityQuestionsComponent.answer") }}</label>
          <div>
            <InputErrorComponent :show="hasSecurityAnswer2Error">
              <input
                id="setSecurityAnswer2"
                v-model="answer2"
                type="text"
                autocomplete="off"
                :placeholder="maskedAnswer"
                @keyup="dirtyFlagAnswer2 = true"
              />
              <template #error>
                <span>{{ securityAnswer2ErrorText }}</span>
              </template>
            </InputErrorComponent>
          </div>
        </dd>
        <!--Question 3-->
        <dt>
          <p>{{ t("PersonalInformationComponent.Question3Header") }}</p>
          <div>
            <select id="setSecurityQuestion3" v-model="question3" class="select-css">
              <option value="" disabled>
                {{ tn("chooseOption") }}
              </option>
              <option v-for="option in questionsList.questionGroup3" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
        </dt>
        <!--Question 3 Answer-->
        <dd>
          <label v-if="question3" for="question3">{{ question3 }}</label>
          <label v-else for="answer">{{ t("SetSecurityQuestionsComponent.answer") }}</label>
          <div>
            <InputErrorComponent :show="hasSecurityAnswer3Error">
              <input
                id="setSecurityAnswer3"
                v-model="answer3"
                type="text"
                autocomplete="off"
                :placeholder="maskedAnswer"
                @keyup="dirtyFlagAnswer3 = true"
              />
              <template #error>
                <span>{{ securityAnswer3ErrorText }}</span>
              </template>
            </InputErrorComponent>
          </div>
        </dd>
      </dl>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button
        id="btnSaveChanges"
        type="submit"
        class="btn-primary"
        :disabled="!saveButtonEnabled || isSaving || hasErrors()"
        @click="save()"
      >
        <spinner :is-spinning="isSaving" />
        {{ tn("footerButtonSave") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common/index";
import { ISecurityQuestions, ISecurityQuestionsList } from "@/common/api/unifiedPortal/interfaces";
import { useUserStore } from "@/stores/user";
import Spinner from "@/components/shared/Spinner.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { securityAnswerValidator } from "@/common/validateSecurityAnswer";

const componentName = "SetSecurityQuestionsComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
  questionsList: {
    type: Object as PropType<ISecurityQuestionsList>,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.questionsList);

const userStore = useUserStore();
const isSaving = ref<boolean>(false);
const questions = ref<ISecurityQuestions>();
const question1 = ref<string>("");
const question2 = ref<string>("");
const question3 = ref<string>("");
const answer1 = ref<string>("");
const answer2 = ref<string>("");
const answer3 = ref<string>("");
const dirtyFlagAnswer1 = ref<boolean>(false);
const dirtyFlagAnswer2 = ref<boolean>(false);
const dirtyFlagAnswer3 = ref<boolean>(false);
const maskedAnswer = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const saveButtonEnabled = computed(() => {
  return question1.value && question2.value && question3.value && (answer1.value || answer2.value || answer3.value);
});

const user = computed(() => {
  return userStore.currentUser;
});

const hasSecurityAnswer1Error = computed(() => {
  return securityAnswer1ErrorText.value.length > 0;
});

const hasSecurityAnswer2Error = computed(() => {
  return securityAnswer2ErrorText.value.length > 0;
});

const hasSecurityAnswer3Error = computed(() => {
  return securityAnswer3ErrorText.value.length > 0;
});

const securityAnswer1ErrorText = computed(() => {
  return dirtyFlagAnswer1.value === false
    ? []
    : securityAnswerValidator(answer1, question1, questions.value?.securityQuestionOne);
});

const securityAnswer2ErrorText = computed(() => {
  if (answer1.value && answer2.value && answer1.value.toLowerCase() === answer2.value.toLowerCase()) {
    return t("SecurityAnswerValidation.AnswerMatchingError");
  }
  return dirtyFlagAnswer2.value === false
    ? []
    : securityAnswerValidator(answer2, question2, questions.value?.securityQuestionTwo);
});

const securityAnswer3ErrorText = computed(() => {
  if (
    (answer1.value && answer3.value && answer1.value.toLowerCase() === answer3.value.toLowerCase()) ||
    (answer2.value && answer3.value && answer2.value.toLowerCase() === answer3.value.toLowerCase())
  ) {
    return t("SecurityAnswerValidation.AnswerMatchingError");
  }
  return dirtyFlagAnswer3.value === false
    ? []
    : securityAnswerValidator(answer3, question3, questions.value?.securityQuestionThree);
});

function resetFlags() {
  dirtyFlagAnswer1.value = false;
  dirtyFlagAnswer2.value = false;
  dirtyFlagAnswer3.value = false;
}

function close() {
  resetFlags();
  emits("close-modal");
}

function hasErrors() {
  if (hasSecurityAnswer1Error.value || hasSecurityAnswer2Error.value || hasSecurityAnswer3Error.value) {
    return true;
  }
  return false;
}

async function save() {
  if (!hasErrors()) {
    isSaving.value = true;
    try {
      await unifiedApi.setSecurityQuestions(
        {
          securityQuestionOne: question1.value,
          securityAnswerOne: answer1.value,
          securityQuestionTwo: question2.value,
          securityAnswerTwo: answer2.value,
          securityQuestionThree: question3.value,
          securityAnswerThree: answer3.value,
        },
        userStore.selectedLanguage
      );

      //to close the modal after saving the data we need to saveModal
      emits("save-modal", "ok");
    } catch (err) {
      logEvent("SetSecurityQuestionsFailure", componentName);
      emits("save-modal", "failure");
      handleApiError(err as AxiosError, true, "SetSecurityQuestionsFailure");
    }
    resetFlags();
    isSaving.value = false;
  }
}

function beforeOpen() {
  questions.value = user.value?.subscriberInfo?.securityQuestions;
  answer1.value = "";
  answer2.value = "";
  answer3.value = "";
  if (questions.value) {
    question1.value = questions.value.securityQuestionOne;
    question2.value = questions.value.securityQuestionTwo;
    question3.value = questions.value.securityQuestionThree;
    maskedAnswer.value = "******";
    logEvent("questions loaded", componentName, questions.value);
  } else {
    question1.value = "";
    question2.value = "";
    question3.value = "";
    maskedAnswer.value = "";
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

p {
  margin-bottom: var(--space-quarter);
}
dd input[type="text"] {
  width: 100%;
}
</style>
