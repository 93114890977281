import { IWebrootSubscriptions } from "@/common/api/unifiedPortal/interfaces";
import { useSubscriptionsStore } from "@/stores/subscriptions";

function isKeycodeExpiredByDate(keycodeExpirationDate: string): boolean {
  return new Date(keycodeExpirationDate) < new Date();
}

export function isKeycodeExpired(item: IWebrootSubscriptions, checkOutstandingInvoice = false) {
  const subscriptionsStore = useSubscriptionsStore();
  if (checkOutstandingInvoice) {
    const sub = subscriptionsStore.subscriptions.find(l => l.keyCode === item.keycode);
    return sub?.hasOutstandingInvoices || isKeycodeExpiredByDate(item.expirationDate);
  }

  return isKeycodeExpiredByDate(item.expirationDate);
}

export function checkIsKeycodeExpired(keycode: string, checkOutstandingInvoice = false) {
  const subscriptionsStore = useSubscriptionsStore();
  const sub = subscriptionsStore.subscriptions.find(l => l.keyCode === keycode);

  if (checkOutstandingInvoice) {
    return sub?.hasOutstandingInvoices || isKeycodeExpiredByDate(sub?.expirationDate ?? "");
  }

  return isKeycodeExpiredByDate(sub?.expirationDate ?? "");
}
