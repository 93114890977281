<template>
  <ModalWrapperComponent
    dialog-name="ApplyActivationCodeComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>

    <template #content>
      <div v-if="step === 1">
        <ul class="form">
          <li v-for="ac in availableActivationCodes" :key="ac">
            <input :id="`radioAC${ac}`" v-model="selectedAC" type="radio" name="selectedAC" :value="ac" />
            <label :for="`radioAC${ac}`">{{ ac }}</label>
          </li>
          <li>
            <input
              v-if="availableActivationCodes.length > 0"
              id="textbox-ac"
              v-model="selectedAC"
              type="radio"
              name="selectedAC"
              value="userInput"
            />
            <label :for="labelFor">{{ tn("label1") }}</label>
            <InputErrorComponent :show="hasActivationCodeErrors && hasActivationCodeErrors.length > 0">
              <input v-show="!hideInput" id="apply-ac" v-model="codeToApply" type="text" autocomplete="off" />
              <template #error>
                <div>
                  <span v-for="e in hasActivationCodeErrors" :key="e.name">{{ e.message }}</span>
                </div>
              </template>
            </InputErrorComponent>
          </li>
        </ul>
      </div>

      <div v-else>
        <p>{{ tn("paragraph1") }}</p>
        <ul class="form">
          <li class="radio">
            <input id="existingSub" v-model="applyTo" name="applyTo" type="radio" value="existingSub" />
            <div>
              <label for="existingSub">{{ tn("label2") }}</label>
              <select id="applyToComputer" v-model="applyToComputer" class="select-css">
                <option v-for="item in availableComputers" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </li>
          <li class="radio">
            <input id="newSub" v-model="applyTo" name="applyTo" type="radio" value="newSub" />
            <label for="newSub">{{ tn("label3") }}</label>
          </li>
        </ul>
      </div>
    </template>

    <template #footer>
      <div class="opposite-ends">
        <div>
          <a
            href="https://support.carbonite.com/articles/Personal-Mac-Windows-Applying-Your-Subscription"
            target="_blank"
          >
            {{ tn("footerNeedHelp") }}
            <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
          </a>
        </div>
        <div>
          <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
            {{ tn("footerButtonCancel") }}
          </button>
          <button
            v-if="step === 1"
            id="verifyAC"
            type="submit"
            class="btn-primary"
            :disabled="!enableNextButton || isVerifying || hasActivationCodeErrors.length > 0"
            @click="verifyActivationCode()"
          >
            <spinner :is-spinning="isVerifying" />
            {{ tn("footerButtonNext") }}
          </button>
          <button
            v-else
            id="applyAC"
            type="submit"
            class="btn-primary"
            :disabled="isApplying"
            @click="applyActivationCode()"
          >
            <spinner :is-spinning="isApplying" />
            {{ tn("footerButtonApply") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { IComputer, IActivationCodeDialogResponse } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { IErrorMessage } from "@/common/api/interfaces";
import InputErrorComponent from "@/components/shared/InputError.vue";
import { validateActivationCode } from "@/common/carboniteActivationCodes";

const componentName = "ApplyActivationCodeComponent";

const props = defineProps({
  origin: {
    type: String,
    default: "",
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props);

const notificationsStore = useNotificationsStore();
const isVerifying = ref<boolean>(false);
const isApplying = ref<boolean>(false);
const codeToApply = ref<string>("");
const step = ref<number>(1);
const applyTo = ref<string>("existingSub");
const applyToComputer = ref<number>(0);
const availableComputers = ref<IComputer[]>([]);
const availableActivationCodes = ref<string[]>([]);
const selectedAC = ref<string>("");
const hasActivationCodeErrors = computed(() => {
  return codeToApply.value === "" ? [] : validateActivationCode(codeToApply.value).filter(e => !e.passed);
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const acToApply = computed<string>(() => {
  return selectedAC.value === "userInput" && codeToApply.value ? codeToApply.value : selectedAC.value;
});

const enableNextButton = computed(() => {
  if (availableActivationCodes.value.length > 0) {
    return (selectedAC.value === "userInput" && codeToApply.value) || selectedAC.value !== "userInput";
  }

  return codeToApply.value;
});

const hideInput = computed(() => {
  return availableActivationCodes.value.length > 0 && selectedAC.value !== "userInput";
});

const labelFor = computed(() => {
  return availableActivationCodes.value.length > 0 ? "textbox-ac" : "apply-ac";
});

async function verifyActivationCode() {
  isVerifying.value = true;
  try {
    if (props.origin === "backupTab") {
      const response: IActivationCodeDialogResponse = { type: props.origin, activationCode: acToApply.value };
      emits("save-modal", response);
      isVerifying.value = false;
      return;
    }

    notificationsStore.clearNotifications();
    const response = (await unifiedApi.verifyActivationCode(acToApply.value)).data;

    availableComputers.value = response?.computers || [];
    if (availableComputers.value.length > 0) {
      applyToComputer.value = availableComputers.value[0].id;
      step.value++;
    } else {
      //apply directly to this computer if there are no computers returned
      applyTo.value = "newSub";
      await applyActivationCode();
    }
  } catch (err) {
    const message = ((err as AxiosError)?.response?.data as IErrorMessage).message as NotificationType;
    notificationsStore.addNotification({ type: message });
    logException(err as Error);
  }
  isVerifying.value = false;
}

async function applyActivationCode() {
  isApplying.value = true;
  try {
    notificationsStore.clearNotifications();
    let computerId = 0;

    if (applyTo.value === "existingSub") {
      computerId = applyToComputer.value;
    }

    const result = (await unifiedApi.applyActivationCode(acToApply.value, computerId)).data;
    notificationsStore.clearNotifications();
    if (result !== null && result.activationCode) {
      const response: IActivationCodeDialogResponse = {
        type: applyTo.value,
        activationCode: result.activationCode,
      };
      emits("save-modal", response);
    } else {
      notificationsStore.addNotification({ type: "ActivationCodeAppliedFailed" });
    }
  } catch (err) {
    handleApiError(err as AxiosError, true, "ActivationCodeAppliedFailed");
  }
  isApplying.value = false;
}

async function beforeOpen() {
  try {
    notificationsStore.clearNotifications();
    step.value = 1;
    codeToApply.value = "";
    const acs = (await unifiedApi.getAvailableActivationCodes()).data;
    selectedAC.value = acs && acs.length > 0 ? acs[0] : "userInput";
    applyTo.value = "existingSub";
    availableActivationCodes.value = acs;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function close() {
  emits("close-modal");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
