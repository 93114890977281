<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else class="select-plan-wrapper">
    <table class="select-plan mt">
      <thead>
        <tr>
          <th class="no-borders-th-td pin" rowspan="4">
            <svg-icon :icon="selectPlanDetails.logo" />
          </th>
          <RecommendedPlan
            v-for="item in selectPlanDetails.products"
            :key="item.sku"
            :product-name="item.sku"
            :is-current="currentPlanSku === item.sku && !currentPlanIsTrial && currentPlanSku !== selectedPlanSku"
            :is-recommended="recommendedPlanSku === item.sku && recommendedPlanSku !== selectedPlanSku"
            :is-selected="selectedPlanSku === item.sku"
          />
        </tr>
        <tr>
          <th v-for="item in selectPlanDetails.products" :key="item.sku">
            <span class="text-lg">{{ t(item.label) }}</span>
          </th>
        </tr>
        <tr>
          <Pricing
            v-for="item in selectPlanDetails.products"
            :key="item.sku"
            :product="item"
            :is-new-purchase="isNewPurchase"
          />
        </tr>
        <tr>
          <ChoosePlan
            v-for="item in selectPlanDetails.products"
            :key="item.sku"
            :show-choose-plan="showChoosePlan[item.sku]"
            :product-name="item.sku"
            :show-quantity-stepper="deviceId === 0"
            :current-plan-sku="currentPlanSku"
            :is-monthly-to-annual-upgrade="isMonthlyToAnnualUpgrade"
            @toggle-component="toggleAllChoosePlanWindows"
            @add-to-cart="addToCart"
            @show-monthly-to-annual-warning="displayMonthlyToAnnualWarningDialog"
          />
        </tr>
      </thead>
      <tbody>
        <FeatureRow
          v-for="feature in selectPlanFeatures"
          :key="feature.order"
          :feature="feature"
          :details="getFeatureDetailsForProducts(feature.name)"
        />
      </tbody>
    </table>
  </div>
  <MonthlyToAnnualWarning
    :show-dialog="displayMonthlyToAnnualWarning"
    @close-modal="toggleMonthlyToAnnualWarning"
    @continue-purchase="continueMonthlyToAnnualPurchase"
  />
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { pageStates } from "@/components/Buy/BuyEnums";
import { useBuyFlowStore } from "@/stores/buyFlow";
import ChoosePlan from "@/components/Buy/SelectPlan/choosePlan.vue";
import RecommendedPlan from "@/components/Buy/SelectPlan/recommendedPlan.vue";
import SvgIcon from "@/components/shared/SvgIcon/SvgIcon.vue";
import { computed, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { IBeginShoppingCartRequest } from "@/components/Buy/Interfaces";
import { useUnauthenticatedBuyFlowStore } from "@/stores/unauthorizedBuyflow";
import { DEFAULT_TERM_LENGTH_MONTHS } from "@/define";
import { safeSelectPlanDetails, safeSelectPlanFeatures } from "@/components/Buy/SelectPlan/config";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import FeatureRow from "@/components/Buy/SelectPlan/FeatureRow.vue";
import { IPlanToggle } from "@/components/Buy/SelectPlan/interfaces";
import Pricing from "@/components/Buy/SelectPlan/Pricing.vue";
import { logEvent } from "@/common/logger";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { checkLoggedStatus, getProspectId, redirectToOutstandingInvoice } from "@/components/Buy/BuyHelper";
import { validGuidFormat } from "@/globalRegex";
import MonthlyToAnnualWarning from "@/components/Buy/Dialogs/MonthlyToAnnualWarning.vue";
import { cartUpdate, googleTagCartEvents } from "@/common/googleTagEvents";

interface ISelectedPlan {
  sku: string;
  ratePlanId: string;
  quantity: number;
}

const componentName = "SelectPlan";
logEvent(componentName);
const route = useRoute();
const router = useRouter();
const buyFlowStore = useBuyFlowStore();
const unauthenticatedBuyflowStore = useUnauthenticatedBuyFlowStore();
const userStore = useUserStore();
const subscriptionsStore = useSubscriptionsStore();
const isLoading = ref<boolean>(false);
const currentPlanSku = ref("");
const currentPlanIsTrial = ref(false);
const prospectId = ref<number>(0);
const displayMonthlyToAnnualWarning = ref(false);
const isMonthlyToAnnualUpgrade = ref(false);
const selectedPlan = ref<ISelectedPlan>();
const loadSessionStorageCart = route.query.lsc?.toString() === "1";

function toggleMonthlyToAnnualWarning() {
  displayMonthlyToAnnualWarning.value = !displayMonthlyToAnnualWarning.value;
}

function displayMonthlyToAnnualWarningDialog(sku, ratePlanId, quantity) {
  toggleMonthlyToAnnualWarning();
  //Save the selected plan to use later
  selectedPlan.value = { sku: sku, ratePlanId: ratePlanId, quantity: quantity };
}

function continueMonthlyToAnnualPurchase() {
  toggleMonthlyToAnnualWarning();
  //use the saved selected plan
  addToCart(selectedPlan.value?.sku, selectedPlan.value?.ratePlanId, selectedPlan.value?.quantity);
}

const recommendedPlanSku = computed(() => {
  return buyFlowStore.getRecommendedSku(currentPlanSku.value, selectedPlanSku.value);
});

const selectedPlanSku = computed(() => {
  return buyFlowStore.cart?.items[0]?.sku;
});

//Making a computed because in the future we will need to figure out which configs to load.
//EX) Safe or Webroot
const selectPlanFeatures = computed(() => {
  return safeSelectPlanFeatures;
});

//Making a computed because in the future we will need to figure out which configs to load.
//EX) Safe or Webroot
const selectPlanDetails = computed(() => {
  return safeSelectPlanDetails;
});

const showChoosePlan = ref<IPlanToggle>({
  PersonalBasic: false,
  PersonalPlus: false,
  PersonalPrime: false,
});

const catId = parseInt(route.query.catId?.toString() || route.query.cid?.toString() || "0");
let userGuid = route.query?.userGuid?.toString() || route.query?.ug?.toString() || "";
const prospectGuid = route.query?.guid?.toString() || route.query?.pguid?.toString() || "";
const deviceId = ref(parseInt(route.query?.deviceId?.toString() || route.query?.did?.toString() || "0"));
const promoCode = route.query.couponCode?.toString() || route.query.cc?.toString() || "";
const sku = route.query.sku?.toString() || "";
const subscriptionMonths =
  parseInt(route.query?.subscriptionMonths?.toString() || route.query?.sm?.toString() || "") ||
  DEFAULT_TERM_LENGTH_MONTHS;
const quantity = parseInt(route.query?.quantity?.toString() || route.query?.qty?.toString() || "1");
buyFlowStore.referralUrl = route.query.referralUrl?.toString() || buyFlowStore.referralUrl || "";

onBeforeMount(async () => {
  isLoading.value = true;
  //Make sure the user guid is a valid format for a guid
  if (!validGuidFormat.test(userGuid)) {
    userGuid = "";
  }

  await checkLoggedStatus();

  //Set DeviceId to 0 if we get passed a value that can not parse to an int
  if (isNaN(deviceId.value)) {
    deviceId.value = 0;
  }

  let isRenewOrUpgrade = false;
  if (
    deviceId.value > 0 ||
    (buyFlowStore.cart && buyFlowStore.cart.items.some(l => l.computerId > 0)) ||
    buyFlowStore.isRenewOrUpgrade
  ) {
    isRenewOrUpgrade = true;
  }

  //If there is already a deviceId on the cart but no deviceId from the query string we should keep that deviceId
  if (buyFlowStore.cart && buyFlowStore.cart.items.some(l => l.computerId > 0)) {
    if (deviceId.value === 0) {
      deviceId.value = buyFlowStore.cart.items.find(l => l.computerId > 0)?.computerId || 0;
    }
  }

  //If prospectGuid exist in query params then fetch the prospectId from API and save to Buyflow store
  if (prospectGuid != "") {
    prospectId.value = (await getProspectId(prospectGuid)) ?? 0;
  }

  if (buyFlowStore.cart && buyFlowStore.cart.userGuid) {
    if (!userGuid) {
      userGuid = buyFlowStore.cart.userGuid;
    }
  }

  if (userGuid) buyFlowStore.userGuid = userGuid;

  // Override for deviceId
  if (!userStore.currentUser && deviceId.value > 0) {
    //Get the subscription for the given device
    try {
      await unauthenticatedBuyflowStore.populateSubscription(deviceId.value, true);
      if (unauthenticatedBuyflowStore.subscription) {
        currentPlanSku.value = unauthenticatedBuyflowStore.subscription.sku;
        currentPlanIsTrial.value = unauthenticatedBuyflowStore.subscription.isTrial || false;
        //Check if the current rate plan for the computer is monthly
        isMonthlyToAnnualUpgrade.value = isMonthlySub(
          unauthenticatedBuyflowStore.subscription.sku,
          unauthenticatedBuyflowStore.subscription.productRatePlanId
        );

        //Redirect to outstanding invoice page
        if (unauthenticatedBuyflowStore.subscription.hasOutstandingInvoices) {
          redirectToOutstandingInvoice(true);
        }
      }
    } catch {
      //If we get an error clear the values so we don't keep using them
      deviceId.value = 0;
      userGuid = "";
      buyFlowStore.userGuid = "";
    }
  } else if (userStore.currentUser && deviceId.value > 0) {
    const sub = subscriptionsStore.subscriptions.find(l => l.computer?.id === deviceId.value);
    if (sub?.hasOutstandingInvoices) {
      redirectToOutstandingInvoice();
    }

    //Check if the current rate plan for the computer is monthly
    isMonthlyToAnnualUpgrade.value = sub?.isActiveUntilCancelled || isMonthlySub(sub?.sku, sub?.ratePlanId);

    currentPlanSku.value = sub?.sku || "";
    currentPlanIsTrial.value = sub?.isTrial || false;
  }

  await buyFlowStore.populatePlans(catId, isRenewOrUpgrade, currentPlanIsTrial.value);

  if (loadSessionStorageCart) {
    buyFlowStore.cart = JSON.parse(sessionStorage["BuyFlow_Session_Cart"]);
    currentPlanSku.value = buyFlowStore.selectedPlan?.sku ?? "";
    isLoading.value = false;
    return;
  }

  //If we don't have a deviceId but we do have a sku from the query params
  //we need to build a cart as this is coming from somewhere outside of VD
  if (sku && !deviceId.value && !buyFlowStore.cart) {
    const beginShoppingCartRequest = {
      sku: sku,
      quantity: quantity,
      ratePlanId: buyFlowStore.getRatePlanIdBySku(sku, subscriptionMonths),
    };
    buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
  }

  isLoading.value = false;
});

const isNewPurchase = computed(() => deviceId.value === 0 || currentPlanIsTrial.value);

function isMonthlySub(sku, ratePlanId) {
  if (!sku || !ratePlanId) {
    return false;
  }

  const plans = buyFlowStore.getRatePlansForSku(sku);
  const ratePlan = plans.find(l => l.ratePlanId === ratePlanId);
  return ratePlan?.months === 1;
}

function getFeatureDetailsForProducts(featureName: string): boolean[] {
  const items: boolean[] = [];

  for (const detail of selectPlanDetails.value.products) {
    items.push(detail.hasFeature[featureName]);
  }

  return items;
}

//Toggling all the choose plan items needs to happen here so we can
//close all the other instances when clicking on a different component
function toggleAllChoosePlanWindows(productName: string) {
  for (const product in showChoosePlan.value) {
    if (productName === product) {
      //Needs to remain a toggle since you can click on the same item to close it
      showChoosePlan.value[product] = !showChoosePlan.value[product];
    } else {
      showChoosePlan.value[product] = false;
    }
  }
}

function addToCart(sku, ratePlanId, quantity) {
  const beginShoppingCartRequest = {
    sku: sku,
    ratePlanId: ratePlanId,
    quantity: quantity,
  } as IBeginShoppingCartRequest;

  if (deviceId.value > 0) {
    beginShoppingCartRequest.deviceId = deviceId.value;
  }

  buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
  cartUpdate(buyFlowStore.cart, googleTagCartEvents.add);

  //If there was a userGuid from query parameters add it to the cart
  if (userGuid && buyFlowStore.cart) {
    buyFlowStore.cart.userGuid = userGuid;
  }

  //If there was a catId from query parameters add it to the cart
  if (catId && buyFlowStore.cart) {
    buyFlowStore.cart.campaignId = catId;
  }

  if (promoCode && buyFlowStore.cart) {
    buyFlowStore.cart.promotionCode = promoCode;
  }
  if (prospectId.value && buyFlowStore.cart) {
    buyFlowStore.cart.prospectId = prospectId.value;
  }

  let nextState = pageStates.email;
  if (userStore.currentUser || userGuid || buyFlowStore.pageState == pageStates.payment || deviceId.value > 0) {
    nextState = pageStates.payment;
  }
  buyFlowStore.pageState = nextState;

  buyFlowStore.pageState = nextState;
  router.push({
    name: "buy",
    query: {
      step: nextState,
      // optional parameters
      ...(deviceId.value > 0 && { deviceId: deviceId.value }),
      ...(userGuid && { userGuid: userGuid }),
    },
  });
}
</script>
