<template>
  <h1>{{ tn("h1OrderConfirmation") }}</h1>
  <i18n-t keypath="OrderConfirmation.isRenewOrUpgradeLine1" tag="p" scope="global" class="mb constrain-50">
    <template #computer>
      <strong>{{ computerName }}</strong>
    </template>
  </i18n-t>
  <p class="mb constrain-50">
    <button type="button" class="inline-btn-link" @click="continueToMyAccount()">
      {{ tn("btnContinueToMyAccount") }}
    </button>
    {{ tn("isRenewOrUpgradeLine2") }}
  </p>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { t } from "@/i18n";
import { continueToMyAccount, getComputerName } from "@/components/Buy/BuyHelper";
import { logEvent } from "@/common/logger";

const componentName = "CarboniteRenewUpgrade";
const baseComponentName = "OrderConfirmation";
logEvent("created", componentName);
const buyFlowStore = useBuyFlowStore();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

const computerName = computed(() => {
  const computerId = buyFlowStore.cart?.items[0]?.computerId ?? 0;
  return computerId > 0 ? getComputerName(computerId) : "";
});
</script>
