import { isSmb } from "@/components/FileAccess/commonFn";
import { logEvent } from "./logger";

const optionsDefault: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "2-digit",
  year: "numeric",
};

const optionsCC: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  year: "numeric",
};

const optionsFileDate: Intl.DateTimeFormatOptions = {
  month: "numeric",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  hour12: true,
  minute: "numeric",
};

const optionsShort24HourDate: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "2-digit",
  year: "numeric",
  hour: "numeric",
  hour12: false,
  minute: "numeric",
  second: "numeric",
};

function dateLocale() {
  if (localStorage.getItem("selectedLanguage")) {
    return localStorage.getItem("selectedLanguage") as string;
  }
  return "en";
}

export function formatDateString(date: string): string {
  try {
    return formatDate(date, optionsDefault);
  } catch {
    return "";
  }
}

export function formatCCDateString(date: string): string {
  try {
    logEvent("formatCCDateString", "dateFormat", date);
    return formatDate(date, optionsCC);
  } catch {
    return "";
  }
}

export function formatFileDate(date: Date | string | undefined): string {
  if (!date) return "";
  try {
    return formatDate(date, optionsFileDate);
  } catch (err) {
    logEvent("formatFileDate", "dateFormat", { msg: (err as Error).message, date });
    return "";
  }
}

export function formatShort24HourTime(date: string): string {
  try {
    return formatDate(date, optionsShort24HourDate);
  } catch {
    return "";
  }
}

//Base date function. Default behavior returns format: MMM dd, YYYY
export function formatDate(date: string | Date, options: Intl.DateTimeFormatOptions = optionsDefault): string {
  //if 'date' is Date object
  if (date instanceof Date) {
    return date.toLocaleDateString(dateLocale(), options);
  }

  if (isSmb.value && !date.includes("Z")) {
    date += "Z";
  }

  // if 'date' is a string, we replace the dash 2025-8-30 with slashes 2025/8/30 because Safari considers
  // dash formatted dates invalid and will not display them or allow processing to convert them
  // Additionally, Firefox will consider it UTC time and not local time if there are dashes instead of slash
  // so you may end up with the previous day as it subtracts 4 hours for eastern time, for instance

  const dateObject = new Date(date.includes("T") ? date : date.replaceAll("-", "/"));

  if (date == "" || !(dateObject instanceof Date) || isNaN(dateObject.valueOf())) {
    logEvent("formatDate", "dateFormat", { date: date, dateObject: dateObject, message: "notADate" });
    return "";
  }

  try {
    return dateObject.toLocaleDateString(dateLocale(), options);
  } catch {
    return dateObject.toLocaleDateString("en", options);
  }
}

export function concatOrdinalNumber(n: number) {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
