<template>
  <section class="card mb-double">
    <header>
      <h3>
        <svg-icon-component icon="webroot-for-chromebook" class="icon-md icon-relative-move" />
        {{ tn("webrootForChromebook") }}
      </h3>
    </header>
    <article>
      <p>
        <strong>{{ tn("toInstall") }}</strong>
      </p>
      <ol class="form tight">
        <i18n-t id="btmMyAccountFromChromebook" keypath="WebrootDownloadInstructions.WSCBol1" tag="li" scope="global">
          <template #myAccount>
            <button
              id="btnGoToDownloadsFromChromebookCard"
              type="button"
              class="inline-btn-link"
              @click="goToDownloads()"
            >
              {{ t("Brand.myAccount") }}
            </button>
          </template>
        </i18n-t>
        <li>
          {{ tn("WSCBol2") }}
          <button
            id="btnGoToPlayStoreFromChromebookCard"
            type="button"
            class="inline-btn-link block mt-half"
            @click="goToPlayStore()"
          >
            <img src="@/assets/img/play-store.png" alt="Google Play Store" />
          </button>
        </li>
        <li>
          {{ tn("WSCBol3") }}
        </li>
      </ol>
      <ul class="mb">
        <li v-if="fetchKeyCodeState === showKeycodeState.loading">
          <span class="accent-box-info">
            <spinner :is-spinning="true" />
            {{ tn("keycodeRetrieving") }}
          </span>
        </li>
        <li v-if="fetchKeyCodeState === showKeycodeState.success">
          {{ tn("keycodeSuccess") }}
          <span id="keycodeDisplay" class="accent-box-info">
            <strong>{{ formatWebrootKeycode(purchaseKeyCode) }}</strong>
          </span>
          <button
            id="btnCopyKeycodeChromebookCard"
            type="button"
            class="btn-link"
            @click="copy(formatWebrootKeycode(purchaseKeyCode))"
          >
            {{ t("Common.copy") }}
          </button>
        </li>
        <li v-if="fetchKeyCodeState === showKeycodeState.failed">
          <span id="keycodeFailed" class="accent-box-info">
            {{ tn("keycodeFailed") }}
          </span>
        </li>
      </ul>
      <p>{{ tn("WSCBp1") }}</p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mb-0" v-html="tn('WSCBp2a')"></p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="border-bottom" v-html="tn('WSCBp2b')"></p>
      <p>
        <strong>{{ tn("forRenewals") }}</strong>
        {{ t("NextSteps.nextStepsRenewUpgradeP1") }}
      </p>
      <i18n-t keypath="WebrootDownloadInstructions.WSCBp4" tag="p" scope="global">
        <template #downloadApp>
          <button
            id="btnDownloadThreatShieldExtension"
            type="button"
            class="inline-btn-link"
            @click="downloadThreatShieldExtension()"
          >
            {{ t("Brand.WebrootWTSBE") }}
          </button>
        </template>
      </i18n-t>
    </article>
  </section>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { WEBROOT_CHROMEBOOK } from "@/define";
import router from "@/routes";
import Spinner from "@/components/shared/Spinner.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { formatKeycode as formatWebrootKeycode } from "@/common/formatKeycode";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { productList } from "@/common/productList";
import { webrootLicenseCategories } from "@/common/webrootProductName";
import { getUrl } from "@/common/getUrl";
import { showKeycodeState } from "@/components/Buy/BuyEnums";

const componentName = "WebrootChromebook";
const baseComponentName = "WebrootDownloadInstructions";
logEvent("created", componentName);

const buyFlowStore = useBuyFlowStore();
const fetchKeyCodeState = ref<showKeycodeState>(showKeycodeState.loading);
const purchaseKeyCode = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

onBeforeMount(() => {
  var keycode = buyFlowStore.PurchaseKeycodes.find(n => n.sku == WEBROOT_CHROMEBOOK)?.keycode;
  if (keycode) {
    purchaseKeyCode.value = keycode;
    fetchKeyCodeState.value = showKeycodeState.success;
  } else {
    fetchKeyCodeState.value = showKeycodeState.failed;
  }
});

function goToDownloads() {
  router.push("/downloads");
}

function goToPlayStore() {
  const link = productList().find(c => c.code === webrootLicenseCategories.WSCB)?.targetSystem[0].link;
  window.open(link, "_blank");
}

function downloadThreatShieldExtension() {
  window.open(getUrl("WEBROOT_THREAT_SHIELD_EXTENSION"), "_blank");
}

function copy(purchaseKeyCode: string) {
  logEvent("copy keycode", componentName, purchaseKeyCode);
  navigator.clipboard.writeText(purchaseKeyCode);
}
</script>
