/* eslint-disable vue/no-v-html */
<template>
  <td>
    <div class="choose-plan">
      <button :id="`btnChoose${props.productName}`" type="button" class="btn-primary" @click="toggleChoosePlan()">
        {{ tn("choosePlan") }}
        <svg-icon icon="chevron-down" class="icon-xs" />
      </button>
      <ul v-if="props.showChoosePlan" id="ratePlans">
        <li v-if="showDowngradeWarning" id="downgradeWarning" class="pa-0 info warning">
          <i18n-t keypath="ChoosePlan.downgradeWarningL1" tag="span" scope="global" class="text-sm">
            <template #fromSku>
              <strong>{{ t(`Brand.${getCarboniteProductTName(props.currentPlanSku)}`) }}</strong>
            </template>
            <template #toSku>
              <strong>{{ t(`Brand.${getCarboniteProductTName(props.productName)}`) }}</strong>
            </template>
          </i18n-t>
          <ul class="disc danger-text mt-half mb-half text-sm">
            <li v-for="item in showDowngradeWarning.items" :key="item">{{ tn(item) }}</li>
          </ul>
          <p v-for="disclaimer in showDowngradeWarning.disclaimers" :key="disclaimer" class="text-sm">
            {{ tn(disclaimer) }}
          </p>
        </li>
        <li v-for="ratePlan in orderedRatePlans" :key="ratePlan.ratePlanId" class="radio fixed-widths">
          <input
            v-model="selectedRatePlanId"
            type="radio"
            name="ratePlanIdGroup"
            :value="ratePlan.ratePlanId"
            :selected="selectedRatePlanId === ratePlan.ratePlanId"
          />
          <label class="mr-quarter">
            {{ getYears(ratePlan.months) }}
            <span v-if="getYears(ratePlan.months) > 1">{{ t("Common.years") }}</span>
            <span v-else>{{ t("Common.year") }}</span>
          </label>
          <div>
            <span class="price">${{ ratePlan.newSubscriptionPrice }}</span>
            <span v-if="ratePlan.newSubscriptionPrice !== ratePlan.catalogPrice" class="price strike text-sm text-grey">
              ${{ ratePlan.catalogPrice }}
            </span>
          </div>
          <div class="savings">
            <span v-if="ratePlan.percentSavings" class="accent-box text-sm">
              {{ tn("additional") }} {{ Math.round(ratePlan.percentSavings) }}% {{ tn("off") }}
            </span>
          </div>
        </li>
        <li v-if="showQuantityStepper" class="no-bg">
          <p>
            <small>{{ tn("numberOfComputers") }}</small>
          </p>
          <QuantityStepper
            :initial-number="numberOfItems"
            :sku="props.productName"
            @increase="updateQuantity(1)"
            @decrease="updateQuantity(-1)"
          />
        </li>
        <li>
          <button id="btnAddPlanToCart" type="button" class="btn-primary" @click="addToCart()">
            {{ t("Common.addToCart") }}
          </button>
        </li>
      </ul>
    </div>
  </td>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import SvgIcon from "@/components/shared/SvgIcon/SvgIcon.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import QuantityStepper from "@/components/Buy/QuantityStepper.vue";
import { getCarboniteProductTName } from "@/i18n/brandMapper";

const componentName = "ChoosePlan";
const numberOfItems = ref<number>(1);
const buyFlowStore = useBuyFlowStore();
const selectedRatePlanId = ref("");

const emits = defineEmits(["toggle-component", "add-to-cart", "show-monthly-to-annual-warning"]);
const props = defineProps({
  showChoosePlan: {
    type: Boolean,
    required: true,
  },
  productName: {
    type: String,
    required: true,
  },
  showQuantityStepper: {
    type: Boolean,
    default: true,
  },
  currentPlanSku: {
    type: String,
    required: false,
    default: null,
  },
  isMonthlyToAnnualUpgrade: {
    type: Boolean,
    default: false,
  },
});

// This configures how the downgrade warning will work.
// As of now, the only place that uses this object is this component so no reason to move it elsewhere.
const productMapping = [
  {
    sku: "PersonalBasic",
    downgradeSkus: [],
  },
  {
    sku: "PersonalPlus",
    downgradeSkus: [
      {
        sku: "PersonalBasic",
        items: ["backupExternalDrives", "automaticVideoBackup", "webrootAntivirus"],
        disclaimers: ["downgradeDisclaimer", "courierRecoveryDisclaimer"],
      },
    ],
  },
  {
    sku: "PersonalPrime",
    downgradeSkus: [
      {
        sku: "PersonalBasic",
        items: ["backupExternalDrives", "automaticVideoBackup", "webrootAntivirus", "courierRecoveryService"],
        disclaimers: ["downgradeDisclaimer", "courierRecoveryDisclaimer"],
      },
      {
        sku: "PersonalPlus",
        items: ["courierRecoveryService"],
        disclaimers: ["courierRecoveryDisclaimer"],
      },
    ],
  },
];

const showDowngradeWarning = computed(() => {
  const aa = productMapping
    .find(p => p.sku === props.currentPlanSku)
    ?.downgradeSkus.find(s => s.sku === props.productName);
  return aa;
});

const product = computed(() => {
  return buyFlowStore.getCatalogItemBySku(props.productName);
});

const orderedRatePlans = computed(() => {
  return product.value?.ratePlans.filter(l => l.canPurchaseOnline).sort((a, b) => a.months - b.months);
});

onBeforeMount(() => {
  if (orderedRatePlans.value && orderedRatePlans.value.length > 0) {
    selectedRatePlanId.value = orderedRatePlans.value[0].ratePlanId;
  }
});

function getYears(months: number) {
  return months / 12;
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function updateQuantity(amount: number) {
  numberOfItems.value += amount;
}

function addToCart() {
  if (props.isMonthlyToAnnualUpgrade) {
    emits("show-monthly-to-annual-warning", props.productName, selectedRatePlanId.value, numberOfItems.value);
  } else {
    emits("add-to-cart", props.productName, selectedRatePlanId.value, numberOfItems.value);
  }
}

watch(props, () => {
  if (props.showChoosePlan) {
    //Check if there is an item in the cart
    if (buyFlowStore.cart && buyFlowStore.cart.items.length > 0) {
      const item = buyFlowStore.cart.items[0];
      //If the item in the cart is the current product set the correct values
      if (item.sku === props.productName) {
        numberOfItems.value = item.quantity;
        selectedRatePlanId.value = item.ratePlanId;
      }
    }
  }
});

function toggleChoosePlan() {
  emits("toggle-component", props.productName);
}
</script>
