<template>
  <div class="mb-double flex-start-lg">
    <div class="flex-start-mobile-and-md align-items-center no-shrink">
      <div class="mr">
        <svg-icon-component icon="icon-allstate" class="mb" />
      </div>
      <h3 class="mb-quarter only-show-mobile-and-md-inline">{{ tn("header") }}</h3>
    </div>
    <div class="grow">
      <h3 class="mb-quarter only-show-lg-inline">{{ tn("header") }}</h3>
      <p>{{ tn("p1") }}</p>
    </div>
    <div class="pt-and-a-half-desktop-lg text-right-lg">
      <button id="btnAllState" class="btn-primary" :disabled="!props.isActive" @click="go()">
        {{ tn(props.isSetUp ? "btnManage" : "btnSetUp") }}
      </button>
      <div v-show="!props.isActive">{{ tn("infoText") }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { useRouter } from "vue-router";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";

const props = defineProps({
  isSetUp: {
    type: Boolean,
    required: true,
  },
  isActive: {
    type: Boolean,
    required: true,
  },
});

const componentName = "AllStateDownloadsTab";
const router = useRouter();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function go() {
  if (props.isSetUp) {
    //Go to AllState portal
    window.open(getUrl("ALLSTATE_MANAGE_URL"), "_blank");
    return;
  }
  router.push("/allstateSignUp");
}
</script>
