<template>
  <div class="flex-start-md">
    <p>
      <span>
        <svg-icon-component icon="circle-exclamation" class="icon-sm danger" />
      </span>
      <i18n-t keypath="NotificationsComponent.NonMigratedLegacyWebrootError" tag="span" class="mr-half" scope="global">
        <template #contactSupport>
          <button class="inline-btn-link" @click="openSupport()">
            {{ t("NotificationsComponent.contactSupportTeam") }}
          </button>
        </template>
      </i18n-t>
    </p>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";

function openSupport() {
  window.open(getUrl("VITE_APP_CARBONITE_SUPPORT_SITE"), "_blank");
}
</script>
