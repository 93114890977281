<template>
  <input v-model="currentValue" v-maska="maskOptions" :data-maska="maskValue" type="text" :placeholder="placeholder" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { logEvent } from "@/common/logger";
import { MaskTypes } from "@/globalEnums";
import { MaskaDetail } from "maska";

const componentName = "MaskedInputComponent";

/*
the default maska pattern matching syntax:
{
    '#': { pattern: /[0-9]/ },
    'X': { pattern: /[0-9a-zA-Z]/ },
    'S': { pattern: /[a-zA-Z]/ },
    'A': { pattern: /[a-zA-Z]/, uppercase: true },
    'a': { pattern: /[a-zA-Z]/, lowercase: true },
    '!': { escape: true },
    '*': { repeat: true }
}
It is possible to add more sophisticated pattern matching if required

Examples for using this control:
<MaskedInputComponent :mask-type="MaskTypes.phone" initial-value="1231231234" @update="handleUpdate" />
<MaskedInputComponent :mask-type="MaskTypes.date" initial-value="02022020" @update="handleUpdate" />
<MaskedInputComponent :mask-type="MaskTypes.zip" initial-value="000001111" @update="handleUpdate" />
<MaskedInputComponent :mask-type="MaskTypes.nonEmpty" initial-value="there" :min-length="3" :max-length="5" @update="handleUpdate" />

the handleUpdate() function will be called once the user has typed a complete mask. If the user keeps typing after the mask is full
then the update event will continue to trigger.

*/

const props = defineProps({
  initialValue: {
    type: String,
    required: false,
    default: null,
  },
  maskType: {
    type: String,
    required: true,
  },
  minLength: {
    type: Number,
    required: false,
    default: null,
  },
  maxLength: {
    type: Number,
    required: false,
    default: null,
  },
  validateLength: {
    type: Boolean,
    required: false,
    default: false,
  },
  validateDate: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["update"]);
logEvent("Created", componentName, { maskType: props.maskType });

const maskValue = ref<string | string[]>();
const placeholder = ref<string>();
const currentValue = ref<string>(props.initialValue);

let requiredLength: number | Array<number> = 0;
let minLengthItem = 0;
let maxLengthItem = 0;

switch (props.maskType) {
  case MaskTypes.date:
    maskValue.value = "##/##/####";
    placeholder.value = "mm/dd/yyyy";
    requiredLength = 8;
    break;
  case MaskTypes.phone:
    maskValue.value = "(###) ###-####";
    placeholder.value = "(___) ___-____";
    requiredLength = 10;
    break;
  case MaskTypes.zip:
    maskValue.value = "#####";
    placeholder.value = "";
    requiredLength = 5;
    break;
  case MaskTypes.nonEmpty:
    minLengthItem = props.minLength ?? 1;
    maxLengthItem = props.maxLength ?? 50;
    maskValue.value = "".padEnd(maxLengthItem, "X");
    placeholder.value = "";
    break;
}

const maskOptions = ref({
  onMaska: handleChange,
});

function handleChange(detail: MaskaDetail) {
  const rawValue: string = detail.unmasked;
  const formattedValue: string = detail.masked;

  let shouldEmit = true;

  // Added prop to tell to the component if the length should be validated or not
  if (props.validateLength) {
    shouldEmit = false;
    //if this field requires a specific length
    if (requiredLength) {
      //if it has the length(s) then it is ok
      if (requiredLength instanceof Array) shouldEmit = (requiredLength as Array<number>).includes(rawValue.length);
      else shouldEmit = rawValue.length == requiredLength;
    } else {
      //if it has a range ensure it is in that range
      shouldEmit = true;
      if (minLengthItem > 0) shouldEmit &&= rawValue.length >= minLengthItem;
      if (maxLengthItem > 0) shouldEmit &&= rawValue.length <= maxLengthItem;
    }
  }

  //if collecting date, then verify that this is a valid date
  if (props.maskType == MaskTypes.date) {
    const month = parseInt(rawValue.slice(0, 2));
    const day = parseInt(rawValue.slice(2, 4));
    const year = parseInt(rawValue.slice(4));
    try {
      const date = new Date(year, month, day);
      if (props.validateDate && date.getTime() > new Date().getTime()) shouldEmit = false;
    } catch {
      if (props.validateDate) shouldEmit = false;
    }
  }

  // if it passes all the tests or length equals to 0, let the parent know the new value
  if (shouldEmit || rawValue.length === 0) emits("update", { rawValue, formattedValue });
}
</script>
