<template>
  <i18n-t :keypath="'NotificationsComponent.' + props.notification.type" tag="span" class="pl-quarter" scope="global">
    <template #contactSupport>
      <button class="inline-btn-link" target="_blank" @click="goToSupportPage()">
        {{ t("Common.contactSupport") }}
      </button>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";
import { getUrl } from "@/common/getUrl";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

function goToSupportPage() {
  window.open(getUrl("VITE_APP_CARBONITE_SUPPORT_SITE"), "_blank");
}
</script>
