<template>
  <div>
    <p>
      <strong>{{ tn("WRAutoRenewOffLine1") }}</strong>
    </p>
    <ul class="tight mt mb">
      <li>
        <i18n-t keypath="WebrootARTurnOffComponent.WRAutoRenewOffLine2" tag="span" scope="global">
          <template #page>
            <button id="visitPage" type="button" class="inline-btn-link" @click="goToWebrootRenewalOptOut()">
              {{ t("WebrootARTurnOffComponent.Link2") }}
            </button>
          </template>
        </i18n-t>
      </li>
      <li>
        <p>
          {{ tn("WRAutoRenewOffLine3") }}
          <br />
          {{ tn("Keycode") }}
          <strong>{{ formatKeycode(subscription?.keyCode ?? "", subscription?.licenseCategoryName ?? "") }}</strong>
          <button
            id="copy"
            type="button"
            class="inline-btn-link"
            @click="copy(formatKeycode(subscription?.keyCode ?? '', subscription?.licenseCategoryName ?? ''))"
          >
            {{ tn("WRAutoRenewOffLine4") }}
          </button>
        </p>
      </li>
      <li>
        <i18n-t keypath="WebrootARTurnOffComponent.WRAutoRenewOffLine5" tag="span" scope="global">
          <template #link>
            <button id="knowledgeBase" type="button" class="inline-btn-link" @click="goToWebrootKnowledgeBase()">
              {{ t("WebrootARTurnOffComponent.Link1") }}
            </button>
          </template>
        </i18n-t>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { formatKeycode } from "@/common/formatKeycode";
import { getUrl } from "@/common/getUrl";

const componentName = "WebrootARTurnOffComponent";

const props = defineProps({
  subscription: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

logEvent("created", componentName, props.subscription);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function copy(keycodeText: string) {
  navigator.clipboard.writeText(keycodeText);
}

function goToWebrootRenewalOptOut() {
  window.open(getUrl("WEBROOT_RENEWAL_OPT_OUT"), "_blank");
}

function goToWebrootKnowledgeBase() {
  window.open(getUrl("AUTO_RENEWAL_OPT_OUT"), "_blank");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
