<template>
  <div class="flex-start-md">
    <p>
      <span>
        <svg-icon-component icon="circle-exclamation" class="icon-sm danger" />
        <svg-icon-component icon="circle-triangle" class="icon-sm warning" />
      </span>
      <span class="mr-half">
        {{ t(`NotificationsComponent.${notification.type}`, { subscription: subscriptionText }) }}
      </span>
    </p>
    <div class="btn-wrapper">
      <button class="btn-primary mr-quarter" target="_self" @click="renewNow()">
        {{ t("NotificationsComponent.RenewNow") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref } from "vue";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { unifiedApi } from "@/common";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import router from "@/routes";
import { pageStates as buyPageStates } from "@/components/Buy/BuyEnums";
import { webrootLicenseCategories } from "@/common/webrootProductName";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

const subscriptionsStore = useSubscriptionsStore();
const subscriptionText = ref<string>(props.notification?.params?.subscription ?? "");
const sub = computed(() => {
  return subscriptionsStore.subscriptions.find(l => l.keyCode === props.notification?.params?.keycode);
});
async function renewNow() {
  try {
    if (props.notification?.params?.keycode) {
      if (sub.value?.hasOutstandingInvoices) {
        router.push({
          name: "subscription_information",
        });
        return;
      }

      if (sub.value?.licenseCategoryName === webrootLicenseCategories.WSAIA) {
        router.push({ name: "buy", query: { step: buyPageStates.email, sku: "WebrootPremium" } });
        return;
      }

      var url = (await unifiedApi.getWebrootRenewUrl(props.notification?.params?.keycode)).data.redirectUrl;
      window.open(url, "_self");
    }
  } catch (err) {
    handleApiError(err as AxiosError, false);
  }
}
</script>
