<template>
  <div v-if="showSpinner">
    <LoadingSpinner />
  </div>
  <article>
    <ReturnComponent v-if="redirectTo === PayPalRedirectState.Return" />
    <NotifyComponent v-if="redirectTo === PayPalRedirectState.Notify" />
    <CancelComponent v-if="redirectTo === PayPalRedirectState.Cancel" />
  </article>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, PropType } from "vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import ReturnComponent from "@/components/PayPal/Return.vue";
import NotifyComponent from "@/components/PayPal/Notify.vue";
import CancelComponent from "@/components/PayPal/Cancel.vue";
import { logEvent, logException } from "@/common/logger";
import { PayPalRedirectState } from "@/globalEnums";

const componentName = "PayPalRedirectComponent";

const props = defineProps({
  redirectTo: {
    type: String as PropType<PayPalRedirectState>,
    default: null,
  },
});

logEvent("created", componentName);
const showSpinner = ref<boolean>(true);
const redirectTo = ref<string>(props.redirectTo);

onMounted(() => {
  try {
    logEvent("mounted", componentName);
    showSpinner.value = false;
  } catch (err) {
    logException(err as Error, componentName);
  }
});

onUnmounted(() => {
  logEvent("unmounted", componentName);
});
</script>
