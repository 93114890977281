<template>
  <div class="mt mb-double">
    <ul class="flex-start-md">
      <li class="mr-md m-auto-mobile mb-double-mobile constrain-25">
        <div class="text-center">
          <img src="@/assets/img/nextsteps-win-1.png" alt="Screenshot of first step in downloading" />
          <h4>{{ tn("downloadInstructionsWindows1") }}</h4>
        </div>
        <div v-if="showDetailsWin" id="Details1">
          <p>{{ tn("nextStepsWin1Line1") }}</p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="tn('nextStepsWin1Line2')"></p>
          <p>
            <button type="button" class="btn-link" @click="goToWinKbArticle()">
              {{ tn("nextStepsWin1Line3") }}
            </button>
          </p>
        </div>
      </li>
      <li class="mr-md m-auto-mobile mb-double-mobile constrain-25">
        <div class="text-center">
          <img src="@/assets/img/nextsteps-win-2.png" alt="Screenshot of third step in downloading" />
          <h4>{{ tn("downloadInstructionsWindows2") }}</h4>
        </div>
        <div v-if="showDetailsWin" id="Details2">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="tn('nextStepsWin2Line1')"></p>
        </div>
      </li>
      <li class="m-auto-mobile constrain-25">
        <div class="text-center">
          <img src="@/assets/img/nextsteps-win-3.png" alt="Screenshot of first step in downloading" />
          <h4>{{ tn("downloadInstructionsWindows3") }}</h4>
        </div>
        <div v-if="showDetailsWin" id="Details2">
          <p>{{ tn("nextStepsWin3Line1") }}</p>
        </div>
      </li>
    </ul>
    <div>
      <button id="btnDetails" type="button" class="btn-link" @click="toggleShowDetailsWin()">
        <span v-if="showDetailsWin">
          {{ tn("hideDetails") }}
          <svg-icon-component icon="chevron-up" class="icon-sm pl-quarter" />
        </span>
        <span v-else>
          {{ tn("showDetails") }}
          <svg-icon-component icon="chevron-down" class="icon-sm pl-quarter" />
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { ref } from "vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { logEvent } from "@/common/logger";

const componentName = "CarboniteNextStepsWindows";
const baseComponentName = "NextSteps";
const showDetailsWin = ref<boolean>(false);
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function toggleShowDetailsWin() {
  return (showDetailsWin.value = !showDetailsWin.value);
}
function goToWinKbArticle() {
  window.open(
    "https://support.carbonite.com/articles/Personal-Windows-Installing-Carbonite-and-Initial-Backup-Selections",
    "_blank"
  );
}
</script>
