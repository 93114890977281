<template>
  <GetFilesWizardComponent :device="curDevice" />
</template>

<script setup lang="ts">
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { useRouter } from "vue-router";
import GetFilesWizardComponent from "@/components/Backup/GetFilesWizard.vue";
import { useUserStore } from "@/stores/user";
import { ref } from "vue";

const router = useRouter();
const userStore = useUserStore();

const q = router.currentRoute.value.query;
const deviceId = parseInt((q.deviceId || q.deviceid) as string);
const device = userStore?.userDetails?.devices.find(d => d.deviceId == deviceId);
const curDevice = ref<IDevice | undefined>(device);
</script>
