import { SafeDataPurgeDays } from "@/define";

export function isPurgedComputer(
  daysRemaining: number,
  isTrial: boolean,
  isResellerUser: boolean,
  dataHasBeenPurged: boolean,
  isEvergreen: boolean
): boolean {
  return (
    dataHasBeenPurged ||
    (isTrial && daysRemaining <= SafeDataPurgeDays.Trials) || // trial
    (!isTrial && !isResellerUser && daysRemaining <= SafeDataPurgeDays.Direct) || // direct consumer
    (!isTrial && isResellerUser && daysRemaining <= SafeDataPurgeDays.Reseller) || // consumer under reseller
    (isEvergreen && daysRemaining <= 0)
  ); // Expired Evergreen
}
