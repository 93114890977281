<template>
  <div v-if="showSpinner" class="credit-cards">
    <LoadingSpinner />
  </div>
  <div v-else class="credit-cards">
    <NotificationsComponent context="CreditCardsComponent" />
    <div class="content">
      <KbSearchComponent />
      <h1>{{ tn("cardsOnFile") }}</h1>
      <ul class="card credit-card">
        <li v-for="card in creditCards" :id="`card${card.id}`" :key="card.id">
          <CardCreditComponent :card="card" />
        </li>
      </ul>
      <div>
        <button type="button" class="btn-primary" @click="handleAddCardClick">{{ tn("addCardBtn") }}</button>
        <button type="button" class="btn-link">{{ tn("editCardBtn") }}</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

import { unifiedApi } from "@/common";
import { IPaymentMethod } from "@/common/api/unifiedPortal/interfaces";

import KbSearchComponent from "@/components/shared/KbSearch.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import CardCreditComponent from "./CardCredit.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";

const componentName = "CreditCardsComponent";

logEvent("created", componentName);

const router = useRouter();
const creditCards = ref<IPaymentMethod[]>([]);
const showSpinner = ref<boolean>(true);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  try {
    const cards = (await unifiedApi.getUserPaymentMethods()).data.paymentMethods;
    creditCards.value = cards.filter(x => x.cardType !== "PayPal") ?? [];
  } catch (err) {
    handleApiError(err as AxiosError);
  }
  showSpinner.value = false;
});

function handleAddCardClick() {
  router.push({ name: "add_credit_cards" });
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
