import { unifiedApi } from "@/common";
import { ref, computed } from "vue";
import { IPayPalApprovalResponse } from "@/common/api/unifiedPortal/interfaces";
import { defineStore } from "pinia";
import { pageStates, purchaseErrorsNotifications } from "@/components/Buy/BuyEnums";
import { logEvent } from "@/common/logger";
import { payPalCheckOutPage } from "@/globalEnums";

export const usePayPalStore = defineStore("payPal", () => {
  const payPalBillingAgreementResponse = ref<IPayPalApprovalResponse | null>();
  const payPalCheckoutFrom = ref<payPalCheckOutPage>(payPalCheckOutPage.BuyFlowPayment);
  const purchaseError = ref<purchaseErrorsNotifications | null>(null);

  //store the shipping address to be used in Review order Page
  const payPalShippingAddress = computed(() => {
    return payPalBillingAgreementResponse.value?.shippingAddress;
  });

  //store the shipping address to be used in Review order Page
  const payPalPaymentMethodId = computed(() => {
    return payPalBillingAgreementResponse.value?.paymentMethodId;
  });

  const payPalEmail = computed(() => {
    return payPalBillingAgreementResponse.value?.email;
  });

  //Generate route to redirect to when paypal return success
  const getPayPalSuccessRoute = computed(() => {
    let path = "/";
    let queryParams = {};
    if (payPalBillingAgreementResponse.value) {
      path = "buy";
      queryParams = Object.assign(
        {},
        {
          step: pageStates.reviewOrder,
          from: "Paypal",
        }
      );
    }
    return { path: path, query: queryParams };
  });
  async function setPayPalBillingAgreementResponse(ba_token: string) {
    //The response we get back contains the address of the user,their email and the paymentMethodId
    //send these back to the buy flow with that information
    payPalBillingAgreementResponse.value = (await unifiedApi.payPalApprove(ba_token)).data;
    logEvent("PayPalBillingAgreementResponse", "paypal", payPalBillingAgreementResponse.value);
  }

  //check and set if cart has AllState with Non US Paypal Shipping Address
  //if yes should display available in the U.S and complete checkout must be disabled
  const payPalShippingAddressIsNonUS = computed(() => {
    if (payPalBillingAgreementResponse.value?.shippingAddress) {
      if (payPalBillingAgreementResponse.value?.shippingAddress.countryCode?.toLowerCase() != "us") {
        return true;
      }
    }
    return false;
  });

  function SetPayPalCheckoutFrom(page: payPalCheckOutPage) {
    payPalCheckoutFrom.value = page;
  }

  function clearPayPalBillingAgreementResponse() {
    payPalBillingAgreementResponse.value = null;
  }

  //Adding this because we are having issues displaying PayPal info when selecting an already existing PayPal
  //payment method from the drop down in the buy flow
  function setPayPalBillingAgreementResponseManually(response: IPayPalApprovalResponse) {
    payPalBillingAgreementResponse.value = response;
  }

  return {
    setPayPalBillingAgreementResponse,
    getPayPalSuccessRoute,
    payPalShippingAddress,
    payPalPaymentMethodId,
    payPalEmail,
    payPalShippingAddressIsNonUS,
    SetPayPalCheckoutFrom,
    payPalCheckoutFrom,
    clearPayPalBillingAgreementResponse,
    purchaseError,
    setPayPalBillingAgreementResponseManually,
  };
});
