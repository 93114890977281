<template>
  <section>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <div v-if="FLAGS.ENABLE_CDLP === 'true'">
      <p v-if="isCDLPUpgradeFromAntivirusGroup">{{ tn("nextStepsCDLPRenewUpgradeP1") }}</p>
      <p v-else>{{ tn("nextStepsRenewUpgradeP1") }}</p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('nextStepsWebrootAllStepsMyAccount')"></p>
      <p class="mb">
        <button id="btn2GoToDownloadsFromRenewUpgrade" type="button" class="btn-primary" @click="goToDownloads()">
          {{ t("Common.visitMyAccount") }}
        </button>
      </p>
    </div>
    <div v-else>
      <p>
        <button id="btn1GoToDownloadsFromRenewUpgrade" type="button" class="inline-btn-link" @click="goToDownloads()">
          {{ t("Common.visitMyAccount") }}
        </button>
        {{ tn("nextStepsWebrootRenewUpgradeP1") }}
      </p>
      <p class="mb">
        <button id="btn2GoToDownloadsFromRenewUpgrade" type="button" class="btn-primary" @click="goToDownloads()">
          {{ t("Common.goToMyAccount") }}
        </button>
      </p>
    </div>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { continueToMyAccount } from "../BuyHelper";
import { computed } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { upgradeGroup } from "@/globalEnums";
import { FLAGS, WEBROOT_TOTAL_PROTECTION, WEBROOT_TOTAL_PROTECTION_FAMILY } from "@/define";

const componentName = "WebrootNextStepsEstablished";
const baseComponentName = "NextSteps";
const buyFlowStore = useBuyFlowStore();
const WTPSkus = [WEBROOT_TOTAL_PROTECTION, WEBROOT_TOTAL_PROTECTION_FAMILY];
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToDownloads() {
  continueToMyAccount();
}
const isCDLPUpgradeFromAntivirusGroup = computed(() => {
  return (
    WTPSkus.some(s => s === buyFlowStore.cart?.items[0].sku) &&
    buyFlowStore.plans.some(
      p =>
        p.sku == buyFlowStore.licenseKeyInfo?.sku &&
        !WTPSkus.some(s => s === p.sku) &&
        p.upgradeGroup === upgradeGroup[upgradeGroup.ConsumerAntivirus]
    )
  );
});
</script>
