<template>
  <div class="mb-double">
    <ul>
      <li class="bold-text">{{ tn("allstateIdentityProtection") }}</li>
      <li>{{ tn("findHelpText") }}</li>
      <li>
        <ul class="pipe">
          <li>
            <a id="goAllstateFAQ" class="inline-btn-link" @click="goAllstateFAQ">
              {{ tn("goAllstateFAQText") }}
            </a>
          </li>
          <li>
            <a id="contactAllState" class="inline-btn-link" @click="contactAllState">
              {{ tn("contactAllState") }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "AllstateSupportSectionComponent";
const allstateFAQsLink = "https://www.allstateidentityprotection.com/webroot-faqs";
const allstateContactSupportLink = "https://www.allstateidentityprotection.com/contact-us?hsLang=en";

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function goAllstateFAQ() {
  logEvent("Go to Carb Knowledge base", componentName);
  window.open(`${allstateFAQsLink}`);
}

function contactAllState() {
  logEvent("Go to Carb Knowledge base", componentName);
  window.open(`${allstateContactSupportLink}`);
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
