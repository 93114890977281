import { t } from "../i18n";

export function getWebrootLicenseDescription(licenseCategory: string): string | undefined {
  return t(`Brand.${licenseCategory}`);
}
export interface IWebrootProductNames {
  licenseCategory: string;
  sku: string;
  candyRackDescription: string[];
}

export const webrootLicenseCategories = {
  WSAV: "WSAV",
  WSAI: "WSAI",
  WSAC: "WSAC",
  WSAFMPK: "WSAFMPK",
  WSCB: "WSCB",
  WSAG: "WSAG",
  WIFI: "WIFI",
  WSAP: "WSAP",
  WSID: "WSID",
  WSAPF: "WSAPF",
  WSIDP: "WSIDP",
  WSIDPF: "WSIDPF",
  WSAIA: "WSAIA",
  WTP: "WTP",
  WTPF: "WTPF",
  WE: "WE",
  CD: "CD",
  WW: "WW",
  ADP: "ADP",
  WSASB: "WSASB",
  WPCO: "WPCO",
  WSVPN: "WSVPN",
  Webroot: "Webroot", // used by old Legacy Webroot Records
};

export const webrootLCNs = [
  "WSAV",
  "WSAI",
  "WSAC",
  "WSAFMPK",
  "WSCB",
  "WSAG",
  "WIFI",
  "WSAP",
  "WSID",
  "WSAPF",
  "WTP",
  "WTPF",
  "WW",
  "ADP",
  "WSASB",
  "WSAIA",
  "WPCO",
];
export const allstateLCNs = [
  webrootLicenseCategories.WSAP,
  webrootLicenseCategories.WSAPF,
  webrootLicenseCategories.WSID,
  webrootLicenseCategories.WSIDP,
  webrootLicenseCategories.WSIDPF,
  webrootLicenseCategories.WTP,
  webrootLicenseCategories.WTPF,
  webrootLicenseCategories.WSAIA,
];
export const premiumLCNs = [
  webrootLicenseCategories.WSAP,
  webrootLicenseCategories.WSAPF,
  webrootLicenseCategories.WTP,
  webrootLicenseCategories.WTPF,
  webrootLicenseCategories.WSAIA,
];
export const allstatePremiumFamilyLCNs = [
  webrootLicenseCategories.WSIDPF,
  webrootLicenseCategories.WSAPF,
  webrootLicenseCategories.WSAIA,
];
export const nonAllstateWebrootLCNs = [
  webrootLicenseCategories.WSAV,
  webrootLicenseCategories.WSCB,
  webrootLicenseCategories.WSAG,
  webrootLicenseCategories.WIFI,
  webrootLicenseCategories.WSAC,
  webrootLicenseCategories.WSAFMPK,
  webrootLicenseCategories.WSAI,
  webrootLicenseCategories.WW,
  webrootLicenseCategories.ADP,
  webrootLicenseCategories.WSASB,
];
export const addOnLCNs = [
  webrootLicenseCategories.WSID,
  webrootLicenseCategories.WSIDP,
  webrootLicenseCategories.WSIDPF,
];
export const webrootCDSku = "WebrootSoftwareInstallationCD";
export const bestBuyAllStateLCNs = [webrootLicenseCategories.WSAIA];

export const bazaarVoiceWebrootProductIds = {
  WSAV: "11100110",
  WSAI: "11115031",
  WSAC: "11120510",
  WSAP: "11136105",
  WSAPF: "11140110",
  WSAG: "11100110G",
  WSIDP: "11136105", // same as WSAP
  WSIDPF: "11140110", // same as WSAPF
  WSVPN: "WSVPN",
  WPCO: "WPCO",
};
export const webrootProducts: IWebrootProductNames[] = [
  {
    licenseCategory: webrootLicenseCategories.WSAV,
    sku: "WebrootAntiVirus",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAI,
    sku: "WebrootPlus",
    candyRackDescription: ["SpecialOffers.WebrootPlusDescription1", "SpecialOffers.WebrootPlusDescription2"],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAC,
    sku: "WebrootComplete",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAFMPK,
    sku: "WebrootCompleteFamily",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSCB,
    sku: "WebrootChromebook",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAG,
    sku: "WebrootGamer",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WIFI,
    sku: "WebrootSecureVPN",
    candyRackDescription: [
      "SpecialOffers.WebrootWiFiSecurityDescription1",
      "SpecialOffers.WebrootWiFiSecurityDescription2",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAP,
    sku: "WebrootPremium",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSAPF,
    sku: "WebrootPremiumFamily",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSID,
    sku: "AllstateIdentityProtectionBasic",
    candyRackDescription: [
      "SpecialOffers.AllstateIdentityProtectionBasicDescription1",
      "SpecialOffers.AllstateIdentityProtectionBasicDescription2",
      "SpecialOffers.AllstateIdentityProtectionBasicDescription3",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSIDP,
    sku: "AllstateIdentityProtectionPremium",
    candyRackDescription: [
      "SpecialOffers.AllstateIdentityProtectionPremiumDescription1",
      "SpecialOffers.AllstateIdentityProtectionPremiumDescription2",
      "SpecialOffers.AllstateIdentityProtectionPremiumDescription3",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSIDPF,
    sku: "AllstateIdentityProtectionPremiumFamily",
    candyRackDescription: [
      "SpecialOffers.AllstateIdentityProtectionPremiumFamilyDescription1",
      "SpecialOffers.AllstateIdentityProtectionPremiumFamilyDescription2",
      "SpecialOffers.AllstateIdentityProtectionPremiumFamilyDescription3",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WTP,
    sku: "WebrootTotalProtection",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WTPF,
    sku: "WebrootTotalProtectionFamily",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.CD,
    sku: webrootCDSku,
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WW,
    sku: "WindowWasher",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.ADP,
    sku: "AndroidMobile",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WSASB,
    sku: "WebrootSmallBusinessAntiVirus",
    candyRackDescription: [],
  },
  {
    licenseCategory: webrootLicenseCategories.WPCO,
    sku: "WebrootPcOptimizer",
    candyRackDescription: [
      "SpecialOffers.WebrootPcOptimizerDescription1",
      "SpecialOffers.WebrootPcOptimizerDescription2",
    ],
  },
  {
    licenseCategory: webrootLicenseCategories.WSVPN,
    sku: "WebrootSecureVPNP",
    candyRackDescription: ["SpecialOffers.WebrootVPNDescription1", "SpecialOffers.WebrootVPNDescription2"],
  },
];

export const webrootSkusWithNotableFeature = [
  "WebrootAntiVirus",
  "WebrootGamer",
  "WebrootPlus",
  "WebrootComplete",
  "WebrootPremium",
  "WebrootPremiumFamily",
  "AllstateIdentityProtectionBasic",
  "AllstateIdentityProtectionPremium",
  "AllstateIdentityProtectionPremiumFamily",
  "WebrootChromebook",
  "WebrootSecureVPN",
  "AndroidMobile",
  "WebrootSmallBusinessAntiVirus",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
  "WebrootPcOptimizer",
  "WebrootSecureVPNP",
];
export const webrootMultiDeviceSkus = [
  "WebrootPlus",
  "WebrootComplete",
  "WebrootPremium",
  "WebrootPremiumFamily",
  "WebrootChromebook",
  "WebrootSecureVPN",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
  "WebrootPcOptimizer",
  "WebrootSecureVPNP",
];
export const webrootSkusWithIdentity = [
  "WebrootPremium",
  "WebrootPremiumFamily",
  "AllstateIdentityProtectionBasic",
  "AllstateIdentityProtectionPremium",
  "AllstateIdentityProtectionPremiumFamily",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
];
export const webrootSkusThatAlreadyHaveIdentity = [
  "WebrootPremium",
  "WebrootPremiumFamily",
  "WebrootTotalProtection",
  "WebrootTotalProtectionFamily",
];
export const webrootCDLP = ["WebrootTotalProtection", "WebrootTotalProtectionFamily"];
export const webrootCDLPcodes = [webrootLicenseCategories.WTP, webrootLicenseCategories.WTPF];
export const webrootSkusThatAreRenewOnly = ["WindowWasher", "AndroidMobile", "WebrootSmallBusinessAntiVirus"];
export const webrootOnlyAllstateSkus = [
  "AllstateIdentityProtectionBasic",
  "AllstateIdentityProtectionPremium",
  "AllstateIdentityProtectionPremiumFamily",
];

export function convertWebrootSku(sku: string, licenseCategory: string) {
  if (webrootProducts.some(w => w.sku === sku)) {
    return sku;
  }

  const response = webrootProducts.find(w => w.licenseCategory.toString() === licenseCategory);
  return response?.sku ?? "";
}
