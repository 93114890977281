<template>
  <ul class="plansList constrain-65">
    <i18n-t
      v-for="(sub, index) in webrootSubs"
      :id="`sub${index}`"
      :key="`sub${index}`"
      keypath="WebrootProductsInfo.li"
      tag="li"
      scope="global"
    >
      <template #sku>
        <strong>{{ t(`Brand.${sub.sku}`) }}</strong>
      </template>
      <template #keycode>
        <strong>{{ sub.displayKeycode }}</strong>
      </template>
    </i18n-t>
  </ul>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IOutstandingInvoice, ISubscription } from "@/common/api/unifiedPortal/interfaces";

const componentName = "WebrootProductsInfo";

const props = defineProps({
  subscriptions: {
    type: Array as PropType<ISubscription[]>,
    required: true,
  },
  outstandingInvoices: {
    type: Array as PropType<IOutstandingInvoice[]>,
    required: true,
  },
});

logEvent("created", componentName);

const webrootSubs = computed(() => {
  //Filter the subs to only return the selected ones that have an outstanding invoice
  return props.subscriptions.filter(
    l =>
      l.outstandingInvoice &&
      props.outstandingInvoices.find(i => i.invoiceNumber === l.outstandingInvoice?.invoiceNumber)
  );
});
</script>
