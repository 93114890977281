<template>
  <ModalWrapperComponent
    dialog-name="CancelSubscriptionComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("modalTitle") }}
    </template>
    <template #content>
      <div>
        <p>{{ tn("note1") }}</p>
        <ul class="tight mt mb">
          <li>
            {{ tn("subscription") }}
            <strong>{{ subscriptionName }}</strong>
          </li>
          <li>
            {{ tn("computerName") }}
            <strong>{{ computerName }}</strong>
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <button id="btnCancelSubscription" type="button" class="btn-link mr" :disabled="isCanceling" @click="save()">
        <spinner :is-spinning="isCanceling" />
        {{ tn("footerButtonCancel") }}
      </button>
      <button id="btnNotCancel" type="button" class="btn-primary" @click="close()">
        {{ tn("footerButtonDoNotCancel") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";

const componentName = "CancelSubscriptionComponent";

const props = defineProps({
  subscription: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.subscription);
const isCanceling = ref<boolean>(false);
const subscriptionName = ref<string>("");
const computerName = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function save() {
  isCanceling.value = true;
  try {
    const updated = (await unifiedApi.cancelSubscription(props.subscription.zuoraSubscriptionNumber)).data;

    emits("save-modal", updated === true);
  } catch (error) {
    handleApiError(error as AxiosError);
  }
  isCanceling.value = false;
}

function close() {
  emits("close-modal");
}

function beforeOpen() {
  logEvent("Before open", componentName);
  subscriptionName.value = props.subscription?.productDisplayName ?? "";
  computerName.value = props.subscription?.computer?.name ?? "";
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
