<template>
  <ModalWrapperComponent
    dialog-name="ImagePreviewComponent"
    :show-dialog="showDialog"
    css-class="modal-lg"
    @close-modal="close"
  >
    <template #header>
      <h1 class="truncate">{{ previewItem?.Name }}</h1>
    </template>
    <template #content>
      <div class="flex-start align-items-center">
        <div>
          <button id="nav-prev" class="btn-link" :title="tn('previous')" @click="navPrevious">
            <svg-icon-component icon="chevron-left" />
          </button>
        </div>
        <div class="image-wrapper">
          <img :src="previewItem?.Base64Image" />
        </div>
        <div>
          <button id="nav-next" class="btn-link" :title="tn('next')" @click="navNext">
            <svg-icon-component icon="chevron-right" />
          </button>
        </div>
      </div>
      <div class="name-size-modified">
        <div class="opposite-ends mb-half">
          <div>
            <i>{{ tn("name") }}:</i>
          </div>
          <div class="file-name">{{ previewItem?.Name }}</div>
        </div>
        <div class="opposite-ends mb-half">
          <div>
            <i>{{ tn("size") }}:</i>
          </div>
          <div>{{ convertSize(previewItem?.Size ?? 0) }}</div>
        </div>
        <div class="opposite-ends">
          <div>
            <i>{{ tn("modified") }}:</i>
          </div>
          <div>{{ formatFileDate(previewItem?.Modified) }}</div>
        </div>
      </div>
    </template>
    <template #footer>
      <button id="download-image" type="button" class="btn-link" :disabled="isDownloading" @click="download">
        <spinner :is-spinning="isDownloading" />
        {{ tn("footerButtonDownload") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import { formatFileDate } from "@/common/dateFormat";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { IRfaItem } from "@/components/FileAccess/interfaces";
import { convertSize } from "@/components/FileAccess/viewFn";

const componentName = "ImagePreviewComponent";

const props = defineProps({
  previewItem: {
    type: Object as PropType<IRfaItem | undefined>,
    required: false,
    default: undefined,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
  isDownloading: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal", "nav", "download"]);

logEvent("created", componentName, props.previewItem?.FileId);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

//tell the parent to that the user requested this file be downloaded
//get the parent to do the work
function download() {
  logEvent("download", componentName, props.previewItem?.FileId);
  emits("download");
}

//tell the parent to that the user requested to move between previews
//get the parent to do the work
function navNext() {
  logEvent("navNext", componentName, props.previewItem?.FileId);
  emits("nav", 1);
}

function navPrevious() {
  logEvent("navPrevious", componentName, props.previewItem?.FileId);
  emits("nav", -1);
}

//let the parent know that the user is done with preview
function close() {
  logEvent("close", componentName, props.previewItem?.FileId);
  emits("close-modal");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
i {
  font-style: normal;
  color: var(--grey);
}
.name-size-modified {
  max-width: 500px;
  margin: var(--space-double) auto 0;
}
.btn-link svg {
  fill: var(--link-color);
}
.image-wrapper {
  text-align: center;
  flex-grow: 1;
}
.image-wrapper img {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.file-name {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
