<template>
  <h1>{{ tn("h1Heading") }}</h1>
  <div v-if="FLAGS.ENABLE_ALLSTATE_FAMILY === 'true'" class="mb">
    <p class="mb"><svg-icon-component icon="logo-allstate" /></p>
    <p>{{ tn("summaryTextFamily") }}</p>
    <p>{{ tn("summaryTextFamilyP2") }}</p>
    <p>
      <small>{{ tn("notes") }}</small>
    </p>
  </div>
  <div v-else class="border-bottom mb">
    <p class="mb"><svg-icon-component icon="logo-allstate" /></p>
    <p>{{ tn("summaryText") }}</p>
    <p>
      <small>{{ tn("notes") }}</small>
    </p>
  </div>
  <div v-if="subscriptionsToDisplay.length > 1" class="border-top">
    <i18n-t keypath="AllstateUpgradeComponent.chooseKeycodeLine1" tag="p" scope="global">
      <template #onlyOne>
        <strong>{{ tn("onlyOne") }}</strong>
      </template>
    </i18n-t>
    <p class="border-bottom mb-0">{{ tn("chooseKeycodeLine2") }}:</p>
    <ul class="form max-char-65 constrain-90 mb">
      <li
        v-for="sub in subscriptionsToDisplay"
        :id="`keycodeLi_${sub.keyCode}`"
        :key="sub.keyCode"
        class="radio border-bottom mb-0 pb-quarter pt-quarter"
      >
        <input
          :id="`keycodeRadio_${sub.keyCode}`"
          v-model="selectedKeycode"
          type="radio"
          name="keycodeSelector"
          :value="sub.keyCode"
        />
        <label :for="`keycodeRadio_${sub.keyCode}`" class="mr">{{ getSubName(sub) }}</label>
        {{
          tn(sub.remainingDays <= 0 ? "expired" : "expires") +
          formatDateString(sub.expirationDate === undefined ? "" : sub.expirationDate)
        }}
      </li>
    </ul>
  </div>
  <div class="mb-double">
    <button id="btn-buyAllstate" class="btn-primary" :disabled="!selectedKeycode" @click="buyAllstate()">
      {{ tn("btnDetailsAndPricing") }}
    </button>
  </div>
  <h2 v-if="FLAGS.ENABLE_ALLSTATE_FAMILY !== 'true'" class="mb-half">{{ tn("featureComparison") }}</h2>
  <table v-if="displayFeatures() && FLAGS.ENABLE_ALLSTATE_FAMILY !== 'true'" class="feature-compare-table pre-family">
    <tbody>
      <tr>
        <th>&nbsp;</th>
        <th>
          {{ t("Brand.WSAP") }}
          <br />
          <small>{{ tn("premiumOption") }}</small>
        </th>
        <th>
          {{ tn("allstateIdProtectionAddOn") }}
          <br />
          <small>{{ tn("addOnOption") }}</small>
        </th>
      </tr>
      <tr v-for="f in features" :id="`feature_${f.id}`" :key="f.id" class="success">
        <td class="feature">
          <span v-if="f.subFeatures.length > 0" class="btn-link link-color pl-0" @click="toggleDisplayItems(f.id)">
            <span v-show="true" :id="`featureChevron_${f.id}_left`" class="mr-half">
              <svg-icon-component icon="chevron-right" class="icon-xs" />
            </span>
            <span v-show="false" :id="`featureChevron_${f.id}_down`" class="mr-half">
              <svg-icon-component icon="chevron-down" class="icon-xs" />
            </span>
            {{ f.feature }}
          </span>
          <span v-else>
            {{ f.feature }}
          </span>
        </td>
        <td class="text-center">
          <span v-if="f.webrootPremium === 'yes'">
            <svg-icon-component icon="circle-check" class="icon-sm" />
          </span>
          <span v-else>
            {{ f.webrootPremium }}
          </span>
        </td>
        <td class="text-center">
          <span v-if="f.addOn === 'yes'">
            <svg-icon-component icon="circle-check" class="icon-sm" />
          </span>
          <span v-else>
            {{ f.addOn }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { formatDateString } from "@/common/dateFormat";
import { getProductDisplayNameBySku } from "@/common/productsBySku";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { IProductDisplayRequest } from "@/common/productsBySku";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { listFeaturesForComparison } from "./features";
import { KeyCodeSeller } from "@/globalEnums";
import { unifiedApi } from "@/common";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { FLAGS } from "@/define";
import { getUpgradeCartUrl } from "./AllstateHelper";

const componentName = "AllstateUpgradeComponent";

const props = defineProps({
  subscriptions: {
    type: Object as PropType<ISubscription[]>,
    required: false,
    default: null,
  },
});

logEvent("created", componentName);

const selectedKeycode = ref<string>();
const subscriptionsToDisplay = ref<ISubscription[]>([]);
const features = ref(listFeaturesForComparison());

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function buyAllstate() {
  try {
    if (selectedKeycode.value) {
      var url = (await unifiedApi.getWebrootRenewUrl(selectedKeycode.value)).data.redirectUrl;
      if (url) {
        const newUrl = await getUpgradeCartUrl(selectedKeycode.value);
        window.open(`${newUrl}&upgrade_path=allstate`, "_self");
      }
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}

function insertAfter(referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function toggleDisplayItems(id: number) {
  const element = document.getElementById(`subFeatures_${id}`);
  if (element) {
    hideItems(id);
  } else {
    displayItems(id);
  }
}

function displayItems(id: number) {
  const items = features.value.find(f => f.id === id)?.subFeatures;
  if (items) {
    const parent = document.getElementById(`feature_${id}`);
    const child = document.createElement("tr");
    child.id = `subFeatures_${id}`;
    let listItems = "";
    for (const item of items) {
      listItems += `<li>${item}</li>`;
    }
    child.innerHTML = `<td colspan="3" class="relative-indent"><ul class="disc">${listItems}</ul></td>`;
    insertAfter(parent, child);
    toggleChevron(id);
  }
}

function hideItems(id: number) {
  const child = document.getElementById(`subFeatures_${id}`);
  if (child) {
    child.remove();
    toggleChevron(id);
  }
}

function toggleChevron(id: number) {
  const chevronLeft = document.getElementById(`featureChevron_${id}_left`);
  const chevronDown = document.getElementById(`featureChevron_${id}_down`);
  if (chevronLeft?.style && chevronDown?.style) {
    chevronLeft.style.display = chevronLeft.style.display === "" ? "none" : "";
    chevronDown.style.display = chevronDown.style.display === "" ? "none" : "";
  }
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  subscriptionsToDisplay.value = props.subscriptions.filter(
    s =>
      (s.keyCodeSellerType === KeyCodeSeller.Webroot || s.keyCodeSellerType === KeyCodeSeller.BestBuy) &&
      s.remainingDays > 0
  );
  if (subscriptionsToDisplay.value.length === 1) {
    selectedKeycode.value = subscriptionsToDisplay.value[0].keyCode;
  }
});

function getSubName(sub: ISubscription) {
  const request: IProductDisplayRequest = {
    sku: sub.sku,
    webrootKeycode: sub.keyCode,
    isTrial: sub.isTrial,
  };
  return t(getProductDisplayNameBySku(request)).toString();
}

function displayFeatures() {
  return features.value.length > 0;
}

function refreshCachedString() {
  // Hide sub features before refreshing;
  for (const feature of features.value) {
    hideItems(feature.id);
  }
  features.value = listFeaturesForComparison();
}

registerRefreshFunction(refreshCachedString);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
