<template>
  <div v-if="showSpinner" class="credit-cards">
    <LoadingSpinner />
  </div>

  <div v-else class="add-credit-card">
    <AddPaymentMethodComponent
      :form-submit-button-text="submitButtonText"
      @submit="submit()"
      @payment-method-added="zuoraCreditCardFormSuccessHandler"
      @payment-method-error="zuoraCreditCardFormFailureHandler"
    >
      <template #cancelButton>
        <button id="btnCancel" type="button" class="btn-outline-primary cancel" @click="cancel()">
          {{ tn("cancel") }}
        </button>
      </template>
    </AddPaymentMethodComponent>
  </div>
</template>

<script setup lang="ts">
import { PropType, onMounted, onUnmounted, ref, computed } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import AddPaymentMethodComponent from "@/components/shared/AddPaymentMethodComponent.vue";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import { pageState as subscriptionPageState, pageState } from "@/globalEnums";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { RateLimitCreditCardError } from "@/define";
import { IAutoRenewUpdateRequest, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { openTextBrands } from "../Buy/BuyEnums";

const componentName = "AddCreditCardComponent";

const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    default: null,
  },
  creditCardPreviousState: {
    type: String as PropType<pageState>,
    default: null,
  },
  isAutoRenew: {
    type: Boolean,
    default: false,
  },
  isEditPayment: {
    type: Boolean,
    default: false,
  },
  submitBtnText: {
    type: String,
    default: "",
  },
  brand: {
    type: String as PropType<openTextBrands>,
    default: openTextBrands.Carbonite,
  },
});

const emits = defineEmits([
  "payment-method-added",
  "subscription-page-state-change",
  "set-auto-renew",
  "edit-payment",
  "auto-renew-payment-method-added",
  "subscription-page-state-change",
  "newly-added-payment-id",
]);

logEvent("created", componentName);
const notificationsStore = useNotificationsStore();
const showSpinner = ref<boolean>(true);
const buyFlowStore = useBuyFlowStore();
const submitButtonText = computed(() => {
  return props.submitBtnText;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  try {
    logEvent("mounted", componentName);
    showSpinner.value = false;
  } catch (err) {
    if ((err as string) !== RateLimitCreditCardError) {
      //Need to change to logException because this catch was overwriting the one from creditCardFunctions.getCreditCardFormParams
      //where we are putting up the notification instead of redirecting
      logException(err as Error, componentName);
    }
  }
});

onUnmounted(() => {
  logEvent("unmounted", componentName);
});

async function zuoraCreditCardFormSuccessHandler(event) {
  try {
    if (props.isEditPayment) {
      emits("edit-payment", props.sub, event.detail.creditCardId);
      return;
    }
    if (props.isAutoRenew) {
      const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
        allowAutoRenew: true,
        surveyChoice: "",
        zuoraSubscriptionNumber: props.sub.zuoraSubscriptionNumber,
        surveyChoiceCode: null,
        paymentMethodId: event.detail.creditCardId,
      };
      //if it is auto renew and a payment method was added, show success notification on SubscriptionInformation
      await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest);
      emits("set-auto-renew", event.detail.creditCardId);
      emits("auto-renew-payment-method-added", props.sub);
      emits("subscription-page-state-change", subscriptionPageState.default);
      buyFlowStore.showProcessingOrderMessage = false;
      //needs to set default cc if its a carbonite autorenew
      if (props.brand === openTextBrands.Carbonite) {
        await setNewDefaultCC(event.detail.creditCardId);
      }
      return;
    }
    // not sure if this needs to be removed
    if (props.brand === openTextBrands.Carbonite) {
      await setNewDefaultCC(event.detail.creditCardId);
    }
    emits("payment-method-added", props.sub);
    emits("newly-added-payment-id", event.detail.creditCardId);
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

async function zuoraCreditCardFormFailureHandler(error: string) {
  if (error === "AddCreditCardFailed") {
    notificationsStore.clearNotifications();
    notificationsStore.addNotification({ type: error as NotificationType });
  }
}

async function setNewDefaultCC(creditCardId: string) {
  try {
    await unifiedApi.setDefaultCreditCard(creditCardId);
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function submit() {
  logEvent("Submit Zuora Credit Card Form");
  /* eslint-disable */
      //@ts-ignore
      Z.submit();
      /* eslint-enable */
}

function cancel() {
  sessionStorage.removeItem("product_name_for_auto_renew");
  emits("subscription-page-state-change", props.creditCardPreviousState);
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
button {
  margin: var(--space-quarter);
  text-align: center;
}
.btn {
  margin-left: 112px;
}
.btn .spinning-icon {
  display: none;
}
.btn:disabled {
  background-color: #c0cea0;
  cursor: not-allowed;
}
.btn:disabled .spinning-icon {
  display: inline;
}
</style>
