import { IAutoRenewRequest, IAutoRenewResponse } from "@/common/api/unifiedPortal/interfaces";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { openTextBrands } from "@/components/Buy/BuyEnums";

export const useAutoRenewalStore = defineStore("autoRenewal", () => {
  const autoRenewRequest = ref<IAutoRenewRequest>();
  const autoRenewResponse = ref<IAutoRenewResponse>();
  const selectedBrandForAutoRenewal = ref<openTextBrands>(openTextBrands.Carbonite);

  function setAutoRenewRequestParameters(request: IAutoRenewRequest) {
    autoRenewRequest.value = request;
  }
  const getAutoRenewRequestParameters = computed(() => {
    return autoRenewRequest.value;
  });

  function setAutoRenewResponse(result: IAutoRenewResponse) {
    autoRenewResponse.value = result;
  }

  function getAutoRenewResponse() {
    return autoRenewResponse.value;
  }
  const isEditPayment = ref(false);
  const getAutoRenewSuccessRoute = computed(() => {
    let path = "/";
    const queryParams = {};
    if (autoRenewResponse.value || isEditPayment.value) {
      path = "subscriptions";
    }
    return { path: path, query: queryParams };
  });

  //Set the selected brand for auto renewal in subscription page
  function setSelectedBrandForAutoRenewal(brand: openTextBrands) {
    selectedBrandForAutoRenewal.value = brand;
  }

  return {
    getAutoRenewRequestParameters,
    setAutoRenewRequestParameters,
    setAutoRenewResponse,
    getAutoRenewResponse,
    isEditPayment,
    getAutoRenewSuccessRoute,
    setSelectedBrandForAutoRenewal,
    selectedBrandForAutoRenewal,
  };
});
