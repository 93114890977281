import { useUserStore } from "@/stores/user";
import { unifiedApi } from "@/common";
import { IClientException } from "./api/unifiedPortal/interfaces";
import { BUILD_HASH, BUILD_HASH_DISPLAY_LENGTH, FLAGS } from "@/define";

const EVENT_ARRAY = "event_array";
const MAX_EVENTS = 10;
const MAX_EXTRA_LENGTH = 500;
const CLIENT_IDENTIFIER = "VueDawn";

interface eventData {
  ticks: number;
  file: string;
  message: string;
  extra: string;
}

export function logEvent(message: string, file?: string | undefined, value?: unknown) {
  //create event object
  let extra = "";
  if (value instanceof String || typeof value == "string") extra = value as string;
  else if (value instanceof Object) extra = JSON.stringify(value);

  if (extra.length > MAX_EXTRA_LENGTH) {
    const first = extra.slice(0, MAX_EXTRA_LENGTH / 2);
    const last = extra.slice(extra.length - MAX_EXTRA_LENGTH / 2);
    extra = first + " ... " + last;
  }
  const ev: eventData = {
    ticks: new Date().getTime(),
    file: file || "",
    message,
    extra,
  };

  //read events from local storage, if any
  let events: eventData[] = [];
  const eventsString = localStorage.getItem(EVENT_ARRAY);
  if (eventsString) events = JSON.parse(eventsString) as eventData[];

  //add the new event
  events.push(ev);
  if (events.length > MAX_EVENTS) events.shift();

  //save the array
  localStorage.setItem(EVENT_ARRAY, JSON.stringify(events));

  //now log to the console
  if (FLAGS.IS_DEBUG) {
    console.log(`${new Date(ev.ticks).toISOString()} | ${ev.file} | ${ev.message}`);
    if (value) console.log(value);
  }
}

export async function logException(err: Error, extra?: unknown) {
  const userStore = useUserStore();
  const user = userStore.currentUser;

  const errorMessage = new Date().getTime().toString();
  let extraString = "";
  if (extra) extraString = JSON.stringify(extra);

  let stackTrace = "";
  if (err.stack) stackTrace = JSON.stringify(err.stack.split("\n"));

  //create clientException message
  const ce: IClientException = {
    clientIdentifier: `${CLIENT_IDENTIFIER} ${BUILD_HASH.substring(0, BUILD_HASH_DISPLAY_LENGTH)}`,
    errorMessage,
    cause: localStorage.getItem(EVENT_ARRAY) || "",
    stackTrace,
    url: extraString,
    userAgent: "isMobile: " + !localStorage.getItem("isMobile") + " OS: " + localStorage.getItem("getOS"),
    ipAddress: "",
    personId: user?.personId || 0,
    resellerId: user?.subscriberInfo?.managingResellerID || 0,
  };

  if (FLAGS.IS_DEBUG) {
    console.log(`${new Date().toISOString()} | ${ce.personId} | ${ce.resellerId}`);
    console.log(ce.stackTrace);
    console.log(ce.userAgent);
    if (extra) console.log(extra);
  }

  //send it
  await unifiedApi.putClientException(ce);

  //delete the queue of events
  localStorage.removeItem(EVENT_ARRAY);
}
