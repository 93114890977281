<template>
  <article>
    <h3 class="text-grey border-bottom pb-quarter mb-quarter">
      {{ t("BackupComponent.BackupYourDevices") }}
    </h3>
    <p class="mt-half">{{ t("BackupComponent.youHaveUninstalledCdlpSubs") }}</p>
    <ol>
      <li>{{ t("BackupComponent.stepOneCdlp") }}</li>
      <li>
        <i18n-t keypath="BackupComponent.stepTwo" tag="div" scope="global">
          <template #button>
            {{ t("BackupComponent.installNow") }}
          </template>
        </i18n-t>
      </li>
      <li>{{ t("BackupComponent.stepThreeCdlp") }}</li>
    </ol>
    <div class="mb">
      <a id="linkNeedHelp" @click="goToSupportPage()">
        {{ t("BackupComponent.linkFooterNeedHelp") }}
        <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
      </a>
    </div>
    <div class="opposite-ends align-items-center border-radius border-around">
      <div>
        <div class="mb-half">
          <strong>{{ t("Brand.WebrootBackupAndRestore") }}</strong>
        </div>
        <div>{{ t("Common.expires") }} {{ expirationDate() }}</div>
      </div>
      <button id="btnGoToDownloadsFromCdlpOnBackup" type="button" class="btn-primary" @click="goToDownloads()">
        {{ t("BackupComponent.installNow") }}
      </button>
    </div>
  </article>
</template>

<script setup lang="ts">
import router from "@/routes";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { PropType } from "vue";
import { formatDateString } from "@/common/dateFormat";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
});

function goToSupportPage() {
  window.open(getUrl("WEBROOT_SUPPORT_HOME"), "_blank");
}

function goToDownloads() {
  router.push(`/backup/download/${props.device.zuoraSubscriptionNumber}`);
}
function expirationDate() {
  return formatDateString(props.device.expirationDate);
}
</script>
