<template>
  <div id="loadingIndicator" class="loading">
    <h2>
      <svg-icon-component icon="spinner" class="spinning-icon" />
      {{ tn("loading") }}
    </h2>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

/* gives a modal like appearance within a box */
/* we have 3 spinners: shared/Spinner.vue, shared/LoadingSpinner.vue, and shared/LoadingBox.vue */
const componentName = "LoadingSpinnerComponent";

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>

<style scoped lang="css">
.loading {
  width: 170px;
  margin: 150px auto;
}
</style>
