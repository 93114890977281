<template>
  <ul v-if="displayPromoCode" class="form">
    <li class="mb-eighth">
      {{ tn("promoCode") }}
    </li>
    <!--Enter promo code-->
    <li v-if="promoCodeState == promoCodeStates.enter" class="constrain-40">
      <InputError :show="promoCodeError !== null">
        <div class="input-btn-set-md">
          <input id="promoCode-input" v-model="promoCode" type="text" autocomplete="off" />
          <button
            id="apply-promo-btn"
            type="button"
            class="btn-outline-primary"
            :disabled="!promoCode || buyFlowStore.isCalculatingCart"
            @click="addPromoCode"
          >
            <svg-icon v-if="isLoading" icon="spinner" class="spinning-icon" />
            {{ t("CartComponent.apply") }}
          </button>
        </div>
        <template #error>
          <div>
            <span :key="promoCodeError?.name">{{ promoCodeError?.message || t(promoCodeError?.name ?? "") }}</span>
          </div>
        </template>
      </InputError>
    </li>
    <!--Promo code applied-->
    <li
      v-if="promoCodeState == promoCodeStates.applied"
      class="success-border constrain-40 opposite-ends border-around border-radius"
    >
      <div class="success icon-relative-move mr-half">
        <svg-icon icon="checkmark" class="icon" />
      </div>
      <div class="success-text grow">
        <div class="flex-start">
          <span class="nowrap pr-quarter">{{ tn("promoCode") }}</span>
          <strong class="truncate-flex constrain-19">{{ promoCode }}</strong>
        </div>
        <ul>
          <li v-for="item in promoDetails" :id="`promo-detail-${item.type}`" :key="item.type" class="mb-0">
            {{ tn(item.type, { amount: getFormattedValue(item) }) }}
          </li>
        </ul>
      </div>
      <div>
        <button id="remove-promo-btn" class="inline-btn-link success" @click="removePromoCode()">
          <svg-icon icon="delete" class="icon-sm" />
        </button>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
//Components
import InputError from "@/components/shared/InputError.vue";
import SvgIcon from "@/components/shared/SvgIcon/SvgIcon.vue";
//Others
import { computed, ref, onBeforeMount } from "vue";
import { t } from "@/i18n";
import { logEvent, logException } from "@/common/logger";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { IValidationResult } from "@/common/validator";
import { promoCodeStates } from "@/components/Buy/BuyEnums";
import { pageStates, adjustments } from "@/components/Buy/BuyEnums";
import { IPromotionDetail } from "@/common/api/unifiedPortal/interfaces";
import { formatMoney } from "@/components/Buy/commonFn";
import { googleTagCartEvents } from "@/common/googleTagEvents";

const componentName = "PromoCodeComponent";

logEvent("created", componentName);

const buyFlowStore = useBuyFlowStore();
const isLoading = ref<boolean>(false);
const promoCode = ref<string>("");
const promoCodeState = ref<string>(promoCodeStates.enter);
const invalidPromoCode = ref<boolean>(false);

const emits = defineEmits(["remove-promo-code"]);

onBeforeMount(() => {
  if (buyFlowStore.cart?.promotionCode || promoDetails.value?.length) {
    promoCode.value = buyFlowStore.cart?.promotionCode ?? "";
    promoCodeState.value = promoCodeStates.applied;
  }
});

const displayPromoCode = computed(
  () => buyFlowStore.pageState === pageStates.reviewOrder || promoDetails.value?.length > 0
);

const promoCodeError = computed(() => {
  if (invalidPromoCode.value) {
    return { name: "invalidCharacter", message: tn("invalidPromoCode") } as IValidationResult;
  }
  return null;
});

const promoDetails = computed(() => {
  return buyFlowStore.cart?.promoDetail || [];
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function getFormattedValue(promoDetails: IPromotionDetail) {
  if (promoDetails.type === adjustments.PromoCodeAmt) {
    return formatMoney(promoDetails.value);
  }

  //Remove decimal points
  return Math.trunc(promoDetails.value);
}

async function addPromoCode() {
  isLoading.value = true;
  if (buyFlowStore.cart && promoCode.value) {
    buyFlowStore.cart.promotionCode = promoCode.value;
  }
  try {
    invalidPromoCode.value = false;
    await buyFlowStore.updateShoppingCartTotal(false, true, googleTagCartEvents.change);
    isLoading.value = false;
    promoCodeState.value = promoCodeStates.applied;
  } catch (error) {
    if (buyFlowStore.cart) {
      buyFlowStore.cart.promotionCode = "";
    }
    invalidPromoCode.value = true;
    isLoading.value = false;
    logException(error as Error, `${componentName}: addPromoCode`);
  }
}

async function removePromoCode() {
  promoCode.value = "";
  promoCodeState.value = promoCodeStates.enter;
  emits("remove-promo-code");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
