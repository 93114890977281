<template>
  <div v-if="showPaymentInfo()">
    <button type="button" class="inline-btn-link" @click="changePaymentMethod()">
      <svg-icon-component icon="edit" class="icon-sm" />
      {{ paymentInfo() }}
    </button>
    <span v-if="payPalEmail" id="payPalEmail">
      {{ payPalEmail }}
    </span>
  </div>
  <div v-else-if="showPaymentInfoForNonInstalledDevice()">
    {{ paymentInfo() }}
  </div>
  <div v-else-if="showAddPaymentMethod()">
    <button type="button" class="inline-btn-link" @click="addPaymentMethod()">
      {{ t("SubscriptionInformationComponent.addNewPaymentMethod") }}
    </button>
  </div>
  <div v-else>{{ t("SubscriptionInformationComponent.notApplicable") }}</div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { KeyCodeSeller } from "@/globalEnums";
import { SafeDataPurgeDays } from "@/define";
import { subscriptionPageState } from "./SubscriptionsEnums";
import { isWebrootProduct } from "@/common/subscriptionHelper";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { canDisplayPerSubscriptionFeature, perSubscriptionFeatures } from "@/common/featureDisplayAccess";
import { paymentTypes } from "../Buy/BuyEnums";

const componentName = "PaymentMethodColumnComponent";

const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

const emits = defineEmits(["set-current-subscription", "subscription-page-state-change"]);

const canDisplayPaymentMethod = canDisplayPerSubscriptionFeature(
  perSubscriptionFeatures.displayPaymentMethod,
  props.sub.carbLicenseDistributionMethodCode
);

logEvent("created", componentName);

function changePaymentMethod() {
  logEvent("changePaymentMethod");
  emits("set-current-subscription", props.sub);
  emits("subscription-page-state-change", subscriptionPageState.paymentMethodDetails);
}

const payPalEmail = computed(() => {
  return props.sub.paymentInfo?.payPalEmail;
});

function showAddPaymentMethod(): boolean {
  if (props.sub && props.sub.isActiveUntilCancelled && !props.sub.isEvergreenMonthly) {
    return false;
  }

  return (
    props.sub.isEvergreenMonthly &&
    !props.sub.isCancelledEvergreenSubscription &&
    props.sub.remainingDays > 0 &&
    !props.sub.paymentInfo?.cardType &&
    !props.sub.isSafeLite
  );
}

function addPaymentMethod() {
  emits("subscription-page-state-change", subscriptionPageState.addPaymentMethod);
}

function showPaymentInfo(): boolean {
  if (!canDisplayPaymentMethod) {
    return false;
  }

  if (!props.sub) {
    return false;
  } else if (props.sub.isActiveUntilCancelled && !props.sub.isEvergreenMonthly) {
    return false;
  } else if (!props.sub.isAutoRenewEnabled && !props.sub.isEvergreenMonthly) {
    return false;
  } else {
    return (
      !!props.sub.paymentInfo?.cardType &&
      (!props.sub.resellerId || props.sub.isPartnerReferral) &&
      !props.sub.isTrial &&
      ((props.sub.isAutoRenewEnabled && !props.sub.isEvergreenMonthly) ||
        (props.sub.isEvergreenMonthly && !props.sub.isCancelledEvergreenSubscription)) &&
      props.sub.remainingDays > 0 &&
      !props.sub.computer?.dataHasBeenPurged &&
      props.sub.remainingDays > SafeDataPurgeDays.Direct &&
      props.sub.keyCodeSellerType !== KeyCodeSeller.BestBuy &&
      props.sub.keyCodeSellerType !== KeyCodeSeller.Amazon
    );
  }
}

function showPaymentInfoForNonInstalledDevice(): boolean {
  if (!canDisplayPaymentMethod) {
    return false;
  }

  if (props.sub && props.sub.isActiveUntilCancelled && !props.sub.isEvergreenMonthly) {
    return false;
  }

  if (!props.sub.isAutoRenewEnabled && !props.sub.isEvergreenMonthly) {
    return false;
  }

  return (
    !!props.sub.paymentInfo?.cardType &&
    !props.sub.resellerId &&
    !props.sub.isTrial &&
    (!props.sub.computer || props.sub.computer.id === 0) &&
    props.sub.remainingDays > 0 &&
    props.sub.keyCodeSellerType === KeyCodeSeller.Safe &&
    !isWebrootProduct(props.sub) &&
    !props.sub.isSafeLite &&
    canDisplayPaymentMethod
  );
}

function paymentInfo() {
  if (props.sub.paymentInfo?.cardType === paymentTypes.PayPal) {
    return paymentTypes.PayPal;
  }

  return `${props.sub.paymentInfo?.cardType} *${getLastFourOfCC(props.sub.paymentInfo?.cardLastFourDigits as string)}`;
}

function getLastFourOfCC(cardNumber: string): string {
  return cardNumber.substring(cardNumber.length - 4, cardNumber.length);
}
</script>
