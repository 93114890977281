<template>
  <div v-if="buyFlowStore.lastCartItemRemoved" class="background-box opposite-ends align-items-center mb">
    <i18n-t keypath="UndoRemove.text" tag="div" scope="global">
      <template #product>
        {{ t(`Brand.${buyFlowStore.lastCartItemRemoved.sku}`) }}
      </template>
    </i18n-t>
    <div>
      <button id="btnUndo" class="btn-link mb-0 pr" type="button" @click="buyFlowStore.undoRemove()">
        {{ t("UndoRemove.undoBtn") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { t } from "@/i18n";

logEvent("Created", "UndoRemove");

const buyFlowStore = useBuyFlowStore();
</script>
