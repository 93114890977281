import { AxiosResponseHeaders, AxiosResponseTransformer } from "axios";
import { logException } from "./logger";
import JSON5 from "json5";

export const axiosTransformJSON5: AxiosResponseTransformer = (data, headers) => {
  if (isJson(headers)) {
    try {
      return JSON5.parse(data);
    } catch (err) {
      logException(err as Error, data);
      return data;
    }
  }
  return data;
};

function isJson(headers: AxiosResponseHeaders | undefined): boolean {
  if (!headers) return false;
  const content = headers["content-type"] || headers["Content-Type"];
  return content?.indexOf("application/json") !== -1 || content?.indexOf("text/json") !== -1;
}

export function getLanguageUrlParm(lang: string) {
  switch (lang) {
    case "ja":
      return `jp/${lang}`;
    default:
      return `us/${lang}`;
  }
}

export function debounce(fn, delay) {
  let timer;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(fn, delay);
  };
}
