<template>
  <div class="backup-page content">
    <article class="no-grid mb-double">
      <header>
        <h1 class="text-grey border-bottom pb-quarter mb-quarter">
          {{ t("Brand.WebrootBackupAndRestore") }} {{ t("Common.download") }}
        </h1>
      </header>
      <section class="mt">
        <p class="mb">{{ tn("p1") }}</p>
        <p class="mb">{{ tn("p2") }}</p>

        <ol class="mb">
          <li class="mb-half">{{ tn("li1") }}</li>
          <li class="mb-half">
            {{ tn("li2") }}
            <div>
              <button
                id="btnGoToDownloadsFromCdlpOnBackup"
                type="button"
                class="btn-primary mt-half mb-half"
                :disabled="isDownloading"
                @click="download()"
              >
                <spinner :is-spinning="isDownloading" />
                {{ t("BackupComponent.downloadNow") }}
              </button>
            </div>
          </li>
          <li>{{ tn("li3") }}</li>
        </ol>
        <p class="mb">
          {{ tn("p3") }}
        </p>
        <div>
          <a id="linkNeedHelp" target="_blank" @click="goToSupportPage()">
            {{ t("BackupComponent.linkFooterNeedHelp") }}
            <svg-icon-component icon="external-link" class="icon-sm pl-quarter" />
          </a>
        </div>
      </section>
    </article>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { GetSafeClientAndDownload } from "@/common/safeClient";
import { logException } from "@/common/logger";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { useUserStore } from "@/stores/user";
import Spinner from "@/components/shared/Spinner.vue";

const name = "DownloadWebrootTotalProtection";
const route = useRoute();

const userStore = useUserStore();
const isDownloading = ref<boolean>(false);
const zuoraSubNumber = route.params.subNumber;
const installType = route.params.installType as string;
const device = ref(userStore?.userDetails?.devices.find(d => d.zuoraSubscriptionNumber === zuoraSubNumber));

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function goToSupportPage() {
  window.open(getUrl("WEBROOT_SUPPORT_HOME"), "_blank");
}

async function download() {
  try {
    isDownloading.value = true;
    await GetSafeClientAndDownload(
      device.value?.activationCode || "",
      device.value?.deviceId || 0,
      "",
      installType ?? "Install",
      ""
    );
  } catch (error) {
    logException(error as Error);
  } finally {
    isDownloading.value = false;
  }
}
</script>
