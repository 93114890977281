export enum displayDirectiveTypes {
  if = "if",
  show = "show",
  visible = "visible",
}

export enum transitionTypes {
  vfmFade = "vfm-fade",
  vfmSlideDown = "vfm-slide-down",
  vfmSlideUp = "vfm-slide-up",
  vfmSlideRight = "vfm-slide-right",
  vfmSlideLeft = "vfm-slide-left",
}

export enum backgroundTypes {
  interactive = "interactive",
  nonInteractive = "non-interactive",
}

export enum SwipeToCloseTypes {
  none = "none",
  up = "up",
  right = "right",
  down = "down",
  left = "left",
}
