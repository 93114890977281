<template>
  <ModalWrapperComponent
    dialog-name="UpdateEmailAddressComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul class="form">
        <li>
          <label for="email">{{ tn("email") }}</label>
          <InputErrorComponent :show="(isLocalEmailError || isServerEmailError) && emailError !== null">
            <input id="email" v-model.trim="eMail" type="text" autocomplete="off" @keyup="dirtyFlagEmail = true" />
            <template #error>
              <div>
                <span :key="emailError?.name">{{ emailError?.message || t(emailError?.name ?? "") }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <div class="label-wrapper">
            <label for="new-password">{{ tn("password") }}</label>
            <button
              v-if="hidePassword"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="hidePassword = !hidePassword"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="hidePassword = !hidePassword"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="showPasswordIncorrect">
            <input
              id="new-password"
              v-model.trim="password"
              :type="hidePassword ? 'password' : 'text'"
              autocomplete="off"
            />
            <template #error>
              <div>
                <span>
                  {{ tn("incorrectPassword") }}
                </span>
              </div>
            </template>
          </InputErrorComponent>
          <p class="mt passwordStatement">
            {{ tn("passwordStatement") }}
          </p>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link" @click="close()">{{ tn("cancel") }}</button>
      <button id="btnSaveChanges" type="submit" class="btn-primary" :disabled="!isValid || isSaving" @click="save()">
        <spinner :is-spinning="isSaving" />
        {{ tn("save") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { IVerifyEmail } from "@/common/api/unifiedPortal/interfaces";
import { AxiosError } from "axios";
import { useNotificationsStore } from "@/stores/notifications";
import { isValidEmail } from "@/common/validateEmail";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { IValidationResult, reportStringErrors } from "@/common/validator";
import { emailCharactersRegex } from "@/globalRegex";
import { IErrorMessage } from "@/common/api/interfaces";
import { MAX_EMAIL_LENGTH, MIN_EMAIL_LENGTH } from "@/define";

const componentName = "UpdateEmailAddressComponent";

enum errorType {
  none,
  local,
  server,
}

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.email);

const notificationsStore = useNotificationsStore();
const originalEmail = ref<string>(props.email);
const eMail = ref<string>("");
const hidePassword = ref(true);
const password = ref<string>("");
const isSaving = ref<boolean>(false);
const emailServerErrorMessage = ref<string>("");
const showPasswordIncorrect = ref(false);
const siteSettings = useSiteSettingsStore();
const dirtyFlagEmail = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function save() {
  // If the user tries to save the same email just close the modal without doing anything
  if (eMail.value === originalEmail.value) {
    close();
    return;
  }

  isSaving.value = true;
  notificationsStore.clearNotifications();
  try {
    if (isValid.value) {
      const emailUpdateResponse = (await unifiedApi.updateEmail(eMail.value, password.value)).data;
      if (emailUpdateResponse === "") {
        const success: IVerifyEmail = { email: eMail.value, response: "ok" };
        siteSettings.hasPendingEmailVerification = true;
        emits("save-modal", success);
      }
    }
  } catch (error) {
    const err = error as AxiosError;
    const errCode = (err.response?.data as IErrorMessage)?.message;

    if (errCode === "unable_to_use_email") {
      notificationsStore.addNotification({ type: "EmailAlreadyInUse" });
    } else if (errCode === "Invalid_Password") {
      notificationsStore.addNotification({ type: "EmailAddressFormSubmissionError" });
      logEvent("user provided wrong current password", componentName);
      showPasswordIncorrect.value = true;
    } else {
      logEvent("SetEmailAddressFailure", `${componentName}`);
      emits("save-modal", "failure");
      handleApiError(err, true, "SetEmailAddressFailure");
    }
  }
  isSaving.value = false;
}

function close() {
  emits("close-modal");
}

watch(eMail, () => {
  emailServerErrorMessage.value = "";
  notificationsStore.clearNotifications();
});

watch(password, () => {
  showPasswordIncorrect.value = false;
  notificationsStore.clearNotifications();
});

const isLocalEmailError = computed(() => {
  return emailErrorState.value === errorType.local;
});

const isServerEmailError = computed(() => {
  return emailErrorState.value === errorType.server;
});

//priority for server errors over local errors
const emailErrorState = computed(() => {
  if (emailServerErrorMessage.value.length > 0) return errorType.server;
  if (isValidEmail(eMail.value).some(e => !e.passed)) return errorType.local;
  return errorType.none;
});

const emailInvalidCharacterError = computed(() => {
  return reportStringErrors(eMail.value, tn("email"), MIN_EMAIL_LENGTH, MAX_EMAIL_LENGTH, emailCharactersRegex);
});

//figure out which error to display
const emailError = computed(() => {
  if (dirtyFlagEmail.value === false) return null;
  if (isServerEmailError.value && emailServerErrorMessage.value !== "")
    return { name: emailServerErrorMessage.value } as IValidationResult;
  const errs = isValidEmail(eMail.value).filter(e => !e.passed);
  if (errs.length > 0) return errs[0];
  if (emailInvalidCharacterError.value.length > 0) {
    return { name: "invalidCharacter", message: emailInvalidCharacterError.value } as IValidationResult;
  }
  return null;
});

const isValid = computed(() => {
  return (
    eMail.value.length > 0 &&
    password.value.length > 0 &&
    emailError.value === null &&
    eMail.value !== originalEmail.value
  );
});

function beforeOpen() {
  password.value = "";
  eMail.value = props.email;
  showPasswordIncorrect.value = false;
  notificationsStore.clearNotifications();
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.passwordStatement {
  font-style: italic;
}
</style>
