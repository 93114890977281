import { WEBROOT_KEYCODE_LENGTH, WEBROOT_KEYCODE_PHRASE_LENGTH } from "@/define";
import { webrootLicenseCategories } from "@/common/webrootProductName";

export function formatKeycode(keycode: string, licenseCategoryName = ""): string {
  if (licenseCategoryName === webrootLicenseCategories.WPCO) return formatVendorKey(keycode);
  if (keycode.length != WEBROOT_KEYCODE_LENGTH) return "";

  let formatted = keycode.substring(0, WEBROOT_KEYCODE_PHRASE_LENGTH);

  for (
    let index = WEBROOT_KEYCODE_PHRASE_LENGTH;
    index < WEBROOT_KEYCODE_LENGTH;
    index += WEBROOT_KEYCODE_PHRASE_LENGTH
  )
    formatted += `-${keycode.substring(index, index + WEBROOT_KEYCODE_PHRASE_LENGTH)}`;

  return formatted;
}

function formatVendorKey(keycode: string): string {
  return keycode.replace("-", "");
}
