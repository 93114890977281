<template>
  <file-access-component v-if="showFileAccess" :computer-id="computerId" :computer-name="computerName" :path="path" />
  <div v-else></div>
</template>

<script setup lang="ts">
import { onBeforeMount, computed, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import FileAccessComponent from "@/components/FileAccess/FileAccess.vue";
import { FLAGS } from "@/define";
import { logEvent } from "@/common/logger";
import { useUserStore } from "@/stores/user";
import { UserType } from "@/globalEnums";

const componentName = "FileAccessCSSBRoute";

const router = useRouter();
const computerId = ref<string>(router.currentRoute.value.query.computerName as string);
const computerName = ref<string>("");
const path = ref<string>("");

logEvent("starting", componentName, router.currentRoute.value.query);

const showFileAccess = computed(() => {
  const userStore = useUserStore();
  return FLAGS.SHOW_CSSB_RFA_COMPONENT === "true" && userStore.userType == UserType.smb;
});

onBeforeMount(() => {
  if (!showFileAccess.value) router.replace("/home");
});

//watch for changes in the route parameters and pass them on

watchEffect(() => {
  computerId.value = router.currentRoute.value.query.computerName as string;
});
</script>
