<template>
  <span>{{ t(`NotificationsComponent.${props.notification.type}`, { plan: notificationMessage }) }}</span>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";

const componentName = "MovePaymentMethodMRToARNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    required: true,
  },
});

logEvent("created", componentName);

const notificationMessage = ref<string>(props.notification?.params?.plan ?? "");
</script>
