<template>
  <i18n-t keypath="NotificationsComponent.DoubleAllstatePremiumError" tag="span" class="pl-quarter" scope="global">
    <template #contactSupport>
      <button class="inline-btn-link" type="button" @click="goToUrl()">
        {{ t("Common.contactSupport") }}
      </button>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { t } from "@/i18n";
import { getLanguageUrlParm } from "@/common/helper";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const languageUrlParm = computed(() => getLanguageUrlParm(userStore.selectedLanguage || "en"));

function goToUrl() {
  window.open(`https://www.webroot.com/${languageUrlParm.value}/support/support-home`, "_blank");
}
</script>
