<template>
  <ul class="progress-bar">
    <!-- the two classes are current or completed -->
    <li v-if="showEmailStepDot" id="emailStep" :class="emailStep">
      <span class="dot"></span>
      <span class="text">
        <svg-icon-component icon="cart" />
        {{ t("Common.cart") }}
      </span>
    </li>
    <li id="paymentStep" :class="paymentStep">
      <span class="dot"></span>
      <span class="text">
        <svg-icon-component icon="payment" />
        {{ t("Common.payment") }}
      </span>
    </li>
    <li id="reviewStep" :class="reviewStep">
      <span class="dot"></span>
      <span class="text">
        <svg-icon-component icon="review" />
        {{ t("Common.reviewOrder") }}
      </span>
    </li>
    <li id="successStep" :class="successStep">
      <span class="dot"></span>
      <span class="text">
        <svg-icon-component icon="checkmark" />
        {{ t("Common.success") }}
      </span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { computed, onBeforeMount, ref, watch } from "vue";
import { logEvent } from "@/common/logger";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { openTextBrands, pageStates } from "@/components/Buy/BuyEnums";
import { useUserStore } from "@/stores/user";

const componentName = "BuyProgressbarComponent";
const buyFlowStore = useBuyFlowStore();
const userStore = useUserStore();
const emailStep = ref<string>("");
const paymentStep = ref<string>("");
const reviewStep = ref<string>("");
const successStep = ref<string>("");
logEvent("created", componentName);

/* progressbarState is loaded with the first li having a class of 'current' and none of the other li's having any class.
 When you reach the second li, the class on the 1st li changes to 'completed' and the second li gets the 'current' class.
 When you reach the third li, it gets the 'current' class, and the first and second li's get the class 'completed' */
function progressbarState() {
  if (buyFlowStore.pageState === pageStates.email) {
    emailStep.value = "current";
    paymentStep.value = "";
    reviewStep.value = "";
    successStep.value = "";
  }
  if (buyFlowStore.pageState === pageStates.payment) {
    emailStep.value = "completed";
    paymentStep.value = "current";
    reviewStep.value = "";
    successStep.value = "";
  }
  if (buyFlowStore.pageState === pageStates.reviewOrder) {
    emailStep.value = "completed";
    paymentStep.value = "completed";
    reviewStep.value = "current";
    successStep.value = "";
  }
  if (buyFlowStore.pageState === pageStates.orderConfirmation) {
    emailStep.value = "completed";
    reviewStep.value = "completed";
    paymentStep.value = "completed";
    successStep.value = "current";
  }
}

const showEmailStepDot = computed(() => {
  const hasComputerOrUserGuid = buyFlowStore.cart?.userGuid || buyFlowStore.cart?.items.some(l => l.computerId > 0);
  if (buyFlowStore.licenseKeyInfo) {
    return (
      !buyFlowStore.licenseKeyInfo.hasAccount ||
      (buyFlowStore.licenseKeyInfo.candyRackItems && buyFlowStore.licenseKeyInfo.candyRackItems.length > 0)
    );
  }
  return (
    buyFlowStore.currentBrand === openTextBrands.Webroot || (!userStore.isEstablishedAccount && !hasComputerOrUserGuid)
  );
});

onBeforeMount(() => {
  /* setting pageState so progressbar updates correctly because component lives on other pages */
  progressbarState();
});

//Watch the buyflow store pageState for changes
watch(
  () => buyFlowStore.triggerStateChange,
  () => {
    progressbarState();
  }
);
</script>
