<template>
  <div class="content">
    <div v-if="loading">
      <LoadingSpinner />
    </div>
    <div v-else>
      <NotificationsComponent context="InvoiceDetailsComponent" />
      <section v-if="pageState === invoicePageState.addPaymentMethod">
        <h1>
          <button type="button" class="inline-btn-link" @click="setPageState(invoicePageState.invoiceDetails)">
            <svg-icon-component icon="chevron-left" />
          </button>
          {{ t("SubscriptionInformationComponent.addPaymentMethod") }}
        </h1>
        <div v-if="isWebroot">
          <WebrootProductsInfo :subscriptions="props.subscriptions" :outstanding-invoices="selectedInvoices" />
        </div>
        <div v-else>
          <i18n-t keypath="SubscriptionInformationComponent.addPaymentHeader" tag="p" class="mt" scope="global">
            <template #all>
              <strong>{{ t("SubscriptionInformationComponent.all") }}</strong>
            </template>
          </i18n-t>
          <SafeProductsInfoComponent :subscriptions="props.subscriptions" />
        </div>
        <PaymentTypeSelector v-if="isWebroot" @change-payment-type="setPaymentType" />
        <div v-if="currentPaymentType == paymentTypes.PayPal" class="mt">
          <button id="btnPaypalCheckout" type="button" class="btn-shell mb-double" @click="initiatePayPalRequest()">
            <svg-icon-component icon="paypal-button" class="paypal-svg-175" />
          </button>

          <button type="button" class="btn-link block mb-double" @click="setPageState(invoicePageState.invoiceDetails)">
            {{ t("Common.cancel") }}
          </button>
        </div>

        <div v-else-if="currentPaymentType == paymentTypes.CreditCard">
          <AddCreditCardComponent
            :brand="props.brand"
            :credit-card-previous-state="invoicePageState.invoiceDetails"
            @subscription-page-state-change="setPageState"
            @newly-added-payment-id="addPaymentMethodHandler"
          />
        </div>
      </section>
      <section v-else-if="pageState === invoicePageState.invoiceDetails">
        <h1>
          <button type="button" class="inline-btn-link" @click="cancel()">
            <svg-icon-component icon="chevron-left" />
          </button>
          {{ tn("invoiceDetails") }}
        </h1>

        <ul>
          <li v-if="filteredPaymentMethods.length > 0" class="mb constrain-65">
            <SelectPayment
              :payment-methods="filteredPaymentMethods"
              :show-check-out-button="false"
              :show-details="true"
              :brand="props.brand"
              :is-outstanding-invoice="true"
              :subscription="props.subscriptions[0]"
              :show-pay-pal-address="false"
              :selected-payment-method-id="selectedCreditCard?.id || ''"
              @add-card="addNewCreditCard"
              @set-display-card-details-state="setPageState(invoicePageState.invoiceDetails)"
              @newly-selected-payment="setSelectedPaymentMethod"
            />
          </li>
        </ul>

        <div class="table-to-list-wrapper">
          <table v-if="outstandingInvoices.length > 0">
            <thead>
              <tr>
                <th @click="toggleAllInvoices"><input type="checkbox" :checked="isAllSelected" /></th>
                <th class="sortable" :class="sortDirection.invoiceNumber" @click="sortInvoices('invoiceNumber')">
                  {{ tn("invoiceIdCol") }}
                </th>
                <th class="sortable" :class="sortDirection.invoiceDate" @click="sortInvoices('invoiceDate')">
                  {{ tn("dateCol") }}
                </th>
                <th class="sortable" :class="sortDirection.packageName" @click="sortInvoices('packageName')">
                  {{ tn("subscriptionCol") }}
                </th>
                <th class="sortable" :class="sortDirection.balance" @click="sortInvoices('balance')">
                  {{ tn("outstandingBalanceCol") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in outstandingInvoices" :key="o.invoiceNumber" @click="toggleInvoice(o)">
                <td :data-th="tn('invoiceIdCol')">
                  <input type="checkbox" :checked="isSelected(o)" />
                </td>
                <td :data-th="tn('dateCol')">{{ o.invoiceNumber }}</td>
                <td :data-th="tn('subscriptionCol')">{{ formatDate(o.invoiceDate) }}</td>
                <td :data-th="tn('outstandingBalanceCol')">
                  {{ o.packageName }}
                  <span v-if="o.isWebroot">{{ tn("keycode") }} {{ o.targetName }}</span>
                  <span v-else class="computer-name">
                    {{ tn("computer") }}
                    <span>
                      {{ o.targetName }}
                    </span>
                  </span>
                </td>
                <td :data-th="tn('productCol')">{{ formatMoney(o.balance) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul>
          <li v-if="totalUpdate" class="mb">
            {{ tn("invoiceTotal") }}
            <strong>{{ formatMoney(payOutstandingInvoicesRequest.amount ?? 0) }}</strong>
          </li>

          <i18n-t keypath="InvoiceDetailsComponent.continueStatement" tag="li" class="mb" scope="global">
            <template #termsAndConditions>
              <button
                id="btnTermsAndConditions"
                type="button"
                class="inline-btn-link"
                target="_blank"
                @click="goToUrl(getBrandUrl(urlTypes.TermsAndConditions))"
              >
                {{ tn("termsAndConditions", { openTextBrand: t(`Brand.${props.brand.toLowerCase()}`) }) }}
              </button>
            </template>
            <template #privacyPolicy>
              <button
                id="btnPrivacyPolicy"
                type="button"
                class="inline-btn-link"
                target="_blank"
                @click="goToUrl(getBrandUrl(urlTypes.PrivacyPolicy))"
              >
                {{ tn("privacyPolicy") }}
              </button>
            </template>
          </i18n-t>
        </ul>

        <div class="mb">
          <button class="btn-link" @click="cancel()">{{ tn("cancel") }}</button>
          <button
            class="btn-primary"
            :disabled="(payOutstandingInvoicesRequest.amount ?? 0) <= 0 || !hasSelectedPaymentMethod || isRenewing"
            @click="completeRenewNow()"
          >
            <spinner :is-spinning="isRenewing" />
            {{ tn("renewNow") }}
          </button>
        </div>
      </section>
    </div>
  </div>

  <LoadingBoxComponent
    :show-dialog="buyFlowStore.showProcessingOrderMessage"
    :message="tn('changePaymentProcessingMessage')"
  />
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import {
  IPaymentMethod,
  IOutstandingInvoices,
  IPayOutstandingInvoicesRequest,
  IInvoicePaymentData,
  IOutstandingInvoice,
  ISubscription,
} from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { useNotificationsStore } from "@/stores/notifications";
import { formatDateString } from "@/common/dateFormat";
import { carboniteProducts } from "@/common/productsBySku";
import { pageState as invoicePageState, payPalCheckOutPage } from "@/globalEnums";
import { pageView, outstandingInvoicePayEvent } from "@/common/googleTagEvents";
import Spinner from "@/components/shared/Spinner.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import * as creditCardFunctions from "@/components/CreditCards/CreditCards";
import AddCreditCardComponent from "@/components/CreditCards/AddCreditCard.vue";
import SafeProductsInfoComponent from "@/components/Subscriptions/SafeProductsInfo.vue";
import WebrootProductsInfo from "@/components/Subscriptions/WebrootProductsInfo.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { useBuyFlowStore } from "@/stores/buyFlow";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import { openTextBrands, paymentTypes } from "@/components/Buy/BuyEnums";
import { getUrl } from "@/common/getUrl";
import SelectPayment from "@/components/Buy/Payment/SelectPayment.vue";
import { onBeforeMount } from "vue";
import PaymentTypeSelector from "@/components/Buy/Payment/PaymentTypeSelector.vue";
import { createPayPalAgreementToken } from "../Buy/Payment/Payment";
import { usePayPalStore } from "@/stores/payPal";

const componentName = "InvoiceDetailsComponent";

const props = defineProps({
  subscriptions: {
    type: Array as PropType<ISubscription[]>,
    required: true,
  },
  paymentMethods: {
    type: Array as PropType<IPaymentMethod[]>,
    required: true,
  },
  originFromBackupTab: {
    type: Boolean,
  },
  brand: {
    type: String as PropType<openTextBrands>,
    default: openTextBrands.Carbonite,
  },
});

const emits = defineEmits([
  "success",
  "invoice-details-canceled",
  "subscription-page-state-change",
  "updated-payment-methods",
]);

logEvent("created", componentName);

const enum urlTypes {
  PrivacyPolicy = "PrivacyPolicy",
  TermsAndConditions = "TermsAndConditions",
}

const notificationsStore = useNotificationsStore();
const buyFlowStore = useBuyFlowStore();
const payPalStore = usePayPalStore();
const isRenewing = ref<boolean>(false);
const selectedCreditCard = ref<IPaymentMethod>();
const outstandingInvoices = ref<IOutstandingInvoice[]>([]);
const loading = ref<boolean>(true);
const payOutstandingInvoicesRequest = ref<IPayOutstandingInvoicesRequest>({});
const totalUpdate = ref<boolean>(false);
const selectedInvoices = ref<IOutstandingInvoice[]>([]);
const pageState = ref<invoicePageState>(invoicePageState.invoiceDetails);
const paymentMethodsList = ref<IPaymentMethod[]>([]);
const hasSelectedPaymentMethod = ref<boolean>(false);
const sortColumn = ref<string>("");
const sortDirection = ref<Record<string, unknown>>({
  invoiceDate: "descend",
  invoiceNumber: "",
  packageName: "",
  balance: "",
});
const currentPaymentType = ref<paymentTypes>(paymentTypes.CreditCard);

const isWebroot = computed(() => {
  return props.brand === openTextBrands.Webroot;
});

const filteredPaymentMethods = computed(() => {
  if (isWebroot.value) {
    return paymentMethodsList.value;
  }
  return paymentMethodsList.value.filter(l => l.cardType != paymentTypes.PayPal);
});

onBeforeMount(() => {
  //make a copy of the credit card data so we can update it
  for (const cc of props.paymentMethods) {
    const ccc = { ...cc };
    ccc.address = { ...cc.address };
    paymentMethodsList.value.push(ccc);
  }

  if (paymentMethodsList.value.length > 0) {
    if (isWebroot.value) {
      //Set the selected Payment for Webroot
      selectedCreditCard.value = paymentMethodsList.value[0];
      payOutstandingInvoicesRequest.value.paymentMethodId = selectedCreditCard.value?.id;
      recalculateTotalOutstanding();
    } else {
      setDefaultPaymentMethod();
    }

    hasSelectedPaymentMethod.value = true;
  } else {
    setPageState(invoicePageState.addPaymentMethod);
  }

  initializeInvoiceDetails();

  //Check if this is the return from PayPal because we need to set the current subscription
  const request = sessionStorage.getItem("PayPal_OutstandingInvoice_Request");
  if (request) {
    const parsedRequest = JSON.parse(request) as IPayOutstandingInvoicesRequest;
    payOutstandingInvoicesRequest.value = parsedRequest;
    payOutstandingInvoicesRequest.value.paymentMethodId = payPalStore.payPalPaymentMethodId;
    //Set the selected payment method
    selectedCreditCard.value = props.paymentMethods.find(l => l.id === payPalStore.payPalPaymentMethodId);

    payOutstandingInvoicesRequest.value?.invoiceData?.forEach(item => {
      const invoice = outstandingInvoices.value.find(l => l.invoiceNumber === item.invoiceNumber);
      if (invoice) {
        selectedInvoices.value.push(invoice);
      }
    });

    sessionStorage.removeItem("PayPal_OutstandingInvoice_Request");
  }

  pageView("invoiceDetails", "payment_form");
  //cSpell:ignore outstandinginvoice, paymentmethod
  //if user has outstanding invoice then url /subscriptions?action=outstandinginvoice&paymentMethod=add will navigate directly to invoice details section
  if (
    useRouter().currentRoute.value.query.action?.toString().toLowerCase() === "outstandinginvoice" &&
    useRouter().currentRoute.value.query.paymentMethod?.toString().toLowerCase() === "add"
  ) {
    setPageState(invoicePageState.addPaymentMethod);
  }
});

const isAllSelected = computed(() => {
  return selectedInvoices.value.length === outstandingInvoices.value?.length;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function isSelected(o: IOutstandingInvoice) {
  return selectedInvoices.value.filter(q => q.invoiceNumber == o.invoiceNumber).length === 1;
}

function setDefaultPaymentMethod(paymentMethodId = "") {
  if (paymentMethodId) {
    selectedCreditCard.value = paymentMethodsList.value.find(l => l.id === paymentMethodId);
  } else {
    selectedCreditCard.value = paymentMethodsList.value.find(card => card.defaultPaymentMethod === true);
  }

  payOutstandingInvoicesRequest.value.paymentMethodId = selectedCreditCard.value?.id;
  recalculateTotalOutstanding();
}

function toggleAllInvoices() {
  if (outstandingInvoices.value) {
    selectedInvoices.value = selectedInvoices.value.length === 0 ? outstandingInvoices.value : [];
  }
  recalculateTotalOutstanding();
}

function toggleInvoice(o: IOutstandingInvoice) {
  //get the current count
  const oldCount = selectedInvoices.value.length;
  //filter out the one we want to toggle
  selectedInvoices.value = selectedInvoices.value.filter(i => i.invoiceNumber !== o.invoiceNumber);
  //if it wasn't on the list, then we're adding it
  if (oldCount === selectedInvoices.value.length) selectedInvoices.value.push(o);
  recalculateTotalOutstanding();
}

//Set the invoice data for the pay off request
function setInvoiceData() {
  payOutstandingInvoicesRequest.value.invoiceData = [];
  for (const invoice of selectedInvoices.value) {
    const invoiceData: IInvoicePaymentData = {
      amount: invoice.balance,
      invoiceId: invoice.invoiceId,
      invoiceNumber: invoice.invoiceNumber,
    };

    payOutstandingInvoicesRequest.value?.invoiceData?.push(invoiceData);
  }
}

async function completeRenewNow() {
  isRenewing.value = true;
  notificationsStore.clearNotifications();
  setInvoiceData();

  try {
    const response = (await unifiedApi.payOutstandingInvoices(payOutstandingInvoicesRequest.value)).data;

    if (response && response.success) {
      pushGoogleTagData(selectedInvoices.value);
      //if success make that credit card as default, if that is not default yet
      const isDefaultPaymentMethod = paymentMethodsList.value.find(
        x => x.id == payOutstandingInvoicesRequest.value.paymentMethodId
      )?.defaultPaymentMethod;
      if (!isDefaultPaymentMethod && payOutstandingInvoicesRequest.value.paymentMethodId && !isWebroot.value) {
        const cards = await creditCardFunctions.setDefaultCreditCard(
          paymentMethodsList.value,
          payOutstandingInvoicesRequest.value.paymentMethodId
        );
        paymentMethodsList.value = cards ?? [];
      }
      emits("success");
    } else {
      if (response && response.errorMessage && response.errorMessage.indexOf("Insufficient Funds") !== -1) {
        notificationsStore.addNotification({ type: "CreditCardInsufficientFunds" });
      } else {
        notificationsStore.addNotification({ type: "RenewFailureMessage", dismissible: false });
        logEvent("RenewFailureMessage");
      }
    }
  } catch (err) {
    handleApiError(err as AxiosError);
  }

  isRenewing.value = false;
}

function pushGoogleTagData(invoiceData: IOutstandingInvoice[]) {
  invoiceData.forEach(item => {
    outstandingInvoicePayEvent(item);
  });
}

function recalculateTotalOutstanding() {
  totalUpdate.value = false;
  let totalAmount = 0;
  for (const invoice of selectedInvoices.value) {
    totalAmount += invoice.balance;
  }

  if (payOutstandingInvoicesRequest.value) payOutstandingInvoicesRequest.value.amount = totalAmount;
  totalUpdate.value = true;
}

function cancel() {
  if (props.originFromBackupTab) {
    emits("invoice-details-canceled");
    return;
  }

  emits("subscription-page-state-change", invoicePageState.default);
}

function addNewCreditCard() {
  notificationsStore.clearNotifications();
  pageState.value = invoicePageState.addPaymentMethod;
  logEvent("Page state change", pageState.value);
}

function formatDate(date: string) {
  return formatDateString(date);
}
function formatMoney(amount: number) {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount);
}

async function initializeInvoiceDetails() {
  try {
    outstandingInvoices.value = processInvoiceData(
      (await unifiedApi.getOutstandingInvoices()).data ?? []
    ).outstandingInvoices;

    outstandingInvoices.value = outstandingInvoices.value.filter(l => l.isWebroot === isWebroot.value);
    toggleAllInvoices();
    sortInvoices("invoiceDate");
    loading.value = false;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function processInvoiceData(outstandingInvoices: IOutstandingInvoices) {
  for (const invoice of outstandingInvoices.outstandingInvoices) {
    const invoiceId = invoice.invoiceId;
    let targetName = "";
    let packageName = "";
    //get all matching invoices from subscription Data and update package name and computer name
    for (const sub of props.subscriptions) {
      if (sub.hasOutstandingInvoices && sub.outstandingInvoice?.invoiceId === invoiceId) {
        const isSafeSKU = carboniteProducts.find(product => product.sku === sub.sku);
        if (isSafeSKU) {
          //for carbonite show computer description
          targetName =
            targetName === "" ? `${sub.computer?.description}` : `${targetName} , ${sub.computer?.description}`;
          packageName = packageName === "" ? `${sub?.packageName}` : `${packageName} , ${sub?.packageName}`;
          invoice.isWebroot = false;
        } else {
          //for webroot show keycode
          targetName = targetName === "" ? `${sub.keyCode}` : `${targetName} , ${sub.keyCode}`;
          packageName = packageName === "" ? `${sub.productDisplayName}` : `${packageName} , ${sub.productDisplayName}`;
          invoice.isWebroot = true;
        }
      }
    }
    invoice.packageName = packageName;
    invoice.targetName = targetName;
  }
  return outstandingInvoices;
}

async function addPaymentMethodHandler(paymentMethodId: string) {
  buyFlowStore.showProcessingOrderMessage = false;
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: "AddCreditCardSuccess" });
  //move to invoice details page and load new credit cards from server
  setPaymentMethodsAfterSuccess(paymentMethodId);
  pageState.value = invoicePageState.invoiceDetails;
  logEvent("Page state change", pageState.value);
}

async function setPageState(state: string) {
  notificationsStore.clearNotifications();
  pageState.value = invoicePageState[state];
  logEvent("Page state change", pageState.value);
}

async function setPaymentMethodsAfterSuccess(paymentMethodId: string) {
  try {
    paymentMethodsList.value = (await unifiedApi.getUserPaymentMethods()).data.paymentMethods;
    //After getting the list latest Credit Card from API,update the parent pages with latest credit card and set default credit card also.
    setDefaultPaymentMethod(paymentMethodId);
    hasSelectedPaymentMethod.value = true;
    emits("updated-payment-methods", paymentMethodsList.value);
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function sortInvoices(field: string) {
  //turn off sorting indicator on old column
  if (sortColumn.value !== field) sortDirection.value[sortColumn.value] = "";

  //pick a direction for the sort
  if (sortDirection.value[field] == "ascend") sortDirection.value[field] = "descend";
  else if (sortDirection.value[field] == "descend") sortDirection.value[field] = "ascend";
  else sortDirection.value[field] = "ascend";

  const order = sortDirection.value[field] === "ascend" ? -1 : 1;

  if (outstandingInvoices.value)
    outstandingInvoices.value = outstandingInvoices.value.sort((a: IOutstandingInvoice, b: IOutstandingInvoice) => {
      if (a[field] > b[field]) return order;
      if (a[field] < b[field]) return -order;
      return 0;
    });
  sortColumn.value = field;
}

function getBrandUrl(type: urlTypes) {
  let url = "";
  if (type === urlTypes.TermsAndConditions) {
    if (isWebroot.value) {
      url = getUrl("BUY_TERMS_WEBROOT_URL");
    } else {
      url = getUrl("BUY_TERMS_URL");
    }
  } else {
    url = getUrl("BUY_PRIVACY_URL");
  }

  return url;
}

function goToUrl(url: string): void {
  window.open(url, "_blank");
}

function setPaymentType(paymentType: paymentTypes) {
  currentPaymentType.value = paymentType;
}

function setSelectedPaymentMethod(paymentMethodId: string) {
  const paymentMethod = props.paymentMethods.find(l => l.id === paymentMethodId);
  if (paymentMethod) {
    payOutstandingInvoicesRequest.value.paymentMethodId = paymentMethodId;
    selectedCreditCard.value = paymentMethod;
  }
}

async function initiatePayPalRequest() {
  buyFlowStore.showProcessingOrderMessage = true;
  logEvent("Initiating paypal transaction", componentName);
  try {
    const paypalAgreementTokenResponse = await createPayPalAgreementToken();
    if (paypalAgreementTokenResponse) {
      logEvent("Got the paypal agreement token, redirecting to paypal approval url", componentName);
      //Make sure the most recent data is on the pay off request
      setInvoiceData();

      //Set the pay off object to session storage so we can set the page back up when the user returns
      sessionStorage.setItem("PayPal_OutstandingInvoice_Request", JSON.stringify(payOutstandingInvoicesRequest.value));

      //set paypal checkout from page in since the redirect to paypal doesn't persist data in pinia store
      sessionStorage.setItem("PayPal_CheckOutPage", payPalCheckOutPage.OutstandingInvoice);

      //set paypal token id since the redirect to paypal doesn't persist data in pinia store
      sessionStorage.setItem("PayPal_token_id", paypalAgreementTokenResponse.tokenId);
      window.location.href = paypalAgreementTokenResponse.approvalUrl;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  } finally {
    buyFlowStore.showProcessingOrderMessage = false;
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

@media (min-width: 600px) {
  .select-css {
    width: 50%;
  }
}

.computer-name {
  max-width: 200px;
  display: flex;
}

.computer-name span {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
