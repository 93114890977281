<template>
  <ModalWrapperComponent
    :dialog-name="componentName"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <span id="title">{{ tn("header") }}</span>
    </template>

    <template #content>
      <p id="p-1-annual">{{ tn("p1Annual", { expirationDate: expirationDate() }) }}</p>
      <p id="p-2-annual">{{ tn("p2Annual", { day: expirationDay }) }}</p>
    </template>
    <template #footer>
      <div class="opposite-ends-desktop">
        <div>
          <button id="btnNo" type="button" class="btn-link" @click="openKb()">
            <svg-icon-component icon="external-link" />
            {{ tn("btnManageAutoRenew") }}
          </button>
        </div>
        <div>
          <button id="btnNo" type="button" class="btn-outline-primary mr" @click="accept(false)">
            {{ tn("btnNo") }}
          </button>
          <button id="btnYes" type="submit" class="btn-primary" @click="accept(true)">
            {{ tn("btnYes") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { concatOrdinalNumber } from "@/common/dateFormat";
import { useUserStore } from "@/stores/user";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { formatDateString } from "@/common/dateFormat";

const componentName = "BuyAutomaticRenewDialogComponent";
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["close-modal", "continue-purchase"]);
const buyFlowStore = useBuyFlowStore();
const userStore = useUserStore();
const isMonthly = ref<boolean>(false);

const expirationDay = computed(() =>
  userStore.selectedLanguage === "en" ? concatOrdinalNumber(new Date().getDate()) : new Date().getDate()
);
// Add criteria here when implementing monthly
onBeforeMount(() => {
  const cartItems = buyFlowStore.cart?.items;
  if (cartItems) {
    cartItems.forEach(item => {
      var months = buyFlowStore.getItemSubscription(item.sku, item.ratePlanId)?.months;
      if (months && months < 12) {
        isMonthly.value = true;
        return;
      }
    });
  }
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function beforeOpen() {
  logEvent("created", componentName);
}

function accept(setAutoRenew: boolean) {
  logEvent("Accepted AR", componentName);
  buyFlowStore.setAutoRenew(setAutoRenew);
  emits("continue-purchase");
}
function openKb() {
  window.open("https://support.carbonite.com/articles/Personal-Mac-Windows-Deactivating-Automatic-Renewal", "_blank");
}

function close() {
  emits("close-modal");
}

function expirationDate() {
  if (buyFlowStore.cart && buyFlowStore.cart.items.length > 0) {
    // The expirationDate returned by Hoth is the ServiceEndDate which represents the last day of the subscription
    const expiryDate = new Date(buyFlowStore.cart?.items[0].expirationDate);
    return formatDateString(expiryDate.toString() || "");
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
