<template>
  <p class="mb">
    <strong>{{ tn("keycodes") }}</strong>
  </p>
  <ul :class="enableScrollKeycodes()">
    <li v-for="(n, i) in webrootSubscriptions" :id="`webrootSubscriptionsInfo${i}`" :key="n.keycode">
      <div v-for="keycode in splitStringByComma(n.keycode)" :key="keycode" class="licenses">
        <div class="plan-detail">
          {{ getLicenseDescription(n.licenseCategoryName) }}
        </div>
        <div class="keycode-detail">
          <strong class="mr-half">{{ formatKeycode(keycode, n.licenseCategoryName) }}</strong>
          <button
            :id="`copy${keycode}`"
            class="inline-btn-link"
            @click="copy(formatKeycode(keycode, n.licenseCategoryName))"
          >
            {{ t("Common.copy") }}
          </button>
        </div>
        <div class="keycode-expiration">
          <small v-if="isKeycodeExpired(n, true)" class="danger-text">{{ t("DownloadsComponent.Expired") }}</small>
          <small v-else-if="!isKeycodeInactive(n.licenseStatusDescription)">
            ({{ tn("expires") }} {{ getFormattedDate(n.expirationDate) }})
          </small>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo, IWebrootSubscriptions } from "@/common/api/unifiedPortal/interfaces";
import { formatDateString } from "@/common/dateFormat";
import { getWebrootLicenseDescription } from "@/common/webrootProductName";
import { formatKeycode } from "@/common/formatKeycode";
import { isKeycodeExpired } from "@/components/Downloads/isKeycodeExpired";
import { productList } from "@/common/productList";

const componentName = "KeycodesComponent";

const props = defineProps({
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  productCode: {
    type: String,
    required: true,
    default: "",
  },
});

logEvent("created", componentName);

const webrootSubscriptions = ref<IWebrootSubscriptions[]>([]);

const licenseCategorySupported = productList().find(x => x.code === props.productCode)?.licenseCategories;
webrootSubscriptions.value =
  props.keycodes?.webrootSubscriptions.filter(
    item =>
      licenseCategorySupported?.includes(item.licenseCategoryName) &&
      (!isKeycodeExpired(item) || isKeycodeInactive(item.licenseStatusDescription))
  ) || [];

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function getFormattedDate(date: string): string {
  return formatDateString(date);
}

function getLicenseDescription(license: string): string | undefined {
  return getWebrootLicenseDescription(license);
}

function copy(keycodeText) {
  navigator.clipboard.writeText(keycodeText);
}

function splitStringByComma(license: string) {
  return license.split(",");
}

function isKeycodeInactive(status: string): boolean {
  return status === "Inactive";
}

function enableScrollKeycodes(): string {
  if (webrootSubscriptions.value) {
    if (webrootSubscriptions.value.length > 1) {
      return "scroll-Keycodes";
    }

    const keycodes = webrootSubscriptions.value.filter(c => c.keycode?.includes(","));
    if (keycodes.length >= 1) {
      return "scroll-Keycodes";
    }
  }
  return "";
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
