<template>
  <div v-if="showSpinner" class="login-page">
    <LoadingSpinnerComponent />
  </div>
  <div v-else class="login-page">
    <NotificationsComponent context="CreateAccountTabLoginComponent" />
    <div class="verify-email content">
      <section v-if="pageState === emailVerificationPageState.success" class="success">
        <header>
          <h1>{{ tn("emailVerifiedH1") }}</h1>
        </header>
        <p>{{ tn("successP1") }}</p>
        <button id="btnGoToMyAccount" class="btn-primary mt mb" @click="goToMyAccount()">
          {{ t("Common.goToMyAccount") }}
        </button>
      </section>
      <section v-if="pageState === emailVerificationPageState.expired" class="expired">
        <header>
          <h1>{{ tn("verificationFailedH1") }}</h1>
        </header>
        <i18n-t keypath="EmailVerification.expiredP1" tag="p" class="mt" scope="global">
          <template #expirationTime>
            <span class="danger-text">{{ tn("expirationTime") }}</span>
          </template>
        </i18n-t>
        <i18n-t keypath="EmailVerification.expiredP2" tag="p" class="mt" scope="global">
          <template #sendNewEmail>
            <strong>{{ tn("sendNewEmail") }}</strong>
          </template>
        </i18n-t>
        <button class="btn-primary mt" :disabled="disableSendNewEmailButton" @click="sendNewEmail">
          <span v-if="disableSendNewEmailButton">{{ tn("emailSent") }}</span>
          <span v-else>{{ tn("sendNewEmail") }}</span>
        </button>
        <i18n-t keypath="EmailVerification.needHelp" tag="p" class="mt mb" scope="global">
          <template #contactSupport>
            <button class="inline-btn-link" @click="goToSupportPage">{{ t("Common.contactSupport") }}</button>
          </template>
        </i18n-t>
      </section>
      <section v-if="pageState === emailVerificationPageState.failure" class="failure">
        <header>
          <h1>{{ tn("verificationFailedH1") }}</h1>
        </header>
        <p>{{ tn("failureP1") }}</p>
        <i18n-t keypath="EmailVerification.needHelp" tag="p" class="mt mb" scope="global">
          <template #contactSupport>
            <button class="inline-btn-link" @click="goToSupportPage">{{ t("Common.contactSupport") }}</button>
          </template>
        </i18n-t>
        <button class="btn-outline-primary mb" @click="goToMyAccount">{{ tn("backToLogin") }}</button>
      </section>
      <section v-if="pageState === emailVerificationPageState.serverError" class="serverError">
        <header>
          <h1>{{ tn("serverErrorH1") }}</h1>
        </header>
        <p>{{ tn("serverErrorP1") }}</p>
        <i18n-t keypath="EmailVerification.needHelp" tag="p" class="mt mb" scope="global">
          <template #contactSupport>
            <button class="inline-btn-link" @click="goToSupportPage">{{ t("Common.contactSupport") }}</button>
          </template>
        </i18n-t>
        <button class="btn-outline-primary mb" @click="goToMyAccount">{{ tn("backToLogin") }}</button>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { HTTP_STATUS_SERVER_ERROR } from "@/define";
import { unifiedApi } from "@/common";
import { AxiosError } from "axios";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { getLandingPage } from "@/common/landingPage";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import LoadingSpinnerComponent from "@/components/shared/LoadingSpinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { IErrorMessage } from "@/common/api/interfaces";
import { getUrl } from "@/common/getUrl";

const componentName = "EmailVerification";

enum emailVerificationPageState {
  success,
  expired,
  failure,
  serverError,
}

const pageState = ref<emailVerificationPageState>(emailVerificationPageState.failure);
const showSpinner = ref<boolean>(true);
const disableSendNewEmailButton = ref<boolean>(false);

const router = useRouter();
const route = useRoute();

const verificationCode = computed(() => {
  return route.query?.verificationCode ? route.query?.verificationCode?.toString() : "";
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onMounted(async () => {
  logEvent("created", componentName);
  if (route.query.verificationCode) {
    logEvent(`Token Received: ${verificationCode.value}`, `${componentName}-onMounted`);

    try {
      await unifiedApi.verifyEmail(verificationCode.value);
      pageState.value = emailVerificationPageState.success;
      logEvent(`Email verified`, `${componentName}-onMounted`);
    } catch (error) {
      logEvent(`Resulted in not OK ${error}`, `${componentName}-onMounted`);

      const e = error as AxiosError;
      const message = (e.response?.data as IErrorMessage)?.message;
      if (e.response?.status == undefined || e.response?.status >= HTTP_STATUS_SERVER_ERROR) {
        pageState.value = emailVerificationPageState.serverError;
      } else if (message === "expiration_time_is_expired") {
        pageState.value = emailVerificationPageState.expired;
      } else if (message === "email_already_validated") {
        pageState.value = emailVerificationPageState.success;
      } else {
        pageState.value = emailVerificationPageState.failure;
      }
    }
  }

  showSpinner.value = false;
});

async function goToMyAccount() {
  logEvent(`Clicked on Go To My Account button`, `${componentName}/goToMyAccount`);
  await reloadSubscriberInfo();
  const nextUrl = await getLandingPage();
  router.push(nextUrl);
}

async function sendNewEmail() {
  try {
    logEvent(`Clicked on Send New Email button`, `${componentName}/sendNewEmail`);
    await unifiedApi.sendNewVerificationEmail(verificationCode.value);
    logEvent(`Email sent`, `${componentName}/sendNewEmail`);
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
  disableSendNewEmailButton.value = true;
}

function goToSupportPage() {
  window.open(getUrl("VITE_APP_CARBONITE_SUPPORT_SITE"), "_blank");
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";
.login-page {
  background-color: white;
  border: none;
}

@media (min-width: 600px) {
  .verify-email {
    width: 800px;
  }
  .login-page {
    padding: var(--space-half) var(--space-double);
  }
}
</style>
