<template>
  <ModalWrapperComponent
    dialog-name="AutoLogoutComponent"
    :show-dialog="props.showDialog"
    @close-modal="continueSession"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <li v-html="t('AutoLogoutComponent.sessionExpiry', { timer: countDownTimer })"></li>
        <li>{{ tn("ctnSession") }}</li>
      </ul>
    </template>
    <template #footer>
      <button id="btnClose" type="button" class="btn-link mr" @click="close()">{{ tn("no") }}</button>
      <button id="btnContinue" type="button" class="btn-primary" @click="continueSession()">
        {{ tn("yes") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { computed, watch, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { AUTO_LOGOUT_INTERVAL_SEC, MSEC_IN_SECOND } from "@/define";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";

const componentName = "AutoLogoutComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props);
const activateDialog = computed(() => props.showDialog);
const countDownTimer = ref<number>(AUTO_LOGOUT_INTERVAL_SEC);
function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
const autoLogOutIntervalID = ref<number>(0);

watch(activateDialog, () => {
  if (activateDialog.value === true) {
    autoLogoutUser(true);
  }
});

function autoLogoutUser(logout: boolean) {
  if (logout) {
    countDownTimer.value = AUTO_LOGOUT_INTERVAL_SEC;
    autoLogOutIntervalID.value = window.setInterval(() => {
      if (countDownTimer.value == 0) {
        emits("close-modal");
        clearInterval(autoLogOutIntervalID.value);
      } else {
        countDownTimer.value--;
      }
    }, MSEC_IN_SECOND);
  } else {
    clearInterval(autoLogOutIntervalID.value);
  }
}

async function continueSession() {
  autoLogoutUser(false);
  emits("save-modal");
}

function close() {
  emits("close-modal");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
