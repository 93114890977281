<template>
  <div>
    <ol class="form">
      <li>
        {{ t(`DownloadProductComponent.SAFEMOBILEStepOne`) }}
        <div class="mt flex-start">
          <button type="button" class="inline-btn-link mr" @click="goToStore(iOS)">
            <img src="@/assets/img/app-store.png" alt="Apple App Store" />
          </button>
          <button type="button" class="inline-btn-link" @click="goToStore(android)">
            <img src="@/assets/img/play-store.png" alt="Google Play Store" />
          </button>
        </div>
      </li>
      <li>
        {{ t(`DownloadProductComponent.SAFEMOBILEStepTwo`) }}
      </li>
    </ol>
  </div>
</template>
<script setup lang="ts">
import { t } from "@/i18n";
import { productList, iOS, android } from "@/common/productList";

const productCode = "SAFEMOBILE";

function goToStore(familyName: string) {
  const currentProduct = productList().find(p => p.code == productCode);
  if (currentProduct) {
    const linkToStore = currentProduct.targetSystem.find(t => t.familyName === familyName)?.link;
    if (linkToStore) {
      window.open(linkToStore, "_blank");
    }
  }
}
</script>
