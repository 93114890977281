import { unifiedApi } from "@/common";
import { IUnauthenticatedSubscription } from "@/common/api/unifiedPortal/interfaces";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useUnauthenticatedBuyFlowStore = defineStore("unauthenticatedBuyFlow", () => {
  const subscription = ref<IUnauthenticatedSubscription>();

  async function populateSubscription(deviceId: number, bubbleUpError = false) {
    try {
      subscription.value = (await unifiedApi.getSubscriptionForUnauthorizedFlow(deviceId)).data;
    } catch (err) {
      //Set a blank object if we get an error
      //This is needed to keep existing functionality that was working when the Hoth error
      //were being suppressed
      subscription.value = {} as IUnauthenticatedSubscription;

      if (bubbleUpError) {
        // leave decision how to handle error to the caller
        throw err;
      }
    }
  }

  return {
    subscription,
    populateSubscription,
  };
});
