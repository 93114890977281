<template>
  <NotificationsComponent context="AllstateAlreadySignedUpComponent" />
  <h1>{{ tn("h1Heading") }}</h1>
  <div class="flex-start-lg">
    <div class="mr mb">
      <div><svg-icon-component icon="logo-allstate" /></div>
      <h2 v-if="justSet" class="mb">{{ tn("subtitle") }}</h2>
      <p>{{ tn("summaryText") }}</p>
      <div v-if="props.forceShowManageBtn || displayManageButton" class="mb">
        <button id="goToAllstateBtn" class="btn-primary" @click="goToAllstate()">{{ tn("goToIDP") }}</button>
      </div>
    </div>

    <div
      v-if="canDisplayGlobalFeature(globalFeatures.allStateUpgrade) && displayUpgrade && !inUnderMaintenance"
      class="border-around-info border-radius"
    >
      <p class="mb-half">
        <strong>{{ t(`Common.extendYourProtection`) }}</strong>
      </p>
      <p class="mb">{{ tn(`subIncludesIdentityProtection`) }}</p>
      <button id="goToAllstateBtn" class="btn-link left-link" @click="goToUpgradeOptions()">
        {{ t("Common.viewDetails") }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onBeforeMount, onMounted, PropType, computed, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { IAllstateResponse } from "@/common/api/unifiedPortal/interfaces";
import { getUrl } from "@/common/getUrl";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { FLAGS } from "@/define";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import {
  dismissAllstateUpgradeNotification,
  showAllstateBanner,
  hasActivePremiumFamilySubscriptions,
  getUpgradeCartUrl,
} from "@/components/Allstate/AllstateHelper";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";
import { redirectToOutstandingInvoice } from "../Buy/BuyHelper";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useUserStore } from "@/stores/user";

const componentName = "AllstateAlreadySignedUpComponent";
const subscriptionsStore = useSubscriptionsStore();
const userStore = useUserStore();

const props = defineProps({
  subscriptions: {
    type: Object as PropType<IAllstateResponse[]>,
    required: false,
    default: null,
  },
  webrootSubscriptionName: {
    type: String,
    required: true,
    default: "",
  },
  webrootSubscriptionSku: {
    type: String,
    required: true,
    default: "",
  },
  isExpired: {
    type: Boolean,
    default: false,
  },
  keycode: {
    type: String,
    required: true,
    default: "",
  },
  justSet: {
    type: Boolean,
    default: false,
  },
  forceShowManageBtn: {
    type: Boolean,
    default: false,
  },
});

logEvent("created", componentName);
const displayManageButton = computed(() => !props.isExpired);
const hasActivePremiumFamily = ref<boolean>(false);

const displayUpgrade = computed(() => !hasActivePremiumFamily.value && FLAGS.DISPLAY_ALLSTATE_UPGRADE === "true");

const inUnderMaintenance = computed(() => {
  return userStore.currentUser?.subscriberInfo?.isUnderMaintenance || false;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function goToAllstate() {
  dismissAllstateUpgradeNotification();
  window.open(getUrl("ALLSTATE_MANAGE_URL"), "_blank");
}

async function goToUpgradeOptions() {
  try {
    if (props.subscriptions && props.keycode) {
      const sub = subscriptionsStore.subscriptions.find(l => l.keyCode === props.keycode);
      if (sub && sub.hasOutstandingInvoices && sub.outstandingInvoice) {
        redirectToOutstandingInvoice();
        return;
      }
    }
    if (props.keycode) {
      window.open(await getUpgradeCartUrl(props.keycode ?? ""), "_self");
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  hasActivePremiumFamily.value = await hasActivePremiumFamilySubscriptions();
});

onMounted(async () => {
  showAllstateBanner();
});
</script>
