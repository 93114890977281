import { IPhoneCountryCodes, IPhoneCodeDisplayNameConfig } from "@/common/api/unifiedPortal/interfaces";

const phoneCodeDisplayNameConfig: IPhoneCodeDisplayNameConfig = {
  config: [{ countryName: "JPN", newDisplayName: "Japan (日本) +81" }],
};
export function transformPhoneCodeDisplayNames(lan: string | null, codes: IPhoneCountryCodes[]): IPhoneCountryCodes[] {
  if (codes.length > 0) {
    const configs = phoneCodeDisplayNameConfig.config;
    configs.forEach(c => {
      const code = codes.find(v => v.name === c.countryName);
      if (code) {
        code.displayName = c.newDisplayName;
      }
    });
  }
  return codes;
}
