import { createI18n, LocaleMessageDictionary, VueMessageType } from "vue-i18n";

export interface ILanguage {
  name: string;
  filename: string;
  messages?: unknown;
}

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  useScope: "global",
  warnHtmlMessage: false,
});

import en from "@/assets/lang/en";
import ja from "@/assets/lang/ja";
import { logEvent } from "@/common/logger";

const loadedLanguages: { [name: string]: ILanguage } = {
  en: { name: "en", filename: "/lang/en.ts", messages: en },
  ja: { name: "ja", filename: "/lang/ja.ts", messages: ja },
};
// load embedded english manually
i18n.global.setLocaleMessage(
  loadedLanguages["en"].name,
  loadedLanguages["en"].messages as LocaleMessageDictionary<VueMessageType>
);

export function loadLanguage(l: string) {
  const lang = loadedLanguages[l];

  if (lang.messages) {
    if (i18n.global.locale.value !== lang.name) {
      i18n.global.setLocaleMessage(
        loadedLanguages[l].name,
        loadedLanguages[l].messages as LocaleMessageDictionary<VueMessageType>
      );
      i18n.global.locale.value = l;
    }
  }
}

export const languages: string[] = Object.keys(loadedLanguages);
export default i18n;

export function t(v: string, params?: Record<string, unknown>) {
  if (v && /^\S*.\S*$/.test(v)) {
    try {
      if (params) return i18n.global.t(v, params);
      return i18n.global.t(v);
    } catch (err) {
      logEvent(`${v} throws`, "i18n.index", err);
      return "";
    }
  } else {
    logEvent(`${v} is invalid`, "i18n.index", params);
    return "";
  }
}
