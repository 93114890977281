<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <i18n-t :keypath="parentComponentName + '.' + productCode + 'StepOne'" tag="p" scope="global">
        <template #copyLink>
          <button class="inline-btn-link" target="_blank" @click="copyCurrentURL()">
            {{ t(`${parentComponentName}.copyLink`) }}
          </button>
        </template>
      </i18n-t>
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
      <SelectorComponent :product-code="productCode" />
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepThree`) }}</p>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";
import { copyCurrentURL } from "@/common/copyCurrentURL";

const productCode = "LPPM";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

logEvent("created", componentName, props);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
