import { unifiedApi } from "@/common/index";
import { IInstallRequest } from "@/common/api/unifiedPortal/interfaces";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { getOsFamily, isWindows, isMac } from "@/common/browserDetect";
import { logEvent } from "@/common/logger";
import { handleApiError } from "@/common/handleApiError";
import { isSafari } from "@/common/browserDetect";
import { AxiosError } from "axios";

export async function GetSafeClient(
  activationCode: string,
  deviceId: number,
  offerCode: string,
  installType: string,
  keyCode: string
): Promise<string> {
  try {
    const userStore = useUserStore();

    const osSupport = checkSafeOSSupport();
    if (!osSupport) {
      return "UnsupportedOS";
    }

    if (userStore.currentUser) {
      const request: IInstallRequest = {
        activationCode: activationCode,
        operatingSystem: getOsFamily(),
        deviceId: deviceId,
        offerCode: offerCode,
        installType: installType,
        keyCodeForSafeLite: keyCode,
      };

      const response = (await unifiedApi.getSafeClientURL(request)).data;
      return response;
    }
    return "UserNotLoggedIn";
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, "SafeClient");
    handleApiError(error as AxiosError);
    return error as string;
  }
}

export async function GetSafeClientAndDownload(
  activationCode: string,
  deviceId: number,
  offerCode: string,
  installType: string,
  keyCode: string
): Promise<string> {
  try {
    const protocol = "http";
    const response = await GetSafeClient(activationCode, deviceId, offerCode, installType, keyCode);
    if (response.substring(0, protocol.length) === protocol) {
      if (isSafari()) {
        downloadClientWithRedirect(response);
      } else {
        downloadWithAnchor(response);
      }
    }
  } catch (error) {
    const notificationsStore = useNotificationsStore();
    if (error === "UnsupportedOS") {
      notificationsStore.addNotification({ type: "UnsupportedOS" });
    } else {
      notificationsStore.addNotification({ type: "DownloadSafeFailed" });
    }
    return "Error";
  }
  return "Ok";
}

export function downloadClient(url: string) {
  // Hack to let browser handle the client download without issues with popup blocker.
  // Extracted from UP downloadClientService.js
  const hiddenIFrameID = "hiddenDownloader";
  let iframe: HTMLIFrameElement = document.getElementById(hiddenIFrameID) as HTMLIFrameElement;
  if (!iframe) {
    iframe = document.createElement("iframe");
    iframe.id = hiddenIFrameID;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }
  iframe.src = url;
}

export function downloadClientWithRedirect(url: string) {
  // added due to automation
  const hiddenInputID = "hiddenDownloader";
  let hiddenInput: HTMLInputElement = document.getElementById(hiddenInputID) as HTMLInputElement;
  if (!hiddenInput) {
    hiddenInput = document.createElement("input");
    hiddenInput.id = hiddenInputID;
    hiddenInput.type = "hidden";
    hiddenInput.style.display = "none";
    document.body.appendChild(hiddenInput);
  }
  hiddenInput.value = url;
  window.location.href = url;
}

function downloadWithAnchor(url: string) {
  // added due to automation
  const hiddenAnchorID = "hiddenDownloader";
  let hiddenAnchor: HTMLAnchorElement = document.getElementById(hiddenAnchorID) as HTMLAnchorElement;
  if (!hiddenAnchor) {
    hiddenAnchor = document.createElement("a");
    hiddenAnchor.id = hiddenAnchorID;
    document.body.appendChild(hiddenAnchor);
  }
  hiddenAnchor.href = url;
  hiddenAnchor.click();
}

export function checkSafeOSSupport(): boolean {
  return isWindows() || isMac();
}
