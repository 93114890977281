<template>
  <div>
    <ul class="productDetails mt" :class="expirationStatusCSS">
      <li>
        <i18n-t :keypath="expirationMessageToDisplay" tag="span" scope="global">
          <template #productName>
            {{ t(productName) }}
          </template>
          <template v-if="displayTrialProductName" #trial>
            {{ t("CardComputerComponent.trial") }}
          </template>
          <template #expiration>
            <span :class="expirationDateCSS">{{ expirationDate() }}</span>
          </template>
        </i18n-t>
        <span v-if="props.actions.displaySetAutoRenewLink">
          |
          <button
            :id="`btnSignUpAutoRenew${props.tagId}`"
            class="inline-btn-link"
            @click="toggleAutoRenewOptionModal('click')"
          >
            {{ t("CardComputerComponent.signUpToRenewAutomatically") }}
          </button>
        </span>
      </li>
      <li>
        <i18n-t
          v-if="displayBackupWillBeLost"
          keypath="CardComputerComponent.backupWillBeLost"
          tag="span"
          scope="global"
        >
          <template #days>
            <strong>{{ purgeDays }} {{ t("CardComputerComponent.days") }}</strong>
          </template>
        </i18n-t>
      </li>
      <li v-if="props.actions.displayResellerEmail">
        <div class="mt">
          <i18n-t
            :id="`linkResellerEmail${props.tagId}`"
            keypath="CardComputerComponent.resellerEmail"
            tag="span"
            scope="global"
          >
            <template #email>
              <a id="linkMailToResellerEmail" :href="'mailto:' + props.resellerEmail">{{ props.resellerEmail }}</a>
            </template>
            <template #action>
              <span v-if="displayBuyButtonForTrial">{{ t("CardComputerComponent.buy") }}</span>
              <span v-else>{{ t("CardComputerComponent.renew") }}</span>
            </template>
          </i18n-t>
        </div>
      </li>
      <li v-else>
        <button
          v-if="
            props.actions.displayRenewNowButton &&
            canDisplayPerSubscriptionFeature(
              perSubscriptionFeatures.renew,
              props.subscription.carbLicenseDistributionMethodCode
            )
          "
          :id="`renewNow${props.tagId}`"
          class="btn-primary renew-button"
          @click="buy(true)"
        >
          {{ t("CardComputerComponent.renewNow") }}
        </button>
        <button
          v-if="props.actions.displayBuyNowButton"
          :id="`buyNow${props.tagId}`"
          class="btn-primary renew-button"
          @click="buy(false)"
        >
          {{ t("CardComputerComponent.buyNow") }}
        </button>
      </li>
    </ul>
    <AutoRenewOptionComponent
      :show-dialog="showAutoRenewOptionModal"
      :subscription="props.subscription"
      @close-modal="toggleAutoRenewOptionModal"
      @save-modal="signUpAutoRenew"
    />
  </div>
</template>
<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { useNotificationsStore } from "@/stores/notifications";
import { MSEC_IN_DAY, SafeDataPurgeDays } from "@/define";
import { formatDateString } from "@/common/dateFormat";
import { getProductDisplayNameBySku, IProductDisplayRequest } from "@/common/productsBySku";
import { ICardComputerConfigActions } from "./CardComputerConfig";
import { customerType, expirationState, termType } from "./CardComputerEnums";
import AutoRenewOptionComponent from "@/components/shared/dialogs/AutoRenewOption/AutoRenewOption.vue";
import { alertText, dangerBoldText, dangerText } from "./CardComputerConstants";
import { canDisplayPerSubscriptionFeature, perSubscriptionFeatures } from "@/common/featureDisplayAccess";

const name = "ProductDetailsComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  resellerEmail: {
    type: String,
    required: false,
    default: null,
  },
  currentExpirationState: {
    type: Number as PropType<expirationState>,
    required: false,
    default: null,
  },
  currentCustomerType: {
    type: Number as PropType<customerType>,
    required: false,
    default: null,
  },
  currentTermType: {
    type: Number as PropType<termType>,
    required: false,
    default: null,
  },
  tagId: {
    type: String,
    required: false,
    default: null,
  },
  actions: {
    type: Object as PropType<ICardComputerConfigActions>,
    required: false,
    default: null,
  },
  subscription: {
    type: Object as PropType<ISubscription>,
    required: false,
    default: null,
  },
});

const emits = defineEmits([
  "buy-now",
  "move-payment-method-mr-to-ar",
  "auto-renew-updated",
  "change-payment-method",
  "set-current-subscription",
]);

const notificationsStore = useNotificationsStore();

const expirationMessageToDisplay = ref<string>("validUntilExpirationMessage");
const displayTrialProductName = ref<boolean>(false);
const displayBackupWillBeLost = ref<boolean>(false);
const displayBuyButtonForTrial = ref<boolean>(false);
const expirationStatusCSS = ref<string>("");
const expirationDateCSS = ref<string>("");
const purgeDays = ref<string>("");
const showAutoRenewOptionModal = ref<boolean>(false);

setExpirationDisplay();

function expirationDate() {
  return formatDateString(props.device.expirationDate);
}

function toggleAutoRenewOptionModal(type: string | null) {
  if (!props.subscription) {
    logEvent("toggleAutoRenewOptionModal", name, `no action because subscription is ${props.subscription} via ${type}`);
    return;
  }
  type = type || "modal";
  logEvent("toggleAutoRenewOptionModal", name, `${showAutoRenewOptionModal.value ? "Hiding" : "Showing"} via ${type}`);
  showAutoRenewOptionModal.value = !showAutoRenewOptionModal.value;
}

const productName = computed(() => {
  const request: IProductDisplayRequest = {
    sku: props.device.sku,
    webrootKeycode: props.device.webrootKeycode,
    isTrial: props.device.isTrial,
  };

  return getProductDisplayNameBySku(request);
});

//display
function setExpirationDisplay() {
  displayTrialProductName.value = props.currentTermType === termType.trial;
  displayBuyButtonForTrial.value = props.currentTermType === termType.trial;
  displayBackupWillBeLost.value =
    props.currentExpirationState === expirationState.expired && !props.device.autoRenewInfo;
  expirationMessageToDisplay.value = `CardComputerComponent.${props.actions?.expirationMessageType}`;

  purgeDays.value = displayBackupWillBeLost.value ? getRemainingDaysToPurge() : "";

  expirationStatusCSS.value =
    props.currentExpirationState === expirationState.expired
      ? dangerText
      : props.currentExpirationState === expirationState.isExpiring
        ? alertText
        : "";
  expirationDateCSS.value = props.currentExpirationState === expirationState.isExpiring ? dangerBoldText : "";
  logEvent("setExpirationDisplay", name, {
    TrialProductName: displayTrialProductName.value,
    BuyButtonForTrial: displayBuyButtonForTrial.value,
    BackupWillBeLost: displayBackupWillBeLost.value,
    ExpirationMessage: expirationMessageToDisplay.value,
    PurgeDays: purgeDays.value,
    ExpirationStatusCSS: expirationStatusCSS.value,
    ExpirationDateCSS: expirationDateCSS.value,
  });
}

function getRemainingDaysToPurge(): string {
  const maxDays =
    (props.currentTermType === termType.trial
      ? SafeDataPurgeDays.Trials
      : props.currentCustomerType === customerType.indirect
        ? SafeDataPurgeDays.Reseller
        : SafeDataPurgeDays.Direct) * -1;

  const todayDateInDays = new Date().getTime() / MSEC_IN_DAY;
  const expirationDateInDays = new Date(props.device.expirationDate).getTime() / MSEC_IN_DAY;
  logEvent("ExpirationDateInDays", name, (expirationDateInDays + maxDays - todayDateInDays).toFixed(0));
  return (expirationDateInDays + maxDays - todayDateInDays).toFixed(0);
}

// Actions
function buy(isRenew: boolean) {
  emits("buy-now", isRenew);
  logEvent("buy-now Event", name, "emitted");
}

async function signUpAutoRenew(res: string) {
  if (!props.subscription) {
    return;
  }

  if (res === "OK") {
    if (!props.subscription.isAutoRenewEnabled) {
      emits("move-payment-method-mr-to-ar", props.subscription);
      logEvent("move-payment-method-mr-to-ar", name, "Emitted");
    } else {
      logEvent("ManageAutoRenew modal is saved", name, res);
      notificationsStore.addNotification({
        type: "AutoRenewTurnOnSuccess",
        params: { carbonitePlan: props.subscription.productDisplayName },
      });
      emits("auto-renew-updated", props.subscription);
    }
  } else if (res === "CHANGE_PAYMENT_METHOD") {
    emits("change-payment-method");
    logEvent("change-payment-method", name, "Emitted");
  } else if (res === "FAILED") {
    emits("set-current-subscription");
    logEvent("set-current-subscription", name, "Emitted");
  }

  toggleAutoRenewOptionModal("signUpAutoRenew");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";

ul {
  color: var(--grey);
}
ul .renew-button {
  margin-top: var(--space-half);
}

ul .danger-text {
  color: var(--danger);
}

ul .danger-boldText {
  color: var(--danger);
  font-weight: bold;
}

ul .alert-text {
  color: var(--text-color);
}
@media (min-width: 960px) {
  ul {
    margin-left: 80px;
  }
}
</style>
