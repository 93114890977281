<template>
  <article class="constrain-90 m-auto mt-double mb-200">
    <h1 class="mb">{{ tn("header") }}</h1>
    <i18n-t keypath="FlatRateExpiredLicense.p1" tag="p" scope="global" class="mb mobile-text">
      <template #currentSku>
        {{ t(`Brand.${currentSku}`) }}
      </template>
    </i18n-t>
    <p class="mb mobile-text">{{ tn("p2") }}</p>

    <p class="mb">
      <button type="button" class="btn-primary mobile-text" @click="renew()">{{ tn("btnRenew") }}</button>
    </p>

    <i18n-t keypath="FlatRateExpiredLicense.p3" tag="p" scope="global" class="mb mobile-text">
      <template #link>
        <button type="button" class="inline-btn-link mobile-text" @click="exploreOffers()">
          {{ tn("exploreOffers") }}
        </button>
      </template>
    </i18n-t>
  </article>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { getWebrootInterstitialLinkUnauthenticated } from "@/components/Buy/BuyHelper";
import { URLS } from "@/define";

const componentName = "FlatRateExpiredLicense";
const buyFlowStore = useBuyFlowStore();
const currentSku = ref("");

onBeforeMount(() => {
  currentSku.value = buyFlowStore.licenseKeyInfo?.sku || "";
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function renew() {
  window.open(getWebrootInterstitialLinkUnauthenticated(), "_self");
}

function exploreOffers() {
  window.open(`${URLS.WEBROOT_BUY_URL}#see-pricing-options`, "_self");
}
</script>
