export const carboniteProductMapper: Ii18Mapper[] = [
  { sku: "PersonalLite", tBlock: "Brand", tName: "PersonalLite" },
  { sku: "PersonalBasic", tBlock: "Brand", tName: "PersonalBasic" },
  { sku: "PersonalPlus", tBlock: "Brand", tName: "PersonalPlus" },
  { sku: "PersonalPrime", tBlock: "Brand", tName: "PersonalPrime" },
  { sku: "CarboniteHomePlus", tBlock: "Brand", tName: "CarboniteHomePlus" },
  { sku: "CarboniteHomePremier", tBlock: "Brand", tName: "CarboniteHomePremier" },
];

export function getCarboniteProductTName(sku: string): string {
  const result = carboniteProductMapper.find(n => n.sku === sku);
  return result?.tName ?? sku;
}

interface Ii18Mapper {
  sku: string;
  tBlock: string;
  tName: string;
}
