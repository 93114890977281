import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { getUrl } from "./getUrl";

let cpLoginUrl = getUrl("CP_LOGIN");
export function getCustomerPortalRedirectURL(routeName: string | null | undefined): string {
  if (routeName === "create_account")
    return `${cpLoginUrl}?dest=vdca&returnUrl=${encodeURIComponent(window.location.href)}`;

  return `${cpLoginUrl}?returnUrl=${encodeURIComponent(window.location.href)}`;
}

export function getRedirectToLoginURL(returnUrl: string) {
  return `${cpLoginUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
}

export function getRedirectToLoginURLWithEmail(returnUrl: string, email: string) {
  return `${cpLoginUrl}?returnUrl=${encodeURIComponent(returnUrl)}&email=${encodeURIComponent(email)}`;
}

function refreshLoginUrl() {
  cpLoginUrl = getUrl("CP_LOGIN");
}

registerRefreshFunction(refreshLoginUrl, true);
