import { ISelectPlanDetails, ISelectPlanFeature } from "@/components/Buy/SelectPlan/interfaces";

export const safeSelectPlanDetails: ISelectPlanDetails = {
  logo: "carb-logo",
  products: [
    {
      sku: "PersonalBasic",
      label: "Common.basic",
      id: "basicDetails",
      hasFeature: {
        unlimitedBackup: true,
        secureCloud: true,
        photoBackup: true,
        allFileTypes: true,
        easyMobile: true,
        hardDriveBackup: false,
        videoBackup: false,
        webrootSecureAnywhereAntivirus: false,
        courierDelivery: false,
      },
    },
    {
      sku: "PersonalPlus",
      label: "Common.plus",
      id: "plusDetails",
      hasFeature: {
        unlimitedBackup: true,
        secureCloud: true,
        photoBackup: true,
        allFileTypes: true,
        easyMobile: true,
        hardDriveBackup: true,
        videoBackup: true,
        webrootSecureAnywhereAntivirus: true,
        courierDelivery: false,
      },
    },
    {
      sku: "PersonalPrime",
      label: "Common.prime",
      id: "primeDetails",
      hasFeature: {
        unlimitedBackup: true,
        secureCloud: true,
        photoBackup: true,
        allFileTypes: true,
        easyMobile: true,
        hardDriveBackup: true,
        videoBackup: true,
        webrootSecureAnywhereAntivirus: true,
        courierDelivery: true,
      },
    },
  ],
};

export const safeSelectPlanFeatures: ISelectPlanFeature[] = [
  {
    name: "unlimitedBackup",
    order: 1,
  },
  {
    name: "secureCloud",
    order: 2,
  },
  {
    name: "photoBackup",
    order: 3,
  },
  {
    name: "allFileTypes",
    order: 4,
  },
  {
    name: "easyMobile",
    order: 5,
  },
  {
    name: "hardDriveBackup",
    order: 6,
  },
  {
    name: "videoBackup",
    order: 7,
  },
  {
    name: "webrootSecureAnywhereAntivirus",
    labelPrefix: "Brand",
    icon: "pc-mag-award",
    order: 8,
  },
  {
    name: "courierDelivery",
    order: 9,
  },
];
