<template>
  <CourierRecoveryComponent />
</template>
<script setup lang="ts">
import CourierRecoveryComponent from "@/components/courier/CourierRecovery.vue";
import { onBeforeMount } from "vue";
import { FLAGS } from "@/define";
import { useRouter } from "vue-router";

const router = useRouter();

onBeforeMount(async () => {
  if (FLAGS.ENABLE_VD_COURIER_RECOVERY === "false") router.push("/home");
});
</script>
