<template>
  <ModalWrapperComponent
    dialog-name="AutoRenewOptionComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <div v-if="header">
        {{ tn(header) }}
      </div>
    </template>

    <template #content>
      <AutoRenewTurnOnComponent
        v-if="currentState === autoRenewOptionState.autoRenewTurnOn"
        :subscription="subscription"
        @change-payment-method="onChangePaymentMethod"
      />
      <AutoRenewTurnOffComponent
        v-if="currentState === autoRenewOptionState.autoRenewTurnOff"
        :subscription="subscription"
      />
      <AutoRenewOffSurveyComponent
        v-if="currentState === autoRenewOptionState.autoRenewTurnOffSurvey"
        @values="setSurveyValues"
      />
      <WebrootAutoRenewOffSurveyComponent
        v-if="currentState === autoRenewOptionState.webrootAutoRenewTurnOffSurvey"
        @values="setSurveyValues"
      />
      <WebrootARTurnOnComponent v-if="currentState === autoRenewOptionState.webrootAutoRenewTurnOn" />
      <WebrootARTurnOffComponent
        v-if="currentState === autoRenewOptionState.webrootAutoRenewTurnOff"
        :subscription="subscription"
      />
    </template>

    <template #footer>
      <div v-if="currentState === autoRenewOptionState.autoRenewTurnOff">
        <button
          v-if="submitButtonText && isPromiseSubmit"
          id="btnSaveChanges"
          type="button"
          class="btn-outline-primary mr-half"
          :disabled="submitDisabled || isSaving"
          @click="save"
        >
          <spinner :is-spinning="isSaving" />
          {{ tn(submitButtonText) }}
        </button>
        <button
          v-if="submitButtonText && !isPromiseSubmit"
          id="btnSaveChanges"
          type="button"
          class="btn-outline-primary mr-half"
          :disabled="submitDisabled || isSaving"
          @click="save"
        >
          <spinner :is-spinning="isSaving" />
          {{ tn(submitButtonText) }}
        </button>
        <button
          v-if="cancelButtonText"
          id="btnCancelChanges"
          type="button"
          class="btn-primary"
          :disabled="isAborting"
          @click="cancelModalOrSkipSurvey(true)"
        >
          <spinner :is-spinning="isAborting" />
          {{ tn(cancelButtonText) }}
        </button>
      </div>
      <div v-else>
        <button
          v-if="cancelButtonText"
          id="btnCancelChanges"
          type="button"
          class="btn-outline-primary mr-half"
          :disabled="isAborting"
          @click="cancelModalOrSkipSurvey(false)"
        >
          <spinner :is-spinning="isAborting" />
          {{ tn(cancelButtonText) }}
        </button>
        <button
          v-if="submitButtonText && isPromiseSubmit"
          id="btnSaveChanges"
          type="button"
          class="btn-primary mr-half"
          :disabled="submitDisabled || isSaving"
          @click="save"
        >
          <spinner :is-spinning="isSaving" />
          {{ tn(submitButtonText) }}
        </button>
        <button
          v-if="submitButtonText && !isPromiseSubmit"
          id="btnSaveChanges"
          type="button"
          class="btn-primary"
          :disabled="submitDisabled || isSaving"
          @click="save"
        >
          <spinner :is-spinning="isSaving" />
          {{ tn(submitButtonText) }}
        </button>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, computed, ref, onUpdated } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

import { IAutoRenewUpdateRequest, IPaymentMethod, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import AutoRenewTurnOffComponent from "@/components/Subscriptions/AutoRenewTurnOff.vue";
import AutoRenewTurnOnComponent from "@/components/Subscriptions/AutoRenewTurnOn.vue";
import AutoRenewOffSurveyComponent from "@/components/Subscriptions/AutoRenewOffSurvey.vue";
import WebrootAutoRenewOffSurveyComponent from "@/components/Subscriptions/WebrootAutoRenewOffSurvey.vue";
import WebrootARTurnOnComponent from "@/components/Subscriptions/WebrootARTurnOn.vue";
import WebrootARTurnOffComponent from "@/components/Subscriptions/WebrootARTurnOff.vue";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { useAutoRenewalStore } from "@/stores/autoRenewal";

import { getUserDetailsFromStore } from "@/common/userDetails";

import { unifiedApi } from "@/common";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { isWebrootProduct } from "@/common/subscriptionHelper";
import { openTextBrands } from "@/components/Buy/BuyEnums";
import { FLAGS } from "@/define";
import { carboniteProducts } from "@/common/productsBySku";

const componentName = "AutoRenewOptionComponent";
//Reason Code 107 is used by MosE for when the survey is skipped
const turnOffAutoRenewSurveySkippedReasonCode = 107;
//Reason Code 307 is used by MosE for when the survey is skipped for Webroot
const turnOffWebrootAutoRenewSurveySkippedReasonCode = 307;

enum autoRenewOptionState {
  autoRenewTurnOn,
  autoRenewTurnOff,
  autoRenewTurnOffSurvey,
  webrootAutoRenewTurnOffSurvey,
  webrootAutoRenewTurnOn,
  webrootAutoRenewTurnOff,
}

const props = defineProps({
  subscription: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
  paymentMethods: {
    type: Object as PropType<IPaymentMethod[]>,
    default: null,
    required: false,
  },
});

// add this below to bring back auto-renew discount "auto-renew-discount-enabled",
const emits = defineEmits(["save-modal", "close-modal", "move-payment-method-mr-to-ar", "auto-renew-updated"]);

const config = [
  {
    type: autoRenewOptionState.autoRenewTurnOn,
    header: "AutoRenewTurnOnHeader",
    cancelButtonText: "footerButtonCancel",
    submitButtonText: "footerButtonTurnOnAutoRenewal",
    isPromiseSubmit: true,
  },
  {
    type: autoRenewOptionState.autoRenewTurnOff,
    header: "AutoRenewTurnOffHeader",
    cancelButtonText: "footerKeepAutoRenewalOn",
    submitButtonText: "footerButtonTurnOffAutoRenewal",
    isPromiseSubmit: false,
  },
  {
    type: autoRenewOptionState.autoRenewTurnOffSurvey,
    header: "AutoRenewOffSurveyComponentHeader",
    cancelButtonText: "skipSurvey",
    submitButtonText: "submit",
    isPromiseSubmit: true,
  },
  {
    type: autoRenewOptionState.webrootAutoRenewTurnOffSurvey,
    header: "AutoRenewOffSurveyComponentHeader",
    cancelButtonText: "skipSurvey",
    submitButtonText: "submit",
    isPromiseSubmit: true,
  },
  {
    type: autoRenewOptionState.webrootAutoRenewTurnOn,
    header: "WebrootARTurnOnHeader",
    cancelButtonText: "",
    submitButtonText: "footerButtonClose",
    isPromiseSubmit: false,
  },
  {
    type: autoRenewOptionState.webrootAutoRenewTurnOff,
    header: "WebrootARTurnOffHeader",
    cancelButtonText: "",
    submitButtonText: "footerButtonClose",
    isPromiseSubmit: false,
  },
];

logEvent("created", componentName, props.subscription);
const autoRenewalStore = useAutoRenewalStore();

const isSaving = ref<boolean>(false);
const isAborting = ref<boolean>(false);
const currentState = ref<autoRenewOptionState>();
const header = ref<string>("");
const cancelButtonText = ref<string>("");
const submitButtonText = ref<string>("");
const isPromiseSubmit = ref<boolean>(true);
const surveySelectedReason = ref<number | null>(null);
const surveyOtherReason = ref<string>("");
const submitDisabled = ref<boolean>(false);
const isSafeSku = computed(() => {
  return carboniteProducts.find(l => props.subscription.sku === l.sku);
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function setAutoRenewModalType(subscription: ISubscription) {
  logEvent("setAutoRenewModalType", componentName, subscription.isAutoRenewEnabled);

  currentState.value = subscription.isAutoRenewEnabled
    ? !isSafeSku.value && FLAGS.ENABLE_BUYFLOW_WEBROOT === "false"
      ? autoRenewOptionState.webrootAutoRenewTurnOff
      : autoRenewOptionState.autoRenewTurnOff
    : !isSafeSku.value && FLAGS.ENABLE_BUYFLOW_WEBROOT === "false"
      ? autoRenewOptionState.webrootAutoRenewTurnOn
      : autoRenewOptionState.autoRenewTurnOn;

  await getCurrentConfig();
}

async function getCurrentConfig() {
  const currentConfig = config.find(c => c.type === currentState.value);
  if (currentConfig) {
    header.value = currentConfig.header;
    cancelButtonText.value = currentConfig.cancelButtonText;
    submitButtonText.value = currentConfig.submitButtonText;
    isPromiseSubmit.value = currentConfig.isPromiseSubmit;
  }
}

async function cancelModalOrSkipSurvey(fromKeepAutoRenewForDiscount: boolean = false) {
  isAborting.value = true;
  // If it is a Webroot product and if you have not accepted the renewal discount, the cancel button sets renewalDiscountApplied to true
  if (
    !props.subscription.renewalDiscountApplied &&
    props.subscription.renewalDiscount === 0 &&
    isWebrootProduct(props.subscription) &&
    FLAGS.ENABLE_BUYFLOW_WEBROOT === "true" &&
    fromKeepAutoRenewForDiscount
  ) {
    // send back to Subscriptions and show 25% off in the next payment column of the table
    // change enableAutoRenewDiscount to true to enable discount
    // uncomment next 2 lines to bring back auto-renew discount
    // const request: IAutoRenewDiscountRequest = { enableAutoRenewDiscount: true };
    // await unifiedApi.setRenewalDiscount(props.subscription.zuoraSubscriptionNumber, request);
    // need to send the AutoRenewDiscountEnabledNotification to SubscriptionInformation
    // uncomment next line and adjust line 176 to bring back auto-renew discount
    // emits("auto-renew-discount-enabled", props.subscription);
    close();
    return;
  }
  if (currentState.value === autoRenewOptionState.autoRenewTurnOffSurvey) {
    surveySelectedReason.value = turnOffAutoRenewSurveySkippedReasonCode;
    await save();
  } else if (currentState.value === autoRenewOptionState.webrootAutoRenewTurnOffSurvey) {
    surveySelectedReason.value = turnOffWebrootAutoRenewSurveySkippedReasonCode;
    await save();
  }
  isAborting.value = false;
  close();
}

async function setCurrentState() {
  var proceedToSave = false;
  if (currentState.value === autoRenewOptionState.autoRenewTurnOff) {
    if (isWebrootProduct(props.subscription)) {
      currentState.value = autoRenewOptionState.webrootAutoRenewTurnOffSurvey;
      await getCurrentConfig();
      submitDisabled.value = true;
    } else {
      currentState.value = autoRenewOptionState.autoRenewTurnOffSurvey;
      await getCurrentConfig();
      submitDisabled.value = true;
    }
  } else if (
    currentState.value === autoRenewOptionState.webrootAutoRenewTurnOff ||
    currentState.value === autoRenewOptionState.webrootAutoRenewTurnOn
  ) {
    close();
  } else {
    proceedToSave = true;
  }
  return proceedToSave;
}

async function save() {
  var proceedToSave = await setCurrentState();
  if (proceedToSave) {
    //Set the selected brand for auto renewal
    if (isWebrootProduct(props.subscription)) {
      autoRenewalStore.setSelectedBrandForAutoRenewal(openTextBrands.Webroot);
    } else {
      autoRenewalStore.setSelectedBrandForAutoRenewal(openTextBrands.Carbonite);
    }
    if (currentState.value === autoRenewOptionState.autoRenewTurnOn) {
      emits("save-modal", "OK");

      if (props.subscription.productDisplayName) {
        sessionStorage.setItem("product_name_for_auto_renew", props.subscription.productDisplayName);
      }
    } else if (currentState.value === autoRenewOptionState.autoRenewTurnOffSurvey) {
      isSaving.value = !isAborting.value;
      await onAutoRenewTurnOnOrOffSurvey(surveySelectedReason.value, surveyOtherReason.value);
      isSaving.value = false;
    } else if (currentState.value === autoRenewOptionState.webrootAutoRenewTurnOffSurvey) {
      isSaving.value = !isAborting.value;
      await onAutoRenewTurnOnOrOffSurvey(surveySelectedReason.value, surveyOtherReason.value);
      isSaving.value = false;
    }
  }
}

onUpdated(async () => {
  isSaving.value = false;
  isAborting.value = false;
});

function onChangePaymentMethod() {
  emits("save-modal", "CHANGE_PAYMENT_METHOD");
}

async function onAutoRenewTurnOnOrOffSurvey(surveyCode, surveyInput) {
  if (!props.subscription.paymentInfo?.cardType && !props.subscription.isAutoRenewEnabled) {
    emits("save-modal", "OK");
  } else {
    try {
      const updateAutoRenewOptionRequest: IAutoRenewUpdateRequest = {
        allowAutoRenew: !props.subscription.isAutoRenewEnabled,
        surveyChoice: surveyInput,
        zuoraSubscriptionNumber: props.subscription.zuoraSubscriptionNumber,
        surveyChoiceCode: surveyCode,
        paymentMethodId: "",
      };
      await unifiedApi.updateAutoRenewOption(updateAutoRenewOptionRequest);
      await getUserDetailsFromStore(true);
      emits("save-modal", "OK");
    } catch (error) {
      emits("save-modal", "FAIL");
      handleApiError(error as AxiosError);
    }
  }
}
function setSurveyValues(selectedReason, otherReason) {
  surveySelectedReason.value = selectedReason;
  surveyOtherReason.value = otherReason;
  submitDisabled.value =
    surveySelectedReason.value === 0 ||
    ((surveySelectedReason.value === 106 || surveySelectedReason.value === 306) &&
      surveyOtherReason.value.length === 0);
}

async function beforeOpen() {
  await setAutoRenewModalType(props.subscription);
}

function close() {
  //reset the value back to false in the case a user closes the survey using the X btn - ZUOR-47624
  submitDisabled.value = false;
  emits("close-modal");
}
</script>
