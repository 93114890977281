<template>
  <div class="downloads-page">
    <button
      id="btnDownload"
      :class="props.btnClass"
      :disabled="isMobileClient || isDownloading"
      class="mt-half"
      @click="downloadSafe"
    >
      <spinner :is-spinning="isDownloading" />
      <span v-if="props.btnText">
        {{ t(props.btnText) }}
      </span>
      <span v-else>
        {{ t("DownloadSafeComponent.downloadNow") }}
      </span>
    </button>
    <p v-show="isOutDatedWindowsResult" class="mt">
      <i18n-t keypath="DownloadSafeComponent.sha1Message" tag="span" class="account-availability" scope="global">
        <template #download>
          <strong>{{ t("DownloadSafeComponent.downloadingOnWindows") }}</strong>
        </template>
        <template #moreDetails>
          <a
            id="linkDownSafeMoreDetailsTargetBlank"
            :href="getUrl('VITE_APP_SAFE_SHA1_MORE_DETAILS_URL')"
            target="_blank"
          >
            {{ t("DownloadSafeComponent.moreDetails") }}
          </a>
        </template>
      </i18n-t>
    </p>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { IActivationCodePurchaseRequest, IDevice } from "@/common/api/unifiedPortal/interfaces";
import { useNotificationsStore } from "@/stores/notifications";
import { GetSafeClientAndDownload } from "@/common/safeClient";
import { isMobile, isOutDatedWindows, isSafari, isFirefox } from "@/common/browserDetect";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import Spinner from "@/components/shared/Spinner.vue";
import { getUserDetailsFromStore } from "@/common/userDetails";
import { getUrl } from "@/common/getUrl";
import { useBuyFlowStore } from "@/stores/buyFlow";

const name = "DownloadSafe";
const buyFlowStore = useBuyFlowStore();
const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: false,
    default: null,
  },
  installType: {
    type: String,
    required: false,
    default: null,
  },
  btnClass: {
    type: String,
    default: "btn-primary",
  },
  btnText: {
    type: String,
    default: null,
  },
});

const emits = defineEmits(["downloaded", "installInstructions"]);

logEvent("created", name, props.device);
const notificationsStore = useNotificationsStore();
const isDownloading = ref<boolean>(false);
const isMobileClient = computed(() => {
  return isMobile();
});

const isOutDatedWindowsResult = computed(() => {
  return isOutDatedWindows();
});

async function downloadSafe() {
  isDownloading.value = true;
  try {
    emits("installInstructions");
    notificationsStore.clearNotifications();
    logEvent("DownloadSafeEvent", name, "Emitted");
    // If null, attribute a default value
    const activationCode = await getActivationCode();
    const deviceId = props.device?.deviceId ? props.device.deviceId : 0;
    const installType = props.installType ? props.installType : "Install";
    const isSafeLiteDownload = props.device?.webrootKeycode && !props.device?.activationCode;
    const safeClient = isSafeLiteDownload
      ? await GetSafeClientAndDownload("", 0, "", "Install", props.device?.webrootKeycode ?? "")
      : await GetSafeClientAndDownload(activationCode || "", deviceId, "", installType, "");
    if (safeClient === "Ok") {
      // Enable update HasSafeSubscriptions and hasTrialSafe flags on user if download started for a Trial
      emits("downloaded");
    }
  } catch (error) {
    logException(error as Error);
  }

  if (!isSafari() && !isFirefox()) {
    await reloadSubscriberInfo();
    await getUserDetailsFromStore(true);
  }
  isDownloading.value = false;
}

async function getActivationCode() {
  let activationCode;
  const cartItem = buyFlowStore.cart?.items[0];
  if (cartItem) {
    const request: IActivationCodePurchaseRequest = {
      userGuid: buyFlowStore.userGuid ?? "",
      email: buyFlowStore.currentEmail ?? "",
      subscriptionNumber: cartItem.subscriptionNumbers[0] ?? "",
    };
    activationCode = (await unifiedApi.getActivationCode(request)).data;
  }
  var response = props.device?.activationCode ? props.device.activationCode : activationCode?.activationCode;
  return response ? response : "";
}
</script>
