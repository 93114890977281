<template>
  <NotificationsComponent
    v-if="props.notificationContext"
    :context="props.notificationContext"
    @notification-open-primary-email-verification="toggleVerifyPrimaryEmailAddressModal"
  />
  <slot name="content"></slot>
  <VerifyPrimaryEmailAddressComponent
    v-if="showVerifyPrimaryEmailAddressModal"
    :show-dialog="showVerifyPrimaryEmailAddressModal"
    :email="props.email"
    @close-modal="toggleVerifyPrimaryEmailAddressModal"
  />
</template>
<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { logEvent } from "@/common/logger";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import VerifyPrimaryEmailAddressComponent from "@/components/shared/dialogs/VerifyPrimaryEmail/VerifyPrimaryEmailAddress.vue";

const name = "PrimaryEmailVerificationWrapperComponent";

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  notificationContext: {
    type: String,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["close-dialog"]);

logEvent("created", name);
const showVerifyPrimaryEmailAddressModal = ref(props.showDialog);
const activateDialog = computed(() => props.showDialog);

watch(activateDialog, () => {
  if (activateDialog.value === true) {
    toggleVerifyPrimaryEmailAddressModal("open");
  }
});

function toggleVerifyPrimaryEmailAddressModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleVerifyPrimaryEmailAddressModal",
    name,
    `${showVerifyPrimaryEmailAddressModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showVerifyPrimaryEmailAddressModal.value = !showVerifyPrimaryEmailAddressModal.value;
  if (!showVerifyPrimaryEmailAddressModal.value) {
    emits("close-dialog");
  }
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
