<template>
  <h1>{{ tn("h1Heading") }}</h1>
  <div class="mb">
    <h2 v-if="FLAGS.ENABLE_ALLSTATE_FAMILY !== 'true'" class="mb">{{ tn("notification") }}</h2>
    <div>
      <svg-icon-component icon="webroot-logo" class="mb" />
      &nbsp;
      <svg-icon-component icon="logo-allstate" class="mb" />
    </div>
    <p v-if="FLAGS.ENABLE_ALLSTATE_FAMILY !== 'true'">
      <strong>{{ tn("action") }}</strong>
    </p>
    <p v-if="FLAGS.ENABLE_ALLSTATE_FAMILY !== 'true'">{{ tn("summaryText") }}</p>
    <p v-else>{{ tn("summaryTextFamily") }}</p>
  </div>
  <div class="mb">
    <button id="btn-learnMore" class="btn-outline-primary" @click="learnMore()">
      {{ tn("learnMore") }}
    </button>
  </div>
  <i18n-t keypath="AllstateNotPurchasedComponent.notes" tag="p" class="mt mb text-grey" scope="global">
    <template #downloads>
      <button id="btn-downloads" class="inline-btn-link" @click="goToDownloads()">{{ tn("downloads") }}</button>
    </template>
  </i18n-t>
</template>
<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";
import { FLAGS } from "@/define";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useRouter } from "vue-router";
import { hasWebrootSubscriptions } from "@/components/Allstate/AllstateHelper";

const componentName = "AllstateNotPurchasedComponent";

logEvent("created", componentName);
const router = useRouter();
const hasWebroot = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function learnMore() {
  window.location.href = hasWebroot.value ? getUrl("WEBROOT_LEARN_MORE") : getUrl("ALLSTATE_BUY");
}

function goToDownloads() {
  router.push("/downloads");
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  hasWebroot.value = await hasWebrootSubscriptions();
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
