<template>
  <section>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <div v-if="FLAGS.ENABLE_CDLP === 'true'">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('nextStepsWebrootAllStepsMyAccount')"></p>
      <p>{{ tn("nextStepsWebrootProvisionalP2") }}</p>
      <div>
        <button id="btnCreateAccountFromProvisional" type="button" class="btn-primary" @click="goToSetPassword()">
          {{ tn("btnSetPassword") }}
        </button>
      </div>
    </div>
    <div v-else>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('nextStepsWebrootAllStepsMyAccount')"></p>
      <ol class="mb">
        <li class="mb">
          {{ tn("nextStepsWebrootProvisionalStep1") }}
          <div class="mt-half">
            <button id="btnCreateAccountFromProvisional" type="button" class="btn-primary" @click="goToSetPassword()">
              {{ t("OrderConfirmation.btnFinishMyAccountSetup") }}
            </button>
          </div>
        </li>
        <li>
          {{ tn("nextStepsWebrootProvisionalStep2") }}
        </li>
      </ol>
    </div>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import router from "@/routes";
import { FLAGS } from "@/define";

const componentName = "WebrootNextStepsProvisional";
const baseComponentName = "NextSteps";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToSetPassword() {
  router.push("/setNewPassword");
}
</script>
