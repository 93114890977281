<template>
  <AllstateSignUpComponent />
</template>

<script setup lang="ts">
import AllstateSignUpComponent from "@/components/Allstate/AllstateSignUp.vue";
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { hasOnlyBestBuyS2 } from "@/common/bestBuy";
import { FLAGS } from "@/define";

const router = useRouter();

onBeforeMount(async () => {
  const bbyS2Only = await hasOnlyBestBuyS2();
  if (bbyS2Only && FLAGS.DISPLAY_ALLSTATE_UPGRADE === "false") router.push("/home");
});
</script>
