import { ref, computed } from "vue";
import { session } from "./sessionFn";
import { getFileExtension } from "./viewFn";
import { IComputerContents, IRfaItem, IRfaPropData } from "./interfaces";
import { FullPageErrorTypes } from "./FileAccessEnums";
import { getUserRequestsShowDisks, setUserRequestsShowDisks, showDisks } from "./diskFn";
import { getUserRequestsShowSortOptions, setUserRequestsShowSortOptions, showSortOptions } from "./sortFn";
import { useUserStore } from "@/stores/user";
import { UserType } from "@/globalEnums";
import { AxiosRequestConfig } from "axios";

export const componentName = "FileAccessComponent";

export const isLoading = ref<boolean>(true);
export const showFullPageError = ref<boolean>(false);
export const fullPageErrorType = ref<FullPageErrorTypes>(FullPageErrorTypes.none);
export const currentItems = ref<IRfaItem[]>([]);
export const allItems = ref<IRfaItem[]>([]);
export const propData = ref<IRfaPropData>({ computerId: "", computerName: "", path: "" });
export const currentCSSBGetComputerContentsRequest = ref<IComputerContents>();
const MaxDownloadTimeOutMsec = 2400000; // 40 Minutes

export const isSmb = computed(() => {
  const userStore = useUserStore();
  return userStore.userType == UserType.smb;
});

export const MIN_SPINNER_DISPLAY_COUNT = 100;

//all the functions in ServerComm need the base url
export function getBaseUrl(): string {
  return `https://${session.value?.serverExternalName}/Access`;
}

export function getErsoBaseUrl(): string {
  return `https://${session.value?.serverExternalName}/Erso/api`;
}

export function getSessionKey(): string {
  return session.value?.sessionKey ?? "";
}

export function addLocalValues(): number {
  let imageCount = 0;
  //populate the file extension and convert the date
  //count the number of image files
  for (const item of allItems.value) {
    if (!item.IsDirectory && !item.IsBackupSet) {
      item.Extension = getFileExtension(item.Name);
      item.Modified = new Date(item.ModifiedDate);

      //RFA server seems to check the Accept-Language header and change the format of the
      //date depending on its setting. In the case of Firefox, it says en-CA and so the server
      //sends a date formatted like 2017-01-01, which then Firefox fails to parse
      //however, replacing the - with / allows Firefox to parse correctly. Would be nice if they just
      //sent an ISO formatted date....
      if (item.Modified.toString() === "Invalid Date") {
        item.Modified = new Date(item.ModifiedDate.replaceAll("-", "/"));
      }
    }
    if (item.IsImage) imageCount++;
  }
  return imageCount;
}

let currentPath: string | null = null;
export function setCurrentPath(path: string | null) {
  currentPath = path;
}
export function getCurrentPath(): string | null {
  return currentPath;
}

//if the user clicks anywhere other than on an open selector, we want to close it
//we can't use the blur event because selecting an individual element from the selector
//causes the blur to occur and the selector is closed before the selection can be made
//so watch for clicks at the highest level and if anything is open, close it
//except if the user just clicked on the selector's icon to open it
export function hideElementsIfOpen() {
  if (!getUserRequestsShowDisks() && showDisks.value) showDisks.value = false;
  else setUserRequestsShowDisks(false);

  if (!getUserRequestsShowSortOptions() && showSortOptions.value) showSortOptions.value = false;
  else setUserRequestsShowSortOptions(false);
}

export function getApiConfig() {
  const encodedToken = btoa(getSessionKey());
  return {
    headers: {
      Authorization: `Basic ${encodedToken}`,
    },
  };
}

export function getApiConfigForDownload() {
  const encodedToken = btoa(getSessionKey());
  const apiConfig: AxiosRequestConfig = {
    headers: {
      Authorization: `Basic ${encodedToken}`,
    },
    responseType: "blob",
    timeout: MaxDownloadTimeOutMsec,
  };
  return apiConfig;
}
