import { defineStore } from "pinia";
import loadConfig, { IConfig } from "@/common/config";

export interface IConfigStore {
  config: IConfig | null;
}

export const useConfigStore = defineStore("config", {
  state: (): IConfigStore => ({
    config: null,
  }),
  actions: {
    getConfig: async function getConfig() {
      if (!this.config) this.config = await loadConfig();
    },
  },
});
