<template>
  <div>
    <div
      v-if="
        FLAGS.ENABLE_BUYFLOW_WEBROOT === 'true' &&
        !subscription.renewalDiscountApplied &&
        isWebrootProduct(subscription)
      "
      class="fill-covers-padding info-bg pa link-color text-left flex-start border-bottom link-color thick"
      style="display: none"
    >
      <div class="no-shrink mr-half">
        <svg-icon-component icon="circle-check" class="icon-sm link-color" />
      </div>
      <i18n-t keypath="AutoRenewTurnOffComponent.oneTimeDiscount" tag="div" scope="global">
        <template #renewalDiscount>{{ AutoRenewOneTimeDiscount }}</template>
      </i18n-t>
    </div>
    <p>
      <strong>{{ tn("autoRenewOffLine1") }}</strong>
    </p>
    <ul class="tight mt mb">
      <li>
        <em>{{ tn("subscription") }}</em>
        {{ subscriptionName }}
      </li>
      <li>
        <div v-if="isWebrootProduct(subscription)">
          <em>{{ t("Common.keycode") }}:</em>
          {{ keyCode }}
        </div>
        <div v-else>
          <em>{{ tn("computer") }}</em>
          {{ computerName }}
        </div>
      </li>
      <li>
        <em>{{ tn("expires") }}</em>
        {{ formatExpirationDate(expiresOn) }}
      </li>
    </ul>
    <p v-if="isIolo">{{ tn("notifyProtectionIolo") }}</p>
    <p v-else>{{ tn("notifyProtection") }}</p>
  </div>
</template>
<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { formatDateString } from "@/common/dateFormat";
import { isWebrootProduct } from "@/common/subscriptionHelper";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { AutoRenewOneTimeDiscount, FLAGS } from "@/define";
import { webrootLicenseCategories } from "@/common/webrootProductName";

const componentName = "AutoRenewTurnOffComponent";

const props = defineProps({
  subscription: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

logEvent("created", componentName, props.subscription);
const subscriptionName = ref<string>(props.subscription?.productDisplayName ?? "");
const expiresOn = ref<string>(props.subscription?.expirationDate ?? "");

const isIolo = computed(() => {
  return props.subscription.licenseCategoryName === webrootLicenseCategories.WPCO;
});

const computerName = computed(() => {
  return props.subscription?.computer?.description ?? props.subscription?.computer?.name ?? "";
});

const keyCode = computed(() => {
  return props.subscription?.keyCode || "";
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function formatExpirationDate(expirationDate: string) {
  return formatDateString(expirationDate);
}
</script>
