<template>
  <ul>
    <li
      v-for="(a, index) in buyFlowStore.getOrderedAdjustmentsToDisplay(props.adjustments)"
      :id="`adjustmentItem-${index}`"
      :key="a.type"
      :class="a.textClass"
      class="opposite-ends"
    >
      <div>
        <span>{{ t(`Adjustments.${a.type}`, { replacementText: a.textReplacement }) }}</span>
      </div>
      <div v-if="a.amountText" class="amount no-shrink text-center">
        <span :id="`adjustment-amount-${a.type}`">{{ a.amountText }}</span>
      </div>
      <div v-else class="amount">
        <strong :id="`adjustment-amount-${a.type}`">{{ formatMoney(a.amount || 0) }}</strong>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { formatMoney } from "@/components/Buy/commonFn";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { onBeforeMount, PropType, watch } from "vue";
import { IAdjustment } from "@/common/api/unifiedPortal/interfaces";
import { logEvent } from "@/common/logger";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import { adjustments as adjustmentsEnum } from "@/components/Buy/BuyEnums";

const componentName = "AdjustmentsComponent";

logEvent("created", componentName);

const buyFlowStore = useBuyFlowStore();
const notificationsStore = useNotificationsStore();

const props = defineProps({
  adjustments: {
    type: Object as PropType<IAdjustment[]>,
    required: true,
  },
});
function LicenseDays() {
  const remainingDaysAdjustment = props.adjustments?.find(l => l.type === adjustmentsEnum.LicenseDaysRemaining);
  if (remainingDaysAdjustment) {
    notificationsStore.clearNotifications();
    notificationsStore.addNotification({
      type: "LicenseDaysRemaining" as NotificationType,
      params: { remainingDays: remainingDaysAdjustment.amount },
    });
  }
}

onBeforeMount(() => {
  LicenseDays();
});

watch(
  () => props.adjustments,
  () => {
    LicenseDays();
  }
);
</script>
