<template>
  <div>
    <div class="content">
      <header>
        <h1>{{ tn("h1Heading") }}</h1>
      </header>
      <p class="mb-double">{{ tn("p1Body") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "ErrorComponent";

logEvent("Created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
