<template>
  <article class="constrain-90 m-auto mt-double mb-200">
    <h1 class="mb">{{ tn("header") }}</h1>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="mb mobile-text" v-html="tn('p1')"></p>

    <p class="mb">
      <button type="button" class="btn-primary mobile-text" @click="startShopping()">
        {{ tn("startShoppingBtn") }}
      </button>
    </p>
  </article>
</template>

<script setup lang="ts">
import { dotComPricingPath } from "@/define";
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";

const componentName = "SafeLongExpiredComputer";

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function startShopping() {
  window.open(`${getUrl("CARBONITE_COM_SITE")}${dotComPricingPath}`, "_self");
}
</script>
