export enum DeviceStatus {
  Starting = "Starting",
  Unknown = "Unknown",
  Frozen = "Frozen",
  Uninstalled = "Uninstalled",
  Disabled = "Disabled",
  RecoverMode = "RecoverMode",
  Overdue = "Overdue",
  OverdueSevere = "OverdueSevere",
  BackupOutOfDate = "BackupOutOfDate",
}

export enum expirationState {
  none,
  isExpiring,
  expired,
  purged,
}

export enum customerType {
  direct,
  indirect,
  WSAC,
}

export enum termType {
  trial,
  annual,
  evergreen,
}

export enum expirationMessageTypes {
  evergreen = "evergreenExpirationMessage",
  autoRenew = "autoRenewExpirationMessage",
  expiresOn = "expiresOnExpirationMessage",
  validUntil = "validUntilExpirationMessage",
  expiredOn = "expiredOnExpirationMessage",
}

//not used anywhere
export enum sourceType {
  partnerReferral = "Partner Referral",
  carbonite = "Carbonite",
}
