<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else>
    <button class="btn-link left-link mb" @click="cancel()">
      <svg-icon-component icon="chevron-left" class="icon-sm" />
      {{ tn("back") }}
    </button>

    <ShippingForm v-if="buyFlowStore.hasShippableItem" />

    <div v-if="buyFlowStore.shippingMatchesBilling" class="constrain-65">
      <BuySelectedCreditCardComponent
        :selected-payment-method-id="buyFlowStore.cart?.paymentMethodId || ''"
        :only-show-address="true"
      />
    </div>

    <div>
      <button id="btnCancel" type="button" class="btn-outline-primary mr" @click="cancel()">
        {{ t("Common.cancel") }}
      </button>
      <button id="btnSubmit" type="button" class="btn-primary" @click="submit()">{{ tn("submit") }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import ShippingForm from "@/components/Buy/Payment/ShippingForm.vue";
import { logEvent } from "@/common/logger";
import { onBeforeMount, ref } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { changePageState, checkShippingFormForErrors } from "@/components/Buy/BuyHelper";
import { layoutStates, pageStates } from "@/components/Buy/BuyEnums";
import { t } from "@/i18n";
import { NotificationLookup, NotificationType, useNotificationsStore } from "@/stores/notifications";
import { AxiosError } from "axios";
import { IPurchaseError } from "../Interfaces";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import BuySelectedCreditCardComponent from "@/components/Buy/Payment/SelectedCreditCard.vue";

const componentName = "EditShipping";
const buyFlowStore = useBuyFlowStore();
const notificationsStore = useNotificationsStore();
const isLoading = ref(false);
//Can not be a ref because we don't want it to match the current value.
let previousShippingMatchesBillingState: boolean;

onBeforeMount(() => {
  logEvent("Created", componentName);
  buyFlowStore.editShippingAddress = Object.assign({}, buyFlowStore.shippingAddress);
  previousShippingMatchesBillingState = buyFlowStore.shippingMatchesBilling;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function cancel() {
  buyFlowStore.shippingAddress = Object.assign({}, buyFlowStore.editShippingAddress);
  buyFlowStore.layoutState = layoutStates.purchase;
  buyFlowStore.shippingMatchesBilling = previousShippingMatchesBillingState;
}

async function submit() {
  if (buyFlowStore.hasShippableItem) {
    //If the billing matches the shipping just return to the review order page. Check out will handle setting the address from ZUOR-44619
    if (buyFlowStore.shippingMatchesBilling) {
      buyFlowStore.layoutState = layoutStates.purchase;
    }

    if (checkShippingFormForErrors()) {
      return;
    }
  }

  //No errors, set shipping address on cart
  if (buyFlowStore.cart) {
    buyFlowStore.cart.shippingAddress = buyFlowStore.shippingAddress;
  }

  try {
    isLoading.value = true;
    //Trigger update shopping cart
    await buyFlowStore.updateShoppingCartTotal(false, true);
    changePageState(pageStates.reviewOrder, true);
  } catch (err) {
    const error = err as AxiosError;
    const response = error?.response?.data as IPurchaseError;

    if (response && response?.message) {
      const errorObject = NotificationLookup[response.message];

      if (errorObject) {
        notificationsStore.addNotification({ type: response.message as NotificationType, dismissible: false });
      } else {
        notificationsStore.addNotification({ type: "ServerErrors", dismissible: false });
      }
    }
  }

  isLoading.value = false;
}
</script>
