<template>
  <div>
    <p>
      <strong>{{ tn("autoRenewOffSurveyH2") }}</strong>
    </p>
    <p>{{ tn("reasonTurnOff") }}</p>
    <ul class="tight">
      <li v-for="questionCode in surveyQuestionCodes" :key="questionCode.id" class="radio">
        <input
          :id="`radioQC${questionCode.id}`"
          v-model="selectedDisableReason"
          name="disableReason"
          type="radio"
          :value="questionCode.id"
        />
        <label :for="`radioQC${questionCode.id}`">{{ tn(questionCode.displayName) }}</label>
      </li>
      <li>
        <input
          id="otherReason"
          v-model="otherReason"
          class="constrain-65 indent"
          type="text"
          :disabled="selectedDisableReason !== 106"
          maxlength="80"
        />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "AutoRenewOffSurveyComponent";

const emits = defineEmits(["values"]);

logEvent("created", componentName);
const otherReason = ref<string>("");

const surveyQuestionCodes = [
  { id: 101, displayName: "r101" },
  { id: 102, displayName: "r102" },
  { id: 103, displayName: "r103" },
  { id: 104, displayName: "r104" },
  { id: 105, displayName: "r105" },
  { id: 106, displayName: "r106" },
];

const selectedDisableReason = ref<number>(0);

watch(selectedDisableReason, () => {
  emitValues();
});

watch(otherReason, () => {
  emitValues();
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function emitValues() {
  emits("values", selectedDisableReason.value, otherReason.value);
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
