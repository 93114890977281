<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
    <li>
      {{ t(`${parentComponentName}.${productCode}UnsupportedReference`) }}
      <Keycodes :keycodes="keycodes" :product-code="productCode" />
    </li>
  </ul>
  <div v-else class="form">
    <p class="danger-text">
      {{ t(`${parentComponentName}.${productCode}MainParagraph`) }}
    </p>
    <ol>
      <li>
        <p>{{ t(`${parentComponentName}.${productCode}StepOne`) }}</p>
        <button id="btnDownload" class="btn-primary" type="button" @click="download()">
          {{ t(`${parentComponentName}.btnDownload`) }}
        </button>
      </li>
      <li>
        <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
        <Keycodes :keycodes="keycodes" :product-code="productCode" />
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import Keycodes from "@/components/Downloads/Keycodes.vue";

const productCode = "WPCO";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

logEvent("created", componentName, props);
function download() {
  logEvent("download", componentName);
  window.location.href = `/WebrootPcOptimizer.exe`;
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
