import {
  IUserPaymentHistoryPaymentInfo,
  IUserPaymentHistoryPaymentInfoItems,
} from "@/common/api/unifiedPortal/interfaces";
import { logEvent } from "@/common/logger";
import { IProductDisplayRequest, getProductDisplayNameBySku } from "@/common/productsBySku";
import { webrootCDSku } from "@/common/webrootProductName";
import { migratedCDInvoicePrefix } from "@/define";
import { carboniteSkus } from "@/common/productsBySku";

interface ISortItem {
  sku: string;
  order: number;
}

//Create a config object with the sort order for WR
const webrootSortOrder = [
  {
    sku: "WebrootAntiVirus",
    order: 1,
  },
  {
    sku: "WebrootPlus",
    order: 1,
  },
  {
    sku: "WebrootComplete",
    order: 1,
  },
  {
    sku: "WebrootCompleteFamily",
    order: 1,
  },
  {
    sku: "WebrootPremium",
    order: 1,
  },
  {
    sku: "WebrootPremiumFamily",
    order: 1,
  },
  {
    sku: webrootCDSku,
    order: 2,
  },
  {
    sku: "WebrootSecureVPN",
    order: 3,
  },
  {
    sku: "AllstateIdentityProtectionBasic",
    order: 4,
  },
  {
    sku: "AllstateIdentityProtectionPremium",
    order: 4,
  },
  {
    sku: "AllstateIdentityProtectionPremiumFamily",
    order: 4,
  },
  {
    sku: "AndroidMobile",
    order: 10,
  },
  {
    sku: "WindowWasher",
    order: 10,
  },
  {
    sku: "WebrootChromebook",
    order: 10,
  },
  {
    sku: "WebrootGamer",
    order: 10,
  },
  {
    sku: "WebrootSmallBusinessAntiVirus",
    order: 99,
  },
] as ISortItem[];

const skusToIgnore = ["Discount", "PriorSubCredit", "FlatRateInPlaceUpgrade"];

export function processData(invoices: IUserPaymentHistoryPaymentInfo[], componentName: string) {
  const filteredInvoices = invoices.filter(l => {
    if (l.invoiceId.toUpperCase().startsWith(migratedCDInvoicePrefix)) {
      return l.subscriptionPaymentItems.some(k => carboniteSkus.some(i => i === k.sku));
    }

    return true;
  });
  for (const invoice of filteredInvoices) {
    //Sort only matters for WR products atm since we only sell 1 Safe product at a time
    invoice.subscriptionPaymentItems = invoice.subscriptionPaymentItems
      .filter(l => !skusToIgnore.some(k => l.sku === k))
      .sort((a: IUserPaymentHistoryPaymentInfoItems, b: IUserPaymentHistoryPaymentInfoItems) => {
        const aSort = webrootSortOrder.find(l => l.sku === a.sku)?.order || 99;
        const bSort = webrootSortOrder.find(l => l.sku === b.sku)?.order || 99;
        if (aSort > bSort) return 1;
        if (aSort < bSort) return -1;
        return 0;
      });
    invoice.productNameSort = productDisplayForInvoice(invoice, componentName);
  }
  invoices = filteredInvoices.filter(i => i.invoicePaymentAmount > 0);
  return invoices;
}

///At the moment with Safe only invoices we can only have 1 type of product per invoice
export function productDisplayForInvoice(
  invoiceItem: IUserPaymentHistoryPaymentInfo,
  localizedStringComponentName: string
) {
  logEvent("productDisplayForInvoice", localizedStringComponentName, invoiceItem);
  if (invoiceItem.subscriptionPaymentItems.length === 0) {
    return "";
  }

  const subscriptionItem: IUserPaymentHistoryPaymentInfoItems = invoiceItem.subscriptionPaymentItems[0];
  const request: IProductDisplayRequest = {
    sku: subscriptionItem.sku,
    webrootKeycode: subscriptionItem.webrootKeycode,
    isTrial: false,
  };

  return getProductDisplayNameBySku(request);
}

export function isSafePlusOrPrime(invoice: IUserPaymentHistoryPaymentInfo) {
  if (invoice.subscriptionPaymentItems.length > 1) {
    return (
      invoice.subscriptionPaymentItems.some(
        l =>
          l.sku === "PersonalPlus" ||
          l.sku === "PersonalPrime" ||
          l.sku === "CarboniteHomePlus" ||
          l.sku === "CarboniteHomePremier"
      ) &&
      (!invoice.subscriptionPaymentItems.some(l => l.sku === "PersonalBasic") ||
        invoice.subscriptionPaymentItems.some(l => l.sku === "PersonalBasic" && l.itemAmount < 0))
    );
  }

  return invoice.subscriptionPaymentItems.some(
    l =>
      l.sku === "PersonalPlus" ||
      l.sku === "PersonalPrime" ||
      l.sku === "CarboniteHomePlus" ||
      l.sku === "CarboniteHomePremier"
  );
}
