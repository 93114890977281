<template>
  <section class="constrain-65">
    <p class="text-grey">{{ tn("priceWarning") }}</p>
    <p v-if="props.showAutoRenewLinks">
      <button class="btn-link left-link" @click="goToHelpDeactivatingAutoRenewal()">
        {{ hasWebrootInCart ? tn("helpLinkDeactivatingAutoRenewalWebroot") : tn("helpLinkDeactivatingAutoRenewal") }}
      </button>
    </p>
    <p v-if="props.showAutoRenewLinks" class="text-grey">{{ tn("autoRenewDisclaimer") }}</p>
    <p class="text-grey">{{ tn("byContinuing") }}</p>
    <ul>
      <li class="mb-0">
        <button class="btn-link left-link" @click="goToTerms()">
          {{ hasWebrootInCart ? tn("helpLinkTermsOfServiceWebroot") : tn("helpLinkTermsOfService") }}
        </button>
      </li>
      <li>
        <button class="btn-link left-link" @click="goToPrivacy()">{{ tn("helpLinkPrivacyPolicy") }}</button>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { getUrl } from "@/common/getUrl";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";

const componentName = "BuyDisclaimersComponent";

logEvent("created", componentName);

const props = defineProps({
  showAutoRenewLinks: {
    type: Boolean,
    default: true,
    required: true,
  },
});

const buyFlowStore = useBuyFlowStore();
const hasWebrootInCart = buyFlowStore.hasWebrootCartItems();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function goToHelpDeactivatingAutoRenewal() {
  logEvent("goToHelpDeactivatingAutoRenewal");
  const link = hasWebrootInCart ? getUrl("BUY_DEACTIVATION_WEBROOT_URL") : getUrl("BUY_DEACTIVATION_URL");
  window.open(link, "_blank");
}

function goToTerms() {
  logEvent("goToTerms");
  const link = hasWebrootInCart ? getUrl("BUY_TERMS_WEBROOT_URL") : getUrl("BUY_TERMS_URL");
  window.open(link, "_blank");
}

function goToPrivacy() {
  logEvent("goToPrivacy");
  const link = getUrl("BUY_PRIVACY_URL");
  window.open(link, "_blank");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
