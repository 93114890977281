import { unifiedApi } from "@/common/index";
import { useUserStore } from "@/stores/user";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { IDeviceInfo } from "@/common/api/interfaces";

export async function getUserDetailsFromStore(forceRefresh: boolean) {
  try {
    const userStore = useUserStore();
    let userDetails = userStore.userDetails;
    if (!userDetails || forceRefresh) {
      userDetails = (await unifiedApi.getUserDetails()).data;
      userStore.userDetails = userDetails;
    }
    return userDetails;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

export function getDeviceInfo(deviceId: number): IDeviceInfo {
  const di: IDeviceInfo = { userId: 0, device: undefined };
  const userStore = useUserStore();
  di.userId = userStore.currentUser?.personId;
  const userDetails = userStore.userDetails;

  if (di.userId && userDetails) di.device = userDetails?.devices.find(d => d.deviceId == deviceId);
  return di;
}
