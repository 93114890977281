<template>
  <ModalWrapperComponent
    dialog-name="LastPassDialogComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn(title) }}
    </template>

    <template v-if="isSingleActivation" #content>
      <p>{{ tn("pleaseWait") }}.</p>
    </template>
    <template v-else #content>
      <div v-if="showSpinner && showDialog">
        <LoadingSpinner />
      </div>
      <div v-else>
        <p>{{ tn("unusedAccounts") }}</p>
        <ul class="mt">
          <li v-for="n in lastPassInfo" id="webrootSubscriptionsInfo" :key="n.keyCode">
            <div v-for="keyCode in splitStringByComma(n.keyCode)" :key="keyCode" class="content-row">
              <div class="plan-detail mr-half">
                <p>
                  {{ getLicenseDescription(n.licenseCategoryName) }}
                  <small v-if="checkIsKeycodeExpired(n.keyCode, true)" class="danger-text">
                    {{ t("DownloadsComponent.Expired") }}
                  </small>
                  <small v-else class="text-sm">({{ tn("expires") }} {{ getFormattedDate(n.expirationDate) }})</small>
                </p>
                <div v-if="n.seatsUsed > 0" class="mr-half">
                  <i18n-t keypath="LastPassDialogComponent.used" tag="strong" scope="global">
                    <template #qtd>{{ n.seatsUsed }}</template>
                    <template #total>{{ n.seatsUsed + n.seatsAvailable }}</template>
                  </i18n-t>
                </div>
                <div v-else class="mr-half">
                  <i18n-t keypath="LastPassDialogComponent.notActivated" tag="strong" scope="global">
                    <template #qtd>{{ n.seatsAvailable }}</template>
                  </i18n-t>
                </div>
                <div>
                  <button :id="`btnActivate${keyCode}`" class="inline-btn-link provision" :disabled="disableButtons">
                    <span v-if="keyCode === currentProvision && n.seatsUsed === 0" class="provisioning">
                      <spinner :is-spinning="true" class="provisioning-svg" />
                      {{ tn("activating") }}
                    </span>
                    <span v-else-if="keyCode === currentProvision && n.seatsUsed > 0" class="provisioning">
                      <spinner :is-spinning="true" class="provisioning-svg" />
                      {{ tn("creating") }}
                    </span>
                    <span
                      v-else-if="n.seatsUsed > 0 && n.pendingLastPassTokenUrl.length == 0"
                      @click="provision(keyCode)"
                    >
                      {{ tn("createNew") }}
                    </span>
                    <span
                      v-else-if="n.seatsUsed > 0 && n.pendingLastPassTokenUrl.length > 0"
                      @click="completeSignUp(n.pendingLastPassTokenUrl)"
                    >
                      {{ tn("finishCreate") }}
                    </span>
                    <span v-else @click="provision(keyCode)">{{ tn("activate") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>

    <template v-if="isSingleActivation" #footer>
      <div id="linksModalFooter" />
      <div id="buttonsModalFooter">
        <button id="btnCloseModal" type="button" class="btn-primary" :disabled="disableButtons" @click="save()">
          {{ tn("ok") }}
        </button>
      </div>
    </template>
    <template v-else #footer>
      <div class="opposite-ends">
        <div id="linksModalFooter">
          <a id="linkNeedHelp" :href="getUrl('LASTPASS_DETAILED_INSTRUCTIONS_URL')" class="mb-half" target="_blank">
            {{ tn("linkFooterNeedHelp") }}
            <svg-icon-component icon="external-link" class="pl-quarter" />
          </a>
        </div>
        <div id="buttonsModalFooter">
          <button id="btnCloseModal" type="button" class="btn-primary" :disabled="disableButtons" @click="close()">
            {{ tn("footerButtonCancel") }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ILastPassInfo } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { formatDateString } from "@/common/dateFormat";
import { getWebrootLicenseDescription } from "@/common/webrootProductName";
import { useNotificationsStore } from "@/stores/notifications";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { checkIsKeycodeExpired } from "@/components/Downloads/isKeycodeExpired";
import { getUrl } from "@/common/getUrl";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import Spinner from "@/components/shared/Spinner.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "LastPassDialogComponent";

const props = defineProps({
  operation: {
    type: String,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.operation);

const notificationsStore = useNotificationsStore();
const title = ref<string>("accountSetup");
const isSingleActivation = ref<boolean>(false);
const disableButtons = ref<boolean>(false);
const lastPassInfo = ref<ILastPassInfo[]>([]);
const currentProvision = ref<string>("");
const showSpinner = ref<boolean>(true);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function getFormattedDate(date: string): string {
  return formatDateString(date);
}

function getLicenseDescription(license: string): string | undefined {
  return getWebrootLicenseDescription(license);
}

function splitStringByComma(license) {
  return license.split(",");
}

async function provision(keycode: string) {
  if (disableButtons.value) {
    return;
  }

  logEvent("provision", componentName, keycode);
  notificationsStore.clearNotifications();

  disableButtons.value = true;
  currentProvision.value = keycode;

  try {
    const provisionResponse = (await unifiedApi.provisionLastPass(keycode)).data;
    if (provisionResponse) {
      const response = (await unifiedApi.getLastPassInfo()).data;
      if (response) {
        lastPassInfo.value = response;
        notificationsStore.addNotification({
          type: "LastPassProvisioned",
          params: { url: provisionResponse.signupUrl },
        });
      } else {
        logEvent(`GetLastPassInfo result NULL`, `${componentName}/getLastPassInfo`);
      }
    } else {
      logEvent(`provisionLastPass result NULL`, `${componentName}/provisionLastPass`);
    }
  } catch (error) {
    handleApiError(error as AxiosError, true, "LastPassError");
  } finally {
    disableButtons.value = false;
    currentProvision.value = "";
  }
}

function completeSignUp(url: string) {
  if (!disableButtons.value) {
    window.open(url);
  }
}

function close() {
  notificationsStore.clearNotifications();
  emits("close-modal");
}

function save() {
  emits("save-modal", "Ok");
}

async function beforeOpen() {
  showSpinner.value = true;
  try {
    const response = (await unifiedApi.getLastPassInfo()).data;
    if (response) {
      lastPassInfo.value = response;
      notificationsStore.clearNotifications();

      title.value = props.operation === "Add" ? "accountSetup" : "activateAccount";

      isSingleActivation.value = lastPassInfo.value.length === 1 && props.operation === "Activate";

      if (isSingleActivation.value) {
        await provision(lastPassInfo.value[0].keyCode);
        disableButtons.value = false;
      }

      logEvent("Before Open", componentName, lastPassInfo.value);
    } else {
      logEvent(`GetLastPassInfo result NULL`, `${componentName}/beforeOpen`);
    }
  } catch (error) {
    handleApiError(error as AxiosError, true, "LastPassError");
  }
  showSpinner.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

div section {
  padding-top: 0;
}
.content-row {
  display: block;
}
.keycode-detail {
  display: block;
  margin-left: var(--space-half);
  margin-top: var(--space-quarter);
}
.keycode-detail strong {
  margin-right: var(--space-half);
}
.btn-link a {
  color: blue; /* needs to be changed to a color from our pallette */
}
.provisioning {
  color: var(--danger);
  font-style: italic;
  background-color: white;
  border: none;
}
.provisioning-svg {
  fill: var(--danger);
}

@media (min-width: 600px) {
  .plan-detail {
    display: block;
    justify-content: space-between;
  }
  .plan-detail div {
    display: inline;
  }
  .keycode-detail {
    margin-top: 0;
  }
  .keycode-detail strong {
    display: inline-block;
    margin-right: var(--space-half);
  }
  .content-row {
    align-content: space-between;
  }
  footer {
    display: flex;
    justify-content: space-between;
  }
  footer a {
    margin-bottom: 0;
  }
}
</style>
