<template>
  <ModalWrapperComponent dialog-name="ApplyActivationCodeComponent" :show-dialog="showDialog" @close-modal="close">
    <template #header>
      {{ tn("headerH1") }}
    </template>

    <template #content>
      {{ tn("p1") }}
    </template>

    <template #footer>
      <button id="btnCancelDownload" type="button" class="btn-primary" @click="close()">
        {{ tn("continueBtn") }}
      </button>
      <button id="btnClose" type="button" class="inline-btn-link" @click="cancelDownload()">
        {{ tn("closeBtn") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { ICssbDownloadUrlRequest } from "@/components/FileAccess/interfaces";

const componentName = "CSSBCancelDownloadComponent";

const props = defineProps({
  request: {
    type: Object as PropType<ICssbDownloadUrlRequest>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function close() {
  emits("close-modal");
}

function cancelDownload() {
  emits("save-modal", props.request);
}
</script>
