import { FLAGS, URLS } from "@/define";
import router from "@/routes";
import { useUserStore } from "@/stores/user";
import { UserType } from "@/globalEnums";
import { propData } from "./commonFn";
import { getDeviceInfo } from "@/common/userDetails";
import {
  requestSessionKey as consumerRequestSessionKey,
  checkHealth as consumerCheckHealth,
  getComputer as consumerGetComputer,
  getComputerContents as consumerGetComputerContents,
  getSearchResults as consumerGetSearchResults,
  getSelectionInfo as consumerGetSelectionInfo,
  downloadFile as consumerDownloadFile,
  downloadImage as consumerDownloadImage,
  updateItemWithThumbnail as consumerUpdateItemWithThumbnail,
  getCollection as consumerGetCollection,
} from "./consumerServerComm";
import {
  checkHealth as consumerCheckHealthErso,
  getComputer as consumerGetComputerErso,
  getComputerContents as consumerGetComputerContentsErso,
  getSearchInfo as consumerGetSearchResultsErso,
  getSelectionInfo as consumerGetSelectionInfoErso,
  downloadFile as consumerDownloadFileErso,
  downloadImage as consumerDownloadImageErso,
  getCollection as consumerGetCollectionErso,
} from "./ersoServerComm";
import {
  requestSessionKey as cssbRequestSessionKey,
  checkHealth as cssbCheckHealth,
  getComputer as cssbGetComputer,
  getComputerContents as cssbGetComputerContents,
  getSearchResults as cssbGetSearchResults,
  getSelectionInfo as cssbGetSelectionInfo,
  downloadFile as cssbDownloadFile,
  downloadImage as cssbDownloadImage,
  updateItemWithThumbnail as cssbUpdateItemWithThumbnail,
  getCollection as cssbGetCollection,
} from "./cssbServerComm";
import {
  ICollectionParams,
  IComputerContents,
  ICssbDownloadInfoResponse,
  IFileDownload,
  IImageDownload,
  IItemCollection,
  IRfaComputer,
  IRfaItem,
  IRfaSelectionInfo,
  ISearchRequest,
  IThumbnailUpdate,
} from "./interfaces";
import { t } from "@/i18n";

let isCssbUser: boolean;

//these functions aren't really 'server communication' related, but
//they are different depending on isCssbUser
//perhaps they should be put into their own .ts file
export function getComputerName(): string {
  if (isCssbUser) return propData.value.computerId;
  else {
    let name = "";

    const di = getDeviceInfo(parseInt(propData.value.computerId));
    if (di.device) name = di.device.description || di.device.name;

    return name;
  }
}

export function close(): void {
  if (isCssbUser) {
    window.location.href = `${URLS.CP_ENDPOINT.protocol}://${URLS.CP_ENDPOINT.hostname}/smb/dashboard`;
  } else {
    router.push("/backup");
  }
}

export function getBackupName(): string {
  return isCssbUser ? t("SidebarComponent.smbBackupDashboard") : t("SidebarComponent.backup");
}

//these functions are required to be different depending on whether
//being called for a cssb user or a consumer since they will be calling into
//completely different APIs

export async function requestSessionKey(): Promise<void> {
  return isCssbUser ? await cssbRequestSessionKey() : await consumerRequestSessionKey();
}
export async function checkHealth(): Promise<boolean> {
  return isCssbUser
    ? await cssbCheckHealth()
    : FLAGS.ENABLE_ERSO === "true"
      ? await consumerCheckHealthErso()
      : await consumerCheckHealth();
}
export async function getComputer(): Promise<IRfaComputer | undefined> {
  return isCssbUser
    ? await cssbGetComputer()
    : FLAGS.ENABLE_ERSO === "true"
      ? await consumerGetComputerErso()
      : await consumerGetComputer();
}
export async function getComputerContents(cc: IComputerContents): Promise<IRfaItem[]> {
  return isCssbUser
    ? await cssbGetComputerContents(cc)
    : FLAGS.ENABLE_ERSO === "true"
      ? await consumerGetComputerContentsErso(cc)
      : await consumerGetComputerContents(cc);
}
export async function getSearchResults(sr: ISearchRequest): Promise<IRfaItem[]> {
  return isCssbUser
    ? await cssbGetSearchResults(sr)
    : FLAGS.ENABLE_ERSO === "true"
      ? await consumerGetSearchResultsErso(sr)
      : await consumerGetSearchResults(sr);
}
export async function getSelectionInfo(ic: IItemCollection): Promise<IRfaSelectionInfo | undefined> {
  return isCssbUser
    ? await cssbGetSelectionInfo(ic)
    : FLAGS.ENABLE_ERSO === "true"
      ? await consumerGetSelectionInfoErso(ic)
      : await consumerGetSelectionInfo(ic);
}
export async function downloadFile(fd: IFileDownload, ic?: IItemCollection, deviceTrustToken = ""): Promise<void> {
  if (isCssbUser) {
    await cssbDownloadFile(fd);
  } else {
    if (FLAGS.ENABLE_ERSO === "true") {
      await consumerDownloadFileErso(fd, ic, deviceTrustToken);
    } else {
      await consumerDownloadFile(fd, ic);
    }
  }
}
export async function downloadFileCssb(fd: IFileDownload): Promise<ICssbDownloadInfoResponse | undefined> {
  return await cssbDownloadFile(fd);
}
export async function downloadImage(img: IImageDownload): Promise<Blob | undefined> {
  return isCssbUser
    ? await cssbDownloadImage(img)
    : FLAGS.ENABLE_ERSO === "true"
      ? await consumerDownloadImageErso(img)
      : await consumerDownloadImage(img);
}
export function updateItemWithThumbnail(tu: IThumbnailUpdate): void {
  if (isCssbUser) {
    cssbUpdateItemWithThumbnail(tu);
  } else {
    consumerUpdateItemWithThumbnail(tu);
  }
}
export function getCollection(cp: ICollectionParams): IItemCollection {
  return isCssbUser
    ? cssbGetCollection(cp)
    : FLAGS.ENABLE_ERSO === "true"
      ? consumerGetCollectionErso(cp)
      : consumerGetCollection(cp);
}

export function initServerComm() {
  const userStore = useUserStore();
  isCssbUser = userStore.userType == UserType.smb;
}
