import { URLS } from "../define";
import { IUnifiedPortalAPI, UnifiedPortalAPI } from "./api/unifiedPortal";
import { useConfigStore } from "@/stores/config";

export const unifiedApi: IUnifiedPortalAPI = new UnifiedPortalAPI(URLS.UP_ENDPOINT);
// export const unifiedApi: IUnifiedPortalAPI = FLAGS.IS_DEBUG
//   ? new UnifiedPortalAPIMock(URLS.UP_ENDPOINT)
//   : new UnifiedPortalAPI(URLS.UP_ENDPOINT);

export const initialize = async () => {
  const store = useConfigStore();
  await store.getConfig();
};
