import { ref } from "vue";
import { logEvent } from "@/common/logger";
import {
  setCurrentPath,
  addLocalValues,
  getCurrentPath,
  allItems,
  componentName,
  propData,
  MIN_SPINNER_DISPLAY_COUNT,
  isLoading,
  fullPageErrorType,
  showFullPageError,
  isSmb,
} from "./commonFn";
import { clearSearchUI } from "./searchFn";
import { clearSelection } from "./selectionFn";
import { currentPage, currentPageSize } from "./paginationFn";
import { initSortOptions, sortDirection, sortField, sortItems } from "./sortFn";
import { getComputerContents, getComputer, close } from "./serverComm";
import { getCurrentItemImagesAndAlerts } from "./imagePreviewFn";
import { currentDirectory, computer, currentDisk, breadcrumbItems, previousDisk, selectedDisk } from "./diskFn";
import { FullPageErrorTypes } from "./FileAccessEnums";
import { selectView } from "./viewFn";
import { IComputerContents } from "./interfaces";
import { cssbDownloadError } from "./downloadFn";
//import { showEncryptedError } from "./downloadFn";

export const FIRST_BREADCRUMB_COMPONENT_LENGTH = 3;

export const hierarchyLevel = ref<number>(0);

//return to backup page
export function closeFileAccess() {
  logEvent(`user requests back to backup page`, componentName);
  close();
}

export function getFileAccessRoute(computerId: string | number, path?: string | null): string {
  return `/FileAccess?computerUid=${computerId}&path=${path ? path : ""}`;
}

export async function initDirectoryDisplay(path: string, name?: string, changeServer = false) {
  //Reset the CSSB error message when changing the selection
  cssbDownloadError.value = "";
  setCurrentPath(path);
  if (name) currentDirectory.value = name;
  //clear out searching and selecting done previously
  clearSearchUI();
  clearSelection();
  let contentsRequest = getComputerContentsRequest(path);
  //showEncryptedError.value = false;

  //Need to set the different items in propData if we are changing servers
  if (isSmb.value && changeServer) {
    propData.value.computerId = path;
    propData.value.path = path;
    propData.value.computerName = path;
    contentsRequest = {
      path: path,
      computerName: path,
    } as IComputerContents;
  }

  //ask for the new contents
  allItems.value = await getComputerContents(contentsRequest);
  currentPage.value = 0;
  const imageCount = addLocalValues();
  selectView(imageCount);

  if (!isSmb.value) {
    initSortOptions(isSmb.value, false);
    sortItems(sortField.value, sortDirection.value, true);
  }

  //update other non-sortable details
  await getCurrentItemImagesAndAlerts(0, currentPageSize.value);

  //if we found no items while loading the initial screen, show an error instead
  if (allItems.value.length === 0 && isLoading.value) {
    showFullPageError.value = true;
    fullPageErrorType.value = FullPageErrorTypes.noData;
  }
  //whether we show an error or not, we're done the loading process
  isLoading.value = false;
}

export async function navigateToProp() {
  logEvent(`path changed to ${propData.value.path} currently on ${getCurrentPath()}`, componentName);
  //if we're already at the current path location, do nothing
  if (propData.value.path == getCurrentPath()) return;

  let hasNavigated = false;
  let destinationFileId = 0;

  //parse the path
  const pathComponents = propData.value?.path ? propData.value.path.split("/") : [];
  //if we're still on the same computer
  if (propData.value.computerId == computer.value?.ComputerId.toString()) {
    //if the path contains only two elements, then we're asking to go to the top of the drive
    if (pathComponents?.length === FIRST_BREADCRUMB_COMPONENT_LENGTH - 1) {
      const disk = computer.value?.HardDisks.find(h => h.FileId == currentDisk.value);
      //if we're on the current drive
      if (disk && disk.Path == propData.value.path) {
        //go to the top
        destinationFileId = 0;
        hasNavigated = true;
      }

      //otherwise, search through current breadcrumbs for a destination
    } else if (pathComponents && pathComponents.length >= FIRST_BREADCRUMB_COMPONENT_LENGTH) {
      [hasNavigated, destinationFileId] = findDestinationInBreadcrumbs(pathComponents);
    }
  }
  if (hasNavigated) {
    navigateTo(destinationFileId, 0, false);
  }
  //if this is not a simple up/down the tree navigation
  else {
    let curPath = "";

    //if the user wants us to navigate to a different computer
    if (propData.value.computerId != computer.value?.ComputerId.toString()) {
      computer.value = await getComputer();
    }

    //if we're on the right computer
    if (computer.value) {
      curPath = await calcCurrentDisk(pathComponents ?? []);
    }

    //if the user wants to go deeper than the top level
    if ((pathComponents?.length ?? 0) >= FIRST_BREADCRUMB_COMPONENT_LENGTH) {
      curPath = await calcBreadcrumbs(pathComponents ?? []);
    }

    if (pathComponents && pathComponents.length > 0)
      await initDirectoryDisplay(curPath, pathComponents[pathComponents.length - 1]);
  }
}

function findDestinationInBreadcrumbs(pathComponents: string[]): [boolean, number] {
  let nav = false;
  let dest = 0;

  //combine the first three elements, as that will be the first breadcrumb
  const path0 = pathComponents.slice(0, FIRST_BREADCRUMB_COMPONENT_LENGTH).join("/");
  //take any remaining and keep them separate in the array
  const pathOther = pathComponents.slice(FIRST_BREADCRUMB_COMPONENT_LENGTH);
  const bciLength = breadcrumbItems.value.length;

  if (bciLength == 0) {
    //no breadcrumbs, we're currently at the drive level
    //if we're going one level down, then navigateTo()
    const directory = allItems.value.find(a => a.Path === propData.value.path);
    logEvent(`advancing one directory to ${propData.value.path} ${directory?.FileId}`, componentName);
    if (directory) {
      dest = directory.FileId;
      nav = true;
    }
  } else if (bciLength > 0 && breadcrumbItems.value[0].Path === path0) {
    //we've got breadcrumbs already, so lets follow them and see if the user wants to navigate somewhere in here
    let bciIndex = 1;
    let pathIndex = 0;
    let fileId = 0;
    while (
      bciIndex < bciLength &&
      pathIndex < pathOther.length &&
      breadcrumbItems.value[bciIndex].Path === `${path0}/${pathOther.slice(0, pathIndex + 1).join("/")}`
    ) {
      bciIndex++;
      pathIndex++;
    }
    fileId = breadcrumbItems.value[bciIndex - 1].FileId;
    //if we ran out of new path before running out of breadcrumbs, we're going back
    if (bciIndex < bciLength && pathIndex == pathOther.length) {
      dest = fileId;
      nav = true;
    }
    //if there's just one new path left and we're out of breadcrumbs, then we're going forward one
    else if (bciIndex == bciLength && pathIndex == pathOther.length - 1) {
      const path = `${path0}/${pathOther.slice(0, pathOther.length).join("/")}`;
      const directory = allItems.value.find(a => a.Path === path);
      logEvent(`advancing one directory to ${path} ${directory?.FileId}`, componentName);
      if (directory) {
        dest = directory.FileId;
        nav = true;
      }
    } else {
      logEvent(`bciIndex:${bciIndex} bciLength:${bciLength} pathIndex:${pathIndex} pathLength: ${pathOther.length}`);
    }
  }

  return [nav, dest];
}

export async function calcCurrentDisk(pathComponents: string[]): Promise<string> {
  let curPath = "";

  //figure out the disk the user wants to show
  const disk = computer.value?.HardDisks.find(
    h => h.Path == pathComponents.slice(0, FIRST_BREADCRUMB_COMPONENT_LENGTH - 1).join("/")
  );
  //if the disk is different from what we are showing now
  if (disk && currentDisk.value != disk.FileId) {
    //do it in this order to short circuit the watch on currentDisk
    previousDisk.value = disk.FileId;
    currentDisk.value = disk.FileId;
  }
  //go get the top level directory for the disk
  if (disk) {
    allItems.value = await getComputerContents(getComputerContentsRequest(disk.Path));
    currentPage.value = 0;
    curPath = disk.Path;
  }

  return curPath;
}

export async function calcBreadcrumbs(pathComponents: string[]): Promise<string> {
  let curPath = "";
  breadcrumbItems.value = [];

  //combine the first three elements, as that will be the first breadcrumb, add it to the list
  //encode each element to match what the server provides in the item's Path property
  const path0 = pathComponents
    .slice(0, FIRST_BREADCRUMB_COMPONENT_LENGTH)
    .map(p => encodeURIComponent(p))
    .join("/");
  const bc0 = allItems.value.find(a => a.Path == path0);
  if (bc0) breadcrumbItems.value.push(bc0);
  //take any remaining and keep them separate in the array
  const pathOther = pathComponents.slice(FIRST_BREADCRUMB_COMPONENT_LENGTH);

  curPath = path0;
  //recalculate the rest of the breadcrumbs
  for (const path of pathOther) {
    allItems.value = await getComputerContents(getComputerContentsRequest(curPath));
    curPath += `/${encodeURIComponent(path)}`;
    const bc = allItems.value.find(a => a.Path == curPath);
    if (bc) breadcrumbItems.value.push(bc);
  }
  currentPage.value = 0;

  return curPath;
}

//all items are identified by their fileId
//the template ensures that only directories can invoke this function
//It switches the view to show the contents of the selected directory.
//If passed 0, it means the user wants to go back to the root of the disk
export async function navigateTo(fileId: number, folderItemCount: number, updateRoute = true) {
  showFullPageError.value = false;
  logEvent("navigateTo", componentName, fileId);

  //this will contain the path to pass to the API to get us the data about the selection
  let path = "";
  let name = "";
  let item = allItems.value.find(i => i.FileId == fileId);

  if (fileId === 0) {
    //find the disk we're currently at
    const disk = computer.value?.HardDisks.find(h => h.FileId == currentDisk.value);
    if (disk) {
      //reset to base for the disk
      currentDirectory.value = disk.Name;
      breadcrumbItems.value = [];
      path = disk.Path;
      if (disk.IsBackupSet) {
        item = disk;
        path = item.Name;
        selectedDisk.value = item;
      }
    }
  } else {
    const index = breadcrumbItems.value.findIndex(b => b.FileId == fileId);
    //if the selection is a currently displayed item
    if (item) {
      //if the selection is NOT in the breadcrumb list
      if (index < 0) {
        //add to the breadcrumbs and remember the path
        currentDirectory.value = item.IsBackupRun ? new Date(item.ModifiedDate).toDateString() : item.Name;
        breadcrumbItems.value.push(item);
        path = item.Path;
      }
    } else {
      if (index >= 0) {
        //if this location is already on the breadcrumb list, remove all after it
        currentDirectory.value = breadcrumbItems.value[index].Name;
        breadcrumbItems.value.splice(index + 1);
        path = breadcrumbItems.value[index].Path;
        item = breadcrumbItems.value[index];
      }
    }
  }

  if (item && (item.IsBackupRun || item.IsBackupSet)) {
    path = item.Name;
    selectedDisk.value = item;
    name = item.IsBackupSet ? item.Name : new Date(item.ModifiedDate).toDateString();
  }

  //Sorting initialization
  if (isSmb.value) {
    if (item?.IsBackupSet) {
      hierarchyLevel.value = 1;
      initSortOptions(isSmb.value, true);
    } else if (item?.IsBackupRun) {
      hierarchyLevel.value = 2;
      initSortOptions(isSmb.value, false);
    } else {
      //this will be itemFromCurrentLevel?.IsDirectory == true.
      //Putting in 'else' block as defaulter.
      hierarchyLevel.value = 2;
      initSortOptions(isSmb.value, false);
    }
  }

  //if we have a path to go to
  if (path) {
    logEvent("navigateTo destination path", componentName, path);
    if (updateRoute && !isSmb.value) {
      history.pushState({ path: path }, "", getFileAccessRoute(propData.value.computerId, path));
    }
    if (folderItemCount > MIN_SPINNER_DISPLAY_COUNT) {
      isLoading.value = true;
    }
    //when navigating between pages, we need to reset to page 1 of the destination
    currentPage.value = 1;
    await initDirectoryDisplay(path, name);
  } else {
    logEvent("navigateTo finds no path", componentName);
  }
}

export function updateCurrentDisk(fileId: number) {
  if (currentDisk.value == fileId) {
    navigateTo(0, 0);
  } else currentDisk.value = fileId;
}

export function getComputerContentsRequest(path: string) {
  return {
    path: path,
    computerName: propData.value.computerName,
    currentDisk: selectedDisk.value,
  } as IComputerContents;
}
