<template>
  <div class="text-center mt-double">
    <div class="mb">
      <svg-icon-component icon="maintenance" class="icon-xxl" />
    </div>
    <h2 class="text-grey mb text-lg">{{ tn("pardonTheInconvenience") }}</h2>
    <p class="text-grey margin-auto mb-200 constrain-50">{{ tn("UnderMaintenanceP1") }}</p>
  </div>
</template>

<script setup lang="ts">
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "UnderMaintenanceComponent";

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
