<template>
  <div>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div v-else-if="countryCode" class="constrain-65">
      <h4 class="mb-half">{{ tn("label") }}</h4>
      <div v-if="editMode">
        <svg-icon-component icon="checkmark" class="icon-sm" />
        {{ vatNumber }}
        <button id="btnEditVat" class="btn-link mb-0" @click="toggleDisplayModal()">
          <svg-icon-component icon="edit" />
          {{ tn("edit") }}
        </button>
        <button id="btnRemoveVat" class="btn-link mb-0" @click="removeVatNumber()">
          <spinner :is-spinning="isRemoving" />
          <svg-icon-component v-if="!isRemoving" icon="delete" />
          {{ t("Common.remove") }}
        </button>
        <p class="success-text">
          {{ tn("vatIdVerified") }}
        </p>
      </div>
      <div v-else class="mb-space-and-a-half">
        <p>{{ tn("businessCustomers") }}</p>
        <button id="btnAddVat" class="btn-outline-primary" @click="toggleDisplayModal()">
          <svg-icon-component icon="circle-plus" />
          {{ tn("addVat") }}
        </button>
      </div>
    </div>
    <AddVatNumberComponent
      :show-dialog="displayModal"
      :vat-info="vatInfo"
      :is-from-buyflow="true"
      @close-modal="toggleDisplayModal"
      @save-modal="saveModalResponse"
    />
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { ref, onBeforeMount, watch } from "vue";
import { logEvent, logException } from "@/common/logger";
import { unifiedApi } from "@/common";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { ISubscriberVatInfo } from "@/common/api/unifiedPortal/interfaces";
import AddVatNumberComponent from "@/components/shared/dialogs/AddVatNumber/AddVatNumber.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useUserStore } from "@/stores/user";
import { useBuyFlowStore } from "@/stores/buyFlow";
import Spinner from "@/components/shared/Spinner.vue";
import { pageStates as buyFlowPageStates } from "@/components/Buy/BuyEnums";
import { googleTagCartEvents } from "@/common/googleTagEvents";

const componentName = "VatInputComponent";

logEvent("created", componentName);
const isLoading = ref<boolean>(false);
const vatNumber = ref<string>("");
const countryCode = ref<string>(""); // for non VAT countries, countryCode will be empty.
const vatInfo = ref<ISubscriberVatInfo>();
const displayModal = ref<boolean>(false);
const editMode = ref<boolean>(false);
const buyFlowStore = useBuyFlowStore();
const isRemoving = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  await loadVat();
});

async function loadVat() {
  try {
    // do not display if pageState is not review order
    if (buyFlowStore.pageState !== buyFlowPageStates.reviewOrder) {
      countryCode.value = "";
      return;
    }

    isLoading.value = true;
    const userStore = useUserStore();
    const paymentMethodId = buyFlowStore.cart?.paymentMethodId ?? null;
    if (userStore.currentUser) {
      vatInfo.value = (await unifiedApi.getConsumerVatInfoAsync(paymentMethodId)).data;
      if (vatInfo.value) {
        vatNumber.value = vatInfo.value?.vatNumber ?? "";
        editMode.value = vatNumber.value !== "";
        countryCode.value = vatInfo.value?.countryCode ?? "";
      }
    }
    isLoading.value = false;
  } catch (err) {
    logException(err as Error, componentName);
  }
}

function toggleDisplayModal() {
  displayModal.value = !displayModal.value;
}

async function removeVatNumber() {
  isRemoving.value = true;
  const vatInfoResponse = (await unifiedApi.setVatInfo("", countryCode.value)).data;
  if (vatInfoResponse.success) {
    vatNumber.value = "";
    buyFlowStore.updateShoppingCartTotal(false, false, googleTagCartEvents.change);
    editMode.value = false;
  }
  isRemoving.value = false;
}

function saveModalResponse(response, vatId) {
  toggleDisplayModal();
  if (response === "OK") {
    buyFlowStore.checkSalesTax = true;
    buyFlowStore.updateShoppingCartTotal();
    buyFlowStore.checkSalesTax = false;
    vatNumber.value = countryCode.value + vatId;
    editMode.value = true;
  }
}

watch(
  () => buyFlowStore.$state.pageState,
  async stateChanged => {
    if (stateChanged === buyFlowPageStates.reviewOrder) {
      await loadVat();
    } else {
      countryCode.value = "";
    }
  }
);
</script>
