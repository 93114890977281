<template>
  <i18n-t :keypath="'NotificationsComponent.' + props.notification.type" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <button
        id="finishEmailVerification"
        class="inline-btn-link"
        type="button"
        @click="openPendingEmailVerificationDialog()"
      >
        {{ tn("FinishEmailVerification") }}
      </button>
    </template>
  </i18n-t>
</template>
<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";

const componentName = "PendingEmailVerificationNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

const emits = defineEmits(["custom-notification-event"]);

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function openPendingEmailVerificationDialog() {
  emits("custom-notification-event", props.notification, "openPendingEmailVerification");
}
</script>
<style lang="css" scoped>
@import "@/styles/variables.css";
</style>
