<template>
  <div id="vueApp" :class="getSelectedLanguage()">
    <NotificationsComponent context="App" />
    <HeaderComponent :hide-titlebar="hideTitlebar" />
    <div class="grid-wrapper" :class="hideSidebar ? 'no-grid' : ''">
      <section class="main">
        <router-view />
        <FooterComponent class="no-print" :hide-footer="hideFooter" />
      </section>
      <SidebarComponent />
    </div>
    <AutoLogout :show-dialog="showAutoLogoutDialog" @close-modal="logoutUser" @save-modal="continueUserSession" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeMount } from "vue";
import { IRouteMetadata } from "@/routes";
import HeaderComponent from "@/components/shared/Header.vue";
import SidebarComponent from "@/components/shared/Sidebar.vue";
import FooterComponent from "@/components/shared/Footer.vue";
import { installPendo } from "@/common/pendo";
import { useUserStore } from "@/stores/user";
import AutoLogout from "@/components/shared/dialogs/AutoLogout/AutoLogout.vue";
import {
  AUTO_LOGOUT_INTERVAL_MSEC,
  MAX_SESSION_IDLE_DURATION,
  SESSION_CHECK_INTERVAL,
  PRELIMINARY_USER_KEEP_ALIVE_TIME_IN_MSEC,
  PRELIMINARY_USER_TOTAL_KEEP_ALIVE_TIME_IN_MINUTES,
  MSEC_IN_MINUTE,
} from "@/define";
import { logout } from "@/common/logout";
import { logEvent } from "@/common/logger";
import { useRoute } from "vue-router";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { isSupportedBrowser } from "@/common/browserDetect";
import { useNotificationsStore } from "@/stores/notifications";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import { unifiedApi } from "./common";
import { NotificationType } from "@/stores/notificationsInterfaces";
import { pageStates as buyFlowPageStates } from "@/components/Buy/BuyEnums";
import { changePageState } from "./components/Buy/BuyHelper";

const componentName = "App";

logEvent("created", componentName);
const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const route = useRoute();
const showAutoLogoutDialog = ref<boolean>(false);

const hideSidebar = computed(() => {
  const metadata: IRouteMetadata | undefined = route.meta;
  return metadata?.hideSidebar;
});

const hideTitlebar = computed(() => {
  const metadata: IRouteMetadata | undefined = route.meta;
  return metadata?.hideTitlebar;
});

const hideFooter = computed(() => {
  const metadata: IRouteMetadata | undefined = route.meta;
  return metadata?.hideFooter;
});

onBeforeMount(() => {
  installPendo();
  checkIfIsStillLogged();
});

onMounted(async () => {
  const result = await isSupportedBrowser();
  if (!result) {
    notificationsStore.addNotification({ type: "BrowserNotSupportedError", dismissible: false });
  }
});

async function toggleAutoLogoutModal(type: string | null) {
  type = type || "modal";
  logEvent("toggleAutoLogoutModal", componentName, `${showAutoLogoutDialog.value ? "Hiding" : "Showing"} via ${type}`);
  showAutoLogoutDialog.value = !showAutoLogoutDialog.value;
}

async function continueUserSession() {
  await reloadSubscriberInfo();
  toggleAutoLogoutModal("close");
}

function logoutUser() {
  toggleAutoLogoutModal("close");
  logout();
}

function getSelectedLanguage() {
  if (userStore.selectedLanguage === "") {
    userStore.selectedLanguage = "en";
  }
  return userStore.selectedLanguage;
}

// This will check if the user is logged in and his session is idle
// for more than XX minutes, then will logout the user
function checkIfIsStillLogged() {
  if (MAX_SESSION_IDLE_DURATION > 0) {
    window.setInterval(() => {
      if (userStore.currentUser) {
        const initialTime = userStore.lastRequestTime;
        const currentTime = new Date().getTime();
        if (initialTime > 0 && !showAutoLogoutDialog.value) {
          // If established Account, show modal after 30 min
          if (
            currentTime - initialTime > MAX_SESSION_IDLE_DURATION - AUTO_LOGOUT_INTERVAL_MSEC &&
            userStore.isEstablishedAccount
          ) {
            toggleAutoLogoutModal("open");
          } else if (
            // If preliminary Account, keep connection alive every 5 min up to 120 min.
            // TODO: Change interval to 29 min, after finding out on why API session is expiring in 10 min.
            currentTime - initialTime > PRELIMINARY_USER_KEEP_ALIVE_TIME_IN_MSEC &&
            !userStore.isEstablishedAccount
          ) {
            if (userStore.preliminaryKeepAliveTimeInMinutes < PRELIMINARY_USER_TOTAL_KEEP_ALIVE_TIME_IN_MINUTES) {
              //Refresh the session
              unifiedApi.getUser(true);
              //Add time to the keep alive timer
              userStore.preliminaryKeepAliveTimeInMinutes += PRELIMINARY_USER_KEEP_ALIVE_TIME_IN_MSEC / MSEC_IN_MINUTE;
              userStore.lastRequestTime = new Date().getTime();
            } else {
              //Reset the time back to 0
              userStore.preliminaryKeepAliveTimeInMinutes = 0;
              //Clear the current user but not the userStore email. Email is used to pre-populate the email input field
              userStore.currentUser = null;
              //If the user is on the order confirmation page
              if (route.name === "orderConfirmation") {
                notificationsStore.addNotification({
                  type: "PreliminaryUserOrderConfirmationTimeOut" as NotificationType,
                  dismissible: false,
                });
              } else {
                //If the user is still in the buy flow
                notificationsStore.addNotification({
                  type: "PreliminaryUserTimeOut" as NotificationType,
                  dismissible: false,
                });
              }
              userStore.preliminaryUserTimedOut = true;
              //Go to the email step and don't clear notifications
              changePageState(buyFlowPageStates.email, false);
            }
          }
        }
      }
    }, SESSION_CHECK_INTERVAL);
  }
}
</script>
