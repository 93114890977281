<template>
  <section class="card">
    <header>
      <h3>
        <svg-icon-component icon="icon-allstate" class="icon-md icon-relative-move" />
        {{ t("Brand.allstate") }} {{ t("Brand.identityProtection") }}
      </h3>
    </header>
    <article>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('nextStepsAllstate')"></p>
    </article>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "WebrootNextStepsAllstate";
const baseComponentName = "NextSteps";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}
</script>
