<template>
  <ModalWrapperComponent dialog-name="PreferredEmailLanguageComponent" :show-dialog="showDialog" @close-modal="close">
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul class="form">
        <li>
          {{ tn("communicationEmailP1") }}
        </li>
        <li class="language">
          <LanguageDropDown :selected-language="preferredLanguage" @set-selected-language="setPreferredLanguage" />
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link" @click="close()">{{ tn("cancel") }}</button>
      <button id="btnSaveChanges" type="submit" class="btn-primary" @click="savePreferredLanguage()">
        <spinner :is-spinning="isSaving" />
        {{ tn("save") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import LanguageDropDown from "@/components/shared/LanguageDropDown/LanguageDropDown.vue";
import { handleApiError } from "@/common/handleApiError";
import { ISetLanguagePreferenceRequest } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { getUserDetailsFromStore } from "@/common/userDetails";
import { useNotificationsStore } from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { AxiosError } from "axios";

const componentName = "PreferredEmailLanguageComponent";
const isSaving = ref<boolean>(false);
logEvent("created", componentName);
const userStore = useUserStore();
defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const userDetails = computed(() => {
  return userStore.userDetails;
});
const emits = defineEmits(["close-modal"]);
const notificationsStore = useNotificationsStore();
const preferredLanguage = ref<string>(userDetails.value?.languageCode || "en");

function setPreferredLanguage(lang: string) {
  preferredLanguage.value = lang;
}

function close() {
  emits("close-modal");
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function savePreferredLanguage() {
  isSaving.value = true;
  const request: ISetLanguagePreferenceRequest = {
    languageCode: !preferredLanguage.value ? "en" : preferredLanguage.value,
  };
  try {
    //Save the language
    await unifiedApi.setLanguagePreference(request);
    await getUserDetailsFromStore(true);
    notificationsStore.addNotification({ type: "UpdateEmailLanguagePreferenceSuccess", dismissible: false });
    close();
    isSaving.value = false;
  } catch (error) {
    handleApiError(error as AxiosError, true);
    isSaving.value = false;
  }
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
/* ::v-deep is an interpreted vue construct that helps to apply styles to child components
 As stated in deep selectors section in https://vuejs.org/api/sfc-css-features.html#scoped-css  */
.language ::v-deep(.select-css) {
  width: 100%;
}
</style>
