<template>
  <li id="currentCityStatePostalCode">
    {{
      `${props.paymentMethod.address.cityName}, ${props.paymentMethod.address.stateOrProvince} ${props.paymentMethod.address.postalCode}`
    }}
  </li>
  <li id="currentCountry">{{ props.paymentMethod.address.country }}</li>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { IPaymentMethod } from "@/common/api/unifiedPortal/interfaces";
import { logEvent } from "@/common/logger";
const componentName = "SelectPaymentAddress";

logEvent(componentName);

const props = defineProps({
  paymentMethod: {
    type: Object as PropType<IPaymentMethod>,
    required: true,
  },
});
</script>
