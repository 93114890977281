<template>
  <i18n-t :keypath="'NotificationsComponent.RFA_ComputerHasPrivateKey'" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <a id="linkToRfaSupport" :href="getUrl('RFA_ENCRYPTED_SUPPORT')" target="_blank">
        {{ t("NotificationsComponent.ClickHere") }}.
      </a>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";

const componentName = "RfaEncryptedNotification";

logEvent("created", componentName);
</script>
