<template>
  <div>
    <section v-if="componentState === applyFloatingSubscriptionComponentState.initial">
      <div>
        <i18n-t keypath="UpgradeSubscriptionComponent.youHaveUnusedH1" tag="h2" class="h1" scope="global">
          <template #qty>
            {{ floatingSubQty }}
          </template>
        </i18n-t>
        <i18n-t keypath="UpgradeSubscriptionComponent.youHaveUnusedP" tag="p" scope="global">
          <template #qty>
            {{ floatingSubQty }}
          </template>
        </i18n-t>
        <p>{{ t("UpgradeSubscriptionComponent.wouldYouLikeToApplyNow") }}</p>
        <ul class="form">
          <li class="radio">
            <input id="radioInitialYes" v-model="initialOption" type="radio" name="initialOption" value="yes" />
            <label for="radioInitialYes">{{ t("UpgradeSubscriptionComponent.yesApply") }}</label>
          </li>
          <li class="radio">
            <input id="radioInitialNo" v-model="initialOption" type="radio" name="initialOption" value="no" />
            <label for="radioInitialNo">{{ t("UpgradeSubscriptionComponent.noPurchase") }}</label>
          </li>
        </ul>
      </div>
      <footer class="mb">
        <button
          id="btnInitialContinue"
          type="button"
          class="btn-primary"
          :disabled="isNextButtonDisabled"
          @click="nextPage"
        >
          {{ t("UpgradeSubscriptionComponent.continue") }}
        </button>
      </footer>
    </section>
    <section v-if="componentState === applyFloatingSubscriptionComponentState.selection">
      <h3 class="mb-half">
        {{ t("UpgradeSubscriptionComponent.selectAC") }}
        <button id="btnAddAnotherCode" class="btn-link" @click="toggleApplyActivationCodeModal('click')">
          {{ t("UpgradeSubscriptionComponent.addAnotherCode") }}
        </button>
      </h3>
      <ul class="form">
        <li v-for="d in visibleDevices" :key="d.activationCode" class="radio">
          <input
            :id="`radioAC${d.activationCode}`"
            v-model="selectedAC"
            type="radio"
            name="radioAC"
            :value="d.activationCode"
          />
          <label :for="`radioAC${d.activationCode}`">
            <span v-if="d.productName">
              {{ t(d.productName) }}
              <br />
            </span>
            {{ d.activationCode }}
          </label>
        </li>
      </ul>
      <h3 class="mb-half">{{ t("UpgradeSubscriptionComponent.chooseUse") }}</h3>
      <ul class="form">
        <li v-if="showDevicesToApply" class="radio">
          <input
            id="radioActionApplyToExisting"
            v-model="selectedAction"
            type="radio"
            name="radioAction"
            value="existing"
          />
          <label for="radioActionApplyToExisting">
            {{ t("UpgradeSubscriptionComponent.applyToExistingComputer") }}
          </label>
        </li>
        <li v-if="showDevicesToApply">
          <select id="selectApplyExistent" v-model="selectedExistentDeviceId" class="select-css">
            <option v-for="d in devicesEnableToApplyCode" :key="d.activationCode" :value="d.deviceId">
              {{ d.name }}
            </option>
          </select>
        </li>
        <li class="radio">
          <input id="radioActionApplyToThis" v-model="selectedAction" type="radio" name="radioAction" value="install" />
          <label for="radioActionApplyToThis">
            {{ t("UpgradeSubscriptionComponent.applyToThisComputerAndInstall") }}
          </label>
        </li>
      </ul>
      <footer class="mb">
        <button id="btnSelectionCancel" type="button" class="btn-link" @click="cancel">
          {{ t("UpgradeSubscriptionComponent.cancel") }}
        </button>
        <button
          id="btnSelectionNext"
          type="button"
          class="btn-primary"
          :disabled="isNextButtonDisabled || isAdvancing"
          @click="nextPage"
        >
          <spinner :is-spinning="isAdvancing" />
          {{ t("UpgradeSubscriptionComponent.next") }}
        </button>
      </footer>
    </section>
    <ApplyActivationCodeComponent
      :show-dialog="showApplyActivationCodeModal"
      origin="backupTab"
      @close-modal="toggleApplyActivationCodeModal"
      @save-modal="addCode"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, PropType, watch } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice, IActivationCodeDialogResponse } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { useNotificationsStore } from "@/stores/notifications";
import { getProductDisplayNameBySku, IProductDisplayRequest } from "@/common/productsBySku";
import Spinner from "@/components/shared/Spinner.vue";
import ApplyActivationCodeComponent from "@/components/shared/dialogs/ApplyActivationCode/ApplyActivationCode.vue";

enum applyFloatingSubscriptionComponentState {
  initial,
  selection,
}

const name = "ApplyFloatingSubscriptionComponent";

const props = defineProps({
  devices: {
    type: Array as PropType<IDevice[]>,
    default: Array as PropType<IDevice[]>,
  },
  userId: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(["buy-new", "ac-applied", "install", "cancel"]);

logEvent("created", name);

const notificationsStore = useNotificationsStore();
const isAdvancing = ref<boolean>(false);
const componentState = ref<applyFloatingSubscriptionComponentState>(applyFloatingSubscriptionComponentState.initial);
const visibleDevices = ref<IDevice[]>([]);

const floatingSubQty = ref<number>(0);
const initialOption = ref<string>("");

const showDevicesToApply = ref<boolean>(false);
const devicesEnableToApplyCode = ref<IDevice[]>([]);
const selectedAC = ref<string>("");
const selectedExistentDeviceId = ref<number>(0);
const selectedAction = ref<string>("");
const showApplyActivationCodeModal = ref<boolean>(false);

const codeAdded = ref<boolean>(false);

visibleDevices.value = props.devices.filter(c => c.deviceId === 0);

visibleDevices.value.forEach(item => {
  const request: IProductDisplayRequest = {
    sku: item.sku,
    webrootKeycode: item.webrootKeycode,
    isTrial: item.isTrial,
  };

  item.productName = getProductDisplayNameBySku(request);
});

floatingSubQty.value = visibleDevices.value.length;

function toggleApplyActivationCodeModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleApplyActivationCodeModal",
    name,
    `${showApplyActivationCodeModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  return (showApplyActivationCodeModal.value = !showApplyActivationCodeModal.value);
}

async function nextPage() {
  logEvent("nextPage", name, `Component state: ${componentState.value}. Initial Option: ${initialOption.value}`);

  isAdvancing.value = true;

  if (componentState.value === applyFloatingSubscriptionComponentState.initial) {
    if (initialOption.value === "yes") {
      componentState.value = applyFloatingSubscriptionComponentState.selection;

      if (visibleDevices.value.length === 1) {
        selectedAC.value = visibleDevices.value[0].activationCode;
      }
    } else {
      emits("buy-new");
    }
  } else if (
    componentState.value === applyFloatingSubscriptionComponentState.selection &&
    selectedAC.value.length > 0
  ) {
    if (selectedAction.value === "existing") {
      try {
        await unifiedApi.applyActivationCode(selectedAC.value, selectedExistentDeviceId.value);
        logEvent("ac-applied", name, true);
        emits("ac-applied", true);
      } catch {
        logEvent("ac-applied", name, false);
        emits("ac-applied", false);
      }
    } else if (selectedAction.value === "install") {
      try {
        const selectedDevice = visibleDevices.value.find(c => c.activationCode === selectedAC.value);
        if (codeAdded.value) {
          await unifiedApi.applyActivationCode(selectedAC.value, 0);
        }

        logEvent("install", name, `Selected Device: ${selectedDevice}`);
        emits("install", selectedDevice, codeAdded.value);
      } catch {
        notificationsStore.addNotification({ type: "ActivationCodeAppliedFailed" });
        logEvent("ac-applied", name, "Failed");
      }
    }
  }
  isAdvancing.value = false;
}

function cancel() {
  emits("cancel");
}

const isNextButtonDisabled = computed(() => {
  return (
    (componentState.value === applyFloatingSubscriptionComponentState.initial && initialOption.value === "") ||
    (componentState.value === applyFloatingSubscriptionComponentState.selection &&
      ((selectedAction.value === "existing" && selectedExistentDeviceId.value === 0) || selectedAction.value === ""))
  );
});

async function addCode(result: IActivationCodeDialogResponse) {
  try {
    if (!result.activationCode) {
      throw "";
    }

    // check if code is valid
    const response = (await unifiedApi.verifyActivationCode(result.activationCode)).data;

    // display code
    const newDevice = createNewDevice();
    newDevice.activationCode = result.activationCode;
    visibleDevices.value = [newDevice];

    // display computers that can be assigned
    devicesEnableToApplyCode.value = [];
    logEvent(
      "addCode",
      name,
      `Computers: ${JSON.stringify(response.computers)}, Devices: ${JSON.stringify(props.devices)}`
    );

    for (const computer of response.computers) {
      const device = props.devices.find(d => d.deviceId === computer.id);
      if (device) {
        devicesEnableToApplyCode.value.push(device);
      }
    }

    selectedAC.value = result.activationCode;
    codeAdded.value = true;

    logEvent(
      "addCode",
      name,
      `Devices to apply AC to: ${JSON.stringify(devicesEnableToApplyCode.value)}, Selected AC: ${JSON.stringify(
        selectedAC.value
      )}`
    );
    toggleApplyActivationCodeModal("addCode");
  } catch (err) {
    notificationsStore.addNotification({ type: "InvalidActivationCode" });
    logException(err as Error);
  }
}

function isSafeLite(device: IDevice | undefined) {
  return !!device && device.sku.toLowerCase() === "personalbasic" && !device.isTrial && !!device.webrootKeycode;
}

watch(selectedAC, () => {
  const selectedACPlan = props.devices.find(d => d.activationCode === selectedAC.value);

  if (!codeAdded.value) {
    devicesEnableToApplyCode.value = props.devices.filter(
      d =>
        d.deviceId > 0 &&
        (d.isTrial ||
          (d.sku === selectedACPlan?.sku &&
            d.activationCode !== selectedACPlan?.activationCode &&
            !d.isEvergreenMonthly &&
            !d.autoRenewInfo &&
            d.daysRemaining <= 0))
    );
  }

  showDevicesToApply.value = !isSafeLite(selectedACPlan) && devicesEnableToApplyCode.value.length > 0;
  if (!showDevicesToApply.value) {
    selectedAction.value = "install";
  }
});

watch(selectedExistentDeviceId, () => {
  selectedAction.value = "existing";
});

function createNewDevice() {
  return {
    deviceId: 0,
    name: "",
    description: "",
    productLevel: "",
    productName: "",
    productType: "",
    platform: "",
    sku: "",
    isTrial: false,
    activationCode: "",
    hasStackedActivationCodes: false,
    subscriptionMonths: 0,
    subscriptionDays: 0,
    expirationDate: "",
    daysRemaining: 0,
    fileCount: 0,
    fileSizeMB: 0,
    pendingFileSizeMB: 0,
    lastBackupTime: "",
    status: "",
    alerts: [],
    alertsCount: 0,
    source: "",
    canUpgrade: false,
    canRenew: false,
    resellerCanAutoRenew: false,
    autoRenewInfo: undefined,
    osVersion: "",
    hasMirrorImageBackup: false,
    zuoraSubscriptionNumber: "",
    zuoraSubscriptionStatus: "",
    distyName: "",
    hasOutstandingInvoice: false,
    isEvergreenMonthly: false,
    evergreenNextBillingDate: undefined,
    webrootKeycode: "",
    hasFailedWebrootProvisioning: false,
    dataHasBeenPurged: false,
    isResellerManaged: false,
    isPartnerReferral: false,
    hasSSLKey: true,
  };
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
