<template>
  <span>{{ t(`NotificationsComponent.${notification.type}`, { renewalDiscount: notificationMessage }) }}</span>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";
import { AutoRenewOneTimeDiscount } from "@/define";

const componentName = "AutoRenewDiscountEnabledNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    required: true,
  },
});

logEvent("created", componentName);

const notificationMessage = ref<number>(props.notification?.params?.renewalDiscount ?? AutoRenewOneTimeDiscount);
</script>
