<template>
  <ModalWrapperComponent
    dialog-name="SetDeviceNicknameComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>

    <template #content>
      <ul class="form">
        <li>
          <div class="label-wrapper">
            <label for="device-nickname">{{ tn("nicknameLabel") }}</label>
          </div>
          <InputErrorComponent :show="!!validationError">
            <input id="device-nickname" v-model="newNickname" type="text" autocomplete="off" />
            <template #error>
              <div>
                <span>{{ validationError }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
      </ul>
    </template>

    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button id="btnSaveDeviceName" class="btn-primary" :disabled="!!validationError" @click="save()">
        <spinner :is-spinning="isUpdating" />
        {{ tn("footerButtonSave") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { reportStringErrors } from "@/common/validator";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { MAX_NICKNAME_LENGTH } from "@/define";
import { webRootDeviceNickNameRegex } from "@/globalRegex";

const componentName = "SetDeviceNicknameComponent";

const props = defineProps({
  nickname: {
    type: String,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName);
const newNickname = ref<string>("");
const isUpdating = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const validationError = computed(() => {
  return reportStringErrors(newNickname.value, tn("nicknameLabel"), 1, MAX_NICKNAME_LENGTH, webRootDeviceNickNameRegex);
});

function save() {
  isUpdating.value = true;
  emits("save-modal", newNickname.value);
}

function close() {
  emits("close-modal");
}

function beforeOpen() {
  isUpdating.value = false;
  newNickname.value = props.nickname;
}
</script>
