<template>
  <article class="main pa-desktop constrain-90 m-auto px-half">
    <h1 class="text-grey">{{ tn("headerH1") }}</h1>

    <h2 class="h1 mb">{{ t("Common.windows11") }}</h2>
    <p>{{ tn("howToTellWhich") }} {{ t("Common.windows11") }}</p>
    <ol>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <li v-html="tn('win11olLi1')"></li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <li v-html="tn('win11olLi2')"></li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <li v-html="tn('win11olLi3')"></li>
    </ol>
    <div class="mb-double">
      <img src="@/assets/img/ARMwin11.png" alt="Windows 11 Settings screenshot" />
    </div>
    <h2 class="h1 mb">{{ t("Common.windows10") }}</h2>
    <p>{{ tn("howToTellWhich") }} {{ t("Common.windows10") }}</p>
    <ol>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <li v-html="tn('win10olLi1')"></li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <li v-html="tn('win10olLi2')"></li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <li v-html="tn('win10olLi3')"></li>
    </ol>
    <div class="mb-double">
      <img src="@/assets/img/ARMwin10.png" alt="Windows 10 Settings screenshot" />
    </div>
  </article>
</template>

<script setup lang="ts">
import { t } from "@/i18n";

const componentName = "WindowsArmInfo";

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
