import { useUserStore } from "@/stores/user";

export function showPaymentHistory(): boolean {
  const userStore = useUserStore();
  const info = userStore.currentUser?.subscriberInfo;

  const hasSafeSub = !!info?.hasSafeSubscriptions;
  const hasECommWebroot = !!info?.hasEcommKeyCodes;
  const hasSafeWebroot = !!info?.hasSafeWebroot;
  const hasWsacRetail = !!info?.hasWsacRetail;

  if (hasECommWebroot && !hasSafeWebroot && !hasSafeSub && !hasWsacRetail) {
    return false;
  }

  const hasResellerUId = !!info?.managingResellerID && info.managingResellerID > 0;
  const hasOnlyResellerManagedDevice = !!info?.hasOnlyResellerComputers;
  const hasPartnerReferralDevice = !!info?.isPartnerReferral;
  const hasDirectACDevice = !!(!info?.hasOnlyResellerComputers && hasSafeSub);

  if (
    !hasECommWebroot &&
    hasOnlyResellerManagedDevice &&
    !hasPartnerReferralDevice &&
    !hasDirectACDevice &&
    hasResellerUId
  ) {
    return false;
  }
  return true;
}
