import { IPaymentMethod } from "@/common/api/unifiedPortal/interfaces";
import { loadPaymentMethods } from "@/components/Buy/BuyHelper";
import { UNITED_STATES } from "@/define";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { pageState as backupPageState } from "@/globalEnums";

export const useBackupPageStore = defineStore("backupPage", () => {
  const creditCards = ref<IPaymentMethod[]>([]);
  const pageState = ref<backupPageState>(backupPageState.default);

  async function populateCreditCards() {
    //Attempt to load Payments, but we don't want the page to break if this fails
    try {
      creditCards.value = (await loadPaymentMethods(true, true)) || [];
    } catch {
      creditCards.value = [];
    }
  }

  const isInternationalAddress = computed(() => {
    const defaultCC = creditCards.value.find(l => l.defaultPaymentMethod);
    if (defaultCC && defaultCC.address) {
      return defaultCC?.address.country !== UNITED_STATES;
    }

    return false;
  });

  return {
    pageState,
    creditCards,
    populateCreditCards,
    isInternationalAddress,
  };
});
