import { defineStore } from "pinia";
import { ref } from "vue";
import { emailPageStates } from "@/components/Buy/BuyEnums";
import { IUserAccountCreationResponse } from "@/common/api/unifiedPortal/interfaces";

export const useEmailPageStore = defineStore("emailPage", () => {
  const pageState = ref<emailPageStates>(emailPageStates.enterEmail);
  const errorResponseMessage = ref("");
  const isSmbEmail = ref(false);
  const createAccountResponse = ref<IUserAccountCreationResponse>();
  const errorResponseStatus = ref<number>();
  const isCreatingAccount = ref(false);

  function reset() {
    //Clear the previous response
    createAccountResponse.value = undefined;
    //Clear the error states in store
    errorResponseMessage.value = "";
    errorResponseStatus.value = undefined;
    isSmbEmail.value = false;
  }

  return {
    pageState,
    errorResponseMessage,
    isSmbEmail,
    createAccountResponse,
    errorResponseStatus,
    isCreatingAccount,
    reset,
  };
});
