import axios from "axios";
import { axiosTransformJSON5 } from "@/common/helper";
import { IZuoraFormConfig, ICaptchaConfig, IPendoConfig, IFooterLinksConfig } from "./api/interfaces";

export interface IConfig {
  zuoraForm: IZuoraFormConfig;
  captcha: ICaptchaConfig;
  pendo: IPendoConfig;
  footerLinks: IFooterLinksConfig;
}
const origin = window.location.origin.toLowerCase();

export default function (): Promise<IConfig> {
  return axios({
    method: "get",
    url: `${origin}/config/config.json`,
    transformResponse: axiosTransformJSON5,
  }).then(res => {
    return res.data;
  });
}
