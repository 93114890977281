<template>
  <i18n-t :keypath="'NotificationsComponent.' + props.notification.type" tag="span" class="pl-quarter" scope="global">
    <template #url>
      <button class="inline-btn-link" target="_blank" @click="goToUrl()">
        {{ t("LastPassDialogComponent.clickHere") }}
      </button>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";

const componentName = "LastPassProvisionSuccessComponent";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    required: true,
  },
});

logEvent("created", componentName);

function goToUrl() {
  if (props.notification.params?.url) window.location.href = props.notification.params.url;
}
</script>
