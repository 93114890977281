<template>
  <div :class="classes">
    <!--input goes here -->
    <slot></slot>
    <div v-if="props.show" class="icon-text-sidebyside">
      <slot name="icon">
        <svg-icon-component icon="circle-exclamation" />
      </slot>
      <slot name="error">Error</slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "InputErrorComponent";

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
  danger: {
    type: Boolean,
    required: false,
    default: true,
  },
});

logEvent("created", componentName);

const classes = computed(() => {
  return {
    validation: props.show,
    danger: props.danger,
  };
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
