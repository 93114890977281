<template>
  <section class="card mb-double">
    <header>
      <h3>
        <svg-icon-component icon="webroot-wifi-security" class="icon-md icon-relative-move" />
        {{ t("Brand.WebrootSecureVPN") }}
      </h3>
    </header>
    <article>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('WIFIp1')"></p>
      <ol class="form tight">
        <li>
          <button id="btnCreateSecurityAccount" type="button" class="inline-btn-link" @click="createSecurityAccount()">
            {{ tn("WIFIol1btn") }}
          </button>
          {{ tn("WIFIol1") }}
        </li>
        <li>{{ tn("WIFIol2") }}</li>
        <i18n-t id="testLine1" keypath="WebrootDownloadInstructions.WIFIol3" tag="li" scope="global">
          <template #downloadsBtn>
            <button type="button" class="inline-btn-link" @click="goToDownloads()">{{ tn("WIFIol3btn") }}</button>
          </template>
        </i18n-t>
      </ol>
      <p>
        <strong>{{ tn("forRenewals") }}</strong>
        {{ t("NextSteps.nextStepsRenewUpgradeP1") }}
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="tn('WIFIp3')"></p>
    </article>
  </section>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import router from "@/routes";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { WEBROOT_SECURE_VPN } from "@/define";

const componentName = "WebrootSecureVPN";
const baseComponentName = "WebrootDownloadInstructions";
logEvent("created", componentName);

const orderHeaderToken = ref<string>("");
const buyFlowStore = useBuyFlowStore();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

onBeforeMount(() => {
  var orderHeaderTokenValue = buyFlowStore.PurchaseKeycodes.find(n => n.sku == WEBROOT_SECURE_VPN)?.orderHeaderToken;
  if (orderHeaderTokenValue) {
    orderHeaderToken.value = orderHeaderTokenValue;
  }
});

// TODO must put the order_token at the end of this url - needs a new endpoint
function createSecurityAccount() {
  window.open(
    `https://www.webroot.com/us/en/home/products/wifi-security?order_token=${orderHeaderToken.value}`,
    "_blank"
  );
}

function goToDownloads() {
  router.push("/Downloads");
}
</script>
