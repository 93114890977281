<template>
  <div v-if="productName === 'SAFE'">
    <div>
      <ul v-if="unsupported" class="form">
        <li class="danger-text">
          {{ t(`${parentComponentName}.SAFEUnsupported`) }}
        </li>
      </ul>
      <ol v-else class="form">
        <li>
          {{ t(`${parentComponentName}.SAFEStepOne`) }}
        </li>
        <li>
          <div class="mb">{{ t(`${parentComponentName}.SAFEStepTwo`) }}</div>
          <div>
            <button
              id="btnDownload"
              class="btn-primary"
              type="button"
              :disabled="isDownloading"
              @click="downloadSafe()"
            >
              <spinner :is-spinning="isDownloading" />
              {{ t(`${parentComponentName}.btnDownload`) }}
            </button>
          </div>
          <p v-show="isWindowsOutDate" class="mt">
            <i18n-t keypath="DownloadSafeComponent.sha1Message" tag="span" class="account-availability" scope="global">
              <template #download>
                <strong>{{ t("DownloadSafeComponent.downloadingOnWindows") }}</strong>
              </template>
              <template #moreDetails>
                <a :href="getUrl('VITE_APP_SAFE_SHA1_MORE_DETAILS_URL')" target="_blank">
                  {{ t("DownloadSafeComponent.moreDetails") }}
                </a>
              </template>
            </i18n-t>
          </p>
        </li>
        <li v-if="installType === 'Reinstall'">
          {{ t(`${parentComponentName}.SAFEStepThreeReinstall`) }}
        </li>
        <li v-else>
          {{ t(`${parentComponentName}.SAFEStepThree`) }}
        </li>
      </ol>
      <p>{{ t(`${parentComponentName}.timeDelayWarning`) }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useNotificationsStore } from "@/stores/notifications";
import { isOutDatedWindows, isSafari } from "@/common/browserDetect";
import { GetSafeClient, downloadClient, downloadClientWithRedirect } from "@/common/safeClient";
import Spinner from "@/components/shared/Spinner.vue";
import { getUrl } from "@/common/getUrl";

const componentName = "SafeProductComponent";
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  productName: {
    type: String,
    default: "SAFE",
  },
  activationCode: {
    type: String,
    default: null,
  },
  deviceId: {
    type: Number,
    default: null,
  },
  installType: {
    type: String,
    default: null,
  },
  unsupportedOS: {
    type: Boolean,
    default: false,
  },
});

logEvent("created", componentName, props);
const notificationsStore = useNotificationsStore();
const isDownloading = ref<boolean>(false);
const unsupported = ref<boolean>(false);

const isWindowsOutDate = computed(() => {
  return isOutDatedWindows();
});

onMounted(() => {
  unsupported.value = props.unsupportedOS;
});

async function downloadSafe() {
  isDownloading.value = true;

  if (props.activationCode && props.deviceId && props.installType) {
    const safeClientURL = await GetSafeClient(props.activationCode, props.deviceId, "", props.installType, "");
    //show error message
    notificationsStore.clearNotifications();
    if (safeClientURL === "MoveMySubscriptionError") {
      notificationsStore.addNotification({ type: "MoveMySubscriptionError" });
    }

    if (safeClientURL === "UnsupportedOS") {
      isDownloading.value = false;
      logEvent("Unsupported", componentName);
      unsupported.value = true;
      return;
    }

    logEvent("URL", componentName, safeClientURL);
    if (isSafari()) {
      downloadClientWithRedirect(safeClientURL);
    } else {
      downloadClient(safeClientURL);
    }
  }

  isDownloading.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
