<template>
  <article class="process-downloads">
    <div class="notification info">
      <h3>{{ tn("title") }}</h3>
      <small class="text-sm">{{ tn("subtitle") }}</small>
    </div>
    <ul class="list">
      <li v-for="(item, index) in downLoadsPending" :key="index">
        <div
          v-if="item.error"
          :class="item.isCriticalError ? 'danger' : 'warning'"
          class="notification flex-start align-items-center"
        >
          <svg-icon-component v-if="item.isCriticalError" icon="circle-exclamation" class="no-shrink icon-sm mr-half" />
          <svg-icon-component v-else icon="circle-triangle" class="no-shrink icon-sm mr-half" />
          <i18n-t :keypath="getDownloadErrorMessage(item.error, true)" tag="p" scope="global" class="grow">
            <template #numberOfFiles>
              {{ item.savedNumberOfFiles }}
            </template>
          </i18n-t>
          <button :id="'btnClose_' + item.requestId" type="button" class="btn-link" @click="clearError(item.requestId)">
            <svg-icon-component icon="times" class="close icon-xs" />
          </button>
        </div>
        <div v-else class="flex-start align-items-center">
          <i18n-t keypath="CssbDownloadWatcherComponent.message" tag="p" scope="global" class="grow">
            <template #numberOfFiles>
              {{ item.savedNumberOfFiles }}
            </template>
          </i18n-t>
          <progress-circle-component
            :percentage="item.progressPercent"
            :class="{ 'mr-half': item.progressPercent >= 100 }"
          ></progress-circle-component>
          <button
            v-show="item.progressPercent < 100"
            :id="'btnClose_' + item.requestId"
            type="button"
            class="btn-link"
            @click="openCancelDialog(item)"
          >
            <svg-icon-component icon="times" class="close icon-xs" />
          </button>
        </div>
      </li>
    </ul>

    <CSSBCancelDownloadComponent
      :show-dialog="showCancelDialog"
      :request="requestToCancel"
      @save-modal="cancelDownload"
      @close-modal="toggleCancelDialog"
    ></CSSBCancelDownloadComponent>
  </article>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import ProgressCircleComponent from "@/components/FileAccess/ProgressCircle.vue";
import { unifiedApi } from "@/common";
import { ICssbDownloadUrlRequest, ICssbDownloadWatcher, ICssbPendingDownloadRequest } from "./interfaces";
import CSSBCancelDownloadComponent from "@/components/shared/dialogs/CSSBCancelDownload/CSSBCancelDownload.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getDownloadErrorMessage } from "./downloadFn";

const componentName = "CssbDownloadWatcherComponent";

logEvent("creating", componentName);
const downLoadsPending = ref<ICssbDownloadWatcher[]>([]);
const showCancelDialog = ref<boolean>(false);
const requestToCancel = ref<ICssbDownloadUrlRequest>({ requestId: "", computerName: "", backupSetName: "" });
const userHitCancel = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function toggleCancelDialog() {
  showCancelDialog.value = !showCancelDialog.value;
}

function openCancelDialog(item: ICssbDownloadWatcher) {
  requestToCancel.value = {
    requestId: item.requestId,
    computerName: item.computerName,
    backupSetName: item.backupSetName,
  };
  toggleCancelDialog();
}

function cancelDownload(request: ICssbDownloadUrlRequest) {
  //Send Cancel request
  unifiedApi.cancelCssbDownload(request);
  //Remove item from downLoadsPending[]
  downLoadsPending.value = downLoadsPending.value.filter(l => l.requestId !== request.requestId);
  toggleCancelDialog();
  userHitCancel.value = true;
  if (downLoadsPending.value.length === 0) {
    localStorage.setItem("closeDownloadPopup", "true");
  }
}

function clearError(requestId: string) {
  downLoadsPending.value = downLoadsPending.value.filter(l => l.requestId !== requestId);
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);

  userHitCancel.value = false;

  //Set up 'Save changes before leaving' message
  //Note: this only works if the user interacts with the pop-up at least once.
  window.onbeforeunload = () => {
    if (userHitCancel.value) {
      return;
    } else {
      return "not undefined";
    }
  };

  //Set up interval for the rest of the time
  window.setInterval(async () => {
    await checkAllInProgressItems();
  }, 500);

  //Set up interval for the rest of the time
  window.setInterval(async () => {
    //Check the progress of each item
    downLoadsPending.value.forEach(async item => {
      if (item.url) {
        return;
      }
      await checkEachDownload(item);
    });

    downloadCompletedPendingItems();
  }, 5000);
});

async function checkAllInProgressItems() {
  try {
    const itemsInProgress: ICssbPendingDownloadRequest[] = [];
    const localItems = localStorage.getItem("downloadRequests");
    //Clear the local storage so we are less likely to get duplicate items
    localStorage.removeItem("downloadRequests");
    if (localItems) {
      const splitItems = localItems.split("|");

      splitItems.forEach(item => {
        const parsedItem: ICssbPendingDownloadRequest = JSON.parse(item);
        itemsInProgress.push(parsedItem);
      });
    }

    itemsInProgress.forEach(item => {
      const pendingItem = downLoadsPending.value.find(l => l.requestId === item.requestId);
      if (!item.requestId || pendingItem) {
        return;
      }

      //Add a pending download if there isn't one already
      downLoadsPending.value.push({
        requestId: item.requestId,
        computerName: item.computerName,
        backupSetName: item.backupSetName,
        error: "",
        progressPercent: 0,
        size: item.totalSize,
        numberOfFiles: item.numberOfFiles,
        savedNumberOfFiles: item.numberOfFiles != 0 ? item.numberOfFiles : 0,
        url: "",
        isCriticalError: false,
      });
    });
  } catch {
    logEvent("getAllInProgressItems Failed", componentName);
  }
}

function downloadCompletedPendingItems() {
  const itemsToDownload = downLoadsPending.value.filter(l => l.url);
  //Download the completed items
  itemsToDownload.forEach(item => {
    downloadItem(item.url, item.requestId);
  });

  downLoadsPending.value = downLoadsPending.value.filter(l => !l.url);

  //Remove completed items from the Array after 3 seconds
  setTimeout(() => {
    //If there are no downloads in progress close the window
    if (downLoadsPending.value.length === 0) {
      localStorage.setItem("closeDownloadPopup", "true");
    }
  }, 3000);
}

async function checkEachDownload(item: ICssbDownloadWatcher) {
  try {
    const checkItem = (
      await unifiedApi.getCssbDownloadUrl({
        requestId: item.requestId,
        computerName: item.computerName,
        backupSetName: item.backupSetName,
      } as ICssbDownloadUrlRequest)
    ).data;

    const isCriticalError = checkItem.error === "Invalid_Passphrase";

    const pendingItem = downLoadsPending.value.find(l => l.requestId === item.requestId);
    if (pendingItem) {
      pendingItem.error = checkItem.error;
      pendingItem.progressPercent = pendingItem.url ? 100 : Math.floor(checkItem.progress); //Round down so we don't get crazy numbers like 12.020292022%
      pendingItem.url = checkItem.url;
      pendingItem.isCriticalError = isCriticalError;
      pendingItem.size = checkItem.totalSize;
      pendingItem.numberOfFiles = checkItem.numFiles;
    } else {
      downLoadsPending.value.push({
        requestId: item.requestId,
        computerName: item.computerName,
        backupSetName: item.backupSetName,
        error: checkItem.error,
        progressPercent: item.url ? 100 : Math.floor(checkItem.progress), //Round down so we don't get crazy numbers like 12.020292022%
        size: item.size,
        numberOfFiles: item.numberOfFiles,
        savedNumberOfFiles: item.numberOfFiles != 0 ? item.numberOfFiles : 0,
        url: checkItem.url,
        isCriticalError: isCriticalError,
      });
    }
  } catch {
    logEvent("checkEachDownload failed", componentName, item);
  }
}

function downloadItem(url: string, name: string) {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
</script>
