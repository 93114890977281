<template>
  <h1>{{ tn("h1Heading") }}</h1>
  <article v-if="props.showCarbonite" class="mb-double">
    <div>
      <div>
        <h4 id="h4CarboniteCustomerSupport" class="mb">{{ tn("carboniteCustomerSupport") }}</h4>
        <p id="pCarboniteHelpText">{{ tn("findHelpCarbText") }}</p>
        <button id="btnCarboniteGo" class="inline-btn-link" @click="goCarbonite">
          {{ tn("carboniteSupportText") }}
        </button>
      </div>
    </div>
  </article>
  <article v-if="props.showWebroot" class="mb-double">
    <div>
      <div>
        <h4 id="h4WebrootCustomerSupport" class="mb">{{ tn("webrootCustomerSupport") }}</h4>
        <ul class="mb">
          <li id="webrootHelpText1">{{ tn("findHelpWebrootLine1") }}</li>
          <li id="webrootHelpText2">{{ tn("findHelpWebrootLine2") }}</li>
          <li id="webrootHelpText3">{{ tn("findHelpWebrootLine3") }}</li>
          <li id="webrootHelpText4">{{ tn("findHelpWebrootLine4") }}</li>
          <li id="webrootHelpText5">{{ tn("findHelpWebrootLine5") }}</li>
        </ul>
        <button id="btnWebrootGo" class="inline-btn-link" @click="goWebroot">
          {{ tn("webrootSupportText") }}
        </button>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useUserStore } from "@/stores/user";
import { getLanguageUrlParm } from "@/common/helper";

const componentName = "ExpiredSubSupportSectionComponent";
const carbSupportSite = "https://support.carbonite.com";

const props = defineProps({
  showCarbonite: {
    type: Boolean,
    default: false,
    required: true,
  },
  showWebroot: {
    type: Boolean,
    default: false,
    required: true,
  },
});

logEvent("created", componentName);
const userStore = useUserStore();
const languageUrlParm = computed(() => getLanguageUrlParm(userStore.selectedLanguage || "en"));

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function goWebroot() {
  logEvent("Go to Webroot Knowledge base", componentName);
  window.open(`https://www.webroot.com/${languageUrlParm.value}/support/support-home`);
}

function goCarbonite() {
  logEvent("Go to Carb Knowledge base", componentName);
  window.open(`${carbSupportSite}`);
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
