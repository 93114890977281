<template>
  <VueFinalModal
    v-model="options.modelValue"
    :modal-id="props.dialogName"
    :teleport-to="options.teleportTo"
    :click-to-close="options.clickToClose"
    :esc-to-close="options.escToClose"
    :lock-scroll="options.lockScroll"
    :content-transition="options.contentTransition"
    :overlay-transition="options.overlayTransition"
    :hide-overlay="options.hideOverlay"
    :reserve-scroll-bar-gap="options.reserveScrollBarGap"
    :swipe-to-close="options.swipeToClose"
    :background="options.background"
    :class="props.cssClass"
    :focus-trap="false"
    @before-open="beforeOpen"
  >
    <header v-if="props.showHeader">
      <h1><slot name="header" /></h1>
      <div v-if="props.closable" class="close-modal">
        <button id="btnCloseModalX" type="button" class="btn-link icon-sm" @click="closeModal()">
          <svg-icon-component icon="times" />
        </button>
      </div>
    </header>
    <NotificationsComponent :context="dialogName" />
    <section class="body">
      <slot name="content" />
    </section>
    <footer v-if="props.showFooter">
      <slot name="footer" />
    </footer>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from "vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { VueFinalModal } from "vue-final-modal";
import { IModalProps } from "./interfaces";
import {
  displayDirectiveTypes,
  transitionTypes,
  backgroundTypes,
  SwipeToCloseTypes,
} from "@/components/shared/dialogs/enums";

const props = defineProps({
  dialogName: {
    type: String,
    required: true,
  },
  cssClass: {
    type: String,
    default: "",
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
  closable: {
    type: Boolean,
    default: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
  modalOptions: {
    type: Object as PropType<IModalProps>,
    default: null,
  },
});

const emits = defineEmits(["close-modal", "pre-open"]);

const initialValues = {
  teleportTo: null, //Needs to stay null or unit tests break
  displayDirective: displayDirectiveTypes.if,
  modelValue: props.showDialog,
  hideOverlay: false,
  overlayTransition: transitionTypes.vfmFade,
  contentTransition: transitionTypes.vfmFade,
  clickToClose: false,
  escToClose: false,
  background: backgroundTypes.nonInteractive,
  lockScroll: false,
  reserveScrollBarGap: true,
  swipeToClose: SwipeToCloseTypes.none,
} as IModalProps;

const options = ref<IModalProps>(initialValues);

function closeModal() {
  emits("close-modal");
}

function beforeOpen() {
  emits("pre-open");
}

watch(props, () => {
  options.value.modelValue = props.showDialog;
});
</script>
