<template>
  <div>
    <svg-icon-component icon="circle-exclamation" class="icon-sm danger mr-half" />
  </div>
  <div class="truncate-flex-child-wrapper">
    <div>
      <i18n-t keypath="OutstandingInvoiceAlertComponent.webrootLine1" tag="strong" scope="global">
        <template #outstandingDays>
          {{ daysToDeleteBackup(props.sub) }}
        </template>
        <template #productName>
          {{ props.sub.productDisplayName }}
        </template>
      </i18n-t>
      <i18n-t keypath="OutstandingInvoiceAlertComponent.line2" tag="div" scope="global">
        <template #invoiceNumber>
          <strong>{{ props.sub?.outstandingInvoice?.invoiceNumber }}</strong>
        </template>
      </i18n-t>
    </div>
    <i18n-t
      v-if="!isAllStateAddOn && !isVPN"
      keypath="OutstandingInvoiceAlertComponent.webrootLine3"
      tag="div"
      scope="global"
      class="truncate-flex"
    >
      <template #productName>
        {{ props.sub.productDisplayName }}
      </template>
      <template #keycode>
        <strong>{{ formatKeycode(props.sub?.keyCode ?? "") }}</strong>
      </template>
    </i18n-t>
  </div>
  <div class="action">
    <button class="btn-outline-primary" @click="renewSubscription(props.sub)">{{ tn("btnRenewNow") }}</button>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { t } from "@/i18n";
import { formatKeycode } from "@/common/formatKeycode";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { daysToDeleteBackup } from "@/common/subscriptionHelper";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { addOnLCNs, webrootLicenseCategories } from "@/common/webrootProductName";

const baseComponent = "OutstandingInvoiceAlertComponent";

const props = defineProps({
  sub: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

const emits = defineEmits(["renew-webroot"]);

const isVPN = computed(() => {
  return props.sub?.licenseCategoryName === webrootLicenseCategories.WIFI;
});

const isAllStateAddOn = computed(() => {
  return addOnLCNs.some(l => l === props.sub.licenseCategoryName);
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponent}.${v}`, params);
}

function renewSubscription(sub: ISubscription) {
  emits("renew-webroot", sub);
}
</script>
