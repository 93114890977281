import {
  carboniteProducts,
  IProductDisplayRequest,
  getProductDisplayNameBySku,
  getCarboniteProductMapper,
} from "@/common/productsBySku";
import { webrootLicenseCategories, webrootProducts as webrootProductNames } from "@/common/webrootProductName";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import { ExpirationState, KeyCodeSeller } from "@/globalEnums";
import {
  INVOICE_FIRST_OUTSTANDING_DAY,
  INVOICE_LAST_OUTSTANDING_DAY,
  SafeDataPurgeDays,
  SafeResellerConvertToDirectDay,
  SUBSCRIPTION_BASE_YEAR,
  SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY,
  SUBSCRIPTION_LAST_AMAZON_EXPIRED_DAY,
  SUBSCRIPTION_MIN_ACTIVE_DAY,
  SUBSCRIPTION_MIN_DATA_LOSS_DAY,
  SUBSCRIPTION_MIN_DAYS_TO_EXPIRATION_FOR_INACTIVE,
  SUBSCRIPTION_MIN_EXPIRED_DAY,
  SUBSCRIPTION_MIN_GRACE_PERIOD_DAY,
  SUBSCRIPTION_MIN_RENEWAL_DAY,
  SUBSCRIPTION_MIN_WARNING_DAY,
  WEBROOT_ANTI_VIRUS_SKU,
  INACTIVE_EXPIRATION_DAYS,
  TRIAL_HIDE_EXPIRATION_DAYS,
  cancelled,
  URLS_JA,
} from "@/define";
import { t } from "../i18n";
import { formatKeycode } from "@/common/formatKeycode";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { getUrl } from "./getUrl";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { useUserStore } from "@/stores/user";
import { allstateLCNs } from "@/common/webrootProductName";
import { FLAGS } from "@/define";
import { portalDestination } from "@/globalEnums";
import { pageStates } from "@/components/Buy/BuyEnums";
import { logException } from "./logger";
import { unifiedApi } from ".";
import { useNotificationsStore } from "@/stores/notifications";

export async function getSubscriptions() {
  try {
    const subscriptionsStore = useSubscriptionsStore();
    await subscriptionsStore.populateSubscriptions();
    const subscriptions = subscriptionsStore.subscriptions;
    for (const sub of subscriptions) {
      //Add productDisplayName column and translate as per the i18n
      if (sub?.sku && carboniteProducts.some(product => product.sku === sub.sku)) {
        sub.productDisplayName = t(`Brand.${getCarboniteProductMapper(sub.sku)}`);
      } else {
        sub.productDisplayName = t(`Brand.${sub.licenseCategoryName}`);
      }
    }
    return subscriptions;
  } catch (err) {
    handleApiError(err as AxiosError, false);
  }
}

export function isSafeProduct(subscription: ISubscription): boolean {
  return !!carboniteProducts.find(product => product.sku === subscription.sku);
}

export function isSafeSubscriptionClosed(subscription: ISubscription): boolean {
  const isSafeSKU = isSafeProduct(subscription);
  let isPurged = false;

  if (isSafeSKU) {
    if (subscription.isEvergreenMonthly) {
      isPurged =
        subscription.isCancelledEvergreenSubscription ||
        subscription.computer?.dataHasBeenPurged ||
        (!subscription.hasOutstandingInvoices && subscription.remainingDays <= SafeDataPurgeDays.Direct) ||
        (!!subscription.computer?.remainingDaysToPurge && subscription.computer?.remainingDaysToPurge <= 0);
    } else if (subscription.isTrial) {
      isPurged =
        subscription.remainingDays <= SafeDataPurgeDays.Trials || (subscription.computer?.dataHasBeenPurged ?? false);
    } else {
      if (subscription.isAutoRenewEnabled) {
        isPurged =
          (!subscription.hasOutstandingInvoices && subscription.remainingDays <= SafeDataPurgeDays.Direct) ||
          (subscription.computer?.dataHasBeenPurged ?? false);
      } else {
        isPurged =
          subscription.remainingDays <= SafeDataPurgeDays.Direct || (subscription.computer?.dataHasBeenPurged ?? false);
      }
    }
  }

  return isPurged;
}

export function showWarning(subscription: ISubscription): boolean {
  return (
    subscription.expiration === ExpirationState.Upcoming &&
    !subscription.isAutoRenewEnabled &&
    !subscription.isEvergreenMonthly
  );
}

export function showDanger(subscription: ISubscription): boolean {
  let showDanger = false;
  const isSafeSKU = isSafeProduct(subscription);

  //Amazon
  if (!isSafeSKU && subscription.keyCodeSellerType === KeyCodeSeller.Amazon) {
    showDanger =
      subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
      subscription.remainingDays > SUBSCRIPTION_LAST_AMAZON_EXPIRED_DAY;
  }

  //BestBuy
  if (!isSafeSKU && subscription.keyCodeSellerType === KeyCodeSeller.BestBuy && !isNotActiveYet(subscription)) {
    showDanger =
      subscription.remainingDays < SUBSCRIPTION_MIN_EXPIRED_DAY && subscription.expiration !== ExpirationState.Purged;
  }

  //Webroot
  if (!isSafeSKU && subscription.keyCodeSellerType === KeyCodeSeller.Webroot && !isNotActiveYet(subscription)) {
    showDanger = subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY;
  }

  //Best Buy
  if (!isSafeSKU && subscription.keyCodeSellerType === KeyCodeSeller.BestBuy && !isNotActiveYet(subscription)) {
    showDanger = subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY;
  }

  //Trial
  if (isSafeSKU && subscription.isTrial && subscription.remainingDays < SUBSCRIPTION_MIN_EXPIRED_DAY) {
    showDanger = subscription.remainingDays > SafeDataPurgeDays.Trials;
  }

  //Manual Renew
  if (
    isSafeSKU &&
    !subscription.isTrial &&
    !subscription.isAutoRenewEnabled &&
    subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
    !subscription.isEvergreenMonthly
  ) {
    showDanger = true; //subscription.expirationDays > SafeDataPurgeDays.Direct;
  }

  //Auto Renew
  if (
    isSafeSKU &&
    !subscription.isTrial &&
    subscription.isAutoRenewEnabled &&
    !isProcessingRenewal(subscription) &&
    subscription.remainingDays < SUBSCRIPTION_MIN_EXPIRED_DAY &&
    !subscription.isEvergreenMonthly
  ) {
    showDanger =
      subscription.remainingDays > SafeDataPurgeDays.Direct ||
      (!!subscription.computer?.remainingDaysToPurge &&
        subscription.computer?.remainingDaysToPurge > 0 &&
        !subscription.computer?.dataHasBeenPurged);
  }

  //Outstanding Invoice
  if (subscription.hasOutstandingInvoices) {
    if (subscription.outstandingInvoice) {
      const outstanding = subscription.outstandingInvoice.outstandingInvoiceDays;
      if (carboniteProducts.find(product => product.sku === subscription.sku)) {
        showDanger = outstanding <= INVOICE_FIRST_OUTSTANDING_DAY && outstanding >= INVOICE_LAST_OUTSTANDING_DAY;
      } else {
        showDanger = outstanding >= INVOICE_LAST_OUTSTANDING_DAY;
      }
    } else {
      showDanger = false;
    }
  }

  //Evergreen Monthly
  if (isSafeSKU && subscription.isEvergreenMonthly) {
    showDanger =
      !subscription.isCancelledEvergreenSubscription &&
      ((subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
        subscription.remainingDays > SafeDataPurgeDays.Direct) ||
        (!!subscription.computer?.remainingDaysToPurge &&
          subscription.computer?.remainingDaysToPurge > 0 &&
          !subscription.computer?.dataHasBeenPurged));
  }

  return showDanger;
}

export function isNotActiveYet(subscription: ISubscription | undefined): boolean {
  if (subscription?.webrootStatusDescription?.toLowerCase() === "inactive") {
    return true;
  }

  if (subscription?.expirationDate) {
    return new Date(subscription.expirationDate).getFullYear() < SUBSCRIPTION_BASE_YEAR;
  }

  //check if its roughly 41 years ago if we don't have a date for some reason
  return subscription?.remainingDays
    ? subscription?.remainingDays > SUBSCRIPTION_MIN_DAYS_TO_EXPIRATION_FOR_INACTIVE
    : false;
}

export function hideExpiredTrial(subscription: ISubscription): boolean {
  return (
    subscription?.isTrial &&
    subscription?.remainingDays <= TRIAL_HIDE_EXPIRATION_DAYS &&
    subscription?.remainingDays > INACTIVE_EXPIRATION_DAYS
  );
}

export function isNotActiveYetByExpirationDate(expirationDate: string): boolean {
  return new Date(expirationDate).getFullYear() < SUBSCRIPTION_BASE_YEAR;
}

export function isProcessingRenewal(subscription: ISubscription): boolean {
  return (
    isSafeProduct(subscription) &&
    subscription.isAutoRenewEnabled &&
    !subscription.isEvergreenMonthly &&
    subscription.remainingDays <= SUBSCRIPTION_MIN_RENEWAL_DAY &&
    subscription.remainingDays >= SUBSCRIPTION_MIN_EXPIRED_DAY
  );
}

export function isWebrootProduct(subscription: ISubscription): boolean {
  return !!webrootProductNames.find(product => product.licenseCategory === subscription.licenseCategoryName);
}

export function showSafeRenewLink(subscription: ISubscription | undefined): boolean {
  let showRenewLink = false;
  if (subscription?.keyCodeSellerType !== KeyCodeSeller.Safe) {
    return false;
  }

  if (subscription?.isEvergreenMonthly && !subscription.isCancelledEvergreenSubscription) {
    showRenewLink =
      !subscription.computer?.dataHasBeenPurged && subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY;
  } else if (
    !subscription?.isTrial &&
    (!subscription?.resellerId || subscription?.isPartnerReferral) &&
    !subscription?.isEvergreenMonthly
  ) {
    if (subscription?.isAutoRenewEnabled) {
      showRenewLink =
        !subscription?.computer?.dataHasBeenPurged && subscription?.remainingDays < SUBSCRIPTION_MIN_EXPIRED_DAY;
    } else if (subscription) {
      showRenewLink =
        subscription?.expiration === ExpirationState.Upcoming ||
        (subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
          subscription.remainingDays > SafeDataPurgeDays.Direct &&
          !subscription?.computer?.dataHasBeenPurged);
      //Do not show renew button for expired SafeLite subs without an computer id
      //We should show the buy button in this case
      if (
        subscription?.isSafeLite &&
        !!subscription.computer &&
        subscription.computer.id <= 0 &&
        subscription.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
        subscription.remainingDays > SafeDataPurgeDays.Direct &&
        !subscription.computer?.dataHasBeenPurged
      ) {
        showRenewLink = false;
      }
    }
  } else if (subscription.resellerId && subscription.resellerId > 0 && !subscription.isTrial) {
    if (subscription.isAutoRenewEnabled) {
      showRenewLink =
        (subscription.computer &&
          subscription.computer.remainingDaysToPurge &&
          subscription.computer.remainingDaysToPurge <= SafeDataPurgeDays.Reseller &&
          !subscription.computer.dataHasBeenPurged) ||
        (subscription.remainingDays <= SafeResellerConvertToDirectDay && subscription.hasOutstandingInvoices);
    } else {
      showRenewLink =
        subscription.remainingDays <= SafeResellerConvertToDirectDay &&
        subscription.remainingDays > SafeDataPurgeDays.Reseller;
    }
  }

  //If there is an Outstanding Invoice show the Renew Now Button
  if (subscription?.hasOutstandingInvoices && subscription.outstandingInvoice) {
    showRenewLink = true;
  }

  if (subscription && subscription.isActiveUntilCancelled && !subscription.isEvergreenMonthly) {
    showRenewLink = false;
  }

  return showRenewLink;
}

export function showSafeBuyLink(subscription: ISubscription | undefined): boolean {
  let showLink = false;
  if (subscription?.isTrial && (!subscription.resellerId || subscription.isPartnerReferral)) {
    showLink = subscription.remainingDays > SafeDataPurgeDays.Trials && !subscription.computer?.dataHasBeenPurged;
  }
  //Show the buy button for SafeLite subs that do not have a computer id
  //This works as the second part to the showSafeRenewLink() for SafeLite subs that are expired with no computer id
  else if (subscription?.isSafeLite && !!subscription.computer && subscription.computer.id <= 0) {
    showLink = true;
  }

  return showLink;
}

// shows or hides the renew link for WR subs purchased via Carbonite
export function showCarbWebrootRenewLink(subscription: ISubscription | undefined): boolean {
  return (
    webrootProductNames.find(product => {
      return product.licenseCategory === subscription?.licenseCategoryName;
    }) !== undefined &&
    !isNotActiveYet(subscription) &&
    !subscription?.isAutoRenewEnabled &&
    subscription?.expiration !== ExpirationState.Normal &&
    subscription?.keyCodeSellerType === KeyCodeSeller.Safe
  );
}

// shows or hides the renew link for WR subs purchased outside of Carbonite
export function showWebrootRenewLink(subscription: ISubscription | undefined): boolean {
  if (subscription?.isPlanetOt) {
    return false;
  }

  //If there is an Outstanding Invoice show the Renew Now Button
  if (subscription?.hasOutstandingInvoices && subscription.outstandingInvoice) {
    return true;
  }

  //If the zuora status is "Cancelled" then that means the sub was refunded, we should still show the renew link for these subs
  if (subscription?.zuoraSubscriptionStatus && subscription?.zuoraSubscriptionStatus.toLowerCase() === cancelled) {
    return true;
  }

  return (
    (!subscription?.isAutoRenewEnabled ||
      (subscription?.isAutoRenewEnabled && subscription?.remainingDays < SUBSCRIPTION_MIN_EXPIRED_DAY)) &&
    !isNotActiveYet(subscription) &&
    subscription?.keyCodeSellerType === KeyCodeSeller.Webroot &&
    subscription?.remainingDays <= SUBSCRIPTION_MIN_ACTIVE_DAY
  );
}

//Only used for BBY atm
export function showConvertToWebRootBuyLink(subscription: ISubscription | undefined): boolean {
  return (
    subscription?.keyCodeSellerType === KeyCodeSeller.BestBuy &&
    subscription?.remainingDays < SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY &&
    !isNotActiveYet(subscription)
  );
}
export function showWebrootBuyLinkForTrial(subscription: ISubscription | undefined): boolean {
  return showWebrootRenewLink(subscription) && (subscription?.isTrial ?? false);
}

//We only ever want to show the contact link for BBY
export function showContactBestBuyLink(subscription: ISubscription | undefined): boolean {
  return (
    subscription?.keyCodeSellerType === KeyCodeSeller.BestBuy &&
    subscription?.remainingDays > SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY
  );
}

let carbBuyUrl = getUrl("CARBONITE_BUY_URL");
// renews WR subs purchased via UP
export function renewCarbWebrootSubscription(subscription: ISubscription | undefined) {
  //If this is a migrated Safe WR sub renew through UP with the new Zuora SKU
  if (subscription?.isLegacyWebroot && FLAGS.ENABLE_SAFE_WEBROOT_MIGRATION === "true") {
    window.location.href = `${carbBuyUrl}/paymentForm?sku=${WEBROOT_ANTI_VIRUS_SKU}&keycode=${subscription?.zuoraSubscriptionNumber}`;
    return;
  }

  window.location.href = `${carbBuyUrl}/paymentForm?sku=Webroot&keycode=${subscription?.zuoraSubscriptionNumber}`;
}

function isValidDelimiter(delimiter: string) {
  const allowedDelimiters = ["?", "&"];
  return allowedDelimiters.includes(delimiter);
}

export function generateInterstitialReturnPortalDestination(delimiter: string = "&"): string {
  if (!isValidDelimiter(delimiter)) {
    return "";
  }
  const domain = new URL(window.location.href);
  const portal =
    getUrl("VITE_APP_WEBROOT_URL") === domain.origin + "/" ? portalDestination.Webroot : portalDestination.Carbonite;

  //The page state should always be email. When a user comes back from the interstitial page
  //we make an API call to see if we have any candy rack items to display to them.
  //They will stay on the email step if there are candy rack items or move to payment if there are none.
  return `${delimiter}portalDest=${portal}-${pageStates.email}`;
}

function refreshSubscriptionHelperUrls() {
  carbBuyUrl = getUrl("CARBONITE_BUY_URL");
}

registerRefreshFunction(refreshSubscriptionHelperUrls, true);

/**
 * Add any post data retrieval processing for each sub
 */
export function processSubscriptionDataForDisplay(subscriptions: ISubscription[]): ISubscription[] {
  // filter out Allstate subscriptions if Japanese Language is selected
  const userStore = useUserStore();
  if (userStore.selectedLanguage !== "en") {
    subscriptions = subscriptions.filter(s => !isAllstateSubscription(s));
  }

  for (const sub of subscriptions) {
    //Adding a display for the sku to the object for sorting
    if (sub?.sku && carboniteProducts.some(product => product.sku === sub.sku)) {
      if (sub.sku === "PersonalBasic" && !!sub.keyCode && !sub.isTrial) {
        sub.isSafeLite = true;
      }
    }

    const request: IProductDisplayRequest = {
      sku: sub.sku,
      webrootKeycode: sub.keyCode,
      isTrial: sub.isTrial,
    };

    sub.productDisplayName = t(getProductDisplayNameBySku(request));

    //Add an easier way to tell what state a sub is in
    if (sub.remainingDays > SUBSCRIPTION_MIN_ACTIVE_DAY) {
      sub.expiration = ExpirationState.Normal;
    } else if (sub.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY) {
      sub.expiration = ExpirationState.Upcoming;
    } else if (sub.remainingDays >= SUBSCRIPTION_MIN_GRACE_PERIOD_DAY) {
      sub.expiration = ExpirationState.Expired;
    } else if (sub.remainingDays >= SUBSCRIPTION_MIN_DATA_LOSS_DAY) {
      sub.expiration = ExpirationState.FinalWarning;
    } else {
      sub.expiration = ExpirationState.Purged;
    }

    //Moved this from the ProductComponent because the table change made it so the component is not fully rendered again so the setup would not
    //be triggered again. This caused the array of keycodes to not be calculated again on sort.
    sub.displayKeycode = formatKeycode(sub?.keyCode ?? "");

    //Adding cardType and customExpirationDate field for sorting purpose
    sub.cardType = sub.paymentInfo?.cardType ? sub.paymentInfo.cardType : " ";
    sub.customExpirationDate =
      sub.hasOutstandingInvoices &&
      sub.outstandingInvoice &&
      sub.outstandingInvoice?.outstandingInvoiceDays <= INVOICE_FIRST_OUTSTANDING_DAY &&
      sub.outstandingInvoice?.outstandingInvoiceDays >= INVOICE_LAST_OUTSTANDING_DAY
        ? "1970-01-01Z00:00:00:000"
        : sub.expirationDate;
  }

  return subscriptions.filter(l => {
    //Filter out Safe Lite subs that are not provisioned
    return l.sku !== null && l.packageName?.toLowerCase() !== "safe lite";
  });
}

export function isAllstateSubscription(subscription: ISubscription): boolean {
  return allstateLCNs.includes(subscription?.licenseCategoryName ?? "");
}

export function isExpiredAllstateWithNewSubscription(subscription: ISubscription | undefined): boolean {
  if (
    subscription &&
    subscription?.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
    isAllstateSubscription(subscription)
  ) {
    return useSubscriptionsStore().subscriptions?.some(s => s.remainingDays > 0 && isAllstateSubscription(s));
  }
  return false;
}

export function isAllExpiredAllstate(subscription: ISubscription | undefined): boolean {
  if (
    subscription &&
    subscription?.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY &&
    isAllstateSubscription(subscription)
  ) {
    return useSubscriptionsStore().subscriptions?.every(s => s.remainingDays <= 0 && isAllstateSubscription(s));
  }
  return false;
}

//This will check if the Renew or Upgrade link should be hidden for products
//that the user can only have one active subscription
export function hideRenewOrUpgradeLinkForLimitedPurchaseSub(
  subscription: ISubscription | undefined,
  subscriptions: ISubscription[]
) {
  return (
    isExpiredAllstateWithNewSubscription(subscription) ||
    isExpiredVPNWithNewSubscription(subscription, subscriptions) ||
    isExpiredWTPWithNewSubscription(subscription, subscriptions)
  );
}

//Checks if the given sub is an expired VPNP sub and the user
//has another active sub that is either VPNP or WTP/WTPF
export function isExpiredVPNWithNewSubscription(
  subscription: ISubscription | undefined,
  subscriptions: ISubscription[]
): boolean {
  if (subscription && isVPNSub(subscription)) {
    //Not Expired
    if (subscription?.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY) {
      return false;
    }

    //Check for active Webroot Total Protection(it comes with VPNP) OR active VPNP subs
    return (
      hasActiveWTPSubscription(subscriptions) ||
      subscriptions.filter(l => isVPNSub(l)).some(l => l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY)
    );
  }

  return false;
}

export function isVPNSub(sub: ISubscription) {
  return sub.licenseCategoryName === webrootLicenseCategories.WSVPN;
}

export function hasActiveVPNSubscription(subscriptions: ISubscription[]) {
  return subscriptions.some(l => isVPNSub(l) && l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY);
}

export function hasActiveWTPSubscription(subscriptions: ISubscription[]) {
  return subscriptions.some(l => isWebrootTotalProtection(l) && l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY);
}

//Checks if the given sub is an expired WTP/WTPF sub and the user
//has another active sub that is either VPNP or WTP/WTPF
export function isExpiredWTPWithNewSubscription(
  subscription: ISubscription | undefined,
  subscriptions: ISubscription[]
): boolean {
  if (subscription && isWebrootTotalProtection(subscription)) {
    //Not Expired
    if (subscription?.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY) {
      return false;
    }

    //Check for active Webroot Total Protection(it comes with VPNP) OR active VPNP subs
    return (
      hasActiveVPNSubscription(subscriptions) ||
      subscriptions.filter(l => isWebrootTotalProtection(l)).some(l => l.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY)
    );
  }

  return false;
}

export function isWebrootTotalProtection(sub: ISubscription) {
  return (
    sub.licenseCategoryName === webrootLicenseCategories.WTP ||
    sub.licenseCategoryName === webrootLicenseCategories.WTPF
  );
}

export function daysToDeleteBackup(sub: ISubscription) {
  let days = SUBSCRIPTION_MIN_WARNING_DAY;
  if (sub.outstandingInvoice) {
    days += sub.outstandingInvoice.outstandingInvoiceDays;
  }
  return Math.ceil(days);
}

export function outstandingSubscriptionExpired(sub: ISubscription): boolean {
  if (sub.outstandingInvoice) {
    return Math.abs(sub.outstandingInvoice.outstandingInvoiceDays) > SUBSCRIPTION_MIN_WARNING_DAY;
  }
  return false;
}

export async function redirectRenewWebrootToInterstitialPage(keyCode: string) {
  const userStore = useUserStore();
  const notificationsStore = useNotificationsStore();
  try {
    if (keyCode) {
      let url;
      if (userStore.selectedLanguage === "ja") {
        url = URLS_JA["WEBROOT_HOME_RENEWAL"];
      } else {
        url = (await unifiedApi.getWebrootRenewUrl(keyCode)).data.redirectUrl;
      }
      window.open(url, "_self");
    }
  } catch (err) {
    notificationsStore.addNotification({ type: "ServerErrors" });
    logException(err as Error);
  }
}
