import { AxiosError } from "axios";
import { logException } from "./logger";
import { useNotificationsStore } from "@/stores/notifications";
import { NotificationType, INotificationParams } from "@/stores/notificationsInterfaces";
import { HTTP_STATUS_FORBIDDEN, HTTP_STATUS_UNAUTHORIZED } from "@/define";
import router from "@/routes";

export function handleApiError(
  err: AxiosError,
  showNotification = true,
  notificationType: NotificationType = "ServerErrors",
  notificationParams?: INotificationParams
) {
  let redirect = false;

  if (err && err.isAxiosError) {
    //decide what we're doing with axios errors, notification or redirection
    if (err.response && showNotification) {
      useNotificationsStore().addNotification({ type: notificationType, params: notificationParams });
    } else if (err.response?.status !== HTTP_STATUS_UNAUTHORIZED && err.response?.status !== HTTP_STATUS_FORBIDDEN) {
      redirect = true;
    }
    //log that this happened
    logException(err, { showNotification, redirect });
  } else {
    //mystery error, so log it and always redirect
    logException(err, "unknown api error");
    redirect = true;
  }
  //if the decision was to redirect, do it
  if (redirect) router.push("/error");
}
