<template>
  <ul v-if="buyFlowStore.pageState === pageStates.email">
    <li v-for="(item, index) in props.bundleItems" :key="index">
      <div v-if="bundleItemExists(item)" class="opposite-ends">
        <div>
          <p class="mb-0">
            {{ t(`Brand.${item.sku}`) }}
            <svg-icon-component icon="circle-information" class="icon-sm" @click="showBundleItemInfo(item.sku)" />
          </p>
          <button
            :id="`btnRemove${item.sku}`"
            class="inline-btn-link left-link"
            type="button"
            @click="buyFlowStore.removeFromCart(item, index, true)"
          >
            {{ t("Common.remove") }}
          </button>
        </div>
        <div>
          <strong>{{ formatMoney(getTotalForItem(item)) }}</strong>
        </div>
      </div>
      <div v-else class="mb">
        <button id="addBack" class="inline-btn-link link-color" @click="buyFlowStore.addBundleItem(item, index)">
          <svg-icon-component class="icon-sm mr-quarter" icon="circle-plus" />
          <span>{{ addBundleItemLink(item) }}</span>
        </button>
      </div>
    </li>
  </ul>
  <ul v-else>
    <li v-for="(item, index) in props.bundleItems" :key="index">
      <div v-if="bundleItemExists(item)" class="flex-start mb">
        <div class="grow mr-half">
          {{ t(`Brand.${item.sku}`) }}
          <svg-icon-component icon="circle-information" class="icon-sm" @click="showBundleItemInfo(item.sku)" />
        </div>
        <div class="amount">{{ formatMoney(item.total || 0) }}</div>
      </div>
    </li>
  </ul>
  <BundleItemInfo
    :show-dialog="displayBundleItemInfo || false"
    :sku="currentSku"
    @close-modal="closeBundleItemInfo()"
  />
</template>

<script setup lang="ts">
import { ICartItem } from "@/common/api/unifiedPortal/interfaces";
import { PropType, ref } from "vue";
import { formatMoney } from "@/components/Buy/commonFn";
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { webrootCDSku } from "@/common/webrootProductName";
import { pageStates } from "@/components/Buy/BuyEnums";
import BundleItemInfo from "@/components/Buy/Dialogs/SpecialOffersInfo.vue";

const componentName = "BundleItemsComponent";

const props = defineProps({
  cartItem: {
    type: Object as PropType<ICartItem>,
    required: true,
  },
  bundleItems: {
    type: Object as PropType<ICartItem[]>,
    required: true,
  },
});

const displayBundleItemInfo = ref<boolean>(false);
const buyFlowStore = useBuyFlowStore();
const currentSku = ref<string>("");
// checks whether bundledItem is already bundled
function bundleItemExists(item: ICartItem) {
  return props.cartItem?.bundledItems?.some(x => x.sku === item.sku);
}

function addBundleItemLink(item: ICartItem) {
  if (item.sku === webrootCDSku) {
    return `${tn("addSoftwareCD")} ${formatMoney(item.total)}`;
  }
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function showBundleItemInfo(sku) {
  currentSku.value = sku;
  displayBundleItemInfo.value = true;
}

function closeBundleItemInfo() {
  displayBundleItemInfo.value = false;
  currentSku.value = "";
}

//Get the price from the cart item when the bundled item is in the cart already
function getTotalForItem(item: ICartItem) {
  return props.cartItem.bundledItems?.find(l => l.sku === item.sku)?.total ?? item.total;
}
</script>
