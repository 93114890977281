<template>
  <!-- if provisional and mobile -->
  <div v-if="isMobile()">
    <p class="mb-double constrain-50">
      {{ tn("isEstablishedAccountLine1") }}
    </p>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="main mb-double constrain-50" v-html="tn('isMobileLine1')"></p>
    <div>
      <button class="btn-primary" type="button" @click="continueToMyAccount()">
        {{ tn("btnFinishMyAccountSetup") }}
      </button>
    </div>
    <i18n-t keypath="OrderConfirmation.provisionalMobileLine2" tag="p" scope="global" class="mb constrain-50">
      <template #url>
        <button id="knowledgeBase" type="button" class="inline-btn-link" @click="goToMyAccount()">
          {{ t("OrderConfirmation.isMobileBtnLine2Url") }}
        </button>
      </template>
    </i18n-t>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="mb-double constrain-50" v-html="tn('setUpMyAccountLine1')"></p>
  </div>
  <!-- if provisional and not mobile -->
  <div v-else>
    <p class="mb constrain-50">{{ tn("isEstablishedAccountLine1") }}</p>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <p class="mb constrain-50">{{ tn("provisionalLine1") }}</p>
    <div class="mb-double">
      <DownloadSafe
        btn-class="btn-primary"
        btn-text="OrderConfirmation.btnContinueToDownload"
        @install-instructions="goToInstallationInstructions()"
      />
    </div>
    <h1>{{ tn("h1SetUpMyAccount") }}</h1>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="mb constrain-50" v-html="tn('setUpMyAccountLine1')"></p>
    <p class="constrain-50">{{ tn("setUpMyAccountLine2") }}</p>
    <div class="mb mt">
      <button class="btn-outline-primary" type="button" @click="continueToMyAccount()">
        {{ tn("btnFinishMyAccountSetup") }}
      </button>
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="constrain-50" v-html="tn('orderConfirmationConclusion')"></p>
  </div>
</template>
<script setup lang="ts">
import { t } from "@/i18n";
import { isMobile } from "@/common/browserDetect";
import { continueToMyAccount } from "@/components/Buy/BuyHelper";
import { useRouter } from "vue-router";
import DownloadSafe from "@/components/Backup/DownloadSafe.vue";
import { logEvent } from "@/common/logger";

const componentName = "CarboniteProvisional";
const baseComponentName = "OrderConfirmation";
const router = useRouter();
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToInstallationInstructions() {
  router.push("/installationInstructions");
}

function goToMyAccount() {
  router.push("/Backup");
}
</script>
