<template>
  <div v-if="showSpinner" class="mb text-center">
    <spinner :is-spinning="true" />
  </div>
  <div v-else class="mb-double flex-start-lg">
    <div class="flex-start-mobile-and-md align-items-center no-shrink">
      <div class="mr">
        <img :src="lastPassPng" :alt="tn('Header')" />
      </div>
      <h3 class="mb-quarter only-show-mobile-and-md-inline">{{ tn("Header") }}</h3>
    </div>
    <div class="grow">
      <h3 class="mb-quarter only-show-lg-inline">{{ tn("Header") }}</h3>
      <p v-if="displayAccountsUsed">{{ tn("accountsUsed", { used: seatsUsed, total: totalSeats }) }}</p>
      <p v-else>{{ tn("downloadDescription") }}</p>
      <p v-if="disableActivate">
        <strong>{{ tn("installToActivate") }}</strong>
      </p>
      <p v-if="displayAdditionalAccounts">
        <button id="btnLastPassAccountSetup" class="inline-btn-link" @click="openLastPassModal('Add')">
          {{ tn(`accountSetup`) }}
        </button>
      </p>
      <p v-if="displayManageAccounts">
        <button id="btnLastPassManage" class="inline-btn-link" @click="manageAccounts()">
          {{ tn(`managePasswords`) }}
        </button>
      </p>
    </div>
    <div class="pt-and-a-half-desktop-lg text-right-lg">
      <button
        v-if="displayActivate"
        id="btnLastPassActivate"
        class="btn-primary"
        :disabled="disableActivate"
        @click="openLastPassModal('Activate')"
      >
        {{ tn(`activate`) }}
      </button>
      <button v-else id="btnLastPassGetTheApp" class="btn-primary" @click="openDownloadModal">
        {{ tn(`getTheApp`) }}
      </button>
    </div>
  </div>

  <LastPassDialogComponent
    :show-dialog="showLastPassModal"
    :operation="modalOperation"
    @close-modal="toggleLastPassModal"
    @save-modal="toggleLastPassModal"
  />
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { unifiedApi } from "@/common";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ILastPassInfo, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import lastPassPng from "@/assets/img/lastPass.png";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { productList } from "@/common/productList";
import Spinner from "@/components/shared/Spinner.vue";
import LastPassDialogComponent from "@/components/shared/dialogs/LastPass/LastPassDialog.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { getUrl } from "@/common/getUrl";

const componentName = "LastPassDownloadsTabComponent";

const emits = defineEmits(["openDownloadModal"]);

logEvent("Created", componentName);
const subscriptionsStore = useSubscriptionsStore();
const displayActivate = ref<boolean>(false);
const displayAdditionalAccounts = ref<boolean>(false);
const displayManageAccounts = ref<boolean>(false);
const displayAccountsUsed = ref<boolean>(false);
const showLastPassModal = ref<boolean>(false);
const modalOperation = ref<string>("");
const seatsUsed = ref<number>(0);
const totalSeats = ref<number>(0);
const webrootSubscriptions = ref<ISubscription[]>([]);
const info = ref<ILastPassInfo[]>();
const showSpinner = ref<boolean>(false);

onBeforeMount(async () => {
  showSpinner.value = true;
  const lastPassLicenseCategories = productList().find(p => p.code === "LPPM")?.licenseCategories;
  await subscriptionsStore.populateSubscriptions();
  const subs = subscriptionsStore.subscriptions;
  webrootSubscriptions.value = subs.filter(s => lastPassLicenseCategories?.includes(s.licenseCategoryName ?? ""));
  // Get LastPass info
  await getLastPassInfo();
  await render();
  showSpinner.value = false;
});

// LastPass
async function getLastPassInfo() {
  try {
    info.value = (await unifiedApi.getLastPassInfo()).data;
  } catch (error) {
    handleApiError(error as AxiosError, true, "LastPassError");
  }
}

const disableActivate = computed(() =>
  webrootSubscriptions.value.every(c => c.webrootStatusDescription === "Inactive")
);

async function render() {
  if (info.value) {
    seatsUsed.value = 0;
    totalSeats.value = 0;
    // Get Pending activations
    info.value.forEach(c => (seatsUsed.value += c.seatsUsed));
    info.value.forEach(c => (totalSeats.value += c.seatsAvailable + c.seatsUsed));
    const hasPendingURL = info.value.some(c => c.pendingLastPassTokenUrl.length > 0);
    const hasCompletedAccounts = info.value.some(
      c => (c.seatsUsed > 0 && c.pendingLastPassTokenUrl.length < 1) || c.seatsUsed > 1
    );
    const hasSeatsAvailable = info.value.some(c => c.seatsAvailable > 0);

    logEvent("Load info", componentName, info);
    // display Activate button if no seat was used;
    displayActivate.value = disableActivate.value || (seatsUsed.value == 0 && info.value.length > 0);
    // display Additional Accounts button if there is still seats available;
    displayAdditionalAccounts.value = !displayActivate.value && (hasSeatsAvailable || hasPendingURL);
    // display amount of accounts used if some has been provisioned
    displayAccountsUsed.value = !displayActivate.value && info.value.length > 0;
    // display Manage Accounts button if there are
    displayManageAccounts.value = hasCompletedAccounts || (!displayActivate.value && !displayAccountsUsed.value);
  }
}

function manageAccounts() {
  window.open(getUrl("LASTPASS_PORTAL_URL"));
}

async function toggleLastPassModal() {
  logEvent("toggleLastPassModal", componentName, `${showLastPassModal.value ? "Hiding" : "Showing"}`);
  showLastPassModal.value = !showLastPassModal.value;
  if (!showLastPassModal.value) {
    showSpinner.value = true;
    await getLastPassInfo();
    await render();
    showSpinner.value = false;
  }
}

function openLastPassModal(operation: string) {
  modalOperation.value = operation;
  toggleLastPassModal();
}

function openDownloadModal() {
  logEvent("openDownloadModal", componentName);
  emits("openDownloadModal", "LPPM");
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
