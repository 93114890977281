<template>
  <ModalWrapper
    dialog-name="loadingBox"
    :show-dialog="props.showDialog"
    :show-header="false"
    :show-footer="false"
    css-class="modal-sm"
    @pre-open="beforeOpen"
  >
    <template #content>
      <div class="text-center">
        <spinner id="spinner" class="icon-xxl" :is-spinning="true" />
        <p class="mt-half">
          <span id="message">{{ props.message }}</span>
        </p>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup lang="ts">
import spinner from "@/components/shared/Spinner.vue";
import { logEvent } from "@/common/logger";
import ModalWrapper from "@/components/shared/dialogs/ModalWrapper.vue";

/* LoadingBox gives a modal like appearance with an internal box with a spinner and a message */
/* we have 3 spinners: shared/Spinner.vue, shared/LoadingSpinner.vue, and shared/LoadingBox.vue */
const componentName = "LoadingBoxComponent";
const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
});

function beforeOpen() {
  logEvent("created", componentName);
}
</script>
