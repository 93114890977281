<template>
  <div class="language-selector">
    <button id="btnLanguageSelector" type="button" class="btn-link" @click="toggleLanguageSelector">
      <svg-icon-component icon="globe" />
      <span class="current-language">{{ getName(selectedLanguage) }}</span>
      <svg-icon-component icon="caret-down" class="icon-xs" />
    </button>
    <nav v-if="selectLanguage">
      <ul id="selectLanguage">
        <li v-for="l in getLanguages" :key="l" :value="l" :class="{ selected: l == selectedLanguage }">
          <button class="inline-btn-link" @click="setLanguage(l, true)">{{ getName(l) }}</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { logEvent, logException } from "@/common/logger";
import i18n, { languages, loadLanguage } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { refreshCachedStrings } from "@/components/shared/LanguageSelector/LanguageSelector";
import { unifiedApi } from "@/common";
import { AxiosError } from "axios";
import { languageDisplayNames } from "@/common/Language";

const componentName = "LanguageSelectorComponent";

logEvent("created", componentName);
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const getLanguages = computed(() => {
  return languages;
});

const selectLanguage = ref<boolean>(false);
const selectedLanguage = ref<string>(localStorage.getItem("selectedLanguage") || i18n.global.locale.value);

onMounted(async () => {
  userStore.selectedLanguage = selectedLanguage.value;
  try {
    if (sessionStorage.getItem("langInQuery") != "true") {
      if (route.path.toString().toLowerCase() === "/createaccount") {
        // Setting the language to browser language in create Account flow
        // Because lang Cookie is not set yet and will be set based on browser language and icon is also set to it.
        let lang = navigator.language.split("-")[0];
        if (!languages.some(l => l === lang)) {
          lang = "en";
        }
        setLanguage(lang, false);
      } else {
        const lang = await (await unifiedApi.getLangCookie()).data;

        if (lang != null && !!lang.language) {
          switch (lang.language.toLowerCase()) {
            case "ja-jp":
              setLanguage("ja", false);
              break;
            default:
              setLanguage("en", false);
              break;
          }
        }
      }
    } else {
      setLanguage(localStorage.getItem("selectedLanguage") ?? "en", false);
    }
  } catch (err) {
    logException(err as AxiosError, "Language Selector onMounted");
  }
});
function getName(locale: string) {
  return languageDisplayNames[locale];
}

//show/hide the language selector dropdown
function toggleLanguageSelector() {
  selectLanguage.value = !selectLanguage.value;
}

async function setLanguage(language: string, toggleSelector: boolean) {
  logEvent(`onSelectedLanguageChanged ${language}`);
  selectedLanguage.value = language;
  userStore.selectedLanguage = language;
  localStorage.setItem("selectedLanguage", language);
  loadLanguage(language);
  refreshCachedStrings();
  if (toggleSelector) {
    toggleLanguageSelector();
  }
  try {
    await unifiedApi.setLangCookie(language);
  } catch (err) {
    logException(err as AxiosError, "Language Selector setLangCookie");
  }

  const url = new URL(window.location.href);
  url.searchParams.set("lang", language);
  // adds a new entry in the browser session history. It doesn't reloads the page
  window.history.pushState({ lang: language }, "", url.toString());

  // google recaptcha lang refresh happens only on page reload.
  if (route.name === "create_account" && toggleSelector) {
    router.go(0);
  }
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
.language-selector {
  display: none;
}
.language-selector .icon {
  fill: white;
  margin-right: var(--space-quarter);
  height: var(--icon-sm);
  width: var(--icon-sm);
  top: 3px;
  margin-left: var(--space);
}
.language-selector .icon-xs {
  fill: white;
  width: 8px;
  margin-left: var(--space-quarter);
}
.language-selector select {
  color: white;
  width: 85%;
  border: none;
  background-color: var(--text-color);
  font-family: var(--opensans);
  font-size: var(--regular-text);
}
.language-selector select option {
  padding: var(--space-quarter) 0;
}
.mobile-expanded-nav .language-selector {
  display: block;
}
.mobile-expanded-nav .language-selector > button {
  text-align: left;
  padding-left: var(--space);
  margin-bottom: 0;
}
.mobile-expanded-nav .language-selector nav {
  left: var(--space-double);
  top: -40px;
}
.current-language {
  color: white;
  padding-left: var(--space-quarter);
}
@media (min-width: 600px) {
  .language-selector {
    display: block;
  }
  .current-language {
    display: none;
  }
}
@media (min-width: 1280px) {
  .language-selector .icon {
    margin-left: 0;
  }
  .language-selector select {
    background-color: var(--darkest-grey);
    width: auto;
  }
}
</style>
