<template>
  <div>
    <section>
      <h1 class="opposite-ends">
        <span class="h1">{{ t("ShippingForm.shipping") }}</span>
        <button
          id="btnEditShipping"
          type="button"
          class="text-sm inline-btn-link only-show-desktop-inline no-print"
          @click="editShipping()"
        >
          {{ t("Common.edit") }}
        </button>
      </h1>
      <ul id="shipping-address" class="mb-double">
        <li id="shippingAddressFullName">
          {{ buyFlowStore.shippingAddress?.firstName }}
          {{ buyFlowStore.shippingAddress?.lastName }}
        </li>
        <li id="shippingAddress1">{{ buyFlowStore.shippingAddress?.address1 }}</li>
        <li v-if="buyFlowStore.shippingAddress?.address2" id="shippingAddress2">
          {{ buyFlowStore.shippingAddress?.address2 }}
        </li>
        <li id="shippingAddress3">
          {{
            `${buyFlowStore.shippingAddress?.city}, ${buyFlowStore.shippingAddress?.state} ${buyFlowStore.shippingAddress?.zipCode}`
          }}
        </li>
        <li id="shippingAddressCountry">{{ buyFlowStore.shippingAddress?.country }}</li>
      </ul>
    </section>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { onBeforeMount } from "vue";
import { usePayPalStore } from "@/stores/payPal";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { layoutStates } from "@/components/Buy/BuyEnums";
import { logEvent } from "@/common/logger";

const componentName = "DisplayPayPalShippingInfo";
const paypalStore = usePayPalStore();
const buyFlowStore = useBuyFlowStore();

onBeforeMount(() => {
  logEvent("Created", componentName);
  logEvent("paypalStore", "payPalShippingAddress", paypalStore.payPalShippingAddress);
});

function editShipping() {
  buyFlowStore.layoutState = layoutStates.editPayPalShippingAddress;
}
</script>
