import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useRoute } from "vue-router";
import { FLAGS } from "@/define";
import { KeyCodeSeller } from "@/globalEnums";

export async function getLandingPage(): Promise<string> {
  const subscriptionsStore = useSubscriptionsStore();
  await subscriptionsStore.populateSubscriptions();
  const subscriptions = subscriptionsStore.subscriptions;
  const route = useRoute();
  const nextRoute =
    FLAGS.SHOW_HOME_COMPONENT === "false"
      ? subscriptions.some(s => s.keyCodeSellerType !== KeyCodeSeller.Safe)
        ? "downloads"
        : "backup"
      : "home";

  // Get queryStrings and forward them in the route
  let jsonQueryStrings = "";
  if (route?.query) {
    jsonQueryStrings = JSON.stringify(route.query);
    jsonQueryStrings = jsonQueryStrings
      .replaceAll("{", "")
      .replaceAll("}", "")
      .replaceAll(" ", "")
      .replaceAll(":", "=")
      .replaceAll(`"`, ``)
      .replaceAll(`,`, `&`);
  }

  return `/${nextRoute}?${jsonQueryStrings}`;
}
