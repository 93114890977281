<template>
  <div>
    <article>
      <div class="info">
        <ul>
          <li class="mb">
            <strong>{{ tn("statement1") }}</strong>
          </li>
          <li>
            <strong>{{ tn("statement2") }}</strong>
          </li>
          <li class="mb">
            <p>{{ tn("statement3") }}</p>
          </li>
        </ul>
      </div>
    </article>
    <footer>
      <div>
        <button id="btnStartFreeTrial" class="btn-outline-primary mr-quarter" @click="startTrial">
          {{ tn("startTrialCTA") }}
        </button>
        <button id="btnBuySubNow" class="btn-outline-primary" @click="buyNow">{{ tn("buyNowCTA") }}</button>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { getUrl } from "@/common/getUrl";
import { FLAGS } from "@/define";
import { useRouter } from "vue-router";

const name = "BuyOrTryCarboniteComponent";

const props = defineProps({
  personId: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["show-start-trial"]);

logEvent("created", name);
const router = useRouter();

const showBuyFlow = computed(() => {
  return FLAGS.ENABLE_BUYFLOW === "true";
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function buyNow() {
  if (showBuyFlow.value) {
    router.push({
      name: "selectPlan",
      query: { sku: "PersonalBasic" },
    });
  } else {
    const carboniteBuyLink =
      getUrl("CARBONITE_BUY_URL") + "/selectplan?personId=" + props.personId + "&sku=PersonalBasic";
    location.href = carboniteBuyLink;
    logEvent("Carbonite Buy Link", name, carboniteBuyLink);
  }
}

function startTrial() {
  emits("show-start-trial");
  logEvent("show-start-trial Event", name, "Emitted");
}
</script>
<style scoped lang="css">
@import "@/styles/variables.css";
</style>
