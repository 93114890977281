<template>
  <div class="flex-start-md">
    <p>
      <span>
        <svg-icon-component icon="circle-information" class="icon-sm" />
      </span>
      <i18n-t keypath="NotificationsComponent.LicenseDaysRemaining" tag="span" scope="global">
        <template #replacementText>
          {{ props.notification?.params?.remainingDays ?? "" }}
        </template>
      </i18n-t>
    </p>
  </div>
</template>

<script setup lang="ts">
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { PropType } from "vue";
import { INotification } from "@/stores/notificationsInterfaces";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    required: true,
  },
});
</script>
