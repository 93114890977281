<template>
  <button v-if="showLink" class="btn-link left-link" @click="go()">
    <svg-icon-component icon="chevron-left" class="icon-sm" />
    {{ t("SelectPlanLink.linkText") }}
  </button>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { useRoute, useRouter } from "vue-router";
import SvgIconComponent from "../shared/SvgIcon/SvgIcon.vue";
import { pageStates } from "@/components/Buy/BuyEnums";
import { buildQueryString } from "@/components/Buy/BuyHelper";
import { REFERRAL_URL_TRUSTED_DOMAINS, URLS } from "@/define";
import { logEvent } from "@/common/logger";
import { computed, ref } from "vue";

const route = useRoute();
const buyFlowStore = useBuyFlowStore();
const router = useRouter();

const referralUrl = ref<string>(
  route.query.referralUrl?.toString() ||
    route.query.referralurl?.toString() ||
    route.query.rurl?.toString() ||
    buyFlowStore.referralUrl ||
    ""
);

const showLink = computed(() => {
  const basicCheck = buyFlowStore.hasCartItems && buyFlowStore.pageState !== pageStates.reviewOrder;

  if (buyFlowStore.hasWebrootCartItems()) {
    return basicCheck && referralUrl.value;
  } else {
    return basicCheck;
  }
});

function go() {
  if (referralUrl.value) {
    try {
      const url = new URL(referralUrl.value);
      if (REFERRAL_URL_TRUSTED_DOMAINS.find(l => l === url.hostname)) {
        window.location.href = `${referralUrl.value}?${buildQueryString(route.query, ["referralUrl", "rurl"])}`;
        return;
      }
    } catch {
      //The URL is bad so don't use it
      logEvent("Referral url is not valid, it will be ignored", "SelectPlanLink");
    }
  }

  //Referral URL is not trusted, use the default
  if (buyFlowStore.hasWebrootCartItems()) {
    window.location.href = URLS.WEBROOT_COM_SITE;
  } else {
    router.push({ name: "selectPlan" });
  }
}
</script>
