<template>
  <ModalWrapperComponent
    dialog-name="AddVatNumberComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn(props.isFromBuyflow ? "headerBuyflow" : "headerH1") }}
    </template>
    <template #content>
      <ul class="form">
        <li class="mb">
          <label for="textVatId">{{ tn("vatId") }}</label>
          <InputErrorComponent :show="hasVatIdErrors && hasVatIdErrors.length > 0">
            <input id="textVatId" v-model="vatId" class="vatId" type="text" />
            <template #error>
              <div>
                <span v-for="e in hasVatIdErrors" :key="e">{{ tn(e) }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li v-if="!props.isFromBuyflow" class="mb">
          <label for="countrySelect">{{ tn("country") }}</label>
          <select id="countrySelect" v-model="countryCode" class="select-css">
            <option value="" selected disabled>
              {{ tn("countryPlaceholder") }}
            </option>
            <option
              v-for="country in props.vatInfo?.euMemberCountries"
              :key="country.countryCode"
              :value="country.countryCode"
            >
              {{ country.country }}
            </option>
          </select>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">{{ tn("cancel") }}</button>
      <button
        id="btnSaveChanges"
        type="button"
        class="btn-primary"
        :disabled="!isSaveEnabled || isSaving"
        @click="save()"
      >
        <spinner :is-spinning="isSaving" />
        {{ tn(props.isFromBuyflow ? "add" : "ok") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import { ISubscriberVatInfo } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { useNotificationsStore, NotificationType } from "@/stores/notifications";
import InputErrorComponent from "@/components/shared/InputError.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";

const componentName = "AddVatNumberComponent";

const props = defineProps({
  vatInfo: {
    type: Object as PropType<ISubscriberVatInfo>,
    default: null,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
  isFromBuyflow: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(["close-modal", "save-modal"]);

logEvent("created", componentName, props.vatInfo);

const notificationsStore = useNotificationsStore();
const isSaving = ref<boolean>(false);
const vatId = ref<string>("");
const countryCode = ref<string>("");
const faultCodes = ref<string[]>(["INVALID_INPUT", "SERVICE_UNAVAILABLE", "MS_UNAVAILABLE", "TIMEOUT", "SERVER_BUSY"]);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function save() {
  isSaving.value = true;
  try {
    notificationsStore.clearNotifications();
    const vatInfoResponse = (
      await unifiedApi.setVatInfo(vatId.value, props.isFromBuyflow ? props.vatInfo.countryCode : countryCode.value)
    ).data;
    if (vatInfoResponse.success) {
      emits("save-modal", "OK", vatId.value);
    } else {
      processAlert(vatInfoResponse.faultCode);
    }
  } catch (e) {
    handleApiError(e as AxiosError);
  }
  isSaving.value = false;
}

function close() {
  emits("close-modal");
}

const isSaveEnabled = computed(() => {
  return (
    hasVatIdErrors.value.length === 0 &&
    ((countryCode.value.length > 0 && vatId.value.length > 0) || (props.isFromBuyflow && vatId.value.length > 0))
  );
});

function processAlert(faultCode: string) {
  notificationsStore.clearNotifications();
  let notificationMessage = props.isFromBuyflow
    ? ("VatError_BUY_GENERIC_ERROR" as NotificationType)
    : ("VatError_Default" as NotificationType);
  if (faultCodes.value.includes(faultCode)) {
    notificationMessage = `VatError_${faultCode}` as NotificationType;
  }
  notificationsStore.addNotification({ type: notificationMessage });
}

const hasVatIdErrors = computed(() => {
  return vatId.value === "" ? [] : validateVatId();
});

function validateVatId() {
  const regex = new RegExp("^[a-zA-Z0-9]{8,15}$");
  if (!regex.test(vatId.value)) {
    return ["invalidVatIdFormat"];
  }

  return [];
}

function beforeOpen() {
  notificationsStore.clearNotifications();
  countryCode.value = "";
  vatId.value = "";
}
</script>
