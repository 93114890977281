<template>
  <ModalWrapperComponent
    dialog-name="LastPassDialogComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("title") }}
    </template>

    <template #content>
      <p>{{ tn("p1") }}</p>
      <label for="passPhrase">{{ tn("label1") }}</label>
      <input id="passPhrase" v-model="passPhrase" type="text" />
    </template>

    <template #footer>
      <div class="text-right">
        <button id="btnClose" type="button" class="btn-link mr" @click="close()">{{ tn("closeBtn") }}</button>
        <button id="btnContinue" type="button" class="btn-primary" :disabled="!passPhrase" @click="submit()">
          {{ tn("continueBtn") }}
        </button>
      </div>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { IPassPhraseDialogResult, IRfaItem } from "@/components/FileAccess/interfaces";

const componentName = "PassPhraseDialogComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
  rfaItem: {
    type: Object as PropType<IRfaItem>,
    required: false,
    default: null,
  },
  isMultiDownload: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName);

const passPhrase = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function close() {
  emits("close-modal");
}

function beforeOpen() {
  passPhrase.value = "";
}

function submit() {
  const dialogResult: IPassPhraseDialogResult = {
    item: props.rfaItem,
    passPhrase: passPhrase.value,
    isMultiDownload: props.isMultiDownload,
  };
  emits("save-modal", dialogResult);
}
</script>
