import { unifiedApi } from "@/common";
import { ISecurityState } from "@/common/api/unifiedPortal/interfaces";
import { LAST_API_FETCH_INTERVAL } from "@/define";
import { logEvent, logException } from "@/common/logger";
import { defineStore } from "pinia";

export const useSecurityStore = defineStore("security", {
  state: (): ISecurityState => ({
    webrootDeviceResult: null,
    totalDevices: 0,
    totalShields: 0,
    totalEnabledShields: 0,
    hasExpiredLicenses: false,
    lastRequestTime: 0,
  }),
  actions: {
    async getSecurity() {
      try {
        const currentTime = new Date().getTime();
        if (currentTime - this.lastRequestTime >= LAST_API_FETCH_INTERVAL) {
          // API call
          const res = (await unifiedApi.getSkyWebrootDevices()).data;
          this.$state.webrootDeviceResult = res.webrootDeviceResult;
          this.$state.lastRequestTime = new Date().getTime();
          this.$state.totalDevices = res.totalDevices;
          this.$state.totalEnabledShields = res.totalEnabledShields;
          this.$state.totalShields = res.totalShields;
          this.$state.hasExpiredLicenses = res.hasExpiredLicenses;
          logEvent("security state updated", "security store", this.$state);
          return this.$state;
        }
        return this.$state;
      } catch (err) {
        logException(err as Error);
        // throw err as it handled in calling method.
        throw err;
      }
    },
    //If the user has updated data we want to refresh the store next time they come to the page
    //This is a lot easier than trying to dig through 2 levels of arrays to update the store for the next time they come to the page
    forceRefresh() {
      this.lastRequestTime = new Date().getTime() - LAST_API_FETCH_INTERVAL;
      logEvent("Force Security store refresh");
    },
  },
});
