import i18n from "@/i18n";
import { webrootLicenseCategories } from "@/common/webrootProductName";

function tn(value: string) {
  return i18n.global.t(`AllstateUpgradeComponent.${value}`);
}

export function listFeaturesForComparison() {
  const features = [
    {
      id: 1,
      feature: tn("financialMonitoring"),
      subFeatures: [
        tn("creditAndDebitCards"),
        tn("bankAccountTransactions"),
        tn("investmentAccounts"),
        tn("accountTakeoverMonitoring"),
      ],
      webrootPremium: "yes",
      addOn: "yes",
    },
    {
      id: 2,
      feature: tn("advancedDarkWebMonitoring"),
      subFeatures: [
        tn("humanSourcedIntelligence"),
        tn("ssnMonitoring"),
        tn("idMonitoring"),
        tn("creditDebitScan"),
        tn("breachNotifications"),
      ],
      webrootPremium: "yes",
      addOn: "yes",
    },
    {
      id: 3,
      feature: tn("identityMonitoring"),
      subFeatures: [tn("healthStatus"), tn("emergingThreatAndScamAlerts")],
      webrootPremium: "yes",
      addOn: "yes",
    },
    {
      id: 4,
      feature: tn("restoration"),
      subFeatures: [tn("customerCare"), tn("remediationSupport")],
      webrootPremium: "yes",
      addOn: "yes",
    },
    {
      id: 5,
      feature: tn("lostWalletProtection"),
      subFeatures: [],
      webrootPremium: "yes",
      addOn: "yes",
    },
    {
      id: 6,
      feature: tn("expenseReimbursement"),
      subFeatures: [],
      webrootPremium: tn("expenseReimbursementWebrootPremium"),
      addOn: tn("expenseReimbursementAddOn"),
    },
    {
      id: 7,
      feature: tn("stolenFundsReimbursement"),
      subFeatures: [],
      webrootPremium: "yes",
      addOn: "",
    },
    {
      id: 8,
      feature: tn("oneBureauCreditMonitoring"),
      subFeatures: [tn("rapidAlertDetection"), tn("autoOnCapability"), tn("transUnion")],
      webrootPremium: "yes",
      addOn: "",
    },
  ];
  return features;
}

export function listFeaturesAfterSetUp() {
  const features = [
    {
      id: 0,
      types: [webrootLicenseCategories.WSAP, webrootLicenseCategories.WSID],
      feature: tn("financialMonitoring"),
      subFeatures: [
        tn("creditAndDebitCards"),
        tn("bankAccountTransactions"),
        tn("investmentAccounts"),
        tn("accountTakeoverMonitoring"),
      ],
      column: 1,
    },
    {
      id: 1,
      types: [webrootLicenseCategories.WSAP, webrootLicenseCategories.WSID],
      feature: tn("advancedDarkWebMonitoring"),
      subFeatures: [
        tn("humanSourcedIntelligence"),
        tn("ssnMonitoring"),
        tn("idMonitoring"),
        tn("creditDebitScan"),
        tn("breachNotifications"),
      ],
      column: 1,
    },
    {
      id: 2,
      types: [webrootLicenseCategories.WSAP, webrootLicenseCategories.WSID],
      feature: tn("identityMonitoring"),
      subFeatures: [tn("healthStatus"), tn("emergingThreatAndScamAlerts")],
      column: 1,
    },
    {
      id: 3,
      types: [webrootLicenseCategories.WSAP, webrootLicenseCategories.WSID, webrootLicenseCategories.WSID],
      feature: tn("restoration"),
      subFeatures: [tn("customerCare"), tn("remediationSupport")],
      column: 2,
    },
    {
      id: 4,
      types: [webrootLicenseCategories.WSAP],
      feature: tn("reimbursement"),
      subFeatures: [tn("expenseReimbursementWebrootPremiumFullFeature"), tn("lostWalletProtection")],
      column: 2,
    },
    {
      id: 5,
      types: [webrootLicenseCategories.WSID],
      feature: tn("expenseReimbursement"),
      subFeatures: [tn("expenseReimbursementAddOnFullFeature"), tn("lostWalletProtection")],
      column: 2,
    },
    {
      id: 6,
      types: [webrootLicenseCategories.WSAP],
      feature: tn("oneBureauCreditMonitoring"),
      subFeatures: [tn("rapidAlertDetection"), tn("autoOnCapability"), tn("transUnion")],
      column: 2,
    },
  ];
  return features;
}
