// *************
// search functions
// *************

import { computed, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { clearSelection } from "./selectionFn";
import { currentPage, currentPageSize, storedPageSize } from "./paginationFn";
import { initSortOptions, sortDirection, sortField, sortItems } from "./sortFn";
import { getCurrentItemImagesAndAlerts } from "./imagePreviewFn";
import { breadcrumbItems, currentDisk, computer, selectedDisk } from "./diskFn";
import { getSearchResults, getComputerContents } from "./serverComm";
import { componentName, addLocalValues, allItems, currentItems, propData, isSmb } from "./commonFn";
import { selectView, setView } from "./viewFn";
import { ViewTypes } from "./FileAccessEnums";

const MAX_SEARCH_ITEMS = 257; //using a weird number here so when the user sees the count increase, it isn't by some obvious round number

//this holds the live value in the search box
export const searchText = ref<string>();
//this holds the text sent to the API and remembers
export const calledSearchText = ref<string>();
export const isSearching = ref<boolean>(false);
export const searchResultsText = computed(() => {
  //CSSB results give each value a 'TotalItems' property. This indirectly tells us it is a CSSB search.
  return t(`${componentName}.searchResults`, {
    results: allItems.value[0]?.TotalItems ? allItems.value[0].TotalItems : allItems.value.length,
    term: isSmb.value ? calledSearchText.value : searchText.value,
  });
});

//figure out the full path to what's visible
function getCurrentPath() {
  let path: string | undefined;
  if (breadcrumbItems.value.length > 0) path = breadcrumbItems.value[breadcrumbItems.value.length - 1].Path;
  else path = computer.value?.HardDisks.find(h => h.FileId == currentDisk.value)?.Path;

  logEvent("current path", componentName, path);
  return path;
}

//search for matching files
export async function handleSearch() {
  logEvent("handleSearch", componentName, searchText.value);

  isSearching.value = true;

  initSortOptions(isSmb.value, false);

  //clear out any existing items
  allItems.value = [];
  currentItems.value = [];
  currentPage.value = 0;
  clearSelection();

  //if we have valid search text
  if (searchText.value && searchText.value.trim().length > 0) {
    const pathToSearch = getCurrentPath();
    let disk = computer.value?.HardDisks.find(h => h.FileId == currentDisk.value);

    if (isSmb.value) {
      //Check the bread crumbs and see if we are on the backup runs step
      disk = breadcrumbItems.value.some(l => l.IsBackupSet) ? breadcrumbItems.value[0] : disk;
      setView(ViewTypes.list);
    }

    if (pathToSearch || isSmb.value) {
      //set the page size to 10 for cross-backup-run search
      if (!selectedDisk.value?.IsBackupRun) {
        storedPageSize.value = currentPageSize.value;
        currentPageSize.value = 10;
      }
      //get the first batch of results
      allItems.value = await getSearchResults({
        path: pathToSearch ?? "",
        filter: searchText.value,
        start: 0,
        maxResults: MAX_SEARCH_ITEMS,
        backupSetName: disk ? disk.Name : "",
        backupRunTime: selectedDisk.value?.IsBackupRun ? selectedDisk.value.UnixTime : 0,
      });
      //tidy them up and display them (assume MAX_SEARCH_ITEMS will always be larger than a page)
      currentPage.value = 0;
      selectView(addLocalValues());
      sortItems(sortField.value, sortDirection.value, true);
      await getCurrentItemImagesAndAlerts(0, currentPageSize.value);

      if (!isSmb.value) {
        //if there are still more to get, go get them until returnedFiles is less than what we asked for
        let returnedFiles = allItems.value.length;
        while (returnedFiles == MAX_SEARCH_ITEMS) {
          const files = await getSearchResults({
            path: pathToSearch ?? "",
            filter: searchText.value,
            start: allItems.value.length,
            maxResults: MAX_SEARCH_ITEMS,
            backupSetName: "",
            backupRunTime: 0,
          });
          returnedFiles = files.length;
          //add to all
          allItems.value.push(...files);
          currentPage.value = 0;
        }
      }
      //can't decide whether to continually sort as new things come in or to wait
      //going to wait for now and see the user experience
      selectView(addLocalValues());
      sortItems(sortField.value, sortDirection.value, true);
    }
  } else {
    clearSearch();
  }
}

export function clearSearchUI() {
  isSearching.value = false;
  searchText.value = "";
  currentPageSize.value = storedPageSize.value;
}

//put back the results for the current path
export async function clearSearch() {
  logEvent("clearSearch", componentName, searchText.value);
  calledSearchText.value = "";
  clearSearchUI();
  const pathToShow = getCurrentPath();
  currentPage.value = 0;
  if (pathToShow || isSmb.value) {
    allItems.value = await getComputerContents({
      path: pathToShow ?? "",
      computerName: propData.value.computerName,
      currentDisk: selectedDisk.value,
    });
    if (allItems.value.some(i => i.IsBackupRun)) {
      initSortOptions(isSmb.value, true);
    } else {
      initSortOptions(isSmb.value, false);
    }
    const imageCount = addLocalValues();
    selectView(imageCount);
    sortItems(sortField.value, sortDirection.value, true);
    await getCurrentItemImagesAndAlerts(0, currentPageSize.value);
  }
}
