<template>
  <section class="card">
    <header :class="{ visibleArticle: show }" @click="toggleCdlpTile()">
      <h3>{{ t("Brand.WebrootBackupAndRestore") }}</h3>
      <div>
        <SvgIconComponent v-if="showCdlpTile" icon="collapse-minus-sign" class="icon-sm" />
        <SvgIconComponent v-else icon="expand-plus" class="icon-sm" />
      </div>
    </header>
    <article v-if="showCdlpTile" class="visibleArticle">
      <KbSearchWebroot />
      <ul v-if="backupPageStore.pageState === backupPageState.default">
        <li v-if="props.device && !cdlpSubNoDeviceId" class="mb">
          <CardComputerComponent
            :device="props.device"
            :is-cdlp="true"
            :subscription="getCdlpSubscriptions(props.device)"
            :user-id="user?.personId"
            :display-forced-reinstall-modal="props.device.deviceId === props.computerIdToReinstall"
            @set-installer-type="setInstallerType"
            @show-invoice-details="showOutstandingInvoiceDetails"
            @change-payment-method="changePaymentMethod"
            @auto-renew-updated="autoRenewUpdated"
            @move-payment-method-mr-to-ar="movePaymentMethodMRtoAR"
            @update-device-keycode="handleUpdateKeycode"
          />
        </li>
      </ul>
      <div v-if="cdlpSubNoDeviceId" class="card">
        <WebrootTotalProtectionNotInstalled :device="props.device" />
      </div>
    </article>
  </section>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import CardComputerComponent from "./CardComputer/CardComputer.vue";
import { IDevice, IDeviceKeycodeUpdateRequest, ISubscription } from "@/common/api/unifiedPortal/interfaces";
import KbSearchWebroot from "@/components/shared/kbSearchWebroot.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { t } from "@/i18n";
import { useBackupPageStore } from "@/stores/backupPage";
import { pageState as backupPageState } from "@/globalEnums";
import { webrootCDLP } from "@/common/webrootProductName";
import { useUserStore } from "@/stores/user";
import WebrootTotalProtectionNotInstalled from "./WebrootTotalProtectionNotInstalled.vue";

const backupPageStore = useBackupPageStore();
const showCdlpTile = ref<boolean>(true);
const show = ref<boolean>(true);
const userStore = useUserStore();

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  subs: {
    type: Object as PropType<ISubscription[]>,
    required: true,
  },
  computerIdToReinstall: {
    type: Number,
    default: -1,
  },
});

const emits = defineEmits([
  "show-invoice-details",
  "set-installer-type",
  "change-payment-method",
  "auto-renew-updated",
  "move-payment-method-mr-to-ar",
  "update-device-keycode",
]);

const user = computed(() => {
  return userStore.currentUser;
});

const cdlpSubNoDeviceId = computed(() => {
  return props.device.deviceId === 0 || null;
});

function showOutstandingInvoiceDetails() {
  emits("show-invoice-details", props.device.deviceId);
}

function toggleCdlpTile() {
  return (showCdlpTile.value = !showCdlpTile.value);
}

function setInstallerType(device: IDevice, type: string) {
  emits("set-installer-type", device, type);
}
function changePaymentMethod() {
  emits("change-payment-method");
}

function autoRenewUpdated(subscription: ISubscription) {
  emits("auto-renew-updated", subscription);
}

function movePaymentMethodMRtoAR(subscription: ISubscription) {
  emits("move-payment-method-mr-to-ar", subscription);
}

function handleUpdateKeycode(request: IDeviceKeycodeUpdateRequest) {
  emits("update-device-keycode", request);
}

function getCdlpSubscriptions(device: IDevice): ISubscription | undefined {
  const onlyCDLPsubscriptions = props.subs.filter(d => webrootCDLP.some(l => l === d.sku));
  return onlyCDLPsubscriptions.find(s => s.zuoraSubscriptionNumber === device.zuoraSubscriptionNumber);
}
</script>
