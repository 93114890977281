<template>
  <article>
    <header>
      <div>
        <svg-icon-component :icon="cardTypeImgPath" class="mr-quarter" />
      </div>
      <div>
        <ul>
          <li>
            {{ tn("endsIn") }}
            <span :id="`cardLastDigits${card?.id}`">
              <strong>{{ card?.lastDigits.slice(-5) }}</strong>
            </span>
          </li>
          <li>
            {{ tn("expires") }}
            <span id="cardExpDate">
              <strong>{{ expirationDate() }}</strong>
            </span>
          </li>
        </ul>
      </div>
    </header>
    <div>
      <ul>
        <li class="cardAccountHolder">{{ card?.accountHolder }}</li>
        <li v-if="card?.address.streetAddress1" id="sAdress1" class="cardAddress1">
          {{ card?.address.streetAddress1 }}
        </li>
        <li v-if="card?.address.streetAddress2" id="sAdress2" class="cardAddress2">
          {{ card?.address.streetAddress2 }}
        </li>
        <li v-if="card?.address.streetAddress3" id="sAdress3" class="cardAddress3">
          {{ card?.address.streetAddress3 }}
        </li>
        <li>
          <span id="city" class="cardCity">{{ card?.address.cityName }}</span>
          <span v-if="card?.address.cityName">,</span>
          <span v-if="card?.address.country === 'United States'" id="state" class="cardState">
            {{ card?.address.stateOrProvince }}
          </span>
        </li>
        <li class="cardPostalCode">{{ card?.address.postalCode }}</li>
        <li id="country" class="cardCountry">{{ card?.address.country }}</li>
      </ul>
    </div>
  </article>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IPaymentMethod } from "@/common/api/unifiedPortal/interfaces";
import { formatCCDateString } from "@/common/dateFormat";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const cardMap = {
  americanexpress: "cc-americanexpress",
  discover: "cc-discover",
  jcb: "cc-jcb",
  mastercard: "cc-mastercard",
  paypal: "cc-paypal",
  visa: "cc-visa",
};

const componentName = "CardCreditComponent";

const props = defineProps({
  card: {
    type: Object as PropType<IPaymentMethod | null>,
    required: true,
  },
});

const cardTypeImgPath = computed(() => {
  return props.card?.cardType ? cardMap[props.card?.cardType.toLowerCase()] : "";
});

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function expirationDate() {
  return formatCCDateString(props.card?.expirationDate ?? "");
}
</script>
