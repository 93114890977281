<template>
  <ModalWrapperComponent dialog-name="UpgradeToProComponent" :show-dialog="props.showDialog" @close-modal="close">
    <template #header>
      {{ tn("header") }}
    </template>

    <template #content>
      <p>{{ tn("paragraph1") }}</p>
      <p>{{ tn("paragraph2") }}</p>
      <ul class="check_bullets">
        <li>
          <svg-icon-component icon="circle-check" />
          {{ tn("bulletPoint1") }}
        </li>
        <li>
          <svg-icon-component icon="circle-check" />
          {{ tn("bulletPoint2") }}
        </li>
        <li>
          <svg-icon-component icon="circle-check" />
          {{ tn("bulletPoint3") }}
        </li>
        <li>
          <svg-icon-component icon="circle-check" />
          {{ tn("bulletPoint4") }}
        </li>
      </ul>
      <div class="support_contact mb">
        <h3>{{ tn("questions") }}</h3>
        <p>
          {{ tn("phoneNumber") }} |
          <a :href="'mailto:' + tn('smallBusinessBackupEmail')">{{ tn("smallBusinessBackupEmail") }}</a>
        </p>
      </div>
    </template>

    <template #footer>
      <button class="btn-primary" @click="redirectToBuyFlow()">
        {{ tn("clickHere") }}
      </button>
      <button type="button" class="btn-link" @click="close()">
        {{ tn("noThanks") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { useUserStore } from "@/stores/user";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";
import { computed } from "vue";

const componentName = "UpgradeToProComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["close-modal"]);

logEvent("created", componentName);

const userStore = useUserStore();
const catId = "239005";
const purchaseType = "Upgrade";
const sku = "OfficeCore";

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const user = computed(() => {
  return userStore.currentUser;
});

function redirectToBuyFlow() {
  const redirectUrl = `${getUrl("CARBONITE_BUY_URL")}/selectplan?personId=${
    user.value?.personId
  }&catId=${catId}&purchaseType=${purchaseType}&sku=${sku}`;
  window.location.href = redirectUrl;
}

function close() {
  emits("close-modal");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

h1 {
  text-transform: uppercase;
  border-bottom: 0;
  padding-bottom: 0;
}
.modalFooter {
  display: inline;
}
ul.check_bullets {
  padding-left: var(--space);
}
ul.check_bullets li {
  line-height: var(--space);
  padding-left: var(--space-three-quarter);
  list-style-type: none;
}
ul.check_bullets svg {
  width: var(--icon-xs);
  height: var(--icon-xs);
  fill: var(--medium-dark-grey);
  top: 3px;
}
.support_contact {
  background: var(--light-grey);
  display: block;
  text-align: center;
  line-height: var(--modal-space);
}
.support_contact p {
  padding: var(--space-quarter);
}
.support_contact p a {
  outline: none;
}
</style>
