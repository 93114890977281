import { cpuArchitecture, cpuDetect } from "@/common/cpuDetect";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useSiteSettingsStore = defineStore("siteSettings", () => {
  const hasPendingEmailVerification = ref(false);
  const installDeviceFromSubscriptionPage = ref(false);
  const activationCodeAppliedSuccess = ref(false);
  const moveMySubscriptionSuccess = ref(false);
  const activationCodeToInstall = ref("");
  const redirectPreliminaryUserToBackup = ref(false);
  const userCpuArchitecture = ref<cpuArchitecture | null>();

  //Returns the user's CPU Architecture
  async function getUserCpuArchitecture() {
    if (!userCpuArchitecture.value) {
      userCpuArchitecture.value = await cpuDetect();
    }

    return userCpuArchitecture.value;
  }

  function getIsCreateAccountFlow(): boolean {
    return sessionStorage.getItem("isCreateAccountFlow") === "true";
  }

  function setIsCreateAccountFlow(value: boolean) {
    sessionStorage.setItem("isCreateAccountFlow", value ? "true" : "false");
  }

  function getShowedVerifyEmailDialogAfterCreateAccountFlow(): boolean {
    return sessionStorage.getItem("showedVerifyEmailDialogAfterCreateAccountFlow") === "true";
  }

  function setShowedVerifyEmailDialogAfterCreateAccountFlow(value: boolean) {
    sessionStorage.setItem("showedVerifyEmailDialogAfterCreateAccountFlow", value ? "true" : "false");
  }

  function getSubscriberRefreshedAfterLogin(): boolean {
    return sessionStorage.getItem("subscriberRefreshedAfterLogin") === "true";
  }

  function setSubscriberRefreshedAfterLogin(value: boolean) {
    sessionStorage.setItem("subscriberRefreshedAfterLogin", value ? "true" : "false");
  }

  function getSkipAccountSetup(): boolean {
    return sessionStorage.getItem("skipAccountSetup") === "true";
  }

  function setSkipAccountSetup(value: boolean) {
    return sessionStorage.setItem("skipAccountSetup", value ? "true" : "false");
  }

  // Since we are using setup instead of options, we need to create a function to reset the state
  // See more on: https://pinia.vuejs.org/core-concepts/state.html - Resetting the State
  function reset() {
    hasPendingEmailVerification.value = false;
    installDeviceFromSubscriptionPage.value = false;
    activationCodeAppliedSuccess.value = false;
    moveMySubscriptionSuccess.value = false;
    activationCodeToInstall.value = "";
    redirectPreliminaryUserToBackup.value = false;
  }

  return {
    hasPendingEmailVerification,
    installDeviceFromSubscriptionPage,
    activationCodeAppliedSuccess,
    moveMySubscriptionSuccess,
    activationCodeToInstall,
    getIsCreateAccountFlow,
    getShowedVerifyEmailDialogAfterCreateAccountFlow,
    getSubscriberRefreshedAfterLogin,
    setIsCreateAccountFlow,
    setShowedVerifyEmailDialogAfterCreateAccountFlow,
    setSubscriberRefreshedAfterLogin,
    getSkipAccountSetup,
    setSkipAccountSetup,
    redirectPreliminaryUserToBackup,
    reset,
    getUserCpuArchitecture,
  };
});
