<template>
  <p><SvgIconComponent v-if="td.installData?.logo" :icon="td.installData?.logo" /></p>
  <p>{{ t(`${textRoot}${td.installData?.mainText}`) }}</p>
  <i18n-t v-if="!isPurchased" :keypath="`${textRoot}${td.installData?.auxText}`" tag="p" scope="global">
    <template #protect>
      <strong>{{ t(`${textRoot}${td.installData?.protectText}`) }}</strong>
    </template>
  </i18n-t>
  <div class="side-by-side">
    <button
      :id="`btnInstallOrCompare_${props.tileData.title}`"
      class="btn-primary no-shrink mr-half"
      type="button"
      @click="buttonClick()"
    >
      {{ t(`${textRoot}${td.installData?.installButtonText}`) }}
    </button>
    <small v-if="isPurchased && td.state !== StateType.securityBlocked" class="text-sm">
      {{ t(`${textRoot}${td.installData?.installText}`) }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ITileData, i18nRoot } from "@/components/Home";
import { StateType } from "@/globalEnums";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const name = "RequiresInstallOrPurchaseComponent";

const props = defineProps({
  tileData: {
    type: Object as PropType<ITileData>,
    required: true,
  },
});

const emits = defineEmits(["button-action"]);

const textRoot = ref<string>(i18nRoot);

logEvent("created", name, props.tileData);
const td = ref<ITileData>(props.tileData);

const isPurchased = computed(() => {
  return td.value.state !== StateType.notPurchased;
});

function buttonClick() {
  emits("button-action");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
