<template>
  <div class="courier">
    <iframe
      id="courierIframe"
      :src="courierURL()"
      width="100%"
      checkorigin="false"
      scrolling="no"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { getUrl } from "@/common/getUrl";
const name = "CourierComponent";

logEvent("created", name);

function courierURL() {
  return getUrl("CARBONITE_UP_URL") + "/courierrecovery/courierform";
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
.courier {
  overflow: hidden;
}
.courier iframe {
  height: 5050px;
  position: relative;
  top: -60px;
}
@media (min-width: 600px) {
  .courier iframe {
    height: 4350px;
  }
}
@media (min-width: 960px) {
  .courier iframe {
    height: 3750px;
  }
}
@media (min-width: 992px) {
  .courier iframe {
    height: 3750px;
    top: -60px;
  }
}
@media (min-width: 1060px) {
  .courier iframe {
    height: 2400px;
  }
}
@media (min-width: 1254px) {
  .courier iframe {
    top: -160px;
  }
}
</style>
