<template>
  <section class="card" :class="calcTileClass()">
    <NotificationsComponent v-if="notificationContext" :context="notificationContext" />
    <header :class="{ visibleArticle: show }" @click="toggleShowArticle">
      <h1><slot name="title"></slot></h1>
      <div :id="titleId">
        <SvgIconComponent v-if="show" icon="collapse-minus-sign" class="icon-sm" />
        <SvgIconComponent v-else icon="expand-plus" class="icon-sm" />
      </div>
    </header>
    <article v-if="show" :class="{ visibleArticle: show }">
      <loading-spinner-component v-if="isLoading"></loading-spinner-component>
      <p v-else-if="isError">{{ t(`${textRoot}apiError`) }}</p>
      <div v-else>
        <slot name="content"></slot>
      </div>
    </article>
  </section>
</template>

<script setup lang="ts">
import { ref, PropType, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IHomePageTiles } from "@/common/api/unifiedPortal/interfaces";
import { useUserStore } from "@/stores/user";
import { ITileData, i18nRoot } from "@/components/Home";
import { StatusType, TileType, StateType } from "@/globalEnums";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import LoadingSpinnerComponent from "@/components/shared/LoadingSpinner.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const name = "TileWrapperComponent";

const props = defineProps({
  tileData: {
    type: Object as PropType<ITileData>,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  notificationContext: {
    type: String,
    required: true,
  },
});

logEvent("created", name, props.tileData);
const userStore = useUserStore();
const td = ref<ITileData>(props.tileData);
const showTileData = ref<IHomePageTiles>(userStore.showTile);
const show = ref<boolean>(showTileData.value[td.value.title] || true);
const textRoot = ref<string>(i18nRoot);

const titleId = computed(() => {
  if (td.value.identifier) {
    return `btnCollapse_${td.value.identifier}`;
  }

  return `btnCollapse_${td.value.title}`;
});

const isError = computed(() => {
  return td.value.state === StateType.serverError;
});

function toggleShowArticle() {
  showTileData.value[td.value.title] = !show.value;
  userStore.showTile = showTileData.value;
  show.value = showTileData.value[td.value.title];
}

function calcTileClass(): Record<string, string> {
  const ic = {};

  switch (td.value.status) {
    case StatusType.warning:
      ic["warning"] = true;
      break;
    case StatusType.danger:
      ic["danger"] = true;
      break;
  }

  switch (td.value.type) {
    case TileType.list:
      ic["list"] = true;
      break;
    case TileType.tile:
      ic["tile"] = true;
      break;
  }

  return ic;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
