<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else>
    <section class="mt m-auto pa-desktop constrain-90">
      <button class="btn-link left-link mb" @click="cancel()">
        <svg-icon-component icon="chevron-left" class="icon-sm" />
        {{ t("EditShipping.back") }}
      </button>
      <h1 class="opposite-ends mb-double">
        <span class="h1">{{ t("BuyPaymentComponent.payment") }}</span>
      </h1>
      <!-- -->
      <ul
        v-if="FLAGS.ENABLE_BUYFLOW_PAYPAL === 'true' && buyFlowStore.currentBrand === openTextBrands.Webroot"
        class="flex-start-md choose-cc-or-pp mb"
      >
        <li class="mr">
          <label class="flex-start pa-half" :class="currentPaymentType === paymentTypes.CreditCard ? 'selected' : ''">
            <input
              id="payment-type-cc"
              v-model="currentPaymentType"
              type="radio"
              name="paymentType"
              value="CreditCard"
              class="mr-quarter"
              @click="setPaymentType(paymentTypes.CreditCard)"
            />
            <svg-icon-component icon="credit-card" class="mr-quarter" />
            {{ t("BuyPaymentComponent.paymentTypeCC") }}
          </label>
        </li>
        <li class="mr">
          <label class="flex-start pa-half" :class="currentPaymentType === paymentTypes.PayPal ? 'selected' : ''">
            <input
              id="payment-type-pp"
              v-model="currentPaymentType"
              type="radio"
              name="paymentType"
              value="PayPal"
              class="mr-quarter"
              @click="setPaymentType(paymentTypes.PayPal)"
            />
            <svg-icon-component icon="paypal-icon" class="mr-quarter" />
            {{ t("BuyPaymentComponent.paymentTypePP") }}
          </label>
        </li>
      </ul>
      <section v-show="currentPaymentType === paymentTypes.CreditCard">
        <section v-if="displayCreditCardDropDown" id="credit-card-drop-down" class="constrain-65">
          <SelectPayment
            :payment-methods="buyFlowStore.creditCards"
            :brand="buyFlowStore.currentBrand as openTextBrands"
            :selected-payment-method-id="selectedPaymentMethodId"
            @add-card="addCard"
            @set-display-card-details-state="displaySelectedCardDetails"
            @newly-selected-payment="updateSelectedPaymentMethodFromDropDown"
          />
        </section>
        <section v-if="displayAddCreditCard" id="credit-card-add-section">
          <AddPaymentMethodComponent
            :show-submit-btn="false"
            :form-submit-button-text="t('Common.reviewOrder')"
            :enable-captcha="false"
            :user-guid="buyFlowStore.userGuid"
            :email="email"
            :computer-id="computerId"
            :pre-populate-form="paymentPageStore.creditAddress"
            @payment-method-added="editPaymentCreditCardSuccessHandler"
            @payment-method-error="editPaymentCreditCardFailureHandler"
          />
        </section>
      </section>
      <div v-if="currentPaymentType === paymentTypes.PayPal" class="mb-double">
        <div v-if="buyFlowStore.isPayPalPayment" class="mb-double">
          <PayPalPaymentDetails />
        </div>
        <div v-else>
          <button class="btn-shell" @click="initiatePayPalRequest()">
            <svg-icon-component icon="paypal-button" class="paypal-svg-175" />
          </button>
        </div>
      </div>
      <ul class="flex-start-md mb-double">
        <li>
          <button id="btnCancel" type="button" class="btn-outline-primary mr" @click="cancel()">
            {{ t("Common.cancel") }}
          </button>
        </li>
        <li>
          <button id="btnSubmit" type="button" class="btn-primary" @click="submit()">{{ tn("savePayment") }}</button>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { t } from "@/i18n";
import { FLAGS } from "@/define";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { useUserStore } from "@/stores/user";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { changePageState, loadPaymentMethods, setPaymentMethodById } from "@/components/Buy/BuyHelper";
import { layoutStates, openTextBrands, pageStates } from "@/components/Buy/BuyEnums";
import { logEvent, logException } from "@/common/logger";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import SelectPayment from "@/components/Buy/Payment/SelectPayment.vue";
import { usePaymentPageStore } from "@/stores/paymentPage";
import AddPaymentMethodComponent from "@/components/shared/AddPaymentMethodComponent.vue";
import { NotificationType } from "@/stores/notificationsInterfaces";
import { useNotificationsStore } from "@/stores/notifications";
import { paymentTypes } from "@/components/Buy/BuyEnums";
import { createPayPalAgreementToken } from "./Payment";
import PayPalPaymentDetails from "@/components/Buy/Payment/PayPalPayment.vue";
import { usePayPalStore } from "@/stores/payPal";

enum editPaymentDisplayStates {
  displayCreditCards = "displayCreditCards",
  addCreditCard = "addCreditCard",
}
const editPaymentDisplayState = ref(editPaymentDisplayStates.displayCreditCards);
const currentPaymentType = ref(paymentTypes.CreditCard);
const componentName = "EditPayment";
const buyFlowStore = useBuyFlowStore();
const isLoading = ref(false);
const paymentPageStore = usePaymentPageStore();
const userStore = useUserStore();
const computerId = computed(() => buyFlowStore.cart?.items[0]?.computerId ?? 0);
const email = computed(() => userStore.currentUser?.email ?? userStore.email);
const notificationsStore = useNotificationsStore();
const payPalStore = usePayPalStore();
const selectedPaymentMethodId = ref(buyFlowStore.creditCard?.id ?? "");

const displayAddCreditCard = computed(() => {
  return editPaymentDisplayState.value === editPaymentDisplayStates.addCreditCard;
});

const displayCreditCardDropDown = computed(() => {
  return (
    userStore.isEstablishedAccount && editPaymentDisplayState.value === editPaymentDisplayStates.displayCreditCards
  );
});

onBeforeMount(() => {
  loadPage();
});

function addCard() {
  editPaymentDisplayState.value = editPaymentDisplayStates.addCreditCard;
}

function displaySelectedCardDetails() {
  editPaymentDisplayState.value = editPaymentDisplayStates.displayCreditCards;
}

async function editPaymentCreditCardSuccessHandler(event) {
  try {
    paymentPageStore.creditAddress = {
      creditCardAddress1: event.detail.creditCardAddress1?.replaceAll("+", " ") || "",
      creditCardAddress2: event.detail.creditCardAddress2?.replaceAll("+", " ") || "",
      creditCardPostalCode: event.detail.creditCardPostalCode?.replaceAll("+", " ") || "",
      creditCardCity: event.detail.creditCardCity?.replaceAll("+", " ") || "",
      creditCardCountry: event.detail.creditCardCountry || "",
      creditCardState: event.detail.creditCardState?.replaceAll("+", " ") || "",
      creditCardHolderName: event.detail.creditCardHolderName?.replaceAll("+", " ") || "",
    };

    buyFlowStore.paymentType = paymentTypes.CreditCard;
    buyFlowStore.updateCreditCard(event.detail.creditCardId);
    logEvent("editPaymentCreditCardSuccessHandler", componentName, event.detail);
    buyFlowStore.creditCards = (await loadPaymentMethods()) || [];
    changePageState(pageStates.reviewOrder);
  } catch (err) {
    logException(err as Error, componentName);
  }
}

async function editPaymentCreditCardFailureHandler(error) {
  logEvent("editPaymentCreditCardFailureHandler", componentName, error);
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: error as NotificationType });
}

async function initiatePayPalRequest() {
  logEvent("Initiating paypal transaction", componentName);
  try {
    const paypalAgreementTokenResponse = await createPayPalAgreementToken();
    if (paypalAgreementTokenResponse) {
      logEvent("Got the paypal agreement token, redirecting to paypal approval url", componentName);
      sessionStorage.setItem("PayPal_token_id", paypalAgreementTokenResponse.tokenId);
      sessionStorage.setItem("BuyFlow_Paypal_cart", JSON.stringify(buyFlowStore.cart));
      window.location.href = paypalAgreementTokenResponse.approvalUrl;
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function setPaymentType(type: paymentTypes) {
  currentPaymentType.value = type;
}

function cancel() {
  buyFlowStore.shippingAddress = Object.assign({}, buyFlowStore.editShippingAddress);
  buyFlowStore.layoutState = layoutStates.purchase;
}
async function submit() {
  clearPaypalAllStateNotification();
  buyFlowStore.processingOrderMessage = tn("processingOrderMessage");

  if (displayCreditCardDropDown.value) {
    setPaymentMethodById(selectedPaymentMethodId.value);
    changePageState(pageStates.reviewOrder);
  } else {
    logEvent("submit", componentName, "Submit Edit Payment Credit Card Form");
    /* eslint-disable */
    //@ts-ignore
    Z.submit();
    /* eslint-enable */
  }
}

//clear Allstate Non US Notification if any
function clearPaypalAllStateNotification() {
  if (buyFlowStore.hasAllStateNonUSPaypalShippingAddress) {
    logEvent("clearPaypalAllStateNotification", componentName, "cleared the All state no us notification");
    //clear the paypal store clearPayPalBillingAgreementResponse since user may have switched from paypal selection
    payPalStore.clearPayPalBillingAgreementResponse();
    //clear the Allstate Non US Notification
    buyFlowStore.setHasAllStateNonUSPaypalShippingAddress(false);
  }
}

async function loadPage() {
  isLoading.value = true;
  if (!userStore.isEstablishedAccount) {
    editPaymentDisplayState.value = editPaymentDisplayStates.addCreditCard;
  } else {
    //If the user has credit cards then the dropdown list must be displayed
    if (buyFlowStore.creditCards.length > 0) {
      editPaymentDisplayState.value = editPaymentDisplayStates.displayCreditCards;
      paymentPageStore.setDisplayPaymentMethods(false);
    }
  }
  isLoading.value = false;
}

function updateSelectedPaymentMethodFromDropDown(paymentId: string) {
  selectedPaymentMethodId.value = paymentId;
}
</script>
