import { unifiedApi } from "@/common";
import { IPaymentMethod, IZuoraCreditCardFormParams } from "@/common/api/unifiedPortal/interfaces";
import { useConfigStore } from "@/stores/config";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { IErrorMessage } from "@/common/api/interfaces";
import { EMPTY_GUID, RateLimitCreditCardError, GenericCreditCardError } from "@/define";
import { IZuoraFormConfig } from "@/common/api/interfaces";
import { useUserStore } from "@/stores/user";
import { getUrl } from "@/common/getUrl";

export async function removeCreditCard(creditCards: IPaymentMethod[], creditCardId: string) {
  try {
    const cardToRemove = creditCards.find(card => {
      return card.id === creditCardId;
    });

    //if there are only 2 CCs the user is allowed to delete the default CC but we must set the other card as the default.
    if (!!cardToRemove && cardToRemove.defaultPaymentMethod && creditCards.length === 2) {
      const newDefaultCC = creditCards.find(card => {
        return card.id !== creditCardId;
      });

      if (newDefaultCC) {
        newDefaultCC.defaultPaymentMethod = true;
        await unifiedApi.removeAndReplaceCreditCard(creditCardId, newDefaultCC.id);
      }
    } else {
      await unifiedApi.removeCreditCard(creditCardId);
    }

    return creditCards.filter((card: IPaymentMethod) => {
      return card.id !== creditCardId;
    });
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

export async function setDefaultCreditCard(creditCards: IPaymentMethod[], creditCardId: string) {
  try {
    await unifiedApi.setDefaultCreditCard(creditCardId);

    for (const card of creditCards) {
      card.defaultPaymentMethod = card.id === creditCardId;
    }

    return creditCards;
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

export async function getCreditCardFormParams(userGuid = "", email = "", computerId?, token = "", licenseKey = "") {
  const zuoraFormInfo = useConfigStore().config?.zuoraForm;
  if (zuoraFormInfo)
    return implementGetCreditCardFormParams(zuoraFormInfo, userGuid, email, computerId, token, licenseKey);
}

async function implementGetCreditCardFormParams(
  zuoraFormInfo: IZuoraFormConfig,
  userGuid = "",
  email = "",
  computerId?,
  token = "",
  licenseKey = ""
) {
  const userStore = useUserStore();
  try {
    let paymentFormInfo: IZuoraCreditCardFormParams | undefined;
    if (zuoraFormInfo) {
      const origin = `${window.location.origin.toLowerCase()}/`;
      const url = getUrl("VITE_APP_WEBROOT_URL");
      const id = origin.includes(url) ? zuoraFormInfo.webrootId : zuoraFormInfo.carboniteId;
      if (userStore.isEstablishedAccount) {
        //This calls creates a zuora Account if there isn't one already
        paymentFormInfo = (
          await unifiedApi.getZuoraCreditCardFormParams(userStore.currentUser?.userGuid ?? "", id, token)
        ).data;
      } else if (userGuid || userStore.currentUser?.userGuid) {
        paymentFormInfo = (
          await unifiedApi.getZuoraCreditCardFormParams(
            userGuid || userStore.currentUser?.userGuid || EMPTY_GUID,
            id,
            token
          )
        ).data;
      } else if (email) {
        paymentFormInfo = (await unifiedApi.getZuoraCreditCardFormEmailParams(email, id, token)).data;
      } else if (computerId > 0) {
        paymentFormInfo = (await unifiedApi.getZuoraCreditCardFormComputerIdParams(computerId, id, token)).data;
      } else if (licenseKey) {
        paymentFormInfo = (await unifiedApi.getZuoraCreditCardFormLicenseKeyParams(licenseKey, id, token)).data;
      }
      if (paymentFormInfo) {
        //These need to be lowercase for the Z render function in the Zuora Javascript
        return {
          tenantId: paymentFormInfo.tenantId,
          id: id,
          token: paymentFormInfo.token,
          signature: paymentFormInfo.signature,
          key: paymentFormInfo.key,
          submitEnabled: zuoraFormInfo.submitEnabled,
          url: zuoraFormInfo.url,
          paymentGateway: zuoraFormInfo.paymentGateway,
          style: zuoraFormInfo.style,
          field_accountId: userStore.isEstablishedAccount ? paymentFormInfo.zuoraAccountId : "", //Only set the zuora account for an established account
          locale: userStore.selectedLanguage,
        };
      } else return {};
    }
  } catch (err) {
    const e = err as AxiosError;
    if ((e.response?.data as IErrorMessage)?.message === RateLimitCreditCardError) {
      handleApiError(e, true, "RateLimitCreditCardError");
      //Throw error to parent so we can disable the "Next" btn
      throw RateLimitCreditCardError;
    } else {
      handleApiError(e, true, "GenericCreditCardError");
      throw GenericCreditCardError;
    }
  }
}
