<template>
  <div class="card">
    <article class="opposite-ends align-items-center">
      <div class="info">
        <ul class="mt-quarter">
          <li>
            <strong>
              {{ t("Brand.PersonalLite") }}
            </strong>
          </li>
          <li v-if="!displayActivateWsac">{{ tn("expires") }} {{ expirationDate() }}</li>
          <li v-else class="not-activated">
            {{ tn("notActivated") }}
          </li>
        </ul>
      </div>
      <div class="install">
        <button
          id="btnWebrootInstallSafeClient"
          class="btn-primary"
          :disabled="disableInstallNowButton"
          @click="installSafeClient"
        >
          {{ tn("installNow") }}
        </button>
      </div>
    </article>
    <article v-if="displayActivateWsac" class="activate-wsac">
      <div class="wsac-info">
        <svg-icon-component icon="circle-information" class="icon-sm info" />
        {{ tn("installWSACDisclaimer") }}
      </div>
      <div class="wsac-button">
        <button id="btnWebrootGoToDownloads" class="btn-primary" @click="goToDownloads">
          {{ tn("goToDownloads") }}
        </button>
      </div>
    </article>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription, IDevice } from "@/common/api/unifiedPortal/interfaces";
import { useNotificationsStore } from "@/stores/notifications";
import { isMobile } from "@/common/browserDetect";
import { formatDate } from "@/common/dateFormat";

const componentName = "CardWsacSafeLiteComponent";

const props = defineProps({
  subscription: {
    type: Object as PropType<ISubscription>,
    required: true,
  },
});

const emits = defineEmits(["ac-selected"]);

const router = useRouter();
const notificationsStore = useNotificationsStore();

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function expirationDate() {
  if (props.subscription.expirationDate) {
    var expirationDate = new Date(props.subscription.expirationDate);
    var expirationPlusYear = new Date(
      expirationDate.getFullYear() + 1, // We need to display an are adding an extra year for Safe Lite
      expirationDate.getMonth(),
      expirationDate.getDate()
    );
    return formatDate(expirationPlusYear);
  }
  return formatDate(new Date()); //temp, will need a API change to return the correct value here.
}

const displayActivateWsac = computed(() => {
  return props.subscription.retailerSubscriptionStatus == "Inactive";
});

const disableInstallNowButton = computed(() => {
  return isMobile() || props.subscription.retailerSubscriptionStatus == "Inactive";
});

function installSafeClient() {
  notificationsStore.clearNotifications();

  // Will convert the Subscription object to a Fake Device, so we can se
  const device: IDevice = {
    deviceId: 0,
    name: "",
    description: "",
    productLevel: "",
    productName: "",
    productType: "",
    platform: "",
    sku: "",
    isTrial: false,
    activationCode: "",
    hasStackedActivationCodes: false,
    subscriptionMonths: 0,
    subscriptionDays: 0,
    expirationDate: "",
    daysRemaining: 0,
    fileCount: 0,
    fileSizeMB: 0,
    pendingFileSizeMB: 0,
    lastBackupTime: "",
    status: "",
    alerts: [],
    alertsCount: 0,
    source: "",
    canUpgrade: false,
    canRenew: false,
    resellerCanAutoRenew: false,
    autoRenewInfo: undefined,
    osVersion: "",
    hasMirrorImageBackup: false,
    zuoraSubscriptionNumber: "",
    zuoraSubscriptionStatus: "",
    distyName: "",
    hasOutstandingInvoice: false,
    isEvergreenMonthly: false,
    evergreenNextBillingDate: undefined,
    webrootKeycode: props.subscription.keyCode ? props.subscription.keyCode : "",
    hasFailedWebrootProvisioning: false,
    dataHasBeenPurged: false,
    canOptInVideoBackup: false,
    catId: 0,
    isResellerManaged: false,
    isPartnerReferral: false,
    hasSSLKey: true,
  };

  emits("ac-selected", device, "Install");
}

function goToDownloads() {
  router.push("/downloads");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

article .btn-primary {
  margin-top: var(--space);
}

.activate-wsac {
  border-top: 1px solid var(--medium-grey);
}

.not-activated {
  color: var(--grey);
}

@media (min-width: 600px) {
  article {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  article .btn-primary {
    margin: 0 0 0 var(--space-quarter);
  }
}
</style>
