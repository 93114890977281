export enum FullPageErrorTypes {
  none,
  encrypted,
  noData,
  expired,
  unknown,
  folderEmpty,
}

export enum SortOrder {
  ascend = 1,
  descend = -1,
}

export enum ViewTypes {
  list,
  tile,
}
