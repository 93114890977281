// CardComputer
import { expirationState, customerType, termType, expirationMessageTypes } from "./CardComputerEnums";
export interface ICardComputerConfig {
  customerType: customerType;
  expirationState: expirationState;
  termType: termType;
  isAutoRenewSet: boolean;
  actions: ICardComputerConfigActions;
}

export interface ICardComputerConfigActions {
  displayBuyNowButton: boolean;
  displayRenewNowButton: boolean;
  displayResellerEmail: boolean;
  displayComputerDropDown: boolean;
  displayViewFilesAndGetFilesBack: boolean;
  computerDropDown: ICardComputerConfigComputerDropDownActions;
  expirationMessageType: expirationMessageTypes;
  displaySetAutoRenewLink: boolean;
}

export interface ICardComputerConfigComputerDropDownActions {
  displayRenew: boolean;
  displayBuyNow: boolean;
  displayMove: boolean;
  displayReinstall: boolean;
  displayChangePlan: boolean;
}

// Config Matrix
// Scenarios
// +----------------+------------+----------------+-------------+
// | Consumer Type  | Term       | Auto-renew     | Expiration  |
// +----------------+------------+----------------+-------------+
// | Direct         | Annual     | Auto-renew On  | None        |
// | Direct         | Annual     | Auto-renew On  | IsExpiring  |
// | Direct         | Annual     | Auto-renew On  | Expired     |
// | Direct         | Annual     | Auto-renew Off | None        |
// | Direct         | Annual     | Auto-renew Off | IsExpiring  |
// | Direct         | Annual     | Auto-renew Off | Expired     |
// | Direct         | Trial      | Auto-renew Off | None        |
// | Direct         | Trial      | Auto-renew Off | IsExpiring  |
// | Direct         | Trial      | Auto-renew Off | Expired     |
// | Direct         | Evergreen  | Auto-renew Off | None        |
// | Direct         | Evergreen  | Auto-renew Off | IsExpiring  |
// | Direct         | Evergreen  | Auto-renew Off | Expired     |
// | Direct         | Evergreen  | Auto-renew On  | None        |
// | Direct         | Evergreen  | Auto-renew On  | IsExpiring  |
// | Direct         | Evergreen  | Auto-renew On  | Expired     |
// | Indirect       | Annual     | Auto-renew On  | None        |
// | Indirect       | Annual     | Auto-renew On  | IsExpiring  |
// | Indirect       | Annual     | Auto-renew On  | Expired     |
// | Indirect       | Annual     | Auto-renew Off | None        |
// | Indirect       | Annual     | Auto-renew Off | IsExpiring  |
// | Indirect       | Annual     | Auto-renew Off | Expired     |
// | Indirect       | Trial      | Auto-renew Off | None        |
// | Indirect       | Trial      | Auto-renew Off | IsExpiring  |
// | Indirect       | Trial      | Auto-renew Off | Expired     |
// | Indirect       | Evergreen  | Auto-renew Off | None        |
// | Indirect       | Evergreen  | Auto-renew Off | IsExpiring  |
// | Indirect       | Evergreen  | Auto-renew Off | Expired     |
// | Indirect       | Evergreen  | Auto-renew On  | None        |
// | Indirect       | Evergreen  | Auto-renew On  | IsExpiring  |
// | Indirect       | Evergreen  | Auto-renew On  | Expired     |
// +----------------+------------+----------------+-------------+

export const cardComputerConfig: ICardComputerConfig[] = [
  // Direct - Annual - Auto-renew On - None
  {
    customerType: customerType.direct,
    termType: termType.annual,
    isAutoRenewSet: true,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.autoRenew,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: true,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Annual - Auto-renew On - IsExpiring
  {
    customerType: customerType.direct,
    termType: termType.annual,
    isAutoRenewSet: true,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.autoRenew,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: true,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Annual - Auto-renew On - Expired
  {
    customerType: customerType.direct,
    termType: termType.annual,
    isAutoRenewSet: true,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.autoRenew,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: true,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Annual - Auto-renew Off - None
  {
    customerType: customerType.direct,
    termType: termType.annual,
    isAutoRenewSet: false,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.validUntil,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: true,
      computerDropDown: {
        displayRenew: true,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Annual - Auto-renew Off - IsExpiring
  {
    customerType: customerType.direct,
    termType: termType.annual,
    isAutoRenewSet: false,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: false,
      displayRenewNowButton: true,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: true,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Annual - Auto-renew Off - Expired
  {
    customerType: customerType.direct,
    termType: termType.annual,
    isAutoRenewSet: false,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.expiredOn,
      displayBuyNowButton: false,
      displayRenewNowButton: true,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: true,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Trial - Auto-renew Off - None
  {
    customerType: customerType.direct,
    termType: termType.trial,
    isAutoRenewSet: false,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: true,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: true,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Trial - Auto-renew Off - IsExpiring
  {
    customerType: customerType.direct,
    termType: termType.trial,
    isAutoRenewSet: false,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: true,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: true,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Trial - Auto-renew Off - Expired
  {
    customerType: customerType.direct,
    termType: termType.trial,
    isAutoRenewSet: false,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.expiredOn,
      displayBuyNowButton: true,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: false,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: true,
        displayMove: false,
        displayReinstall: false,
        displayChangePlan: false,
      },
    },
  },
  // Direct - Evergreen - Auto-renew Off - None
  {
    customerType: customerType.direct,
    termType: termType.evergreen,
    isAutoRenewSet: false,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: true,
      },
    },
  },
  // Direct - Evergreen - Auto-renew Off - IsExpiring
  {
    customerType: customerType.direct,
    termType: termType.evergreen,
    isAutoRenewSet: false,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: true,
      },
    },
  },
  // Direct - Evergreen - Auto-renew Off - Expired
  {
    customerType: customerType.direct,
    termType: termType.evergreen,
    isAutoRenewSet: false,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: true,
      },
    },
  },
  // Direct - Evergreen - Auto-renew On - None
  {
    customerType: customerType.direct,
    termType: termType.evergreen,
    isAutoRenewSet: true,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: true,
      },
    },
  },
  // Direct - Evergreen - Auto-renew On - IsExpiring
  {
    customerType: customerType.direct,
    termType: termType.evergreen,
    isAutoRenewSet: true,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: true,
      },
    },
  },
  // Direct - Evergreen - Auto-renew On - Expired
  {
    customerType: customerType.direct,
    termType: termType.evergreen,
    isAutoRenewSet: true,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: true,
      },
    },
  },
  // Indirect - Annual - Auto-renew On - None
  {
    customerType: customerType.indirect,
    termType: termType.annual,
    isAutoRenewSet: true,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.validUntil,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Annual - Auto-renew On - IsExpiring
  {
    customerType: customerType.indirect,
    termType: termType.annual,
    isAutoRenewSet: true,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Annual - Auto-renew On - Expired
  {
    // Indirect - Expired - annual term - Auto-renew  set
    customerType: customerType.indirect,
    termType: termType.annual,
    isAutoRenewSet: true,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.expiredOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Annual - Auto-renew Off - None
  {
    customerType: customerType.indirect,
    termType: termType.annual,
    isAutoRenewSet: false,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.validUntil,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Annual - Auto-renew Off - IsExpiring
  {
    customerType: customerType.indirect,
    termType: termType.annual,
    isAutoRenewSet: false,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Annual - Auto-renew Off - Expired
  {
    customerType: customerType.indirect,
    termType: termType.annual,
    isAutoRenewSet: false,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.expiredOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Trial - Auto-renew Off - None
  {
    customerType: customerType.indirect,
    termType: termType.trial,
    isAutoRenewSet: false,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Trial - Auto-renew Off - IsExpiring
  {
    customerType: customerType.indirect,
    termType: termType.trial,
    isAutoRenewSet: false,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.expiresOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Trial - Auto-renew Off - Expired
  {
    // Indirect - Expired - trial
    customerType: customerType.indirect,
    termType: termType.trial,
    isAutoRenewSet: false,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.expiredOn,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: false,
      displayViewFilesAndGetFilesBack: false,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: false,
        displayReinstall: false,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Evergreen - Auto-renew Off - None
  {
    customerType: customerType.indirect,
    termType: termType.evergreen,
    isAutoRenewSet: false,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Evergreen - Auto-renew Off - IsExpiring
  {
    customerType: customerType.indirect,
    termType: termType.evergreen,
    isAutoRenewSet: false,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Evergreen - Auto-renew Off - Expired
  {
    customerType: customerType.indirect,
    termType: termType.evergreen,
    isAutoRenewSet: false,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Evergreen - Auto-renew On - None
  {
    customerType: customerType.indirect,
    termType: termType.evergreen,
    isAutoRenewSet: true,
    expirationState: expirationState.none,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: false,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Evergreen - Auto-renew On - IsExpiring
  {
    customerType: customerType.indirect,
    termType: termType.evergreen,
    isAutoRenewSet: true,
    expirationState: expirationState.isExpiring,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
  // Indirect - Evergreen - Auto-renew On - Expired
  {
    // Indirect - Expired - annual term - Auto-renew  set
    customerType: customerType.indirect,
    termType: termType.evergreen,
    isAutoRenewSet: true,
    expirationState: expirationState.expired,
    actions: {
      expirationMessageType: expirationMessageTypes.evergreen,
      displayBuyNowButton: false,
      displayRenewNowButton: false,
      displayResellerEmail: true,
      displayComputerDropDown: true,
      displayViewFilesAndGetFilesBack: true,
      displaySetAutoRenewLink: false,
      computerDropDown: {
        displayRenew: false,
        displayBuyNow: false,
        displayMove: true,
        displayReinstall: true,
        displayChangePlan: false,
      },
    },
  },
];
