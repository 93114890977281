<template>
  <div v-if="isLoading" class="content">
    <LoadingSpinner />
  </div>
  <div v-else class="content">
    <h1>{{ tn("headerH1") }}</h1>
    <h3 class="mb truncate">{{ tn("welcome", { email: userStore.currentUser?.email }) }}</h3>
    <p class="mb">{{ tn("pleaseEnter") }}</p>
    <ul class="form">
      <li>
        <div class="label-wrapper">
          <label for="new-password">{{ tn("password") }}</label>
          <button
            v-if="hidePassword"
            type="button"
            class="inline-btn-link"
            title="Show password"
            @click="hidePassword = !hidePassword"
          >
            {{ t("Common.show") }}
          </button>
          <button
            v-else
            type="button"
            class="inline-btn-link"
            title="Hide password"
            @click="hidePassword = !hidePassword"
          >
            {{ t("Common.hide") }}
          </button>
        </div>
        <InputErrorComponent :show="passwordErrors && passwordErrors.length > 0">
          <input
            id="new-password"
            v-model.trim="newPassword"
            :type="hidePassword ? 'password' : 'text'"
            autocomplete="off"
            @keyup="dirtyFlagPassword = true"
          />
          <template #error>
            <div>
              <span v-for="e in passwordErrors" :key="e.name">{{ e.message }}</span>
            </div>
          </template>
        </InputErrorComponent>
        <p class="mt-quarter">{{ tn("passwordRequirements") }}</p>
        <PasswordRulesComponent :password="newPassword" :show-rules="false" />
      </li>
      <li>
        <div class="label-wrapper">
          <label for="confirm-new-password">{{ tn("confirmNewPassword") }}</label>
          <button
            v-if="hideConfirmPassword"
            type="button"
            class="inline-btn-link"
            title="Show password"
            @click="hideConfirmPassword = !hideConfirmPassword"
          >
            {{ t("Common.show") }}
          </button>
          <button
            v-else
            type="button"
            class="inline-btn-link"
            title="Hide password"
            @click="hideConfirmPassword = !hideConfirmPassword"
          >
            {{ t("Common.hide") }}
          </button>
        </div>
        <InputErrorComponent :show="showPasswordConfirmError">
          <input
            id="confirm-new-password"
            v-model.trim="newPasswordConfirm"
            :type="hideConfirmPassword ? 'password' : 'text'"
            autocomplete="off"
            @keyup="dirtyFlagConfirmPassword = true"
          />
          <template #error>
            <div>
              <span>{{ tn("passwordMatch") }}</span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <div class="mb-double">
          <button id="btnFinish" class="btn-primary" :disabled="!isPasswordValid || isSaving" @click="save()">
            <spinner :is-spinning="isSaving" />
            {{ tn("finishSetup") }}
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
// components
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import Spinner from "@/components/shared/Spinner.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import PasswordRulesComponent from "@/components/shared/PasswordRules.vue";
// others
import { ref, computed, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { validatePassword } from "@/common/strongPassword";
import { passwordRegex } from "@/globalRegex";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { reportStringErrors } from "@/common/validator";
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "@/define";
import { useUserStore } from "@/stores/user";
import router from "@/routes";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { openTextBrands } from "@/components/Buy/BuyEnums";
import { useBuyFlowStore } from "@/stores/buyFlow";

const componentName = "SetNewUserPassword";

logEvent("created", componentName);

const userStore = useUserStore();
const isLoading = ref<boolean>(false);
const isSaving = ref<boolean>(false);
const newPassword = ref<string>("");
const newPasswordConfirm = ref<string>("");
const dirtyFlagPassword = ref<boolean>(false);
const dirtyFlagConfirmPassword = ref<boolean>(false);
const updatePassword = ref(false);
const hidePassword = ref(true);
const hideConfirmPassword = ref(true);
const siteSettingsStore = useSiteSettingsStore();
const buyFlowStore = useBuyFlowStore();

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onBeforeMount(async () => {
  isLoading.value = true;
  await reloadSubscriberInfo();
  // If password already set, redirect to home
  if (userStore.currentUser?.subscriberInfo?.hasPasswordSet) {
    router.push("/home");
  }

  isLoading.value = false;
});

const isPasswordValid = computed(() => {
  return (
    newPassword.value &&
    newPasswordConfirm.value &&
    !showPasswordConfirmError.value &&
    passwordErrors.value.length === 0
  );
});

const showPasswordConfirmError = computed(() => {
  return !!newPassword.value && !!dirtyFlagConfirmPassword.value && newPassword.value !== newPasswordConfirm.value;
});

const passwordInvalidCharacterError = computed(() => {
  return reportStringErrors(
    newPassword.value,
    tn("newPassword"),
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH,
    passwordRegex
  );
});

const passwordErrors = computed(() => {
  const result = validatePassword(newPassword.value);

  //Piggybacking reportStringErrors on top of validatePassword to return
  //error message that displays the invalid characters used.
  result.push({
    name: "invalid_characters",
    message: passwordInvalidCharacterError.value,
    displayOrder: 5,
    passed: passwordInvalidCharacterError.value.length === 0,
  });

  const isNotValidPassword = result.some(e => !e.passed) && dirtyFlagPassword.value === true;
  return isNotValidPassword ? [result.filter(e => !e.passed)[0]] : [];
});

function resetFlags() {
  dirtyFlagPassword.value = false;
  dirtyFlagConfirmPassword.value = false;
}

async function save() {
  isSaving.value = true;
  try {
    if (isPasswordValid.value) {
      updatePassword.value = (await unifiedApi.validateAndUpdatePassword("", newPassword.value)).data;
      //Need to update the user store so they will not be viewed as a preliminary user if they enter the buy flow again
      await reloadSubscriberInfo();
      if (updatePassword.value) {
        if (!siteSettingsStore.redirectPreliminaryUserToBackup) {
          router.push("/home");
        } else {
          var route =
            buyFlowStore.cart?.brand === openTextBrands.Webroot
              ? buyFlowStore.hasOnlyAllStateOrVPNInCart
                ? "/home"
                : "/downloads"
              : "/backup";
          router.push(route);
        }
      }
    }
  } catch (error) {
    logEvent("SetPasswordFailure", `${componentName}`);
    handleApiError(error as AxiosError, true, "SetPasswordFailure");
  }
  resetFlags();
  isSaving.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
