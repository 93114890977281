<template>
  <!-- if established account and mobile -->
  <div v-if="isMobile()">
    <p class="mb-double constrain-50">{{ tn("isEstablishedAccountLine1") }}</p>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <i18n-t keypath="OrderConfirmation.isEstablishedAccountMobile" tag="p" scope="global" class="mb constrain-50">
      <template #url>
        <button id="btnMyAccountFromMobile" type="button" class="inline-btn-link" @click="goToMyAccount()">
          {{ t("OrderConfirmation.isMobileBtnLine2Url") }}
        </button>
      </template>
    </i18n-t>
  </div>
  <div v-else>
    <h1>{{ tn("h1OrderConfirmation") }}</h1>
    <p class="mb-double constrain-50">{{ tn("isEstablishedAccountLine1") }}</p>
    <h1>{{ t("Common.nextSteps") }}</h1>
    <p class="mb constrain-50">{{ tn("isEstablishedAccountNextStepsLine1") }}</p>
    <DownloadSafe btn-class="btn-primary" btn-text="Common.download" />
    <CarboniteNextStepsWindows v-if="isWindows()" />
    <CarboniteNextStepsMac v-if="isMac()" />
    <p class="mb constrain-50">
      <button type="button" class="inline-btn-link" @click="continueToMyAccount()">
        {{ tn("btnContinueToMyAccount") }}
      </button>
      {{ tn("isEstablishedAccountLine2") }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { continueToMyAccount } from "@/components/Buy/BuyHelper";
import { isMac, isMobile, isWindows } from "@/common/browserDetect";
import { t } from "@/i18n";
import DownloadSafe from "@/components/Backup/DownloadSafe.vue";
import CarboniteNextStepsWindows from "@/components/Buy/OrderConfirmation/CarboniteNextStepsWindows.vue";
import CarboniteNextStepsMac from "@/components/Buy/OrderConfirmation/CarboniteNextStepsMac.vue";
import { useRouter } from "vue-router";
import { logEvent } from "@/common/logger";

const componentName = "EstablishedAccountCarb";
const baseComponentName = "OrderConfirmation";
const router = useRouter();
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function goToMyAccount() {
  router.push("/Backup");
}
</script>
