import axios, { AxiosError } from "axios";
import { HTTP_STATUS_OK, HTTP_STATUS_SERVER_ERROR } from "@/define";
import { logEvent, logException } from "@/common/logger";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import {
  ICollectionParams,
  IComputerContents,
  IFileDownload,
  IImageDownload,
  IItemCollection,
  IRfaComputer,
  IRfaError,
  IRfaItem,
  IRfaSelectionInfo,
  ISearchRequest,
  IThumbnailUpdate,
} from "./interfaces";
import { FullPageErrorTypes } from "./FileAccessEnums";
import { fullPageErrorType, getBaseUrl, getSessionKey, isLoading, propData, showFullPageError } from "./commonFn";
import { unifiedApi } from "@/common";
import { session } from "./sessionFn";

const componentName = "FileAccess.ConsumerServerComm";
//Increase the timeout for the download requests to 20 minutes
const downloadTimeOut = 1200000;

export async function requestSessionKey() {
  // the one thing we need from api is a session token, the rest we do directly with the RFA server
  session.value = (await unifiedApi.getRfaSession(propData.value.computerId)).data;
  logEvent("getting rfa session", componentName, session.value);
}

//documentation for these requests can be found at https://dev-crf1.carbonite.com/Access/Computer/help
//will not implement CreatePhotoAlbum (can't get it to work), Events (nothing to record),
//Folders (subset of Contents), and the Facebook APIs (need a facebook Account?)

//hit the RFA server to see if it is there
export async function checkHealth(): Promise<boolean> {
  let isPresent = false;
  try {
    logEvent("checking rfa server health", componentName);
    const url = `${getBaseUrl()}/Computer/CheckHealth`;
    const resp = (await axios.get(url)).data;
    isPresent = resp === true;

    if (!isPresent) logEvent("rfa server health check failed", componentName);
  } catch (err) {
    logException(err as Error, "checkHealth");
  }
  return isPresent;
}

//get data about the computer
export async function getComputer(): Promise<IRfaComputer | undefined> {
  let computer: IRfaComputer | undefined = undefined;
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getBaseUrl()}/Computer/${propData.value.computerId}/${getSessionKey()}`;
    computer = (await axios.get(url)).data as IRfaComputer;
    logEvent("getting computer info", componentName, computer);
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response?.status == HTTP_STATUS_SERVER_ERROR) {
      logEvent("500 error", "ServerComm", axiosErr.response?.data);
      const errorTypes = (axiosErr.response?.data as IRfaError)?.Identifiers;
      if (errorTypes.indexOf("Carbonite.RemoteAccess.ComputerHasPrivateKey") >= 0) {
        showFullPageError.value = true;
        fullPageErrorType.value = FullPageErrorTypes.encrypted;
      } else if (errorTypes.indexOf("Carbonite.RemoteAccess.ExpiredSubscription") >= 0) {
        showFullPageError.value = true;
        fullPageErrorType.value = FullPageErrorTypes.expired;
      } else if (errorTypes.indexOf("Carbonite.RemoteAccess.FirstServerError") >= 0) {
        showFullPageError.value = true;
        fullPageErrorType.value = FullPageErrorTypes.unknown;
      }
    } else logException(err as Error, "getComputer");

    //only short circuit the full page error determinate process if there was an exception here
    //otherwise, others may still decide to show errors.
    isLoading.value = false;
  }
  return computer;
}

//get a list of items in the folder identified by path
export async function getComputerContents(cc: IComputerContents): Promise<IRfaItem[]> {
  let currentItems: IRfaItem[] = [];
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url = `${getBaseUrl()}/Computer/Contents/${getSessionKey()}?path=${cc.path}`;
    //put the filter back once MAC reports correct file count / size
    currentItems = (await axios.get(url)).data as IRfaItem[]; //.filter(i => !i.IsDirectory || i.FolderItemCount != 0 || i.Size != 0);
    logEvent("getting folder info", componentName, { cc, currentItems });
  } catch (err) {
    logException(err as Error, "getComputerContents");
  }
  return currentItems;
}

//the results of a string search
export async function getSearchResults(sr: ISearchRequest): Promise<IRfaItem[]> {
  let searchItems: IRfaItem[] = [];
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url =
      `${getBaseUrl()}/Computer/Search/${getSessionKey()}` +
      `?searchString=${sr.filter}&path=${sr.path}&startIndex=${sr.start}&maxResults=${sr.maxResults}`;
    searchItems = (await axios.get(url)).data as IRfaItem[];
    logEvent("getting search results", componentName, {
      sr,
      searchItems,
    });
  } catch (err) {
    logException(err as Error, "getSearchResults");
  }
  return searchItems;
}

// get file count and size information about a list of files/folders
export async function getSelectionInfo(ic: IItemCollection): Promise<IRfaSelectionInfo | undefined> {
  let selectionInfo: IRfaSelectionInfo | undefined = undefined;
  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const url =
      `${getBaseUrl()}/Computer/Selection/Info` +
      `?collection=${ic.collection}&token=${getSessionKey()}&onlyImages=false`;
    selectionInfo = (await axios.get(url)).data as IRfaSelectionInfo;
    logEvent("getting selection info", componentName, selectionInfo);
  } catch (err) {
    logException(err as Error, "getSelectionInfo");
  }
  return selectionInfo;
}

//download a file to the user's browser
export async function downloadFile(fd: IFileDownload, ic?: IItemCollection) {
  // single file format:path is just DownloadUrl
  // zip format: /CXXXXX-Z/Archive.zip?token=XXXX&collection=<collectionStr>&cnt=<numFiles>
  let url = `${getBaseUrl()}/${fd.path}?token=${getSessionKey()}`;
  if (ic?.collection) url += `&collection=${ic.collection}`;
  if (fd.count) url += `&cnt=${fd.count}`;

  try {
    useUserStore().lastRequestTime = new Date().getTime();
    const resp = await axios.get(url, { responseType: "blob", timeout: downloadTimeOut });
    if (resp && resp.data && resp.status == HTTP_STATUS_OK) {
      const blobData = new Blob([resp.data]);
      triggerBrowserDownload(blobData, fd.name);
      logEvent("downloading file", componentName, fd);
    } else {
      logEvent("downloading file failed", componentName, { path: fd.path, status: resp.status });
      useNotificationsStore().addNotification({ type: "ServerErrors" });
    }
  } catch (err) {
    const axiosErr = err as AxiosError;
    useNotificationsStore().addNotification({ type: "ServerErrors" });
    logException(axiosErr, fd);
  }
}

//convert the blob data to a downloadable object and 'click' on it to force the browser to download
function triggerBrowserDownload(blobData: Blob, name: string) {
  const blobUrl = window.URL.createObjectURL(blobData);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//download an image
//this is different from any other type of file because we can specify the size
//of the result (giving us the possibility of thumbnails)
export async function downloadImage(img: IImageDownload): Promise<Blob | undefined> {
  logEvent("downloading image", componentName, img);
  let blob: Blob | undefined = undefined;

  try {
    useUserStore().lastRequestTime = new Date().getTime();
    let url = `${getBaseUrl()}/${img.path}?token=${getSessionKey()}`;
    if (img.size) url += `&size=${img.size}`;
    blob = (await axios.get(url, { responseType: "blob", timeout: downloadTimeOut })).data as Blob;
  } catch (err) {
    //don't display a notification to the user in this case
    //we're just going to return undefined and let the caller figure out what to do
    logException(err as Error, "downloadImage");
  }

  return blob;
}

//take a blob, convert it to a base64 formatted image and assign it to an item
export function updateItemWithThumbnail(tu: IThumbnailUpdate) {
  const reader = new FileReader();
  //this is asynchronous, so the image will not be available immediately
  //fortunately, vue takes care of updating when it does become available
  reader.addEventListener(
    "load",
    () => {
      const base64data = reader.result as string;
      tu.item.Base64Image = base64data;
      tu.item.ImageSize = tu.size;
    },
    false
  );
  if (tu.image) {
    reader.readAsDataURL(tu.image);
  } else tu.item.IsInvalidImage = true;
}

//creates an appropriately formatted string identifying the files/folders we want to
//communicate with the RFA API about
const MAX_COLLECTION_LENGTH = 1750;
export function getCollection(cp: ICollectionParams): IItemCollection {
  let collection = `C${propData.value.computerId}-Z`;
  let length = collection.length;

  for (const item of cp.items) {
    const f = `-${item.FileId}`;
    collection += f;

    length += f.length;
    if (length > MAX_COLLECTION_LENGTH) break;
  }
  return { collection };
}
