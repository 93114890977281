import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { logEvent } from "@/common/logger";
import { unifiedApi } from ".";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
export async function refreshSubscriber() {
  try {
    new Promise((resolve, reject) => {
      logEvent("refresh Subscriber for webroot license", "refreshSubscriber");
      unifiedApi
        .refreshSubscriber()
        .then(result => {
          if (result?.data?.subscriptionDataChanged) {
            logEvent(`Result has SubscriptionDataChanged true`, "refreshSubscriber");
            useSubscriptionsStore()
              .forceRefreshAndPopulateSubscriptions()
              .then(() => {
                logEvent(`reloadSubscriberInfo`, "refreshSubscriber");
                reloadSubscriberInfo();
              });
          }
          useSiteSettingsStore().setSubscriberRefreshedAfterLogin(true);
          resolve(true);
        })
        .catch(error => {
          logEvent(`Resulted in error ${error}`, "refreshSubscriber");
          useSiteSettingsStore().setSubscriberRefreshedAfterLogin(true);
          reject(false);
        });
    });
  } catch (err) {
    handleApiError(err as AxiosError, false);
  }
}
