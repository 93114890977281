<template>
  <div :id="`deviceWrapper-${props.device.id}`" @device-deactivated="hideDevice">
    <div class="flex-start">
      <div class="compound-svg mr-half">
        <svg-icon-component icon="circle-check" class="success pip" />
        <svg-icon-component icon="circle-triangle" class="warning pip" />
        <svg-icon-component icon="circle-exclamation" class="danger pip" />
        <svg-icon-component :icon="deviceIcon" class="icon-xxl" />
      </div>
      <div>
        <h3 class="mb-quarter">
          {{ limitDeviceName(deviceData.nickname || deviceData.name) }}
          <button id="editDeviceNicknameBtn" class="inline-btn-link" @click="toggleSetDeviceNicknameModal('click')">
            <svg-icon-component icon="edit" class="icon-sm" />
          </button>
          <small v-if="deviceData.nickname" class="block text-tiny">{{ deviceData.name }}</small>
        </h3>
        <h4 class="mb-quarter" :class="protectionStatusClass">{{ tn(protectionStatus) }}</h4>
      </div>
    </div>
    <div class="flex-start-lg">
      <div class="mr-half">
        <button
          v-if="isCdlp"
          id="removeDeviceBtn"
          class="btn-outline-primary"
          @click="toggleRemoveDeviceModal('click')"
        >
          {{ tn("removeDevice") }}
        </button>
      </div>
      <div v-if="showHowToEnableBtn" class="mr-half">
        <button id="howToEnableBtn" class="btn-primary" @click="goToHowToEnableBtnLink()">
          {{ tn("howToEnableBtn") }}
          <svg-icon-component icon="external-link" class="icon-xs" />
        </button>
      </div>
    </div>
    <div v-if="showShields" :class="toggleLinkClass" class="border-top">
      <h5>{{ t(`Brand.${webrootProductName}`) }}</h5>
      <p v-if="deviceData.version">{{ tn("version") }} {{ deviceData.version }}</p>
      <ul class="shields mt mb">
        <li v-for="(shield, i) in deviceData.shields" :key="i" :class="shieldClass(shield)" class="opposite-ends">
          <div>
            <svg-icon-component :icon="getShieldIcon(shield)" class="icon-sm" />
            {{ tn(shield.name) }}
          </div>
          <div class="device-state">
            <strong v-if="!shield.value">{{ tn(getOnOffString(shield.value)) }}</strong>
            <span v-else>{{ tn(getOnOffString(shield.value)) }}</span>
          </div>
        </li>
      </ul>
      <div v-if="!isCdlp">
        <input v-model="hideThisDevice" type="checkbox" />
        {{ tn("hideThisDevice") }}
      </div>
    </div>
    <footer>
      <button id="toggleShields" class="btn-link" @click="toggleShowShields()">
        {{ tn(toggleLinkText) }}
        <svg-icon-component :icon="toggleLinkIcon" class="icon-xs ml-half" />
      </button>
    </footer>
  </div>
  <RemoveDevice
    :show-dialog="showRemoveDeviceModal"
    :device="props.device"
    @close-modal="toggleRemoveDeviceModal"
    @device-deactivated="hideDevice"
  />

  <SetDeviceNicknameComponent
    :show-dialog="showSetDeviceNicknameModal"
    :nickname="deviceData.nickname || deviceData.name"
    @close-modal="toggleSetDeviceNicknameModal"
    @save-modal="saveSetDeviceNicknameModal"
  />
</template>

<script setup lang="ts">
import { PropType, ref, watch, computed } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { useSecurityStore } from "@/stores/security";
import { ISkyDevice, IShield } from "@/components/Home";
import { criticalShields } from "@/common/securityDevices";
import { ProtectionStatus } from "@/globalEnums";
import SetDeviceNicknameComponent from "@/components/Security/SetDeviceNickname.vue";
import RemoveDevice from "@/components/Security/RemoveDevice.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { getUrl } from "@/common/getUrl";
import { webrootCDLPcodes } from "@/common/webrootProductName";

const emits = defineEmits(["device-deactivated"]);
const componentName = "DeviceComponent";

const props = defineProps({
  device: {
    type: Object as PropType<ISkyDevice>,
    required: true,
  },
  licenseCategoryName: {
    type: String,
    required: true,
  },
  isExpired: {
    type: Boolean,
    required: true,
  },
});

logEvent("Created", componentName);
const webrootProductName = ref<string>(props.licenseCategoryName);
const deviceData = ref<ISkyDevice>(props.device);
const showShields = ref<boolean>(false);
const hideThisDevice = ref<boolean>(!deviceData.value.isVisible);
const showSetDeviceNicknameModal = ref<boolean>(false);
const showRemoveDeviceModal = ref<boolean>(false);
const securityStore = useSecurityStore();
const isCdlp = computed(() => {
  return webrootCDLPcodes.some(l => l === props.licenseCategoryName);
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function hideDevice() {
  emits("device-deactivated");
}

function toggleShowShields() {
  return (showShields.value = !showShields.value);
}

function toggleSetDeviceNicknameModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleSetDeviceNicknameModal",
    componentName,
    `${showSetDeviceNicknameModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showSetDeviceNicknameModal.value = !showSetDeviceNicknameModal.value;
}

function toggleRemoveDeviceModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleRemoveDeviceModal",
    componentName,
    `${showRemoveDeviceModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showRemoveDeviceModal.value = !showRemoveDeviceModal.value;
}

async function saveSetDeviceNicknameModal(newNickname: string) {
  toggleSetDeviceNicknameModal("save");
  deviceData.value.nickname = newNickname;
  await updateDevice();
}

const protectionStatus = computed(() => {
  let status = ProtectionStatus.protected;

  if (deviceData.value.shields.some(l => criticalShields.includes(l.name) && !l.value)) {
    status = ProtectionStatus.protectionDisabled;
  } else if (deviceData.value.shields.some(l => !l.value)) {
    status = ProtectionStatus.shieldsDisabled;
  }

  if (props.isExpired) {
    status = ProtectionStatus.expired;
  }

  return status;
});

const protectionStatusClass = computed(() => {
  return protectionStatus.value === ProtectionStatus.protectionDisabled ||
    protectionStatus.value === ProtectionStatus.expired
    ? "danger-text"
    : "";
});

const showHowToEnableBtn = computed(() => {
  return deviceData.value.shields.some(l => !l.value) && !props.isExpired;
});

const toggleLinkText = computed(() => {
  return showShields.value ? "showLessLink" : "showMoreLink";
});
const toggleLinkIcon = computed(() => {
  return showShields.value ? "chevron-up" : "chevron-down";
});
const toggleLinkClass = computed(() => {
  return showShields.value ? "animate" : "";
});

const deviceIcon = computed(() => {
  let icon = "";
  const deviceType = deviceData.value.type ? deviceData.value.type.toLowerCase() : "";
  const operatingSystem = deviceData.value.operatingSystem ? deviceData.value.operatingSystem.toLowerCase() : "";

  if (
    (deviceType && (deviceType.includes("pc") || deviceType.includes("windows"))) ||
    operatingSystem.includes("windows")
  ) {
    icon = "security-windows";
  }

  if ((deviceType && deviceType.includes("mac")) || operatingSystem.includes("mac")) {
    icon = "security-mac-os";
  }
  //Next few are not really implemented from SKY atm
  if ((deviceType && deviceType.includes("ios")) || operatingSystem.includes("ios")) {
    icon = "security-ios";
  }

  if ((deviceType && deviceType.includes("android")) || operatingSystem.includes("android")) {
    icon = "security-android";
  }

  if ((deviceType && deviceType.includes("chrome")) || operatingSystem.includes("chrome")) {
    icon = "security-chrome-os";
  }

  return icon;
});

function getOnOffString(value: boolean) {
  let onOffString = "shieldOn";
  if (!value) {
    onOffString = "shieldOff";
  }
  return onOffString;
}

function getShieldIcon(shield: IShield) {
  let icon = "checkmark";

  if (criticalShields.includes(shield.name) && !shield.value) {
    icon = "circle-exclamation";
  } else if (!shield.value) {
    icon = "circle-triangle";
  }

  return icon;
}

function shieldClass(shield: IShield): Record<string, string> {
  const shieldClass = {};

  if (criticalShields.includes(shield.name) && !shield.value) {
    shieldClass["danger"] = true;
  } else if (!shield.value) {
    shieldClass["warning"] = true;
  } else {
    shieldClass["success"] = true;
  }

  return shieldClass;
}

async function updateDevice() {
  try {
    const request = {
      deviceId: deviceData.value.id,
      instanceId: deviceData.value.instanceId,
      deviceNickname: deviceData.value.nickname || "",
      isVisible: deviceData.value.isVisible,
      keycode: deviceData.value.keycode,
    };

    await unifiedApi.updateWebrootDevice(request);
    securityStore.forceRefresh();
  } catch (err) {
    logException(err as Error);
  }
}

function goToHowToEnableBtnLink() {
  window.open(getUrl("WEBROOT_HOW_TO_ENABLE"), "_blank");
}

watch(hideThisDevice, async () => {
  deviceData.value.isVisible = !hideThisDevice.value;
  await updateDevice();
});

// Limits the computer name display to 19 Characters
function limitDeviceName(deviceName: string) {
  return `${deviceName.substring(0, 18)}${deviceName.length > 14 ? "..." : ""}`;
}
</script>
