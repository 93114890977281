<template>
  <ModalWrapperComponent dialog-name="MoveMySubscriptionComponent" :show-dialog="showDialog" @close-modal="closeModal">
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <i18n-t keypath="CrossPlatformTrialMoveSubscriptionComponent.trialCrossPlatformP1" tag="p" scope="global">
        <template #agentOs>
          {{ oldComputerOs }}
        </template>
        <template #browserOs>
          {{ newComputerOs }}
        </template>
        <template #upgradeAdd>
          <strong>{{ tn("upgradeAdd") }}</strong>
        </template>
      </i18n-t>
      <i18n-t keypath="CrossPlatformTrialMoveSubscriptionComponent.trialCrossPlatformP2" tag="p" scope="global">
        <template #carboniteContactUrl>
          <a id="linkSupportTargetBlank" href="https://support.carbonite.com/contact_us" target="_blank">
            {{ tn("supportLink") }}
          </a>
        </template>
      </i18n-t>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-primary" @click="closeModal()">
        {{ tn("ok") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>
<script setup lang="ts">
import { PropType, computed, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { getOsFamily } from "@/common/browserDetect";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";

const componentName = "CrossPlatformTrialMoveSubscriptionComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["cancel"]);

logEvent("created", componentName, props.device);

const oldComputerOs = ref<string>(props.device.platform.toLowerCase());

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const newComputerOs = computed(() => {
  return getOsFamily().toLowerCase();
});

function closeModal() {
  emits("cancel");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
