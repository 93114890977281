<template>
  <ModalWrapperComponent
    dialog-name="SetUserFieldsComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      <span id="spanHeader">
        {{ tn("headerH1") }}
      </span>
    </template>
    <template #content>
      <ul class="form">
        <li>
          <label id="lblFirstName" for="first-name">{{ tn("firstName") }}</label>
          <InputErrorComponent :show="hasFirstNameErrors">
            <input id="first-name" v-model.trim="firstName" type="text" autocomplete="off" />
            <template #error>
              <span id="spanFirstNameError">
                {{ firstNameErrorText }}
              </span>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <label id="lblLastName" for="last-name">{{ tn("lastName") }}</label>
          <InputErrorComponent :show="hasLastNameErrors">
            <input id="last-name" v-model.trim="lastName" type="text" autocomplete="off" />
            <template #error>
              <span id="spanLastNameError">
                {{ lastNameErrorText }}
              </span>
            </template>
          </InputErrorComponent>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button
        id="btnSaveChanges"
        type="submit"
        class="btn-primary"
        :disabled="!updatePersonalInfoEnabled || isSaving"
        @click="save()"
      >
        <spinner :is-spinning="isSaving" />
        {{ tn("footerButtonSave") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { MAX_USER_NAME_LENGTH } from "@/define";
import { nameAndSecurityAnswerRegex } from "@/globalRegex";
import { unifiedApi } from "@/common";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import Spinner from "@/components/shared/Spinner.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { IUpdateSubscriberResponse, IUpdateSubscriberRequest, IUser } from "@/common/api/unifiedPortal/interfaces";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { reportStringErrors } from "@/common/validator";

const componentName = "SetUserFieldsComponent";

const props = defineProps({
  user: {
    type: Object as PropType<IUser>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.user);

const userStore = useUserStore();
const notificationsStore = useNotificationsStore();

const isSaving = ref<boolean>(false);
const firstName = ref<string>(props.user.firstName);
const lastName = ref<string>(props.user.lastName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function close() {
  emits("close-modal");
}

function beforeOpen() {
  isSaving.value = false;
  firstName.value = props.user.firstName;
  lastName.value = props.user.lastName;
}

const hasFirstNameErrors = computed(() => {
  return firstNameErrorText.value.length > 0;
});

const hasLastNameErrors = computed(() => {
  return lastNameErrorText.value.length > 0;
});

const updatePersonalInfoEnabled = computed(() => {
  return !hasFirstNameErrors.value && !hasLastNameErrors.value;
});

const firstNameErrorText = computed(() => {
  return reportStringErrors(firstName.value, tn("firstNameError"), 0, MAX_USER_NAME_LENGTH, nameAndSecurityAnswerRegex);
});

const lastNameErrorText = computed(() => {
  return reportStringErrors(lastName.value, tn("lastNameError"), 0, MAX_USER_NAME_LENGTH, nameAndSecurityAnswerRegex);
});

async function save() {
  let updatedUserInfo = {} as IUpdateSubscriberRequest;

  isSaving.value = true;
  notificationsStore.clearNotifications();

  try {
    if (updatePersonalInfoEnabled.value) {
      updatedUserInfo = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: props.user.email,
        alternateEmail: props.user.alternateEmail,
      };
      const resp: IUpdateSubscriberResponse = (await unifiedApi.updateUserPersonalInfo(updatedUserInfo)).data;

      if (resp.success && userStore.currentUser) {
        userStore.currentUser.firstName = firstName.value;
        userStore.currentUser.lastName = lastName.value;
        emits("save-modal", "ok");
      } else {
        notificationsStore.addNotification({ type: "ServerErrors" });
        logEvent("User info is not updated", componentName, updatedUserInfo);
      }
    }
  } catch (err) {
    logEvent("setUserFields", componentName);
    emits("save-modal", "failure");
    handleApiError(err as AxiosError, true, "SetUserFieldsFailure");
  }
  isSaving.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
