<template>
  <div v-if="showSpinner" class="personal-information">
    <LoadingSpinner />
  </div>
  <div v-else class="personal-information">
    <div class="content">
      <NotificationsComponent
        context="PersonalInformationComponent"
        @notification-open-primary-email-verification="toggleVerifyPrimaryEmailAddressModal(null)"
        @notification-open-pending-email-verification="showVerifyEmailAddressModal('verifyEmailAddress')"
      />
      <section class="mb-double">
        <h1>{{ tn("personalInfo") }}</h1>
        <dl>
          <dt>{{ tn("firstName") }}</dt>
          <dd :class="{ notSetUp: !userFirstName }">
            <span id="userFirstName">{{ userFirstName ? userFirstName : tn("noEntry") }}</span>
          </dd>
          <dt>{{ tn("lastName") }}</dt>
          <dd :class="{ notSetUp: !userLastName }">
            <span id="userLastName">{{ userLastName ? userLastName : tn("noEntry") }}</span>
          </dd>
        </dl>
        <button id="btnHeaderEdit" class="btn-outline-primary" type="button" @click="toggleUserFieldsModal('click')">
          {{ tn("update") }}
        </button>
      </section>

      <emailSection @show-spinner="setSpinner" />

      <section class="mb-double">
        <h2 class="h1">{{ tn("Password") }}</h2>
        <dl>
          <dt>{{ tn("Password") }}:</dt>
          <dd>************</dd>
        </dl>
        <button
          id="btnEditPassword"
          class="btn-outline-primary"
          type="button"
          @click="toggleUserPasswordModal('click')"
        >
          {{ tn("update") }}
        </button>
      </section>

      <section class="security-questions mb-double">
        <h3 class="h1">{{ tn("SecurityQuestions") }}</h3>
        <div v-if="questions">
          <dl>
            <dt>{{ tn("SecurityQuestion1Header") }}:</dt>
            <dd :class="{ notSetUp: !questions.securityQuestionOne }">
              <span id="question1">
                {{ questions.securityQuestionOne ? questions.securityQuestionOne : tn("noEntry") }}
              </span>
            </dd>
            <dt>{{ tn("SecurityQuestion2Header") }}:</dt>
            <dd :class="{ notSetUp: !questions.securityQuestionTwo }">
              <span id="question2">
                {{ questions.securityQuestionTwo ? questions.securityQuestionTwo : tn("noEntry") }}
              </span>
            </dd>
            <dt>{{ tn("SecurityQuestion3Header") }}:</dt>
            <dd :class="{ notSetUp: !questions.securityQuestionThree }">
              <span id="question3">
                {{ questions.securityQuestionThree ? questions.securityQuestionThree : tn("noEntry") }}
              </span>
            </dd>
          </dl>
        </div>
        <button
          id="btnEditSecurityQuestions"
          class="btn-outline-primary"
          type="button"
          :disabled="!enableSecurityQuestionUpdate"
          @click="toggleSecurityQuestionsModal('click')"
        >
          {{ tn("update") }}
        </button>
      </section>

      <section class="two-step mb-double">
        <h4 class="h1">
          {{ tn("TwoStep") }}
          <span v-if="!phoneNumber">
            <small>{{ tn("TwoStep2") }}</small>
          </span>
        </h4>
        <div v-if="!!phoneNumber">
          <dl>
            <dt>
              {{ tn("TwoStepPhoneNumber") }}
            </dt>
            <dd>
              {{ formatPhoneNumber() }}
              <span v-if="!isVerified" id="twoStepPhoneNumber" class="not-verified">
                {{ tn("TwoStepNotVerified") }}
              </span>
            </dd>
            <dd v-if="!isVerified">
              <button id="linkFinish2fa" class="btn-link" @click="openTwoStepDialog">{{ tn("FinishSetup") }}</button>
            </dd>
          </dl>
          <button
            v-if="!!phoneNumber"
            id="btnEdit2fa"
            class="btn-outline-primary"
            type="button"
            @click="openTwoStepDialog"
          >
            {{ tn("update") }}
          </button>
        </div>
        <div v-else>
          <p class="notSetUp">{{ tn("TwoStepNotSetUp") }}</p>
          <button id="btnTurnOn2fa" type="button" class="btn-outline-primary" @click="openTwoStepDialog">
            {{ tn("TwoStepTurnOn2fa") }}
          </button>
          <p class="mt">
            <strong>{{ tn("TwoStepParagraph1Heading") }}</strong>
            <br />
            {{ tn("TwoStepParagraph1") }}
          </p>
          <p>{{ tn("TwoStepParagraph2") }}</p>
          <p>{{ tn("TwoStepParagraph3") }}</p>
        </div>
      </section>
      <section class="two-step mb-double">
        <h3 class="h1">{{ tn("PreferredLanguage") }}</h3>
        <dl>
          <dt>{{ tn("PreferredEmailLanguage") }} {{ preferredLanguage }}</dt>
        </dl>
        <button
          id="btnPreferredLanguageEdit"
          class="btn-outline-primary"
          type="button"
          @click="togglePreferredEmailLanguage('click')"
        >
          {{ tn("update") }}
        </button>
      </section>
    </div>
    <SetUserFields
      :show-dialog="showUserFieldsDialog"
      :user="userFieldsDialogData"
      @close-modal="toggleUserFieldsModal"
      @save-modal="setUserFields"
    />
    <SetUserPassword
      :show-dialog="showUserPasswordDialog"
      @close-modal="toggleUserPasswordModal"
      @save-modal="setUserPassword"
    />
    <SetSecurityQuestions
      :show-dialog="showSecurityQuestionsDialog"
      :questions-list="questionsList"
      @close-modal="toggleSecurityQuestionsModal"
      @save-modal="setSecurityQuestions"
    />
    <SetTwoStepVerificationComponent
      :show-dialog="showSetTwoStepVerificationModal"
      :phone-number="phoneNumber"
      :current-user="user"
      :is-verified="isVerified"
      :country-codes="countryCodes"
      @close-modal="handleSetTwoStepVerificationModal"
      @save-modal="handleSetTwoStepVerificationModal"
    />
    <PreferredEmailLanguage
      :show-dialog="showPreferredEmailLanguageDialog"
      @close-modal="togglePreferredEmailLanguage"
    />
    <VerifyPrimaryEmailAddressComponent
      :show-dialog="showVerifyPrimaryEmailAddressModal"
      :email="user?.email ?? ''"
      @close-modal="toggleVerifyPrimaryEmailAddressModal"
    />
  </div>
</template>

<script setup lang="ts">
export interface ISelectListItem<T> {
  name: string;
  value: T;
}

import { computed, ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import {
  ISecurityQuestions,
  ISecurityQuestionsList,
  IPhoneCountryCodes,
  IUser,
  ILoggedInUser,
} from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common/index";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import SetUserFields from "@/components/PersonalInformation/SetUserFields.vue";
import SetUserPassword from "@/components/PersonalInformation/SetUserPassword.vue";
import SetSecurityQuestions from "@/components/PersonalInformation/SetSecurityQuestions.vue";
import SetTwoStepVerificationComponent from "@/components/PersonalInformation/SetTwoStepVerification.vue";
import PreferredEmailLanguage from "@/components/PersonalInformation/PreferredEmailLanguage.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { transformPhoneCodeDisplayNames } from "@/common/phoneCodeHelper";
import { languageDisplayNames } from "@/common/Language";
import { usePersonalInfoStore } from "@/stores/personalInfo";
import emailSection from "@/components/PersonalInformation/EmailSection.vue";
import VerifyPrimaryEmailAddressComponent from "@/components/shared/dialogs/VerifyPrimaryEmail/VerifyPrimaryEmailAddress.vue";

const name = "PersonalInformationComponent";

logEvent("created", name);
const route = useRoute();
const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const personalInfoStore = usePersonalInfoStore();

// refs for reactivity
const questions = ref<ISecurityQuestions | null>(null);
const phoneNumber = ref("");
const isVerified = ref(false);
const showSpinner = ref(true);

// computed properties based on the current user
const user = computed(() => {
  return userStore.currentUser;
});

const userDetails = computed(() => {
  return userStore.userDetails;
});
const userFirstName = computed(() => {
  return user.value?.firstName;
});
const userLastName = computed(() => {
  return user.value?.lastName;
});
const preferredLanguage = computed(() => {
  return languageDisplayNames[userDetails.value?.languageCode || "en"];
});
const email = ref<string>("");

const enableSecurityQuestionUpdate = ref(true);
const showUserFieldsDialog = ref<boolean>(false);
const userFieldsDialogData = computed(() => {
  const userData: IUser = {
    userGuid: user.value?.userGuid || "",
    firstName: user.value?.firstName == null ? "" : user.value?.firstName,
    lastName: user.value?.lastName == null ? "" : user.value?.lastName,
    personId: user.value?.personId == null ? 0 : user.value?.personId,
    email: user.value?.email == null ? "" : user.value?.email,
    alternateEmail: user.value?.alternateEmail == null ? "" : user.value?.alternateEmail,
  };
  return userData;
});
const questionsList = ref<ISecurityQuestionsList>({
  questionGroup1: [],
  questionGroup2: [],
  questionGroup3: [],
});
const showUserPasswordDialog = ref<boolean>(false);
const showSecurityQuestionsDialog = ref<boolean>(false);
const countryCodes = ref<IPhoneCountryCodes[]>([]);
const showSetTwoStepVerificationModal = ref<boolean>(false);
const showPreferredEmailLanguageDialog = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

const selectedLanguage = computed(() => {
  return userStore.selectedLanguage;
});

watch(selectedLanguage, async () => {
  const [questionsRes] = await Promise.all([unifiedApi.getSecurityQuestionsList(selectedLanguage.value)]);
  questionsList.value = questionsRes.data;
});

const apiUser = ref<ILoggedInUser>();

// life cycle hooks
onMounted(async () => {
  logEvent("mounted", name, user.value);
  notificationsStore.clearNotifications();
  await getQuestions();
  if (user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  }
  phoneNumber.value = await getSecurityPhoneFor2FA();
  if (phoneNumber.value && !isVerified.value) {
    notificationsStore.addNotification({ type: "SetTwoStepVerificationUnverifiedPhone" });
  }
  await personalInfoStore.checkPendingEmailVerification();
  await reloadSubscriberInfo();
  getApiUser();
  email.value = user.value?.email ?? "";
  showSpinner.value = false;
  manageSetTwoStepVerificationUrl();
  getQuestionsList();
});

function setSpinner(value: boolean) {
  showSpinner.value = value;
}

async function getApiUser() {
  try {
    apiUser.value = (await unifiedApi.getUser()).data;
    if (apiUser.value) {
      userStore.setLoggedInUser(apiUser.value);
    }
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getQuestions`);
    handleApiError(error as AxiosError);
  }
}

function showVerifyEmailAddressModal(type: string) {
  personalInfoStore.showVerifyEmailAddressModal = type;
}

async function getQuestions() {
  try {
    if (user.value) {
      questions.value = user.value?.subscriberInfo?.securityQuestions
        ? user.value?.subscriberInfo?.securityQuestions
        : (await unifiedApi.getSecurityQuestions()).data;
      logEvent("questions loaded", name, questions.value);
    }
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getQuestions`);
    handleApiError(error as AxiosError);
  }
}

async function getSecurityPhoneFor2FA() {
  try {
    let phoneNumber = "";

    if (user.value) {
      const securityPhone = (await unifiedApi.getUserSecurityPhone()).data;
      logEvent("SecurityPhone", name, securityPhone?.phoneNumber);
      phoneNumber = securityPhone?.phoneNumber;
      isVerified.value = !!securityPhone?.phoneNumberValidatedDate;
    }

    logEvent("phoneNumber", name, phoneNumber);
    return phoneNumber;
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getUserSecurityPhone`);
    handleApiError(error as AxiosError);
    return "";
  }
}

async function openTwoStepDialog() {
  try {
    if (countryCodes.value.length === 0) {
      countryCodes.value = transformPhoneCodeDisplayNames(
        selectedLanguage.value,
        (await unifiedApi.getPhoneCountryCodes()).data
      );
    }

    //Need to call for the info before opening the modal in case the user had gone through the first step and canceled out of the second step
    phoneNumber.value = await getSecurityPhoneFor2FA();
    toggleSetTwoStepVerificationModal("openTwoStepDialog");
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getPhoneCountryCodes`);
    handleApiError(error as AxiosError);
  }
}

function toggleSetTwoStepVerificationModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleSetTwoStepVerificationModal",
    name,
    `${showSetTwoStepVerificationModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  return (showSetTwoStepVerificationModal.value = !showSetTwoStepVerificationModal.value);
}

async function handleSetTwoStepVerificationModal(result: string) {
  notificationsStore.clearNotifications();
  try {
    if (result === "OK") {
      notificationsStore.addNotification({ type: "SetTwoStepVerificationSuccess" });
    }

    if (result === "DELETED") {
      phoneNumber.value = "";
      isVerified.value = false;
      notificationsStore.addNotification({ type: "SetTwoStepVerificationDeletePhone" });
    } else {
      phoneNumber.value = await getSecurityPhoneFor2FA();

      if (!!phoneNumber.value && !isVerified.value) {
        notificationsStore.addNotification({ type: "SetTwoStepVerificationUnverifiedPhone" });
      }
    }

    toggleSetTwoStepVerificationModal("handleSetTwoStepVerificationModal");
  } catch (err) {
    logException(err as Error);
  }
}

function formatPhoneNumber(): string {
  const phone = phoneNumber.value.split(" ");
  if (phone && phone[0] === "+1") {
    const usPhone = phone[1].replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (usPhone) {
      return !usPhone[2] ? usPhone[1] : `${phone[0]} (${usPhone[1]}) ${usPhone[2]}-${usPhone[3]}`;
    }
  }
  return `${phoneNumber.value}`;
}

function manageSetTwoStepVerificationUrl() {
  //cSpell:ignore setupsecurityphone
  //if url contains mode=setupsecurityphone call showSetTwoStepVerification and show set2FA model
  const mode: string = route.query.mode ? route.query.mode.toString().toLowerCase() : "";
  if (mode === "setupsecurityphone") {
    openTwoStepDialog();
  }
}

async function toggleUserFieldsModal(type: string | null) {
  type = type || "modal";
  if (user.value) {
    notificationsStore.clearNotifications();
    logEvent("toggleUserFieldsModal", name, `${showUserFieldsDialog.value ? "Hiding" : "Showing"} via ${type}`);
    showUserFieldsDialog.value = !showUserFieldsDialog.value;
  } else {
    logEvent("toggleUserFieldsModal", name, `No action due to no user via ${type}`);
  }
}

async function setUserFields(res) {
  toggleUserFieldsModal("setUserFields");
  if (res === "ok") {
    notificationsStore.addNotification({ type: "SetUserFieldsSuccess" });
  }
}

async function toggleUserPasswordModal(type: string | null) {
  type = type || "modal";
  if (user.value) {
    notificationsStore.clearNotifications();
    logEvent("toggleUserPasswordModal", name, `${showUserPasswordDialog.value ? "Hiding" : "Showing"} via ${type}`);
    showUserPasswordDialog.value = !showUserPasswordDialog.value;
  } else {
    logEvent("toggleUserPasswordModal", name, `No action due to no user via ${type}`);
  }
}

async function setUserPassword(res) {
  toggleUserPasswordModal("setUserPassword");
  if (res === "ok") {
    notificationsStore.addNotification({ type: "SetPasswordSuccess" });
  }
}

async function toggleSecurityQuestionsModal(type: string | null) {
  type = type || "modal";
  if (user.value) {
    notificationsStore.clearNotifications();
    logEvent(
      "toggleSecurityQuestionsModal",
      name,
      `${showSecurityQuestionsDialog.value ? "Hiding" : "Showing"} via ${type}`
    );
    showSecurityQuestionsDialog.value = !showSecurityQuestionsDialog.value;
  } else {
    logEvent("toggleSecurityQuestionsModal", name, `No action due to no user via ${type}`);
  }
}

async function setSecurityQuestions(res) {
  showSpinner.value = true;
  toggleSecurityQuestionsModal("setSecurityQuestions");
  if (res === "ok") {
    notificationsStore.addNotification({ type: "SetSecurityQuestionsSuccess" });
    await reloadSubscriberInfo();
    await getQuestions();
  }
  showSpinner.value = false;
}

async function getQuestionsList() {
  try {
    if (user.value) {
      questionsList.value = (await unifiedApi.getSecurityQuestionsList(userStore.selectedLanguage)).data;
    }
  } catch (error) {
    logEvent(`Resulted in not OK ${error}`, `${name}/getSecurityQuestionsList`);
    handleApiError(error as AxiosError);
  }
}

function togglePreferredEmailLanguage(type: string | null) {
  type = type || "modal";
  if (user.value) {
    logEvent(
      "togglePreferredEmailLanguageModal",
      name,
      `${showPreferredEmailLanguageDialog.value ? "Hiding" : "Showing"} via ${type}`
    );
    showPreferredEmailLanguageDialog.value = !showPreferredEmailLanguageDialog.value;
  } else {
    logEvent("togglePreferredEmailLanguage", name, `No action due to no user via ${type}`);
  }
}

const showVerifyPrimaryEmailAddressModal = ref(false);
function toggleVerifyPrimaryEmailAddressModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleVerifyPrimaryEmailAddressModal",
    name,
    `${showVerifyPrimaryEmailAddressModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  showVerifyPrimaryEmailAddressModal.value = !showVerifyPrimaryEmailAddressModal.value;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

h1,
.h1 {
  margin-bottom: 0;
}
dt {
  color: var(--dark-grey);
}
.notSetUp {
  font-style: italic;
  color: var(--dark-grey);
}
li span + span {
  min-width: auto;
  text-align: left;
  padding-right: 0;
}
.two-step p {
  margin-bottom: 20px;
}
h3 small {
  font-weight: normal;
  font-family: var(--opensans);
}
.user-email,
.pending-email {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.not-verified {
  color: var(--danger);
  padding-left: var(--space-half);
}
.verified {
  color: var(--success);
  padding-left: var(--space-half);
}
.left-space {
  padding-left: var(--space-half);
}
.notSetUp {
  color: var(--dark-grey);
  font-style: italic;
  margin-top: var(--space-half);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  border-bottom: 1px solid var(--medium-dark-grey);
  padding-bottom: var(--space-half);
}

/* custom media query - not small (600 or medium (960) */
@media (min-width: 768px) {
  dt {
    font-size: var(--small-text);
  }
}
</style>
