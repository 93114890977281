<template>
  <div :id="`device${tagId}`" class="card" :class="cardComputerCSS">
    <article class="pt-half pb-half border-around border-radius mb-0">
      <div class="backup-header flex-start">
        <div class="indicator mr-quarter" :class="indicatorCSS">
          <svg-icon-component :id="`indicator${tagId}`" :icon="indicatorIcon" class="icon" />
        </div>
        <div :id="`nickname${tagId}`" class="flex-start">
          <span class="system-icon-wrapper mr-quarter">
            <svg-icon-component :id="`systemIcon${tagId}${systemIcon}`" class="icon" :icon="systemIcon" />
          </span>
          <span class="truncate-mobile-and-med-20ch mr-quarter">{{ computerNameDialogData.computerDescription }}</span>
          <button
            :id="`edit${tagId}`"
            type="button"
            class="inline-btn-link no-shrink"
            @click="toggleComputerModal('click')"
          >
            {{ tn("edit") }}
            <svg-icon-component icon="edit" class="icon-sm" />
          </button>
        </div>
        <ComputerDropDownComponent
          v-show="actions.displayComputerDropDown"
          :device="props.device"
          :tag-id="tagId"
          :actions="actions.computerDropDown"
          :display-forced-reinstall-modal="props.displayForcedReinstallModal"
          :subscription="props.subscription"
          :is-cdlp="props.isCdlp"
          @buy-now="buyNow"
          @change-plan="changePlan"
          @set-installer-type="setInstallerType"
        />
      </div>
      <DeviceStatusComponent
        :device="props.device"
        :tag-id="tagId"
        :display-view-files-and-get-files-back-prop="actions.displayViewFilesAndGetFilesBack"
        :is-cdlp="props.isCdlp"
        @indicator="getDeviceStatusIndicator"
      />
      <ProductDetailsComponent
        v-if="!isCdlp"
        :device="props.device"
        :tag-id="tagId"
        :actions="actions"
        :current-customer-type="currentCustomerType"
        :current-expiration-state="currentExpirationState"
        :current-term-type="currentTermType"
        :reseller-email="props.resellerEmail"
        :subscription="props.subscription"
        @buy-now="buyNow"
        @change-payment-method="changePaymentMethod"
        @auto-renew-updated="autoRenewUpdated"
        @move-payment-method-mr-to-ar="movePaymentMethodMRtoAR"
      />
    </article>
    <WebrootLicenseCardComputerComponent
      :current-customer-type="currentCustomerType"
      :device="device"
      :current-expiration-state="currentExpirationState"
      :tag-id="tagId"
      :expiration-date="device.expirationDate"
      :user-id="props.userId"
      @update-device-keycode="handleUpdateKeycode"
    />

    <ComputerNameComponent
      :show-dialog="showComputerNameDialog"
      :description="computerNameDialogData"
      @close-modal="toggleComputerModal"
      @save-modal="setNewComputerName"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, PropType } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import {
  IDevice,
  ISubscription,
  IComputerDescription,
  IDeviceKeycodeUpdateRequest,
} from "@/common/api/unifiedPortal/interfaces";
import { NotificationType, useNotificationsStore } from "@/stores/notifications";
import { SUBSCRIPTION_MIN_ACTIVE_DAY, SUBSCRIPTION_MIN_EXPIRED_DAY } from "@/define";
import { ICardComputerConfigActions, cardComputerConfig } from "./CardComputerConfig";
import { customerType, expirationState, termType, expirationMessageTypes } from "./CardComputerEnums";
import WebrootLicenseCardComputerComponent from "./WebrootLicenseCardComputer.vue";
import DeviceStatusComponent from "./DeviceStatus.vue";
import ComputerDropDownComponent from "./ComputerDropDown.vue";
import ProductDetailsComponent from "./ProductDetails.vue";
import ComputerNameComponent from "@/components/Backup/CardComputer/ComputerName.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { useRouter } from "vue-router";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { pageStates as buyPageStates } from "@/components/Buy/BuyEnums";
import { useUserStore } from "@/stores/user";

// CSS names
const cardWarning = "warning-border";
const cardDanger = "danger-border";
const danger = "danger";
const alert = "alert";
const success = "success";

const name = "CardComputerComponent";

const props = defineProps({
  userId: {
    type: Number,
    required: false,
    default: null,
  },
  resellerEmail: {
    type: String,
    required: false,
    default: null,
  },
  subscription: {
    type: Object as PropType<ISubscription>,
    required: false,
    default: null,
  },
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  displayForcedReinstallModal: {
    type: Boolean,
    required: false,
    default: null,
  },
  isCdlp: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits([
  "show-invoice-details",
  "set-installer-type",
  "change-payment-method",
  "auto-renew-updated",
  "move-payment-method-mr-to-ar",
  "update-device-keycode",
]);

// Pinia Stores
const notificationsStore = useNotificationsStore();
const buyFlowStore = useBuyFlowStore();
const router = useRouter();
const userStore = useUserStore();

// Identifiers
const tagId = ref<string>("");

// Controls
const currentCustomerType = ref<customerType>(customerType.direct);
const currentExpirationState = ref<expirationState>(expirationState.none);
const currentTermType = ref<termType>(termType.annual);

// Warnings
const cardComputerCSS = ref<string>("");

// Actions
let actions: ICardComputerConfigActions = {
  expirationMessageType: expirationMessageTypes.validUntil,
  displayBuyNowButton: false,
  displayRenewNowButton: false,
  displayResellerEmail: false,
  displayComputerDropDown: false,
  displayViewFilesAndGetFilesBack: false,
  displaySetAutoRenewLink: false,
  computerDropDown: {
    displayRenew: false,
    displayBuyNow: false,
    displayMove: false,
    displayReinstall: false,
    displayChangePlan: false,
  },
};

// calculatedCSS
const indicatorIcon = ref<string>("circle-check");
const indicatorCSS = ref<string>(success);

const isUnderMaintenance = computed(() => {
  return !!userStore.currentUser?.subscriberInfo?.isUnderMaintenance;
});

logEvent("created", name, props.device);
logEvent("resellerEmail", name, props.resellerEmail);
if (props.device) {
  logEvent("Device Id", name, props.device.deviceId);
  tagId.value = `-cid${props.device.deviceId}`;
  setCurrentTypesAndDisplay();
}

const showComputerNameDialog = ref<boolean>(false);
const computerNameDialogData = ref<IComputerDescription>({
  computerId: props.device.deviceId,
  computerDescription: props.device.description,
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

function toggleComputerModal(type: string | null) {
  type = type || "modal";
  logEvent("toggleComputerModal", name, `${showComputerNameDialog.value ? "Hiding" : "Showing"} via ${type}`);
  showComputerNameDialog.value = !showComputerNameDialog.value;
}

function setNewComputerName(response: IComputerDescription) {
  try {
    computerNameDialogData.value.computerDescription = response?.computerDescription;
    logEvent("Device Updated", name, computerNameDialogData.value);
    toggleComputerModal("setNewComputerName");
  } catch (err) {
    logException(err as Error);
  }
}

// getters
const systemIcon = computed(() => {
  return props.device.platform === "Windows" ? "windows" : "apple";
});

// Render
function setDangerIndicators() {
  cardComputerCSS.value = cardDanger;
  indicatorIcon.value = "circle-exclamation";
  indicatorCSS.value = danger;
}

function setAlertIndicators() {
  if (indicatorIcon.value !== "circle-exclamation") {
    indicatorIcon.value = "circle-triangle";
    indicatorCSS.value = alert;
    cardComputerCSS.value = cardWarning;
  }
}

function setCurrentTypesAndDisplay() {
  currentTermType.value = props.device.isTrial
    ? termType.trial
    : props.device.isEvergreenMonthly
      ? termType.evergreen
      : termType.annual;
  currentCustomerType.value = props.device.isPartnerReferral
    ? customerType.direct // Partner Referral will be treated as Direct consumer
    : props.device.isResellerManaged && props.device.daysRemaining > -29
      ? customerType.indirect // Reseller User
      : currentTermType.value === termType.trial && props.device.isResellerManaged
        ? customerType.indirect // Reseller User under a trial
        : customerType.direct;

  if (props.device) {
    const isTrial = currentTermType.value === termType.trial;
    const isIndirect = currentCustomerType.value === customerType.indirect;

    currentExpirationState.value =
      props.device.daysRemaining <= SUBSCRIPTION_MIN_ACTIVE_DAY &&
      props.device.daysRemaining > SUBSCRIPTION_MIN_EXPIRED_DAY
        ? expirationState.isExpiring
        : props.device.daysRemaining <= SUBSCRIPTION_MIN_EXPIRED_DAY
          ? expirationState.expired
          : expirationState.none;

    // Bypass for Evergreen
    currentExpirationState.value =
      currentTermType.value == termType.evergreen ? expirationState.none : currentExpirationState.value;

    if (currentExpirationState.value === expirationState.isExpiring && !props.device.autoRenewInfo) {
      //Do not show expired notifications if user is under maintenance
      if (!isUnderMaintenance.value) {
        notificationsStore.addNotification({
          type:
            isTrial && isIndirect
              ? "ResellerTrialSubscriptionsAlmostExpired"
              : isTrial
                ? "SubscriptionWillExpireTrial"
                : isIndirect
                  ? "ResellerSubscriptionsAlmostExpired"
                  : "CardComputerSubscriptionWillExpire",
          dismissible: false,
        });
      }
      setAlertIndicators();
    }

    if (currentExpirationState.value === expirationState.expired) {
      if (!isUnderMaintenance.value) {
        notificationsStore.addNotification({
          type:
            isTrial && isIndirect
              ? "ResellerTrialSubscriptionsExpired"
              : isIndirect
                ? "ResellerSubscriptionsExpired"
                : "CardComputerSubscriptionExpired",
          dismissible: false,
        });
      }
      setDangerIndicators();
    }

    setActionsToDisplay();

    // Outstanding Invoice
    if (props.device.hasOutstandingInvoice) {
      if (!isUnderMaintenance.value) {
        notificationsStore.addNotification({ type: "SubscriptionsExpired", dismissible: false });
      }
      actions.displayRenewNowButton = true;
      actions.computerDropDown.displayRenew = true;
      actions.computerDropDown.displayChangePlan = false;
      setDangerIndicators();
    }
  }
}

function setActionsToDisplay() {
  const isAutoRenew = props.device.autoRenewInfo != null;

  const configActions = cardComputerConfig.find(
    c =>
      c.customerType === currentCustomerType.value &&
      c.expirationState === currentExpirationState.value &&
      c.termType === currentTermType.value &&
      c.isAutoRenewSet == isAutoRenew
  );

  if (configActions) {
    actions = configActions.actions;
  }
}

function getDeviceStatusIndicator(type: string, message: string) {
  logEvent(type, name, message);
  if (type === "Alert") {
    setAlertIndicators();
  }

  if (type === "Danger") {
    setDangerIndicators();
  }

  if (message.length > 0) {
    const notificationType = message as NotificationType;
    notificationsStore.addNotification({ type: notificationType, dismissible: false });
  }
}

// Actions
function buyNow(isRenew: boolean) {
  logEvent("buyNow", name, "Called");
  if (props.device.hasOutstandingInvoice) {
    emits("show-invoice-details", props.device.deviceId);
    return;
  }

  if (isRenew) {
    renewNow();
  } else {
    changePlan();
  }
}

async function renewNow() {
  var catIdString = "";
  if (props.device.catId && props.device.catId > 0) {
    catIdString = props.device.catId.toString();
  }

  router.push({
    name: "buy",
    query: {
      step: buyPageStates.payment,
      deviceId: props.subscription.computer?.id,
      ratePlanId: props.subscription.ratePlanId,
      subscriptionNumber: props.subscription.zuoraSubscriptionNumber,
      cid: catIdString,
    },
  });
}

async function changePlan() {
  logEvent("changePlan", name, "Called");
  if (props.device.hasOutstandingInvoice) {
    emits("show-invoice-details", props.device.deviceId);
    return;
  }

  var catIdString = "";
  if (props.device.catId && props.device.catId > 0) {
    catIdString = props.device.catId.toString();
  }

  // We need to clear the cart if one already exists, so we can start over.
  await buyFlowStore.clearCart();

  if (props.subscription.computer?.id) {
    router.push({
      name: "selectPlan",
      query: {
        deviceId: props.subscription.computer?.id,
        ratePlanId: props.subscription.ratePlanId,
        subscriptionNumber: props.subscription.zuoraSubscriptionNumber,
        cid: catIdString,
      },
    });
  } else {
    router.push({
      name: "selectPlan",
      query: {
        sku: props.subscription.sku,
        ratePlanId: props.subscription.ratePlanId,
        subscriptionNumber: props.subscription.zuoraSubscriptionNumber,
        cid: catIdString,
      },
    });
  }
}

function setInstallerType(device: IDevice, type: string) {
  emits("set-installer-type", device, type);
  logEvent("set-installer-type event", name, "Emitted");
}

function changePaymentMethod() {
  emits("change-payment-method");
  logEvent("change-payment-method event", name, "Emitted");
}

function autoRenewUpdated(subscription: ISubscription) {
  emits("auto-renew-updated", subscription);
  logEvent("auto-renew-updated event", name, "Emitted");
}

function movePaymentMethodMRtoAR(subscription: ISubscription) {
  emits("move-payment-method-mr-to-ar", subscription);
  logEvent("move-payment-method-mr-to-ar event", name, "Emitted");
}

function handleUpdateKeycode(keycode: string) {
  logEvent("handleUpdateKeycode", name, keycode);
  emits("update-device-keycode", {
    deviceId: props.device.deviceId,
    keycode: keycode,
  } as IDeviceKeycodeUpdateRequest);
}
</script>
