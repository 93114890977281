export enum pageStates {
  addCard = "addCard",
  displayCards = "displayCards",
  selectedCard = "selectedCard",
  hidden = "hidden",
  forceAddCard = "forceAddCard",
}

// Credit cards return the fully spelled out state names, paypal returns the 2 character abbreviations
export const autoRenewOptInCountries = ["United States", "US"];

// Credit cards return the fully spelled out state names, paypal returns the 2 character abbreviations
export const autoRenewOptInUSStates = ["California", "Vermont", "New York", "CA", "VT", "NY"];
