<template>
  <div>
    <section>
      <h1 class="opposite-ends">
        <span class="h1">{{ t("ShippingForm.shipping") }}</span>
        <button
          id="btnEditShipping"
          type="button"
          class="text-sm inline-btn-link only-show-desktop-inline no-print"
          @click="editShipping()"
        >
          {{ t("Common.edit") }}
        </button>
      </h1>
    </section>

    <div v-if="buyFlowStore.shippingMatchesBilling" class="constrain-65">
      <BuySelectedCreditCardComponent
        :selected-payment-method-id="buyFlowStore.cart?.paymentMethodId || ''"
        :only-show-address="true"
        :show-all-state-non-u-s-paypal-shipping-address="false"
      />
    </div>
    <ul v-else id="shipping-address" class="mb-double">
      <li id="shippingAddressFullName">
        {{ buyFlowStore.shippingAddress.firstName }} {{ buyFlowStore.shippingAddress.lastName }}
      </li>
      <li id="shippingAddress1">{{ buyFlowStore.shippingAddress.address1 }}</li>
      <li v-if="buyFlowStore.shippingAddress.address2" id="shippingAddress2">
        {{ buyFlowStore.shippingAddress.address2 }}
      </li>
      <li id="shippingAddress3">
        {{
          `${buyFlowStore.shippingAddress.city}, ${buyFlowStore.shippingAddress.state} ${buyFlowStore.shippingAddress.zipCode}`
        }}
      </li>
      <li id="shippingAddressCountry">{{ buyFlowStore.shippingAddress.country }}</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { logEvent } from "@/common/logger";
import { onBeforeMount } from "vue";
import { layoutStates } from "@/components/Buy/BuyEnums";
import BuySelectedCreditCardComponent from "@/components/Buy/Payment/SelectedCreditCard.vue";

const componentName = "DisplayShippingInfo";
const buyFlowStore = useBuyFlowStore();

onBeforeMount(() => {
  logEvent("Created", componentName);
});

function editShipping() {
  buyFlowStore.layoutState = layoutStates.editShipping;
}
</script>
