<template>
  <i18n-t :keypath="'NotificationsComponent.' + props.notification.type" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <router-link :to="{ name: props.notification?.params?.url }">{{ tn("AccountLockedOutLink") }}</router-link>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";

const componentName = "AccountLockedOutNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
