<template>
  <TileWrapper :key="td.status" :is-loading="isLoading" :tile-data="td" notification-context="BackupOverviewComponent">
    <template #title>{{ t(`${textRoot}${td.title}`) }}</template>

    <template #content>
      <RequiresInstallOrPurchaseComponent
        v-if="showRequiresInstallOrPurchase"
        :tile-data="td"
        @button-action="installOrCompare()"
      />
      <TileDetails v-else :path="td.detailsLink" :title="td.title">
        <template #content>
          <ul>
            <li v-for="(item, i) in td.items" :key="i" :class="calcItemClass(item)">
              <span>{{ t(`${textRoot}${item.caption}`) }}</span>
              <b v-if="td.type === 'tile'">{{ convertAmount(item) }}</b>
              <em v-if="td.type === 'tile'">{{ convertUnitType(item) }}</em>
              <SvgIconComponent :icon="item.iconType || ''" />
            </li>
          </ul>
        </template>
      </TileDetails>
    </template>
  </TileWrapper>
</template>

<script setup lang="ts">
import { ref, onMounted, PropType, computed, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { IconType, TileType, TileTitle, UnitType, StatusType, StateType } from "@/globalEnums";
import { BYTES_IN_MEGABYTE } from "@/define";
import TileWrapper from "@/components/shared/TileWrapper.vue";
import TileDetails from "@/components/shared/TileDetails.vue";
import RequiresInstallOrPurchaseComponent from "@/components/Home/RequiresInstallOrPurchase.vue";
import { clearRefreshFunctions } from "@/components/shared/LanguageSelector/LanguageSelector";
import { getUserDetailsFromStore } from "@/common/userDetails";
import { isPurgedComputer } from "@/components/Backup/purgedComputer";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { getUrl } from "@/common/getUrl";
import { ITileData, i18nRoot, calcItemClass, convertAmount, convertUnitType } from "@/components/Home";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const name = "BackupOverviewComponent";
const subscriptionsStore = useSubscriptionsStore();

const props = defineProps({
  subscriptionStatusType: {
    type: String as PropType<StatusType>,
    default: StatusType.normal,
  },
});
const emits = defineEmits(["tile-ready", "tile-count"]);

logEvent("created", name);
const textRoot = ref<string>(i18nRoot);
const router = useRouter();
const isLoading = ref(true);
const td = ref<ITileData>({
  title: TileTitle.backupOverview,
  type: TileType.tile,
  status: StatusType.normal,
  state: StateType.installed,
});
const subscriptionStatusType = computed<StatusType>(() => props.subscriptionStatusType);
const deviceDangerAlertLevel = ["Disabled", "OverdueSevere", "Expired"];
const deviceWarningAlertLevel = ["Uninstalled", "Unknown", "Frozen", "Overdue"];

onMounted(async () => {
  try {
    emits("tile-count");
    await getBackupSummary();
    getAlertFromSubscription();
    isLoading.value = false;
    emits("tile-ready");
  } catch (ex) {
    logException(ex as Error);
  }
});

onUnmounted(() => {
  clearRefreshFunctions();
});

async function getBackupSummary() {
  try {
    await subscriptionsStore.populateSubscriptions();
    const subscriptions = subscriptionsStore.subscriptions;
    const hasSafeLite = subscriptions.some(s => s.packageName === "Safe Lite");

    const userDetails = await getUserDetailsFromStore(false);
    if (!hasSafeLite && (!userDetails?.devices || userDetails?.devices?.length === 0)) {
      // backup compare plans
      td.value = {
        title: TileTitle.backupOverview,
        type: TileType.tile,
        status: StatusType.normal,
        state: StateType.notPurchased,
        installData: {
          logo: "carb-logo",
          mainText: "backupNotPurchasedP1",
          auxText: "backupNotPurchasedP2",
          protectText: "protectYourSelfWithCarbonite",
          installButtonText: "comparePlans",
          installText: "",
        },
        detailsLink: "/backup",
      };
      return;
    }

    // filter purged computers and do not display it.
    const devices = userDetails?.devices.filter(
      d => !isPurgedComputer(d.daysRemaining, d.isTrial, d.isResellerManaged, d.dataHasBeenPurged, d.isEvergreenMonthly)
    );

    if (devices?.every(d => d.deviceId === 0) || hasSafeLite) {
      td.value = {
        title: TileTitle.backupZeroState,
        type: TileType.tile,
        status: StatusType.normal,
        state: StateType.requiresInstall,
        installData: {
          logo: "carb-logo",
          mainText: "backupZeroStateText",
          installButtonText: "install",
          installText: "installText",
        },
        detailsLink: "/backup",
      };
    }

    if (devices?.some(d => d.deviceId > 0)) {
      const fileMbSum = devices?.reduce((total, d) => {
        return total + d.fileSizeMB;
      }, 0);
      const fileCount = devices?.reduce((total, d) => {
        return total + d.fileCount;
      }, 0);
      const alertLevel = devices?.some(d => deviceDangerAlertLevel.includes(d.status))
        ? StatusType.danger
        : devices?.some(d => deviceWarningAlertLevel.includes(d.status))
          ? StatusType.warning
          : StatusType.normal;

      td.value = {
        title: TileTitle.backupOverview,
        type: TileType.tile,
        status: alertLevel,
        state: StateType.installed,
        items: [
          {
            caption: "dataBackedUp",
            value: fileMbSum * BYTES_IN_MEGABYTE, // using scale of 1000s
            unitType: UnitType.bytes,
            iconType: IconType.data,
          },
          {
            caption: "numberOfFiles",
            value: fileCount,
            unitType: UnitType.count,
            iconType: IconType.files,
          },
          {
            caption: "devicesBackedUp",
            value: devices?.length,
            unitType: UnitType.none,
            iconType: IconType.devices,
          },
        ],
        detailsLink: "/backup",
      };
    }
  } catch {
    td.value = {
      title: TileTitle.backupOverview,
      type: TileType.tile,
      status: StatusType.warning,
      state: StateType.serverError,
    };
  }
}

const showRequiresInstallOrPurchase = computed(() => {
  return td.value.state === StateType.notPurchased || td.value.state === StateType.requiresInstall;
});

function installOrCompare() {
  if (td.value.state === StateType.requiresInstall) {
    router.push("/backup");
  } else {
    window.open(getUrl("VITE_APP_CARBONITE_COMPARE_PLANS"));
  }
}

// Override the status if subscription has a higher level alert
watch(subscriptionStatusType, () => {
  getAlertFromSubscription();
});

function getAlertFromSubscription() {
  const isDanger = subscriptionStatusType.value === StatusType.danger;
  const isWarning = subscriptionStatusType.value === StatusType.warning;
  td.value.status = isDanger
    ? StatusType.danger
    : isWarning && td.value.status !== StatusType.danger
      ? StatusType.warning
      : td.value.status;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
