<template>
  <ModalWrapperComponent
    dialog-name="ComputerNameComponent"
    :show-dialog="showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul class="form">
        <li>
          <label :for="`computer-name${computerId}`">{{ tn("computerName") }}</label>
          <InputErrorComponent :show="hasComputerNameErrors">
            <input :id="`computer-name${computerId}`" v-model="computerDescription" type="text" autocomplete="off" />
            <template #error>
              <span id="spanComputerNameError">{{ computerNameErrorText }}</span>
            </template>
          </InputErrorComponent>
        </li>
      </ul>
    </template>
    <template #footer>
      <button :id="`btnCancelChanges${computerId}`" type="button" class="btn-link mr" @click="close()">
        {{ tn("cancel") }}
      </button>
      <button
        :id="`btnSaveChanges${computerId}`"
        type="submit"
        class="btn-primary"
        :disabled="!computerDescription?.trim() || isSaving || hasComputerNameErrors"
        @click="save()"
      >
        <spinner :is-spinning="isSaving" />
        {{ tn("save") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { IComputerDescription } from "@/common/api/unifiedPortal/interfaces";
import { useUserStore } from "@/stores/user";
import { reportStringErrors } from "@/common/validator";
import InputErrorComponent from "@/components/shared/InputError.vue";
import Spinner from "@/components/shared/Spinner.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { MAX_COMPUTER_NAME_LENGTH } from "@/define";
import { computerNameRegex } from "@/globalRegex";

const componentName = "ComputerNameComponent";

const props = defineProps({
  description: {
    type: Object as PropType<IComputerDescription>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.description);

const userStore = useUserStore();
const isSaving = ref<boolean>(false);
const computerDescription = ref<string>(
  props.description.computerDescription?.substring(0, MAX_COMPUTER_NAME_LENGTH - 1)
);
const computerId = ref<number>(props.description.computerId);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function beforeOpen() {
  isSaving.value = false;
  computerDescription.value = props.description.computerDescription?.substring(0, MAX_COMPUTER_NAME_LENGTH - 1);
  computerId.value = props.description.computerId;
}

const hasComputerNameErrors = computed(() => {
  return computerNameErrorText.value.length > 0;
});

const computerNameErrorText = computed(() => {
  return reportStringErrors(
    computerDescription.value,
    tn("computerName"),
    1,
    MAX_COMPUTER_NAME_LENGTH,
    computerNameRegex
  );
});

async function save() {
  isSaving.value = true;
  try {
    if (computerDescription.value?.trim()) {
      const updated = (
        await unifiedApi.updateComputerDescription(props.description.computerId, computerDescription.value)
      ).data;
      if (updated) {
        const userDetails = (await unifiedApi.getUserDetails()).data;
        userStore.userDetails = userDetails;

        //Prepare response object
        const res: IComputerDescription = {
          computerDescription: computerDescription.value,
          computerId: props.description.computerId,
        };
        emits("save-modal", res);
      }
    }
  } catch (error) {
    handleApiError(error as AxiosError);
  }
  isSaving.value = false;
}

function close() {
  emits("close-modal");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
