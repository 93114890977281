// *************
// disk functions
// *************

import { computed, ref, watch } from "vue";
import { logEvent } from "@/common/logger";
import { IRfaComputer, IRfaItem } from "./interfaces";
import { getCurrentPath, componentName, propData, isLoading, showFullPageError, isSmb } from "./commonFn";
import { getFileAccessRoute, initDirectoryDisplay } from "./navigationFn";

export const accountComputers = ref<string[]>([]);
export const computer = ref<IRfaComputer>();
export const showDisks = ref<boolean>(false);
export const currentDisk = ref<number>();
export const selectedDisk = ref<IRfaItem>();
export const previousDisk = ref<number>();
export const currentDirectory = ref<string>();
export const breadcrumbItems = ref<IRfaItem[]>([]);
export const curDiskName = computed(() => {
  let name = "";
  const disk = computer.value?.HardDisks.find(h => h.FileId == currentDisk.value);
  if (disk) name = disk.Name;
  return name;
});
export const currentDisplayDirectory = computed(() => {
  return currentDirectory.value ?? "...";
});

let userRequestsShowDisks = false;
export function setUserRequestsShowDisks(show: boolean) {
  userRequestsShowDisks = show;
}
export function getUserRequestsShowDisks(): boolean {
  return userRequestsShowDisks;
}

//watching currentDisk and if it changes, we update everything to base for that disk
watch(currentDisk, async () => {
  const disk = computer.value?.HardDisks.find(h => h.FileId == currentDisk.value);
  if (disk && disk.FileId != previousDisk.value) {
    logEvent("user switching disks", componentName, disk);

    //we are switching disks, so it may be appropriate to display
    //new full page errors or not. Let the code decide.
    isLoading.value = true;
    showFullPageError.value = false;
    initDirectoryDisplay(disk.Path, disk.Name);

    if (!isSmb.value) {
      history.replaceState(
        { path: getCurrentPath() },
        "",
        getFileAccessRoute(propData.value.computerId, getCurrentPath())
      );
    }

    //the breadcrumbs are no longer valid
    breadcrumbItems.value = [];

    //hide the UI and remember where we came from
    showDisks.value = false;
    previousDisk.value = currentDisk.value;
  }
});

//show/hide the disk selector dropdown
export function toggleDiskSelector() {
  if (computer.value?.HardDisks) {
    showDisks.value = !showDisks.value;
    setUserRequestsShowDisks(showDisks.value);
  }
}
