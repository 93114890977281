<template>
  <div>
    <div class="input-btn-set mb-quarter">
      <div class="input-wrapper">
        <input id="inputSearchTerm" v-model="kbSearchText" type="text" :placeholder="tn('knowledgebaseArticles')" />
      </div>
      <div>
        <button id="btnSearch" type="button" class="btn-primary" @click="search('')">{{ tn("search") }}</button>
      </div>
    </div>
    <p class="mb">
      {{ tn("popularSearches") }}:
      <button id="btnKbReinstall" class="inline-btn-link" type="button" @click="search('reinstall carbonite')">
        {{ tn("reinstallCarbonite", { carbonite: t("Brand.carbonite") }) }}
      </button>
      ,&nbsp;
      <button id="btnKbRenewSub" class="inline-btn-link" type="button" @click="search('renew subscription')">
        {{ tn("renewSubscription") }}
      </button>
      ,&nbsp;
      <button
        id="btnKbRestoreFiles"
        class="inline-btn-link"
        type="button"
        @click="search('restore files to new computer')"
      >
        {{ tn("restoreFiles") }}
      </button>
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const name = "KbSearchComponent";

logEvent("created", name);
const supportSite = "https://support.carbonite.com";
const kbSearchText = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${name}.${v}`, params);
}

onMounted(() => {
  kbSearchText.value = "";
});

function search(text) {
  if (!text) {
    text = kbSearchText.value;
  }
  logEvent("SearchText", name, text);
  window.open(supportSite + `/search/${text}?&utm_campaign=portalSearch&utm_term=${text}`);
}
</script>
