<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
    <li>
      <div>{{ t(`${parentComponentName}.${productCode}UnsupportedReference`) }}</div>
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <i18n-t :keypath="parentComponentName + '.' + productCode + 'StepOne'" tag="p" scope="global">
        <template #copyLink>
          <button class="inline-btn-link" target="_blank" @click="copyCurrentURL()">
            {{ t(`${parentComponentName}.copyLink`) }}
          </button>
        </template>
      </i18n-t>
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepTwo`) }}</p>
      <SelectorComponent :product-code="productCode" />
    </li>
    <li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="t(`${parentComponentName}.${productCode}StepThree`)"></p>
      <Keycodes :keycodes="keycodes" :product-code="productCode" />
    </li>
  </ol>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";
import Keycodes from "@/components/Downloads/Keycodes.vue";
import { copyCurrentURL } from "@/common/copyCurrentURL";

const productCode = "WRMS";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
});

logEvent("created", componentName, props);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
