<template>
  <div class="mb-double m-auto constrain-65">
    <h1 class="mt">{{ t("OrderConfirmation.h1OrderConfirmation") }}</h1>
    <p>
      {{ t("NextSteps.nextStepsWinDownloadStartAuto") }}
      <DownloadSafe btn-class="inline-btn-link" btn-text="NextSteps.nextStepsWinDownloadStartAutoBtn" />
    </p>
    <CarboniteNextStepsWindows v-if="isWindows()" />
    <CarboniteNextStepsMac v-if="isMac()" />
    <h1>{{ tn("h1SetUpMyAccount") }}</h1>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p v-html="tn('setUpMyAccountLine1')"></p>
    <p>{{ tn("setUpMyAccountLine2") }}</p>
    <div>
      <button class="btn-primary" type="button" @click="continueToMyAccount()">
        {{ tn("btnFinishMyAccountSetup") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { isMac, isWindows } from "@/common/browserDetect";
import CarboniteNextStepsMac from "@/components/Buy/OrderConfirmation/CarboniteNextStepsMac.vue";
import CarboniteNextStepsWindows from "@/components/Buy/OrderConfirmation/CarboniteNextStepsWindows.vue";
import { continueToMyAccount } from "@/components/Buy/BuyHelper";
import DownloadSafe from "@/components/Backup/DownloadSafe.vue";
import { logEvent } from "@/common/logger";

const componentName = "CarboniteInstallationInstructions";
const baseComponentName = "OrderConfirmation";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}
</script>
