import { useNotificationsStore } from "@/stores/notifications";
import { useRoute } from "vue-router";

export function displayPasswordResetSuccess() {
  const route = useRoute();
  if (route.query.passwordReset) {
    const nStore = useNotificationsStore();
    nStore.addNotification({ type: "PasswordResetNoLogin" });
  }
}
