<template>
  <article class="content">
    <header>
      <h1>
        <button id="btnReturnToDownload" class="inline-btn-link" @click="returnToBackup()">
          <svg-icon-component icon="chevron-left" />
        </button>
        {{ tn("headerStepStart") }}
        <span class="block indent-32">{{ device?.description || device?.name }}</span>
      </h1>
    </header>
    <section v-if="showInitialState">
      <!-- v-if="step === 'showInitialState'" -->
      <h4>{{ tn("lostFiles") }}</h4>
      <p>{{ tn("whichFiles") }}</p>
      <dl class="bold-dt">
        <dt>{{ tn("allFiles") }}</dt>
        <dd class="mb-double">
          <p>{{ tn("allFilesCdlp") }}</p>
          <button id="CompleteRestoreNext" type="button" class="btn-primary" @click="goToSecondState()">
            {{ tn("completeRestore") }}
          </button>
        </dd>
        <dt>{{ tn("onlyAFewCdlp") }}</dt>
        <dd class="mb-double">
          <p>{{ tn("onlyAFewCdlpText") }}</p>
          <button id="DownloadNext2" type="button" class="btn-primary" @click="goToRemoteAccess()">
            {{ tn("remoteAccess") }}
          </button>
        </dd>
      </dl>
    </section>
    <section v-if="showSecondState">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mb-space-and-a-half" v-html="tn('note')"></p>
      <p>
        {{ tn("isCdlpInstalled") }}
        <button type="button" class="btn-link block left-link link-color" @click="goToWebrootSupportPage()">
          {{ tn("howToTell") }}
          <svg-icon-component icon="external-link" class="icon-xs" />
        </button>
      </p>

      <ul class="form tight">
        <li class="radio">
          <!-- v-model="cdlpInstalled" -->
          <input id="yes" type="radio" name="cdlpInstalled" value="yes" />
          <label for="yes">{{ tn("yes") }}</label>
        </li>
        <li class="radio">
          <!-- TBC: what happens if the user clicks 'No' on the Complete restore page -->
          <!-- v-model="cdlpInstalled" -->
          <input id="no" type="radio" name="cdlpInstalled" value="no" />
          <label for="no">{{ tn("no") }}</label>
        </li>
      </ul>
      <div>
        <button id="CompleteRestoreNext" type="button" class="btn-primary" @click="goToThirdState()">
          {{ tn("completeRestore") }}
        </button>
      </div>
    </section>
    <section v-if="showThirdState">
      <div v-if="isWindowsDevice && isMac()" class="border-around-danger">
        <svg-icon-component icon="circle-exclamation" class="icon-sm mr-quarter" />
        {{ tn("macWithWindowsBackup") }}
      </div>
      <div v-if="isMacDevice && isWindows()" class="border-around-danger">
        <svg-icon-component icon="circle-exclamation" class="icon-sm mr-quarter" />
        {{ tn("windowsWithNacBackup") }}
      </div>
      <p class="mb-space-and-a-half">{{ tn("considerTheseOptions") }}</p>
      <dl class="bold-dt">
        <dt>{{ tn("onlyAFewCdlp") }}</dt>
        <dd class="mb-space-and-a-half">
          <p>{{ tn("onlyAFewCdlpText") }}</p>
          <button id="DownloadNext2" type="button" class="btn-primary" @click="goToRemoteAccess()">
            {{ tn("remoteAccess") }}
          </button>
        </dd>
        <dt class="capitalize">{{ t("Common.contactSupport") }}</dt>
        <dd class="mb-space-and-a-half">
          <p>{{ tn("contactSupportTeam") }}</p>
          <button type="button" class="btn-outline-primary capitalize" @click="goToSupportPage()">
            {{ t("Common.contactSupport") }}
          </button>
        </dd>
      </dl>
    </section>
  </article>
</template>
<script setup lang="ts">
import { getUrl } from "@/common/getUrl";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { t } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { logEvent } from "@/common/logger";
import { getFileAccessRoute } from "@/components/FileAccess/navigationFn";
import { useUserStore } from "@/stores/user";
import { isMac, isWindows } from "@/common/browserDetect";
import { WINDOWS, MAC } from "@/define";

const componentName = "GetFilesCdlp";
const baseComponentName = "GetFilesWizardComponent";
const carboniteInstalled = ref<string>("");
const showInitialState = ref(true);
const showSecondState = ref(false);
const showThirdState = ref(false);
const router = useRouter();
const route = useRoute();

const userStore = useUserStore();
const deviceId = parseInt(route.params.deviceId as string);
const device = ref(userStore?.userDetails?.devices.find(d => d.deviceId === deviceId));

const isWindowsDevice = computed(() => {
  return device.value?.platform === WINDOWS;
});
const isMacDevice = computed(() => {
  return device.value?.platform === MAC;
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

function returnToBackup() {
  router.push("/backup");
}

function goToRemoteAccess() {
  if (!deviceId) return;
  logEvent("new viewFiles", componentName, deviceId);
  router.push(getFileAccessRoute(deviceId));
}

function goToSecondState() {
  showInitialState.value = false;
  showSecondState.value = true;
  if (carboniteInstalled.value === "yes") {
    // open restoring all files link in new tab
    /* eslint-disable */
    //@ts-ignore
    window.open(getUrl("VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL"), "_blank").focus();
    /* eslint-enable */
  }
  if (carboniteInstalled.value === "no") {
    // step.value = "downloadInstructions";
  }
}
function goToThirdState() {
  showSecondState.value = false;
  showThirdState.value = true;
  if (carboniteInstalled.value === "yes") {
    // open restoring all files link in new tab
    /* eslint-disable */
    //@ts-ignore
    window.open(getUrl("VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL"), "_blank").focus();
    /* eslint-enable */
  }
  if (carboniteInstalled.value === "no") {
    // step.value = "downloadInstructions";
  }
}
function goToWebrootSupportPage() {
  window.open(getUrl("WEBROOT_SUPPORT_HOME"), "_blank");
}
function goToSupportPage() {
  window.open(getUrl("CONTACT_SUPPORT_URL"), "_blank");
}
</script>
