<template>
  <ul>
    <li>{{ t(productDisplayForInvoice(props.invoice, props.localizedStringComponentName)) }}</li>
    <li v-if="isSafePlusOrPrime(props.invoice)">{{ t("Brand.webrootAntivirus") }}</li>
    <li v-if="isEvergreenMonthly(props.invoice.subscriptionPaymentItems)">
      {{ tn("quantity") }} {{ getQuantity(props.invoice) }} ({{ tn("activeUntilCancelled") }})
    </li>
    <li v-else-if="getYears(props.invoice.subscriptionPaymentItems) > 0">
      {{ tn("quantity") }}
      {{ getQuantity(props.invoice) }} ({{ getYears(props.invoice.subscriptionPaymentItems) }}
      {{ t(`Common.year${getYears(props.invoice.subscriptionPaymentItems) === 1 ? "" : "s"}`) }})
    </li>
    <li v-else>
      <span v-if="getQuantity(props.invoice) > 0">{{ tn("quantity") }} {{ getQuantity(props.invoice) }}</span>
      {{ getDescription(props.invoice.subscriptionPaymentItems) }}
    </li>
    <li v-if="props.invoice.computerName || props.invoice.computerDescription">
      {{ props.invoice.computerName || props.invoice.computerDescription }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { DEFAULT_QUANTITY, MONTHS_IN_YEAR } from "@/define";
import { productDisplayForInvoice, isSafePlusOrPrime } from "@/components/payment/PaymentHistoryPageHelper";
import {
  IUserPaymentHistoryPaymentInfo,
  IUserPaymentHistoryPaymentInfoItems,
} from "@/common/api/unifiedPortal/interfaces";

const props = defineProps({
  invoice: {
    type: Object as PropType<IUserPaymentHistoryPaymentInfo>,
    required: true,
  },
  localizedStringComponentName: {
    type: String,
    default: "PaymentHistoryComponent",
  },
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${props.localizedStringComponentName}.${v}`, params);
}

///Will need to updated once we are returning Ecomm invoices, but will work for Safe
function isEvergreenMonthly(subscriptionPaymentItems: IUserPaymentHistoryPaymentInfoItems[]) {
  return subscriptionPaymentItems[0].isEvergreenMonthly;
}

function getQuantity(invoice: IUserPaymentHistoryPaymentInfo) {
  let quantity = 0;
  //For invoices with upgrades the old item is returned in the subscriptionPaymentItems with 0 for the subscriptionMonths
  //We don't want to count those items towards the QTY

  let items = invoice.productNameSort.toLowerCase().includes("lite")
    ? (invoice.subscriptionPaymentItems.filter(l => l.subscriptionMonths) ?? [])
    : (invoice.subscriptionPaymentItems.filter(l => l.subscriptionMonths > 0 && l.itemAmount > 0) ?? []);

  if (invoice.invoiceId.toLowerCase().startsWith("carb")) {
    items = invoice.subscriptionPaymentItems;
  }

  for (const item of items) {
    quantity += item.quantity;
  }

  return quantity > 0 ? quantity : DEFAULT_QUANTITY;
}

///An evergreen sub shouldn't ever get to this
//If there are multiple items they will all have the same amount of time
//since safe purchases only allow 1 product type per cart and you buy the same
//years and sku for all of them
function getYears(subscriptionPaymentItems: IUserPaymentHistoryPaymentInfoItems[]) {
  let years = 1;
  years = subscriptionPaymentItems[0].subscriptionMonths / MONTHS_IN_YEAR;

  return Math.trunc(years);
}

function getDescription(subscriptionPaymentItems: IUserPaymentHistoryPaymentInfoItems[]) {
  return subscriptionPaymentItems.length > 0 && subscriptionPaymentItems[0].description
    ? subscriptionPaymentItems[0].description
    : "";
}
</script>
