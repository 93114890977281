<template>
  <button :id="props.btnId" type="button" :class="props.btnClass" @click="go()">
    {{ t(props.btnText) }}
  </button>
</template>

<script setup lang="ts">
import { t } from "@/i18n";
import { changePageState } from "@/components/Buy/BuyHelper";
import { pageStates } from "@/components/Buy/BuyEnums";
import { PropType } from "vue";

const props = defineProps({
  btnDestination: {
    type: String as PropType<pageStates>,
    default: pageStates.email,
  },
  btnId: {
    type: String,
    default: "btnChangeState",
  },
  btnClass: {
    type: String,
    default: "btn-primary",
  },
  btnText: {
    type: String,
    default: "Common.submit",
  },
});
function go() {
  changePageState(props.btnDestination);
}
</script>
