<template>
  <div v-show="!isReadyToShow">
    <LoadingSpinner />
  </div>
  <section v-show="isReadyToShow">
    <PrimaryEmailVerificationWrapperComponent
      notification-context="HomeComponent"
      :email="email"
      :show-dialog="toggleVerifyEmail"
      @close-dialog="closeVerifyDialog()"
    />
    <article class="tile-wrapper">
      <BackupOverviewComponent
        v-if="canDisplayGlobalFeature(globalFeatures.backupTile)"
        :key="`backupKey-${reloadKey}`"
        :subscription-status-type="backupStatus"
        @tile-ready="updateTilesReadyCount()"
        @tile-count="updateVisibleTileCount()"
      />
      <SecurityOverviewComponent
        v-if="canDisplayGlobalFeature(globalFeatures.securityTile)"
        :key="`securityKey-${reloadKey}`"
        :subscription-status-type="securityStatus"
        :needs-to-validate-email="needsToValidateEmail"
        :user-email="email"
        @tile-ready="updateTilesReadyCount()"
        @tile-count="updateVisibleTileCount()"
        @show-verify-email-dialog="toggleVerifyEmailDialog()"
      />
      <AllStateOverviewComponent
        v-if="allStateEnabled && canDisplayGlobalFeature(globalFeatures.allStateTile)"
        :key="`allstateKey-${reloadKey}`"
        @tile-ready="updateTilesReadyCount()"
        @tile-count="updateVisibleTileCount()"
      />
      <SubscriptionOverviewComponent
        v-if="!isEmptySubscriptions && canDisplayGlobalFeature(globalFeatures.subscriptionsTile)"
        :key="`subscriptionKey-${reloadKey}`"
        @subscription-status="updateSubscriptionStatus"
        @tile-ready="updateTilesReadyCount()"
        @tile-count="updateVisibleTileCount()"
        @is-empty-subs="setIsEmptySubscriptions"
      />
    </article>
  </section>
  <ManageResellerControlRequestComponent
    :show-dialog="displayManageResellerControlRequest"
    :request-data="pendingManageRequest"
    @close-modal="toggleManageResellerControlRequest"
    @save-modal="saveManageResellerControlRequest"
  />
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, onMounted, watch } from "vue";
import { logEvent } from "@/common/logger";
import BackupOverviewComponent from "@/components/Home/BackupOverview.vue";
import SecurityOverviewComponent from "@/components/Home/SecurityOverview.vue";
import SubscriptionOverviewComponent from "@/components/Home/SubscriptionOverview.vue";
import PrimaryEmailVerificationWrapperComponent from "@/components/shared/PrimaryEmailVerificationWrapper.vue";
import AllStateOverviewComponent from "@/components/Home/AllStateOverviewComponent.vue";
import { StatusType } from "@/globalEnums";
import { useUserStore } from "@/stores/user";
import { hasBestBuyS2, hasOnlyBestBuyS2 } from "@/common/bestBuy";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { useNotificationsStore } from "@/stores/notifications";
import { useSecurityStore } from "@/stores/security";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import ManageResellerControlRequestComponent from "@/components/shared/dialogs/ManageResellerControlRequest/ManageResellerControlRequest.vue";
import { unifiedApi } from "@/common";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import {
  IGetPendingRequestToManageResponse,
  ISetLanguagePreferenceRequest,
} from "@/common/api/unifiedPortal/interfaces";
import { getUserDetailsFromStore } from "@/common/userDetails";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";
import { FLAGS } from "@/define";
import { hasAllstateSubscriptions, showAllstateBanner } from "@/components/Allstate/AllstateHelper";

const name = "HomeComponent";

logEvent("created", name);
const backupStatus = ref(StatusType.normal);
const userStore = useUserStore();
const email = ref<string>("");
const securityStatus = ref(StatusType.normal);
const allStateEnabled = ref<boolean>(false);
const visibleTilesCount = ref(0);
const tilesReadyCount = ref<number>(0);
const toggleVerifyEmail = ref<boolean>(false);
const notificationsStore = useNotificationsStore();
const isEmptySubscriptions = ref<boolean>(false);
const reloadKey = ref<number>(0);
const displayManageResellerControlRequest = ref<boolean>(false);
const pendingManageRequest = ref<IGetPendingRequestToManageResponse>();

onBeforeMount(async () => {
  const bbyS2Only = await hasOnlyBestBuyS2();
  const hasBbyS2Sub = await hasBestBuyS2();

  if (FLAGS.ENABLE_ALLSTATE_BBY === "true" && hasBbyS2Sub) {
    allStateEnabled.value = await hasAllstateSubscriptions();
  } else {
    allStateEnabled.value = !bbyS2Only;
  }
  await openManageResellerControlRequest();
});

const user = computed(() => {
  return userStore.currentUser;
});

const userDetails = computed(() => {
  return userStore.userDetails;
});

const needsToValidateEmail = computed(
  () => user.value?.subscriberInfo?.hasPendingWebrootLicenses && !user.value?.subscriberInfo?.hasValidatedEmail
);

onMounted(async () => {
  logEvent("mounted", name, user.value);
  email.value = user.value?.email ?? "";
  notificationsStore.clearNotifications();
  // allstate upgrade banner displays if More than 90 days from sub expiring and api returns showUpgradedBanner and flag is set to show allstate family
  showAllstateBanner();
  if (needsToValidateEmail.value) {
    notificationsStore.addNotification({ type: "PrimaryEmailVerificationWebroot" });
  } else {
    notificationsStore.deleteNotificationByType("PrimaryEmailVerificationWebroot");
  }
  // Checking the languageCode because just after Account setup
  // Check should happen based on latest value
  await getUserDetailsFromStore(true);
  if (!userDetails.value?.languageCode) {
    try {
      //Setting the default email communication language as english
      const request: ISetLanguagePreferenceRequest = {
        languageCode: localStorage.getItem("selectedLanguage") || "en",
      };
      await unifiedApi.setLanguagePreference(request);
      await getUserDetailsFromStore(true);
    } catch (error) {
      handleApiError(error as AxiosError, true);
    }
  }

  if (userStore.twoFactorAuthAddedFromFirstLogin) {
    notificationsStore.addNotification({ type: "SetTwoStepVerificationSuccess" });
    userStore.twoFactorAuthAddedFromFirstLogin = false;
  }
});

function updateSubscriptionStatus(type: string, status: StatusType) {
  if (type === "backup") {
    backupStatus.value = status;
  } else if (type === "security") {
    securityStatus.value = status;
  }
}
function toggleVerifyEmailDialog() {
  toggleVerifyEmail.value = !toggleVerifyEmail.value;
}
async function closeVerifyDialog() {
  toggleVerifyEmail.value = false;
  await refreshHome();
}

async function refreshHome() {
  isEmptySubscriptions.value = false;
  tilesReadyCount.value = 0;
  await Promise.all([
    useSubscriptionsStore().forceRefreshAndPopulateSubscriptions(),
    useSecurityStore().forceRefresh(),
    useAllstateSubscriptionsStore().forceRefreshAndGetAllstateSubscriptions(),
    getUserDetailsFromStore(true),
    reloadSubscriberInfo(),
  ]);
  reloadKey.value++;
}

const isReadyToShow = computed(() => tilesReadyCount.value >= visibleTilesCount.value);

function updateTilesReadyCount() {
  tilesReadyCount.value++;
}

function updateVisibleTileCount() {
  visibleTilesCount.value++;
}

function setIsEmptySubscriptions(isEmpty: boolean) {
  isEmptySubscriptions.value = isEmpty;
}

async function openManageResellerControlRequest() {
  try {
    if (userStore.displayedManageResellerControlRequest) return;
    userStore.displayedManageResellerControlRequest = true;
    pendingManageRequest.value = (await unifiedApi.getPendingRequestToManageAsync())?.data;
    if (pendingManageRequest.value && pendingManageRequest.value.resellerId > 0) toggleManageResellerControlRequest();
  } catch (err) {
    handleApiError(err as AxiosError);
  }
}

function toggleManageResellerControlRequest() {
  displayManageResellerControlRequest.value = !displayManageResellerControlRequest.value;
}

async function saveManageResellerControlRequest() {
  toggleManageResellerControlRequest();
  await refreshHome();
}

const language = computed(() => userStore.selectedLanguage);
watch(language, async () => {
  await refreshHome();
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
