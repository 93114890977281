<template>
  <div class="verify-2fa">
    <div v-if="!showSendAgainMessage">
      <i18n-t keypath="Verify2FA.bodyP1" tag="p" scope="global">
        <template #phone>{{ phoneNumber }}</template>
      </i18n-t>
      <p>{{ tn("bodyP2") }}</p>
    </div>
    <p v-else>
      <button id="btnSendAgain" class="btn-link" @click="sendAgain()">{{ tn("informationP3c") }}</button>
    </p>

    <ul class="form">
      <li>
        <label for="securityCode">{{ tn("labelSecurityCode") }}</label>
        <input id="securityCode" v-model="code" type="text" />
      </li>
    </ul>
    <div v-if="!showSendAgainMessage">
      <p>
        {{ tn("informationP3a") }}
        <button id="linkSendAgain" class="inline-btn-link" @click="sendAgain()">{{ tn("informationP3b") }}</button>
      </p>
    </div>
    <div class="text-right">
      <button id="btnStep3Skip2FA" type="button" class="btn-outline-primary mr" @click="accountSetupFinish()">
        {{ tn("cancel") }}
      </button>

      <button id="btnSaveChanges" type="submit" class="btn-primary" :disabled="nextDisabled" @click="submitCode()">
        {{ tn("next") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, PropType, ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IUser } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import { useNotificationsStore, NotificationType } from "@/stores/notifications";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { TWO_FACTOR_CODE_LENGTH } from "@/define";

const componentName = "Verify2FA";

const props = defineProps({
  phoneNumber: {
    type: String,
    default: null,
    required: false,
  },
  currentUser: {
    type: Object as PropType<IUser>,
    default: null,
  },
  showEmail: {
    type: Boolean,
    default: true,
  },
  verificationMethod: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["finish", "submit-code"]);

const notificationsStore = useNotificationsStore();
const email = ref<string>("");
const code = ref<string>("");
const showSendAgainMessage = ref<boolean>(false);

const expirationTime = ref(0);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

const nextDisabled = computed(() => {
  return code.value.length !== TWO_FACTOR_CODE_LENGTH;
});

onMounted(async () => {
  logEvent("created", "Verify2FA");
  try {
    if (props.currentUser) {
      email.value = props.currentUser.email;
      const verificationMethod = props.verificationMethod !== null ? props.verificationMethod : "Sms";
      logEvent(verificationMethod);
      const response = (await unifiedApi.requestVerificationCode(props.currentUser.personId, verificationMethod)).data;
      expirationTime.value = Date.parse(response.expirationTime);
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
});

async function accountSetupFinish() {
  emits("finish");
}

async function sendAgain() {
  notificationsStore.clearNotifications();
  showSendAgainMessage.value = false;
  try {
    if (props.currentUser) {
      const verificationMethod = props.verificationMethod !== null ? props.verificationMethod : "Sms";
      const response = (await unifiedApi.requestVerificationCode(props.currentUser.personId, verificationMethod)).data;
      expirationTime.value = Date.parse(response.expirationTime);
      notificationsStore.addNotification({ type: "TwoStepVerificationCodeSent" });
    }
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}

async function submitCode() {
  notificationsStore.clearNotifications();
  try {
    if (!props.currentUser || code.value.length !== TWO_FACTOR_CODE_LENGTH) {
      throw "CodeInvalid";
    }
    const securityPhoneValidation = (await unifiedApi.sendVerificationCode(props.currentUser.personId, code.value))
      .data;
    if (!securityPhoneValidation.isValid) {
      let notificationMessage: NotificationType = "TwoStepVerificationFailed";
      switch (securityPhoneValidation.securityPhoneOperationCode) {
        case "VerificationWindowExpired":
          showSendAgainMessage.value = true;
          notificationMessage = "SetTwoStepVerificationVerificationWindowExpired";
          break;
        case "TooManyVerificationAttempts":
          notificationMessage = "SetTwoStepVerificationTooManyVerificationAttempts";
          break;
        case "HighRiskScore":
          notificationMessage = "SetTwoStepVerificationHighRiskScore";
          break;
      }
      notificationsStore.addNotification({ type: notificationMessage });
    } else {
      emits("submit-code", "OK");
    }
  } catch (error) {
    handleApiError(error as AxiosError, true, "TwoStepVerificationFailed");
  }
}
</script>
