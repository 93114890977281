<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.WWTSUnsupported`) }}
    </li>
    <li>
      {{ t(`${parentComponentName}.WWTSUnsupportedReference`) }}
      <Keycodes :keycodes="keycodes" :product-code="productCode" />
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <p>{{ t(`${parentComponentName}.WWTSStepOne`) }}</p>
      <SelectorComponent :product-code="productCode" />
    </li>
    <li>
      <p>{{ t(`${parentComponentName}.WWTSStepTwo`) }}</p>
      <Keycodes :keycodes="keycodes" :product-code="productCode" />
    </li>
  </ol>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";
import Keycodes from "@/components/Downloads/Keycodes.vue";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";

const productCode = "WWTS";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
});

logEvent("created", componentName, props);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
