//***********
//Please update the following Confluence page if any changes are made here:
//https://confluence.opentext.com/display/IBA/Textbox+Input+Validation+Reference+Sheet
//***********

//AllState specific Regex are self-contained within AllStateFormValidation.ts

export const isEmailWithTLDRegex =
  /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/;

export const emailCharactersRegex = /[^`~0-9a-zA-Z+@$&_.\-]/g;

export const passwordRegex = /[^`~0-9a-zA-Z'*+!@#$%^&_=.{|}/\-]/g;

/// The computer description which needs to update for Activation Code or computer id
/// Characters allowed are 0-9,a-z,A-Z,',(,),*,+,\s(white space),
/// !,@,#,$,^,&,_,=,(comma),.,","",-(hyphen),%
export const computerNameRegex = /[<>?\\~`/\[\]{}\|;:]/g;

export const webRootDeviceNickNameRegex =
  /[^0-9a-zA-Z'-+\s!@#$^%&_=,.""\-\u3040-\u309F\u30A0-\u30FF\uFF60-\uFFEF\u4E00-\u9FBF]/g;

//username does not allow %
//also used for security answer create/update
export const nameAndSecurityAnswerRegex =
  /[^0-9a-zA-Z'-+\s!@#$^&_=,.""\-\u3040-\u309F\u30A0-\u30FF\uFF60-\uFFEF\u4E00-\u9FBF]/g;

export const alphaNumericWithHyphenRegex = /^[a-zA-Z0-9-]*$/;

export const alphaNumericRegex = /^[0-9a-zA-Z]+$/;

export const validGuidFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
