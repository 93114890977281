<template>
  <TileWrapperComponent
    :key="td.status"
    :is-loading="isLoading"
    :tile-data="td"
    notification-context="SubscriptionOverviewComponent"
  >
    <template #title>{{ t(`${textRoot}${td.title}`) }}</template>
    <template #content>
      <TileDetailsComponent v-if="haveSubscriptions" :path="td.detailsLink">
        <template #content>
          <ul>
            <li v-for="(item, i) in td.items" :key="i" :class="calcItemClass(item)">
              <span v-if="!!item.caption">{{ item.caption }}</span>
              <span v-if="td.type === 'list'" :class="calcAmountClass(convertAmount(item))">
                <span v-if="item.value === SubscriptionState.activateNow" @click="goToCreateAccountForm()">
                  <a>
                    {{ convertAmount(item) }}
                  </a>
                </span>
                <span v-else>
                  {{ convertAmount(item) }}
                </span>
              </span>
            </li>
          </ul>
        </template>
      </TileDetailsComponent>
      <div v-else>
        <p>{{ t(`${textRoot}noSubscriptions`) }}</p>
        <button id="viewSubDetails" class="inline-btn-link" type="button" @click="viewDetails()">
          {{ t(`Common.viewDetails`) }}
        </button>
      </div>
    </template>
  </TileWrapperComponent>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { ISubscription } from "@/common/api/unifiedPortal/interfaces";
import {
  KeyCodeSeller,
  TileType,
  TileTitle,
  UnitType,
  StatusType,
  StateType,
  SubscriptionState,
  ZuoraSubscriptionStatus,
} from "@/globalEnums";
import { ITileData, i18nRoot, calcItemClass, convertAmount, ITileItemData } from "@/components/Home";
import { getProductDisplayNameBySku, IProductDisplayRequest } from "@/common/productsBySku";
import TileDetailsComponent from "@/components/shared/TileDetails.vue";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import TileWrapperComponent from "@/components/shared/TileWrapper.vue";
import { SUBSCRIPTION_MIN_ACTIVE_DAY, SUBSCRIPTION_MIN_DATA_LOSS_DAY, SUBSCRIPTION_MIN_EXPIRED_DAY } from "@/define";
import { isNotActiveYet, isAllstateSubscription } from "@/common/subscriptionHelper";
import { useUserStore } from "@/stores/user";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";

const name = "SubscriptionOverviewComponent";

const emits = defineEmits(["subscriptions-action", "subscription-status", "tile-ready", "tile-count", "is-empty-subs"]);
logEvent("created", name);
const textRoot = ref<string>(i18nRoot);
const subscriptions = ref<ISubscription[]>([]);
const haveSubscriptions = ref(true);
const router = useRouter();
const subscriptionsStore = useSubscriptionsStore();
const userStore = useUserStore();
const allstateSubscriptionsStore = useAllstateSubscriptionsStore();

const isLoading = ref(true);
const td = ref<ITileData>({
  title: TileTitle.subscriptionOverview,
  type: TileType.list,
  status: StatusType.normal,
  state: StateType.installed,
  detailsLink: "/subscriptions",
});

onMounted(async () => {
  try {
    emits("tile-count");
    await getSubscriptionDetails();
    isLoading.value = false;
    emits("tile-ready");
    emits("is-empty-subs", subscriptions.value.length === 0);
  } catch (ex) {
    logException(ex as Error);
  }
});

async function getSubscriptionDetails() {
  try {
    haveSubscriptions.value = true;
    const subscriptionsTileItems = ref<ITileItemData[]>([]);
    await subscriptionsStore.populateSubscriptions();
    subscriptions.value = subscriptionsStore.subscriptions;
    subscriptions.value = subscriptions.value.filter(
      s => s.zuoraSubscriptionStatus !== ZuoraSubscriptionStatus.Cancelled
    );

    if (userStore.selectedLanguage !== "en") {
      subscriptions.value = subscriptions.value.filter(s => !isAllstateSubscription(s));
    }

    if (
      subscriptions.value === null ||
      subscriptions.value.every(sub => sub.remainingDays < SUBSCRIPTION_MIN_DATA_LOSS_DAY && !isNotActiveYet(sub))
    ) {
      haveSubscriptions.value = false;
      return;
    }

    for (const sub of subscriptions.value) {
      if (sub.remainingDays < SUBSCRIPTION_MIN_DATA_LOSS_DAY && !isNotActiveYet(sub)) {
        continue;
      }
      //Adding a display for the sku to the object for sorting
      const request: IProductDisplayRequest = {
        sku: sub.sku,
        webrootKeycode: sub.keyCode,
        isTrial: sub.isTrial,
      };
      sub.productDisplayName = t(getProductDisplayNameBySku(request)).toString();
      if (!sub.productDisplayName) {
        continue;
      }

      const tileItem = ref<ITileItemData>({
        caption: sub.productDisplayName,
        value: SubscriptionState.active,
        unitType: UnitType.subscriptionState,
      });
      if (isNotActiveYet(sub)) {
        tileItem.value.value = SubscriptionState.inactive;
      } else if (sub.isEvergreenMonthly) {
        tileItem.value.value = SubscriptionState.active;

        if (sub.isCancelledEvergreenSubscription) {
          tileItem.value.value = SubscriptionState.cancelled;
        }
      } else if (sub.outstandingInvoice) {
        tileItem.value.value = SubscriptionState.expired;
      } else if (sub.remainingDays > SUBSCRIPTION_MIN_ACTIVE_DAY) {
        tileItem.value.value = !isAllstateSubscription(sub) ? SubscriptionState.active : await isAllstateSet();
      } else if (sub.remainingDays <= SUBSCRIPTION_MIN_EXPIRED_DAY) {
        tileItem.value.value = SubscriptionState.expired;
      } else if (
        sub.remainingDays <= SUBSCRIPTION_MIN_ACTIVE_DAY &&
        sub.remainingDays > SUBSCRIPTION_MIN_EXPIRED_DAY &&
        sub.keyCodeSellerType !== KeyCodeSeller.BestBuy &&
        !sub.isAutoRenewEnabled
      ) {
        tileItem.value.value = SubscriptionState.expiring;
      }
      subscriptionsTileItems.value.push(tileItem.value);
    }

    const subscriptionStatus = ref<string>(StatusType.normal);
    const hasBackupExpiredItems = subscriptionsTileItems.value.some(
      sub => sub.value === SubscriptionState.expired && sub.caption.toLowerCase().includes("carbonite")
    );
    const hasBackupExpiringItems = subscriptionsTileItems.value.some(
      sub => sub.value === SubscriptionState.expiring && sub.caption.toLowerCase().includes("carbonite")
    );
    const hasSecurityExpiredItems = subscriptionsTileItems.value.some(
      sub =>
        sub.value === SubscriptionState.expired &&
        (sub.caption.toLowerCase().includes("webroot") || sub.caption.toLowerCase().includes("identity"))
    );
    const hasSecurityExpiringItems = subscriptionsTileItems.value.some(
      sub =>
        sub.value === SubscriptionState.expiring &&
        (sub.caption.toLowerCase().includes("webroot") || sub.caption.toLowerCase().includes("identity"))
    );

    emits(
      "subscription-status",
      "backup",
      hasBackupExpiredItems ? StatusType.danger : hasBackupExpiringItems ? StatusType.warning : StatusType.normal
    );

    subscriptionStatus.value =
      hasBackupExpiredItems || hasSecurityExpiredItems
        ? StatusType.danger
        : hasBackupExpiringItems || hasSecurityExpiringItems
          ? StatusType.warning
          : StatusType.normal;

    // Sort the exhibition on the subscriptions tile
    subscriptionsTileItems.value.sort((a, b) => a.value - b.value);

    td.value = {
      title: "subscriptions",
      type: TileType.list,
      status: subscriptionStatus.value,
      items: subscriptionsTileItems.value,
      state: StateType.installed,
      detailsLink: "/subscriptions",
    };
  } catch {
    td.value = {
      title: TileTitle.subscriptionOverview,
      type: TileType.tile,
      status: StatusType.warning,
      state: StateType.serverError,
    };
  }
}

function viewDetails() {
  router.push({ path: "/subscriptions" });
}

function calcAmountClass(content: string) {
  return content === "Expired" ? "state" : "";
}

async function isAllstateSet() {
  const allstateSubscriptions = (await allstateSubscriptionsStore.getAllstateSubscriptions()).allstateSubscriptions;
  return allstateSubscriptions.length > 0 ? SubscriptionState.active : SubscriptionState.activateNow;
}

function goToCreateAccountForm() {
  router.push("allstateSignUp");
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
