<template>
  <div class="progress-circle no-shrink">
    <svg viewBox="0 0 32 32" class="circle">
      <circle r="16" cx="16" cy="16" :style="style" />
    </svg>
    <b v-if="percentage < 100">{{ percentage }}%</b>
    <i v-else><svg-icon-component icon="checkmark" class="icon-sm" /></i>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { logEvent } from "@/common/logger";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "ProgressCircleComponent";

const props = defineProps({
  percentage: {
    type: Number,
    required: true,
  },
});

logEvent("creating", componentName);

const style = computed(() => {
  return `fill: white; stroke: #005799; stroke-width: 32; stroke-dasharray: ${props.percentage} 100;`;
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
/* progress circle */
.progress-circle {
  position: relative;
}
.progress-circle b,
.progress-circle i {
  position: absolute;
  z-index: 2;
  top: 2px;
  left: 2px;
  height: 21px;
  width: 21px;
  padding: 3px 0 0 1px;
  background-color: white;
  font-size: var(--text-xs);
  border-radius: 50%;
}
.progress-circle i {
  z-index: 1;
}
.progress-circle .icon-sm {
  top: 1px;
  left: 2px;
}
@keyframes fillup {
  to {
    stroke-dasharray: 158 158;
  }
}

.circle {
  width: 25px;
  height: 25px;
  transform: rotate(-90deg);
  background: white;
  border-radius: 50%;
}
</style>
