<template>
  <ModalWrapperComponent
    dialog-name="SetUserPasswordComponent"
    :show-dialog="props.showDialog"
    @close-modal="close"
    @pre-open="beforeOpen"
  >
    <template #header>
      {{ tn("headerH1") }}
    </template>
    <template #content>
      <ul class="form">
        <li>
          <div class="label-wrapper">
            <label for="current-password">{{ tn("currentPassword") }}</label>
            <button
              v-if="hideCurrentPassword"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="hideCurrentPassword = !hideCurrentPassword"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              class="inline-btn-link"
              type="button"
              title="Hide password"
              @click="hideCurrentPassword = !hideCurrentPassword"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="showPasswordIncorrect">
            <input
              id="current-password"
              v-model.trim="currentPassword"
              :type="hideCurrentPassword ? 'password' : 'text'"
              autocomplete="off"
            />
            <template #error>
              <div>
                <span>
                  {{ tn("incorrectPassword") }}
                </span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
        <li>
          <div class="label-wrapper">
            <label for="new-password">{{ tn("newPassword") }}</label>
            <button
              v-if="hidePassword"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="hidePassword = !hidePassword"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="hidePassword = !hidePassword"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="passwordErrors && passwordErrors.length > 0">
            <input
              id="new-password"
              v-model.trim="newPassword"
              :type="hidePassword ? 'password' : 'text'"
              autocomplete="off"
              @keyup="dirtyFlagPassword = true"
            />
            <template #error>
              <div>
                <span v-for="e in passwordErrors" :key="e.name">{{ e.message }}</span>
              </div>
            </template>
          </InputErrorComponent>
          <p class="mt-quarter">{{ tn("passwordRequirements") }}</p>
          <PasswordRulesComponent :password="newPassword" :show-rules="false" />
        </li>
        <li>
          <div class="label-wrapper">
            <label for="confirm-new-password">{{ tn("confirmNewPassword") }}</label>
            <button
              v-if="hideConfirmPassword"
              type="button"
              class="inline-btn-link"
              title="Show password"
              @click="hideConfirmPassword = !hideConfirmPassword"
            >
              {{ t("Common.show") }}
            </button>
            <button
              v-else
              type="button"
              class="inline-btn-link"
              title="Hide password"
              @click="hideConfirmPassword = !hideConfirmPassword"
            >
              {{ t("Common.hide") }}
            </button>
          </div>
          <InputErrorComponent :show="showPasswordConfirmError">
            <input
              id="confirm-new-password"
              v-model.trim="newPasswordConfirm"
              :type="hideConfirmPassword ? 'password' : 'text'"
              autocomplete="off"
              @keyup="dirtyFlagConfirmPassword = true"
            />
            <template #error>
              <div>
                <span>{{ tn("passwordMatch") }}</span>
              </div>
            </template>
          </InputErrorComponent>
        </li>
      </ul>
    </template>
    <template #footer>
      <button id="btnCancelChanges" type="button" class="btn-link mr" @click="close()">
        {{ tn("footerButtonCancel") }}
      </button>
      <button
        id="btnSaveChanges"
        type="submit"
        class="btn-primary"
        :disabled="!isPasswordValid || isSaving"
        @click="save()"
      >
        <spinner :is-spinning="isSaving" />
        {{ tn("footerButtonSave") }}
      </button>
    </template>
  </ModalWrapperComponent>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { validatePassword } from "@/common/strongPassword";
import { passwordRegex } from "@/globalRegex";
import Spinner from "@/components/shared/Spinner.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import PasswordRulesComponent from "@/components/shared/PasswordRules.vue";
import ModalWrapperComponent from "@/components/shared/dialogs/ModalWrapper.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { reportStringErrors } from "@/common/validator";
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "@/define";

const componentName = "SetUserPasswordComponent";

const props = defineProps({
  showDialog: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName);

const isSaving = ref<boolean>(false);
const currentPassword = ref<string>("");
const newPassword = ref<string>("");
const newPasswordConfirm = ref<string>("");
const dirtyFlagPassword = ref<boolean>(false);
const dirtyFlagConfirmPassword = ref<boolean>(false);
const updatePassword = ref(false);
const showPasswordIncorrect = ref(false);
const hidePassword = ref(true);
const hideConfirmPassword = ref(true);
const hideCurrentPassword = ref(true);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function close() {
  logEvent("closed", componentName);
  resetFlags();
  emits("close-modal");
}

const isPasswordValid = computed(() => {
  return (
    currentPassword.value &&
    newPassword.value &&
    newPasswordConfirm.value &&
    !showPasswordConfirmError.value &&
    passwordErrors.value.length === 0
  );
});

const showPasswordConfirmError = computed(() => {
  return !!newPassword.value && !!dirtyFlagConfirmPassword.value && newPassword.value !== newPasswordConfirm.value;
});

const passwordInvalidCharacterError = computed(() => {
  return reportStringErrors(
    newPassword.value,
    tn("newPassword"),
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH,
    passwordRegex
  );
});

const passwordErrors = computed(() => {
  const result = validatePassword(newPassword.value);

  //Piggybacking reportStringErrors on top of validatePassword to return
  //error message that displays the invalid characters used.
  result.push({
    name: "invalid_characters",
    message: passwordInvalidCharacterError.value,
    displayOrder: 5,
    passed: passwordInvalidCharacterError.value.length === 0,
  });

  const isNotValidPassword = result.some(e => !e.passed) && dirtyFlagPassword.value === true;
  return isNotValidPassword ? [result.filter(e => !e.passed)[0]] : [];
});

function resetFlags() {
  dirtyFlagPassword.value = false;
  dirtyFlagConfirmPassword.value = false;
}

async function save() {
  isSaving.value = true;
  try {
    if (isPasswordValid.value) {
      //await for Validate and Update Password API, returns false if password sent is incorrect
      updatePassword.value = (
        await unifiedApi.validateAndUpdatePassword(currentPassword.value, newPassword.value)
      ).data;
      if (!updatePassword.value) {
        logEvent("user provided wrong current password", componentName);
        showPasswordIncorrect.value = true;
      } else {
        //to close the modal after saving the data we need to save modal
        emits("save-modal", "ok");
      }
    }
  } catch (error) {
    logEvent("SetPasswordFailure", `${componentName}`);
    emits("save-modal", "failure");
    handleApiError(error as AxiosError, true, "SetPasswordFailure");
  }
  resetFlags();
  isSaving.value = false;
}

function beforeOpen() {
  currentPassword.value = "";
  newPassword.value = "";
  newPasswordConfirm.value = "";
  showPasswordIncorrect.value = false;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
