<template>
  <div>
    <div class="input-btn-set mb-quarter">
      <div class="input-wrapper">
        <input
          id="inputSearchTermWebroot"
          v-model="kbSearchWebrootText"
          type="text"
          :placeholder="tn('knowledgebaseArticlesWebroot')"
        />
      </div>
      <div>
        <button id="btnSearchWebroot" type="button" class="btn-primary" @click="search('')">{{ tn("search") }}</button>
      </div>
    </div>
    <p class="mb">
      {{ tn("popularSearches") }}:
      <button
        id="btnKbReinstallWebroot"
        class="inline-btn-link"
        type="button"
        @click="search('reinstall Webroot Backup')"
      >
        {{ tn("reinstallCdlp") }}
      </button>
      ,&nbsp;
      <button id="btnKbRenewSubWebroot" class="inline-btn-link" type="button" @click="search('renew subscription')">
        {{ tn("renewSubscription") }}
      </button>
      ,&nbsp;
      <button id="btnKbRestoreFilesWebroot" class="inline-btn-link" type="button" @click="search('restore files')">
        {{ tn("restoreFiles") }}
      </button>
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const name = "KbSearchWebroot";
const baseComponentName = "KbSearchComponent";
logEvent("created", name);
const supportSite = "https://www.webroot.com/";
const kbSearchWebrootText = ref<string>("");

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}

onMounted(() => {
  kbSearchWebrootText.value = "";
});

function search(text) {
  if (!text) {
    text = kbSearchWebrootText.value;
  }
  logEvent("SearchWebrootText", name, text);
  window.open(supportSite + `/us/en/support/support-home?q=${text}`);
}
</script>
