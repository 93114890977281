<template>
  <div>
    <WindowsMoveSubscriptionComponent
      v-if="modalType === 'windowsTransfer'"
      :device="device"
      :is-cdlp="props.isCdlp"
      :show-dialog="showDialog"
      @cancel="close"
      @save="save"
    ></WindowsMoveSubscriptionComponent>
    <CrossPlatformTrialMoveSubscriptionComponent
      v-else-if="(modalType === 'windowsToMacTransfer' || modalType === 'macToWindowsTransfer') && isTrial"
      :device="device"
      :is-cdlp="props.isCdlp"
      :show-dialog="showDialog"
      @cancel="close"
    ></CrossPlatformTrialMoveSubscriptionComponent>
    <MacToWindowsMoveSubscriptionComponent
      v-else-if="(modalType === 'windowsToMacTransfer' || modalType === 'macToWindowsTransfer') && !isTrial"
      :device="device"
      :is-cdlp="props.isCdlp"
      :show-dialog="showDialog"
      @cancel="close"
    ></MacToWindowsMoveSubscriptionComponent>
    <MacToMacMoveSubscriptionComponent
      v-else-if="modalType === 'macTransfer'"
      :device="device"
      :is-cdlp="props.isCdlp"
      :show-dialog="showDialog"
      @cancel="close"
      @save="save"
    ></MacToMacMoveSubscriptionComponent>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { getOsFamily, isWindows } from "@/common/browserDetect";

import MacToMacMoveSubscriptionComponent from "@/components/Backup/MacToMacMoveSubscription.vue";
import MacToWindowsMoveSubscriptionComponent from "@/components/Backup/MacToWindowsMoveSubscription.vue";
import WindowsMoveSubscriptionComponent from "@/components/Backup/WindowsMoveSubscription.vue";
import CrossPlatformTrialMoveSubscriptionComponent from "@/components/Backup/CrossPlatformTrialMoveSubscription.vue";
import { logEvent } from "@/common/logger";

const componentName = "MoveMySubscriptionComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  showDialog: {
    type: Boolean,
    required: true,
  },
  isCdlp: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(["save-modal", "close-modal"]);

logEvent("created", componentName, props.device);

const modalType = ref<string>("");
const oldComputerOs = ref<string>("");
const newComputerOs = ref<string>("");
const isTrial = ref<boolean>(false);

showModalContent();

function save(installType: string) {
  emits("save-modal", installType);
}

function close() {
  emits("close-modal");
}

function showModalContent() {
  oldComputerOs.value = props.device.platform.toLowerCase();
  newComputerOs.value = getOsFamily().toLowerCase();
  isTrial.value = props.device.isTrial;
  modalType.value =
    oldComputerOs.value.includes(newComputerOs.value) || newComputerOs.value.includes(oldComputerOs.value)
      ? isWindows()
        ? "windowsTransfer"
        : "macTransfer"
      : isWindows()
        ? "macToWindowsTransfer"
        : "windowsToMacTransfer";
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
