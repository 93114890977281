import { detect } from "detect-browser";
import { logEvent } from "./logger";

let browserVoices;
const detectBrowser = detect();

//Detect can be NULL so for now just log an error to the console
if (detectBrowser == null) {
  logEvent("detect-browser is NULL", "browserDetect.ts");
}

export function getBrowser(): string {
  return detectBrowser ? detectBrowser.name : "";
}

export function getOS(): string {
  return !!detectBrowser && !!detectBrowser.os ? detectBrowser.os : "";
}

export function isChromium(): boolean {
  //chromium don't have google voices
  if (getBrowser().toLowerCase().includes("chrome") || getBrowser().toLowerCase().includes("crios")) {
    return browserVoices.some(v => /^google/i.test(v.name)) ? false : true;
  }
  return false;
}
export function isChrome(): boolean {
  return getBrowser().toLowerCase().includes("chrome") || getBrowser().toLowerCase().includes("crios");
}

export function isFirefox(): boolean {
  return getBrowser().toLowerCase().includes("firefox") || getBrowser().toLowerCase().includes("fxios");
}

export function isEdge(): boolean {
  return getBrowser().toLowerCase().includes("edge");
}

export function isSafari(): boolean {
  return getBrowser().toLowerCase().includes("safari") || getBrowser().toLowerCase().includes("ios");
}

export function isMobile(): boolean {
  return getOS().toLowerCase().includes("ios") || isAndroid();
}

export function isWindows(): boolean {
  return getOS().toLowerCase().includes("windows");
}

export function isMac(): boolean {
  return (
    getOS().toLowerCase().includes("mac") ||
    getOS().toLowerCase().includes("ios") ||
    getBrowser().toLowerCase().includes("ios")
  );
}

export function isChromeOs(): boolean {
  return getOS().toLowerCase().includes("chrome") || getBrowser().toLowerCase().includes("ios");
}

export function isLinux(): boolean {
  return getOS().toLowerCase().includes("linux") || getBrowser().toLowerCase().includes("ios");
}

export function isAndroid(): boolean {
  return getOS().toLowerCase().includes("android");
}

export function getOsFamily(): string {
  if (isMac() && isMobile()) {
    return "iOS";
  }

  if (isMac() && !isMobile()) {
    return "Mac";
  }

  if (isChromeOs()) {
    return "ChromeOS";
  }

  if (isLinux()) {
    return "Linux";
  }

  if (isAndroid()) {
    return "Android";
  }

  if (isWindows()) {
    return "Windows";
  }

  return "Not Supported";
}

export function getBrowserFamily(): string {
  if (isChromium()) {
    return "Chromium";
  }

  if (isChrome()) {
    return "Chrome";
  }

  if (isFirefox()) {
    return "Firefox";
  }

  if (isEdge()) {
    return "Edge";
  }

  if (isSafari()) {
    return "Safari";
  }

  return "Not Supported";
}

export function isOutDatedWindows(): boolean {
  const sha1WindowsList = [
    "Windows Mobile",
    "Windows 3.11",
    "Windows 95",
    "Windows 98",
    "Windows 2000",
    "Windows XP",
    "Windows ME",
    "Windows Server 2003",
    "Windows Vista",
    "Windows 7",
  ];
  return isWindows() && sha1WindowsList.some(c => getOS().includes(c));
}

const supportedBrowsers = [
  {
    os: "Windows",
    browser: "Chrome",
    minimumVersionNumber: 88,
  },
  {
    os: "Windows",
    browser: "Chromium",
    minimumVersionNumber: 88,
  },
  {
    os: "Windows",
    browser: "Edge",
    minimumVersionNumber: 87,
  },
  {
    os: "Windows",
    browser: "Firefox",
    minimumVersionNumber: 85,
  },
  {
    os: "Mac",
    browser: "Chrome",
    minimumVersionNumber: 87,
  },
  {
    os: "Mac",
    browser: "Chromium",
    minimumVersionNumber: 87,
  },
  {
    os: "Mac",
    browser: "Edge",
    minimumVersionNumber: 87,
  },
  {
    os: "Mac",
    browser: "Firefox",
    minimumVersionNumber: 85,
  },
  {
    os: "Mac",
    browser: "Safari",
    minimumVersionNumber: 14,
  },
  {
    os: "ChromeOS",
    browser: "Chrome",
    minimumVersionNumber: 87,
  },
  {
    os: "ChromeOS",
    browser: "Chromium",
    minimumVersionNumber: 85,
  },
  {
    os: "iOS",
    browser: "Chrome",
    minimumVersionNumber: 87,
  },
  {
    os: "iOS",
    browser: "Chromium",
    minimumVersionNumber: 6,
  },
  {
    os: "iOS",
    browser: "Edge",
    minimumVersionNumber: 87,
  },
  {
    os: "iOS",
    browser: "Firefox",
    minimumVersionNumber: 85,
  },
  {
    os: "iOS",
    browser: "Safari",
    minimumVersionNumber: 13,
  },
  {
    os: "Android",
    browser: "Chrome",
    minimumVersionNumber: 86,
  },
  {
    os: "Android",
    browser: "Chromium",
    minimumVersionNumber: 85,
  },
  {
    os: "Android",
    browser: "Firefox",
    minimumVersionNumber: 80,
  },
  {
    os: "Android",
    browser: "Edge",
    minimumVersionNumber: 86,
  },
];

export async function isSupportedBrowser() {
  logEvent(`getBrowser: ${getBrowser()}`, "browserDetect");
  browserVoices = await allVoicesObtained;
  const os = getOsFamily();
  logEvent(`getOsFamily: ${os}`, "browserDetect");
  if (os === "Not supported") return false;

  const browser = getBrowserFamily();
  logEvent(`getBrowserFamily: ${browser}`, "browserDetect");
  if (browser === "Not supported") return false;

  const version = detectBrowser?.version?.split(".");
  logEvent(`Browser Version: ${version}`, "browserDetect");
  const versionNumber = version !== undefined ? parseInt(version[0]) : 0;

  return supportedBrowsers.some(b => b.os === os && b.browser === browser && versionNumber >= b.minimumVersionNumber);
}

//since getBrowser() returns "Chrome" for both chrome and chromium Browser
//we require a specific property to distinguish these two browsers
//since Chromium does not have Google voices
//speechSynthesis is used to load voices and then check if it has "Google" as name in the list of voices or not!
const allVoicesObtained = new Promise(function (resolve) {
  let voices = window?.speechSynthesis?.getVoices();
  if (voices?.length !== 0) {
    resolve(voices);
  } else {
    window.speechSynthesis.addEventListener("voiceschanged", function () {
      //chrome requires callback to load voices
      voices = window?.speechSynthesis?.getVoices();
      resolve(voices);
    });
  }
});
