<template>
  <div id="bestBuySection" class="opposite-ends-desktop row-reverse-desktop pa-desktop mb-double-mobile">
    <div class="best-buy-logo mb">
      <svg-icon-component icon="logo-best-buy" />
    </div>
    <div>
      <p>
        <strong>{{ tn("line1") }}</strong>
      </p>
      <p>
        <strong class="mr-quarter">
          <a id="linkBestBuy" href="https://www.bestbuy.com/services/remotesupport" target="_blank">
            {{ tn("line2a") }}
          </a>
        </strong>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="t('BestBuySupportSectionComponent.line2b')"></span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";

const componentName = "BestBuySupportSectionComponent";

logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.best-buy-section {
  margin: var(--space) var(--space-half) var(--space-double);
}
.best-buy-section .best-buy-logo {
  text-align: left;
}

@media (min-width: 600px) {
  .best-buy-section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: white;
    border-radius: var(--border-radius);
    border: 1px solid var(--grey);
    padding: var(--space);
    margin: var(--space) var(--space-half);
  }
  .best-buy-section .best-buy-logo {
    text-align: left;
  }
}
@media (min-width: 960px) {
  .best-buy-section {
    margin-left: 0;
  }
}
</style>
