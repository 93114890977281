<template>
  <section class="mb">
    <h1>{{ tn("thanks") }}</h1>
    <p>{{ tn("thanksMessage") }}</p>
  </section>
</template>
<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";

const componentName = "WebrootThanks";
const baseComponentName = "OrderConfirmation";
logEvent("created", componentName);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${baseComponentName}.${v}`, params);
}
</script>
