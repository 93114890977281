<template>
  <div>
    <article v-if="displayWebrootLicense" class="bottomPadding">
      <div class="webroot">
        <hr />
        <div class="info">
          <div class="title">
            <strong>{{ t("Brand.WebrootAntiVirus") }}</strong>
          </div>
          <i18n-t
            v-if="displayWebrootLicenseSafeProductName"
            keypath="CardComputerComponent.wsavIncluded"
            tag="div"
            class="mt"
            scope="global"
          >
            <template #productName>
              {{ props.device.productName }}
            </template>
          </i18n-t>
          <div v-if="displayWebrootKeycode" class="mt">
            <span :id="`webrootKeycode${props.tagId}`" class="keycode">{{ t("CardComputerComponent.keycode") }}</span>
            <strong>{{ getFormatKeycode(props.device.webrootKeycode) }}</strong>
            <button
              :id="`copy${props.tagId}`"
              class="inline-btn-link"
              type="button"
              @click="copy(props.device.webrootKeycode)"
            >
              {{ t("CardComputerComponent.copy") }}
            </button>
            <div v-if="displayWebrootGetAppButton" class="mt">
              <button
                :id="`getTheApp${props.tagId}`"
                class="btn-outline-primary"
                @click="toggleDownloadProductModal('click')"
              >
                {{ t("CardComputerComponent.getTheApp") }}
              </button>
            </div>
          </div>
          <div v-else-if="displayGetMyKeycode" class="mt">
            <span :id="`webrootKeycode${props.tagId}`" class="keycode">{{ t("CardComputerComponent.keycode") }}</span>
            <button
              :id="`btnGetMyKeycode${props.tagId}`"
              class="inline-btn-link"
              :disabled="isGettingKeycode"
              @click="getWebrootKeycode()"
            >
              <spinner :is-spinning="isGettingKeycode" />
              {{ t("CardComputerComponent.getMyKeycode") }}
            </button>
          </div>
        </div>
      </div>
    </article>
    <article v-if="displayWebrootLicenseFailedProvisioning" class="bottomPadding">
      <div class="webroot">
        <hr />
        <div class="info">
          <div class="title">{{ t("Brand.WebrootAntiVirus") }}</div>
          <div class="icon-name-wrapper">
            <svg-icon-component icon="circle-exclamation" class="danger" />
            <div class="mt">
              {{ t("CardComputerComponent.unityError") }}
            </div>
          </div>
        </div>
      </div>
    </article>
    <DownloadProductComponent
      :show-dialog="showDownloadProductModal"
      product-name="WSAP"
      :keycodes="keycodesInfo"
      install-type="Install"
      @close-modal="toggleDownloadProductModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, onMounted, onUnmounted, onUpdated } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { IDevice } from "@/common/api/unifiedPortal/interfaces";
import { formatKeycode } from "@/common/formatKeycode";
import { customerType, expirationState } from "./CardComputerEnums";
import Spinner from "@/components/shared/Spinner.vue";
import DownloadProductComponent from "@/components/shared/dialogs/DownloadProduct/DownloadProduct.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { getOS, getBrowser } from "@/common/browserDetect";

const name = "WebrootLicenseCardComputerComponent";

const props = defineProps({
  device: {
    type: Object as PropType<IDevice>,
    required: true,
  },
  currentExpirationState: {
    type: Number as PropType<expirationState>,
    required: false,
    default: null,
  },
  currentCustomerType: {
    type: Number as PropType<customerType>,
    required: false,
    default: null,
  },
  userId: {
    type: Number,
    required: false,
    default: null,
  },
  tagId: {
    type: String,
    required: false,
    default: null,
  },
});

const emits = defineEmits(["update-device-keycode"]);

// WebrootLicense
const displayWebrootLicense = ref<boolean>(false);
const displayWebrootLicenseSafeProductName = ref<boolean>(false);
const displayWebrootKeycode = ref<boolean>(false);
const displayWebrootGetAppButton = ref<boolean>(false);
const displayGetMyKeycode = ref<boolean>(false);
const displayWebrootLicenseFailedProvisioning = ref<boolean>(false);
const showDownloadProductModal = ref<boolean>(false);

const isGettingKeycode = ref<boolean>(false);

logEvent("created", name, props);

function toggleDownloadProductModal(type: string | null) {
  type = type || "modal";
  logEvent("toggleDownloadProductModal", name, `${showDownloadProductModal.value ? "Hiding" : "Showing"} via ${type}`);
  showDownloadProductModal.value = !showDownloadProductModal.value;
}

// Render
onMounted(() => {
  try {
    displayGetMyKeycode.value =
      props.device.webrootKeycode === null &&
      props.currentExpirationState !== expirationState.expired &&
      (props.device.isTrial || props.device.productLevel === "plus" || props.device.productLevel === "premier");

    displayWebrootLicense.value =
      (!isSafeLite.value && props.device.webrootKeycode !== null) || displayGetMyKeycode.value;
    displayWebrootLicenseSafeProductName.value = props.currentCustomerType !== customerType.WSAC;
    displayWebrootKeycode.value = props.device.webrootKeycode !== null;
    displayWebrootGetAppButton.value = props.currentExpirationState !== expirationState.expired;
    displayWebrootLicenseFailedProvisioning.value = props.device.hasFailedWebrootProvisioning;

    logEvent("onMounted", name, {
      displayWebrootLicense: displayWebrootLicense.value,
      displayGetMyKeycode: displayGetMyKeycode.value,
      displayWebrootKeycode: displayWebrootKeycode.value,
      displayWebrootGetAppButton: displayWebrootGetAppButton.value,
      displayWebrootLicenseSafeProductName: displayWebrootLicenseSafeProductName.value,
      displayWebrootLicenseFailedProvisioning: displayWebrootLicenseFailedProvisioning.value,
    });
  } catch (err) {
    logException(err as Error, props);
  }
});

onUpdated(() => {
  logEvent("onUpdated", name);
});

onUnmounted(() => {
  logEvent("onUnmounted", name);
});

const isSafeLite = computed(() => {
  return props.device.sku.toLowerCase() === "personalbasic" && !props.device.isTrial && !!props.device.webrootKeycode;
});

function getFormatKeycode(keycode: string) {
  return formatKeycode(keycode);
}

async function getWebrootKeycode() {
  logEvent("getWebrootKeycode", name);
  isGettingKeycode.value = true;

  try {
    const keycode = (await unifiedApi.getWebrootKeycode(props.device.deviceId, props.device.zuoraSubscriptionNumber))
      .data;

    logEvent("keycode", name, keycode);

    if (keycode.length > 0) {
      displayGetMyKeycode.value = false;
      displayWebrootKeycode.value = true;

      emits("update-device-keycode", keycode);
    }
  } catch (err) {
    handleApiError(err as AxiosError);
  }

  isGettingKeycode.value = false;
}

// Actions
function copy(keycodeText: string) {
  try {
    navigator.clipboard.writeText(getFormatKeycode(keycodeText));
  } catch (err) {
    logException(err as Error, {
      Method: "Copy",
      keycodeText: keycodeText,
      FormattedKeyCode: getFormatKeycode(keycodeText),
      Browser: getBrowser(),
      Os: getOS(),
    });
  }
}

const keycodesInfo = computed(() => {
  return {
    hasSafeSubscriptions: true,
    webrootSubscriptions: [
      {
        webrootLicenseUID: 0,
        keycode: props.device.webrootKeycode,
        expirationDate: props.device.expirationDate,
        term: 0,
        quantity: 0,
        seats: 0,
        productName: "",
        autoRenew: false,
        isActive: false,
        distributionMethodCode: "",
        licenseCategoryName: "WSAV",
        licenseStatusDescription: "",
        backupSyncEnabled: false,
      },
    ],
    hasFailedWebrootProvisioning: false,
    isCarboniteOnlyCustomer: false,
  };
});
</script>

<style scoped lang="css">
@import "@/styles/variables.css";

.bottomPadding {
  padding-bottom: var(--space-half);
}

article {
  padding-bottom: 0;
  padding-top: 0;
}
article li {
  margin-bottom: var(--space-half);
  margin-left: 0;
  padding: 0;
}

article .btn-link {
  min-width: auto;
  margin: 0 0 0 10px;
  padding: 0;
  display: inline-block;
  text-decoration: none;
  font-weight: normal;
}

article .webroot {
  display: block;
  width: 100%;
}

article .icon-name-wrapper {
  display: flex;
  align-content: flex-start;
}
article .icon-name-wrapper .mt {
  padding-left: var(--space-quarter);
}
article .icon-name-wrapper svg {
  width: var(--icon-sm);
  height: var(--icon-sm);
}

@media (min-width: 960px) {
  article .webroot .info {
    padding-left: var(--icon-xxxl);
  }
}
</style>
