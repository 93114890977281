<template>
  <ul>
    <li v-for="(item, i) in props.invoice.subscriptionPaymentItems" :key="i">
      <ul class="mb-half">
        <li>{{ getProductName(item.sku) }}</li>
        <li>{{ tn("quantity") }} {{ item.quantity }}</li>
      </ul>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { t } from "@/i18n";
import { IUserPaymentHistoryPaymentInfo } from "@/common/api/unifiedPortal/interfaces";

import { webrootLicenseCategories } from "@/common/webrootProductName";

const props = defineProps({
  invoice: {
    type: Object as PropType<IUserPaymentHistoryPaymentInfo>,
    required: true,
  },
  localizedStringComponentName: {
    type: String,
    default: "PaymentHistoryComponent",
  },
});

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${props.localizedStringComponentName}.${v}`, params);
}

function getProductName(sku: string) {
  return t(`Brand.${sku === webrootLicenseCategories.Webroot ? webrootLicenseCategories.WSAV : sku}`);
}
</script>
