<template>
  <i18n-t :keypath="'NotificationsComponent.' + props.notification.type" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <router-link :to="{ name: notification?.params?.url }">{{ t(notificationMessage) }}</router-link>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { INotification } from "@/stores/notifications";

const componentName = "KeycodeExpiredNotification";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    required: true,
  },
});

logEvent("created", componentName);

const notificationMessage = ref<string>(props.notification?.params?.text ?? "");
</script>
