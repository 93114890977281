<template>
  <i18n-t :keypath="'NotificationsComponent.' + props.notification.type" tag="span" class="pl-quarter" scope="global">
    <template #link>
      <router-link v-if="!props.notification.params?.linkToOutsideSite" :to="{ name: props.notification.params?.url }">
        {{ t(notificationMessage) }}
      </router-link>
      <button v-else class="inline-btn-link" type="button" @click="goToUrl()">{{ t(notificationMessage) }}</button>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { ref, PropType } from "vue";
import { t } from "@/i18n";
import { INotification, INotificationParams } from "@/stores/notifications";

const props = defineProps({
  notification: {
    type: Object as PropType<INotification>,
    default: null,
  },
});

const notificationMessage = ref<string>((props.notification?.params as INotificationParams)?.text ?? "");

function goToUrl() {
  if (props.notification?.params && props.notification?.params?.url)
    window.location.href = props.notification.params.url;
}
</script>
