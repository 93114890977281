<template>
  <div v-if="loading">
    <LoadingSpinner />
  </div>
  <div v-else>
    <h1>{{ tn("h1Heading") }}</h1>
    <div class="mb"><svg-icon-component icon="logo-allstate" /></div>
    <p class="mb">{{ tn("summaryText") }}</p>
    <ul class="form">
      <li>
        <label for="email">{{ tn("email") }}</label>
        <input id="email" v-model="email" type="text" disabled />
      </li>
      <li>
        <label for="first-name">{{ tn("firstName") }}</label>
        <InputErrorComponent :show="firstNameErrors && firstNameErrors.length > 0">
          <input
            id="first-name"
            v-model="firstName"
            type="text"
            :disabled="isInactiveKeycode"
            @keyup="dirtyFlagFirstName = true"
          />
          <template #error>
            <div>
              <span :key="firstNameErrors[0]">
                {{ firstNameErrors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="last-name">{{ tn("lastName") }}</label>
        <InputErrorComponent :show="lastNameErrors && lastNameErrors.length > 0">
          <input
            id="last-name"
            v-model="lastName"
            type="text"
            :disabled="isInactiveKeycode"
            @keyup="dirtyFlagLastName = true"
          />
          <template #error>
            <div>
              <span :key="lastNameErrors[0]">
                {{ lastNameErrors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="phone">{{ tn("phone") }}</label>
        <InputErrorComponent :show="phoneErrors && phoneErrors.length > 0">
          <MaskedInputComponent
            :mask-type="MaskTypes.phone"
            :validate-length="false"
            :disabled="isInactiveKeycode"
            @update="value => (phone = value.rawValue)"
            @keyup="dirtyFlagPhone = true"
          />
          <template #error>
            <div>
              <span :key="phoneErrors[0]">
                {{ phoneErrors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="address-1">{{ tn("address1") }}</label>
        <InputErrorComponent :show="address1Errors && address1Errors.length > 0">
          <input
            id="address-1"
            v-model="address1"
            type="text"
            :disabled="isInactiveKeycode"
            @keyup="dirtyFlagAddress1 = true"
          />
          <template #error>
            <div>
              <span :key="address1Errors[0]">
                {{ address1Errors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="address-2">{{ tn("address2") }}</label>
        <InputErrorComponent :show="address2Errors && address2Errors.length > 0">
          <input id="address-2" v-model="address2" type="text" :disabled="isInactiveKeycode" />
          <template #error>
            <div>
              <span :key="address2Errors[0]">
                {{ address2Errors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="city">{{ tn("city") }}</label>
        <InputErrorComponent :show="cityErrors && cityErrors.length > 0">
          <input id="city" v-model="city" type="text" :disabled="isInactiveKeycode" @keyup="dirtyFlagCity = true" />
          <template #error>
            <div>
              <span :key="cityErrors[0]">
                {{ cityErrors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="state">{{ tn("state") }}</label>
        <InputErrorComponent :show="stateErrors && stateErrors.length > 0">
          <select id="state" v-model="state" class="select-css" :disabled="isInactiveKeycode">
            <option selected value="" disabled>{{ tn("selectOption") }}</option>
            <option v-for="usState in usStates" :key="usState.code" :value="usState.code">{{ usState.name }}</option>
          </select>
          <template #error>
            <div>
              <span :key="stateErrors[0]">
                {{ stateErrors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="zip">{{ tn("zip") }}</label>
        <InputErrorComponent :show="zipErrors && zipErrors.length > 0">
          <!-- <MaskedInputComponent
            :mask-type="MaskTypes.zip"
            :validate-length="false"
            @update="value => (zip = value.formattedValue)"
          /> -->
          <input
            id="zip"
            v-model="zip"
            type="text"
            maxlength="5"
            :disabled="isInactiveKeycode"
            @keyup="dirtyFlagZip = true"
          />
          <template #error>
            <div>
              <span :key="zipErrors[0]">
                {{ zipErrors[0] }}
              </span>
            </div>
          </template>
        </InputErrorComponent>
      </li>
      <li>
        <label for="country">{{ tn("country") }}</label>
        <select id="country" class="select-css" disabled>
          <option>{{ tn("us") }}</option>
        </select>
      </li>
      <li class="radio border-bottom border-top">
        <input id="privacy-agreement-radio" v-model="privacyCheck" type="checkbox" value="selected" />
        <div>
          <label for="privacy-agreement-radio">{{ tn("privacyAgreement") }}</label>
          <button id="privacy-agreement-link" class="inline-btn-link" @click="openPrivacyPolicy()">
            {{ tn("privacyPolicyLink") }}
          </button>
        </div>
      </li>
      <li class="mt">
        <button
          id="btn-create"
          class="btn-primary"
          :disabled="!isCreateButtonEnabled || isCreatingAccount || isInactiveKeycode"
          @click="createAccount()"
        >
          <spinner :is-spinning="isCreatingAccount" />
          {{ tn("createAllstate") }}
        </button>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { useUserStore } from "@/stores/user";
import InputErrorComponent from "@/components/shared/InputError.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import {
  isFirstNameValid,
  isLastNameValid,
  isAddress1Valid,
  isAddress2Valid,
  isPhoneValid,
  isZipValid,
  isStateValid,
  isCityValid,
} from "./AllstateFormValidations";
import { usStates } from "@/common/usStates";
import Spinner from "@/components/shared/Spinner.vue";
import { IAllstateRequest } from "@/common/api/unifiedPortal/interfaces";
import { unifiedApi } from "@/common";
import MaskedInputComponent from "@/components/shared/MaskedInput.vue";
import { MaskTypes } from "@/globalEnums";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import { useAllstateSubscriptionsStore } from "@/stores/allstateSubscriptions";
import { getUrl } from "@/common/getUrl";
import { useNotificationsStore } from "@/stores/notifications";
import { AxiosError } from "axios";
import { IErrorMessage } from "@/common/api/interfaces";

const componentName = "AllstateSignUpFormComponent";

const props = defineProps({
  keycode: {
    type: String,
    default: null,
    required: false,
  },
  isInactive: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emits = defineEmits(["completed"]);

logEvent("created", componentName);

const userStore = useUserStore();
const email = computed(() => userStore.currentUser?.email);
const firstName = ref<string>("");
const lastName = ref<string>("");
const phone = ref<string>("");
const address1 = ref<string>("");
const address2 = ref<string>("");
const city = ref<string>("");
const state = ref<string>("");
const zip = ref<string>("");
const privacyCheck = ref<boolean>(false);
const isCreatingAccount = ref<boolean>(false);
const loading = ref<boolean>(true);
const dirtyFlagFirstName = ref<boolean>(false);
const dirtyFlagLastName = ref<boolean>(false);
const dirtyFlagPhone = ref<boolean>(false);
const dirtyFlagAddress1 = ref<boolean>(false);
const dirtyFlagCity = ref<boolean>(false);
const dirtyFlagZip = ref<boolean>(false);
const notificationsStore = useNotificationsStore();

// Computed validations
const firstNameErrors = computed(() => (dirtyFlagFirstName.value === false ? [] : isFirstNameValid(firstName.value)));

const lastNameErrors = computed(() => (dirtyFlagLastName.value === false ? [] : isLastNameValid(lastName.value)));

const phoneErrors = computed(() => (dirtyFlagPhone.value === false ? [] : isPhoneValid(phone.value)));

const cityErrors = computed(() => (dirtyFlagCity.value === false ? [] : isCityValid(city.value)));

const stateErrors = computed(() => (state.value === "" ? [] : isStateValid(state.value)));

const zipErrors = computed(() => (dirtyFlagZip.value === false ? [] : isZipValid(zip.value)));

const address1Errors = computed(() => (dirtyFlagAddress1.value === false ? [] : isAddress1Valid(address1.value)));

const address2Errors = computed(() => (address2.value === "" ? [] : isAddress2Valid(address2.value)));

const isCreateButtonEnabled = computed(() => privacyCheck.value && !hasValidationErrors() && areRequiredFieldsFilled());

const isInactiveKeycode = computed(() => props.isInactive);

// Functions
function hasValidationErrors() {
  return (
    firstNameErrors.value.length > 0 ||
    lastNameErrors.value.length > 0 ||
    phoneErrors.value.length > 0 ||
    cityErrors.value.length > 0 ||
    stateErrors.value.length > 0 ||
    zipErrors.value.length > 0 ||
    address1Errors.value.length > 0 ||
    address2Errors.value.length > 0
  );
}

function areRequiredFieldsFilled() {
  return (
    firstName.value.length > 0 &&
    lastName.value.length > 0 &&
    phone.value.length > 0 &&
    city.value.length > 0 &&
    state.value.length > 0 &&
    zip.value.length > 0 &&
    address1.value.length > 0
  );
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onBeforeMount(async () => {
  logEvent("mounting", componentName);
  loading.value = true;
  const webrootUserInfo = (await unifiedApi.getWebrootPIIInformation(props.keycode))?.data;
  if (webrootUserInfo && webrootUserInfo.country === "US") {
    firstName.value = webrootUserInfo.firstName ?? "";
    lastName.value = webrootUserInfo.lastName ?? "";
    address1.value = webrootUserInfo.address1 ?? "";
    address2.value = webrootUserInfo.address2 ?? "";
    state.value = webrootUserInfo.state ?? "";
    city.value = webrootUserInfo.city ?? "";
    zip.value = webrootUserInfo.postalCode?.substring(0, 5) ?? "";
  }
  loading.value = false;
});

function openPrivacyPolicy() {
  window.open(getUrl("ALLSTATE_PRIVACY_POLICY_URL"), "_blank");
}

async function createAccount() {
  isCreatingAccount.value = true;
  setDirtyFlagsToTrue();

  if (hasValidationErrors()) {
    isCreatingAccount.value = false;
    return;
  }

  const request: IAllstateRequest = {
    firstName: firstName.value.trim(),
    lastName: lastName.value.trim(),
    address1: address1.value.trim(),
    address2: address2.value.trim(),
    state: state.value,
    zip: zip.value,
    city: city.value.trim(),
    phone: phone.value.replace("-", "").replace("(", "").replace(")", ""),
    keycode: props.keycode,
  };

  try {
    const response = (await unifiedApi.addAllstateSubscription(request)).data;
    if (response) {
      logEvent(`createAccount: ${JSON.stringify(response)}`, componentName);
      await useAllstateSubscriptionsStore().forceRefreshAndGetAllstateSubscriptions();
      await reloadSubscriberInfo();
      emits("completed", response);
    }
  } catch (error) {
    const e = error as AxiosError;
    const message = (e.response?.data as IErrorMessage)["Message"];
    notificationsStore.addNotification({
      type: message === "Status:Conflict" ? "AllstateConflictError" : "AllstateSignUpError",
      dismissible: false,
    });
    logException(error as Error, `${componentName}/createAccount`);
  }
  isCreatingAccount.value = false;
}

function setDirtyFlagsToTrue() {
  dirtyFlagFirstName.value = true;
  dirtyFlagLastName.value = true;
  dirtyFlagPhone.value = true;
  dirtyFlagAddress1.value = true;
  dirtyFlagCity.value = true;
  dirtyFlagZip.value = true;
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
